import _ from "lodash";
import { transformReceivedCollection } from "../../model.utils";
import { defaultProps, schema, MODEL_NAME } from "./model";

/**
 * Funded loan (details) Transform [RECEIVED]
 * payments
 * transform data -> before it reaches the store
 * @param {*} rawData
 * @author Ehsan
 */

export const transformReceived = rawData => {
  const transformData = transformReceivedCollection(
    rawData,
    defaultProps,
    schema,
    MODEL_NAME
  );

  const payments = transformData.results;

  payments.forEach(payment => {
    payment.amount = payment.amount
      ? _.round(payment.amount, 2)
      : payment.amount;

    payment.principal = payment.principal
      ? _.round(payment.principal, 2)
      : payment.principal;

    payment.insurance = payment.insurance
      ? _.round(payment.insurance, 2)
      : payment.insurance;

    payment.interest = payment.interest
      ? _.round(payment.interest, 2)
      : payment.interest;

    payment.adminFees = payment.adminFees
      ? _.round(payment.adminFees, 2)
      : payment.adminFees;

    payment.fees = payment.fees ? _.round(payment.fees, 2) : payment.fees;
    payment.balance = payment.balance
      ? _.round(payment.balance, 2)
      : payment.balance;

    if (payment.isDebit) {
      payment.amount = -payment.amount;
      payment.principal = -payment.principal;
      payment.insurance = -payment.insurance;
      payment.interest = -payment.interest;
      payment.adminFees = -payment.adminFees;
      payment.fees = -payment.fees;
    }

    if (!payment.isHistory) {
      // If the payment is in the future, API doesn't return a transaction date
      // Due to the edge case where if we have pass payment and future payment on the same day,
      // We need to sort the transactions based on transactionDate and as a result,
      // We add a huge transaction date to payments that are in the future
      payment.transactionDate = "9999-01-01";
    }
  });

  return { results: payments };
};
