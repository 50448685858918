import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles/action.menu.styles";

function _ActionGroup({ classes, children, dataTestId }) {
  return (
    <List
      classes={{ root: classes.listRoot }}
      data-test={dataTestId}
      component="div"
    >
      {children}
    </List>
  );
}

function _Action({ classes, children, onClick, dataTestId }) {
  return (
    <ListItem
      classes={{ root: classes.listItemRoot }}
      button
      aria-label={children}
      onClick={onClick}
      disableRipple
      data-test={dataTestId}
    >
      <Typography>{children}</Typography>
    </ListItem>
  );
}

const ActionGroup = withStyles(styles)(_ActionGroup);
const Action = withStyles(styles)(_Action);

export { ActionGroup, Action };
