import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import { EDIT_TABLE_COLUMN_KEY } from "./edit.table.constants";

/**
 * createCacheData creates data for caching based on the initialValues
 * @param initialValues
 * @param original
 */
export const createCacheData = (initialValues, original) => {
  const cacheData = {};
  for (const key in initialValues) {
    if (initialValues.hasOwnProperty(key)) {
      cacheData[key] = original[key];
    }
  }
  return cacheData;
};

/**
 * createPayloadData creates object based on the fields
 * that are editable
 * @param editableFields
 * @param original
 * @returns {{}}
 */
export const createPayloadData = (editableFields, original) => {
  return pickBy(original, (value, key) => editableFields.indexOf(key) > -1);
};

/**
 * getAddedItems extracts item that do not have id
 * @param data
 * @returns {*}
 */
export const getAddedItems = data => {
  if (!Array.isArray(data)) {
    return [];
  }
  // newly added items not in service don't have an id
  return data.filter(d => isNil(d.id));
};

/**
 * canAddRow determines if there is an already added item
 * in the added items array
 * @param data
 * @returns {boolean}
 */
export const canAddRow = data => getAddedItems(data).length < 20;

/**
 * isColTypeEdit
 * @param col
 * @returns {*}
 */
export const isColTypeEdit = col => {
  return (
    col.id && col[EDIT_TABLE_COLUMN_KEY] && col[EDIT_TABLE_COLUMN_KEY].enabled
  );
};

/**
 * getEditableFields extracts the editable field
 * as defined in the columns schema, specifically 'editable' prop
 * @param columns
 * @returns {*}
 */
export const getEditableFields = columns => {
  return columns.filter(isColTypeEdit).map(field => field.id);
};

/**
 * hasError determines if the errors prop contain field error
 * @param errors
 * @returns {boolean}
 */
export const hasError = errors => {
  const sanitizedErrors = omitBy(errors, value => isNil(value));
  return Object.keys(sanitizedErrors).length > 0;
};

/**
 * warnUnsavedChanges determines if data is touched or not
 * @param editableFields
 * @param data
 * @returns {*|boolean}
 */
export const warnUnsavedChanges = (editableFields, data) => {
  let hasChanged = false;

  const original =
    data && data.defaultProps && Object.keys(data.defaultProps).length > 0
      ? data.defaultProps
      : null;

  if (!editableFields || !data || !original) {
    return hasChanged;
  }

  editableFields.forEach(field => {
    if (data.hasOwnProperty(field) && original.hasOwnProperty(field)) {
      const currValue = data[field];
      const origValue = original[field];

      if (currValue !== origValue) {
        hasChanged = true;
      }
    }
  });

  return hasChanged;
};
