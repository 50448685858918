"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var google = _interopRequireWildcard(require("./google/google.auth.service"));

var oauth = {
  google: google
};
var _default = oauth;
exports.default = _default;