export const styles = theme => ({
  breadcrumb: {
    whiteSpace: "nowrap",
    flex: 1
  },
  crumb: {
    "&:link": {
      color: theme.palette.grey[800],
      fontWeight: "bold",
      textDecoration: "none"
    },
    "&:visited": {
      color: theme.palette.grey[800]
    },
    "&:hover": {
      textDecoration: "underline"
    }
  },
  crumbIcon: {
    height: 15,
    fontSize: 20
  }
});
