"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _redux = require("redux");

var _connectedReactRouter = require("connected-react-router");

var _reduxPersist = require("redux-persist");

var _history = require("history");

var _reduxSaga = _interopRequireDefault(require("redux-saga"));

var _store = require("./store.enhancers");

var _constants = require("./constants");

var developmentStore = function developmentStore(_ref) {
  var rootReducer = _ref.rootReducer,
      _ref$initialState = _ref.initialState,
      initialState = _ref$initialState === void 0 ? {} : _ref$initialState,
      rootSaga = _ref.rootSaga,
      _ref$persist = _ref.persist,
      persist = _ref$persist === void 0 ? true : _ref$persist,
      _ref$middleware = _ref.middleware,
      middleware = _ref$middleware === void 0 ? [] : _ref$middleware;
  if (!rootReducer) throw Error("Missing rootReducer argument.");
  if (!rootSaga) throw Error("Missing rootSaga argument.");
  var history = (0, _history.createBrowserHistory)(); // Create composer with redux devtools

  var composeEnhancers = (0, _store.composeWithReduxDevTools)(); // Enhancers and Middleware

  var sagaMiddleware = (0, _reduxSaga.default)();
  var combinedMiddleware = [(0, _connectedReactRouter.routerMiddleware)(history)].concat((0, _toConsumableArray2.default)(middleware), [sagaMiddleware]); // Enhance middleware with Redux Logger

  (0, _store.withReduxLogger)(combinedMiddleware);
  var enhancers = [_redux.applyMiddleware.apply(void 0, (0, _toConsumableArray2.default)(combinedMiddleware))];
  var store = (0, _redux.createStore)( // (function) new root reducer with router state
  (0, _connectedReactRouter.connectRouter)(history)(rootReducer), // (pre-loaded state)
  initialState, // (function) may optionally specify to enhance with third-party capabilities
  // such as middleware, time travel, persistence, etc.
  composeEnhancers.apply(void 0, enhancers)); // Start Saga
  // NOTE: running sagas in store should happen after applying enhancers!

  sagaMiddleware.run(rootSaga); // Setup store persistence to browser storage

  var persistor = persist ? (0, _reduxPersist.persistStore)(store) : null; // Development window.store

  if (window) window.store = store;
  return {
    store: store,
    persistor: persistor,
    history: history,
    type: _constants.DEVELOPMENT
  };
};

var _default = developmentStore;
exports.default = _default;