"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AGE_OF_MAJORITY", {
  enumerable: true,
  get: function get() {
    return _ageOf.AGE_OF_MAJORITY;
  }
});
Object.defineProperty(exports, "LOAN_AMOUNT", {
  enumerable: true,
  get: function get() {
    return _loan.LOAN_AMOUNT;
  }
});

var _ageOf = require("./age.of.majority");

var _loan = require("./loan.amount");