import { createSelector } from "reselect";

const coordinator = state => state.data.coordinator;

export const getApplicationDecision = createSelector(
  coordinator,
  coordinatorData => coordinatorData.decision || {}
);

export const getApplicationScore = createSelector(
  coordinator,
  coordinatorData => coordinatorData.score || {}
);

export const getApplicationStatus = createSelector(
  coordinator,
  coordinatorData => coordinatorData.newStatus || null
);
