export const STATUS = {
  APPROVED: "Approved",
  AUTOMATED_APPROVED: "Automated - Approved",
  AUTOMATED_DECLINED: "Automated - Declined",
  AUTOMATED_DRAFT: "Automated - Draft",
  AUTOMATED_NO_BANK: "Automated - No Bank",
  CONDITIONALLY_APPROVED: "Conditionally Approved",
  PENDING_DECLINED: "Pending Declined",
  DECLINED: "Declined",
  DECLINED_AGENCY_REFERRAL: "Declined Agency Referral",
  DRAFT: "Draft",
  FAILED_IMPORT_VALIDATION: "LMS Send Failed",
  FUNDED: "Funded",
  FUNDING_FAILED: "Funding Failed",
  IWS_DECLINED: "IWS Declined",
  IWS_UNRESPONSIVE: "IWS Unresponsive",
  LMS_SEND_FAILED: "LMS Send Failed",
  NEEDS_BANK_STATEMENTS: "Needs Bank Statements",
  NO_BANK: "No Bank",
  READY_FOR_FUNDING: "Ready for Funding",
  READY_FOR_LMS: "Ready for LMS",
  READY_FOR_LOAN_IMPORT: "Ready for Loan Import",
  READY_FOR_LOANS_OFFICER: "Ready for Loans Officer",
  READY_FOR_SCORING: "Ready for Scoring",
  STALE_UNRESPONSIVE: "Stale - Unresponsive",
  STALE_CANCELLED_BY_CUSTOMER: "Stale - Cancelled by Customer",
  VERIFY_DOCUMENTS: "Verify Documents",
  CHECK_IMPORT_VALIDATIONS: "Check Import Validations"
};
