/**
 * FUNDED LOAN - FORM ACTION TYPES
 * @author: Ehsan
 */

//  TITLE
// CACHE ADD/UPDATE PERSONAL DETAILS FORM
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITE_FORM_CACHE =
  "funded.loan.details/PERSONAL_DETAILS_TITLE_FORM_CACHE";

// CANCEL PERSONAL DETAILS FORM
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_TITLE_CANCEL =
  "funded.loan.details/PERSONAL_DETAILS_EDIT_TITLE_CANCEL";

// CACHE PERSONAL DETAILS FORM VALUES - TITLE
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_CACHE_VALUE =
  "funded.loan.details/PERSONAL_DETAILS_TITLE_CACHE_VALUE";

// UPDATE PERSONAL DETAILS FORM - TITLE
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE =
  "funded.loan.details/PERSONAL_DETAILS_TITLE_UPDATE";

// UPDATE PERSONAL DETAILS FORM - TITLE (SUCCESS)
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_SUCCESS =
  "funded.loan.details/PERSONAL_DETAILS_TITLE_UPDATE_SUCCESS";

// UPDATE PERSONAL DETAILS FORM - TITLE (FAILURE)
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_FAILURE =
  "funded.loan.details/PERSONAL_DETAILS_TITLE_UPDATE_FAILURE";

//  MARITAL STATUS
// CACHE ADD/UPDATE PERSONAL DETAILS FORM
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_FORM_CACHE =
  "funded.loan.details/PERSONAL_DETAILS_MARITAL_FORM_CACHE";

// CANCEL PERSONAL DETAILS FORM
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_MARITAL_CANCEL =
  "funded.loan.details/PERSONAL_DETAILS_EDIT_MARITAL_CANCEL";

// CACHE PERSONAL DETAILS FORM VALUES - MARITAL
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_CACHE_VALUE =
  "funded.loan.details/PERSONAL_DETAILS_MARITAL_CACHE_VALUE";

// UPDATE PERSONAL DETAILS FORM - MARITAL
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE =
  "funded.loan.details/PERSONAL_DETAILS_MARITAL_UPDATE";

// UPDATE PERSONAL DETAILS FORM - MARITAL (SUCCESS)
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_SUCCESS =
  "funded.loan.details/PERSONAL_DETAILS_MARITAL_UPDATE_SUCCESS";

// UPDATE PERSONAL DETAILS FORM - MARITAL (FAILURE)
export const FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_FAILURE =
  "funded.loan.details/PERSONAL_DETAILS_MARITAL_UPDATE_FAILURE";

// PHONE EDIT
export const FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CACHE =
  "funded.loan.details/PHONE_EDIT_FORM_CACHE";
export const FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CLEAN =
  "funded.loan.details/PHONE_EDIT_FORM_CLEAN";
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE =
  "funded.loan.details/CLIENT_PHONE_UPDATE";
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_SUCCESS =
  "funded.loan.details/CLIENT_PHONE_UPDATE_SUCCESS";
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_FAILURE =
  "funded.loan.details/CLIENT_PHONE_UPDATE_FAILURE";

// PHONE ADD
export const FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CACHE =
  "funded.loan.details/PHONE_ADD_FORM_CACHE";
export const FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CLEAN =
  "funded.loan.details/PHONE_ADD_FORM_CLEAN";
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD =
  "funded.loan.details/CLIENT_PHONE_ADD";
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_SUCCESS =
  "funded.loan.details/CLIENT_PHONE_ADD_SUCCESS";
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_FAILURE =
  "funded.loan.details/CLIENT_PHONE_ADD_FAILURE";

// AUTH USER ADD
export const FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CACHE =
  "funded.loan.details/AUTH_USER_ADD_FORM_CACHE";
export const FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CLEAN =
  "funded.loan.details/AUTH_USER_ADD_FORM_CLEAN";
export const FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD =
  "funded.loan.details/CLIENT_AUTH_USER_ADD";
export const FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_SUCCESS =
  "funded.loan.details/CLIENT_AUTH_USER_ADD_SUCCESS";
export const FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_FAILURE =
  "funded.loan.details/CLIENT_AUTH_USER_ADD_FAILURE";

// AUTH USER UPDATE
export const FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CACHE =
  "funded.loan.details/AUTH_USER_UPDATE_FORM_CACHE";
export const FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CLEAN =
  "funded.loan.details/AUTH_USER_UPDATE_FORM_CLEAN";
export const FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE =
  "funded.loan.details/CLIENT_AUTH_USER_UPDATE";
export const FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_SUCCESS =
  "funded.loan.details/CLIENT_AUTH_USER_UPDATE_SUCCESS";
export const FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_FAILURE =
  "funded.loan.details/CLIENT_AUTH_USER_UPDATE_FAILURE";

// CLIENT TITLE
export const FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM =
  "funded.loan.details/CLIENT_EDIT_NAME_FORM";

export const FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM_CLEAR =
  "funded.loan.details/CLIENT_EDIT_NAME_FORM_CLEAR";
