import { MESSAGE_SHOW, MESSAGE_HIDE } from "./messenger.action.types";

/**
 * MESSENGER REDUCER
 */
const initialState = {
  message: null,
  type: "info", // info | error | warn | success
  show: false,
  autoHideDuration: 5000
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_SHOW:
      return {
        ...state,
        ...action.messenger
      };

    case MESSAGE_HIDE:
      return {
        ...state,
        show: false
      };

    default:
      return state;
  }
};
