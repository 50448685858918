"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var styles = function styles() {
  return {
    banner: {
      padding: "15px 60px 40px 60px",
      width: "100%",
      minHeight: "60px"
    },
    space24: {
      height: 24
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline",
      justifyContent: "space-between"
    },
    text: {
      marginLeft: "20px",
      marginRight: "20px"
    }
  };
};

var _default = styles;
exports.default = _default;