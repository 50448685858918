import { takeEvery, fork, call } from "redux-saga/effects";
import { http } from "../http/http.service";

// LOCAL DEPENDENCIES
import { ERROR_POST } from "./messenger.action.types";
import { NODE_ENV } from "../../core/constants/env";

/**
 * POST ERROR
 * sends error to an external error logging system
 * @param action
 * @returns {IterableIterator<*|CallEffect>}
 */
export const postError = action => {
  const payload = action.error.response;
  return http({
    request: (httpSrv, API) => call(httpSrv.post, API.LOG_REPORTS, payload),
    success: data => {
      if (NODE_ENV === "local" || NODE_ENV === "development")
        return Promise.resolve(data);
    },
    error: error => {
      if (NODE_ENV === "local" || NODE_ENV === "development")
        return Promise.resolve(error);
    }
  });
};

// WATCHERS
export function* watchAsyncRequests() {
  yield takeEvery(ERROR_POST, postError);
}

/**
 * FUNDED LOANS SAGA
 * Watches async requests,
 * in case of request, uses appropriate Action
 * passed in the reducer to return a new state
 */
export default [fork(watchAsyncRequests)];
