import React from "react";
import Loadable from "react-loadable";
import Loader from "../../ui.library/Loader";

export default Loadable.Map({
  loader: {
    Tranche: () => import("./tranche.management.index")
  },
  render(loaded, props) {
    const Tranche = loaded.Tranche.default;
    return <Tranche {...props} />;
  },
  loading: () => <Loader size={75} thickness={3.5} centerScreen />
});
