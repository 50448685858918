"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;

var _loglevel = _interopRequireDefault(require("loglevel"));

var bugsnag = _interopRequireWildcard(require("../bugsnag"));

_loglevel.default.setDefaultLevel("error");

function create(name) {
  if (process.env.REACT_APP_ENABLE_EXTERNAL_LOGGING === "true") {
    bugsnag.apply(_loglevel.default);
  }

  var newLogger = name ? _loglevel.default.getLogger(name) : _loglevel.default;

  if (process.env.REACT_APP_LOG_LEVEL) {
    newLogger.setLevel(process.env.REACT_APP_LOG_LEVEL, false);
  }

  return newLogger;
}