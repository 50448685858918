import {
  FUNDED_LOANS_SEARCH,
  FUNDED_LOANS_SEARCH_SUCCESS,
  FUNDED_LOANS_SEARCH_FAILURE,
  FUNDED_LOANS_CLEAR,
  FUNDED_LOANS_SEARCH_TABLE_SETTINGS_UPDATE
} from "./funded.loans.action.types";

export function searchFundedLoans(searchCriteria) {
  return {
    type: FUNDED_LOANS_SEARCH,
    searchCriteria
  };
}

export function searchFundedLoansSuccess(data) {
  return {
    type: FUNDED_LOANS_SEARCH_SUCCESS,
    data: data
  };
}

export function searchFundedLoansFailure(error) {
  return {
    type: FUNDED_LOANS_SEARCH_FAILURE,
    error
  };
}

export function clearFundedLoans() {
  return {
    type: FUNDED_LOANS_CLEAR
  };
}

export function updateSearchTableSettings(settings) {
  return {
    type: FUNDED_LOANS_SEARCH_TABLE_SETTINGS_UPDATE,
    settings
  };
}
