"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CenterCenter", {
  enumerable: true,
  get: function get() {
    return _center.default;
  }
});

var _center = _interopRequireDefault(require("./center.center"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}