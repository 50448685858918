import { utils } from "@cauldron/core";
import { actionTypes } from "./credit.report.action.types";

const { makeActionCreator } = utils;

export const fetchCreditReportSummary = makeActionCreator(
  actionTypes.CREDIT_REPORT_SUMMARY_FETCH_REQUEST,
  "applicationId",
  "clientId"
);
export const fetchCreditReportSummarySuccess = makeActionCreator(
  actionTypes.CREDIT_REPORT_SUMMARY_FETCH_SUCCESS,
  "clientId",
  "data"
);
