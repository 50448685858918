import * as actions from "./error.handler.actions";
import * as selectors from "./error.handler.selector";
import * as constants from "./error.handler.constants";
import reducer from "./error.handler.reducer";

export { constants, actions, selectors, reducer };

export { default as ErrorPage } from "./error.page";

// LEGACY
// TODO: Error handler migrate references
export {
  addSectionError,
  clearSectionError,
  clearAllSectionErrors
} from "./error.handler.actions";
export { getSectionError } from "./error.handler.selector";
export { APP_SECTIONS } from "./error.handler.constants";
