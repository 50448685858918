import log from "loglevel";
import { messengerActionTypes } from ".";

const logger = log.getLogger("messenger.middleware");

/**
 * HANDLE ERROR
 * entry point for handling errors
 * @returns {IterableIterator<*|PutEffect<{type, error}>>}
 */
const errorHandler = () => next => action => {
  next(action);

  const { error } = action;

  if (error && action.type === messengerActionTypes.ERROR_HANDLE) {
    logger.log("Middleware triggered - log error to an external system");
    // store.dispatch(messengerActions.postError(error));
  }
};

export default errorHandler;
