export const LOAN_APPLICATION_CLIENT_INCOME_ADD =
  "loan.application/CLIENT_ADD_INCOME";
export const LOAN_APPLICATION_CLIENT_INCOME_ADD_SUCCESS =
  "loan.application/CLIENT_ADD_INCOME_SUCCESS";

export const LOAN_APPLICATION_CLIENT_INCOME_UPDATE =
  "loan.application/CLIENT_UPDATE_INCOME";
export const LOAN_APPLICATION_CLIENT_INCOME_UPDATE_SUCCESS =
  "loan.application/CLIENT_UPDATE_INCOME_SUCCESS";

// CLIENT INCOME - DELETE
export const LOAN_APPLICATION_CLIENT_INCOME_DELETE =
  "loan.application/CLIENT_INCOME_DELETE";
export const LOAN_APPLICATION_CLIENT_INCOME_DELETE_SUCCESS =
  "loan.application/CLIENT_INCOME_DELETE_SUCCESS";
export const LOAN_APPLICATION_CLIENT_INCOME_DELETE_FAILURE =
  "loan.application/CLIENT_INCOME_DELETE_FAILURE";
