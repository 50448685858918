// DELETE AUTH USER
export const FUNDED_LOAN_DETAILS_AUTH_USER_DELETE =
  "funded.loan.details/AUTH_USER_DELETE";
export const FUNDED_LOAN_DETAILS_AUTH_USER_DELETE_SUCCESS =
  "funded.loan.details/AUTH_USER_DELETE_SUCCESS";
export const FUNDED_LOAN_DETAILS_AUTH_USER_DELETE_FAILURE =
  "funded.loan.details/AUTH_USER_DELETE_FAILURE";

// Update email
export const FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE =
  "funded.loan.details/CLIENT_EMAIL_UPDATE";
export const FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE_SUCCESS =
  "funded.loan.details/CLIENT_EMAIL_UPDATE_SUCCESS";
export const FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE_FAILURE =
  "funded.loan.details/CLIENT_EMAIL_UPDATE_FAILURE";

// Remove auth user add form
export const FUNDED_LOAN_DETAILS_ADD_FORM_REMOVE =
  "funded.loan.details/ADD_FORM_REMOVE";

// Update address
export const FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE =
  "funded.loan.details/CLIENT_ADDRESS_UPDATE";
export const FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE_SUCCESS =
  "funded.loan.details/CLIENT_ADDRESS_UPDATE_SUCCESS";
export const FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE_FAILURE =
  "funded.loan.details/CLIENT_ADDRESS_UPDATE_FAILURE";

// DELETE PHONE
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_DELETE =
  "funded.loan.details/CLIENT_PHONE_DELETE";
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_DELETE_SUCCESS =
  "funded.loan.details/CLIENT_PHONE_DELETE_SUCCESS";
export const FUNDED_LOAN_DETAILS_CLIENT_PHONE_DELETE_FAILURE =
  "funded.loan.details/CLIENT_PHONE_DELETE_FAILURE";

// Switch edit mode / view mode
export const FUNDED_LOAN_DETAILS_CLIENT_EDIT_MODE_SWITCH =
  "funded.loan.details/CLIENT_EDIT_MODE_SWITCH";

// Switch edit mode / view mode
export const FUNDED_LOAN_DETAILS_CLIENT_EDIT_MODE_SAVE_DRAFT_DATA =
  "funded.loan.details/CLIENT_EDIT_MODE_SAVE_DRAFT_DATA";

// Edit name form
export const FUNDED_LOAN_DETAILS_CLIENT_UPDATE_NAME =
  "funded.loan.details/CLIENT_UPDATE_NAME";

export const FUNDED_LOAN_DETAILS_CLIENT_UPDATE_NAME_SUCCESS =
  "funded.loan.details/CLIENT_UPDATE_NAME_SUCCESS";

export const FUNDED_LOAN_DETAILS_CLIENT_UPDATE_NAME_FAILURE =
  "funded.loan.details/CLIENT_UPDATE_NAME_FAILURE";
