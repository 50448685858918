import React, { Component, Fragment } from "react";
import { compose } from "recompose";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from "prop-types";

import { styles } from "./simple.menu.styles";

class SimpleMenu extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClickItem = item => {
    const { onClickItem } = this.props;
    this.handleClose();
    onClickItem(item);
  };

  render() {
    const { anchorEl } = this.state;
    // Please consider using renderOptions prop and composition before adding adding more props
    const {
      classes,
      options,
      renderOpenButton,
      renderOptions,
      dataTestId,
      dataTestBuilder
    } = this.props;

    return (
      <Fragment>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          disableAutoFocusItem
          data-test={
            dataTestBuilder && dataTestBuilder(dataTestId, "menu-wrapper")
          }
        >
          {options &&
            options.map((item, i) => (
              <MenuItem
                key={item.label}
                onClick={() => this.handleClickItem(item, i)}
                className={classes.menuItem}
              >
                <Typography
                  className={classes.itemText}
                  data-test={
                    dataTestBuilder &&
                    dataTestBuilder(dataTestId, `menu-option-${i}`)
                  }
                >
                  {item.label}
                </Typography>
              </MenuItem>
            ))}
          {renderOptions && renderOptions(this.handleClickItem)}
        </Menu>
        {renderOpenButton(this.handleClick)}
      </Fragment>
    );
  }
}

SimpleMenu.propTypes = {
  options: PropTypes.array.isRequired,
  renderOpenButton: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  dataTestBuilder: PropTypes.func.isRequired
};

const enhance = compose(withStyles(styles));

export { SimpleMenu };
export default enhance(SimpleMenu);
