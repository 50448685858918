import {
  SECTION_HAS_ERROR,
  SECTION_ERROR_CLEAR,
  ERRORS_CLEAR_ALL
} from "./error.handler.action.types";

const initialState = {};

export default (state = initialState, action) => {
  const { type, error } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  let requestName = null;
  let requestState = null;

  switch (type) {
    case SECTION_HAS_ERROR: {
      const {
        payload: { error, section }
      } = action;
      return {
        ...state,
        [section]: error
      };
    }
    case SECTION_ERROR_CLEAR: {
      const newState = { ...state };
      for (let section in newState) {
        if (section === action.section) {
          delete newState[section];
          return { ...newState };
        }
      }
      return state;
    }
    case ERRORS_CLEAR_ALL:
      return initialState;

    default:
      if (matches) {
        requestName = matches[1];
        requestState = matches[2];
        return {
          ...state,
          // Store errorMessage
          // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
          // else clear errorMessage when receiving GET_TODOS_REQUEST
          [requestName]: requestState === "FAILURE" ? error.messages : null
        };
      }
      return state;
  }
};
