"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REFERRAL_COMPANIES_AND_AGENTS = exports.REFERRAL_SOURCE_AGENTS = exports.REFERRAL_SOURCES = exports.REFERRAL_AGENT = exports.PERMISSIONS = exports.PROFILE = exports.LOG_REPORTS = exports.INFO = exports.EMAIL_SEND = exports.EMAIL = exports.CONSENTS = exports.CREDIT_REPORTS_DOWNLOAD = exports.CREDIT_REPORTS_PULL = exports.CREDIT_REPORTS_SUMMARY = exports.CREDIT_REPORTS = exports.COORDINATOR_PRE_SCREEN = exports.COORDINATOR_EVALUATE = exports.BANK_SERVICE_REQUEST_CODE_RESEND = exports.BANK_SERVICE_REQUEST_CODE = exports.BANK_REPORTS_MANUAL = exports.BANK_REPORT_PDF = exports.BANK_REPORTS_SEARCH = exports.BANK_REPORTS = void 0;

var _api = require("./api.contants");
/**
 * API MAIN ENDPOINTS
 */


var BANK = "".concat(_api.ROOT, "/bank");
var BANK_SERVICE = "".concat(_api.ROOT, "/bank-service");
var COORDINATOR = "".concat(_api.ROOT, "/coordinator"); // BANK

var BANK_REPORTS = "".concat(BANK, "/reports");
exports.BANK_REPORTS = BANK_REPORTS;
var BANK_REPORTS_SEARCH = "".concat(BANK, "/tokens/search");
exports.BANK_REPORTS_SEARCH = BANK_REPORTS_SEARCH;

var BANK_REPORT_PDF = function BANK_REPORT_PDF(requestCode, index) {
  return "".concat(BANK_REPORTS, "/by-request-code/").concat(requestCode, "/pdf/").concat(index);
}; // prettier-ignore


exports.BANK_REPORT_PDF = BANK_REPORT_PDF;

var BANK_REPORTS_MANUAL = function BANK_REPORTS_MANUAL(appId, clientId) {
  return "".concat(BANK_REPORTS, "/").concat(appId, "/").concat(clientId, "/manual");
}; // prettier-ignore


exports.BANK_REPORTS_MANUAL = BANK_REPORTS_MANUAL;
var BANK_SERVICE_REQUEST_CODE = "".concat(BANK_SERVICE, "/request-codes");
exports.BANK_SERVICE_REQUEST_CODE = BANK_SERVICE_REQUEST_CODE;
var BANK_SERVICE_REQUEST_CODE_RESEND = "".concat(BANK_SERVICE, "/request-codes/resend"); // COORDINATOR

exports.BANK_SERVICE_REQUEST_CODE_RESEND = BANK_SERVICE_REQUEST_CODE_RESEND;
var COORDINATOR_EVALUATE = "".concat(COORDINATOR, "/evaluate");
exports.COORDINATOR_EVALUATE = COORDINATOR_EVALUATE;
var COORDINATOR_PRE_SCREEN = "".concat(COORDINATOR, "/run-pre-screens"); // CREDIT REPORTS

exports.COORDINATOR_PRE_SCREEN = COORDINATOR_PRE_SCREEN;
var CREDIT_REPORTS = "".concat(_api.ROOT, "/credit-reports");
exports.CREDIT_REPORTS = CREDIT_REPORTS;

var CREDIT_REPORTS_SUMMARY = function CREDIT_REPORTS_SUMMARY(appId, clientId) {
  return "".concat(CREDIT_REPORTS, "/").concat(appId, "/").concat(clientId, "/summaries");
}; // prettier-ignore


exports.CREDIT_REPORTS_SUMMARY = CREDIT_REPORTS_SUMMARY;

var CREDIT_REPORTS_PULL = function CREDIT_REPORTS_PULL(appId, clientId) {
  return "".concat(CREDIT_REPORTS, "/").concat(appId, "/").concat(clientId);
}; // prettier-ignore


exports.CREDIT_REPORTS_PULL = CREDIT_REPORTS_PULL;

var CREDIT_REPORTS_DOWNLOAD = function CREDIT_REPORTS_DOWNLOAD(appId, clientId) {
  var docType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "pdf";
  return "".concat(CREDIT_REPORTS_PULL(appId, clientId), "/").concat(docType);
}; // prettier-ignore
// Application Integration Service Consents


exports.CREDIT_REPORTS_DOWNLOAD = CREDIT_REPORTS_DOWNLOAD;

var CONSENTS = function CONSENTS(appId, clientId) {
  return "".concat(_api.ROOT, "/app-service/").concat(appId, "/").concat(clientId, "/consents");
}; // prettier-ignore
// EMAIL


exports.CONSENTS = CONSENTS;

var EMAIL = function EMAIL(emailId) {
  return "".concat(_api.ROOT, "/emails/").concat(emailId);
};

exports.EMAIL = EMAIL;

var EMAIL_SEND = function EMAIL_SEND(emailId) {
  return "".concat(EMAIL(emailId), "/send");
}; // INFO


exports.EMAIL_SEND = EMAIL_SEND;
var INFO = "".concat(_api.ROOT, "/info"); // LOG REPORTS

exports.INFO = INFO;
var LOG_REPORTS = "".concat(_api.ROOT, "/log-reports"); // PROFILE

exports.LOG_REPORTS = LOG_REPORTS;
var PROFILE = "".concat(_api.ROOT, "/profile"); // PERMISSIONS

exports.PROFILE = PROFILE;
var PERMISSIONS = "".concat(_api.ROOT, "/auth/permissions"); // REFERRAL SOURCES

exports.PERMISSIONS = PERMISSIONS;

var REFERRAL_AGENT = function REFERRAL_AGENT(id) {
  return "".concat(_api.ROOT, "/referral-sources/companies/").concat(id, "/agents");
};

exports.REFERRAL_AGENT = REFERRAL_AGENT;
var REFERRAL_SOURCES = "".concat(_api.ROOT, "/referral-sources");
exports.REFERRAL_SOURCES = REFERRAL_SOURCES;

var REFERRAL_SOURCE_AGENTS = function REFERRAL_SOURCE_AGENTS(companyId) {
  return "".concat(_api.ROOT, "/referral-sources/companies/").concat(companyId, "/agents");
};

exports.REFERRAL_SOURCE_AGENTS = REFERRAL_SOURCE_AGENTS;
var REFERRAL_COMPANIES_AND_AGENTS = "".concat(_api.ROOT, "/referral-sources/companies-and-agents");
exports.REFERRAL_COMPANIES_AND_AGENTS = REFERRAL_COMPANIES_AND_AGENTS;