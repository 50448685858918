import {
  SECTION_HAS_ERROR,
  SECTION_ERROR_CLEAR,
  ERRORS_CLEAR_ALL
} from "./error.handler.action.types";

export const addSectionError = (section, error) => ({
  type: SECTION_HAS_ERROR,
  payload: { section, error }
});

export const clearSectionError = section => ({
  type: SECTION_ERROR_CLEAR,
  section
});

export const clearAllSectionErrors = () => ({
  type: ERRORS_CLEAR_ALL
});
