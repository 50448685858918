"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ANON_PORTAL_VERIFY_TOKEN = exports.ANON_PORTAL_SEND_VERIFICATION = exports.ANON_PORTAL_SEND_RESET_PASSWORD = exports.ANON_PORTAL_RESET_PASSWORD = exports.ANON_PORTAL_REGISTER = exports.ANON_PORTAL_AUTHENTICATE_FACEBOOK = exports.ANON_PORTAL_AUTHENTICATE = exports.ANON_BANK_SERVICE_REQUEST_CODES_NOTIFY_COMPLETE = exports.ANON_BANK_SERVICE_REQUEST_CODES_ACTIVATE = void 0;

var _api = require("./api.contants");
/**
 * API ANON ENDPOINTS
 */


var ANON = "".concat(_api.ROOT, "/anon");
var ANON_PORTAL = "".concat(ANON, "/portal");
var ANON_BANK_SERVICE_REQUEST_CODES = "".concat(ANON, "/bank-service/request-codes");

var ANON_BANK_SERVICE_REQUEST_CODES_ACTIVATE = function ANON_BANK_SERVICE_REQUEST_CODES_ACTIVATE(token) {
  return "".concat(ANON_BANK_SERVICE_REQUEST_CODES, "/").concat(token, "/activate");
}; // prettier-ignore


exports.ANON_BANK_SERVICE_REQUEST_CODES_ACTIVATE = ANON_BANK_SERVICE_REQUEST_CODES_ACTIVATE;

var ANON_BANK_SERVICE_REQUEST_CODES_NOTIFY_COMPLETE = function ANON_BANK_SERVICE_REQUEST_CODES_NOTIFY_COMPLETE(token) {
  return "".concat(ANON_BANK_SERVICE_REQUEST_CODES, "/").concat(token, "/notify-complete");
}; // prettier-ignore


exports.ANON_BANK_SERVICE_REQUEST_CODES_NOTIFY_COMPLETE = ANON_BANK_SERVICE_REQUEST_CODES_NOTIFY_COMPLETE;
var ANON_PORTAL_AUTHENTICATE = "".concat(ANON_PORTAL, "/authenticate");
exports.ANON_PORTAL_AUTHENTICATE = ANON_PORTAL_AUTHENTICATE;
var ANON_PORTAL_AUTHENTICATE_FACEBOOK = "".concat(ANON_PORTAL, "/authenticate-facebook");
exports.ANON_PORTAL_AUTHENTICATE_FACEBOOK = ANON_PORTAL_AUTHENTICATE_FACEBOOK;
var ANON_PORTAL_REGISTER = "".concat(ANON_PORTAL, "/register");
exports.ANON_PORTAL_REGISTER = ANON_PORTAL_REGISTER;

var ANON_PORTAL_RESET_PASSWORD = function ANON_PORTAL_RESET_PASSWORD(token) {
  return "".concat(ANON_PORTAL, "/reset-password/").concat(token);
};

exports.ANON_PORTAL_RESET_PASSWORD = ANON_PORTAL_RESET_PASSWORD;
var ANON_PORTAL_SEND_RESET_PASSWORD = "".concat(ANON_PORTAL, "/send-reset-password"); // prettier-ignore

exports.ANON_PORTAL_SEND_RESET_PASSWORD = ANON_PORTAL_SEND_RESET_PASSWORD;
var ANON_PORTAL_SEND_VERIFICATION = "".concat(ANON_PORTAL, "/send-verification"); // prettier-ignore

exports.ANON_PORTAL_SEND_VERIFICATION = ANON_PORTAL_SEND_VERIFICATION;

var ANON_PORTAL_VERIFY_TOKEN = function ANON_PORTAL_VERIFY_TOKEN(token) {
  return "".concat(ANON_PORTAL, "/verify/").concat(token);
}; // prettier-ignore


exports.ANON_PORTAL_VERIFY_TOKEN = ANON_PORTAL_VERIFY_TOKEN;