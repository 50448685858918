import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { styles } from "./styles/upload.indicator.styles";

class UploadIndicator extends React.Component {
  state = {
    completed: 0,
    increment: 20
  };

  componentDidMount() {
    this.timer = setInterval(this.progress, 200);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { completed, increment } = this.state;
    const newCompleted =
      completed + increment >= 98 ? 98 : completed + increment;
    this.setState({
      completed: newCompleted,
      increment: increment <= 1 ? 1 : increment - 3
    });
  };

  render() {
    const { classes } = this.props;
    const { completed } = this.state;

    return (
      <div>
        <CircularProgress
          className={classes.progress}
          variant="static"
          value={completed}
          size={60}
        />
      </div>
    );
  }
}

UploadIndicator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UploadIndicator);
