"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.URLQuery = URLQuery;
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _urlQuery = require("./url.query.utils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}
/**
 * URLQuery
 * @param initialUrlParams
 * @param showDefaultUrlParams
 * @param transformIncomingUrlQuery
 * @param transformOutgoingUrlQuery
 * @param render
 * @param location
 * @param history
 * @returns {*}
 * @constructor
 */


function URLQuery(_ref) {
  var initialUrlParams = _ref.initialUrlParams,
      showDefaultUrlParams = _ref.showDefaultUrlParams,
      transformIncomingUrlQuery = _ref.transformIncomingUrlQuery,
      transformOutgoingUrlQuery = _ref.transformOutgoingUrlQuery,
      render = _ref.render,
      location = _ref.location,
      history = _ref.history;
  var urlParams = (0, _urlQuery.getUrlParams)(initialUrlParams, location);
  return render({
    initialUrlParams: initialUrlParams,
    urlParams: transformIncomingUrlQuery(urlParams),
    cacheURLParams: (0, _react.useCallback)(function (query) {
      var transformedQuery = transformOutgoingUrlQuery(query);

      if (!showDefaultUrlParams) {
        transformedQuery = (0, _urlQuery.omitDefaultParams)(transformedQuery, initialUrlParams);
      }

      if (history && history.location) {
        var _history$location = history.location,
            pathname = _history$location.pathname,
            hash = _history$location.hash;
        var encodedQuery = (0, _urlQuery.createUrlQuery)(transformedQuery);
        history.replace("".concat(pathname).concat(hash, "?").concat(encodedQuery));
      }
    }, [history, initialUrlParams, showDefaultUrlParams, transformOutgoingUrlQuery])
  });
}

URLQuery.defaultProps = {
  initialUrlParams: {},
  showDefaultUrlParams: false,
  transformIncomingUrlQuery: function transformIncomingUrlQuery(query) {
    return query;
  },
  transformOutgoingUrlQuery: function transformOutgoingUrlQuery(query) {
    return query;
  },
  location: {}
};
URLQuery.propTypes = {
  initialUrlParams: _propTypes.default.object,
  showDefaultUrlParams: _propTypes.default.bool,
  transformIncomingUrlQuery: _propTypes.default.func,
  transformOutgoingUrlQuery: _propTypes.default.func,
  render: _propTypes.default.func.isRequired,
  location: _propTypes.default.shape({
    search: _propTypes.default.string
  }),
  history: _propTypes.default.shape({
    location: _propTypes.default.object
  })
};
var EnhancedURLQuery = (0, _reactRouterDom.withRouter)(URLQuery);
/**
 * Higher order component to provide and set url params to component
 * @returns {function(*=): function(*): *}
 */

var withURLQuery = function withURLQuery() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (WrappedComponent) {
    return function (props) {
      return _react.default.createElement(EnhancedURLQuery, _extends({}, config, {
        render: function render(urlQueryProps) {
          return WrappedComponent ? _react.default.createElement(WrappedComponent, _extends({}, urlQueryProps, props)) : null;
        }
      }));
    };
  };
};

var _default = withURLQuery;
exports.default = _default;