import findIndex from "lodash/findIndex";

import * as actionTypes from "../action.types/funded.loan.details.action.types";
import * as clientActionTypes from "../action.types/client.content.action.types";

import {
  TOOLTIPS,
  FORM_ACTION_TYPE,
  FORM_CATEGORY,
  FORM_GROUP_TYPE
} from "../../../../core/constants/ui";

const initialState = {
  tabsInfo: [
    {
      value: "LoanTab",
      label: "Loan",
      isDefault: true,
      hasBadge: false
    },
    {
      value: "NotesTab",
      label: "Notes",
      isDefault: false,
      hasBadge: true,
      count: 0
    },
    {
      value: "DocsTab",
      label: "Docs",
      isDefault: false,
      hasBadge: true
    },
    {
      value: "PaymentsTab",
      label: "Payments",
      isDefault: false,
      hasBadge: false
    }
  ],
  statusActions: [],
  infoActions: [
    {
      tooltip: "edit",
      icon: "edit"
    }
  ],
  noteActions: [],
  tabsActions: {
    loanTab: {
      header: []
    },
    notesTab: {
      header: [
        {
          tooltip: `${TOOLTIPS.tabsHeader.addNew}`,
          icon: "add_circle",
          title: "Add New",
          isDisabled: false,
          isSelected: false
        },
        {
          tooltip: `${TOOLTIPS.tabsHeader.fullView}`,
          icon: "view_headline",
          isDisabled: true,
          isSelected: false
        },
        {
          tooltip: `${TOOLTIPS.tabsHeader.splitView}`,
          icon: "vertical_split",
          isDisabled: false,
          isSelected: true
        }
      ],
      contentPanel: [
        {
          tooltip: `${TOOLTIPS.notesExpander.priority}`,
          value: "priority",
          icon: "priority_high",
          isDisabled: false
        }
      ],
      noteDetailexpanders: [
        {
          tooltip: `${TOOLTIPS.notesExpander.priority}`,
          value: "priority",
          icon: "priority_high"
        }
      ],
      addNoteexpanders: [
        {
          tooltip: `${TOOLTIPS.notesExpander.priority}`,
          value: "priority",
          icon: "priority_high",
          isDisabled: false
        }
      ]
    },
    docsTab: {
      header: [
        {
          tooltip: `${TOOLTIPS.tabsHeader.fullView}`,
          icon: "view_headline",
          isDisabled: true,
          isSelected: false
        },
        {
          tooltip: `${TOOLTIPS.tabsHeader.splitView}`,
          icon: "vertical_split",
          isDisabled: false,
          isSelected: true
        }
      ]
    },
    paymentsTab: {
      header: []
    }
  },
  isFetching: true,
  staticCodes: null,
  forms: []
};

/**
 * Whitelist for funded.loan.details.ui
 * that will be persisted to the browser storage
 * @type {string[]}
 */
export const fundedLoanDetailsUIWhitelist = [];

const cleanNoteFormData = (state, action) => {
  const formState = action.formState;
  const formIndex = findIndex(state.forms, formState);
  const form = { ...state.forms[formIndex] };
  form.isEditMode = !form.isEditMode;
  form.data = {};
  return {
    ...state,
    forms: state.forms.map((f, index) => {
      if (index === formIndex) {
        return { ...f, ...form };
      } else {
        return f;
      }
    })
  };
};

/**
 * Reducer for funded.loan.details.ui
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FUNDED_LOAN_DETAILS_LOAN_FETCH:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.FUNDED_LOAN_DETAILS_LOAN_FETCH_SUCCESS: {
      const data = action.payload;
      const clients = data.clients;
      const forms = [];
      if (clients) {
        clients.forEach(client => {
          forms.push({
            clientId: client.id,
            category: FORM_CATEGORY.CLIENTS,
            group: FORM_GROUP_TYPE.EMAIL,
            type: FORM_ACTION_TYPE.EDIT,
            isEditMode: false,
            isFetching: false
          });

          forms.push({
            clientId: client.id,
            category: FORM_CATEGORY.CLIENTS,
            group: FORM_GROUP_TYPE.ADDRESS,
            type: FORM_ACTION_TYPE.EDIT,
            isEditMode: false,
            isFetching: false
          });
        });
      }
      return {
        ...state,
        isFetching: false,
        forms: [...state.forms, ...forms]
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_EDIT_MODE_SWITCH:
      return cleanNoteFormData(state, action);

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_EDIT_MODE_SAVE_DRAFT_DATA: {
      const newForms = JSON.parse(JSON.stringify(state.forms));
      // handle finding or creating index
      let draftFormIndex = findIndex(newForms, action.formState);
      if (draftFormIndex === -1) {
        newForms.push(action.formState);
        draftFormIndex = newForms.length - 1;
      }
      // update
      const draftForm = newForms[draftFormIndex];
      draftForm.data = action.data;
      return {
        ...state,
        forms: newForms
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE: {
      const emailUpdateFormIndex = findIndex(state.forms, action.formState);
      const emailUpdateForm = {
        ...state.forms[emailUpdateFormIndex]
      };
      emailUpdateForm.isFetching = true;
      return {
        ...state,
        forms: state.forms.map((f, index) => {
          if (index === emailUpdateFormIndex) {
            return { ...f, ...emailUpdateForm };
          } else {
            return f;
          }
        })
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE_SUCCESS: {
      const emailFormIndex = findIndex(state.forms, action.formState);
      const emailForm = { ...state.forms[emailFormIndex] };
      emailForm.isEditMode = false;
      emailForm.isFetching = false;
      return {
        ...state,
        forms: state.forms.map((f, index) => {
          if (index === emailFormIndex) {
            return { ...f, ...emailForm };
          } else {
            return f;
          }
        })
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE_FAILURE: {
      const emailFailFormIndex = findIndex(state.forms, action.formState);
      const emailFailForm = { ...state.forms[emailFailFormIndex] };
      emailFailForm.isFetching = false;
      return {
        ...state,
        forms: state.forms.map((f, index) => {
          if (index === emailFailFormIndex) {
            return { ...f, ...emailFailForm };
          } else {
            return f;
          }
        })
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE: {
      const addressUpdateFormIndex = findIndex(state.forms, action.formState);
      const addressUpdateForm = { ...state.forms[addressUpdateFormIndex] };
      addressUpdateForm.isFetching = true;
      return {
        ...state,
        forms: state.forms.map((f, index) => {
          if (index === addressUpdateFormIndex) {
            return { ...f, ...addressUpdateForm };
          } else {
            return f;
          }
        })
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE_SUCCESS: {
      const addressFormSuccessIndex = findIndex(state.forms, action.formState);
      const addressSuccessForm = { ...state.forms[addressFormSuccessIndex] };
      addressSuccessForm.isFetching = false;
      addressSuccessForm.isEditMode = false;
      return {
        ...state,
        forms: state.forms.map((f, index) => {
          if (index === addressFormSuccessIndex) {
            return { ...f, ...addressSuccessForm };
          } else {
            return f;
          }
        })
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE_FAILURE: {
      const addressFormIndex = findIndex(state.forms, action.formState);
      const addressForm = { ...state.forms[addressFormIndex] };
      addressForm.isFetching = false;
      return {
        ...state,
        forms: state.forms.map((f, index) => {
          if (index === addressFormIndex) {
            return { ...f, ...addressForm };
          } else {
            return f;
          }
        })
      };
    }

    case actionTypes.FUNDED_LOAN_DETAILS_LOAN_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    case actionTypes.FUNDED_LOAN_DETAILS_TAB_SWITCH: {
      const switchedTabsInfo = state.tabsInfo.map(tInfo => {
        tInfo.isDefault = tInfo.value === action.tab;
        return tInfo;
      });

      return {
        ...state,
        tabsInfo: switchedTabsInfo
      };
    }
    case actionTypes.FUNDED_LOAN_DETAILS_TAB_STATS_UPDATE: {
      const stats = action.stats;
      const tabsInfo = [];
      state.tabsInfo.forEach((info, index) => {
        let count = 0;
        if (index === 1) count = stats.commentCounts;
        tabsInfo.push({
          value: info.value,
          label: info.label,
          isDefault: info.isDefault,
          hasBadge: info.hasBadge,
          count
        });
      });

      return {
        ...state,
        tabsInfo
      };
    }

    /**
     * Update a specific tab to a new value (ex. note submit)
     */
    case actionTypes.FUNDED_LOAN_DETAILS_TAB_STAT_UPDATE: {
      const tabInfo = action.stat;
      return {
        ...state,
        tabsInfo: state.tabsInfo.map(t => {
          if (t.value === tabInfo.value) t.count = tabInfo.count;
          return t;
        })
      };
    }

    case actionTypes.FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH_SUCCESS: {
      const staticCodes = action.data;
      return {
        ...state,
        staticCodes
      };
    }
    case actionTypes.FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH_FAILURE:
      return {
        ...state,
        staticCodes: null
      };

    case clientActionTypes.FUNDED_LOAN_DETAILS_ADD_FORM_REMOVE: {
      const addFormCopy = [...state.forms];
      const formToRemoveIndex = findIndex(addFormCopy, action.formState);
      addFormCopy.splice(formToRemoveIndex, 1);
      return {
        ...state,
        forms: [...addFormCopy]
      };
    }
    default:
      return state;
  }
};
