"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var styles = function styles() {
  return {
    root: {
      textAlign: "right",
      padding: "10px 10px 0 10px"
    }
  };
};

var _default = styles;
exports.default = _default;