import {
  FUNDED_LOANS_SEARCH,
  FUNDED_LOANS_CLEAR,
  FUNDED_LOANS_SEARCH_SUCCESS,
  FUNDED_LOANS_SEARCH_FAILURE,
  FUNDED_LOANS_SEARCH_TABLE_SETTINGS_UPDATE
} from "../funded.loans.action.types";

/**
 * FUNDED LOANS UI STATE REDUCER
 * @author Ryan Rivera
 * @type {{results: Array}}
 */
const initialState = {
  isSearching: false,
  hasError: false,
  errorMessage: "",
  errorMessageType: "info",
  searchCleared: false,
  hiddenColumns: []
};

/**
 * Whitelist for funded.loans.ui
 * that will be persisted to the browser storage
 * @type {string[]}
 */
export const fundedLoansUIWhitelist = ["hiddenColumns"];

/**
 * Reducer for funded.loans.ui
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case FUNDED_LOANS_SEARCH:
      return {
        ...state,
        isSearching: true
      };
    case FUNDED_LOANS_SEARCH_SUCCESS:
      return {
        ...state,
        isSearching: false,
        hasError: false,
        errorMessage: "",
        searchCleared: false
      };
    case FUNDED_LOANS_SEARCH_FAILURE:
      return {
        ...state,
        isSearching: false,
        hasError: true,
        errorMessage: action.error.message,
        errorMessageType: action.error.type || "error",
        searchCleared: false
      };
    case FUNDED_LOANS_CLEAR:
      return {
        ...state,
        isSearching: false,
        hasError: false,
        errorMessage: "",
        errorMessageType: "info",
        searchCleared: true
      };
    case FUNDED_LOANS_SEARCH_TABLE_SETTINGS_UPDATE:
      return {
        ...state,
        ...action.settings
      };
    default:
      return state;
  }
};
