// ADD BANK ACCOUNT
const LOAN_APP = "loan.application";

export const LOAN_APPLICATION_BANK_ACCOUNT_ADD = `${LOAN_APP}/BANK_ACCOUNT_ADD`;
export const LOAN_APPLICATION_BANK_ACCOUNT_ADD_SUCCESS = `${LOAN_APP}/BANK_ACCOUNT_ADD_SUCCESS`;
export const LOAN_APPLICATION_BANK_ACCOUNT_ADD_FAILURE = `${LOAN_APP}/BANK_ACCOUNT_ADD_FAILURE`;
export const LOAN_APPLICATION_BANK_ACCOUNT_CACHE_ADD_FORMS = `${LOAN_APP}/LOAN_APPLICATION_BANK_ACCOUNT_CACHE_ADD_FORMS`;
export const LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_ADD_FORMS = `${LOAN_APP}/LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_ADD_FORMS`;

// DELETE BANK ACCOUNT
export const LOAN_APPLICATION_BANK_ACCOUNT_DELETE = `${LOAN_APP}/BANK_ACCOUNT_DELETE`;
export const LOAN_APPLICATION_BANK_ACCOUNT_DELETE_SUCCESS = `${LOAN_APP}/BANK_ACCOUNT_DELETE_SUCCESS`;
export const LOAN_APPLICATION_BANK_ACCOUNT_DELETE_FAILURE = `${LOAN_APP}/BANK_ACCOUNT_DELETE_FAILURE`;

// EDIT BANK ACCOUNT
export const LOAN_APPLICATION_BANK_ACCOUNT_CACHE_EDIT_FORMS = `${LOAN_APP}/BANK_ACCOUNT_CACHE_EDIT_FORMS`;
export const LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_EDIT_FORM = `${LOAN_APP}/BANK_ACCOUNT_CANCEL_EDIT_FORM`;
export const LOAN_APPLICATION_BANK_ACCOUNT_CACHE_FORM_VALUES = `${LOAN_APP}/BANK_ACCOUNT_CACHE_FORM_VALUES`;
export const LOAN_APPLICATION_BANK_ACCOUNT_UPDATE = `${LOAN_APP}/BANK_ACCOUNT_UPDATE`;
export const LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_SUCCESS = `${LOAN_APP}/BANK_ACCOUNT_UPDATE_SUCCESS`;
export const LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_FAILURE = `${LOAN_APP}/BANK_ACCOUNT_UPDATE_FAILURE`;
