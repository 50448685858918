import PropTypes from "prop-types";

/**
 * AUTH-USER MODEL
 */

// UPDATE AUTH-USER  - DEFAULT PROPS
export const defaultProps = {
  dateOfBirth: "",
  emailAddress: "",
  firstName: "",
  lastName: "",
  middleName: "",
  phoneNumber: {
    ext: "",
    flags: [],
    number: "",
    type: "",
    primary: true
  },
  relationship: ""
};

// UPDATE AUTH-USER - SCHEMA
export const schema = {
  dateOfBirth: PropTypes.string,
  emailAddress: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  phoneNumber: PropTypes.object,
  relationship: PropTypes.string
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-auth-user-info-update";
