import React from "react";
import { withI18n } from "@lingui/react";
import log from "loglevel";
import { ErrorMessage as Error } from "../../shared/ErrorMessage";
import { ERRORS } from "./error.handler.constants";
import { HTTP_SUB_ERROR_CODES } from "../messenger/messenger.constants";

function createSubErrorMsgObj() {
  const msgObj = {};
  for (const key in HTTP_SUB_ERROR_CODES) {
    if (HTTP_SUB_ERROR_CODES.hasOwnProperty(key)) {
      msgObj[key] = { message: HTTP_SUB_ERROR_CODES[key] };
    }
  }
}

function ErrorPage({ error, i18n, skipSubErrorCodes }) {
  const logger = log.getLogger("AppError");
  logger.debug(error);

  const subErrorCodes = createSubErrorMsgObj();

  const getSubErrorCodes = () => {
    let responseErrors = [];
    if (error.related && error.related.length > 0) {
      const subErrors = error.related;

      subErrors.forEach(e => {
        if (e.code && subErrorCodes[e.code.toString()]) {
          responseErrors.push(e);
        }
      });
    }
    return responseErrors;
  };

  const generateErrorInfo = () => {
    if (error && error.status) {
      const httpErrorStatus = error.status.toString();
      // 400 level error messages handling
      if (error.status >= 400 && error.status < 500) {
        const errorObject = ERRORS[httpErrorStatus]
          ? ERRORS[httpErrorStatus]
          : ERRORS["400Default"];

        if (skipSubErrorCodes) {
          return errorObject;
        } else {
          const subErrors = getSubErrorCodes();
          return { ...errorObject, subErrors, status: httpErrorStatus };
        }
      }

      // 500 level error messages handling
      if (error.status >= 500 && error.status < 600) {
        const errorObject = ERRORS[httpErrorStatus]
          ? ERRORS[httpErrorStatus]
          : ERRORS["500Default"];
        return {
          ...errorObject,
          status: httpErrorStatus,
          tryAgain: i18n.t`Please try again in 30 seconds.`
        };
      }
    }
    // default server error info
    return {
      ...ERRORS["-1"],
      tryAgain: i18n.t`Please try again in 30 seconds.`
    };
  };

  const errorInfo = generateErrorInfo();
  const { status, icon, message, title, tryAgain } = errorInfo;
  const errorString = status ? i18n.t`Error` + `: ${status}` : null;

  return (
    <Error
      title={i18n._(title)}
      message={i18n._(message)}
      icon={icon}
      tryAgain={tryAgain}
      error={errorString}
      links={status === "400" ? [{ label: i18n.t`Modules`, path: "/" }] : null}
    />
  );
}

export default withI18n()(ErrorPage);
