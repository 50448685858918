"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _logo = _interopRequireDefault(require("./img/logo1-01.svg"));

var _logo2 = _interopRequireDefault(require("./img/logo2-01.svg"));

var _splitBackground = _interopRequireDefault(require("./img/splitBackground.png"));

var _splitBgGradient = _interopRequireDefault(require("./img/splitBgGradient.png"));

var _wave_small = _interopRequireDefault(require("./img/wave_small.svg"));

var _wave_medium = _interopRequireDefault(require("./img/wave_medium.svg"));

var _wave_large = _interopRequireDefault(require("./img/wave_large.svg"));

var _dl_logo = _interopRequireDefault(require("./img/dl_logo.png"));

var _piggyBank = _interopRequireDefault(require("./img/piggyBank.png"));

var _bulb = _interopRequireDefault(require("./img/bulb.PNG"));

var _financialLiteracy = _interopRequireDefault(require("./img/financial-literacy.png"));

var FONT_FAMILY = "Lato, Helvetica, Arial, sans-serif";
var palette = {
  contrastText: "#FFF",
  gradient: "linear-gradient(90deg, #18B2E7 0%, #6071FF 100%)",
  primary: {
    light: "#3A93F2",
    main: "#2AACE2",
    dark: "#333E50"
  },
  secondary: {
    dark: "#2F487E",
    main: "#6071FF",
    light: "#96E4FF"
  },
  error: {
    light: "#FF6838",
    main: "#FF2901",
    dark: "#C30000"
  },
  warning: {
    light: "#FFFF53",
    main: "#FFD800",
    dark: "#C7A700"
  },
  success: {
    light: "#98ff4d",
    main: "#61CF01",
    dark: "#1e9d00"
  },
  darkPurple: {
    50: "#EAECF2",
    100: "#D5DAE5",
    200: "#C0C8D8",
    300: "#ACB6CB",
    400: "#97A3BE",
    500: "#8291B2",
    600: "#6D7EA4",
    700: "#596D98",
    800: "#435A8A",
    900: "#2F487E"
  },
  purple: {
    50: "#EEF0F7",
    100: "#DEE3F1",
    200: "#CCD4E9",
    300: "#BCC7E2",
    400: "#ABB8DB",
    500: "#9BAAD4",
    600: "#8A9CCC",
    700: "#798EC5",
    800: "#6880BE",
    900: "#5872B7"
  },
  lightGrey: {
    50: "#F9F9F9",
    100: "#F3F4F4",
    200: "#EDEEEE",
    300: "#E7E9E9",
    400: "#E1E3E4",
    500: "#DCDDDF",
    600: "#D5D7D9",
    700: "#D0D2D4",
    800: "#C9CCCE",
    900: "#C4C7C9"
  },
  darkGrey: {
    50: "#EFEFEF",
    100: "#DFE0DF",
    200: "#CFCFCF",
    300: "#BFC0BF",
    400: "#AFB0AF",
    500: "#9FA19F",
    600: "#8F918F",
    700: "#7F817F",
    800: "#6F716F",
    900: "#5F625F"
  },
  charcoal: {
    50: "#EAEBED",
    100: "#D6D8DC",
    200: "#C1C5CA",
    300: "#ADB2B9",
    400: "#999EA7",
    500: "#858B96",
    600: "#707784",
    700: "#5C6573",
    800: "#475161",
    900: "#333E50"
  },
  lightPurple: {
    50: "#EFF0FF",
    100: "#DFE3FF",
    200: "#CFD4FF",
    300: "#BFC6FF",
    400: "#AFB8FF",
    500: "#A0AAFF",
    600: "#8F9BFF",
    700: "#808DFF",
    800: "#6F7FFF",
    900: "#6071FF"
  },
  deepBlue: {
    50: "#EBF4FD",
    100: "#D8E9FC",
    200: "#C3DEFB",
    300: "#B0D4FA",
    400: "#9CC9F8",
    500: "#89BEF7",
    600: "#75B3F5",
    700: "#61A9F5",
    800: "#4D9DF3",
    900: "#3A93F2"
  },
  lightBlue: {
    50: "#F4FCFF",
    100: "#EAFAFF",
    200: "#DFF6FF",
    300: "#D5F4FF",
    400: "#CAF1FF",
    500: "#C0EFFF",
    600: "#B5ECFF",
    700: "#ABE9FF",
    800: "#A0E6FF",
    900: "#96E4FF"
  },
  darkBlue: {
    50: "#E5F4F9",
    100: "#CCEBF5",
    200: "#B2E0EF",
    300: "#99D6EB",
    400: "#7FCCE5",
    500: "#66C2E0",
    600: "#4CB7DB",
    700: "#33ADD6",
    800: "#19A3D1",
    900: "#0099CC"
  },
  blue: {
    50: "#E9F6FC",
    100: "#D4EEF9",
    200: "#BFE6F6",
    300: "#AADEF3",
    400: "#94D5F0",
    500: "#7FCDEE",
    600: "#69C4EA",
    700: "#55BDE8",
    800: "#3FB4E4",
    900: "#2AACE2"
  }
};
var _default = {
  palette: palette,
  button: {
    height: "60px",
    large: {
      width: "275px"
    },
    medium: {
      width: "200px"
    },
    small: {
      width: "160px"
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: FONT_FAMILY,
    fontSize: 16,
    h1: {
      fontSize: "3em",
      fontWeight: "bold",
      lineHeight: "1.33em"
    },
    h2: {
      fontSize: "2em",
      fontWeight: "bold",
      lineHeight: "1.5em"
    },
    h3: {
      fontSize: "1.5em",
      fontWeight: "bold",
      lineHeight: "1.67em"
    },
    h4: {
      fontSize: "1.25em",
      fontWeight: "bold",
      lineHeight: "1.6em"
    },
    h5: {
      fontSize: "1em",
      fontWeight: "bold",
      lineHeight: "1.5em"
    },
    h6: {
      fontSize: "0.75em",
      fontWeight: "bold",
      lineHeight: "1.33em"
    },
    p1: {
      fontSize: "24px",
      lineHeight: "29px"
    },
    p2: {
      fontSize: "16px",
      lineHeight: "19px"
    },
    p3: {
      fontSize: "14px",
      lineHeight: "16px"
    },
    p4: {
      fontSize: "12px",
      lineHeight: "15px"
    },
    p5: {
      fontSize: "10px",
      lineHeight: "13px"
    },
    linkText: {
      display: "inline-flex",
      justifyContent: "space-around",
      flexDirection: "row",
      alignItems: "baseline",
      textDecoration: "none",
      fontWeight: "bold",
      letterSpacing: "1.08px",
      fontSize: "10px",
      lineHeight: "17px",
      textTransform: "uppercase",
      marginLeft: "5px",
      marginRight: "5px",
      color: palette.primary.main,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
      }
    }
  },
  shape: {
    borderRadius: 2
  },
  sidebarBackground: palette.gradient,
  logo: {
    backgroundImage: "url(".concat(_logo.default, ")"),
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center"
  },
  altLogo: {
    backgroundImage: "url(".concat(_logo2.default, ")"),
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center"
  },
  images: {
    piggyBank: "url(".concat(_piggyBank.default, ")"),
    lightBulb: "url(".concat(_bulb.default, ")"),
    financialLiteracy: "url(".concat(_financialLiteracy.default, ")"),
    splitBackground: "url(".concat(_splitBackground.default, ")"),
    splitBackgroundGradient: "url(".concat(_splitBgGradient.default, ")"),
    waveBg: {
      zIndex: -1,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundRepeat: "no-repeat",
      position: "absolute",
      width: "100%",
      height: "55%",
      overflowY: "hidden",
      overflowX: "hidden"
    },
    waveSmall: "url(".concat(_wave_small.default, ")"),
    waveMedium: "url(".concat(_wave_medium.default, ")"),
    waveLarge: "url(".concat(_wave_large.default, ")"),
    dlLogo: "url(".concat(_dl_logo.default, ")")
  },
  layout: {
    well: {
      xs: "320px",
      sm: "504px",
      md: "728px",
      lg: "1135px"
    }
  },
  icon: {
    icon20: {
      width: "20px",
      height: "20px"
    },
    icon16: {
      width: "16px",
      height: "16px"
    },
    icon16Static: {
      width: "16px",
      height: "16px"
    }
  }
};
exports.default = _default;