import { actionTypes } from "./referral.sources.action.types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REFERRAL_SOURCES_FETCH_SUCCESS:
      return {
        ...action.payload
      };
    case actionTypes.REFERRAL_SOURCE_AGENTS_FETCH_SUCCESS:
      return {
        results: [...(state.results ? state.results : []), ...action.data]
      };
    default:
      return state;
  }
};
