import React, { Component } from "react";
import PropTypes from "prop-types";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// LOCAL DEPENDENCIES
import components from "./components/nested.menu.index";

// LOCAL STYLES
import styles from "./styles/nested.menu.styles";

/**
 * NESTED_MENU COMPONENT
 * @author rrive
 */
class NestedMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.anchorEl = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { open } = this.state;
    const { onCommit } = this.props;
    if (
      !open &&
      typeof prevState.open !== "undefined" &&
      prevState.open !== open &&
      onCommit
    ) {
      onCommit();
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getTriggerLabel = menu => {
    return menu && menu.label ? menu.label : "";
  };

  renderTrigger = () => {
    const {
      classes,
      trigger,
      triggerIconName,
      menu,
      open,
      dataTestId
    } = this.props;

    const label = this.getTriggerLabel(menu);

    return trigger ? (
      trigger({
        "aria-owns": open ? "menu" : null,
        "aria-haspopup": "true",
        onClick: this.handleOpen,
        buttonRef: this.anchorEl
      })
    ) : (
      <Button
        disableRipple
        variant="text"
        aria-label={label}
        aria-owns={open ? "menu" : null}
        aria-haspopup="true"
        onClick={this.handleOpen}
        buttonRef={this.anchorEl}
        classes={{ root: classes.triggerButtonRoot }}
        data-test={dataTestId.button}
      >
        <Icon>{triggerIconName ? triggerIconName : "more_vert"}</Icon>
        <Typography classes={{ root: classes.triggerLabelRoot }}>
          {label}
        </Typography>
      </Button>
    );
  };

  render() {
    const {
      classes,
      placement,
      onChange,
      menu,
      dataTestId,
      actions
    } = this.props;
    const { open } = this.state;
    const WrappedComponent = components(menu.type);
    return (
      <span className={classes.root}>
        {this.renderTrigger()}
        {!menu.menuItems ? null : (
          <Popper
            id={open ? "menu" : null}
            open={open}
            anchorEl={this.anchorEl.current}
            disablePortal
            placement={placement}
            modifiers={{
              flip: {
                enabled: true
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: "scrollParent"
              }
            }}
            data-test={dataTestId.popper}
          >
            <Paper classes={{ root: classes.root }}>
              <ClickAwayListener onClickAway={this.handleClose}>
                <WrappedComponent
                  containerId={menu.id}
                  close={this.handleClose}
                  type={menu && menu.type ? menu.type : "SELECT"}
                  menuItems={menu.menuItems}
                  handleMenuItemClick={onChange}
                  dataTestId={dataTestId}
                  actions={actions}
                />
              </ClickAwayListener>
            </Paper>
          </Popper>
        )}
      </span>
    );
  }
}

NestedMenu.defaultProps = {
  placement: "bottom-start",
  dataTestId: {
    settings: {
      root: "",
      button: "",
      popper: "",
      menu: "",
      menuItem: "",
      checkbox: ""
    }
  }
};

NestedMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  menu: PropTypes.object.isRequired,
  onCommit: PropTypes.func,
  onChange: PropTypes.func,
  trigger: PropTypes.func,
  triggerIconName: PropTypes.string,
  placement: PropTypes.string,
  dataTestId: PropTypes.shape({
    settings: PropTypes.objectOf(PropTypes.string)
  })
};

export default withStyles(styles)(NestedMenu);
