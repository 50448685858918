import * as agentPortalClient from "./data/agent.portal.clients";
import * as agentPortalApplication from "./data/agent.portal.applications";
import * as application from "./data/applications";
import * as client from "./data/clients";
import * as creditReport from "./data/credit.reports";
import * as referralSource from "./data/referral.sources";
import * as coordinator from "./data/coordinator";
import * as info from "./data/info";
import * as bankReports from "./data/bank.reports";
import * as notes from "./data/notes";
import * as appServiceConsents from "./data/app.service.consents";

import * as errorHandler from "./error.handler";
import * as loading from "./loading";
import * as messenger from "./messenger";

import authSaga from "./auth/auth.saga";
import errorHandlerSaga from "./messenger/messenger.saga";
import documentSaga from "./data/documents/document.saga";
import consentSaga from "./data/app.service.consents/app.service.consents.saga";
import creditReportSaga from "./data/credit.reports/credit.report.sagas";

/**
 * CORE BINDINGS: SERVICES SAGA
 */
export const servicesSaga = [
  ...application.saga,
  ...authSaga,
  ...errorHandlerSaga,
  ...documentSaga,
  ...info.saga,
  ...notes.saga,
  ...bankReports.saga,
  ...coordinator.saga,
  ...agentPortalClient.saga,
  ...agentPortalApplication.saga,
  ...referralSource.saga,
  ...consentSaga,
  ...creditReportSaga
];

/**
 * CORE BINDINGS: SERVICES REDUCERS
 */
export { default as messengerReducer } from "./messenger/messenger.reducer";
export { persistedSessionReducer as sessionReducer } from "./services.reducers";
export { default as trackerReducer } from "./ui.tracker/tracker.reducer";
export { default as documentReducer } from "./data/documents/document.reducer";
export {
  default as creditReportReducer
} from "./data/credit.reports/credit.report.reducer";

/**
 * SERVICE API
 * All integrations should access the services
 * through what's exported from the services index
 */
export {
  application,
  agentPortalClient,
  agentPortalApplication,
  appServiceConsents,
  bankReports,
  client,
  coordinator,
  creditReport,
  errorHandler,
  info,
  loading,
  messenger,
  notes,
  referralSource
};
