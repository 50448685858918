"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.normalize = normalize;
exports.sortByCreatedDate = sortByCreatedDate;
exports.nullify = nullify;
exports.transformReceivedCollection = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _defaultsDeep = _interopRequireDefault(require("lodash/defaultsDeep"));
/**
 * Normalize data to insert missing fields
 * and checks for data type
 * @param data
 * @param defaultProps
 * @param schema
 * @param modelName
 */


function normalize(data, defaultProps, schema, modelName) {
  var clonedData = JSON.parse(JSON.stringify(data)); // Inserts default properties coming defaultProps

  var normalizedData = (0, _defaultsDeep.default)(clonedData, defaultProps); // Captures data type changes from service
  // Only for development so it does not slow production builds or tests

  if (process.env.NODE_ENV === "development") {
    _propTypes.default.checkPropTypes(schema, normalizedData, "property", "".concat(modelName, " Data"));
  }

  return normalizedData;
}
/**
 * sortByCreatedDate
 * @param a
 * @param b
 * @returns {number}
 */


function sortByCreatedDate(a, b) {
  if (a.createdAt > b.createdAt) {
    return -1;
  } else if (a.createdAt < b.createdAt) {
    return 1;
  } else {
    return a.subject <= b.subject ? -1 : 1;
  }
}
/**
 * transformReceived - data as collection
 * @param rawData {object} // coming from the service
 * @param props {object}   // default props
 * @param schema {object}  // data schema of each item of the collection
 * @param modelName "string"
 */


var transformReceivedCollection = function transformReceivedCollection(rawData, props, schema, modelName) {
  var collectionData = rawData.results;

  if (collectionData && typeof collectionData !== "undefined") {
    var data = collectionData.map(function (item) {
      return toDataCollection(item, props, schema, modelName);
    });
    return (0, _objectSpread2.default)({}, rawData, {
      results: data
    });
  }

  if (!collectionData && Array.isArray(rawData)) {
    var raw = rawData.map(function (item) {
      return toDataCollection(item, props, schema, modelName);
    });
    return {
      results: raw
    };
  }

  return {
    results: [(0, _objectSpread2.default)({}, rawData)]
  };
}; // normalize (validate) each item of the collection


exports.transformReceivedCollection = transformReceivedCollection;

function toDataCollection(data, props, schema, modelName) {
  return normalize((0, _objectSpread2.default)({}, data), props, schema, modelName);
}

function nullify(value) {
  return value === "" || typeof value === "undefined" ? null : value;
}