import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { getToken, getProfile, getSession } from "./auth.selectors";
import Login from "../../pages/login/login.index";
import * as authActions from "./auth.actions";
import {
  AUDITOR_USER,
  CREATE,
  DELETE,
  EDIT,
  READ,
  SEARCH,
  SUPER_USER,
  UPDATE,
  VIEW
} from "./auth.constants";
import { PERMISSIONS_MAP, READ_ONLY_ACTIONS } from "./auth.permissions.map";

/**
 * isAllowed
 *
 * Main logic for permissions
 *
 * Roles values include:
 * [] - user has no permissions
 * ["USER"] - user has permissions
 * ["USER", "AUDITOR_USER"] - user has permissions and isAuditor
 * ["USER", "SUPER_USER"] - user has permissions and isSuperUser
 * ["USER", "AUDITOR_USER", "SUPER_USER"] - user has permissions and isAuditor and isSuperUser
 *
 * Note: There's probably slim chance for a user being an auditorUser and isSuperUser, but since
 * the API define fields for superUser and auditorUser separately, there could be that case for handling it.
 *
 * @param action
 * @param entityMap
 * @param session
 * @returns {*}
 */
export const isAllowed = (action, entityMap, session) => {
  const { permissions, roles } = session;

  const permissionsArray = permissions || [];

  // If super user return true immediately
  if (includes(roles, SUPER_USER)) {
    return true;
  }

  // If auditor user and action is not "view" type return false immediately
  if (includes(roles, AUDITOR_USER) && !includes(READ_ONLY_ACTIONS, action)) {
    return false;
  }

  if (permissionsArray.length === 0) {
    return false;
  }

  // If there are no defined permissions return false immediately
  if (!action || !entityMap || (!isNil(roles) && isEmpty(roles))) {
    return false;
  }

  const entityAction = entityMap && entityMap[action];
  return !isNil(entityAction) && includes(permissionsArray, entityAction);
};

const AuthContext = React.createContext({ user: {} });

export const withAuth = WrappedComponent => props => (
  <AuthContext.Consumer>
    {authProps => {
      return <WrappedComponent {...props} {...authProps} />;
    }}
  </AuthContext.Consumer>
);

export const fallback = FallbackComponent => {
  return FallbackComponent ? FallbackComponent : null;
};

/**
 * Check whether the user is authenticated
 * @returns {boolean}
 */
export const isUserAuthenticated = token => !!token;

/**
 * getAuthActions
 * @param session
 * @param authActions
 * @param isAuthenticated
 * @returns {{canView: (function(*=): *), can: (function(*=, *=): *), canUpdate: (function(*=): *), canRead: (function(*=): *), canEdit: (function(*=): *), canDelete: (function(*=): *), canCreate: (function(*=): *), canSearch: (function(*=): *), logOut: logoutUser}}
 */
export const getAuthActions = (session, authActions = {}, isAuthenticated) => {
  const { logoutUser } = authActions;

  // The user is truly allowed
  // if the user is authenticated
  // and is authorized
  const delegateIsAllowed = (action, entity) =>
    isAuthenticated &&
    isAllowed.call(this, action, PERMISSIONS_MAP[entity], session);

  return {
    can: (action, entity) => delegateIsAllowed(action, entity),
    canSearch: entity => delegateIsAllowed(SEARCH, entity),
    canCreate: entity => delegateIsAllowed(CREATE, entity),
    canRead: entity => delegateIsAllowed(READ, entity),
    canUpdate: entity => delegateIsAllowed(UPDATE, entity),
    canDelete: entity => delegateIsAllowed(DELETE, entity),
    canView: entity => delegateIsAllowed(VIEW, entity),
    canEdit: entity => delegateIsAllowed(EDIT, entity),
    filterByAccess: (list, user) =>
      list.filter(l => l.hasOwnProperty("hasAccess") && l.hasAccess(user)),
    logOut: logoutUser
  };
};

/**
 * getWrappedComponent
 * @param Component
 * @param FallbackComponent
 * @param hasAccess
 * @param user
 * @returns {*}
 */
export const getWrappedComponent = (
  Component,
  FallbackComponent,
  hasAccess,
  user
) => {
  return isNil(hasAccess) || (hasAccess && hasAccess(user))
    ? Component
    : fallback(FallbackComponent);
};

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.isUserAuthenticated = isUserAuthenticated;
    this.getAuthActions = getAuthActions;
    this.getWrappedComponent = getWrappedComponent;
  }

  componentDidMount() {
    const { token, authActions } = this.props;
    if (this.isUserAuthenticated(token)) {
      authActions.fetchProfile();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { token, authActions } = this.props;
    if (
      !prevProps.token &&
      prevProps.token !== token &&
      this.isUserAuthenticated(token)
    ) {
      authActions.fetchProfile();
    }
  }

  /**
   * If the user is not authenticated, will redirect user to login page.
   * If the user is not authorized, will redirect user to dashboard page
   * @returns {*}
   */
  render() {
    const {
      Component,
      profile,
      session,
      authActions,
      fallback: FallbackComponent = Login,
      hasAccess,
      token,
      ...rest
    } = this.props;

    const isAuthenticated = this.isUserAuthenticated(token);
    const userActions = this.getAuthActions(
      session,
      authActions,
      isAuthenticated
    );

    const user = { profile, ...userActions };

    const WrappedComponent = this.getWrappedComponent(
      Component,
      FallbackComponent,
      hasAccess,
      user
    );

    return isAuthenticated ? (
      <AuthContext.Provider value={{ user }}>
        <WrappedComponent {...rest} />
      </AuthContext.Provider>
    ) : (
      <Login />
    );
  }
}

const mapStateToProps = state => ({
  token: getToken(state),
  profile: getProfile(state),
  session: getSession(state)
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
