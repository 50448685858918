import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { withI18n } from "@lingui/react";

import { PrivateRoute } from "../services/auth";
import { pageModules } from "./pages.modules";

/**
 * ROUTER
 * all persisted application's routes
 * this component show all the existing routes in the app
 *
 * Use private route if the route requires authentication
 * The module props is required in private route if the route requires authorization
 */
const PagesRouter = ({ i18n, history }) => (
  <ConnectedRouter history={history}>
    <Switch>
      {pageModules.map((route, i) => {
        const { isPrivate = false, label, ...rest } = route;
        const RouteComponent = isPrivate ? PrivateRoute : Route;
        return <RouteComponent key={i} label={i18n._(label)} {...rest} />;
      })}
    </Switch>
  </ConnectedRouter>
);

export default withI18n()(PagesRouter);
