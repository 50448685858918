/**
 * Get initial data for the specific loan (excluding notes, docs and payments info)
 */
export const FUNDED_LOAN_DETAILS_LOAN_FETCH = "funded.loan.details/LOAN_FETCH";
export const FUNDED_LOAN_DETAILS_LOAN_FETCH_SUCCESS =
  "funded.loan.details/LOAN_FETCH_SUCCESS";
export const FUNDED_LOAN_DETAILS_LOAN_FETCH_FAILURE =
  "funded.loan.details/LOAN_FETCH_FAILURE";
export const FUNDED_LOAN_DETAILS_TAB_STATS_UPDATE =
  "funded.loan.details/TAB_STATS_UPDATE";
export const FUNDED_LOAN_DETAILS_TAB_STAT_UPDATE =
  "funded.loan.details/TAB_STAT_UPDATE";
export const FUNDED_LOAN_DETAILS_TAB_SWITCH = "funded.loan.details/TABS_SWITCH";

// FETCH STATIC CODES
export const FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH =
  "funded.loan.details/STATIC_CODES_FETCH";
export const FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH_SUCCESS =
  "funded.loan.details/STATIC_CODES_FETCH_SUCCESS";
export const FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH_FAILURE =
  "funded.loan.details/STATIC_CODES_FETCH_FAILURE";
