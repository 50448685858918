import React from "react";
import { connect } from "react-redux";
import omit from "lodash/omit";
import { getForm } from "../../services/forms/forms.selectors";
import {
  cacheForm,
  cacheFormValues,
  deleteCachedForm,
  isFetchingForm
} from "../../services/forms/forms.actions";

function withFormState(WrappedComponent) {
  class WithFormState extends React.Component {
    cacheForm = data => {
      const { formId, cacheForm } = this.props;
      cacheForm(formId, data);
    };

    cacheFormValues = values => {
      const { formId, cacheFormValues } = this.props;
      cacheFormValues(formId, values);
    };

    deleteCachedForm = () => {
      const { formId, deleteCachedForm } = this.props;
      deleteCachedForm(formId);
    };

    updateIsFetchingFormStatus = value => {
      const { formId, isFetchingForm } = this.props;
      isFetchingForm(formId, value);
    };

    getFromErrorStack = () => {
      const { cachedForm } = this.props;
      if (cachedForm && cachedForm.errors) {
        const { errors } = cachedForm;
        return errors.map(e => e.message);
      }
      return [];
    };

    render() {
      const { cachedForm } = this.props;

      const passThrough = omit(this.props, [
        "cacheForm",
        "cacheFormValues",
        "deleteCachedForm",
        "isFetchingForm"
      ]);

      return (
        <WrappedComponent
          cacheForm={this.cacheForm}
          cacheFormValues={this.cacheFormValues}
          cachedForm={cachedForm}
          deleteCachedForm={this.deleteCachedForm}
          updateIsFetchingFormStatus={this.updateIsFetchingFormStatus}
          errorStack={this.getFromErrorStack()}
          {...passThrough}
        />
      );
    }
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      cachedForm: getForm(state, ownProps.formId)
    };
  };

  const WithFormStateConnected = connect(
    mapStateToProps,
    { cacheForm, cacheFormValues, deleteCachedForm, isFetchingForm }
  )(WithFormState);

  return WithFormStateConnected;
}

export default withFormState;
