"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "middleware", {
  enumerable: true,
  get: function get() {
    return _mixpanel.default;
  }
});
Object.defineProperty(exports, "initialize", {
  enumerable: true,
  get: function get() {
    return _mixpanel2.initialize;
  }
});
Object.defineProperty(exports, "identify", {
  enumerable: true,
  get: function get() {
    return _mixpanel3.identify;
  }
});
Object.defineProperty(exports, "register", {
  enumerable: true,
  get: function get() {
    return _mixpanel3.register;
  }
});
Object.defineProperty(exports, "track", {
  enumerable: true,
  get: function get() {
    return _mixpanel3.track;
  }
});

var _mixpanel = _interopRequireDefault(require("./mixpanel.middleware"));

var _mixpanel2 = require("./mixpanel.min");

var _mixpanel3 = require("./mixpanel.actions");