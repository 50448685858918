import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles/header.menu.styles";

const generateMenuItems = (items, classes, emptyMenuListText, dataTestId) => {
  const hasMenuItems = items && items.length > 0;
  const emptyListArray = [{ label: emptyMenuListText }];
  const menuItems = hasMenuItems ? items : emptyListArray;
  return menuItems.map((item, index) => {
    const testId = index === 0 ? dataTestId.modulesLa : dataTestId.modulesFl;
    const isDivider = item.type && item.type === "DIVIDER";

    return isDivider ? (
      <hr key={index} />
    ) : (
      <MenuItem
        data-test={testId}
        key={index}
        className={classes.menuItem}
        onClick={item.onClick ? item.onClick : null}
      >
        {item.path && (
          <Link to={item.path} className={classes.menuItemLink}>
            {item.label}
          </Link>
        )}
        {!item.path && item.label}
      </MenuItem>
    );
  });
};

/**
 * HEADER MENU
 * this components shows the menu in the header toolbar.
 * @author Ehsan | Stanley
 * @version 0.2.0
 * @param props
 * @returns {*}
 * @constructor
 */
function HeaderMenuComponent({
  menuList,
  classes,
  dataTestId,
  anchorEl,
  menuOpen,
  menuClose,
  emptyMenuListText
}) {
  return (
    <Menu
      id="module-menu"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={menuClose}
      disableAutoFocusItem
    >
      {generateMenuItems(menuList, classes, emptyMenuListText, dataTestId)}
    </Menu>
  );
}

export default withStyles(styles)(HeaderMenuComponent);
