"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _model = require("../../utils/model.utils");

var _PortalApplication = require("../PortalApplication");
/**
 * Model name
 * Alias to CreateApplicationRequest Model
 */


var MODEL_NAME = "AgentPortalApplication";
/**
 * Protects data from missing fields
 */

var defaultProps = _PortalApplication.model.defaultProps;
/**
 * Protects data from change in data type
 */

var schema = _PortalApplication.model.schema;

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
}; // Any "" values will be transformed to null


var transformSend = function transformSend(data) {
  return data;
};

var _default = {
  transformReceived: transformReceived,
  transformSend: transformSend
};
exports.default = _default;