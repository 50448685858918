/**
 * LOADING STYLES
 * class name -loading
 * @param theme
 * @author Ryan Rivera
 */
const styles = () => ({
  display: "block",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "rgba(255, 255, 255, 0.8)",
  transition: "all 0.3s ease",
  zIndex: "-1",
  opacity: 0,
  pointerEvents: "none",

  "& > div": {
    position: "absolute",
    display: "block",
    textAlign: "center",
    width: "100%",
    top: "50%",
    left: 0,
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.6)",
    transform: "translateY(-52%)",
    transition: "all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)"
  },

  "&.-active": {
    opacity: 1,
    zIndex: 2,
    pointerEvents: "all",

    "& > div": {
      transform: "translateY(50%)"
    }
  }
});

export default styles;
