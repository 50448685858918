import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CancelIcon from "@material-ui/icons/Cancel";
import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";
import log from "loglevel";
import { styles } from "./styles/parent.expander.styles";
import ExpanderMenu from "./components/header.menu";
import { AlchemyIconButton } from "../Buttons";
import { AlchemyTypography } from "../AlchemyTypography";

const logger = log.getLogger("ParentExpander");

/**
 * PARENT EXPANDER
 * this component shows those expanders that are being used as parent tile with expanding ability
 * example, can be found in status tile expander which holds the history/other detail expanders
 * @type Component
 * @author Ehsan
 * @version 0.0.2
 * @param {string} title
 * @param {string} subTitle
 * @param {boolean} hasExpandIcon show/hide expand more icon
 * @param {boolean} hasMenu show/hide menu icon
 * @param {array} menuList list of menu items
 * @param {function} onMenuItemClick
 * @param {boolean} hasActions show/hide actions (action icons)
 * @param {array} actionsList list of actions with icon names, other info
 * @param {function} onActionClick
 */
class ParentExpander extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.defaultExpanded ? props.defaultExpanded : false,
      anchorEl: null,
      menuOpen: false
    };

    this.cardEl = null;
  }

  generateActions = list => {
    return list.map((action, index) => {
      return this.generateIcon(action, index);
    });
  };

  generateIcon = (action, index) => {
    const { classes, onActionClick, dataTestId, dataTestBuilder } = this.props;
    if (action.icon && action.icon.length > 1) {
      return (
        <div key={index}>
          <AlchemyIconButton
            disabled={action.isDisabled}
            onClick={e => {
              e.stopPropagation();
              onActionClick(action);
            }}
            size="MD"
            dataTestId={dataTestId}
            dataTestBuilder={dataTestBuilder}
          >
            <Icon classes={{ root: classes.rootIcon }}>{action.icon}</Icon>
          </AlchemyIconButton>
        </div>
      );
    } else {
      return null;
    }
  };

  handleExpanderMenuClick = (event, action) => {
    if (event) {
      event.stopPropagation();
    }

    action
      ? this.setState({ menuOpen: action, anchorEl: event.currentTarget })
      : this.setState({ menuOpen: action, anchorEl: null });
  };

  handleCloseFullScreenClick = event => {
    const { id, toggleFullScreen } = this.props;

    if (event) {
      event.stopPropagation();
    }

    if (toggleFullScreen && id) toggleFullScreen(id, this.cardEl);
  };

  generateHeaderMenu = () => {
    const { anchorEl, menuOpen } = this.state;
    const { classes, menuList, dataTestId, dataTestBuilder } = this.props;
    const menuDataTestId =
      dataTestBuilder && dataTestBuilder(dataTestId, "section-expander-menu");
    return (
      <Fragment>
        <AlchemyIconButton
          dataTestId={menuDataTestId}
          dataTestBuilder={dataTestBuilder}
          aria-label="Expander Menu"
          aria-owns={anchorEl ? "expander-menu" : null}
          aria-haspopup="true"
          onClick={event => this.handleExpanderMenuClick(event, true)}
          size="MD"
        >
          <MoreHorizIcon className={classes.moreHorizIcon} />
        </AlchemyIconButton>
        <ExpanderMenu
          dataTestId={menuDataTestId}
          dataTestBuilder={dataTestBuilder}
          anchorEl={anchorEl ? anchorEl : {}}
          menuOpen={menuOpen}
          menuClose={event => this.handleExpanderMenuClick(event, false)}
          menuList={menuList}
          onMenuItemClick={this.onMenuItemClick}
        />
      </Fragment>
    );
  };

  getCloseFullScreenIcon = () => {
    const { dataTestId, dataTestBuilder } = this.props;
    return (
      <AlchemyIconButton
        aria-label="Close full screen"
        onClick={event => this.handleCloseFullScreenClick(event, true)}
        dataTestId={
          dataTestBuilder && dataTestBuilder(dataTestId, "close-full-screen")
        }
        dataTestBuilder={dataTestBuilder}
        size="MD"
      >
        <CancelIcon />
      </AlchemyIconButton>
    );
  };

  getExpandIcon = () => {
    const { classes, tracker, dataTestId, dataTestBuilder } = this.props;
    const { expanded: componentExpanded } = this.state;
    const expanded = tracker ? tracker.expanded : componentExpanded;

    const ExpandIcon = expanded ? (
      <ExpandLessIcon className={classes.expandIcon} />
    ) : (
      <ExpandMoreIcon className={classes.expandIcon} />
    );

    return (
      <AlchemyIconButton
        size="MD"
        dataTestId={dataTestBuilder && dataTestBuilder(dataTestId, "expander")}
        dataTestBuilder={dataTestBuilder}
      >
        {ExpandIcon}
      </AlchemyIconButton>
    );
  };

  onExpandClick = e => {
    const { editMode, handleExpandClick, tracker } = this.props;
    const { expanded } = this.state;

    e.stopPropagation();
    if (!editMode) {
      logger.debug("onExpandClick-setState", !expanded);
      this.setState(
        prevState => ({ expanded: !prevState.expanded }),
        () => {
          const { expanded: componentExpanded } = this.state;
          const expanded = tracker ? tracker.expanded : !componentExpanded;
          if (handleExpandClick) handleExpandClick(!expanded);
        }
      );
    }
  };

  onMenuItemClick = value => {
    const { id, toggleFullScreen, onMenuItemClick } = this.props;

    switch (value.name) {
      case "full_screen_view":
        if (toggleFullScreen && id) toggleFullScreen(id, this.cardEl);
        break;
      case "tile_view":
        if (toggleFullScreen && id) toggleFullScreen(id, this.cardEl);
        break;
      default:
        onMenuItemClick(value);
        break;
    }
  };

  render() {
    const {
      classes,
      title,
      subTitle,
      hasExpandIcon,
      hasMenu,
      hasActions,
      actionsList,
      isFullScreen,
      cardStyle,
      cardHeaderStyle,
      headerSeparatorClassName,
      adornments,
      className,
      dataTestId,
      dataTestBuilder,
      content,
      children,
      tracker
    } = this.props;

    const { expanded: componentExpanded } = this.state;
    const expanded = tracker ? tracker.expanded : componentExpanded;
    const Content = content;
    return (
      <Card
        className={classNames("card", className)}
        classes={{ root: classes.cardRoot }}
        ref={node => {
          this.cardEl = node;
        }}
        style={cardStyle}
        data-test={dataTestBuilder && dataTestBuilder(dataTestId)}
      >
        <CardHeader
          style={cardHeaderStyle}
          title={
            <AlchemyTypography
              dataTestId={
                dataTestBuilder && dataTestBuilder(dataTestId, "title")
              }
              dataTestBuilder={dataTestBuilder}
              bold
              type="p2"
              component="span"
              suppressTracking
            >
              {title}
            </AlchemyTypography>
          }
          subheader={
            <div
              data-test={
                dataTestBuilder && dataTestBuilder(dataTestId, "sub-title")
              }
            >
              {subTitle}
            </div>
          }
          className={classes.cardHeader}
          classes={{
            title: classes.headerTitle,
            subheader: classes.subTitle,
            action: classes.headerAction
          }}
          data-test={dataTestBuilder && dataTestBuilder(dataTestId, "header")}
          action={
            <div
              data-test={
                dataTestBuilder && dataTestBuilder(dataTestId, "actions")
              }
            >
              {isFullScreen && this.getCloseFullScreenIcon()}
              {hasMenu && this.generateHeaderMenu()}
              {hasActions && this.generateActions(actionsList)}
              {adornments}
              {!isFullScreen && hasExpandIcon && this.getExpandIcon()}
            </div>
          }
          onClick={this.onExpandClick}
        />
        {children}
        {headerSeparatorClassName && (
          <hr
            className={`${classes.headerSeparator} ${
              classes[headerSeparatorClassName]
            }`}
            data-test={
              dataTestBuilder && dataTestBuilder(dataTestId, "separator")
            }
          />
        )}
        {hasExpandIcon && (
          <Collapse
            in={expanded}
            timeout="auto"
            className="collapse-wrapper"
            data-test={
              dataTestBuilder && dataTestBuilder(dataTestId, "content")
            }
          >
            <CardContent className={classes.cardContent}>
              <Content isFullScreen={isFullScreen} />
            </CardContent>
          </Collapse>
        )}
      </Card>
    );
  }
}

ParentExpander.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasExpandIcon: PropTypes.bool.isRequired,
  hasMenu: PropTypes.bool.isRequired,
  menuList: PropTypes.array,
  hasActions: PropTypes.bool.isRequired,
  actionsList: PropTypes.array,
  onActionClick: PropTypes.func,
  onMenuItemClick: PropTypes.func,
  content: PropTypes.func.isRequired,
  defaultExpanded: PropTypes.bool,
  toggleFullScreen: PropTypes.func,
  headerSeparatorClassName: PropTypes.string,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(ParentExpander);
