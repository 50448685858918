"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var socialMediaLinks = [{
  name: "facebook",
  link: "https://www.facebook.com/progressacanada/"
}, {
  name: "twitter",
  link: "https://twitter.com/progressacanada"
}, {
  name: "instagram",
  link: "https://www.instagram.com/progressalife/"
}, {
  name: "linkedin",
  link: "https://www.linkedin.com/company/progressacanada?originalSubdomain=ca" // prettier-ignore

}];
var _default = socialMediaLinks;
exports.default = _default;