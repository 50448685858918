import PropTypes from "prop-types";

/**
 * FUNDED LOAN DETAILS MODEL
 * payments
 */

// FUNDED LOAN DETAILS  - DEFAULT PROPS
export const defaultProps = {
  adminFees: 0,
  amount: 0,
  balance: 0,
  classification: "Payment",
  description: "",
  effectiveDate: "",
  fees: 0,
  insurance: 0,
  interest: 0,
  isDebit: true,
  isHistory: true,
  principal: 0,
  reversalTransactionRefNumber: 0,
  transactionDate: {
    date: 0,
    day: 0,
    hours: 0,
    minutes: 0,
    month: 0,
    nanos: 0,
    seconds: 0,
    time: 0,
    timezoneOffset: 0,
    year: 0
  },
  transactionRefNumber: 0
};

/**
 * FUNDED LOAN DETAILS - SCHEMA
 * payments
 */
export const schema = {
  adminFees: PropTypes.number,
  amount: PropTypes.number,
  balance: PropTypes.number,
  classification: PropTypes.string,
  description: PropTypes.string,
  effectiveDate: PropTypes.string,
  fees: PropTypes.number,
  insurance: PropTypes.number,
  interest: PropTypes.number,
  isDebit: PropTypes.bool,
  isHistory: PropTypes.bool,
  principal: PropTypes.number,
  reversalTransactionRefNumber: PropTypes.number,
  transactionDate: PropTypes.shape({
    date: PropTypes.number,
    day: PropTypes.number,
    hours: PropTypes.number,
    minutes: PropTypes.number,
    month: PropTypes.number,
    nanos: PropTypes.number,
    seconds: PropTypes.number,
    time: PropTypes.number,
    timezoneOffset: PropTypes.number,
    year: PropTypes.number
  }),
  transactionRefNumber: PropTypes.number
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-loan-details-payments";
