import React, { Component } from "react";
import PropTypes from "prop-types";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";

// LOCAL STYLES
import styles from "./styles/checkbox.styles";

class AlchemyCheckbox extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { value, error, disabled } = this.props;
    return (
      value !== nextProps.value ||
      error !== nextProps.error ||
      disabled !== nextProps.disabled
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { setFormStateData, fieldName, value } = this.props;

    if (setFormStateData) {
      const formData = {};
      formData[fieldName] = value;
      setFormStateData(formData);
    }
  }

  getDataTestId = (...args) => {
    const { dataTestId, dataTestBuilder } = this.props;
    return dataTestBuilder && dataTestBuilder(dataTestId, ...args);
  };

  normalize = (value = "false") => {
    if (typeof value === "boolean") {
      return value.toString();
    }

    if (typeof value === "string" && value !== "true") {
      return "false";
    }

    return value;
  };

  render() {
    const {
      classes,
      labelText,
      checkboxText,
      fieldName,
      value,
      required,
      error,
      helperText,
      children,
      disabled,
      isSmallCheckBox,
      onChange
    } = this.props;

    const WRAPPER = "wrapper";
    const CHECKBOX = "checkbox";
    const FORM_CONTROL = "form-control";

    return (
      <FormControl
        error={error}
        required={required}
        className={classes.formControl}
        data-test={this.getDataTestId(FORM_CONTROL)}
      >
        {labelText && (
          <FormLabel className={classes.formLabel}>{labelText}</FormLabel>
        )}
        <FormControlLabel
          className={
            disabled
              ? classes.formControlLabelDisabled
              : classes.formControlLabel
          }
          control={
            <Checkbox
              name={fieldName}
              classes={{
                root: isSmallCheckBox
                  ? classes.checkboxSmall
                  : classes.checkbox,
                checked: disabled ? classes.disabledChecked : classes.checked
              }}
              checked={value}
              onChange={onChange}
              value={this.normalize(value)}
              disableRipple
              disabled={disabled}
              data-test={this.getDataTestId(CHECKBOX)}
            />
          }
          label={checkboxText ? checkboxText : children}
          data-test={this.getDataTestId(CHECKBOX, WRAPPER)}
        />
        {(helperText || required) && (
          <FormHelperText className={classes.helperText}>
            {!helperText && required ? "Required" : helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

AlchemyCheckbox.defaultProps = {
  isSmallCheckBox: false
};

AlchemyCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  checkboxText: PropTypes.string,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setFormStateData: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isSmallCheckBox: PropTypes.bool,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(AlchemyCheckbox);
