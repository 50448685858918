const styles = theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "10px 5px"
  },
  loanStatus: {
    display: "flex !important",
    padding: "0 4px"
  },
  iconRoot: Object.assign({}, theme.icon.icon20, {
    display: "inline-block",
    fontSize: "20px",

    "&:hover": {
      color: theme.palette.error["main"]
    }
  }),
  action: {
    color: theme.palette.grey[900]
  },
  success: {
    color: theme.palette.success["main"]
  },
  warning: {
    color: theme.palette.warning["main"]
  },
  error: {
    color: theme.palette.error["main"],
    position: "relative",
    top: "-5px"
  },
  loanStatusEscalation: {
    color: theme.palette.error["main"],
    paddingLeft: "5px"
  }
});

export default styles;
