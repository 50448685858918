import PropTypes from "prop-types";

/**
 * IMPORT MODEL
 */

// IMPORT TRANCHE - DEFAULT PROPS
export const defaultProps = {
  id: null,
  name: null,
  type: null,
  loansCount: 0,
  failuresCount: 0,
  value: 0,
  dateRange: {
    min: null,
    max: null
  },
  investor: {
    name: null
  },
  errors: []
};

// IMPORT TRANCHE - SCHEMA
export const schema = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  loansCount: PropTypes.number,
  failuresCount: PropTypes.number,
  value: PropTypes.number,
  dateRange: PropTypes.object,
  investor: PropTypes.object,
  errors: PropTypes.array
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Tranche-import";
