import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { withAuth } from "../../services/auth/auth.service";
import { ErrorMessage } from "../../shared/ErrorMessage";
import { ERROR_DEFINITIONS } from "../constants/error.messages";
import { translateMessages } from "./error.utils";

// LOCAL DEPENDENCIES
import Page from "../../shared/Page";
import { menuList } from "../pages.modules";
import { getMenuList } from "../../utils/engage.app.utils";
// MATERIAL-UI

// ERROR STYLE
import styles from "./styles/error.styles";

function ErrorPage(props) {
  const { classes, i18n, user, history } = props;

  const { logOut, profile, filterByAccess } = user;
  const errorMessage = translateMessages(i18n, ERROR_DEFINITIONS.NOT_FOUND);
  return (
    <Page
      style={{ paddingTop: "48px" }}
      HeaderProps={{
        profile,
        menuList: getMenuList(filterByAccess(menuList, user), i18n),
        onLogOut: logOut,
        onLogoClick: () => history.push("/"),
        emptyMenuListText: i18n.t`No modules available`
      }}
    >
      <Grid container spacing={0} className={classes.grid}>
        <Grid item className={classes.gridItem}>
          <ErrorMessage {...errorMessage} />
        </Grid>
      </Grid>
    </Page>
  );
}

ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const enhance = compose(
  withI18n(),
  withStyles(styles),
  withAuth
);

export default enhance(ErrorPage);
