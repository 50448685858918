export const styles = theme => ({
  logoDiv: {
    padding: "8px 0px 0px 16px"
  },
  logo: {
    flex: 1,
    position: "relative",
    height: 32,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    backgroundImage: theme.altLogo.backgroundImage
  },
  loginForm: {
    marginTop: "96px",
    position: "absolute",
    left: "50%",
    "-webkit-transform": "translateX(-50%)",
    "-moz-transform": "translateX(-50%)",
    "-ms-transform": "translateX(-50%)",
    transform: "translateX(-50%)",
    "-webkit-box-orient": "vertical"
  },
  loginText: theme.typography.h2Dark,
  loginButton: {
    width: "304px",
    height: "48px",
    marginTop: "40px",
    backgroundColor: theme.palette.secondary.dark,
    fontSize: theme.typography.p2Light.fontSize,
    lineHeight: theme.typography.p2Light.lineHeight,
    color: theme.typography.p2Light.color,
    border: 0,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    outline: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.typography.p2Dark.color
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.typography.p2Dark.color
    }
  },
  rootSignIn: {
    color: "inherit",
    fontSize: theme.typography.p1Light.fontSize
  },
  loginButtonText: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.common.white
  }
});
