import { i18nMark } from "@lingui/react";

export const ERROR_MESSAGES = {
  DEFAULT_DATA: {
    ADD: i18nMark("The data was successfully added."),
    UPDATE: i18nMark("The data was updated successfully."),
    DELETE: i18nMark("The data was successfully deleted.")
  },
  AUTH_USER: {
    ADD: i18nMark("Error adding authorized user."),
    UPDATE: i18nMark("Error updating authorized user."),
    DELETE: i18nMark("Error deleting authorized user.")
  },
  INCOME: {
    ADD: i18nMark("Error adding income."),
    UPDATE: i18nMark("Error updating income."),
    DELETE: i18nMark("Error deleting income.")
  },
  BANK_ACCOUNT: {
    ADD: i18nMark("Error adding bank account."),
    UPDATE: i18nMark("Error updating bank account."),
    DELETE: i18nMark("Error deleting bank account.")
  },
  STATIC_CODES: {
    FETCH: i18nMark("Failure in fetching codes!")
  },
  COSIGNER: {
    ADD: i18nMark("Error adding cosigner."),
    DELETE: i18nMark("Error deleting cosigner")
  },
  LIABILITY: {
    ADD: i18nMark("Error adding liability."),
    UPDATE: i18nMark("Error updating liability."),
    DELETE: i18nMark("Error deleting liability.")
  },
  FUNDED_CLIENT_NAME: {
    UPDATE: i18nMark("Error updating name.")
  },
  DEAL_LEAD: {
    UPDATE: i18nMark("Error updating deal lead.")
  },
  REFERRAL_SOURCE: {
    UPDATE: i18nMark("Error updating referral source.")
  },
  PERSONAL_DETAILS: {
    UPDATE: i18nMark("Error updating personal details.")
  },
  CLIENT_EMAIL: {
    ADD: i18nMark("Error adding email."),
    UPDATE: i18nMark("Error updating email."),
    DELETE: i18nMark("Error deleting email.")
  },
  PHONE_NUMBER: {
    ADD: i18nMark("Error adding phone number."),
    UPDATE: i18nMark("Error updating phone number."),
    DELETE: i18nMark("Error deleting phone number.")
  },
  CLIENT_RESIDENCE: {
    ADD: i18nMark("Error adding residence."),
    UPDATE: i18nMark("Error updating residence."),
    DELETE: i18nMark("Error deleting residence.")
  },
  STATUS: {
    UPDATE: i18nMark("Error updating status.")
  }
};
