"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRANCHE_IMPORT = exports.TRANCHES_PARSES = exports.TRANCHE_UPLOAD = exports.FUNDED_CLIENT_PHONE = exports.FUNDED_CLIENT_PHONES = exports.FUNDED_CLIENT_NOTE = exports.FUNDED_CLIENT_NOTES = exports.FUNDED_CLIENT_EMAIL = exports.FUNDED_CLIENT_ADDRESS = exports.FUNDED_CLIENT = exports.FUNDED_CLIENTS_DOCUMENT_LINK = exports.FUNDED_CLIENTS = exports.FUNDED_LOAN_PAYOFF_AMOUNT = exports.FUNDED_LOAN_PAYMENTS = exports.FUNDED_LOAN_NOTE = exports.FUNDED_LOAN_NOTES = exports.FUNDED_LOAN_DOCUMENT_LINK = exports.FUNDED_LOAN_DOCUMENTS = exports.FUNDED_LOAN_CLIENT_AUTH_USER = exports.FUNDED_LOAN_CLIENT_AUTH_USERS = exports.FUNDED_LOAN_CLIENT = exports.FUNDED_LOAN_CLIENTS = exports.FUNDED_LOAN = exports.FUNDED_LOANS_STATIC_CODES = exports.FUNDED_LOANS_SEARCH = exports.FUNDED_LOANS = void 0;

var _api = require("./api.contants");
/**
 * API FUNDED ENDPOINTS
 * @type {string}
 */


var FUNDED = "".concat(_api.ROOT, "/funded"); // FUNDED LOANS

var FUNDED_LOANS = "".concat(FUNDED, "/loans");
exports.FUNDED_LOANS = FUNDED_LOANS;
var FUNDED_LOANS_SEARCH = "".concat(FUNDED_LOANS, "/search");
exports.FUNDED_LOANS_SEARCH = FUNDED_LOANS_SEARCH;
var FUNDED_LOANS_STATIC_CODES = "".concat(FUNDED_LOANS, "/static-codes"); // FUNDED LOAN

exports.FUNDED_LOANS_STATIC_CODES = FUNDED_LOANS_STATIC_CODES;

var FUNDED_LOAN = function FUNDED_LOAN(loanId) {
  return "".concat(FUNDED_LOANS, "/").concat(loanId);
};

exports.FUNDED_LOAN = FUNDED_LOAN;

var FUNDED_LOAN_CLIENTS = function FUNDED_LOAN_CLIENTS(loanId) {
  return "".concat(FUNDED_LOAN(loanId), "/clients");
};

exports.FUNDED_LOAN_CLIENTS = FUNDED_LOAN_CLIENTS;

var FUNDED_LOAN_CLIENT = function FUNDED_LOAN_CLIENT(loanId, clientId) {
  return "".concat(FUNDED_LOAN_CLIENTS(loanId), "/").concat(clientId);
}; // prettier-ignore


exports.FUNDED_LOAN_CLIENT = FUNDED_LOAN_CLIENT;

var FUNDED_LOAN_CLIENT_AUTH_USERS = function FUNDED_LOAN_CLIENT_AUTH_USERS(loanId, clientId) {
  return "".concat(FUNDED_LOAN_CLIENTS(loanId), "/").concat(clientId, "/auth-users");
}; // prettier-ignore


exports.FUNDED_LOAN_CLIENT_AUTH_USERS = FUNDED_LOAN_CLIENT_AUTH_USERS;

var FUNDED_LOAN_CLIENT_AUTH_USER = function FUNDED_LOAN_CLIENT_AUTH_USER(loanId, clientId, authUserId) {
  return "".concat(FUNDED_LOAN_CLIENT_AUTH_USERS(loanId, clientId), "/").concat(authUserId);
}; // prettier-ignore


exports.FUNDED_LOAN_CLIENT_AUTH_USER = FUNDED_LOAN_CLIENT_AUTH_USER;

var FUNDED_LOAN_DOCUMENTS = function FUNDED_LOAN_DOCUMENTS(loanId) {
  return "".concat(FUNDED_LOAN(loanId), "/documents");
}; // prettier-ignore


exports.FUNDED_LOAN_DOCUMENTS = FUNDED_LOAN_DOCUMENTS;

var FUNDED_LOAN_DOCUMENT_LINK = function FUNDED_LOAN_DOCUMENT_LINK(loanId, noteId, docId) {
  return "".concat(FUNDED_LOAN_DOCUMENTS(loanId), "/").concat(noteId, "/").concat(docId);
}; // prettier-ignore


exports.FUNDED_LOAN_DOCUMENT_LINK = FUNDED_LOAN_DOCUMENT_LINK;

var FUNDED_LOAN_NOTES = function FUNDED_LOAN_NOTES(loanId) {
  return "".concat(FUNDED_LOAN(loanId), "/comments");
};

exports.FUNDED_LOAN_NOTES = FUNDED_LOAN_NOTES;

var FUNDED_LOAN_NOTE = function FUNDED_LOAN_NOTE(loanId, noteId) {
  return "".concat(FUNDED_LOAN(loanId), "/comments/").concat(noteId);
}; // prettier-ignore


exports.FUNDED_LOAN_NOTE = FUNDED_LOAN_NOTE;

var FUNDED_LOAN_PAYMENTS = function FUNDED_LOAN_PAYMENTS(loanId) {
  return "".concat(FUNDED_LOAN(loanId), "/payments");
};

exports.FUNDED_LOAN_PAYMENTS = FUNDED_LOAN_PAYMENTS;

var FUNDED_LOAN_PAYOFF_AMOUNT = function FUNDED_LOAN_PAYOFF_AMOUNT(loanId, date) {
  return "".concat(FUNDED_LOAN(loanId), "/payoff-amount?date=").concat(date);
}; // prettier-ignore
// FUNDED CLIENTS


exports.FUNDED_LOAN_PAYOFF_AMOUNT = FUNDED_LOAN_PAYOFF_AMOUNT;
var FUNDED_CLIENTS = "".concat(FUNDED, "/clients");
exports.FUNDED_CLIENTS = FUNDED_CLIENTS;

var FUNDED_CLIENTS_DOCUMENT_LINK = function FUNDED_CLIENTS_DOCUMENT_LINK(noteId, docId) {
  return "".concat(FUNDED_CLIENTS, "/documents/").concat(noteId, "/").concat(docId);
}; // prettier-ignore


exports.FUNDED_CLIENTS_DOCUMENT_LINK = FUNDED_CLIENTS_DOCUMENT_LINK;

var FUNDED_CLIENT = function FUNDED_CLIENT(clientId) {
  return "".concat(FUNDED_CLIENTS, "/").concat(clientId);
}; // prettier-ignore


exports.FUNDED_CLIENT = FUNDED_CLIENT;

var FUNDED_CLIENT_ADDRESS = function FUNDED_CLIENT_ADDRESS(clientId) {
  return "".concat(FUNDED_CLIENT(clientId), "/address");
}; // prettier-ignore


exports.FUNDED_CLIENT_ADDRESS = FUNDED_CLIENT_ADDRESS;

var FUNDED_CLIENT_EMAIL = function FUNDED_CLIENT_EMAIL(clientId) {
  return "".concat(FUNDED_CLIENT(clientId), "/email-address");
}; // prettier-ignore


exports.FUNDED_CLIENT_EMAIL = FUNDED_CLIENT_EMAIL;

var FUNDED_CLIENT_NOTES = function FUNDED_CLIENT_NOTES(clientId) {
  return "".concat(FUNDED_CLIENT(clientId), "/comments");
}; // prettier-ignore


exports.FUNDED_CLIENT_NOTES = FUNDED_CLIENT_NOTES;

var FUNDED_CLIENT_NOTE = function FUNDED_CLIENT_NOTE(clientId, noteId) {
  return "".concat(FUNDED_CLIENT(clientId), "/comments/").concat(noteId);
}; // prettier-ignore


exports.FUNDED_CLIENT_NOTE = FUNDED_CLIENT_NOTE;

var FUNDED_CLIENT_PHONES = function FUNDED_CLIENT_PHONES(clientId) {
  return "".concat(FUNDED_CLIENT(clientId), "/phones");
}; // prettier-ignore


exports.FUNDED_CLIENT_PHONES = FUNDED_CLIENT_PHONES;

var FUNDED_CLIENT_PHONE = function FUNDED_CLIENT_PHONE(clientId, phoneId) {
  return "".concat(FUNDED_CLIENT_PHONES(clientId), "/").concat(phoneId);
}; // prettier-ignore
// FUNDED TRANCHES


exports.FUNDED_CLIENT_PHONE = FUNDED_CLIENT_PHONE;
var TRANCHE_UPLOAD = "".concat(FUNDED, "/tranches");
exports.TRANCHE_UPLOAD = TRANCHE_UPLOAD;
var TRANCHES_PARSES = "".concat(FUNDED, "/tranches/parses");
exports.TRANCHES_PARSES = TRANCHES_PARSES;

var TRANCHE_IMPORT = function TRANCHE_IMPORT(parseId) {
  return "".concat(FUNDED, "/tranches/parses/").concat(parseId, "/import");
}; // prettier-ignore


exports.TRANCHE_IMPORT = TRANCHE_IMPORT;