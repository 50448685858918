"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // We used array here because we need to have a historical mapping of the consent changes.
// The latest version should be the first element in the array.

var _default = [{
  name: "newsletter",
  type: "Newsletter",
  text: "I would like to receive a monthly Progressa e-newsletter email with personal finance tips, smart spending ideas and more."
}];
exports.default = _default;