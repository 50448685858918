"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialize = initialize;
/**
 * initialize Hotjar
 * @param enabled
 * @param hjid
 */

function initialize(_ref) {
  var hjid = _ref.hjid,
      enabled = _ref.enabled; // prettier-ignore

  if (enabled && hjid) {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };

      h._hjSettings = {
        hjid: hjid,
        hjsv: 6
      };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }
}