import { put, takeLatest, fork, call } from "redux-saga/effects";
import { http } from "../../../services/http/http.service";

// LOCAL DEPENDENCIES
import { LOAN_APPLICATIONS_SEARCH } from "./loan.applications.action.types";
import {
  searchLoanApplicationsSuccess,
  searchLoanApplicationsFailure
} from "./loan.applications.actions";

// FUNDED LOANS SEARCH
export const searchLoanApplications = action =>
  http({
    request: (httpSrv, API) =>
      call(httpSrv.post, API.APPLICATIONS_SEARCH, action.searchCriteria),
    success: data => put(searchLoanApplicationsSuccess(data)),
    error: error => put(searchLoanApplicationsFailure(error))
  });

// WATCHERS
export function* watchLoanApplicationsSearchAsync() {
  yield takeLatest(LOAN_APPLICATIONS_SEARCH, searchLoanApplications);
}

/**
 * FUNDED LOANS SAGA
 * Watches async requests,
 * in case of request, uses appropriate Action
 * passed in the reducer to return a new state
 */
export default [fork(watchLoanApplicationsSearchAsync)];
