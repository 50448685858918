"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormBG = FormBG;
exports.FormGroup = FormGroup;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _base = require("./base.knobs");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function FormBG(_ref) {
  var children = _ref.children;
  return _react.default.createElement("form", {
    autoComplete: "off",
    style: {
      background: (0, _base.choice)("White BG") ? "#FFF" : "#F5F6F5",
      width: "50%",
      margin: "16px",
      padding: "16px"
    }
  }, children);
}

FormBG.propTypes = {
  children: _propTypes.default.any
};

function FormGroup(_ref2) {
  var children = _ref2.children;
  return _react.default.createElement("div", {
    style: {
      margin: "8px 16px"
    }
  }, children);
}

FormGroup.propTypes = {
  children: _propTypes.default.any
};