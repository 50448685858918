import React from "react";
import Loadable from "react-loadable";

export default Loadable.Map({
  loader: {
    Dashboard: () => import("./dashboard.index")
  },
  render(loaded, props) {
    const Dashboard = loaded.Dashboard.default;
    return <Dashboard {...props} />;
  },
  loading: () => <div />
});
