export const styles = theme => ({
  buttonWrapper: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer"
    },
    "&:hover svg": {
      color: theme.palette.secondary.dark
    }
  },
  disabledButtonWrapper: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center"
  },
  buttonText: Object.assign({}, theme.typography.p2Dark, {
    verticalAlign: "middle",
    display: "flex"
  })
});

export default styles;
