import * as actionTypes from "../action.types/loan.application.details.action.types";
import * as liabilityActionTypes from "../../components/application.card/components/liabilities/engine/liabilities.action.types";
import { TOOLTIPS, DOCS_TAB_MODE } from "../../../../core/constants/ui";
import {
  LOAN_APPLICATION_BANK_REQUEST_FETCH,
  LOAN_APPLICATION_BANK_REQUEST_FETCH_SUCCESS,
  LOAN_APPLICATION_BANK_REQUEST_FETCH_FAILURE
} from "../../components/application.card/components/bank.requests/engine/bank.requests.types";

import {
  LOAN_REQUEST_SUBMIT,
  LOAN_REQUEST_SUBMIT_SUCCESS,
  LOAN_REQUEST_SUBMIT_FAILURE
} from "../../components/application.card/components/loanRequest/engine/loan.request.action.types";
import {
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_OUTSTANDING_DOCUMENT_REQUEST,
  GET_OUTSTANDING_DOCUMENT_SUCCESS,
  SELECT_DOC_ENTRY,
  UPDATE_DOC_MODE
} from "../../../../services/data/documents/document.action.types";

import { coordinator as coordinatorService } from "../../../../services";

import TAB_VALUES from "../../../constants/tab.values";

const initialState = {
  tabsInfo: [
    {
      value: TAB_VALUES.APPLICATION,
      label: "Application",
      isDefault: true,
      hasBadge: false
    },
    {
      value: TAB_VALUES.NOTES,
      label: "Notes",
      isDefault: false,
      hasBadge: true
    },
    {
      value: TAB_VALUES.DOCS,
      label: "Docs",
      isDefault: false,
      hasBadge: true
    },
    {
      value: TAB_VALUES.EMAILS,
      label: "Emails",
      isDefault: false,
      hasBadge: true
    },
    {
      value: TAB_VALUES.ACTIVITY,
      label: "Activity",
      isDefault: false,
      hasBadge: false
    }
  ],
  statusActions: [],
  infoActions: [
    {
      tooltip: "edit",
      icon: "edit"
    }
  ],
  noteActions: [],
  tabsActions: {
    applicationsTab: {
      header: []
    },
    notesTab: {
      header: [
        {
          tooltip: `${TOOLTIPS.tabsHeader.fullView}`,
          icon: "view_headline",
          isDisabled: true,
          isSelected: false
        },
        {
          tooltip: `${TOOLTIPS.tabsHeader.splitView}`,
          icon: "view_list",
          isDisabled: false,
          isSelected: true
        }
      ]
    },
    docsTab: {
      header: [
        {
          tooltip: `${TOOLTIPS.tabsHeader.fullView}`,
          icon: "view_headline",
          isDisabled: true,
          isSelected: false
        },
        {
          tooltip: `${TOOLTIPS.tabsHeader.splitView}`,
          icon: "view_list",
          isDisabled: false,
          isSelected: true
        }
      ]
    },
    emailsTab: {
      header: [
        {
          tooltip: `${TOOLTIPS.tabsHeader.fullView}`,
          icon: "view_headline",
          isDisabled: true
        },
        {
          tooltip: `${TOOLTIPS.tabsHeader.splitView}`,
          icon: "view_list",
          isDisabled: false
        }
      ]
    },
    activityTab: {
      header: [
        {
          tooltip: `${TOOLTIPS.tabsHeader.fullView}`,
          icon: "view_headline",
          isDisabled: true
        },
        {
          tooltip: `${TOOLTIPS.tabsHeader.splitView}`,
          icon: "view_list",
          isDisabled: false
        }
      ]
    }
  },
  isFetching: {
    application: true,
    notes: false,
    documents: false,
    outstandingDocs: false,
    serviceDebts: true,
    liability: false,
    expense: false,
    clientAddForm: false,
    bankRequests: false
  },
  selectedNote: null,
  loanRequest: {
    isSubmitting: false,
    isFetchingNotes: false,
    successfullyPosted: false
  },
  docsTab: {
    mode: DOCS_TAB_MODE.DOCS,
    selectedClientId: null,
    selectedLoanId: null,
    selectedDocId: null
  }
};

/**
 * Whitelist for loan.application.details.ui
 * that will be persisted to the browser storage
 * @type {string[]}
 */
export const loanApplicationDetailsUIWhitelist = [];

/**
 * Reducer for loan.application.details.ui
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    // LOAN APPLICATION
    case actionTypes.LOAN_APPLICATION_DETAILS_FETCH:
      return {
        ...state,
        isFetching: { ...state.isFetching, application: true }
      };

    case actionTypes.LOAN_APPLICATION_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: { ...state.isFetching, application: false }
      };

    case actionTypes.LOAN_APPLICATION_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, application: false }
      };

    // LOAN APPLICATION BANK REQUEST
    case LOAN_APPLICATION_BANK_REQUEST_FETCH:
      return {
        ...state,
        isFetching: { ...state.isFetching, bankRequests: true }
      };

    case LOAN_APPLICATION_BANK_REQUEST_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: { ...state.isFetching, bankRequests: false }
      };

    case LOAN_APPLICATION_BANK_REQUEST_FETCH_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, bankRequests: false }
      };

    // DOCUMENTS
    case GET_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: { ...state.isFetching, documents: true }
      };

    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: { ...state.isFetching, documents: false }
      };

    case GET_OUTSTANDING_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: { ...state.isFetching, outstandingDocs: true }
      };

    case GET_OUTSTANDING_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: { ...state.isFetching, outstandingDocs: false }
      };

    case SELECT_DOC_ENTRY:
      return {
        ...state,
        docsTab: {
          ...state.docsTab,
          mode: action.mode,
          selectedClientId: action.selectedClientId,
          selectedLoanId: action.selectedLoanId,
          selectedDocId: action.selectedDocId
        }
      };

    case UPDATE_DOC_MODE:
      return {
        ...state,
        docsTab: {
          ...state.docsTab,
          mode: action.mode
        }
      };

    // EXPENSE FETCHING
    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_ADD:
      return {
        ...state,
        isFetching: { ...state.isFetching, expense: true }
      };

    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_SUCCESS:
    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, expense: false }
      };

    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE:
      return {
        ...state,
        isFetching: { ...state.isFetching, expense: true }
      };

    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_SUCCESS:
    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, expense: false }
      };

    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_DELETE:
      return {
        ...state,
        isFetching: { ...state.isFetching, expense: true }
      };

    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_DELETE_SUCCESS:
    case actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_DELETE_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, expense: false }
      };

    // LIABILITY FETCHING
    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_ADD:
      return {
        ...state,
        isFetching: { ...state.isFetching, liability: true }
      };

    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_ADD_SUCCESS:
    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_ADD_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, liability: false }
      };

    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE:
      return {
        ...state,
        isFetching: { ...state.isFetching, liability: true }
      };

    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_SUCCESS:
    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, liability: false }
      };

    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_DELETE:
      return {
        ...state,
        isFetching: { ...state.isFetching, liability: true }
      };

    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_DELETE_SUCCESS:
    case liabilityActionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_DELETE_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, liability: false }
      };

    // TABS SWITCH
    case actionTypes.LOAN_APPLICATION_TABS_SWITCH:
      return {
        ...state,
        tabsInfo: state.tabsInfo.map(t_Info => {
          t_Info.isDefault = t_Info.value === action.tab;
          return t_Info;
        })
      };

    // AFFORDABILITY
    case actionTypes.LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          serviceDebts: true
        }
      };

    case actionTypes.LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          serviceDebts: false
        }
      };

    case actionTypes.LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_FAILURE:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          serviceDebts: false
        }
      };

    //TODO: refactor this after the common reducer function is updated
    case actionTypes.LOAN_APPLICATION_CLIENT_ADD:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          clientAddForm: true
        }
      };

    case actionTypes.LOAN_APPLICATION_CLIENT_ADD_SUCCESS:
    case actionTypes.LOAN_APPLICATION_CLIENT_ADD_FAILURE:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          clientAddForm: false
        }
      };

    case actionTypes.LOAN_APPLICATION_NOTES_CACHE_SELECTED_NOTE:
      return {
        ...state,
        selectedNote: action.id
      };

    case actionTypes.LOAN_APPLICATION_NOTES_ADD_SUCCESS:
      return {
        ...state,
        selectedNote: action.data.id
      };

    case LOAN_REQUEST_SUBMIT:
      return {
        ...state,
        loanRequest: {
          ...state.loanRequest,
          isSubmitting: true,
          successfullyPosted: false
        }
      };

    case LOAN_REQUEST_SUBMIT_SUCCESS:
      return {
        ...state,
        loanRequest: {
          ...state.loanRequest,
          isSubmitting: false,
          successfullyPosted: true
        }
      };
    case LOAN_REQUEST_SUBMIT_FAILURE:
      return {
        ...state,
        loanRequest: {
          ...state.loanRequest,
          isSubmitting: false,
          successfullyPosted: false
        }
      };

    case actionTypes.LOAN_APPLICATION_NOTES_FETCH:
      return {
        ...state,
        loanRequest: {
          ...state.loanRequest,
          isFetchingNotes: true
        }
      };

    case actionTypes.LOAN_APPLICATION_NOTES_FETCH_SUCCESS:
    case actionTypes.LOAN_APPLICATION_NOTES_FETCH_FAILURE:
      return {
        ...state,
        loanRequest: {
          ...state.loanRequest,
          isFetchingNotes: false
        }
      };

    case coordinatorService.actionTypes.EVALUATE_SUCCESS:
      return {
        ...state,
        riskAssessmentsUpdated: true
      };

    case actionTypes.LOAN_APPLICATION_RESET_RISK_ASSESSMENTS_UPDATED_FLAG:
      return {
        ...state,
        riskAssessmentsUpdated: false
      };

    default:
      return state;
  }
};
