import { utils } from "@cauldron/core";

const { makeActionTypes } = utils;

const namespace = "coordinator";

/**
 * Generates actions
 * creates one for each REQUEST | SUCCESS | FAILURE
 */
export const actionTypes = {
  ...makeActionTypes(namespace, "EVALUATE"),
  ...makeActionTypes(namespace, "PRE_SCREEN")
};
