import { fork, call, put, takeLatest } from "redux-saga/effects";
import { LOAN_APPLICATION_BANK_REPORTS_MANUAL_ADD } from "./bank.reports.action.types";
import {
  addBankReportsManualFailure,
  addBankReportsManualSuccess
} from "./bank.reports.actions";
import { http } from "../../http/http.service";

export function* addBankReportsManual(action) {
  const res = yield* http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.BANK_REPORTS_MANUAL(action.appId, action.clientId),
        action.data
      );
    }
  });

  if (res.success) {
    yield put(addBankReportsManualSuccess());
  } else {
    yield put(addBankReportsManualFailure(res.data));
  }

  return res;
}

export function* watchDocumentAsync() {
  yield takeLatest(
    LOAN_APPLICATION_BANK_REPORTS_MANUAL_ADD,
    addBankReportsManual
  );
}

export default [fork(watchDocumentAsync)];
