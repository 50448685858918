"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createController = createController;
exports.getPCA = getPCA;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));
/**
 * createController
 * @param pcaFields
 * @param pca
 * @param eventHandlers
 * @param config
 * @returns {*}
 */


function createController(pcaFields, pca) {
  var eventHandlers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var config = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var defaults = {
    url: null,
    key: null,
    suppressAutocomplete: true,
    defaultCountry: null
  };
  var options = (0, _objectSpread2.default)({}, defaults, config);

  if (!options.key) {
    throw Error("Error: Missing CanadaPost key.");
  }

  var controller = new pca.Address(pcaFields, {
    key: options.key,
    suppressAutocomplete: options.suppressAutocomplete
  });

  if (options.defaultCountry) {
    controller.countrylist.setCountry(options.defaultCountry);
  }

  for (var key in eventHandlers) {
    if (eventHandlers.hasOwnProperty(key)) {
      var eventHandler = eventHandlers[key];
      controller.listen(key, eventHandler);
    }
  }

  return controller;
}
/**
 * getPCA
 * @param pca
 * @returns {*}
 */


function getPCA(pca) {
  if (pca) return pca;
  return window && window.pca ? window.pca : {};
}