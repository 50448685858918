import {
  TRACKER_INIT,
  TRACKER_UPDATE,
  TRACKER_UPDATE_WITH_CATEGORY,
  TRACKER_CLEAR,
  TRACKER_CLEAR_WITH_CATEGORY,
  TRACKER_COLLAPSE_ALL_UPDATE
} from ".";

export function initTrackers(tab) {
  return {
    type: TRACKER_INIT,
    tab
  };
}

export function clearTrackers() {
  return {
    type: TRACKER_CLEAR
  };
}

export function updateTracker(identifier, data) {
  return {
    type: TRACKER_UPDATE,
    identifier,
    data
  };
}

export function updateTrackerWithCategory(identifier, data, category) {
  return {
    type: TRACKER_UPDATE_WITH_CATEGORY,
    identifier,
    data,
    category
  };
}

export function clearTrackerWithCategory(category) {
  return {
    type: TRACKER_CLEAR_WITH_CATEGORY,
    category
  };
}

export function setCollapseAllState(category, value) {
  return {
    type: TRACKER_COLLAPSE_ALL_UPDATE,
    category,
    value
  };
}
