// UPLOAD FILE
export const TRANCHE_MANAGEMENT_UPLOAD_FILE = "tranche.management/UPLOAD_FILE";
export const TRANCHE_MANAGEMENT_UPLOAD_FILE_SUCCESS =
  "tranche.management/UPLOAD_FILE_SUCCESS";
export const TRANCHE_MANAGEMENT_UPLOAD_FILE_FAILURE =
  "tranche.management/UPLOAD_FILE_FAILURE";

// IMPORT TRANCHES
export const TRANCHE_MANAGEMENT_IMPORT = "tranche.management/IMPORT";
export const TRANCHE_MANAGEMENT_IMPORT_SUCCESS =
  "tranche.management/IMPORT_SUCCESS";
export const TRANCHE_MANAGEMENT_IMPORT_FAILURE =
  "tranche.management/IMPORT_FAILURE";
// CANCEL TRANCHE
export const TRANCHE_MANAGEMENT_CANCEL = "tranche.management/TRANCHE_CANCEL";
// UPDATE ERROR STATUS
export const TRANCHE_MANAGEMENT_UPDATE_ERROR_STATUS =
  "tranche.management/UPDATE_ERROR_STATUS";
