/**
 * HELPER_TEXT STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = theme => ({
  messageRoot: {
    color: theme.palette.grey[600],
    display: "flex",
    alignItems: "center",
    padding: "8px 0",

    "& .success.icon": {
      color: theme.palette.success["main"]
    },

    "& .success.message": {
      color: theme.palette.grey[900]
    },

    "& .warning": {
      color: theme.palette.warning["main"]
    },

    "& .error": {
      color: theme.palette.error["main"]
    }
  },
  iconRoot: {
    fontSize: "17px",
    color: theme.palette.grey[600],
    margin: "0 4px"
  }
});

export default styles;
