import {
  QUICK_NOTE_SUBMIT,
  QUICK_NOTE_SUBMIT_SUCCESS,
  QUICK_NOTE_SUBMIT_FAILURE,
  QUICK_NOTE_CACHE,
  QUICK_NOTE_CACHE_CLEAN
} from "../action.types/quick.notes.action.types";

// QUICK NOTE SUBMISSION
export const submitQuickNote = (data, parentId, isLoan) => ({
  type: QUICK_NOTE_SUBMIT,
  data,
  parentId,
  isLoan
});

export const submitQuickNoteSuccess = data => ({
  type: QUICK_NOTE_SUBMIT_SUCCESS,
  data
});

export const submitQuickNoteFailure = error => ({
  type: QUICK_NOTE_SUBMIT_FAILURE,
  error
});

// QUICK NOTE CACHE
export const cacheQuickNote = data => ({ type: QUICK_NOTE_CACHE, data });

export const cacheCleanQuickNote = data => ({
  type: QUICK_NOTE_CACHE_CLEAN,
  data
});
