"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var _model2 = _interopRequireDefault(require("../AppClient/model"));

var MODEL_NAME = "Application";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  id: null,
  createdDate: null,
  updatedDate: null,
  name: null,
  status: null,
  clientId: null,
  cosignerIds: [],
  emailMessageIds: [],
  loan: {},
  notes: [],
  extras: {},
  owner: {},
  referral: {},
  documents: []
};
/**
 * Protects data from change in data type
 */

var schema = {
  id: _propTypes.default.string,
  createdDate: _propTypes.default.string,
  updatedDate: _propTypes.default.string,
  name: _propTypes.default.string,
  status: _propTypes.default.string,
  clientId: _propTypes.default.string,
  cosignerIds: _propTypes.default.arrayOf(_propTypes.default.string),
  emailMessageIds: _propTypes.default.arrayOf(_propTypes.default.string),
  loan: _propTypes.default.shape({}),
  notes: _propTypes.default.array,
  extras: _propTypes.default.shape({}),
  owner: _propTypes.default.shape({}),
  referral: _propTypes.default.shape({}),
  documents: _propTypes.default.array,
  clients: _propTypes.default.array
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  var normalizedData = (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
  var clients = normalizedData.clients;
  var transformedClients = clients.map(function (client) {
    return _model2.default.transformReceived(client);
  });
  var transformed = (0, _objectSpread2.default)({}, normalizedData, {
    clients: transformedClients
  });
  return transformed;
};

var _default = {
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;