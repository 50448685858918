import {
  NOTE_PARENT_LIST,
  NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS,
  NOTE_TAB_NEW_NOTE_SUBMIT_FAILURE,
  NOTE_NEW_NOTE_SUBMIT,
  FUNDED_LOAN_NOTES_FETCH,
  FUNDED_LOAN_NOTES_FETCH_SUCCESS,
  FUNDED_LOAN_NOTES_FETCH_FAILURE,
  FUNDED_LOAN_PERSIST_NOTE,
  NOTE_DRAFT_CREATE,
  NOTE_DRAFT_UPDATE,
  NOTE_DRAFT_DELETE,
  NOTE_PRIORITY_UPDATE,
  NOTE_TAB_PRIORITY_UPDATE_SUCCESS,
  NOTE_TAB_PRIORITY_UPDATE_FAILURE
} from "../action.types/notes.action.types";

/**
 * @param data
 * @returns {{type: string, data: *}}
 */
export function addNewNoteSuccess(data) {
  return { type: NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS, data };
}

export function addNewNoteFailure(error) {
  return { type: NOTE_TAB_NEW_NOTE_SUBMIT_FAILURE, error };
}

export function addNewNote(data, parentId, isLoan) {
  return {
    type: NOTE_NEW_NOTE_SUBMIT,
    data,
    parentId,
    isLoan
  };
}

export function updateParentList(data) {
  return { type: NOTE_PARENT_LIST, data };
}

/**
 * GET NOTES
 */
export function fetchFundedLoanNotes(loanId, borrowerId, coBorrowerIdArray) {
  return {
    type: FUNDED_LOAN_NOTES_FETCH,
    loanId,
    borrowerId,
    coBorrowerIdArray
  };
}

export function fetchFundedLoanDetailsSuccess(data) {
  return {
    type: FUNDED_LOAN_NOTES_FETCH_SUCCESS,
    data
  };
}

export function fetchFundedLoanDetailsFailure(error) {
  return {
    type: FUNDED_LOAN_NOTES_FETCH_FAILURE,
    error
  };
}

export function persistSelectedNote(index, id) {
  const selectedNote = { id, index };
  return {
    type: FUNDED_LOAN_PERSIST_NOTE,
    data: selectedNote
  };
}

export function createDraftNote(data) {
  return {
    type: NOTE_DRAFT_CREATE,
    data
  };
}

export function updateDraftNote(data) {
  return {
    type: NOTE_DRAFT_UPDATE,
    data
  };
}

export function removeDraftNote() {
  return {
    type: NOTE_DRAFT_DELETE
  };
}

// PRIORITY ACTIONS
export const updateNotePriority = data => {
  return {
    type: NOTE_PRIORITY_UPDATE,
    data
  };
};

export const updateNotePrioritySuccess = data => {
  return {
    type: NOTE_TAB_PRIORITY_UPDATE_SUCCESS,
    data
  };
};

export const updateNotePriorityFailure = error => {
  return {
    type: NOTE_TAB_PRIORITY_UPDATE_FAILURE,
    error
  };
};
