export const MIME_TYPE_PDF = "application/pdf";

export const MIME_TYPE_IMAGES = [
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/x-rgb",
  "image/bmp"
];

export const MIME_TYPE_TXT = "text/plain";
