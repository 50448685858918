import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// LOCAL STYLES
import styles from "../styles/status.styles";

function Status({ classes, value, isEscalated, options }) {
  return isEscalated ? (
    <span className={classnames(classes.loanStatus)}>
      <Icon
        classes={{ root: classnames(classes.iconRoot, classes.error) }}
        color="inherit"
      >
        {options.icon}
      </Icon>
      <span className={classes.loanStatusEscalation}>{value.text}</span>
    </span>
  ) : (
    <span>{value.text}</span>
  );
}

Status.propTypes = {
  value: PropTypes.object.isRequired,
  isEscalated: PropTypes.bool.isRequired
};

export default withStyles(styles)(Status);
