"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkSessionExpiry = checkSessionExpiry;

var _distance_in_words = _interopRequireDefault(require("date-fns/distance_in_words"));

var _loglevel = require("../loglevel");

var log = (0, _loglevel.create)("authentication.utils");
/**
 * checkSessionExpiry - checks if current session has expired
 * @param expiry
 * @param tolerance - re-auth will happen within the minutes before the session expires
 * @returns {*|boolean}
 */

function checkSessionExpiry(expiry) {
  var tolerance = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;

  var isNotDefined = function isNotDefined(expiry) {
    return typeof expiry === "undefined" || expiry == null;
  }; // If there is no expiry defined return false immediately


  if (isNotDefined(expiry)) {
    return false;
  } // If the expiry values are invalid types, throw error.


  var isValidTimestamp = function isValidTimestamp(timestamp) {
    return new Date(timestamp).getTime() > 0;
  };

  if (!isNotDefined(expiry) && !isValidTimestamp(expiry)) {
    throw Error("Invalid expiry.");
  } // 1 minute = 60,000 milliseconds


  var setTolerance = tolerance * 60000; // 10 minutes default

  var now = new Date();
  var current = Date.parse(now.toString());
  var timeDifference = expiry - current;
  var isPastToleranceLevel = expiry && timeDifference < setTolerance;
  var timeRemaining = (0, _distance_in_words.default)(new Date(current), new Date(expiry));

  if (isPastToleranceLevel) {
    log.debug("Token has expired.");
  } else {
    log.debug("Token expiring in ".concat(timeRemaining, "."));
  }

  return !!isPastToleranceLevel;
}