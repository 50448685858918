import dashboardSaga from "./dashboard/engine/dashboard.saga";
import loanApplicationsSaga from "./loan.applications/engine/loan.applications.saga";
import {
  loanApplicationDetailsSaga,
  incomesSaga,
  loanApplicationScoresSaga,
  loanApplicationBankRequestSaga
} from "./loan.application.details/engine/sagas";
import fundedLoansSaga from "./funded.loans/engine/funded.loans.saga";
import {
  fundedLoanDetailsNoteSagas,
  fundedLoanDetailsLoanSagas,
  fundedLoanDetailsTabSagas,
  fundedLoanDetailsClientSagas
} from "./funded.loan.details/engine/sagas";
import trancheManagementSaga from "./tranche.management/engine/tranche.saga";
import loanApplicationLoanRequestSaga from "./loan.application.details/components/application.card/components/loanRequest/engine/loan.request.saga";
import manualScoreSaga from "./loan.application.details/engine/sagas/manual.score/manual.score.saga";

/**
 * CORE BINDINGS: PAGES SAGA
 */
export const pagesSaga = [
  ...manualScoreSaga,
  ...dashboardSaga,
  ...loanApplicationsSaga,
  ...loanApplicationDetailsSaga,
  ...loanApplicationScoresSaga,
  ...loanApplicationBankRequestSaga,
  ...incomesSaga,
  ...fundedLoansSaga,
  ...fundedLoanDetailsLoanSagas,
  ...fundedLoanDetailsTabSagas,
  ...fundedLoanDetailsNoteSagas,
  ...fundedLoanDetailsClientSagas,
  ...trancheManagementSaga,
  ...loanApplicationLoanRequestSaga
];

/**
 * CORE BINDINGS: PAGE REDUCERS
 */
export { default as pagesReducers } from "./pages.reducers";

export { menuList } from "./pages.modules";
