import { createSelector } from "reselect";
import { APPLICATION_PURPOSE_TYPES } from "./agent.portal.applications.constants";

const agentPortalApplication = state => state.data.agentPortalApplication;

export const getAgentPortalApplication = createSelector(
  agentPortalApplication,
  data => data
);

export const getPersonalDetailsIsVisible = createSelector(
  agentPortalApplication,
  data => {
    const app = data.application || { application: {} };
    if (!app.loanPurpose || !app.term || !app.loanAmount) {
      return false;
    } else {
      if (app.loanPurpose === APPLICATION_PURPOSE_TYPES[1]) {
        return true;
      } else if (!app.referrerId) {
        return false;
      }
    }
    return true;
  }
);

export const getAgentPortalClients = createSelector(
  agentPortalApplication,
  data => data.applicants || []
);
