import deburr from "lodash/deburr";
import { utils } from "@cauldron/core";

const { getSelectedOption } = utils;

/**
 * getFilteredOptions
 * @param options
 * @param value
 * @param filterOptions
 * @returns {Array}
 */
function getFilteredOptions(options, value, filterOptions = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;

  const fOptions = {
    strategy: "CONTAINS",
    termLength: 0,
    ...filterOptions
  };

  if (fOptions.termLength > inputLength) return [];

  const { strategy } = fOptions;

  if (strategy && typeof strategy === "function") {
    return options.filter(strategy);
  }

  const filterFn = {
    CONTAINS: ({ label }) => {
      return label && label.toLowerCase().indexOf(inputValue) > -1;
    },
    STARTS_WITH: ({ label }) => {
      return label && label.slice(0, inputLength).toLowerCase() === inputValue;
    }
  };

  return options.filter(filterFn[strategy]);
}

/**
 * normalizeValue
 * @param options
 * @param value
 * @param includeNonOptionData
 * @returns {*}
 */
function normalizeValue(options, value, includeNonOptionData = true) {
  if (value == null || typeof value === "undefined") {
    return { label: "", value: "" };
  }

  const foundSelectedItem = getSelectedOption(options, value);
  if (!foundSelectedItem) {
    return includeNonOptionData ? { label: value, value } : "";
  }

  return foundSelectedItem;
}

export { normalizeValue, getFilteredOptions };
