import { fork, call, put, takeLatest, all } from "redux-saga/effects";
import { delay } from "redux-saga";
import { domain } from "@cauldron/core";
import {
  UPLOAD_BANK_STATEMENT_REQUEST,
  GET_DOCUMENT_REQUEST,
  GET_OUTSTANDING_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_REQUEST,
  EDIT_DOCUMENT_REQUEST
} from "./document.action.types";
import {
  getDocumentRequest,
  getDocumentSuccess,
  editDocumentSuccess,
  getOutstandingDocumentSuccess,
  selectDocEntry
} from "./document.actions";
import { http } from "../../http/http.service";
import { showSuccess } from "../../messenger/messenger.actions";
import {
  deleteCachedForm,
  isFetchingForm,
  addFormSectionError
} from "../../forms/forms.actions";
import { SUCCESS_MESSAGES } from "../../../core/constants";
import { DOCS_TAB_MODE } from "../../../core/constants/ui";

const { Documentation } = domain;

export const getDocument = action => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.get, API.APPLICATION_DOCUMENTS(action.applicationId));
    },
    success: data => put(getDocumentSuccess(data)),
    transformReceiveData: Documentation.collection.transformReceived
  });
};

export const getOutstandingDocs = action => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.get,
        API.APPLICATION_OUTSTANDING_DOCS(action.applicationId)
      );
    },
    success: data => put(getOutstandingDocumentSuccess(data))
  });
};

export const uploadBankStatement = action => {
  return http({
    request: (httpSrv, API) => {
      const data = new FormData();
      data.append("content", action.data.file);
      data.append("classification", action.data.documentType || "");
      data.append("parent-type", "Client");
      data.append("parent-id", action.data.clientId);
      // TODO: add a httpSrv.upload mode
      const reqConfig = {
        url: API.APPLICATION_DOCUMENTS(action.data.applicationId),
        data: data,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      return all([call(httpSrv.request, reqConfig), delay(8000)]);
    },
    success: () =>
      all([
        put(getDocumentRequest(action.data.applicationId)),
        put(showSuccess(SUCCESS_MESSAGES.BANK_REQUEST.UPLOAD)),
        put(deleteCachedForm(action.data.formId))
      ]),
    error: error =>
      all([
        put(addFormSectionError(action.data.formId, error)),
        put(isFetchingForm(action.data.formId, false))
      ])
  });
};

export const uploadDocument = action => {
  return http({
    request: (httpSrv, API) => {
      const data = new FormData();
      const actionData = action.data;
      data.append("content", actionData.file);
      data.append("classification", actionData.documentType);
      data.append(
        "parent-type",
        actionData.parentId === actionData.loanId ? "Loan" : "Client"
      );
      data.append("parent-id", actionData.parentId);
      data.append("status", actionData.reviewState);
      data.append("description", actionData.description);

      const reqConfig = {
        url: API.APPLICATION_DOCUMENTS(actionData.applicationId),
        data: data,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      return all([call(httpSrv.request, reqConfig), delay(8000)]);
    },
    success: () =>
      all([
        put(getDocumentRequest(action.data.applicationId)),
        put(selectDocEntry(DOCS_TAB_MODE.DOCS)),
        put(showSuccess(SUCCESS_MESSAGES.DOCUMENT.ADD)),
        put(deleteCachedForm(action.data.formId))
      ]),
    error: error =>
      all([
        put(addFormSectionError(action.data.formId, error)),
        put(isFetchingForm(action.data.formId, false))
      ])
  });
};

export const editDocument = action => {
  const actionData = action.data;
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.APPLICATION_DOCUMENT(actionData.applicationId, actionData.docId),
        {
          classification: actionData.documentType,
          parentId: actionData.parentId,
          status: actionData.reviewState,
          description: actionData.description,
          parentType:
            actionData.parentId === actionData.loanId ? "Loan" : "Client"
        }
      );
    },
    success: data =>
      all([
        put(editDocumentSuccess(data)),
        put(showSuccess(SUCCESS_MESSAGES.DOCUMENT.UPDATE)),
        put(deleteCachedForm(actionData.formId))
      ]),
    error: error =>
      all([
        put(addFormSectionError(actionData.formId, error)),
        put(isFetchingForm(actionData.formId, false))
      ])
  });
};

export function* watchDocumentAsync() {
  yield takeLatest(UPLOAD_BANK_STATEMENT_REQUEST, uploadBankStatement);
  yield takeLatest(GET_DOCUMENT_REQUEST, getDocument);
  yield takeLatest(GET_OUTSTANDING_DOCUMENT_REQUEST, getOutstandingDocs);
  yield takeLatest(UPLOAD_DOCUMENT_REQUEST, uploadDocument);
  yield takeLatest(EDIT_DOCUMENT_REQUEST, editDocument);
}

export default [fork(watchDocumentAsync)];
