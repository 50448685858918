import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { styles } from "../styles/breadcrumb.styles";
import { AlchemyTypography } from "../../AlchemyTypography";

const Crumb = ({
  index,
  classes,
  label,
  path,
  modLength,
  dataTestId,
  suppressTracking
}) => (
  <Fragment>
    <Link data-test={dataTestId} to={path} className={classes.crumb}>
      <AlchemyTypography inline suppressTracking={suppressTracking}>
        {label}
      </AlchemyTypography>
    </Link>
    {index < modLength - 1 && (
      <Icon color="action" className={classes.crumbIcon}>
        chevron_right
      </Icon>
    )}
  </Fragment>
);

Crumb.propTypes = {
  dataTestId: PropTypes.string
};

export default withStyles(styles)(Crumb);
