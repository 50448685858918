import PropTypes from "prop-types";

/**
 * FUNDED LOAN DETAILS MODEL
 */
export const defaultProps = {
  id: "",
  applicationId: "",
  loanNumber: "",
  trancheId: "",
  status: {
    active: true,
    codes: [],
    status: ""
  },
  interestRate: 0,
  compoundingFrequency: "",
  fundedDate: "",
  originalEndDate: "",
  actualEndDate: "",
  term: 0,
  termUnit: "",
  originalLoanNumbers: [],
  refinancedLoanNumbers: [],
  loanType: "",
  loanValue: 0,
  totalOwingAmount: 0,
  pastDueAmount: 0,
  diemAmount: 0,
  daysBehind: 0,
  nextPaymentDate: "",
  paymentAmount: 0,
  preAuthDebitAmount: 0,
  paymentFrequency: "",
  debitSchedules: [],
  firstPaymentDate: "",
  referral: {
    agent: {
      abbreviation: "",
      companyId: "",
      emails: [
        {
          email: "",
          id: "",
          label: "",
          primary: true
        }
      ],
      id: "",
      name: ""
    },
    company: {
      abbreviation: "",
      id: "",
      name: ""
    },
    policy: ""
  },
  merchantName: "",
  clients: [
    {
      address: {
        city: "",
        country: "",
        postalCode: "",
        province: "",
        streetAddress: "",
        unitNumber: ""
      },
      applicantId: "",
      authUsers: [],
      bankAccount: {
        id: "",
        institutionName: "",
        institutionNumber: "",
        branchNumber: "",
        accountNumber: "",
        type: ""
      },
      dateOfBirth: "",
      dateOfDeath: "",
      deceased: true,
      emailAddress: "",
      fraud: "",
      gender: "",
      id: "",
      incomeSources: [
        {
          description: "",
          employment: true,
          employmentDetails: {
            address: {
              city: "",
              country: "",
              postalCode: "",
              province: "",
              streetAddress: "",
              unitNumber: ""
            },
            companyName: "",
            contactName: "",
            contactPosition: "",
            jobType: "",
            phoneNumber: {
              id: "",
              countryCode: "",
              number: "",
              ext: "",
              type: "",
              primary: true
            },
            position: ""
          },
          endMonth: 0,
          endYear: 0,
          id: "",
          income: 0,
          payFrequency: "",
          samplePayDate: "",
          startMonth: 0,
          startYear: 0,
          type: ""
        }
      ],
      phones: [
        {
          id: "",
          countryCode: "",
          number: "",
          ext: "",
          type: "",
          primary: true,
          smsVerified: true,
          flags: []
        }
      ],
      primary: true,
      sin: "",
      suffix: "",
      tuRiskScore: "",
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      maritalStatus: ""
    }
  ],
  bankAccount: {
    id: "",
    institutionName: "",
    institutionNumber: "",
    branchNumber: "",
    accountNumber: "",
    type: ""
  },
  underwriter: {
    email: "",
    name: ""
  },
  officer: {
    email: "",
    name: ""
  },
  debtServiceRatio: {
    futureDebtService: 0,
    futureGrossDebtService: 0,
    grossAnnualIncome: 0,
    grossDebtService: 0,
    loanCollateralExpenses: 0,
    loanExpenses: 0,
    otherLiabilityPayments: 0,
    progressaLoanExpense: 0,
    totalDebtService: 0
  },
  insurancePremium: 0,
  insuranceTax: 0,
  adminFee: 0,
  progressaScore: "",
  upfrontAdminFee: 0,
  disbursements: [
    {
      amount: 0,
      id: "",
      liabilityId: "",
      merchantVertical: "",
      recipient: "",
      type: ""
    }
  ],
  purchasePrice: 0,
  metro2: {
    accountStatus: "",
    paymentRating: "",
    paymentHistory: [
      {
        code: "",
        month: "",
        year: 0
      }
    ],
    paymentHistoryRaw: "",
    lastReportedDate: ""
  },
  loanStats: {
    commentCounts: 0,
    documentCounts: 0
  }
};
export const schema = {
  id: PropTypes.string,
  applicationId: PropTypes.string,
  loanNumber: PropTypes.string,
  trancheId: PropTypes.string,
  status: PropTypes.shape({
    active: PropTypes.bool,
    codes: PropTypes.array,
    status: PropTypes.string
  }),
  interestRate: PropTypes.number,
  compoundingFrequency: PropTypes.string,
  fundedDate: PropTypes.string,
  originalEndDate: PropTypes.string,
  actualEndDate: PropTypes.string,
  term: PropTypes.number,
  termUnit: PropTypes.string,
  originalLoanNumbers: PropTypes.array,
  refinancedLoanNumbers: PropTypes.array,
  loanType: PropTypes.string,
  loanValue: PropTypes.number,
  totalOwingAmount: PropTypes.number,
  pastDueAmount: PropTypes.number,
  diemAmount: PropTypes.number,
  daysBehind: PropTypes.number,
  nextPaymentDate: PropTypes.string,
  paymentAmount: PropTypes.number,
  preAuthDebitAmount: PropTypes.number,
  paymentFrequency: PropTypes.string,
  debitSchedules: PropTypes.array,
  firstPaymentDate: PropTypes.string,
  referral: PropTypes.shape({
    agent: PropTypes.shape({
      abbreviation: PropTypes.string,
      companyId: PropTypes.string,
      emails: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string,
          id: PropTypes.string,
          label: PropTypes.string,
          primary: PropTypes.bool
        })
      ),
      id: PropTypes.string,
      name: PropTypes.string
    }),
    company: PropTypes.shape({
      abbreviation: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string
    }),
    policy: PropTypes.string
  }),
  merchantName: PropTypes.string,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        postalCode: PropTypes.string,
        province: PropTypes.string,
        streetAddress: PropTypes.string
      }),
      applicantId: PropTypes.string,
      authUsers: PropTypes.arrayOf(
        PropTypes.shape({
          dateOfBirth: PropTypes.string,
          emailAddress: PropTypes.string,
          firstName: PropTypes.string,
          id: PropTypes.string,
          lastName: PropTypes.string,
          middleName: PropTypes.string,
          phoneNumber: PropTypes.shape({
            id: PropTypes.string,
            countryCode: PropTypes.string,
            number: PropTypes.string,
            ext: PropTypes.string,
            type: PropTypes.string,
            primary: PropTypes.bool,
            smsVerified: PropTypes.bool,
            flags: PropTypes.array
          }),
          relationship: PropTypes.string
        })
      ),
      bankAccount: PropTypes.shape({
        id: PropTypes.string,
        institutionName: PropTypes.string,
        institutionNumber: PropTypes.string,
        branchNumber: PropTypes.string,
        accountNumber: PropTypes.string,
        type: PropTypes.string
      }),
      dateOfBirth: PropTypes.string,
      dateOfDeath: PropTypes.string,
      deceased: PropTypes.bool,
      emailAddress: PropTypes.string,
      fraud: PropTypes.string,
      gender: PropTypes.string,
      id: PropTypes.string,
      incomeSources: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          employment: PropTypes.bool,
          employmentDetails: PropTypes.shape({
            address: PropTypes.shape({
              city: PropTypes.string,
              country: PropTypes.string,
              postalCode: PropTypes.string,
              province: PropTypes.string,
              streetAddress: PropTypes.string
            }),
            companyName: PropTypes.string,
            contactName: PropTypes.string,
            contactPosition: PropTypes.string,
            jobType: PropTypes.string,
            phoneNumber: PropTypes.shape({
              id: PropTypes.string,
              countryCode: PropTypes.string,
              number: PropTypes.string,
              ext: PropTypes.string,
              type: PropTypes.string,
              primary: PropTypes.bool
            }),
            position: PropTypes.string
          }),
          endMonth: PropTypes.number,
          endYear: PropTypes.number,
          id: PropTypes.string,
          income: PropTypes.number,
          payFrequency: PropTypes.string,
          samplePayDate: PropTypes.string,
          startMonth: PropTypes.number,
          startYear: PropTypes.number,
          type: PropTypes.string
        })
      ),
      phones: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          countryCode: PropTypes.string,
          number: PropTypes.string,
          ext: PropTypes.string,
          type: PropTypes.string,
          primary: PropTypes.bool,
          smsVerified: PropTypes.bool,
          flags: PropTypes.array
        })
      ),
      primary: PropTypes.bool,
      sin: PropTypes.string,
      suffix: PropTypes.string,
      tuRiskScore: PropTypes.string,
      title: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      maritalStatus: PropTypes.string
    })
  ),
  bankAccount: PropTypes.shape({
    id: PropTypes.string,
    institutionName: PropTypes.string,
    institutionNumber: PropTypes.string,
    branchNumber: PropTypes.string,
    accountNumber: PropTypes.string,
    type: PropTypes.string
  }),
  underwriter: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string
  }),
  officer: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string
  }),
  debtServiceRatio: PropTypes.shape({
    futureDebtService: PropTypes.number,
    futureGrossDebtService: PropTypes.number,
    grossAnnualIncome: PropTypes.number,
    grossDebtService: PropTypes.number,
    loanCollateralExpenses: PropTypes.number,
    loanExpenses: PropTypes.number,
    otherLiabilityPayments: PropTypes.number,
    progressaLoanExpense: PropTypes.number,
    totalDebtService: PropTypes.number
  }),
  insurancePremium: PropTypes.number,
  insuranceTax: PropTypes.number,
  adminFee: PropTypes.number,
  progressaScore: PropTypes.string,
  upfrontAdminFee: PropTypes.number,
  disbursements: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      id: PropTypes.string,
      liabilityId: PropTypes.string,
      merchantVertical: PropTypes.string,
      recipient: PropTypes.string,
      type: PropTypes.string
    })
  ),
  purchasePrice: PropTypes.number,
  metro2: PropTypes.shape({
    accountStatus: PropTypes.string,
    paymentRating: PropTypes.string,
    paymentHistory: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.number
      })
    ),
    paymentHistoryRaw: PropTypes.string,
    lastReportedDate: PropTypes.string
  }),
  loanStats: PropTypes.shape({
    commentCounts: PropTypes.number,
    documentCounts: PropTypes.number
  })
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-loan-details";
