import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getTrackedComponents,
  updateTracker,
  updateTrackerWithCategory
} from ".";

function mapStateToProps(state) {
  return {
    trackedComponents: getTrackedComponents(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateTracker: bindActionCreators(updateTracker, dispatch),
    updateTrackerWithCategory: bindActionCreators(
      updateTrackerWithCategory,
      dispatch
    )
  };
}

export class Tracker extends Component {
  constructor(props) {
    super(props);
    this.handleExpandClick = this.handleExpandClick.bind(this);
  }

  handleExpandClick(value) {
    const {
      updateTracker,
      updateTrackerWithCategory,
      trackerId,
      category
    } = this.props;

    if (!category) updateTracker(trackerId, { expanded: value });
    if (category)
      updateTrackerWithCategory(trackerId, { expanded: value }, category);
  }

  render() {
    const { component, trackerId, trackedComponents, category } = this.props;
    const TrackedComponent = component;
    let tracker = null;
    if (trackedComponents) {
      if (
        category &&
        trackedComponents[category] &&
        trackedComponents[category][trackerId]
      ) {
        tracker = trackedComponents[category][trackerId];
      } else if (trackedComponents[trackerId]) {
        tracker = trackedComponents[trackerId];
      } else if (
        category &&
        trackedComponents[category] &&
        trackedComponents[category]["collapseAll"]
      ) {
        tracker = { expanded: false };
      }
    }

    return (
      <TrackedComponent
        {...this.props}
        handleExpandClick={this.handleExpandClick}
        tracker={tracker}
      />
    );
  }
}

Tracker.propTypes = {
  adornments: PropTypes.any
};

export default function withTracker() {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Tracker);
}
