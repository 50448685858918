//APP
export const APP_HEADER = "app-header";
export const APP_SECONDARY_HEADER = "app-secondary-header";

// GENERIC
export const LOGO = "logo";
export const SUBMIT = "submit";
export const HEADER = "header";
export const CONTENT = "content";
export const FOOTER = "footer";
export const FORM = "form";
export const EDIT = "edit";
export const DELETE = "delete";
export const COPY = "copy";
export const BUTTON = "button";
export const WRAPPER = "wrapper";
export const ROOT = "root";
export const ICON = "icon";
export const TITLE = "title";

// PAGES
export const LOGIN_PAGE = "login-page";
export const APP_DASHBOARD_PAGE = "app-dashboard-page";
export const FL_DETAILS_PAGE = "fl-details-page";
export const FL_SEARCH_PAGE = "fl-search-page";
export const LA_DETAILS_PAGE = "la-details-page";
export const LA_SEARCH_PAGE = "la-search-page";
export const FL_MAIN_PAGE = "fl-main-page";
export const LA_MAIN_PAGE = "la-main-page";
export const APP_MAIN_DASHBOARD_PAGE = "app-main-dashboard-page";

// CARDS
export const CLIENT_CARDS = "client-cards";
export const STATUS_CARD = "status-card";

// BUTTONS
export const ADD_QUICK_NOTE = "add-quick-note";
export const ADD_QUICK_NOTE_ICON = "add-quick-note-icon";
export const ADD_QUICK_NOTE_LABEL = "add-quick-note-label";
export const AVATAR_ICON = "avatar-icon";
export const MODULES_ICON = "modules-icon";
export const LOGO_ICON = "logo-icon";
export const ADD_NEW_COSIGNER = "add-new-cosigner";
export const ADD_NEW_APPLICANT = "add-new-applicant";
export const DUPLICATE_APPLICATION = "duplicate-application";

// BREADCRUMB LINKS
export const BREADCRUMB_NAV = "breadcrumb-nav";

// AUX
export const AVATAR_MENU_ITEM = "logout";
export const MODULES_MENU_LA = "la-module";
export const MODULES_MENU_FL = "fl-module";
export const LA_TILE = "la-tile";
export const FL_TILE = "fl-tile";
export const TM_TILE = "tm-tile";

// SEARCH PAGE (COMMON CASES IN BOTH FL & LA)
export const SEARCH_TOOLBAR = "toolbar";
export const SEARCH_TOOLBAR_SEARCH = "search";
export const SEARCH_TOOLBAR_SEARCH_INPUT = "input";
export const SEARCH_TOOLBAR_SEARCH_ICON_BTN = "icon";
export const SEARCH_TOOLBAR_SEARCH_CLEAR_BTN = "clear-icon";
export const SEARCH_TOOLBAR_SETTINGS = "settings";
export const SEARCH_TOOLBAR_SETTINGS_BTN = "button";
export const SEARCH_TOOLBAR_SETTINGS_POPPER = "popper";
export const SEARCH_TOOLBAR_SETTINGS_POPPER_MENU = "menu";
export const SEARCH_TOOLBAR_SETTINGS_POPPER_MENU_ITEM = "item";
export const SEARCH_TOOLBAR_SETTINGS_POPPER_MENU_CHECKBOX = "checkbox";
export const SEARCH_TOOLBAR_SETTINGS_ACTION_GROUP = "action-group";
export const SEARCH_TOOLBAR_SETTINGS_ACTION_RESTORE = "action-restore";
export const SEARCH_TABLE = "search-table";
export const SEARCH_TABLE_RESULT_ROW = "result-row";
export const SEARCH_TABLE_SORT_BUTTON = "sort-button";
export const SEARCH_TABLE_SORT_BUTTON_DEC = "sort-button-dec";
export const SEARCH_TABLE_SORT_BUTTON_ASC = "sort-button-asc";
export const SEARCH_TABLE_HEADER = "column-header";
export const SEARCH_TABLE_PAGINATION = "footer-pagination";
export const SEARCH_TABLE_PAGINATION_NEXT = "pagination-next-btn";
export const SEARCH_TABLE_PAGINATION_PREV = "pagination-prev-btn";
export const SEARCH_TABLE_PAGINATION_CENTER = "pagination-center";
export const SEARCH_TABLE_PAGINATION_PAGE_INFO = "pagination-page-info";
export const SEARCH_TABLE_PAGINATION_PAGE_NUMBER = "pagination-page-number";

// STATUS CARD (COMMON CASES IN BOTH FL & LA)
export const STATUS_CARD_HEADER = "header-text";
export const STATUS_CARD_BODY = "body-text";
