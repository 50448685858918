import { utils } from "@cauldron/core";
import * as actionTypes from "../action.types/loan.application.details.action.types";
import { actionTypes as statusActionTypes } from "../../../../services/data/status";

const { makeActionCreator } = utils;

export const updatePersonalDetails = (
  formId,
  data,
  clientId,
  eventHandlers
) => {
  return {
    type: actionTypes.LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_REQUEST,
    formId,
    data,
    clientId,
    eventHandlers
  };
};

export const updatePersonalDetailsSuccess = (clientId, data) => {
  return {
    type: actionTypes.LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_SUCCESS,
    data,
    clientId
  };
};

export const updatePersonalDetailsFailure = () => {
  return {
    type: actionTypes.LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_FAILURE
  };
};

// APPLICATION DETAILS (EXTENDED)

export const fetchLoanApplicationDetails = applicationId => ({
  type: actionTypes.LOAN_APPLICATION_DETAILS_FETCH,
  applicationId
});

export const fetchLoanApplicationDetailsSuccess = payload => ({
  type: actionTypes.LOAN_APPLICATION_DETAILS_FETCH_SUCCESS,
  payload
});

export const fetchLoanApplicationDetailsFailure = error => ({
  type: actionTypes.LOAN_APPLICATION_DETAILS_FETCH_FAILURE,
  error
});

// APPLICATION NOTES

export const fetchLoanApplicationNotes = applicationId => ({
  type: actionTypes.LOAN_APPLICATION_NOTES_FETCH,
  applicationId
});

export const fetchLoanApplicationNotesSuccess = data => {
  const { results } = data;
  return {
    type: actionTypes.LOAN_APPLICATION_NOTES_FETCH_SUCCESS,
    data: results
  };
};

export const addLoanApplicationNotes = makeActionCreator(
  actionTypes.LOAN_APPLICATION_NOTES_ADD,
  "applicationId",
  "formId",
  "data"
);

export const addLoanApplicationNotesSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_NOTES_ADD_SUCCESS,
  "data"
);

export const addLoanApplicationNotesFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_NOTES_ADD_FAILURE,
  "data"
);

export const fetchLoanApplicationNotesFailure = error => ({
  type: actionTypes.LOAN_APPLICATION_NOTES_FETCH_FAILURE,
  error
});

export const cacheNote = id => ({
  type: actionTypes.LOAN_APPLICATION_NOTES_CACHE_SELECTED_NOTE,
  id
});

// APPLICATION EMAILS

export const fetchLoanApplicationEmails = applicationId => ({
  type: actionTypes.LOAN_APPLICATION_EMAILS_FETCH,
  applicationId
});

export const fetchLoanApplicationEmailsSuccess = data => ({
  type: actionTypes.LOAN_APPLICATION_EMAILS_FETCH_SUCCESS,
  data
});

export const fetchLoanApplicationEmailsFailure = error => ({
  type: actionTypes.LOAN_APPLICATION_EMAILS_FETCH_FAILURE,
  error
});

// APPLICATION SCORES

export const fetchLoanApplicationScores = applicationId => ({
  type: actionTypes.LOAN_APPLICATION_SCORES_FETCH,
  applicationId
});

export const fetchLoanApplicationScoresSuccess = data => ({
  type: actionTypes.LOAN_APPLICATION_SCORES_FETCH_SUCCESS,
  data
});

export const fetchLoanApplicationScoresFailure = error => ({
  type: actionTypes.LOAN_APPLICATION_SCORES_FETCH_FAILURE,
  error
});

// APPLICATION TAB SWITCH

export const switchTab = tab => ({
  type: actionTypes.LOAN_APPLICATION_TABS_SWITCH,
  tab
});

// APPLICATION AFFORDABILITY

export const fetchDebtServiceRatio = applicationId => ({
  type: actionTypes.LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH,
  applicationId
});

export const fetchDebtServiceRatioSuccess = data => ({
  type: actionTypes.LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_SUCCESS,
  data
});

export const fetchDebtServiceRatioFailure = error => ({
  type: actionTypes.LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_FAILURE,
  error
});

// CLIENT EXPENSE - ADD

export const addClientExpense = (applicationId, clientId, data, index) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_ADD,
  applicationId,
  clientId,
  data,
  index
});

export const addClientExpenseSuccess = (clientId, data) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_SUCCESS,
  clientId,
  data
});

export const addClientExpenseFailure = (clientId, error) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_FAILURE,
  clientId,
  error
});

// CLIENT EXPENSE - UPDATE

export const updateClientExpense = (
  applicationId,
  clientId,
  expenseId,
  data
) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE,
  applicationId,
  clientId,
  expenseId,
  data
});

export const updateClientExpenseSuccess = (clientId, expenseId, data) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_SUCCESS,
  clientId,
  expenseId,
  data
});

export const updateClientExpenseFailure = (clientId, error) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_FAILURE,
  clientId,
  error
});

// CLIENT EXPENSE - DELETE

export const deleteClientExpense = (applicationId, clientId, expenseId) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_DELETE,
  applicationId,
  clientId,
  expenseId
});

export const deleteClientExpenseSuccess = (clientId, expenseId) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_DELETE_SUCCESS,
  clientId,
  expenseId
});

export const deleteClientExpenseFailure = (clientId, error) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_DELETE_FAILURE,
  clientId,
  error
});

// CLIENT EXPENSE - CACHE

export const cacheAddClientExpense = data => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_CACHE,
  data
});

export const cleanAddCacheExpense = (clientId, index) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_CACHE_CLEAN,
  id: clientId,
  index
});

export const cleanUpdateCacheExpense = expenseId => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_CACHE_CLEAN,
  id: expenseId
});

export const cacheUpdateClientExpense = data => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_CACHE,
  data
});

// Cosigner (client) form - cache
export const cacheAddClientForm = (applicationId, data) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_ADD_CACHE,
  data: {
    id: applicationId,
    index: 0,
    values: data
  }
});

export const cleanAddCacheClient = applicationId => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_ADD_CACHE_CLEAN,
  id: applicationId
});

// Cosigner add submit
export const addClient = (applicationId, data) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_ADD,
  applicationId,
  data
});

export const linkClientToApplication = (
  clientId,
  applicationId,
  payload,
  response
) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_APPLICATION_LINK,
  clientId,
  applicationId,
  payload,
  response
});

export const addClientSuccess = (applicationId, clientId, data) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_ADD_SUCCESS,
  applicationId,
  clientId,
  data
});

export const addClientFailure = (applicationId, clientId, error) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_ADD_FAILURE,
  applicationId,
  clientId,
  error
});

export const deleteClient = (applicationId, clientId) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_DELETE,
  applicationId,
  data: {
    clientId
  }
});

export const deleteClientSuccess = clientId => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_DELETE_SUCCESS,
  clientId
});

export const deleteClientFailure = () => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_DELETE_FAILURE
});

// DEAL LEAD
export const fetchDealLeadOptions = () => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH
});

export const fetchDealLeadOptionsSuccess = data => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH_SUCCESS,
  data
});

export const fetchDealLeadOptionsFailure = () => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH_FAILURE
});

export const cacheUpdateClientDealLead = data => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_CACHE_FORM,
  data
});

export const cacheUpdateClientReferral = cacheData => ({
  type: actionTypes.LOAN_APPLICATION_REFERRAL_SOURCES_CACHE_FORM,
  data: cacheData
});

export const cleanUpdateClientReferral = companyId => ({
  type: actionTypes.LOAN_APPLICATION_REFERRAL_SOURCES_CLEAN_FORM,
  id: companyId
});

export const cacheUpdateClientReferralValues = (id, values, options) => ({
  type: actionTypes.LOAN_APPLICATION_REFERRAL_SOURCES_CACHE_VALUES,
  id,
  values,
  options
});

export const cleanUpdateClientDealLead = applicationId => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_CLEAN_FORM,
  id: applicationId
});

export const cacheUpdateClientDealLeadValues = (applicationId, values) => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_CACHE_VALUES,
  id: applicationId,
  values
});

export const updateDealLead = (applicationId, dealLeadId) => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_UPDATE,
  applicationId,
  data: { value: dealLeadId }
});

export const updateDealLeadSuccess = data => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_UPDATE_SUCCESS,
  data
});

export const updateDealLeadFailure = () => ({
  type: actionTypes.LOAN_APPLICATION_DEAL_LEAD_UPDATE_FAILURE
});

export const updateReferralSource = (applicationId, updateId) => ({
  type: actionTypes.LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE,
  applicationId,
  data: {
    value: updateId
  }
});

export const updateReferralSourceSuccess = data => ({
  type: actionTypes.LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE_SUCCESS,
  data
});

export const updateReferralSourceFailure = () => ({
  type: actionTypes.LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE_FAILURE
});

export const fetchCreditReportSummary = (applicationId, clientId) => ({
  type: actionTypes.LOAN_APPLICATION_GET_CREDIT_REPORT_SUMMARY,
  applicationId,
  clientId
});

export const fetchCreditReportSummarySuccess = (clientId, data) => ({
  type: actionTypes.LOAN_APPLICATION_GET_CREDIT_REPORT_SUMMARY_SUCCESS,
  clientId,
  data
});

export const addClientEmail = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_ADD,
  "clientId",
  "formId",
  "data"
);

export const addClientEmailSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_ADD_SUCCESS,
  "clientId",
  "data"
);

export const addClientEmailFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_ADD_FAILURE
);

export const updateClientEmail = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_UPDATE,
  "clientId",
  "emailId",
  "formId",
  "data"
);
export const updateClientEmailSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_UPDATE_SUCCESS,
  "emailId",
  "clientId",
  "data"
);
export const updateClientEmailFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_UPDATE_FAILURE
);

export const deleteClientEmail = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_DELETE,
  "clientId",
  "emailId",
  "formId"
);
export const deleteClientEmailSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_DELETE_SUCCESS,
  "clientId",
  "emailId"
);

export const deleteClientEmailFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_EMAIL_DELETE_FAILURE
);

// PHONE NUMBERS

export const addPhoneNumber = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_ADD,
  "clientId",
  "formId",
  "data"
);

export const addPhoneNumberSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_ADD_SUCCESS,
  "clientId",
  "data"
);

export const addPhoneNumberFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_ADD_FAILURE
);

export const updatePhoneNumber = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_UPDATE_REQUEST,
  "clientId",
  "phoneId",
  "formId",
  "data",
  "eventHandlers"
);
export const updatePhoneNumberSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_UPDATE_SUCCESS,
  "phoneId",
  "clientId",
  "data"
);
export const updatePhoneNumberFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_UPDATE_FAILURE
);

export const deletePhoneNumber = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_DELETE,
  "clientId",
  "phoneId",
  "formId"
);
export const deletePhoneNumberSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_DELETE_SUCCESS,
  "clientId",
  "phoneId"
);

export const deletePhoneNumberFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_PHONE_NUMBER_DELETE_FAILURE
);

export const addClientResidence = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_ADD,
  "clientId",
  "formId",
  "data"
);

export const addClientResidenceSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_ADD_SUCCESS,
  "clientId",
  "data"
);

export const addClientResidenceFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_ADD_FAILURE
);

export const updateClientResidence = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_REQUEST,
  "clientId",
  "residenceId",
  "formId",
  "data",
  "eventHandlers"
);
export const updateClientResidenceSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_SUCCESS,
  "residenceId",
  "clientId",
  "data"
);
export const updateClientResidenceFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_FAILURE
);

export const deleteClientResidence = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE,
  "clientId",
  "residenceId",
  "formId"
);
export const deleteClientResidenceSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE_SUCCESS,
  "clientId",
  "residenceId"
);

export const deleteClientResidenceFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE_FAILURE
);

// update loan application status
export const updateStatus = makeActionCreator(
  statusActionTypes.LOAN_APPLICATION_STATUS_UPDATE_REQUEST,
  "applicationId",
  "formId",
  "data",
  "onSuccesAction"
);

export const updateStatusSuccess = makeActionCreator(
  statusActionTypes.LOAN_APPLICATION_STATUS_UPDATE_SUCCESS,
  "data"
);

export const updateStatusFailure = makeActionCreator(
  statusActionTypes.LOAN_APPLICATION_STATUS_UPDATE_FAILURE
);

export const resetRiskAssessmentsUpdatedFlag = makeActionCreator(
  actionTypes.LOAN_APPLICATION_RESET_RISK_ASSESSMENTS_UPDATED_FLAG
);
