import { i18nMark } from "@lingui/react";

export const ERROR_MESSAGES = {
  DEFAULT: i18nMark("Something went wrong. [Error: -1]"),
  DEFAULT_MULTIPLE: i18nMark("A number of errors happened.")
};

// http status error codes
export const HTTP_STATUS_ERROR_CODES = {
  401: i18nMark("Your session timed-out. Please re-login."),
  500: i18nMark("Something went wrong. [Error: 500]"),
  501: i18nMark("Something went wrong. [Error: 501]"),
  502: i18nMark("Something went wrong. [Error: 502]"),
  503: i18nMark("Something went wrong. [Error: 503]"),
  504: i18nMark("Something went wrong. [Error: 504]"),
  505: i18nMark("Something went wrong. [Error: 505]"),
  511: i18nMark("Something went wrong. [Error: 511]")
};

// http sub error codes
export const HTTP_SUB_ERROR_CODES = {
  1001: i18nMark("First name is required."),
  1100: i18nMark("Request contains field with invalid value."),
  1101: i18nMark("Date of birth not ISO 8601 compliant."),
  1503: i18nMark("A user with that email already exists in our system."),
  1506: i18nMark("Can not update application that has already been submitted."),
  2002: i18nMark("Retry later."),
  3005: i18nMark("Not found."),
  9001: i18nMark(
    "Search resulted in too many records. Refine the search to continue."
  )
};
