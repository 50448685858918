/**
 * GET ELEMENT POSITION
 * @param DOM node
 * @returns {*}
 */
export const getPosition = node => {
  if (!node) {
    return null;
  } else {
    const { bottom, left, right, top } = node.getBoundingClientRect();

    return {
      bottom: bottom,
      left: left,
      right: right,
      top: top,
      x: left,
      y: top
    };
  }
};

/**
 * GET ELEMENT DIMENSION
 * @param DOM node
 * @returns {*}
 */
export const getDimension = node => {
  if (!node) {
    return null;
  } else {
    const { height, width } = node.getBoundingClientRect();

    return {
      height: height || node.clientHeight,
      width: width || node.clientWidth
    };
  }
};

/**
 * SCROLL WINDOW
 * @param settings
 * @author Ehsan B
 */
export const scrollWindow = settings => {
  if (window && window.scroll) {
    window.scroll({
      top: settings.top,
      left: settings.left,
      behavior: settings.behavior
    });
  }
};

export const getActiveEl = () => {
  return document.activeElement;
};

export const blurActiveEl = options => {
  const defaults = {
    excludes: ["BODY"],
    activeEl: getActiveEl()
  };

  options = options ? { ...defaults, ...options } : defaults;

  const { activeEl, excludes } = options;
  const tagName = activeEl.tagName || "";

  if (activeEl && excludes.indexOf(tagName)) {
    activeEl.blur();
  }
};
