import React from "react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";
import Parser from "html-react-parser";
import { utils } from "@cauldron/core";
import { AlchemyTypography } from "../AlchemyTypography";

const {
  getSelectedOption,
  formatCurrency,
  formatDate,
  formatPhone,
  highlight
} = utils;

const getClassNames = options => {
  const names = [];
  const alignmentLookup = {
    LEFT: "text-align-left",
    RIGHT: "text-align-right",
    CENTER: "text-align-center"
  };
  const defaults = {
    align: "LEFT"
  };

  options = options ? Object.assign({}, defaults, options) : defaults;

  const textAlignment = options.align.toUpperCase();
  if (textAlignment !== "LEFT") {
    names.push(alignmentLookup[textAlignment]);
  }

  return names.join(" ");
};

/**
 * FORMAT TEXT
 * @author Ryan Rivera
 * @param props
 * @constructor
 */
const FormatText = ({ value, format, options, noValue }) => {
  let formattedText;

  const isSearchHighlightEnabled = options.searchHighlight;
  const searchedTerm = options.searchedTerm;
  const isPII = options.isPII;

  noValue = noValue ? noValue : "-";
  format =
    format && typeof format === "string" ? format.toUpperCase() : "STRING";

  // If the value is null or undefined, switch format type to "STRING"
  if (typeof value === "undefined" || value == null || value === "") {
    value = noValue;
    format = "STRING";
  }

  // Convert numbers to strings
  if (typeof value === "number") {
    value = value.toString();
  }

  // Trim whitespace around text
  if (typeof value === "string") {
    value = value.trim();
  }

  switch (format) {
    case "CURRENCY":
      formattedText = formatCurrency(value, options);
      break;
    case "DATE_TIME":
      formattedText = formatDate(
        value,
        Object.assign(
          {
            format: "MMM DD, YYYY, hh:mm a"
          },
          options
        )
      );
      break;
    case "DATE":
      formattedText = formatDate(
        value,
        Object.assign(
          {
            format: "MMM DD, YYYY"
          },
          options
        )
      );
      break;
    case "PHONE":
      formattedText = formatPhone(value, options);
      break;
    case "STRING":
      formattedText = value;
      if (options && options.dataType === "ENUM" && options.dataOptions) {
        const selectedValueObj = getSelectedOption(options.dataOptions, value);
        formattedText =
          selectedValueObj && selectedValueObj.label
            ? selectedValueObj.label
            : value;
      }
      break;
    default:
      formattedText = noValue;
  }

  const cNames = getClassNames(options);

  const props = {
    ...(cNames ? { className: cNames } : {}),
    ...(isPII ? { suppressTracking: true } : {})
  };

  // Highlighting search term
  const canHighlight =
    isSearchHighlightEnabled &&
    searchedTerm &&
    searchedTerm !== "" &&
    !isNil(value);

  if (canHighlight) {
    const highlightTerm = highlight(formattedText);
    const htmlStr = highlightTerm(searchedTerm);
    formattedText = Parser(htmlStr);
  }

  return <AlchemyTypography {...props}>{formattedText}</AlchemyTypography>;
};

FormatText.defaultProps = {
  options: {},
  noValue: "-",
  format: "STRING"
};

FormatText.propTypes = {
  format: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.object,
  noValue: PropTypes.string
};

export default FormatText;
