import { normalize } from "../../model.utils";
import { defaultProps, schema, MODEL_NAME } from "./model";

/**
 * Funded Comments Transform [RECEIVED]
 * transform data -> before it reaches the store
 * @param {*} rawData
 */

export const transformReceived = rawData => {
  const normalizedData = normalize(rawData, defaultProps, schema, MODEL_NAME);
  const { createdBy, ...rest } = normalizedData;

  return {
    createdByName: createdBy.name,
    createdByEmail: createdBy.email,
    ...rest
  };
};
