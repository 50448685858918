"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "Permission";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  name: null,
  code: null,
  description: null,
  contexts: [],
  readOnly: true
};
/**
 * Protects data from change in data type
 */

var schema = {
  name: _propTypes.default.string,
  code: _propTypes.default.string,
  description: _propTypes.default.string,
  contexts: _propTypes.default.array,
  readOnly: _propTypes.default.bool
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var _default = {
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;