"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AGENT_PORTAL_APPLICATION = exports.AGENT_PORTAL_APPLICATIONS = exports.AGENT_PORTAL_CLIENT_FROM_APPLICANT = exports.AGENT_PORTAL_CLIENT = exports.AGENT_PORTAL_CLIENTS = void 0;

var _api = require("./api.contants");
/**
 * API AGENT PORTAL ENDPOINTS
 */


var AGENT_PORTAL_CLIENTS = "".concat(_api.ROOT, "/agent-portal/clients");
exports.AGENT_PORTAL_CLIENTS = AGENT_PORTAL_CLIENTS;

var AGENT_PORTAL_CLIENT = function AGENT_PORTAL_CLIENT(id) {
  return "".concat(AGENT_PORTAL_CLIENTS, "/").concat(id);
};

exports.AGENT_PORTAL_CLIENT = AGENT_PORTAL_CLIENT;
var AGENT_PORTAL_CLIENT_FROM_APPLICANT = "".concat(AGENT_PORTAL_CLIENTS, "/from-applicant"); // prettier-ignore

exports.AGENT_PORTAL_CLIENT_FROM_APPLICANT = AGENT_PORTAL_CLIENT_FROM_APPLICANT;
var AGENT_PORTAL_APPLICATIONS = "".concat(_api.ROOT, "/agent-portal/applications");
exports.AGENT_PORTAL_APPLICATIONS = AGENT_PORTAL_APPLICATIONS;

var AGENT_PORTAL_APPLICATION = function AGENT_PORTAL_APPLICATION(id) {
  return "".concat(_api.ROOT, "/agent-portal/applications/").concat(id);
}; // prettier-ignore


exports.AGENT_PORTAL_APPLICATION = AGENT_PORTAL_APPLICATION;