// TODO: remove duplicates and refactor to use generated names i.e. [SEARCH]: "APPS0000" / [SEARCH_APPLICATIONS]: "APPS0000"

import {
  CHANGE_AGREEMENT_TERMS,
  VIEW_APP_DEAL_LEAD,
  CHANGE_APP_DEAL_LEAD,
  CHANGE_APP_REFERRAL,
  CHANGE_APP_STATUS,
  CREATE,
  EDIT,
  FUNDED_LOANS,
  GENERATE_LOAN_AGREEMENT,
  LOAN_APPS,
  READ,
  SEARCH,
  TRANCHE,
  VIEW,
  VIEW_RISK_ASSESSMENT_BRIEF_DESC,
  VIEW_RISK_ASSESSMENT_FULL_DESC,
  SUBMIT_MANUAL_SCORECARD,
  //generated
  SEARCH_APPLICATIONS,
  VIEW_APPLICATIONS,
  CREATE_NEW_APPLICATIONS,
  CHANGE_THE_TERMS_OF_AN_APPLICATIONS_LOAN_AGREEMENT,
  GENERATE_A_LOAN_AGREEMENT_FOR_AN_APPLICATION,
  VIEW_LOAN_AGREEMENT,
  VIEW_THE_DEAL_LEAD_OWNER_OF_AN_APPLICATION,
  CHANGE_THE_DEAL_LEAD_OWNER_OF_AN_APPLICATION,
  CHANGE_THE_REFERRAL_SOURCE_OF_AN_APPLICATION,
  MODIFY_APPLICATION_REFERRAL_AGENT,
  VIEW_APPLICATION_REFERRAL_AGENT,
  CHANGE_THE_STATUS_OF_AN_APPLICATION,
  VIEW_APPLICATION_STATUS_HISTORY,
  VIEW_DECISION_ACTIONS,
  VIEW_THE_BRIEF_DESCRIPTION_OF_THE_JUSTIFICATION_FOR_A_DECISION_ACTION,
  VIEW_THE_FULLY_DETAILED_DESCRIPTION_OF_THE_JUSTIFICATION_FOR_A_DECISION_ACTION,
  EVALUATE_AN_APPLICATION_CREATING_A_NEW_DECISION_ACTION_RISK_ASSESSMENT,
  VIEW_DEBTTOINCOME_RATIOS,
  ADD_AN_APPLICANT_COSIGNER_TO_AN_APPLICATION,
  REMOVE_AN_APPLICANT_COSIGNER_FROM_AN_APPLICATION,
  MODIFY_A_NOTE,
  VIEW_NOTES,
  VIEW_NOTE_TEMPLATES,
  CREATE_EDIT_DELETE_NOTE_TEMPLATES,
  VIEW_DOCUMENTS,
  MODIFY_DOCUMENT,
  UPLOAD_DOCUMENT,
  VIEW_OUTSTANDING_DOCUMENTS,
  MODIFY_DOCUMENT_CONTENT,
  VIEW_DOCUMENT_CONTENT,
  DELETE_A_DOCUMENT,
  APPROVE_OR_REJECT_A_DOCUMENT,
  VIEW_AUDIT_HISTORY,
  MODIFY_LOAN_DETAILS,
  VIEW_LOAN_DETAILS,
  ADD_UPDATE_AN_APPLICANT_COSIGNER,
  ADD_MODIFY_INCOME_SOURCE,
  VIEW_INCOME_SOURCES,
  VIEW_APPLICANT_EMAIL_ADDRESSES,
  ADD_MODIFY_APPLICANT_EMAIL_ADDRESSES,
  VIEW_EMAIL_MESSAGES_RECEIVED_SENT_DRAFTS,
  ADD_MODIFY_EMAIL_MESSAGE_DRAFTS,
  VIEW_LIABILITIES,
  ADD_MODIFY_LIABILITY,
  MODIFY_CLIENT_EXPENSES,
  VIEW_CLIENT_EXPENSES,
  VIEW_CLIENT_SOCIAL_INSURANCE_NUMBERS,
  MODIFY_CLIENT_BANK_ACCOUNT,
  VIEW_CLIENT_BANK_ACCOUNTS,
  VIEW_CLIENT_BANK_ACCOUNT_NUMBERS,
  VIEW_CREDIT_REPORT,
  MODIFY_CREDIT_REPORT,
  RETRIEVE_A_NEW_CREDIT_HISTORY_REPORT_FROM_THE_CREDIT_BUREAU,
  VIEW_BANK_STATEMENTS,
  ADD_MODIFY_BANK_STATEMENT,
  VIEW_BANK_STATEMENT_STATUS,
  CREATE_A_NEW_REQUEST_CODE_FOR_BANK_STATEMENT_RETRIEVAL,
  RESEND_NOTIFICATION_TO_THE_CUSTOMER_ABOUT_AN_EXISTING_REQUEST_CODE,
  SUBMIT_MANUAL_SCORECARD_INFO
} from "./auth.constants";

export const PERMISSIONS_MAP = {
  [LOAN_APPS]: {
    [SEARCH]: "APPS0000",
    [VIEW]: "APPS0001",
    [CREATE]: "APPS0010",
    [CHANGE_AGREEMENT_TERMS]: "APPS0020",
    [GENERATE_LOAN_AGREEMENT]: "APPS0021",
    [VIEW_APP_DEAL_LEAD]: "APPS0030",
    [CHANGE_APP_DEAL_LEAD]: "APPS0031",
    [CHANGE_APP_REFERRAL]: "APPS0032",
    [CHANGE_APP_STATUS]: "APPS0041",
    [VIEW_RISK_ASSESSMENT_BRIEF_DESC]: "APPS0051",
    [VIEW_RISK_ASSESSMENT_FULL_DESC]: "APPS0052",
    [SUBMIT_MANUAL_SCORECARD]: "APPS8110",
    // generated
    [SEARCH_APPLICATIONS]: "APPS0000",
    [VIEW_APPLICATIONS]: "APPS0001",
    [CREATE_NEW_APPLICATIONS]: "APPS0010",
    [CHANGE_THE_TERMS_OF_AN_APPLICATIONS_LOAN_AGREEMENT]: "APPS0020",
    [GENERATE_A_LOAN_AGREEMENT_FOR_AN_APPLICATION]: "APPS0021",
    [VIEW_LOAN_AGREEMENT]: "APPS0022",
    [VIEW_THE_DEAL_LEAD_OWNER_OF_AN_APPLICATION]: "APPS0030",
    [CHANGE_THE_DEAL_LEAD_OWNER_OF_AN_APPLICATION]: "APPS0031",
    [CHANGE_THE_REFERRAL_SOURCE_OF_AN_APPLICATION]: "APPS0032",
    [MODIFY_APPLICATION_REFERRAL_AGENT]: "APPS0033",
    [VIEW_APPLICATION_REFERRAL_AGENT]: "APPS0034",
    [CHANGE_THE_STATUS_OF_AN_APPLICATION]: "APPS0041",
    [VIEW_APPLICATION_STATUS_HISTORY]: "APPS0042",
    [VIEW_DECISION_ACTIONS]: "APPS0050",
    [VIEW_THE_BRIEF_DESCRIPTION_OF_THE_JUSTIFICATION_FOR_A_DECISION_ACTION]:
      "APPS0051",
    [VIEW_THE_FULLY_DETAILED_DESCRIPTION_OF_THE_JUSTIFICATION_FOR_A_DECISION_ACTION]:
      "APPS0052",
    [EVALUATE_AN_APPLICATION_CREATING_A_NEW_DECISION_ACTION_RISK_ASSESSMENT]:
      "APPS0055",
    [VIEW_DEBTTOINCOME_RATIOS]: "APPS0060",
    [ADD_AN_APPLICANT_COSIGNER_TO_AN_APPLICATION]: "APPS0070",
    [REMOVE_AN_APPLICANT_COSIGNER_FROM_AN_APPLICATION]: "APPS0071",
    [MODIFY_A_NOTE]: "APPS0160",
    [VIEW_NOTES]: "APPS0161",
    [VIEW_NOTE_TEMPLATES]: "APPS0165",
    [CREATE_EDIT_DELETE_NOTE_TEMPLATES]: "APPS0166",
    [VIEW_DOCUMENTS]: "APPS0170",
    [MODIFY_DOCUMENT]: "APPS0171",
    [VIEW_OUTSTANDING_DOCUMENTS]: "APPS0172",
    [MODIFY_DOCUMENT_CONTENT]: "APPS0173",
    [VIEW_DOCUMENT_CONTENT]: "APPS0174",
    [DELETE_A_DOCUMENT]: "APPS0175",
    [APPROVE_OR_REJECT_A_DOCUMENT]: "APPS0176",
    [UPLOAD_DOCUMENT]: "APPS0177",
    [VIEW_AUDIT_HISTORY]: "APPS0190",
    [MODIFY_LOAN_DETAILS]: "APPS0210",
    [VIEW_LOAN_DETAILS]: "APPS0211",
    [ADD_UPDATE_AN_APPLICANT_COSIGNER]: "APPS5110",
    [ADD_MODIFY_INCOME_SOURCE]: "APPS5140",
    [VIEW_INCOME_SOURCES]: "APPS5141",
    [VIEW_APPLICANT_EMAIL_ADDRESSES]: "APPS5180",
    [ADD_MODIFY_APPLICANT_EMAIL_ADDRESSES]: "APPS5181",
    [VIEW_EMAIL_MESSAGES_RECEIVED_SENT_DRAFTS]: "APPS5182",
    [ADD_MODIFY_EMAIL_MESSAGE_DRAFTS]: "APPS5183",
    [VIEW_LIABILITIES]: "APPS5161",
    [ADD_MODIFY_LIABILITY]: "APPS5162",
    [MODIFY_CLIENT_EXPENSES]: "APPS5200",
    [VIEW_CLIENT_EXPENSES]: "APPS5201",
    [VIEW_CLIENT_SOCIAL_INSURANCE_NUMBERS]: "APPS5210",
    [MODIFY_CLIENT_BANK_ACCOUNT]: "APPS5220",
    [VIEW_CLIENT_BANK_ACCOUNTS]: "APPS5221",
    [VIEW_CLIENT_BANK_ACCOUNT_NUMBERS]: "APPS5222",
    [VIEW_CREDIT_REPORT]: "APPS8000",
    [MODIFY_CREDIT_REPORT]: "APPS8001",
    [RETRIEVE_A_NEW_CREDIT_HISTORY_REPORT_FROM_THE_CREDIT_BUREAU]: "APPS8002",
    [VIEW_BANK_STATEMENTS]: "APPS8100",
    [ADD_MODIFY_BANK_STATEMENT]: "APPS8101",
    [VIEW_BANK_STATEMENT_STATUS]: "APPS8102",
    [CREATE_A_NEW_REQUEST_CODE_FOR_BANK_STATEMENT_RETRIEVAL]: "APPS8103",
    [RESEND_NOTIFICATION_TO_THE_CUSTOMER_ABOUT_AN_EXISTING_REQUEST_CODE]:
      "APPS8104",
    [SUBMIT_MANUAL_SCORECARD_INFO]: "APPS8110"
  },
  [FUNDED_LOANS]: {
    [SEARCH]: "LOAN0000",
    [VIEW]: "LOAN0001"
  },
  [TRANCHE]: {
    [VIEW]: "FIN001",
    [EDIT]: "FIN002"
  }
};

export const READ_ONLY_ACTIONS = [VIEW, READ];
