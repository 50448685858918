import { combineReducers } from "redux";

import storage from "redux-persist/lib/storage";
import { persistReducer, createMigrate } from "redux-persist";
import loginReducer from "./login/engine/login.reducer";
import { PERSISTED_DATA_DEBUG } from "../core/constants/env";

// LOAN APPLICATIONS
import loanApplicationsDataReducer from "./loan.applications/engine/reducers/loan.applications.data.reducer";
import loanApplicationsUIReducer, {
  loanApplicationsUIWhitelist
} from "./loan.applications/engine/reducers/loan.applications.ui.reducer";
import loanApplicationDetailsDataReducer from "./loan.application.details/engine/reducers/loan.application.details.data.reducer";
import loanApplicationDetailsUIReducer, {
  loanApplicationDetailsUIWhitelist
} from "./loan.application.details/engine/reducers/loan.application.details.ui.reducer";
import loanApplicationDetailsFormReducer from "./loan.application.details/engine/reducers/loan.application.details.form.reducer";

// FUNDED LOANS
import fundedLoansDataReducer from "./funded.loans/engine/reducers/funded.loans.data.reducer";
import fundedLoansUIReducer, {
  fundedLoansUIWhitelist
} from "./funded.loans/engine/reducers/funded.loans.ui.reducer";
import fundedLoanDetailsDataReducer from "./funded.loan.details/engine/reducers/funded.loan.details.data.reducer";
import fundedLoanDetailsUIReducer, {
  fundedLoanDetailsUIWhitelist
} from "./funded.loan.details/engine/reducers/funded.loan.details.ui.reducer";
import fundedLoanDetailsFormReducer, {
  fundedLoanDetailsFormWhitelist
} from "./funded.loan.details/engine/reducers/funded.loan.details.form.reducer";
import fundedLoanDetailsTabsDataReducer from "./funded.loan.details/engine/reducers/tabs.data.reducer";
import fundedLoanDetailsTabsUIReducer from "./funded.loan.details/engine/reducers/tabs.ui.reducer";

// FUNDED LOANS => NOTES
import fundedLoansNoteDataReducer from "./funded.loan.details/engine/reducers/note.data.reducer";
import fundedLoansNoteUIReducer from "./funded.loan.details/engine/reducers/note.ui.reducer";

// Dashboard
import dashboardUIReducer from "./dashboard/engine/reducers/dashboard.ui.reducer";
import dashboardDataReducer, {
  dashboardDataWhitelist
} from "./dashboard/engine/reducers/dashboard.data.reducer";
import trancheReducer from "./tranche.management/engine/tranche.reducer";

const basePersistConfig = {
  // default to local storage and can be overridden
  // in each persisted reducer
  storage,

  // defined version for migration
  version: 0,

  // prefix for persistend data
  keyPrefix: "pbp_",

  // adds error and key properties in the action
  debug: PERSISTED_DATA_DEBUG === "true"
};

export default combineReducers({
  loanApplications: combineReducers({
    ui: persistReducer(
      {
        ...basePersistConfig,
        migrate: createMigrate(
          {
            // The key should match the version set in the basePersistConfig
            // ex. return the updated persisted state structure if version is 0
            // 0: state => state
          },
          { debug: PERSISTED_DATA_DEBUG === "true" }
        ),
        key: "loan.applications.ui",
        whitelist: loanApplicationsUIWhitelist
      },
      loanApplicationsUIReducer
    ),
    data: loanApplicationsDataReducer
  }),
  loanApplicationDetails: combineReducers({
    ui: persistReducer(
      {
        ...basePersistConfig,
        key: "loan.application.details.ui",
        whitelist: loanApplicationDetailsUIWhitelist
      },
      loanApplicationDetailsUIReducer
    ),
    data: loanApplicationDetailsDataReducer,
    form: loanApplicationDetailsFormReducer
  }),
  fundedLoans: combineReducers({
    ui: persistReducer(
      {
        ...basePersistConfig,
        migrate: createMigrate(
          {
            // The key should match the version set in the basePersistConfig
            // ex. return the updated persisted state structure if version is 0
            // 0: state => state
          },
          { debug: PERSISTED_DATA_DEBUG === "true" }
        ),
        key: "funded.loans.ui",
        whitelist: fundedLoansUIWhitelist
      },
      fundedLoansUIReducer
    ),
    data: fundedLoansDataReducer
  }),
  fundedLoanDetails: combineReducers({
    ui: persistReducer(
      {
        ...basePersistConfig,
        key: "funded.loan.details.ui",
        whitelist: fundedLoanDetailsUIWhitelist
      },
      fundedLoanDetailsUIReducer
    ),
    form: persistReducer(
      {
        ...basePersistConfig,
        key: "funded.loan.details.form",
        whitelist: fundedLoanDetailsFormWhitelist
      },
      fundedLoanDetailsFormReducer
    ),
    data: fundedLoanDetailsDataReducer
  }),
  fundedLoanDetailsTabs: combineReducers({
    ui: fundedLoanDetailsTabsUIReducer,
    data: fundedLoanDetailsTabsDataReducer
  }),
  fundedLoanDetailsNotes: combineReducers({
    data: fundedLoansNoteDataReducer,
    ui: fundedLoansNoteUIReducer
  }),
  login: loginReducer,
  dashboard: combineReducers({
    ui: dashboardUIReducer,
    data: persistReducer(
      {
        ...basePersistConfig,
        key: "dashboard.data",
        whitelist: dashboardDataWhitelist
      },
      dashboardDataReducer
    )
  }),
  tranche: trancheReducer
});
