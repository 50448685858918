import {
  CREATE_FORM_CACHE,
  FORM_CACHE,
  FORM_VALUES_CACHE,
  FORM_DELETE,
  FORM_FETCHING_STATUS_UPDATE,
  FORM_ERRORS_UPDATE
} from "./forms.actions.types";

/**
 * Upsert form cache
 * @param formId
 * @param data
 * @returns {{formId: *, data, type: string}}
 */
export function cacheForm(formId, data = {}) {
  return {
    type: FORM_CACHE,
    formId,
    data
  };
}

/**
 * Create form cache
 * @param formId
 * @param data
 * @returns {{formId: *, data, type: string}}
 */
export function createFormCache(formId, data = {}) {
  return {
    type: CREATE_FORM_CACHE,
    formId,
    data
  };
}

export function cacheFormValues(formId, values = {}) {
  return {
    type: FORM_VALUES_CACHE,
    formId,
    values
  };
}

/**
 * FORM ACTION TO DELETE CACHE FORM
 * @param formId
 * @returns {{type: string, formId: *}}
 */
export function deleteCachedForm(formId) {
  return {
    type: FORM_DELETE,
    formId
  };
}

/**
 * FORM ACTION TO UPDATE FORM IS FETCHING STATUS
 * @param formId
 * @param value
 * @returns {{type: string, formId: *, value: *}}
 */
export function isFetchingForm(formId, value) {
  return {
    type: FORM_FETCHING_STATUS_UPDATE,
    formId,
    value
  };
}

export const addFormSectionError = (formId, error) => ({
  type: FORM_ERRORS_UPDATE,
  formId,
  error
});
