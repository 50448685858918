import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Loader from "../../Loader";

/**
 * Loader for Alchemy Table
 * @author Ryan R
 * @param props
 * @returns {*}
 * @constructor
 */
function TableLoader({ loading }) {
  return (
    <div className={classNames("-loading", loading ? "-active" : "")}>
      <div className="-loading-inner">
        <Loader size={60} thickness={3} />
      </div>
    </div>
  );
}

TableLoader.defaultProps = {
  loading: false
};

TableLoader.propTypes = {
  loading: PropTypes.bool
};

export default TableLoader;
