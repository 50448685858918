"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.escapeHtml = exports.toPossessive = exports.toPercent = exports.obfusicateSIN = exports.maskAccountNumber = exports.getFirstDayOfMonth = exports.formatPercentage = exports.formatTitleCase = exports.formatSentenceCase = exports.formatAddress = exports.highlight = exports.concatText = exports.removePhoneFormat = exports.formatPhone = exports.formatDate = exports.formatCurrency = exports.formatLineBreaks = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _numeral = _interopRequireDefault(require("numeral"));

var _format = _interopRequireDefault(require("date-fns/format"));

var _libphonenumberJs = require("libphonenumber-js");

var _isNumber = _interopRequireDefault(require("lodash/isNumber"));

var _isNil = _interopRequireDefault(require("lodash/isNil"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

var _isString = _interopRequireDefault(require("lodash/isString"));

var _htmlReactParser = _interopRequireDefault(require("html-react-parser"));
/**
 * formatLineBreaks
 * @param str
 * @returns {*}
 */


var formatLineBreaks = function formatLineBreaks(str) {
  if (!(0, _isString.default)(str) || (0, _isNil.default)(str)) return "";
  return (0, _htmlReactParser.default)(str.replace(/(?:\r\n|\r|\n)/g, "<br/>"));
};
/**
 * FORMAT CURRENCY
 * reference: http://numeraljs.com/
 * @author Ryan Rivera
 * @param value
 * @param options
 * @returns {*}
 */


exports.formatLineBreaks = formatLineBreaks;

var formatCurrency = function formatCurrency(value, options) {
  // Treat undefined and nulls as "-"
  // Treat non-numbers ({}/[]/etc...) as "-"
  if ((0, _isNil.default)(value) || isNaN(value)) {
    return "-";
  }

  var defaults = {
    format: "$0,0.00",
    allowZero: true
  };

  if (!isNaN(value) && value < 0) {
    defaults = {
      format: "$(0,0.00)",
      allowZero: true
    };
  }

  var fOptions = options ? (0, _objectSpread2.default)({}, defaults, options) : defaults; // if not allowed zero, show as "-"
  // otherwise, treat as normal value

  if (!fOptions.allowZero && (value === 0 || value === "0")) {
    return "-";
  }

  var number = (0, _numeral.default)(value);
  return number.format(fOptions.format);
};
/**
 * FORMAT DATE
 * reference: https://date-fns.org/
 * @author Ryan Rivera
 * @param value
 * @param options
 * @returns {string | *}
 */


exports.formatCurrency = formatCurrency;

var formatDate = function formatDate(value, options) {
  if (!value) {
    return "-";
  }

  var defaults = {
    format: "MMM DD, YYYY, hh:mm a"
  };
  var fOptions = options ? (0, _objectSpread2.default)({}, defaults, options) : defaults;
  return (0, _format.default)(value, fOptions.format);
};
/**
 * FORMAT PHONE
 * reference: https://github.com/catamphetamine/libphonenumber-js
 * demo: https://catamphetamine.github.io/libphonenumber-js/
 * @author Ryan Rivera
 * @param value
 * @param options
 * @returns {*}
 */


exports.formatDate = formatDate;

var formatPhone = function formatPhone(value, options) {
  if (!value) {
    return "-";
  }

  var phone = (0, _isNumber.default)(value) ? value.toString() : value;
  var defaults = {
    extLabel: "Ext.",
    ext: null,
    country: "US",
    format: "National"
  };
  var fOptions = options ? (0, _objectSpread2.default)({}, defaults, options) : defaults;
  var num = (0, _libphonenumberJs.formatNumber)({
    country: fOptions.country,
    phone: phone
  }, fOptions.format);
  var ext = fOptions.ext ? " ".concat(fOptions.extLabel, "\xA0").concat(fOptions.ext) : "";
  return "".concat(num).concat(ext);
};
/**
 * removePhoneFormat
 * Remove applied format from the phone number
 * @param value [formated number]
 * @returns value (without format)
 */


exports.formatPhone = formatPhone;

var removePhoneFormat = function removePhoneFormat(value) {
  return typeof value === "string" && value.length ? value.replace(/\D/g, "") : value;
};
/**
 * CONCAT TEXT - remove null values
 * @author Stanley Li
 * @param separator
 * @param ...args [texts that need to be formatted
 * @returns {*}
 */


exports.removePhoneFormat = removePhoneFormat;

var concatText = function concatText(separator) {
  var result = "";

  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  args.forEach(function (text) {
    if (text !== null && typeof text !== "undefined") {
      var textString = text.toString();
      result = result ? result + separator + textString : textString;
    }
  });
  return result;
};
/**
 * HIGHLIGHT TEXT
 * @author Ryan Rivera
 * @param value
 * @returns {function(*=): Function}
 */


exports.concatText = concatText;

var highlight = function highlight(value) {
  return function (term) {
    // Safeguard
    if (!value) return "-";
    if (!term) return value; // Generate a regEx that looks for the term
    // in a formatted value regardless of the added special characters
    // due to the formatting
    // Ex. Given a formatted phone number (604) 604-6046
    // a term "460" will highlight "4) 60" due to the original
    // un-formatted value being 6046046046

    var basePattern = "[^A-Za-z0-9]*";
    var metaChars = "().+*".split("");
    var pattern = term.trim().split("").map(function (_char) {
      return metaChars.indexOf(_char) > -1 ? "[".concat(_char, "]") : _char;
    }).join(basePattern);
    var regEx = new RegExp("(".concat(pattern, ")"), "ig"); // wraps the value with mark which is styled in css
    // to highlight the term

    var replaceMask = "<mark>$1</mark>";
    return value.replace(regEx, replaceMask);
  };
};
/**
 * FORMAT ADDRESS - if streetAddress is set, will format full address using streetAddress.
 * If streetAddress is not set, we will use number and street to format the full address.
 * @author Ryan Rivera
 * @param address
 * @returns {string}
 */


exports.highlight = highlight;

var formatAddress = function formatAddress(address) {
  // Safeguard
  if ((0, _isEmpty.default)(address) || (0, _typeof2.default)(address) !== "object") return "-";
  var baseAddress = {
    unit: null,
    number: null,
    street: null,
    city: null,
    province: null,
    postalCode: null,
    streetAddress: null,
    country: null
  };
  var addressData = (0, _objectSpread2.default)({}, baseAddress, address);
  var fullAddress = "";

  if (addressData.streetAddress) {
    fullAddress = formatTitleCase(addressData.streetAddress);
  } else if (addressData.number && addressData.street) {
    fullAddress = "".concat(addressData.number, " ").concat(formatTitleCase(addressData.street));
  } else if (addressData.street) {
    fullAddress = formatTitleCase(addressData.street);
  } else if (addressData.number) {
    fullAddress = addressData.number;
  }

  if (fullAddress && addressData.unit) {
    fullAddress = "".concat(addressData.unit, "-").concat(fullAddress);
  }

  var secondAddressLine = "";

  if (addressData.city && addressData.province) {
    secondAddressLine = "".concat(formatTitleCase(addressData.city), ", ").concat(addressData.province.toUpperCase());
  } else if (addressData.city && !addressData.province) {
    secondAddressLine = formatTitleCase(addressData.city);
  } else if (!addressData.city && addressData.province) {
    secondAddressLine = addressData.province.toUpperCase();
  }

  if (addressData.postalCode) {
    var postalCode = addressData.postalCode.split(" ").join("\xA0").toUpperCase();
    secondAddressLine = secondAddressLine ? "".concat(secondAddressLine, " ").concat(postalCode) : postalCode;
  }

  if (secondAddressLine) {
    fullAddress = fullAddress ? "".concat(fullAddress, "\n").concat(secondAddressLine) : secondAddressLine;
  }

  if (addressData.country) {
    var country = formatTitleCase(addressData.country);
    fullAddress = fullAddress ? "".concat(fullAddress, "\n").concat(country) : country;
  }

  return fullAddress ? fullAddress.trim() : "-";
};
/**
 * format a string to sentence case
 * @param str
 * @returns {string}
 */


exports.formatAddress = formatAddress;

var formatSentenceCase = function formatSentenceCase(str) {
  if (!str) {
    return "";
  } else if (str.length === 1) {
    return str.toUpperCase();
  } else {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }
};
/**
 * FORMAT TITLE CASE
 * format a string to title case (capitalize all words in a string)
 * @param val
 * @returns {string}
 */


exports.formatSentenceCase = formatSentenceCase;

var formatTitleCase = function formatTitleCase(val) {
  if (typeof val !== "string" || val === "") {
    return "";
  }

  return val.split(" ").map(function (word) {
    return "".concat(word.charAt(0).toUpperCase()).concat(word.slice(1).toLowerCase());
  }).join(" ");
};
/**
 * format a value to percentage format. i.e. 0.565 >>> 57%
 * @param value
 * @param options
 * @returns {string}
 */


exports.formatTitleCase = formatTitleCase;

var formatPercentage = function formatPercentage(value, options) {
  if (typeof value !== "number") return "-";
  var defaultOption = {
    format: "0%"
  };
  var opt = options ? (0, _objectSpread2.default)({}, defaultOption, options) : defaultOption;
  var number = (0, _numeral.default)(value);
  return number.format(opt.format);
};
/**
 * Given a year and month create a date string using 01 for the day
 * @param year
 * @param month
 * @returns {(string | null)}
 */


exports.formatPercentage = formatPercentage;

var getFirstDayOfMonth = function getFirstDayOfMonth(year, month) {
  if (!(0, _isNil.default)(year) && !(0, _isNil.default)(month)) {
    var strMonth = month.toString();
    var strYear = year.toString();
    var paddedMonth = ("00" + strMonth).slice(-2);
    return "".concat(strYear, "-").concat(paddedMonth, "-01");
  }

  return null;
};
/**
 * MASK ACCOUNT NUMBER
 * @param accountNumber
 * @returns string masked account number - showing the last 4 digits
 * @author Ehsan
 */


exports.getFirstDayOfMonth = getFirstDayOfMonth;

var maskAccountNumber = function maskAccountNumber(accountNumber) {
  if (accountNumber) {
    var accountLength = accountNumber.length;
    var _maskAccountNumber = "";

    if (accountLength >= 4) {
      for (var i = 0; i <= accountLength - 5; i++) {
        _maskAccountNumber = _maskAccountNumber.concat("*");
      }

      _maskAccountNumber = _maskAccountNumber + accountNumber.substr(-4);
      return _maskAccountNumber;
    }

    return accountNumber;
  }

  return "-";
};
/**
 * obfusicateSIN
 * @param sin
 * @return {string|null}
 */


exports.maskAccountNumber = maskAccountNumber;

var obfusicateSIN = function obfusicateSIN(sin) {
  if (!sin || sin.length < 9) return null;
  return "xxx - xxx - ".concat(sin.slice(6, 9));
};
/**
 * toPercent
 * @param value
 * @param options
 * @returns {*}
 */


exports.obfusicateSIN = obfusicateSIN;

var toPercent = function toPercent(value, options) {
  if (value === null || typeof value === "undefined" || value === "") return "-";
  if (isNaN(value)) return "-";
  var defaults = {
    format: "0.00%"
  };
  var fOptions = options ? (0, _objectSpread2.default)({}, defaults, options) : defaults;
  return (0, _numeral.default)(value).format(fOptions.format);
};
/**
 * toPossessive
 * @param str
 * @return {string}
 */


exports.toPercent = toPercent;

var toPossessive = function toPossessive(str) {
  var len = str.length;
  if (typeof str !== "string" || len === 0) return "";
  var lastChar = str.charAt(len - 1);
  var isLastCharS = lastChar === "s";
  return "".concat(str, "'").concat(isLastCharS ? "" : "s");
};
/**
 * escapeHtml
 * @param unsafe
 * @returns {*}
 */


exports.toPossessive = toPossessive;

var escapeHtml = function escapeHtml(unsafe) {
  if ((0, _isNil.default)(unsafe)) {
    return "";
  }

  if (typeof unsafe !== "string") {
    return unsafe;
  }

  return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
};

exports.escapeHtml = escapeHtml;