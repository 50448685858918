import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles/menu.item.styles";

/**
 * MenuItem
 * @param item
 * @param rest
 * @returns {*}
 * @private
 */
function _MenuItem({ classes, children, focused, index = 1, ...rest }) {
  return (
    <Fade in timeout={(200 * index) / 2}>
      <MenuItem
        className={focused ? "focused" : ""}
        classes={{
          root: classes.menuItemRoot,
          selected: classes.menuItemRootSelected
        }}
        disableRipple
        {...rest}
      >
        {children}
      </MenuItem>
    </Fade>
  );
}

export default withStyles(styles)(_MenuItem);
