import { fork, call, put, takeLatest, all } from "redux-saga/effects";
import { domain } from "@cauldron/core";
import { http } from "../../http/http.service";
import { actionTypes } from "./referral.sources.action.types";
import {
  createAgentSuccess,
  createAgentFailure,
  fetchReferralSourcesSuccess,
  fetchReferralSourcesFailure,
  fetchReferralSourceAgentsSuccess,
  fetchReferralSourceAgentsFailure
} from "./referral.sources.actions";

const { ReferralSource } = domain;

export const fetchReferralSources = action => {
  const { eventHandlers } = action;
  const { onSuccess } = eventHandlers;
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.get, API.REFERRAL_SOURCES);
    },
    success: payload => {
      const successActions = onSuccess
        ? [put(fetchReferralSourcesSuccess(payload)), call(onSuccess, payload)]
        : [put(fetchReferralSourcesSuccess(payload))];
      return all(successActions);
    },
    error: e => put(fetchReferralSourcesFailure(e)),
    transformReceiveData: ReferralSource.collection.transformReceived
  });
};

export const fetchReferralSourceAgents = action => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.get, API.REFERRAL_SOURCE_AGENTS(action.id));
    },
    success: data => put(fetchReferralSourceAgentsSuccess(data.results)),
    error: e => put(fetchReferralSourceAgentsFailure(e))
  });
};

export const createAgent = action => {
  const { id: companyId, payload, eventHandlers } = action;
  const { onSuccess } = eventHandlers;

  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.REFERRAL_AGENT(companyId), payload);
    },
    success: data => {
      return all([put(createAgentSuccess(data)), call(onSuccess, data)]);
    },
    error: e => {
      return put(createAgentFailure(e));
    }
  });
};

export function* watchClientsAsync() {
  yield takeLatest(
    actionTypes.REFERRAL_SOURCES_FETCH_REQUEST,
    fetchReferralSources
  );
  yield takeLatest(
    actionTypes.REFERRAL_SOURCE_AGENTS_FETCH_REQUEST,
    fetchReferralSourceAgents
  );
  yield takeLatest(actionTypes.CREATE_REFERRAL_AGENT_REQUEST, createAgent);
}

export default [fork(watchClientsAsync)];
