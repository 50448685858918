import * as actionTypes from "../dashboard.action.types";

const initialState = {
  isFetching: false
};

/**
 * Reducer for dashboard ui
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_REQUEST_START:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.DASHBOARD_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false
      };

    case actionTypes.DASHBOARD_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false
      };

    default:
      return state;
  }
};
