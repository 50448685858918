import React from "react";
import PropTypes from "prop-types";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "../IconButton";

import styles from "./styles/icon.text.button.styles";

import { ICON, ICON_TEXT } from "../../constants/data.test.ids";

/**
 * Icon Button with Text (outside IconButton, div has onClick)
 */
function IconTextButton(props) {
  const {
    classes,
    buttonText,
    onClick,
    icon,
    disabled,
    dataTestId,
    dataTestBuilder,
    buttonRef,
    ...rest
  } = props;

  return (
    <div
      data-test={dataTestId}
      className={
        !disabled ? classes.buttonWrapper : classes.disabledButtonWrapper
      }
      onClick={!disabled ? onClick : null}
      onKeyPress={e => {
        if (e.which === 13 && !disabled) onClick(e);
      }}
      tabIndex={0}
      role="button"
      {...rest}
    >
      <IconButton
        disabled={disabled}
        size="MD"
        data-test={dataTestBuilder && dataTestBuilder(dataTestId, ICON)}
        buttonRef={buttonRef}
      >
        {icon}
      </IconButton>
      <Typography
        className={classes.buttonText}
        data-test={dataTestBuilder && dataTestBuilder(dataTestId, ICON_TEXT)}
      >
        {buttonText}
      </Typography>
    </div>
  );
}

IconTextButton.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * The text for the button
   */
  buttonText: PropTypes.string.isRequired,
  /**
   * The icon for the button
   */
  icon: PropTypes.object.isRequired,
  /**
   * onClick func for the button
   */
  onClick: PropTypes.func.isRequired,
  /**
   * button disabled state
   */
  disabled: PropTypes.bool,
  /**
   * locators
   */
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(IconTextButton);
