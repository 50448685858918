import { fadeHEX } from "../../../../utils/jss.utils";

const styles = theme => ({
  root: theme.button["btn32"],
  primary: {
    backgroundColor: theme.palette.secondary["dark"],
    "&:hover": {
      backgroundColor: theme.palette.secondary["light"],
      color: theme.palette.grey[900]
    }
  },
  secondary: {
    backgroundColor: theme.palette.lightGrey[900],
    color: theme.palette.grey[900],
    "&:hover": {
      backgroundColor: theme.palette.lightGrey[600]
    }
  },
  critical: {
    backgroundColor: fadeHEX(theme.palette.error["main"], ".4"),
    color: theme.palette.grey[900],
    "&:hover": {
      backgroundColor: fadeHEX(theme.palette.error["main"], ".5")
    }
  },
  link: {
    backgroundColor: "unset",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.main
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main
    }
  },
  md: {
    width: "120px"
  },
  lg: {
    width: "166px"
  }
});

export default styles;
