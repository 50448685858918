import React, { Fragment } from "react";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles/action.buttons.styles";

import { AlchemyIconButton, TextButton } from "../../../Buttons";
import { blurActiveEl } from "../../../../utils/dom.utils";

function ActionButtons({
  classes,
  row,
  isFetching,
  addButtonText,
  editButtonText,
  deleteButtonText,
  saveButtonText,
  handleOnEdit,
  handleOnDelete,
  handleOnSubmit,
  disableEditButton,
  disableDeleteButton,
  dataTestBuilder,
  dataTestId
}) {
  const btnSize = "MD";
  return (
    <div className={classes.cell}>
      {!row.isEditMode ? (
        // NON-EDIT MODE BUTTONS
        <Fragment>
          <AlchemyIconButton
            dataTestId={
              dataTestBuilder && dataTestBuilder(dataTestId, "edit-button")
            }
            dataTestBuilder={dataTestBuilder}
            disabled={isFetching || disableEditButton}
            aria-label={editButtonText}
            onClick={handleOnEdit.bind(this, row)}
            size={btnSize}
            onMouseEnter={() => blurActiveEl()}
          >
            <EditIcon className={classes.actionButtonIcon} />
          </AlchemyIconButton>
          <AlchemyIconButton
            dataTestId={
              dataTestBuilder && dataTestBuilder(dataTestId, "delete-button")
            }
            dataTestBuilder={dataTestBuilder}
            disabled={isFetching || disableDeleteButton}
            aria-label={deleteButtonText}
            onClick={handleOnDelete.bind(this, row)}
            size={btnSize}
            onMouseEnter={() => blurActiveEl()}
          >
            <DeleteIcon className={classes.actionButtonIcon} />
          </AlchemyIconButton>
        </Fragment>
      ) : (
        // EDIT MODE BUTTONS
        <Fragment>
          <TextButton
            disabled={isFetching}
            aria-label={saveButtonText}
            onClick={handleOnSubmit.bind(this, row)}
            importance="PRIMARY"
            buttonText={row.id ? saveButtonText : addButtonText}
            onMouseEnter={() => blurActiveEl()}
          />
          <AlchemyIconButton
            disabled={isFetching}
            aria-label={deleteButtonText}
            onClick={handleOnDelete.bind(this, row)}
            size={btnSize}
            onMouseEnter={() => blurActiveEl()}
            dataTestId={
              dataTestBuilder && dataTestBuilder(dataTestId, "delete-button")
            }
            dataTestBuilder={dataTestBuilder}
          >
            <DeleteIcon className={classes.actionButtonIcon} />
          </AlchemyIconButton>
        </Fragment>
      )}
    </div>
  );
}

ActionButtons.defaultProps = {
  isFetching: false
};

ActionButtons.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    isEditMode: PropTypes.bool
  }).isRequired,
  isFetching: PropTypes.bool,

  // button texts
  addButtonText: PropTypes.string.isRequired,
  editButtonText: PropTypes.string.isRequired,
  deleteButtonText: PropTypes.string.isRequired,
  saveButtonText: PropTypes.string.isRequired,

  // event handlers
  handleOnEdit: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired
};

export default withStyles(styles)(ActionButtons);
