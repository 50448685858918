import { useState, useEffect, useRef } from "react";
import isNil from "lodash/isNil";

function createCacheObj(initialParams, cachedURLParams) {
  let cacheObj = {};
  for (let key in cachedURLParams) {
    if (
      initialParams.hasOwnProperty(key) &&
      cachedURLParams.hasOwnProperty(key) &&
      !!cachedURLParams[key]
    ) {
      cacheObj[key] = cachedURLParams[key];
    }
  }
  return cacheObj;
}

function usePrevious(value) {
  const ref = useRef(value);
  useEffect(
    () => {
      ref.current = value;
    },
    [value]
  );
  return ref.current;
}

function useUrlParams({ urlParams, cacheURLParams }) {
  const initialParams = useRef(urlParams);
  const [cachedURLParams, setCachedURLParams] = useState(initialParams.current);

  useEffect(
    () => {
      const cacheObj = createCacheObj(initialParams.current, cachedURLParams);
      if (Object.keys(cacheObj).length > -1) {
        cacheURLParams({ ...cacheObj });
      }
    },
    [cachedURLParams, cacheURLParams]
  );

  function updateCachedURLParams(newParams) {
    setCachedURLParams({ ...cachedURLParams, ...newParams });
  }

  return { cachedURLParams, updateCachedURLParams };
}

function useNoData({
  error = {},
  hasError = false,
  isSearching = false,
  searchCleared = false,
  dataLength = 0,
  noDataMessages = "",
  defaultNoDataType = ""
}) {
  const [noData, setNoData] = useState({
    noDataText: noDataMessages.DEFAULT,
    noDataType: defaultNoDataType
  });

  const prevProps = usePrevious({ hasError, isSearching, searchCleared });

  useEffect(
    () => {
      const hasErrorDidChange = prevProps.hasError !== hasError;
      const isSearchingDidChange = prevProps.isSearching !== isSearching;
      const searchClearedDidChange = prevProps.searchCleared !== searchCleared;

      // Given hasError did change
      // and there's a search error
      // the message shown on the table should be the search error from the service
      if (
        hasErrorDidChange &&
        hasError &&
        !isNil(error.message) &&
        error.message !== ""
      ) {
        setNoData({ noDataText: error.message, noDataType: error.type });
      }

      // Given isSearching did change
      // and is currently not searching
      // and results is 0
      // the message shown on the table should be noDataText 'noResults' message
      else if (isSearchingDidChange && !isSearching && dataLength <= 0) {
        // We do a further check here for hasError because hasErrorDidChange may be false but
        // we still have a valid error to show - but we need to check if hasErrorDidChange above in order to stop an infinite loop
        if (hasError) {
          setNoData({ noDataText: error.message, noDataType: error.type });
        } else {
          setNoData({
            noDataText: noDataMessages.NO_RESULTS,
            noDataType: defaultNoDataType
          });
        }
      }

      // Given search result length is 0
      // and search cleared did change
      // and search is cleared
      // the message shown on the table should be noDataText 'initial' message
      else if (dataLength <= 0 && searchClearedDidChange && searchCleared) {
        setNoData({
          noDataText: noDataMessages.PLACEHOLDER,
          noDataType: defaultNoDataType
        });
      }
    },
    [
      prevProps.hasError,
      prevProps.isSearching,
      prevProps.searchCleared,
      hasError,
      isSearching,
      searchCleared,
      error.message,
      error.type,
      dataLength,
      noDataMessages.NO_RESULTS,
      noDataMessages.PLACEHOLDER,
      defaultNoDataType
    ]
  );

  return noData;
}

export { useUrlParams, useNoData };
