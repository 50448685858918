/**
 * NESTED_MENU_MULTISELECT STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = theme => {
  const lightGrey = theme.palette.lightGrey[900];
  const grey = theme.palette.grey[900];

  return {
    formControlRoot: {
      margin: theme.spacing.unit,
      marginRight: theme.spacing.unit * 2
    },
    formControlLabelRoot: {
      marginLeft: 0,
      marginRight: 0,
      color: lightGrey,
      "&:hover": {
        color: grey
      }
    },
    formControlLabel: {
      color: grey,
      whiteSpace: "nowrap"
    },
    checkboxRoot: {
      height: "36px",
      width: "36px",
      color: grey
    },
    checkboxColorPrimary: {
      color: "inherit",
      "& + span": {
        color: "inherit"
      }
    },
    checkboxChecked: {
      color: `${grey} !important`,
      "& + span": {
        color: grey
      }
    },
    checkboxDisabled: {
      color: `${lightGrey} !important`,
      "& + span": {
        color: lightGrey
      }
    }
  };
};

export default styles;
