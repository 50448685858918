import formControlStyles from "../../../../FormControls/styles/form.controls.styles";

const styles = theme => ({
  ...formControlStyles(theme),
  root: {},
  formControl: {
    width: "100%"
  },
  selectMenu: {
    ...theme.typography.p3Dark,
    padding: "3px 18px 3px 0",
    minHeight: "auto",
    "&:focus": {
      background: "none"
    }
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main
    }
  }
});

export default styles;
