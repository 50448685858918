import React from "react";
import Parser from "html-react-parser";
import { utils } from "@cauldron/core";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import { MenuItem } from "../../../MenuItem";

// LOCAL STYLES
import styles from "../styles/select.filter.menu.styles";

const { highlight } = utils;

/**
 * renderMenuItem
 * @param itemProps
 * @param itemValue
 * @param index
 * @param inputValue
 * @param highlightedIndex
 * @param selected
 * @returns {*}
 */
function renderMenuItem({
  itemProps,
  itemValue,
  index,
  inputValue,
  highlightedIndex,
  selected,
  dataTestId,
  dataTestBuilder
}) {
  const formattedValue =
    itemValue && inputValue ? highlight(itemValue)(inputValue) : itemValue;

  const parsedValue =
    itemValue && inputValue
      ? Parser(formattedValue.replace(/\s/g, "&nbsp;"))
      : formattedValue;

  return (
    <MenuItem
      {...itemProps}
      index={index}
      focused={highlightedIndex === index}
      selected={selected}
      data-test={
        dataTestBuilder && dataTestBuilder(dataTestId, "menu-item", index)
      }
      key={index.toString()}
    >
      {parsedValue}
    </MenuItem>
  );
}

/**
 * SelectFilterMenu
 * @param options
 * @param menuProps
 * @param getItemProps
 * @param highlightedIndex
 * @param selectedItem
 * @param isOpen
 * @param popperNode
 * @returns {*}
 * @constructor
 */
function SelectFilterMenu({
  classes,
  menuItems,
  menuProps,
  getItemProps,
  highlightedIndex,
  selectedItem,
  isOpen,
  isFetching = false,
  inputValue,
  popperNode,
  dataTestId,
  dataTestBuilder
}) {
  const selectedItemValue = selectedItem ? selectedItem.value : "";

  return (
    <Popper
      open={isOpen}
      anchorEl={popperNode}
      placement="bottom-start"
      data-test={dataTestBuilder && dataTestBuilder(dataTestId, "menu")}
      modifiers={{
        flip: { enabled: true },
        preventOverflow: { enabled: true, boundariesElement: "scrollParent" }
      }}
      style={{ zIndex: 1 }}
    >
      <div {...menuProps}>
        <Paper
          square
          className={classes.root}
          style={{ width: popperNode ? popperNode.clientWidth + 48 : null }}
        >
          {isFetching && <MenuItem disabled>Loading options...</MenuItem>}
          {!isFetching && (!menuItems || menuItems.length === 0) && (
            <MenuItem disabled>No filtered options available</MenuItem>
          )}
          {!isFetching && menuItems && !!menuItems.length && (
            <MenuList>
              {menuItems.map((suggestion, index) =>
                renderMenuItem({
                  itemProps: getItemProps({
                    key: suggestion.value,
                    item: suggestion,
                    index
                  }),
                  index,
                  itemValue: suggestion.label || suggestion.value,
                  inputValue,
                  highlightedIndex,
                  selected: selectedItemValue === suggestion.value,
                  dataTestId,
                  dataTestBuilder
                })
              )}
            </MenuList>
          )}
        </Paper>
      </div>
    </Popper>
  );
}

export default withStyles(styles)(SelectFilterMenu);
