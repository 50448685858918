"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidDate = isValidDate;
exports.isSIN = isSIN;
exports.isStreetAddress = isStreetAddress;
exports.isValidStreetLength = isValidStreetLength;
exports.isValidCityLength = isValidCityLength;
exports.isValidAge = isValidAge;

var _dateFns = require("date-fns");

var _legal = require("../legal");

function getLength(value) {
  return value && typeof value === "string" ? value.trim().length : 0;
}
/**
 * isValidDate
 * Test for a valid Date object
 * @param dateObject
 * @returns {boolean}
 */


function isValidDate(dateObject) {
  if (Object.prototype.toString.call(dateObject) === "[object Date]") {
    return !isNaN(dateObject.getTime());
  } else {
    return false;
  }
}
/**
 * SIN Validator
 * validation rules: https://en.wikipedia.org/wiki/Social_Insurance_Number
 * A fictitious but valid SIN 130692544
 * @param str
 * @returns {boolean}
 */


function isSIN(value) {
  if (typeof value !== "string") {
    return false;
  }

  var str = value.replace(/[\s-]+/g, "");

  if (str.length !== 9) {
    return false;
  } // first number must not be zero


  if (/[^1-9]/.test(str.charAt(0))) {
    return false;
  }

  if (/[^0-9]/g.test(str)) {
    return false;
  }

  var multipliers = [1, 2, 1, 2, 1, 2, 1, 2, 1];
  var arrayOfNums = str.split("");
  var multiplied = arrayOfNums.map(function (num, i) {
    return num * multipliers[i];
  });
  var individualDigits = multiplied.join("").split("");
  var summed = individualDigits.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  return summed % 10 === 0;
}
/**
 * STREET_ADDRESS VALIDATOR
 * @param streetAddress
 * @returns {number|boolean}
 */


function isStreetAddress(streetAddress) {
  if (typeof streetAddress !== "string") return false;
  if (streetAddress.charAt(0) === " ") return false;
  var firstNumChar = Number(streetAddress.charAt(0));
  var isValidStreetLength = getLength(streetAddress) > 0;
  return isValidStreetLength && !isNaN(firstNumChar);
}
/**
 * TOTAL STREET ADDRESS LENGTH VALIDATION
 * total street and unit number length
 * @param unitNumber
 * @param streetAddress
 * @returns {number|boolean|boolean}
 */


function isValidStreetLength(unitNumber, streetAddress) {
  var maxTotalStreetLength = 80;
  var totalStreetLength = getLength(unitNumber) + getLength(streetAddress);
  var isValidTotalLength = totalStreetLength < maxTotalStreetLength;
  return isStreetAddress(streetAddress) && isValidTotalLength;
}
/**
 * CITY LENGTH VALIDATOR
 * @param city
 * @returns {boolean}
 */


function isValidCityLength(city) {
  var maxCityLength = 25;
  return typeof city === "string" && getLength(city) <= maxCityLength;
}
/**
 * Age of majority validator
 * @param dateOfBirth
 * @param province
 * @returns {boolean}
 */


function isValidAge(dateOfBirth, province) {
  var defaultAgeOfMaj = Object.values(_legal.AGE_OF_MAJORITY).sort()[0]; // lowest yr

  var ageOfMaj = _legal.AGE_OF_MAJORITY[province] || defaultAgeOfMaj;
  if (!dateOfBirth || !ageOfMaj) return false;
  var age = (0, _dateFns.differenceInYears)(new Date(), new Date(dateOfBirth)) || 0;
  return age >= ageOfMaj;
}