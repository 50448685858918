import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles/layout.section.styles";

function Section({
  classes,
  children,
  dataTestId,
  dataTestBuilder,
  alignText
}) {
  return (
    <div
      className={classNames([classes.root, alignText && classes[alignText]])}
      data-test={dataTestBuilder && dataTestBuilder(dataTestId, "section")}
    >
      {children}
    </div>
  );
}

Section.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(Section);
