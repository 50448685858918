import * as actions from "./notes.action.types";

export default (state = [], action) => {
  switch (action.type) {
    case actions.LOAN_APPLICATION_NOTES_FETCH_SUCCESS:
      return action.data;
    case actions.LOAN_APPLICATION_NOTES_ADD_SUCCESS:
      return [action.data, ...state];
    default:
      return state;
  }
};
