import { i18nMark } from "@lingui/react";

/**
 * Liability types list is based off of requirements
 * from PBP-841 [INFRA-1002]
 * @type {*[]}
 */
export const LIABILITY_TYPES = [
  { label: i18nMark("Auto lease"), value: "AutoLease" },
  { label: i18nMark("Auto loan"), value: "AutoLoan" },
  { label: i18nMark("Collection"), value: "Collection" },
  { label: i18nMark("Credit card"), value: "CreditCard" },
  { label: i18nMark("Loan"), value: "Loan" },
  { label: i18nMark("Mortgage"), value: "Mortgage" },
  { label: i18nMark("Personal loan"), value: "PersonalLoan" },
  { label: i18nMark("Secured line of credit"), value: "SecuredLineOfCredit" },
  { label: i18nMark("Student loan"), value: "StudentLoan" },
  {
    label: i18nMark("Unsecured line of credit"),
    value: "UnsecuredLineOfCredit"
  }
];

/**
 * Select options for liability pay frequency types for client
 * @type {*[]}
 */
export const PAY_FREQUENCY_TYPES = [
  { label: i18nMark("Daily"), value: "Daily" },
  { label: i18nMark("Weekly"), value: "Weekly" },
  { label: i18nMark("Biweekly"), value: "BiWeekly" },
  { label: i18nMark("Semimonthly"), value: "SemiMonthly" },
  { label: i18nMark("Monthly"), value: "Monthly" },
  { label: i18nMark("Quarterly"), value: "Quarterly" },
  { label: i18nMark("Semiannually"), value: "SemiAnnually" },
  { label: i18nMark("Annually"), value: "Annually" }
];

/**
 * Select options for income pay frequency types for client
 * @type {*[]}
 */
export const INCOME_PAY_FREQUENCY_TYPES = [
  { label: i18nMark("Daily"), value: "Daily", numValue: 365 },
  { label: i18nMark("Weekly"), value: "Weekly", numValue: 52 },
  { label: i18nMark("Biweekly"), value: "BiWeekly", numValue: 26 },
  { label: i18nMark("Semimonthly"), value: "SemiMonthly", numValue: 24 },
  { label: i18nMark("Monthly"), value: "Monthly", numValue: 12 },
  { label: i18nMark("Quarterly"), value: "Quarterly", numValue: 4 },
  { label: i18nMark("Semiannually"), value: "SemiAnnually", numValue: 2 },
  { label: i18nMark("Annually"), value: "Annually", numValue: 1 }
];

/**
 * Select options for title types for client
 * @type {*[]}
 */
export const TITLE_TYPES = [
  { label: i18nMark("Mr"), value: "Mr" },
  { label: i18nMark("Mrs"), value: "Mrs" },
  { label: i18nMark("Ms"), value: "Ms" },
  { label: i18nMark("Miss"), value: "Miss" },
  { label: i18nMark("Dr"), value: "Dr" },
  { label: i18nMark("Madam"), value: "Madam" },
  { label: i18nMark("Sir"), value: "Sir" }
];

/**
 * Select options for gender types for client
 * @type {*[]}
 */
export const GENDER_TYPES = [
  { label: i18nMark("Male"), value: "Male" },
  { label: i18nMark("Female"), value: "Female" },
  { label: i18nMark("Other"), value: "Other" },
  { label: i18nMark("Undisclosed"), value: "Undisclosed" }
];

/**
 * Select options for marital status types for client
 * @type {*[]}
 */
export const MARITAL_STATUS_TYPES = [
  { label: i18nMark("Single"), value: "Single" },
  { label: i18nMark("Common Law"), value: "CommonLaw" },
  { label: i18nMark("Married"), value: "Married" },
  { label: i18nMark("Separated"), value: "Separated" },
  { label: i18nMark("Divorced"), value: "Divorced" },
  { label: i18nMark("Widowed"), value: "Widowed" }
];

/**
 * Select options for phone types for client phone
 * @type {*[]}
 */
export const PHONE_TYPES = [
  { label: i18nMark("Home"), value: "Home" },
  { label: i18nMark("Mobile"), value: "Mobile" },
  { label: i18nMark("Work"), value: "Work" },
  { label: i18nMark("Fax"), value: "Fax" },
  { label: i18nMark("Other"), value: "Other" }
];

/**
 * Select options for phone types for client phone numbers
 * @type {*[]}
 */
export const CLIENT_PHONE_TYPES = [
  { label: i18nMark("Home"), value: "Home" },
  { label: i18nMark("Mobile"), value: "Mobile" },
  { label: i18nMark("Work"), value: "Work" },
  { label: i18nMark("Fax"), value: "Fax" }
];

/**
 * Select options for province types for client address
 * @type {*[]}
 */
export const PROVINCE_TYPES = [
  { label: i18nMark("AB"), value: "AB" },
  { label: i18nMark("BC"), value: "BC" },
  { label: i18nMark("MB"), value: "MB" },
  { label: i18nMark("NB"), value: "NB" },
  { label: i18nMark("NL"), value: "NL" },
  { label: i18nMark("NT"), value: "NT" },
  { label: i18nMark("NS"), value: "NS" },
  { label: i18nMark("NU"), value: "NU" },
  { label: i18nMark("ON"), value: "ON" },
  { label: i18nMark("PE"), value: "PE" },
  { label: i18nMark("QC"), value: "QC" },
  { label: i18nMark("SK"), value: "SK" },
  { label: i18nMark("YT"), value: "YT" }
];

/**
 * Select options for bank account types
 * @type {*[]}
 */
export const ACCOUNT_TYPES = [
  { label: i18nMark("Chequing"), value: "Chequing" },
  { label: i18nMark("Savings"), value: "Savings" }
];

/**
 * Select option for income job types
 * @type {*[]}
 */
export const JOB_TYPES = [
  { label: i18nMark("Permanent full time"), value: "PermanentFullTime" },
  { label: i18nMark("Temporary full time"), value: "TemporaryFullTime" },
  { label: i18nMark("Permanent part time"), value: "PermanentPartTime" },
  { label: i18nMark("Temporary part time"), value: "TemporaryPartTime" },
  { label: i18nMark("Seasonal"), value: "Seasonal" },
  { label: i18nMark("Paid internship"), value: "PaidInternship" },
  { label: i18nMark("Unpaid internship"), value: "UnpaidInternship" },
  { label: i18nMark("Co-op"), value: "Coop" },
  { label: i18nMark("Volunteer"), value: "Volunteer" }
];

/**
 * Select option for client title
 * @type {*[]}
 */
export const CLIENT_TITLE_TYPES = [
  { label: i18nMark("Mr."), value: "Mr." },
  { label: i18nMark("Ms."), value: "Ms." },
  { label: i18nMark("Mrs."), value: "Mrs." },
  { label: i18nMark("Miss"), value: "Miss" },
  { label: i18nMark("Dr."), value: "Dr." },
  { label: i18nMark("Prof."), value: "Prof." }
];

/**
 * Phone number flags for funded loan
 * @type {{MOBILE_PHONE: string, DO_NOT_TEXT: string, DO_NOT_CALL: string, WRONG_NUMBER: string, NOT_IN_SERVICE: string}}
 */
export const FUNDED_LOAN_PHONE_FLAG_TYPES = {
  MOBILE_PHONE: "MobilePhone",
  DO_NOT_TEXT: "DoNotText",
  DO_NOT_CALL: "DoNotCall",
  WRONG_NUMBER: "WrongNumber",
  NOT_IN_SERVICE: "NotInService"
};

export const REFERRAL_CATEGORY = {
  POS: "Point-of-sale"
};

export const NOTE_CATEGORY_TYPE = [
  { label: i18nMark("No category (Default)"), value: "<default>" },
  { label: i18nMark("Customer contact"), value: "Customer Contact" },
  { label: i18nMark("Audit"), value: "Audit" },
  { label: i18nMark("Statements"), value: "Statements" }
];

// TODO Remove by refactoring ReferralSource Model
export const NO_REFERRAL_AGENT = {
  label: i18nMark("No Agent"),
  value: "No Agent"
};

export const INCOME_START_MONTH_TYPES = [
  {
    label: i18nMark("January"),
    value: "1"
  },
  {
    label: i18nMark("February"),
    value: "2"
  },
  {
    label: i18nMark("March"),
    value: "3"
  },
  {
    label: i18nMark("April"),
    value: "4"
  },
  {
    label: i18nMark("May"),
    value: "5"
  },
  {
    label: i18nMark("June"),
    value: "6"
  },
  {
    label: i18nMark("July"),
    value: "7"
  },
  {
    label: i18nMark("August"),
    value: "8"
  },
  {
    label: i18nMark("September"),
    value: "9"
  },
  {
    label: i18nMark("October"),
    value: "10"
  },
  {
    label: i18nMark("November"),
    value: "11"
  },
  {
    label: i18nMark("December"),
    value: "12"
  }
];

export const BANK_REQUESTS_ADD_NEW_TYPES = [
  { value: "Manual", label: i18nMark("Manual bank statement") },
  { value: "Automated", label: i18nMark("Automated bank statement request") }
];

export const INCOME_TYPES = [
  {
    value: "Job",
    label: i18nMark("Job")
  },
  {
    value: "SelfEmployment",
    label: i18nMark("Self Employment")
  },
  {
    value: "ChildSupport",
    label: i18nMark("Child support")
  },
  {
    value: "Alimony",
    label: i18nMark("Alimony")
  },
  {
    value: "Investment",
    label: i18nMark("Investment")
  },
  {
    value: "Disability",
    label: i18nMark("Disability")
  },
  {
    value: "Pension",
    label: i18nMark("Pension")
  },
  {
    value: "InterestIncome",
    label: i18nMark("Interest income")
  },
  {
    value: "Other",
    label: i18nMark("Other")
  }
];

export const INCOME_BUSINESS_TYPE_TYPES = [
  {
    value: "Corporation",
    label: i18nMark("Corporation")
  },
  {
    value: "Partnership",
    label: i18nMark("Partnership")
  },
  {
    value: "SoleProprietorship ",
    label: i18nMark("Sole proprietorship")
  }
];

export const INCOME_INDUSTRY_SECTOR_TYPES = [
  {
    value: "BankingOrFinance",
    label: i18nMark("Banking / Finance")
  },
  {
    value: "Construction",
    label: i18nMark("Construction")
  },
  {
    value: "Education",
    label: i18nMark("Education")
  },
  {
    value: "FarmingOrNaturalResources",
    label: i18nMark("Farming / Natural resources")
  },
  {
    value: "Government ",
    label: i18nMark("Government")
  },
  {
    value: "Health",
    label: i18nMark("Health")
  },
  {
    value: "HighTech",
    label: i18nMark("High-tech")
  },
  {
    value: "LeisureOrEntertainment",
    label: i18nMark("Leisure / Entertainment")
  },
  {
    value: "Manufacturing",
    label: i18nMark("Manufacturing")
  },
  {
    value: "RetailSales",
    label: i18nMark("Retail sales")
  },
  {
    value: "Services",
    label: i18nMark("Services")
  },
  {
    value: "Transportation",
    label: i18nMark("Transportation")
  },
  {
    value: "Other",
    label: i18nMark("Other")
  }
];

export const DOCUMENT_REVIEW_STATE = [
  {
    value: "Unreviewed",
    label: i18nMark("Unreviewed")
  },
  {
    value: "Approved",
    label: i18nMark("Approved")
  },
  {
    value: "Rejected",
    label: i18nMark("Rejected")
  }
];

export const DOCUMENT_TYPE_FOR_APPLICANT = [
  {
    value: "Pre Authorized Debt Form or Void Cheque",
    label: i18nMark("Pre Authorized Debt Form or Void Cheque")
  },
  {
    value: "Notice of Assessment",
    label: i18nMark("Notice of Assessment")
  },
  {
    value: "T2124 - Stmnt of Business Activities",
    label: i18nMark("T2124 - Stmnt of Business Activities")
  },
  {
    value: "T5 - Investment Income Slip",
    label: i18nMark("T5 - Investment Income Slip")
  },
  {
    value: "T1 - General Tax Return",
    label: i18nMark("T1 - General Tax Return")
  },
  {
    value: "T4 - Employment Income Slip",
    label: i18nMark("T4 - Employment Income Slip")
  },
  {
    value: "T2032 - Stmnt of Professional Activities",
    label: i18nMark("T2032 - Stmnt of Professional Activities")
  },
  {
    value: "Scorecard",
    label: i18nMark("Scorecard")
  },
  {
    value: "Bank Statement",
    label: i18nMark("Bank Statement")
  },
  {
    value: "Bank Statements",
    label: i18nMark("Bank Statements")
  },
  {
    value: "Credit Bureau",
    label: i18nMark("Credit Bureau")
  },
  {
    value: "Personal Reference Check",
    label: i18nMark("Personal Reference Check")
  },
  {
    value: "Bank Statements (180 Days)",
    label: i18nMark("Bank Statements (180 Days)")
  },
  {
    value: "Other",
    label: i18nMark("Other")
  },
  {
    value: "Balance Letter",
    label: i18nMark("Balance Letter")
  },
  {
    value: "Settlement in Full Letter",
    label: i18nMark("Settlement in Full Letter")
  },
  {
    value: "Letter of Direction",
    label: i18nMark("Letter of Direction")
  },
  {
    value: "Payment in Full Letter",
    label: i18nMark("Payment in Full Letter")
  },
  {
    value: "Account Statements",
    label: i18nMark("Account Statements")
  },
  {
    value: "Letter of Employment",
    label: i18nMark("Letter of Employment")
  },
  {
    value: "Proof of Income",
    label: i18nMark("Proof of Income")
  },
  {
    value: "VOE",
    label: i18nMark("VOE")
  },
  {
    value: "Paystub(s)",
    label: i18nMark("Paystub(s)")
  },
  {
    value: "Employment Reference Check",
    label: i18nMark("Employment Reference Check")
  },
  {
    value: "Proof of Address",
    label: i18nMark("Proof of Address")
  },
  {
    value: "Emergency Contacts",
    label: i18nMark("Emergency Contacts")
  },
  {
    value: "Primary Identification",
    label: i18nMark("Primary Identification")
  },
  {
    value: "Secondary Identification",
    label: i18nMark("Secondary Identification")
  },
  {
    value: "Pension Statement",
    label: i18nMark("Pension Statement")
  },
  {
    value: "Investment Statements",
    label: i18nMark("Investment Statements")
  },
  {
    value: "Separation Agreement",
    label: i18nMark("Separation Agreement")
  },
  {
    value: "Disability Income Letter",
    label: i18nMark("Disability Income Letter")
  },
  {
    value: "Stated Income Declaration",
    label: i18nMark("Stated Income Declaration")
  },
  {
    value: "Business Income Statements",
    label: i18nMark("Business Income Statements")
  }
];

export const DOCUMENT_TYPE_FOR_LOAN = [
  {
    value: "Other",
    label: i18nMark("Other")
  },
  {
    value: "Signed Loan Agreement",
    label: i18nMark("Signed Loan Agreement")
  },
  {
    value: "E-sign Disclosure",
    label: i18nMark("E-sign Disclosure")
  },
  {
    value: "Loan Agreement",
    label: i18nMark("Loan Agreement")
  },
  {
    value: "Change Status to Ready for LMS Once Signed Agreement Received",
    label: i18nMark(
      "Change Status to Ready for LMS Once Signed Agreement Received"
    )
  },
  {
    value: "Payment Confirmation",
    label: i18nMark("Payment Confirmation")
  },
  {
    value: "POP Sent",
    label: i18nMark("POP Sent")
  }
];
