"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AGE_OF_MAJORITY = void 0;
var AGE_OF_MAJORITY = {
  AB: 18,
  BC: 19,
  MB: 18,
  NB: 19,
  NL: 19,
  NT: 19,
  NS: 19,
  NU: 19,
  ON: 18,
  PE: 18,
  QC: 18,
  SK: 18,
  YT: 19
};
exports.AGE_OF_MAJORITY = AGE_OF_MAJORITY;