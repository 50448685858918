import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { TRANCHE_TILE_CARD_INPUT } from "../../pages/tranche.management/tranche.data.test.ids";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { fileInput: null };
  }

  componentDidMount() {
    const fileInput = this.getFileInput();
    this.setState({ fileInput });
  }

  getFileInput = () => {
    return this.inputRef.current;
  };

  handleFormSubmit = async event => {
    const { fileInput } = this.state;
    const { onFormSubmit } = this.props;
    const hasValidSize = this.validateSize();
    const hasValidType = this.validateType();

    if (
      (fileInput.files[0] || event.target.files) &&
      hasValidSize &&
      hasValidType
    ) {
      const reader = new FileReader();
      await reader.readAsText(fileInput.files[0]);
      reader.onload = e => {
        const csvResult = e.currentTarget.result;
        if (e.currentTarget.readyState === 2 && csvResult) {
          const fileMeta = fileInput.files[0];
          onFormSubmit({ hasValidSize, hasValidType }, fileMeta, csvResult);
        }
      };
    }
    if (!hasValidSize || !hasValidType) {
      onFormSubmit({ hasValidSize, hasValidType }, null, null);
    }
  };

  validateType = () => {
    const { fileInput } = this.state;
    const { accept } = this.props;

    let hasValidType = false;

    const fileType = fileInput.files[0].name.split(".").pop();
    const acceptList = accept.filter(type => type === `.${fileType}`)[0];

    if (acceptList || typeof acceptList !== "undefined")
      fileType === acceptList.toString().substr(1)
        ? (hasValidType = true)
        : (hasValidType = false);

    return hasValidType;
  };

  validateSize = () => {
    const { fileInput } = this.state;
    const { validFileSize } = this.props;
    let isValid = true;
    if (fileInput.files[0]) {
      const fileSize = fileInput.files[0].size / 1024 / 1024; // in MB
      if (fileSize > validFileSize || fileSize === 0) {
        isValid = false;
      }
    }

    return isValid;
  };
  render() {
    const { classes, id, accept, children, inputClass, multiple } = this.props;
    return (
      <div className={classes.fileUploadWrapper}>
        {children}
        <input
          type="file"
          accept={accept.join(", ")}
          id={id}
          className={inputClass}
          onChange={this.handleFormSubmit}
          ref={this.inputRef}
          multiple={multiple}
          data-test={TRANCHE_TILE_CARD_INPUT}
        />
      </div>
    );
  }
}

FileUpload.defaultProps = {
  multiple: false
};

FileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  accept: PropTypes.array.isRequired, // array or accepted files types .csv, .png ,...
  inputClass: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  validFileSize: PropTypes.number
};

export default withStyles(styles)(FileUpload);
