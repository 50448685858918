export const styles = () => ({
  fieldGridWrapper: {
    width: "332px",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    flex: "none"
  },
  leftIndent: {
    margin: "16px 16px 0px 16px"
  },
  noIndent: {
    margin: "16px 16px 0px 0px"
  }
});
