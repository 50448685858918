import React, { Fragment } from "react";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// LOCAL STYLES
import styles from "../styles/nested.menu.multiselect.styles";

const MultiSelectMenu = props => {
  const {
    classes,
    containerId,
    type,
    handleMenuItemClick,
    dataTestId,
    actions,
    menuItems
  } = props;

  return (
    <Fragment>
      {actions}
      <FormControl
        component="fieldset"
        classes={{ root: classes.formControlRoot }}
        data-test={dataTestId.menu}
      >
        <FormGroup>
          {menuItems &&
            menuItems.map(item => (
              <FormControlLabel
                classes={{
                  root: classes.formControlLabelRoot,
                  label: classes.formControlLabel
                }}
                key={item.id}
                id={item.id}
                data-test={`${dataTestId.menuItem}-${kebabCase(item.id)}`}
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{
                      "data-test": `${dataTestId.checkbox}-${kebabCase(
                        item.id
                      )}`
                    }}
                    checked={item.selected}
                    disabled={item.disabled}
                    classes={{
                      root: classes.checkboxRoot,
                      colorPrimary: classes.checkboxColorPrimary,
                      checked: classes.checkboxChecked,
                      disabled: classes.checkboxDisabled
                    }}
                    onChange={event => {
                      handleMenuItemClick(event, {
                        containerId,
                        type,
                        itemId: item.id
                      });
                      event.stopPropagation();
                    }}
                    value={item.id}
                  />
                }
                label={item.label}
              />
            ))}
        </FormGroup>
      </FormControl>
    </Fragment>
  );
};

MultiSelectMenu.defaultProps = {
  actions: []
};

MultiSelectMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  containerId: PropTypes.string.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired,
  actions: PropTypes.node,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withStyles(styles)(MultiSelectMenu);
