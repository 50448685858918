import { fadeHEX } from "../../../utils/jss.utils";

export const styles = theme => ({
  dialogWrapper: {
    maxWidth: "340px"
  },
  dialogTitle: {
    fontSize: "26px",
    fontWeight: "bold",
    color: theme.palette.grey[900],
    lineHeight: "32px"
  },
  cancelButton: {
    backgroundColor: theme.palette.lightGrey[900],
    color: theme.palette.grey[900],
    boxShadow: "none",
    width: "81px",
    height: "32px",
    minHeight: "32px",
    margin: "0 8px 0 0",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.grey[900]
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.grey[900]
    }
  },
  confirmButton: {
    backgroundColor: fadeHEX(theme.palette.error.main, 0.3),
    color: theme.palette.grey[900],
    boxShadow: "none",
    minWidth: "81px",
    height: "32px",
    minHeight: "32px",
    margin: "0 0 0 8px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: fadeHEX(theme.palette.error.main, 0.45)
    },
    "&:active": {
      backgroundColor: fadeHEX(theme.palette.error.main, 0.7),
      color: theme.palette.secondary.contrastText
    }
  },
  actionsWrapper: {
    margin: "16px"
  }
});
