import PropTypes from "prop-types";
import defaultsDeep from "lodash/defaultsDeep";

/**
 * Normalize data to insert missing fields
 * and checks for data type
 * @param data
 * @param defaultProps
 * @param schema
 * @param modelName
 */
export function normalize(data, defaultProps, schema, modelName) {
  const clonedData = JSON.parse(JSON.stringify(data));

  // Inserts default properties coming defaultProps
  const normalizedData = defaultsDeep(clonedData, defaultProps);

  // Captures data type changes from service
  // Only for development so it does not slow production builds or tests
  if (process.env.NODE_ENV === "development") {
    PropTypes.checkPropTypes(
      schema,
      normalizedData,
      "property",
      `${modelName} Data`
    );
  }

  return normalizedData;
}

/**
 * sortByCreatedDate
 * @param a
 * @param b
 * @returns {number}
 */
export function sortByCreatedDate(a, b) {
  if (a.createdAt > b.createdAt) {
    return -1;
  } else if (a.createdAt < b.createdAt) {
    return 1;
  } else {
    return a.subject <= b.subject ? -1 : 1;
  }
}

/**
 * transformReceived - data as collection
 * @author Ehsan
 * @param rawData {object} // coming from the service
 * @param props {object}   // default props
 * @param schema {object}  // data schema of each item of the collection
 * @param modelName "string"
 */
export const transformReceivedCollection = (
  rawData,
  props,
  schema,
  modelName
) => {
  const { results: collectionData } = rawData;

  if (collectionData && typeof collectionData !== "undefined") {
    const data = collectionData.map(item => {
      return toDataCollection(item, props, schema, modelName);
    });
    return { ...rawData, results: data };
  }

  if (!collectionData && Array.isArray(rawData)) {
    const raw = rawData.map(item => {
      return toDataCollection(item, props, schema, modelName);
    });
    return { results: raw };
  }

  return { results: [{ ...rawData }] };
};

// normalize (validate) each item of the collection
function toDataCollection(data, props, schema, modelName) {
  return normalize({ ...data }, props, schema, modelName);
}
