/**
 * RESIZER STYLES
 * class name -pagination
 * @param theme
 * @author Ryan Rivera
 */
const styles = theme => ({
  display: "inline-block",
  position: "absolute",
  width: "16px",
  top: 0,
  bottom: 0,
  right: "-8px",
  cursor: "col-resize",
  zIndex: 1,

  "&:after": {
    borderLeft: "1px solid",
    borderColor: "transparent",
    position: "absolute",
    width: "1px",
    left: "50%",
    content: '" "', // Gotcha: " " should be wrapped in '' to render properly
    zIndex: 2,
    transition: "border-color 0.2s ease-out"
  },

  "&:hover:after": {
    borderColor: theme.palette.primary["light"],
    transition: "border-color 0.2s ease-in",
    height: "100vh"
  },

  "&:active:after": {
    borderColor: theme.palette.primary["main"],
    transition: "border-color 0.2s ease-in"
  }
});

export default styles;
