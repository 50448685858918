const transitionStyles = {
  transition: "background-color .2s ease-in",
  "& .material-icons": {
    transition: "color .2s ease-in"
  }
};

const styles = theme => ({
  rootMD: {
    width: "32px",
    height: "32px",
    padding: 0,
    ...transitionStyles,
    "&$disabled": {
      color: theme.palette.lightGrey[900]
    }
  },
  rootSM: {
    width: "22px",
    height: "22px",
    padding: 0,
    ...transitionStyles
  },
  label: {
    position: "absolute"
  },
  disabled: {
    color: theme.palette.lightGrey[900]
  }
});

export default styles;
