import find from "lodash/find";
import partition from "lodash/partition";
import pullAllBy from "lodash/pullAllBy";
import pullAll from "lodash/pullAll";

/**
 * Cache management utilities which manages multiple add and update items
 * @author Ryan R
 * Usage:
 * formCache.addCollection.cache(collection, item)
 * formCache.addCollection.clean(collection, id, index)
 * formCache.updateCollection.cache(collection, item)
 * formCache.updateCollection.clean(collection, id)
 */
export const formCache = {
  addCollection: {
    cache: collection => ({ id, values, index }) => {
      const partitionedCollection = partition(collection, {
        id: id
      });
      const firstPartition = partitionedCollection[0];
      const firstPartitionIndex = index || 0;
      const firstPartitionValues = firstPartition[firstPartitionIndex]
        ? firstPartition[firstPartitionIndex].values
        : {};
      const newValues = { ...firstPartitionValues, ...values };
      const targetCollection = !firstPartition[firstPartitionIndex]
        ? [...firstPartition, { id, values: newValues }]
        : firstPartition.map((item, i) =>
            i === firstPartitionIndex
              ? { ...item, id, values: newValues }
              : item
          );
      return targetCollection.concat(partitionedCollection[1]);
    },
    cacheMultiple: collection => ({ id, values, index }) => {
      // [[formA], [formB, formC]]
      const partitionedCollection = partition(collection, {
        id: id,
        index: index
      });
      // [formA]
      const firstPartition = partitionedCollection[0];
      const firstPartitionValues = firstPartition[0]
        ? firstPartition[0].values
        : {};
      const newValues = { ...firstPartitionValues, ...values };
      const targetCollection = !firstPartition[0]
        ? [...firstPartition, { id, values: newValues, index }]
        : firstPartition.map(item =>
            item.index === index
              ? { ...item, id, values: newValues, index }
              : item
          );
      return targetCollection.concat(partitionedCollection[1]);
    },
    clean: collection => (id, index) => {
      const partitionedCollection = partition(collection, { id: id });
      const firstPartition = partitionedCollection[0];
      const targetCollection = firstPartition[index]
        ? firstPartition.filter((item, i) => i !== index)
        : firstPartition;
      return targetCollection.concat(partitionedCollection[1]);
    },
    cleanMultiple: collection => (id, index) => {
      // [[formA], [formB, formC]]
      const partitionedCollection = partition(collection, {
        id: id,
        index: index
      });
      // [formA]
      const firstPartition = partitionedCollection[0];
      const targetCollection = firstPartition[0]
        ? firstPartition.filter(item => item.index !== index)
        : firstPartition;
      return targetCollection.concat(partitionedCollection[1]);
    }
  },
  updateCollection: {
    cache: collection => ({ id, values, isFetching, ...props }) => {
      const itemExists = find(collection, { id: id });
      if (itemExists) {
        return collection.map(item => {
          if (item.id === id) {
            return {
              ...item,
              values: { ...item.values, ...values }
            };
          }
          return item;
        });
      }
      return collection.concat([{ id, values, isFetching, ...props }]);
    },
    clean: collection => id => {
      return collection.filter(item => item.id !== id);
    }
  }
};

/**
 * @author Ehsan
 * @param {array} cachedForms form reducer state (update or add array)
 * @param {string} key target object key name
 * @param {string} id (accountId, liabilityId, ...)
 */
export const cancelEditForm = (cachedForms, key, id) => {
  const newArray = [...cachedForms];
  const targetObject = {};
  targetObject[`${key}`] = id;
  return pullAllBy(newArray, [targetObject], `${key}`);
};

/**
 * @author Ehsan
 * @param {array} cachedForms form reducer state (update or add array)
 * @param {object} compareObject includes key and id need to be compared against the existing form objects
 * @param {object} values form values
 */
export const cacheEditFormValues = (cachedForms, compareObject, values) => {
  const compareKeys = Object.keys(compareObject);
  return cachedForms.map(form => {
    if (form[compareKeys[0]] === compareObject[compareKeys[0]]) {
      return {
        ...form,
        values
      };
    }
    return form;
  });
};

/**
 * @author Ehsan
 * @param {array} cachedForms form reducer state (update or add array)
 * @param {object} values form values
 */
export const cachForm = (cachedForms, values) => {
  const newArray = [...cachedForms];
  return [...newArray, values];
};

/**
 * @author Ehsan
 * @param {array} cachedForms form reducer state (update or add array)
 * @param {string} id form id
 * @param {string} field form field (e.g: title, marital, ...)
 */
export const cancelEditFormByField = (cachedForms, id, field) => {
  const newArray = [...cachedForms];
  const targetObject = newArray.filter(
    form => form.id === id && form.field === field
  );
  return pullAll(newArray, targetObject);
};

/**
 * @author Ehsan
 * @param {array} cachedForms form reducer state (update or add array)
 * @param {string} id form id
 * @param {string} field form field (e.g: title, marital, ...)
 * @param {boolean} status target form's isFetching status
 */
export const updateFormFetchingStatus = (
  cachedForms,
  id,
  field,
  status,
  index = undefined
) => {
  const newArray = [...cachedForms];
  return newArray.map(form => {
    if (form.id === id && form.field === field && form.index === index) {
      return {
        ...form,
        isFetching: status
      };
    }
    return form;
  });
};

/**
 * @author Ehsan
 * @param {array} clientsState clients state
 * @param {string} id client id
 * @param {string} key target prop name
 * @param {any} data response data from api or specific part of it
 */
export const updateClientsData = (clientsState, id, key, data) => {
  const clientToUpdate = Object.assign({}, find(clientsState, { id }));
  clientToUpdate[`${key}`] = data;
  const newState = [...clientsState];
  return newState.map(client => {
    if (client.id === id) {
      return {
        ...client,
        ...clientToUpdate
      };
    }
    return client;
  });
};

/**
 * create initial state for form reducers
 * @param {array} keys
 */
export const createInitialFormState = keys => {
  const state = {};
  keys.forEach(key => {
    if (key && typeof key === "string") {
      state[key] = {
        add: [],
        update: []
      };
    }
  });
  return state;
};
