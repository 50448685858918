import * as actionTypes from "../action.types/tabs.action.types";
import {
  FUNDED_LOAN_NOTES_FETCH,
  FUNDED_LOAN_NOTES_FETCH_FAILURE,
  FUNDED_LOAN_NOTES_FETCH_SUCCESS,
  NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS,
  NOTE_TAB_NEW_NOTE_SUBMIT_FAILURE,
  NOTE_NEW_NOTE_SUBMIT
} from "../action.types/notes.action.types";
import * as detailActionTypes from "../action.types/funded.loan.details.action.types";

const initialState = {
  docsTab: {
    docs: [],
    docsCount: 0
  },
  payoffAmount: null,
  payments: [],
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ALL BASIC FETCHING USE FALL THROUGH!

    case FUNDED_LOAN_NOTES_FETCH_SUCCESS:
    case FUNDED_LOAN_NOTES_FETCH_FAILURE:
    case actionTypes.FUNDED_LOAN_DETAILS_DOCS_FETCH_COMPLETED:
    case NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS:
    case NOTE_TAB_NEW_NOTE_SUBMIT_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    case FUNDED_LOAN_NOTES_FETCH:
    case NOTE_NEW_NOTE_SUBMIT:
    case actionTypes.FUNDED_LOAN_DETAILS_DOCS_FETCH:
    case actionTypes.FUNDED_LOAN_DETAILS_PAYMENTS_FETCH:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.FUNDED_LOAN_DETAILS_PAYOFF_FETCH_SUCCESS: {
      const payoffAmount = action.data.payoffAmount;
      return {
        ...state,
        payoffAmount
      };
    }

    case actionTypes.FUNDED_LOAN_DETAILS_PAYMENTS_FETCH_SUCCESS: {
      const payments = action.data.results;
      return {
        ...state,
        payments,
        isFetching: false
      };
    }

    case actionTypes.FUNDED_LOAN_DETAILS_DOCS_FETCH_SUCCESS: {
      const docs = action.data.results;
      return {
        ...state,
        docsTab: {
          docs,
          docsCount: docs.length
        },
        isFetching: false
      };
    }

    case detailActionTypes.FUNDED_LOAN_DETAILS_TAB_STATS_UPDATE: {
      const stats = action.stats;
      return {
        ...state,
        docsTab: {
          ...state.docsTab,
          docsCount: stats.documentCounts
        }
      };
    }

    default:
      return state;
  }
};
