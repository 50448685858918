import { put, call, fork, takeLatest, all } from "redux-saga/effects";

import { http } from "../../../../../../../services/http/http.service";
import * as messengerActions from "../../../../../../../services/messenger/messenger.actions";

import { fetchDebtServiceRatio } from "../../../../../engine/actions/loan.application.details.actions";
import {
  addNewIncomeSuccess,
  deleteIncomeSuccess,
  deleteIncomeFailure,
  updateIncomeSuccess
} from "./incomes.actions";
import {
  LOAN_APPLICATION_CLIENT_INCOME_ADD,
  LOAN_APPLICATION_CLIENT_INCOME_DELETE,
  LOAN_APPLICATION_CLIENT_INCOME_UPDATE
} from "./incomes.action.types";
import { transformSent } from "../../../../../../../models/clients/income.model";
import {
  SUCCESS_MESSAGES,
  ERROR_MESSAGES
} from "../../../../../../../core/constants";
import {
  deleteCachedForm,
  isFetchingForm,
  addFormSectionError
} from "../../../../../../../services/forms/forms.actions";
import { generateFormErrorActions } from "../../../../../../../services/error.handler/error.handler.saga.util";

export const postIncome = action => {
  const newFormIncome = action.payload.data;
  const newIncome = transformSent(newFormIncome);
  const { applicationId, clientId } = action.payload;

  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.CLIENT_INCOMES(clientId), newIncome);
    },
    success: data => [
      put(addNewIncomeSuccess(clientId, data)),
      put(deleteCachedForm(action.formId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.INCOME.ADD)),
      put(fetchDebtServiceRatio(applicationId))
    ],
    error: error =>
      all([
        put(isFetchingForm(action.formId, false)),
        put(addFormSectionError(action.formId, error))
      ])
  });
};

export const putIncome = action => {
  const { applicationId, clientId, incomeId, payload } = action;
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.put,
        API.CLIENT_INCOME(clientId, incomeId),
        transformSent(payload.data)
      );
    },
    success: data => [
      put(updateIncomeSuccess(clientId, incomeId, data)),
      put(deleteCachedForm(action.formId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.INCOME.UPDATE)),
      put(fetchDebtServiceRatio(applicationId))
    ],
    error: error =>
      all([
        put(isFetchingForm(action.formId, false)),
        put(addFormSectionError(action.formId, error))
      ])
  });
};

export const deleteIncome = action => {
  const { applicationId, clientId, incomeId, formId } = action;
  return http({
    request: (httpSrv, API) => {
      const endpoint = API.CLIENT_INCOME(clientId, incomeId);
      return call(httpSrv.delete, endpoint);
    },
    success: () => [
      put(deleteIncomeSuccess(clientId, incomeId)),
      put(deleteCachedForm(formId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.INCOME.DELETE)),
      put(fetchDebtServiceRatio(applicationId))
    ],
    error: error => {
      const primaryActions = [put(deleteIncomeFailure())];
      const errorActions = generateFormErrorActions(
        primaryActions,
        formId,
        error,
        ERROR_MESSAGES.INCOME.DELETE
      );

      return all(errorActions);
    }
  });
};

export function* watchIncomeRequests() {
  yield takeLatest(LOAN_APPLICATION_CLIENT_INCOME_ADD, postIncome);
  yield takeLatest(LOAN_APPLICATION_CLIENT_INCOME_DELETE, deleteIncome);
  yield takeLatest(LOAN_APPLICATION_CLIENT_INCOME_UPDATE, putIncome);
}

export default [fork(watchIncomeRequests)];
