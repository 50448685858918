export const styles = theme => ({
  headerBtn: {
    width: "auto",
    height: 48,
    "& span p": {
      paddingLeft: 8
    }
  },
  headerBtnLabel: {
    fontSize: 16,
    lineHeight: "24px",
    color: theme.palette.grey["50"],
    "&:hover": {
      cursor: "pointer"
    }
  },
  headerBtnIcon: {
    fontSize: 18
  },
  avatar: {
    color: theme.palette.grey["800"],
    fontSize: 13,
    width: 28,
    height: 28,
    background: "white"
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main
    },
    paddingLeft: "16px",
    height: "23px",
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  menuItemText: theme.typography.p2Dark
});
