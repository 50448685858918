var blogLinks = {
  BLOG: {
    name: "blog",
    label: "Blog",
    link: "http://blog.progressa.com/"
  },
  FINANCIAL_TIPS: {
    name: "financialTips",
    label: "Financial Literacy Tips",
    link: "https://blog.progressa.com/how-poor-credit-can-affect-your-life/"
  }
};
var siteLinks = {
  TERMS_AND_CONDITIONS: {
    name: "terms-conditions",
    label: "T&Cs",
    link: "https://progressa.com/terms-and-conditions/"
  },
  PRIVACY_POLICY: {
    name: "privacy-policy",
    label: "Privacy Policy",
    link: "https://progressa.com/privacy_policy"
  },
  ABOUT_US: {
    name: "about-us",
    label: "About Us",
    link: "https://progressa.com/about"
  },
  BLOG: blogLinks.BLOG,
  GIVEAWAYS: {
    name: "giveaways",
    label: "Giveaway",
    link: "https://progressa.com/giveaway"
  },
  CONTACT_US: {
    name: "contact-us",
    label: "Contact Us",
    link: "https://progressa.com/contact"
  }
};
export { blogLinks };
export default siteLinks;