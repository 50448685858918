import React, { Component } from "react";
import { utils } from "@cauldron/core";
import classnames from "classnames";
import {
  SEARCH_TABLE,
  SEARCH_TABLE_PAGINATION,
  SEARCH_TABLE_PAGINATION_NEXT,
  SEARCH_TABLE_PAGINATION_PREV,
  SEARCH_TABLE_PAGINATION_CENTER,
  SEARCH_TABLE_PAGINATION_PAGE_INFO,
  SEARCH_TABLE_PAGINATION_PAGE_NUMBER
} from "../../../pages/constants/data.test.ids";

const { generateTestIds } = utils;

export const defaultButton = props => {
  const { children, ...rest } = props;
  return (
    <button type="button" {...rest} className="-btn">
      {children}
    </button>
  );
};

export default class Pagination extends Component {
  constructor(props) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);

    this.state = {
      page: props.page
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ page: nextProps.page });
  }

  getSafePage(page) {
    const { page: propsPage, pages } = this.props;
    if (Number.isNaN(page)) {
      page = propsPage;
    }
    return Math.min(Math.max(page, 0), pages - 1);
  }

  changePage(page) {
    page = this.getSafePage(page);

    const { page: propsPage, onPageChange } = this.props;

    this.setState({ page });
    if (propsPage !== page) {
      onPageChange(page);
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault();
    }
    const { page: statePage } = this.state;
    const { page: propsPage } = this.props;
    this.changePage(statePage === "" ? propsPage : statePage);
  }

  render() {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      PreviousComponent = defaultButton,
      NextComponent = defaultButton,
      style,
      previousText,
      pageText,
      ofText,
      rowsText,
      nextText
    } = this.props;

    const { page: statePage } = this.state;

    const root_testID = generateTestIds(SEARCH_TABLE, SEARCH_TABLE_PAGINATION);
    const paginationNextButton = generateTestIds(
      root_testID,
      SEARCH_TABLE_PAGINATION_NEXT
    );
    const paginationPrevButton = generateTestIds(
      root_testID,
      SEARCH_TABLE_PAGINATION_PREV
    );
    const paginationCenter = generateTestIds(
      root_testID,
      SEARCH_TABLE_PAGINATION_CENTER
    );
    const paginationPageInfo = generateTestIds(
      root_testID,
      SEARCH_TABLE_PAGINATION_CENTER,
      SEARCH_TABLE_PAGINATION_PAGE_INFO
    );
    const paginationPageNumber = generateTestIds(
      root_testID,
      SEARCH_TABLE_PAGINATION_CENTER,
      SEARCH_TABLE_PAGINATION_PAGE_NUMBER
    );

    return (
      <div
        className={classnames(className, "-pagination")}
        style={style}
        data-test={root_testID}
      >
        <div className="-previous" data-test={paginationPrevButton}>
          <PreviousComponent
            onClick={() => {
              if (!canPrevious) return;
              this.changePage(page - 1);
            }}
            disabled={!canPrevious}
          >
            {previousText}
          </PreviousComponent>
        </div>
        <div className="-center" data-test={paginationCenter}>
          <span className="-pageInfo" data-test={paginationPageInfo}>
            {pageText}{" "}
            {showPageJump ? (
              <div className="-pageJump">
                <input
                  type={statePage === "" ? "text" : "number"}
                  onChange={e => {
                    const val = e.target.value;
                    const page = val - 1;
                    if (val === "") {
                      return this.setState({ page: val });
                    }
                    this.setState({ page: this.getSafePage(page) });
                  }}
                  value={statePage === "" ? "" : statePage + 1}
                  onBlur={this.applyPage}
                  onKeyPress={e => {
                    if (e.which === 13 || e.keyCode === 13) {
                      this.applyPage();
                    }
                  }}
                />
              </div>
            ) : (
              <span className="-currentPage">{(page + 1).toString()}</span>
            )}{" "}
            {ofText} <span className="-totalPages">{pages || 1}</span>
          </span>
          {showPageSizeOptions && (
            <span
              className="select-wrap -pageSizeOptions"
              data-test={paginationPageNumber}
            >
              <span className="-caret" />
              <select
                onChange={e => onPageSizeChange(Number(e.target.value))}
                value={pageSize}
              >
                {pageSizeOptions.map((option, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={i} value={option}>
                    {option} {rowsText}
                  </option>
                ))}
              </select>
            </span>
          )}
        </div>
        <div className="-next" data-test={paginationNextButton}>
          <NextComponent
            onClick={() => {
              if (!canNext) return;
              this.changePage(page + 1);
            }}
            disabled={!canNext}
          >
            {nextText}
          </NextComponent>
        </div>
      </div>
    );
  }
}
