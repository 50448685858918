const styles = theme => ({
  toggleButtonGroup: {
    marginTop: "32px",
    marginBottom: "32px",
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "wrap",
    zIndex: 1,
    padding: "0 3px",
    "&.condensed > *": {
      flexGrow: 0,
      minWidth: "60px"
    }
  },
  toggleButton: {
    height: "56px",
    minWidth: "126px",
    padding: "20px 40px",
    fontSize: "16px",
    lineHeight: "20px"
  },
  iconToggleButton: {
    height: "194px",
    width: "194px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "2px",
    boxShadow:
      "0 0 2px 0 rgba(0,0,0,0.14), 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    justifyContent: "initial"
  },
  activeIconToggleButton: {
    height: "194px",
    width: "194px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "2px",
    boxShadow:
      "0 0 2px 0 rgba(0,0,0,0.14), 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    zIndex: 1,
    justifyContent: "initial",
    "& > div": {
      backgroundColor: theme.palette.secondary.main
    },
    "& > div > svg": {
      fill: theme.palette.secondary.dark
    }
  },
  iconWrapper: {
    height: "112px",
    width: "112px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    backgroundColor: theme.palette.lightGrey[50],
    marginBottom: "4px",
    marginTop: "20px"
  },
  typographyWrapper: {
    ...theme.typography.p2Disabled,
    width: "158px",
    whiteSpace: "normal",
    wordBreak: "break-word"
  },
  typographyActiveWrapper: {
    ...theme.typography.p2Dark,
    width: "158px",
    whiteSpace: "normal",
    wordBreak: "break-word"
  },
  iconToggleStyle: {
    margin: "16px"
  },
  toggleStyle: {
    padding: 0,
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.lightGrey[500]}`,
    "&:last-child": {
      borderRightWidth: 0
    },
    borderLeft: "none",
    borderTop: "none",
    borderBottom: "none",
    boxShadow: `0 1px 3px 1px ${theme.palette.lightGrey[400]}`,
    outline: "none",
    cursor: "pointer",
    zIndex: 0,
    textTransform: "none",
    transition: "all .5s",
    borderRadius: 0,
    "&.disabled": {
      color: theme.palette.lightGrey[500],
      backgroundColor: theme.palette.grey[50],
      boxShadow: `0 1px 3px 1px ${theme.palette.lightGrey[100]}`
    },
    "&:active": {
      "& span > div > svg": {
        fill: theme.palette.secondary.dark
      },
      "& span > div": {
        backgroundColor: theme.palette.secondary.main
      }
    },
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      "& span > div > svg": {
        fill: theme.palette.secondary.dark
      },
      "& span > div": {
        backgroundColor: theme.palette.secondary.light
      },
      "& span > p": {
        ...theme.typography.p2Dark
      }
    },
    "&.active": {
      boxShadow: `0 1px 3px 1px ${theme.palette.lightGrey[700]}`,
      borderRadius: "2px",
      zIndex: 1
    }
  },
  collectionAgencyIcon: {
    fontSize: "60px",
    color: theme.palette.lightGrey[900]
  },
  payoffDebtIcon: {
    fontSize: "60px",
    marginTop: "20px",
    color: theme.palette.lightGrey[900]
  }
});

export default styles;
