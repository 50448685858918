"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _model = _interopRequireDefault(require("./model"));

var transformReceived = function transformReceived(data) {
  var referralSources = data.results.map(function (p) {
    return _model.default.transformReceived(p);
  });
  return {
    results: referralSources
  };
};
/**
 * Permission Collection
 */


var _default = {
  model: "ReferralSource",
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;