import { actionTypes } from "./credit.report.action.types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREDIT_REPORT_SUMMARY_FETCH_SUCCESS:
      return {
        ...state,
        [action.clientId]: action.data.results
      };

    default:
      return state;
  }
};
