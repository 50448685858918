/**
 * Bank List
 * Seeded from LMS
 * select BankName, BankNumber from Banks
 */
export const BANKS_LIST = [
  {
    name: "Bank of Montreal",
    id: "001"
  },
  {
    name: "Bank of Nova Scotia",
    id: "002"
  },
  {
    name: "Royal Bank of Canada",
    id: "003"
  },
  {
    name: "Toronto-Dominion Bank",
    id: "004"
  },
  {
    name: "National Bank of Canada",
    id: "006"
  },
  {
    name: "Canadian Imperial Bank of Commerce",
    id: "010"
  },
  {
    name: "HSBC Canada",
    id: "016"
  },
  {
    name: "Canadian Western Bank",
    id: "030"
  },
  {
    name: "Laurentian Bank of Canada",
    id: "039"
  },
  {
    name: "Government of Canada",
    id: "117"
  },
  {
    name: "Canada Post",
    id: "127"
  },
  {
    name: "Bank of Canada",
    id: "177"
  },
  {
    name: "ATB Financial",
    id: "219"
  },
  {
    name: "Bank of America",
    id: "241"
  },
  {
    name: "Citibank",
    id: "260"
  },
  {
    name: "JPMorgan Chase (Toronto Branch)",
    id: "270"
  },
  {
    name: "UBS Bank",
    id: "290"
  },
  {
    name: "Bank of China",
    id: "308"
  },
  {
    name: "Citizens Bank of Canada",
    id: "309"
  },
  {
    name: "Simplii Financial (AKA PC Financial)",
    id: "010"
  },
  {
    name: "Canadian Tire Bank",
    id: "338"
  },
  {
    name: "ICICI Bank",
    id: "340"
  },
  {
    name: "Canada Trust",
    id: "509"
  },
  {
    name: "Manulife Bank",
    id: "540"
  },
  {
    name: "ING Direct Canada",
    id: "619"
  },
  {
    name: "Central 1 [Credit Union] - BC Region",
    id: "809"
  },
  {
    name: "Caisses Desjardins du Québec",
    id: "815"
  },
  {
    name: "Caisses populaires Desjardins du Manitoba",
    id: "819"
  },
  {
    name: "Central 1 [Credit Union] - ON Region",
    id: "828"
  },
  {
    name: "Caisses populaires Desjardins de l'Ontario",
    id: "829"
  },
  {
    name: "Meridian Credit Union",
    id: "837"
  },
  {
    name: "Credit Union Heritage (Nova Scotia)",
    id: "839"
  },
  {
    name: "Caisses populaires Desjardins acadiennes",
    id: "865"
  },
  {
    name: "Credit Union Central of Manitoba",
    id: "879"
  },
  {
    name: "Credit Union Central of Saskatchewan",
    id: "889"
  },
  {
    name: "Credit Union Central Alberta",
    id: "899"
  },
  {
    name: "C.P. DE NOELVILLE LTEE",
    id: "890"
  },
  {
    name: "BAYVIEW CREDIT UNION LTD",
    id: "849"
  },
  {
    name: "FIRST NATIONS BANK OF CANADA",
    id: "310"
  },
  {
    name: "ALTERNA SAVINGS AND CREDIT UNION LTD",
    id: "842"
  },
  {
    name: "Tangerine Bank",
    id: "614"
  },
  {
    name: "809",
    id: "243"
  },
  {
    name: "809",
    id: "643"
  },
  {
    name: "Golden Horseshoe Credit Union",
    id: "854"
  },
  {
    name: "Community First CU",
    id: "834"
  },
  {
    name: "Alterna Bank",
    id: "608"
  },
  {
    name: "Peace Hills Trust Company",
    id: "568"
  },
  {
    name: "ADS CANADIAN BANK",
    id: "343"
  },
  {
    name: "AMEX BANK OF CANADA",
    id: "303"
  },
  {
    name: "THE BANK OF NEW YORK MELLON",
    id: "242"
  },
  {
    name: "BARCLAYS BANK OF CANADA",
    id: "248"
  },
  {
    name: "BNP PARIBAS",
    id: "250"
  },
  {
    name: "BOFA CANADA BANK",
    id: "311"
  },
  {
    name: "Bank of China Toronto Branch",
    id: "372"
  },
  {
    name: "The Bank of Tokyo-Mitsubishi UFJ Ltd-Can",
    id: "245"
  },
  {
    name: "CAPITAL ONE BANK (CANADA BRANCH)",
    id: "323"
  },
  {
    name: "CIDEL BANK CANADA",
    id: "378"
  },
  {
    name: "CITIBANK, NA",
    id: "328"
  },
  {
    name: "COMERICA BANK",
    id: "330"
  },
  {
    name: "COMTECH FIRECREDIT UNION LIMITED",
    id: "807"
  },
  {
    name: "CONCENTRA BANK",
    id: "853"
  },
  {
    name: "CTBC BANK CORP. (CANADA)",
    id: "315"
  },
  {
    name: "China Construction Bank Toronto Br",
    id: "366"
  },
  {
    name: "DEUTSCHE BANK AG",
    id: "265"
  },
  {
    name: "DIRECTCASH BANK",
    id: "352"
  },
  {
    name: "EQUITABLE BANK",
    id: "623"
  },
  {
    name: "EXCHANGE BANK OF CANADA",
    id: "376"
  },
  {
    name: "FIRST COMMERCIAL BANK",
    id: "332"
  },
  {
    name: "HABIB CANADIAN BANK",
    id: "321"
  },
  {
    name: "Home Bank",
    id: "361"
  },
  {
    name: "INDUSTRIAL & COMMERCIAL BANK OF CHINA",
    id: "307"
  },
  {
    name: "JP MORGAN BANK CANADA",
    id: "314"
  },
  {
    name: "KEB HANA BANK CANADA",
    id: "275"
  },
  {
    name: "MEGA INT'L COMMERCIAL BANK (CANADA)",
    id: "269"
  },
  {
    name: "MIZUHO CORPORATE BANK LTD. CANADA BR",
    id: "277"
  },
  {
    name: "SBI CANADA BANK",
    id: "294"
  },
  {
    name: "SHINHAN BANK CANADA",
    id: "355"
  },
  {
    name: "SOCIETE GENERALE (CANADA BRANCH)",
    id: "346"
  },
  {
    name: "STATE STREET",
    id: "327"
  },
  {
    name: "SUMITOMO MITSUI BANKING CORPORATION CAN.",
    id: "301"
  },
  {
    name: "UNITED OVERSEAS BANK LIMITED",
    id: "335"
  },
  {
    name: "VersaBank",
    id: "334"
  },
  {
    name: "WEALTH ONE BANK OF CANADA",
    id: "370"
  },
  {
    name: "Wells Fargo Bank NA Canadian Branch",
    id: "362"
  },
  {
    name: "ZAG BANK",
    id: "342"
  },
  {
    name: "CREDIT UNION CENTRAL OF MANITOBA",
    id: "879"
  },
  {
    name: "DUCA FINANCIAL SERVICES CR UNION LTD",
    id: "806"
  },
  {
    name: "DUNDALK DISTRICT CREDIT UNION LTD",
    id: "840"
  },
  {
    name: "LATVIAN CREDIT UNION LIMITED",
    id: "803"
  },
  {
    name: "PACE SAVINGS & CREDIT UNION LIMITED",
    id: "810"
  },
  {
    name: "RAPPORT CREDIT UNION",
    id: "846"
  },
  {
    name: "CREDIT UNION CENTRAL OF MANITOBA",
    id: "879"
  },
  {
    name: "DUCA FINANCIAL SERVICES CR UNION LTD",
    id: "806"
  },
  {
    name: "DUNDALK DISTRICT CREDIT UNION LTD",
    id: "840"
  },
  {
    name: "LATVIAN CREDIT UNION LIMITED",
    id: "803"
  },
  {
    name: "PACE SAVINGS & CREDIT UNION LIMITED",
    id: "810"
  },
  {
    name: "RAPPORT CREDIT UNION",
    id: "846"
  }
];
