import { utils } from "@cauldron/core";
import { defaultProps, schema, MODEL_NAME } from "./model";
import { transformReceivedCollection } from "../../model.utils";

const { formatDate, formatCurrency } = utils;

/**
 * Tanche Transform [RECEIVED]
 * IMPORT
 * data -> before it reaches the store
 * @param {*} rawData
 * @author Ehsan
 */

export const transformReceived = rawData => {
  const transformedData = transformReceivedCollection(
    rawData,
    defaultProps,
    schema,
    MODEL_NAME
  );
  const errorData = prepareErrorTableData(transformedData);
  return { ...transformedData, errorData: [...errorData] };
};

export const prepareErrorTableData = data => {
  const { results } = data;
  const errorData = [];
  if (results && typeof results !== "undefined") {
    for (const t of results) {
      if (t.hasOwnProperty("errors")) {
        const { errors } = t;
        for (const err of errors) {
          errorData.push({
            "tranche-name": t.name,
            "error-message": err.message,
            "loan-number": err.loanNumber,
            "loan-value": formatCurrency(err.loanValue),
            "origination-date": formatDate(err.originationDate, {
              format: "MMM DD, YYYY"
            })
          });
        }
      }
    }
  }

  return errorData;
};
