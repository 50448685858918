import { transformReceivedCollection } from "../../model.utils";
import { defaultProps, schema, MODEL_NAME } from "./model";

/**
 * Tanche Transform [RECEIVED]
 * UPLOAD
 * transform data -> before it reaches the store
 * @param {*} rawData
 * @author Ehsan
 */

export const transformReceived = rawData => {
  return transformReceivedCollection(rawData, defaultProps, schema, MODEL_NAME);
};
