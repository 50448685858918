import { utils } from "@cauldron/core";
import { FUNDED_LOAN_PHONE_FLAG_TYPES } from "../../../../../pages/constants/data.types";

const { removePhoneFormat } = utils;

/**
 * Auth User Transform [SENT]
 * Update info
 * data -> goes to the service
 * @param {*} sendData
 * @author Ehsan
 */
export const transformSent = sendData => {
  const authUser = { ...sendData };
  const phone = {
    number: removePhoneFormat(authUser.phone),
    ext: authUser.extension,
    primary: true,
    type: authUser.phoneType
  };
  phone.flags = [];
  if (authUser.isMobile) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.MOBILE_PHONE);
  }

  if (authUser.doNotText) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.DO_NOT_TEXT);
  }

  if (authUser.doNotCall) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.DO_NOT_CALL);
  }

  if (authUser.wrongNumber) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.WRONG_NUMBER);
  }

  if (authUser.notInService) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.NOT_IN_SERVICE);
  }

  return {
    dateOfBirth: authUser.dob,
    emailAddress: authUser.email,
    firstName: authUser.firstName,
    lastName: authUser.lastName,
    middleName: authUser.middleName,
    phoneNumber: phone,
    relationship: authUser.relationship
  };
};
