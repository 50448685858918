import {
  UPLOAD_BANK_STATEMENT_REQUEST,
  UPLOAD_DOCUMENT_REQUEST,
  EDIT_DOCUMENT_REQUEST,
  EDIT_DOCUMENT_SUCCESS,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_OUTSTANDING_DOCUMENT_REQUEST,
  GET_OUTSTANDING_DOCUMENT_SUCCESS,
  SELECT_DOC_ENTRY,
  UPDATE_DOC_MODE
} from "./document.action.types";

export function uploadBankStatementRequest(data) {
  return {
    type: UPLOAD_BANK_STATEMENT_REQUEST,
    data
  };
}

export function uploadDocumentRequest(data) {
  return {
    type: UPLOAD_DOCUMENT_REQUEST,
    data
  };
}

export function editDocumentRequest(data) {
  return {
    type: EDIT_DOCUMENT_REQUEST,
    data
  };
}

export function editDocumentSuccess(data) {
  return {
    type: EDIT_DOCUMENT_SUCCESS,
    data
  };
}

export function getDocumentRequest(applicationId) {
  return {
    type: GET_DOCUMENT_REQUEST,
    applicationId
  };
}

export function getDocumentSuccess(data) {
  return {
    type: GET_DOCUMENT_SUCCESS,
    data
  };
}

export function getOutstandingDocumentRequest(applicationId) {
  return {
    type: GET_OUTSTANDING_DOCUMENT_REQUEST,
    applicationId
  };
}

export function getOutstandingDocumentSuccess(data) {
  return {
    type: GET_OUTSTANDING_DOCUMENT_SUCCESS,
    data
  };
}

export function selectDocEntry(
  mode,
  selectedClientId,
  selectedLoanId,
  selectedDocId
) {
  return {
    type: SELECT_DOC_ENTRY,
    mode,
    selectedClientId,
    selectedLoanId,
    selectedDocId
  };
}

export function updateDocMode(mode) {
  return {
    type: UPDATE_DOC_MODE,
    mode
  };
}
