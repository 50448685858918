import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography/Typography";
import { withStyles } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import styles from "./styles/error.message.styles";

function ErrorMessage(props) {
  const { classes, error, title, message, links, icon, tryAgain } = props;
  return (
    <div className={classes.root}>
      <div className={classes.messageWrapper}>
        <Icon classes={{ root: classes.icon }}>{icon}</Icon>
        <div className={classes.text}>
          {title && <Typography className={classes.title}>{title}</Typography>}
          {message && (
            <Typography className={classes.message}>{message}</Typography>
          )}
          {tryAgain && (
            <Typography className={classes.tryAgain}>{tryAgain}</Typography>
          )}
          {error && (
            <Typography className={classes.error}>{error.trim()}</Typography>
          )}
          {links &&
            links.map((link, i) => (
              <Link key={i} to={link.path} className={classes.parentLink}>
                <Typography className={classes.link}>{link.label}</Typography>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}

ErrorMessage.defaultProps = {
  icon: "error_outline",
  error: "",
  links: []
};

ErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  ),
  tryAgain: PropTypes.string
};

export default withStyles(styles)(ErrorMessage);
