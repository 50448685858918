import {
  FUNDED_LOAN_PERSIST_NOTE,
  NOTE_PARENT_LIST,
  NOTE_DRAFT_DELETE,
  NOTE_DRAFT_CREATE,
  NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS,
  NOTE_TAB_PRIORITY_UPDATE_SUCCESS
} from "../action.types/notes.action.types";
import { QUICK_NOTE_SUBMIT_SUCCESS } from "../action.types/quick.notes.action.types";

const handlers = {
  [NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS]: (state, action) => {
    const noteId = action.data.id;
    return {
      ...state,
      currentNote: {
        id: noteId,
        index: null
      }
    };
  },
  [QUICK_NOTE_SUBMIT_SUCCESS]: (state, action) => {
    const noteId = action.data.id;
    return {
      ...state,
      currentNote: {
        id: noteId,
        index: null
      }
    };
  },
  [NOTE_DRAFT_CREATE]: state => {
    return {
      ...state,
      currentNote: initialState.currentNote
    };
  },
  [FUNDED_LOAN_PERSIST_NOTE]: (state, action) => {
    return {
      ...state,
      currentNote: action.data
    };
  },
  [NOTE_DRAFT_DELETE]: state => {
    return {
      ...state,
      currentNote: initialState.currentNote
    };
  },
  [NOTE_PARENT_LIST]: (state, action) => {
    const clientsList = action.data.clients;
    const parents = clientsList.map(client => {
      return {
        value: client.id,
        name: client.fullName,
        type: "Client"
      };
    });

    parents.push({
      value: action.data.id,
      name: "Loan",
      type: "Loan"
    });

    return {
      ...state,
      addNoteSelectList: {
        ...state.addNoteSelectList,
        parents
      }
    };
  },
  [NOTE_TAB_PRIORITY_UPDATE_SUCCESS]: (state, action) => {
    const updatedNote = action.data;
    return {
      ...state,
      currentNote: {
        id: updatedNote.id,
        index: null
      }
    };
  }
};

const initialState = {
  addNoteSelectList: {
    parents: []
  },
  currentNote: null
};

export default (state = initialState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action)
    : state;
};
