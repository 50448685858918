"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CREDIT_PULL = void 0;
var CREDIT_PULL = "I consent to Progressa obtaining my credit score and credit report from credit reporting agencies, after I create my account and verify my identity, and to obtaining updated credit scores and credit reports on a regular basis for as long as I have an account with Progressa. I acknowledge this consent is obtained to allow Progressa to obtain and periodically refresh my credit score and credit report to present me tailored personal finance information, educational insights, recommendations and third party offers. I authorize the applicable credit reporting agencies to provide this data to Progressa."; // We used array here because we need to have a historical mapping of the consent changes.
// The latest version should be the first element in the array.

exports.CREDIT_PULL = CREDIT_PULL;
var _default = [{
  name: "creditPull",
  type: "CreditPull",
  text: CREDIT_PULL
}];
exports.default = _default;