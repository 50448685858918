"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PORTAL_APPLICATION_DOCUMENTS = exports.PORTAL_APPLICATION_LOAN_DETAILS = exports.PORTAL_APPLICATION_CURRENT_SCREEN = exports.PORTAL_APPLICATION_CURRENT = exports.PORTAL_APPLICATION_PRESCREEN = exports.PORTAL_APPLICATION_CANCEL = exports.PORTAL_APPLICATION_EVALUATE = exports.PORTAL_APPLICATION_EXTENDED = exports.PORTAL_APPLICATION = exports.PORTAL_APPLICATIONS = exports.PORTAL_RESET_PASSWORD = exports.PORTAL_REFERRAL_SOURCES = exports.PORTAL_CLIENT_DETAILS = void 0;

var _api = require("./api.contants");
/**
 * API ANON ENDPOINTS
 */


var PORTAL = "".concat(_api.ROOT, "/portal");
var PORTAL_CLIENT_DETAILS = "".concat(PORTAL, "/client-details");
exports.PORTAL_CLIENT_DETAILS = PORTAL_CLIENT_DETAILS;
var PORTAL_REFERRAL_SOURCES = "".concat(PORTAL, "/referral-sources");
exports.PORTAL_REFERRAL_SOURCES = PORTAL_REFERRAL_SOURCES;
var PORTAL_RESET_PASSWORD = "".concat(PORTAL, "/reset-password"); // PORTAL APPLICATION

exports.PORTAL_RESET_PASSWORD = PORTAL_RESET_PASSWORD;
var PORTAL_APPLICATIONS = "".concat(PORTAL, "/applications");
exports.PORTAL_APPLICATIONS = PORTAL_APPLICATIONS;

var PORTAL_APPLICATION = function PORTAL_APPLICATION(appId) {
  return "".concat(PORTAL_APPLICATIONS, "/").concat(appId);
};

exports.PORTAL_APPLICATION = PORTAL_APPLICATION;

var PORTAL_APPLICATION_EXTENDED = function PORTAL_APPLICATION_EXTENDED(appId) {
  return "".concat(PORTAL_APPLICATION(appId), "/extended");
}; // prettier-ignore


exports.PORTAL_APPLICATION_EXTENDED = PORTAL_APPLICATION_EXTENDED;

var PORTAL_APPLICATION_EVALUATE = function PORTAL_APPLICATION_EVALUATE(appId) {
  return "".concat(PORTAL_APPLICATION(appId), "/evaluate");
}; // prettier-ignore


exports.PORTAL_APPLICATION_EVALUATE = PORTAL_APPLICATION_EVALUATE;

var PORTAL_APPLICATION_CANCEL = function PORTAL_APPLICATION_CANCEL(appId) {
  return "".concat(PORTAL_APPLICATION(appId), "/cancel");
}; // prettier-ignore


exports.PORTAL_APPLICATION_CANCEL = PORTAL_APPLICATION_CANCEL;

var PORTAL_APPLICATION_PRESCREEN = function PORTAL_APPLICATION_PRESCREEN(appId) {
  return "".concat(PORTAL_APPLICATION(appId), "/pre-screen");
}; // prettier-ignore
// CURRENT PORTAL APPLICATION


exports.PORTAL_APPLICATION_PRESCREEN = PORTAL_APPLICATION_PRESCREEN;
var PORTAL_APPLICATION_CURRENT = "".concat(PORTAL_APPLICATIONS, "/current");
exports.PORTAL_APPLICATION_CURRENT = PORTAL_APPLICATION_CURRENT;
var PORTAL_APPLICATION_CURRENT_SCREEN = "".concat(PORTAL_APPLICATION_CURRENT, "/screen"); // prettier-ignore

exports.PORTAL_APPLICATION_CURRENT_SCREEN = PORTAL_APPLICATION_CURRENT_SCREEN;
var PORTAL_APPLICATION_LOAN_DETAILS = "".concat(PORTAL_APPLICATION_CURRENT, "/loan-details"); // prettier-ignore

exports.PORTAL_APPLICATION_LOAN_DETAILS = PORTAL_APPLICATION_LOAN_DETAILS;
var PORTAL_APPLICATION_DOCUMENTS = "".concat(PORTAL_APPLICATION_CURRENT, "/documents"); // prettier-ignore

exports.PORTAL_APPLICATION_DOCUMENTS = PORTAL_APPLICATION_DOCUMENTS;