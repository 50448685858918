/**
 * Loan App Bank Service Request Code Transform [SENT]
 * @param applicationId
 * @param clientId
 * @param sendData
 * @returns {{applicationId: *, clientId: *, emailAddress: null, phoneNumber: null}}
 */
export const transformSent = (applicationId, clientId, sendData) => {
  let data = {
    applicationId: applicationId,
    clientId: clientId,
    emailAddress: sendData.isEmailCheck ? sendData.email : null,
    phoneNumber: null
  };
  if (sendData.isSMSCheck) {
    data.phoneNumber = { number: sendData.phone };
  }
  return data;
};
