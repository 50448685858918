/**
 * SNACKBAR STYLES
 * @param theme
 * @author Ryan Rivera
 */
const styles = theme => ({
  snackbarContent: {
    maxWidth: "688px"
  },
  info: {
    backgroundColor: theme.palette.primary["main"]
  },
  error: {
    backgroundColor: theme.palette.error["main"]
  },
  warning: {
    backgroundColor: theme.palette.warning["main"]
  },
  success: {
    backgroundColor: theme.palette.success["main"]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

export default styles;
