import { put, takeLatest, fork, call } from "redux-saga/effects";
import { http } from "../../../services/http/http.service";

// LOCAL DEPENDENCIES
import { FUNDED_LOANS_SEARCH } from "./funded.loans.action.types";
import {
  searchFundedLoansSuccess,
  searchFundedLoansFailure
} from "./funded.loans.actions";

// FUNDED LOANS SEARCH
export const searchFundedLoans = action =>
  http({
    request: (httpSvc, API) =>
      call(httpSvc.post, API.FUNDED_LOANS_SEARCH, action.searchCriteria),
    success: data => put(searchFundedLoansSuccess(data)),
    error: error => put(searchFundedLoansFailure(error))
  });

// WATCHERS
export function* watchFundedLoansSearchAsync() {
  yield takeLatest(FUNDED_LOANS_SEARCH, searchFundedLoans);
}

/**
 * FUNDED LOANS SAGA
 * Watches async requests,
 * in case of request, uses appropriate Action
 * passed in the reducer to return a new state
 */
export default [fork(watchFundedLoansSearchAsync)];
