import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import UserAvatar from "../Avatar";
import { styles } from "./styles/header.toolbar.styles";
import HeaderMenu from "../HeaderMenu";

/**
 * HEADER TOOLBAR
 * this component refers to the top header section which contains the Logo, Avatar and Header menu components.
 * @type Component
 * @author Ehsan
 * @version 0.1.2
 */

function PrimaryHeader(props) {
  const {
    classes,
    fullName,
    menuList,
    photoLink,
    hasPhoto,
    onLogoClick,
    emptyMenuListText,
    dataTestId,
    onLogout,
    onShowAppInfo
  } = props;

  return (
    <Toolbar
      data-test={dataTestId.root}
      classes={{
        root: classes.headerToolBar,
        gutters: classes.headerToolbarGutters
      }}
    >
      <Button data-test={dataTestId.logo} className={classes.logoWrapper}>
        <div
          className={classes.logo}
          onClick={onLogoClick}
          onFocus={e => e.preventDefault()}
          onKeyPress={e => {
            if (e.which === 13) onLogoClick();
          }}
          tabIndex="0"
          role="button"
        />
      </Button>
      <div>
        <HeaderMenu
          dataTestId={dataTestId}
          menuList={menuList}
          emptyMenuListText={emptyMenuListText}
          onShowAppInfo={onShowAppInfo}
        />
        <UserAvatar
          dataTestId={dataTestId}
          fullName={fullName}
          hasPhoto={hasPhoto}
          photoLink={photoLink}
          onLogout={onLogout}
        />
      </div>
    </Toolbar>
  );
}

PrimaryHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  menuList: PropTypes.array.isRequired,
  emptyMenuListText: PropTypes.string,
  hasPhoto: PropTypes.bool.isRequired,
  photoLink: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.object.isRequired
};

export default withStyles(styles)(PrimaryHeader);
