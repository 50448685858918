import { i18nMark } from "@lingui/react/index";
import * as actionTypes from "../action.types/tabs.action.types";

const initialState = {
  docsTab: {
    selectedIndex: 0
  },
  paymentsTab: {
    sortBy: [
      {
        id: "effectiveDate",
        desc: true
      },
      {
        id: "transactionDate",
        desc: true
      }
    ],
    columns: [
      {
        id: "description",
        label: i18nMark("Type"),
        sortable: false,
        minWidth: 130
      },
      {
        id: "amount",
        label: i18nMark("Amount"),
        sortable: false,
        format: "CURRENCY",
        minWidth: 66,
        options: {
          align: "RIGHT"
        }
      },
      {
        id: "principal",
        label: i18nMark("Principal"),
        sortable: false,
        format: "CURRENCY",
        minWidth: 66,
        options: {
          align: "RIGHT"
        }
      },
      {
        id: "insurance",
        label: i18nMark("Insurance"),
        sortable: false,
        format: "CURRENCY",
        minWidth: 66,
        options: {
          align: "RIGHT"
        }
      },
      {
        id: "interest",
        label: i18nMark("Interest"),
        sortable: false,
        format: "CURRENCY",
        minWidth: 66,
        options: {
          align: "RIGHT"
        }
      },
      {
        id: "adminFees",
        label: i18nMark("Admin fees"),
        sortable: false,
        format: "CURRENCY",
        minWidth: 66,
        options: {
          align: "RIGHT"
        }
      },
      {
        id: "fees",
        label: i18nMark("Fees"),
        sortable: false,
        format: "CURRENCY",
        minWidth: 66,
        options: {
          align: "RIGHT"
        }
      },
      {
        id: "balance",
        label: i18nMark("Balance"),
        sortable: false,
        format: "CURRENCY",
        minWidth: 66,
        options: {
          align: "RIGHT"
        }
      },
      {
        id: "effectiveDate",
        label: i18nMark("Date"),
        sortable: false,
        format: "DATE",
        minWidth: 66
      },
      {
        id: "transactionDate",
        sortable: false,
        format: "DATE",
        show: false
      },
      {
        id: "history",
        sortable: false,
        show: false
      }
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FUNDED_LOAN_DETAILS_DOCS_UPDATE_SELECTED_INDEX:
      return {
        ...state,
        docsTab: {
          selectedIndex: action.index
        }
      };

    default:
      return state;
  }
};
