import * as types from "../action.types/client.content.action.types";

export function deleteAuthUserData(authUserId, loanId, clientId) {
  return {
    type: types.FUNDED_LOAN_DETAILS_AUTH_USER_DELETE,
    authUserId,
    loanId,
    clientId
  };
}

export function deleteAuthUserDataSuccess(clientId, authUserId) {
  return {
    type: types.FUNDED_LOAN_DETAILS_AUTH_USER_DELETE_SUCCESS,
    clientId,
    authUserId
  };
}

export function deleteAuthUserDataFailure(error) {
  return {
    type: types.FUNDED_LOAN_DETAILS_AUTH_USER_DELETE_FAILURE,
    error
  };
}

export function updateClientEmail(formId, data, clientId) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE,
    formId,
    data,
    clientId
  };
}

export function updateClientEmailSuccess(data, clientId) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE_SUCCESS,
    data,
    clientId
  };
}

export function updateClientEmailFailure(error) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE_FAILURE,
    error
  };
}

export function cancelAddForm(formState) {
  return {
    type: types.FUNDED_LOAN_DETAILS_ADD_FORM_REMOVE,
    formState
  };
}

export function updateClientAddress(formId, data, clientId) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE,
    formId,
    data,
    clientId
  };
}

export function updateClientAddressSuccess(data, clientId) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE_SUCCESS,
    data,
    clientId
  };
}

export function updateClientAddressFailure(error) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE_FAILURE,
    error
  };
}

export function deleteClientPhone(clientId, phoneId) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_PHONE_DELETE,
    clientId,
    phoneId
  };
}

export function deleteClientPhoneSuccess(data, clientId, phoneId) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_PHONE_DELETE_SUCCESS,
    data,
    clientId,
    phoneId
  };
}

export function deleteClientPhoneFailure(error) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_PHONE_DELETE_FAILURE,
    error
  };
}

export function switchClientEditMode(formState) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_EDIT_MODE_SWITCH,
    formState
  };
}

export function saveClientEditModeDraftData(formState, data) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_EDIT_MODE_SAVE_DRAFT_DATA,
    formState,
    data
  };
}

export function updateClientName(clientId, data) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_UPDATE_NAME,
    clientId,
    data
  };
}

export function updateClientNameSuccess(clientId, data) {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_UPDATE_NAME_SUCCESS,
    clientId,
    data
  };
}

export function updateClientNameFailure() {
  return {
    type: types.FUNDED_LOAN_DETAILS_CLIENT_UPDATE_NAME_FAILURE
  };
}
