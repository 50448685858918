import errorHandler from "./messenger.middleware";
import * as messengerActions from "./messenger.actions";
import * as messengerActionTypes from "./messenger.action.types";

// TODO: Deprecate messengerActions, export as actions
const actions = messengerActions;

export { extractErrorMsg } from "./messenger.utils";
export { getMessenger } from "./messenger.selectors";
export { actions, messengerActions, messengerActionTypes, errorHandler };
