import * as types from "../action.types/tabs.action.types";

/**
 * GET DOCS
 */
export function fetchFundedLoanDetailsDocs(
  loanId,
  borrowerId,
  coBorrowerIdArray
) {
  return {
    type: types.FUNDED_LOAN_DETAILS_DOCS_FETCH,
    loanId,
    borrowerId,
    coBorrowerIdArray
  };
}

export function fetchFundedLoanDetailsDocsSuccess(data) {
  return { type: types.FUNDED_LOAN_DETAILS_DOCS_FETCH_SUCCESS, data };
}

export function fetchFundedLoanDetailsDocsCompleted() {
  return { type: types.FUNDED_LOAN_DETAILS_DOCS_FETCH_COMPLETED };
}

/**
 * UPDATE DOCS SELECTED INDEX
 * @returns {{type: string}}
 */
export function updateDocsSelectedIndex(index) {
  return {
    type: types.FUNDED_LOAN_DETAILS_DOCS_UPDATE_SELECTED_INDEX,
    index: index
  };
}

/**
 * GET PAY OFF
 */
export function fetchFundedLoanDetailsPayoff(loanId, date) {
  return { type: types.FUNDED_LOAN_DETAILS_PAYOFF_FETCH, loanId, date };
}

export function fetchFundedLoanDetailsPayoffSuccess(data) {
  return { type: types.FUNDED_LOAN_DETAILS_PAYOFF_FETCH_SUCCESS, data };
}

export function fetchFundedLoanDetailsPayoffFailure(error) {
  return { type: types.FUNDED_LOAN_DETAILS_PAYOFF_FETCH_FAILURE, error };
}

/**
 * GET PAYMENTS
 */
export function fetchFundedLoanDetailsPayments(loanId) {
  return { type: types.FUNDED_LOAN_DETAILS_PAYMENTS_FETCH, loanId };
}

export function fetchFundedLoanDetailsPaymentsSuccess(data) {
  return { type: types.FUNDED_LOAN_DETAILS_PAYMENTS_FETCH_SUCCESS, data };
}

export function fetchFundedLoanDetailsPaymentsFailure(error) {
  return { type: types.FUNDED_LOAN_DETAILS_PAYMENTS_FETCH_FAILURE, error };
}
