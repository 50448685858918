"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "Documentation";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  classification: null,
  createdAt: null,
  description: null,
  id: null,
  mimeType: null,
  name: null,
  parentId: null,
  parentType: null,
  status: null,
  updatedAt: null
};
/**
 * Protects data from change in data type
 */

var schema = {
  classification: _propTypes.default.string,
  createdAt: _propTypes.default.string,
  description: _propTypes.default.string,
  id: _propTypes.default.string,
  mimeType: _propTypes.default.string,
  name: _propTypes.default.string,
  parentId: _propTypes.default.string,
  parentType: _propTypes.default.string,
  status: _propTypes.default.string,
  updatedAt: _propTypes.default.string
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var transformSend = function transformSend(data) {
  return data;
};

var _default = {
  defaultProps: defaultProps,
  schema: schema,
  transformReceived: transformReceived,
  transformSend: transformSend
};
exports.default = _default;