export const styles = theme => ({
  item: {
    display: "flex",
    padding: "4px 0"
  },
  itemKey: {
    width: "38%"
  },
  itemContent: {
    width: "62%",
    display: "inline-block",
    verticalAlign: "middle",
    whiteSpace: "pre-line",

    "& *": {
      lineHeight: "16px"
    },

    "& a": {
      color: theme.palette.grey["900"]
    }
  },
  itemContentLetterWrap: {
    width: "62%",
    display: "inline-block",
    verticalAlign: "middle",
    whiteSpace: "pre-line",
    wordBreak: "break-all"
  },
  itemKeyTypography: theme.typography.p3Disabled,
  itemContentTypography: theme.typography.p3Dark
});
