import { i18nMark } from "@lingui/react";
import sortBy from "lodash/sortBy";

// PAGES
import FundedLoanDetails from "./funded.loan.details/async.load";
import FundedLoans from "./funded.loans/async.load";
import { LoanApplicationContainer } from "./loan.application.details";
import LoanApplications from "./loan.applications/async.load";
import Dashboard from "./dashboard/async.load";
import ErrorPage from "./error/error.index";
import Tranche from "./tranche.management/async.load";
import { ClientsCreateContainer } from "./clients";
import { LoanApplicationDuplicateContainer } from "./loan.application.duplicate";

// SERVICES
import {
  LOAN_APPS,
  FUNDED_LOANS,
  TRANCHE,
  CREATE_NEW_APPLICATIONS
} from "../services/auth";

// CONSTANTS
const MENU_SORT_ORDER = i18nMark("menuSortOrder");
const LABEL_DASHBOARD = i18nMark("Dashboard");
const LABEL_LOAN_APPLICATIONS = i18nMark("Loan applications");
const LABEL_FUNDED_LOANS = i18nMark("Funded loans");
const LABEL_TRANCHE = i18nMark("Tranche management");

/**
 * PAGES_MODULES
 * Used for Page Routes and Menu
 * @label Label to show up in the menu
 * @path navigates to this path when clicked
 * @component component that is rendered when app routes
 * @exact exact match to routes
 * @isPrivate Use isPrivate route if the route requires authentication
 * @hasAccess required in private route if the route requires authorization
 * @showInMenu shows the module in the header menu
 * @menuSortOrder order of the menu items
 */
const pageModules = [
  {
    label: LABEL_DASHBOARD,
    path: "/",
    component: Dashboard,
    exact: true,
    isPrivate: true
  },
  {
    label: LABEL_LOAN_APPLICATIONS,
    path: "/loan-applications",
    component: LoanApplications,
    exact: true,
    isPrivate: true,
    showInMenu: true,
    fallback: ErrorPage,
    hasAccess: user => user.canSearch(LOAN_APPS),
    [MENU_SORT_ORDER]: 0
  },
  {
    path: "/loan-applications/:id/create-from-existing",
    component: LoanApplicationDuplicateContainer,
    exact: true,
    isPrivate: true,
    fallback: ErrorPage,
    hasAccess: user => user.can(CREATE_NEW_APPLICATIONS, LOAN_APPS)
  },
  {
    path: "/loan-applications/:id",
    component: LoanApplicationContainer,
    exact: true,
    isPrivate: true
  },
  {
    label: LABEL_FUNDED_LOANS,
    path: "/funded-loans",
    component: FundedLoans,
    exact: true,
    isPrivate: true,
    showInMenu: true,
    fallback: ErrorPage,
    hasAccess: user => user.canSearch(FUNDED_LOANS),
    [MENU_SORT_ORDER]: 1
  },
  {
    path: "/funded-loans/:id",
    component: FundedLoanDetails,
    exact: true,
    isPrivate: true
  },
  {
    label: LABEL_TRANCHE,
    path: "/tranche-management",
    component: Tranche,
    exact: true,
    isPrivate: true,
    showInMenu: true,
    fallback: ErrorPage,
    hasAccess: user => user.canView(TRANCHE),
    [MENU_SORT_ORDER]: 2
  },
  {
    path: "/clients/create",
    component: ClientsCreateContainer,
    exact: true,
    isPrivate: true,
    fallback: ErrorPage,
    hasAccess: user => user.can(CREATE_NEW_APPLICATIONS, LOAN_APPS)
  },
  {
    component: ErrorPage,
    isPrivate: true
  }
];

export const NOT_FOUND_PATH = "/not-found";

/**
 * Filtered and Sorted menu list
 */
const filteredMenuList = pageModules.filter(menu => menu && menu.showInMenu);
const sortedMenuList = sortBy(filteredMenuList, [MENU_SORT_ORDER]);

export { pageModules, sortedMenuList as menuList };
