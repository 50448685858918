import { utils } from "@cauldron/core";

const { makeActionTypes } = utils;

const namespace = "app.service.consents";

/**
 * Generates actions
 * creates one for each REQUEST | SUCCESS | FAILURE
 */
export const actionTypes = {
  ...makeActionTypes(namespace, "POST_CONSENTS")
};
