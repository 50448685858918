import _ from "lodash";

const stateSlice = "loading";

export const createLoadingSelector = actions => state => {
  // returns true only when all actions is not loading
  return _(actions).some(action => {
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action);
    const [, requestName] = matches;
    return state[stateSlice][requestName];
  });
};

export const getIsLoading = (state, action) => {
  const [requestName] = action.split("_REQUEST");
  return !!state[stateSlice][requestName];
};
