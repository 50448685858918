import { utils } from "@cauldron/core";
import isEmpty from "lodash/isEmpty";
import { FUNDED_LOAN_PHONE_FLAG_TYPES } from "../../pages/constants/data.types";

const { removePhoneFormat } = utils;

export const prepareClientPhoneModelData = data => {
  // Safeguard
  if (isEmpty(data)) return {};

  const phone = {
    id: data.phoneId,
    number: removePhoneFormat(data.number),
    ext: data.ext,
    primary: data.primary,
    type: data.type
  };
  phone.flags = [];
  if (data.isMobile) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.MOBILE_PHONE);
  }

  if (data.doNotText) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.DO_NOT_TEXT);
  }

  if (data.doNotCall) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.DO_NOT_CALL);
  }

  if (data.wrongNumber) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.WRONG_NUMBER);
  }

  if (data.notInService) {
    phone.flags.push(FUNDED_LOAN_PHONE_FLAG_TYPES.NOT_IN_SERVICE);
  }

  return phone;
};
