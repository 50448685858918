export const styles = ({ palette, typography }) => {
  return {
    "no-tracking": {
      border: `1px dashed ${palette.warning.main}`,
      position: "relative",
      "&:after": {
        background: palette.warning.main,
        color: palette.common.white,
        content: "'no-tracking'",
        fontSize: "10px",
        lineHeight: "1em",
        padding: "0 3px 3px",
        position: "absolute",
        right: "-4px",
        textAlign: "center",
        top: "calc(100% - 8px)",
        transition: "opacity 200ms ease-in-out"
      },
      "&:hover:after": {
        opacity: 0,
        transition: "opacity 200ms ease-in-out"
      }
    },
    h1: { ...typography.h1Dark },
    h2: { ...typography.h2Dark },
    h3: { ...typography.h3Dark },
    h4: { ...typography.h4Dark },
    h5: { ...typography.h5Dark },
    p1: { ...typography.p1Dark },
    p2: { ...typography.p2Dark },
    p3: { ...typography.p3Dark, lineHeight: "1.3em" },
    bold: { fontWeight: 600 },
    grey: { color: palette.grey[900] },
    error: { color: palette.error.main },
    primary: { color: palette.primary.main },
    secondary: { color: palette.secondary.main },
    disabled: { color: palette.lightGrey[900] },
    disabledLight: { color: palette.lightGrey[200] },
    warning: { color: palette.warning.main },
    success: { color: palette.success.main },
    boldText: { fontWeight: "bold" },
    spacer: { marginRight: "5px" },
    anchorText: { textDecoration: "none" },
    center: { textAlign: "center" },
    right: { textAlign: "right" }
  };
};
