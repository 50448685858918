import { utils } from "@cauldron/core";
import { actionTypes } from "./agent.portal.clients.action.types";

const { makeActionCreator } = utils;

export function fetchClient(id) {
  return {
    type: actionTypes.CLIENT_FETCH_REQUEST,
    id
  };
}

export function fetchClientSuccess(data) {
  return {
    type: actionTypes.CLIENT_FETCH_SUCCESS,
    data
  };
}

export function fetchClientFailure(error) {
  return {
    type: actionTypes.CLIENT_FETCH_FAILURE,
    error
  };
}

export function createClient(payload, eventHandlers) {
  return {
    type: actionTypes.CLIENT_CREATE_REQUEST,
    payload,
    eventHandlers
  };
}
export function createClientSuccess(data) {
  return {
    type: actionTypes.CLIENT_CREATE_SUCCESS,
    data
  };
}

export function createClientFailure(error) {
  return {
    type: actionTypes.CLIENT_CREATE_FAILURE,
    error
  };
}

export function updateClient(id, payload, eventHandlers) {
  return {
    type: actionTypes.CLIENT_UPDATE_REQUEST,
    id,
    payload,
    eventHandlers
  };
}

export function updateClientSuccess(data) {
  return {
    type: actionTypes.CLIENT_UPDATE_SUCCESS,
    data
  };
}

export function updateClientFailure(error) {
  return {
    type: actionTypes.CLIENT_UPDATE_FAILURE,
    error
  };
}

export const createClientfromApplicant = makeActionCreator(
  actionTypes.CLIENT_CREATE_FROM_APPLICANT_REQUEST,
  "payload",
  "eventHandlers"
);

export const createClientfromApplicantSuccess = makeActionCreator(
  actionTypes.CLIENT_CREATE_FROM_APPLICANT_SUCCESS,
  "data"
);

export const createClientfromApplicantFailure = makeActionCreator(
  actionTypes.CLIENT_CREATE_FROM_APPLICANT_FAILURE,
  "error"
);
