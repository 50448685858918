import {
  fork,
  call,
  put,
  takeLatest,
  takeEvery,
  all
} from "redux-saga/effects";
import { domain } from "@cauldron/core";
import { http } from "../../http/http.service";
import { actionTypes } from "./agent.portal.clients.action.types";
import {
  fetchClientSuccess,
  fetchClientFailure,
  createClientSuccess,
  createClientFailure,
  updateClientSuccess,
  updateClientFailure,
  createClientfromApplicantSuccess,
  createClientfromApplicantFailure
} from "./agent.portal.clients.actions";

const { AgentPortalClient } = domain;

export const createClient = action => {
  const { payload, eventHandlers } = action;
  const { onSuccess } = eventHandlers;

  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.AGENT_PORTAL_CLIENTS, payload);
    },
    success: data => {
      return all([put(createClientSuccess(data)), call(onSuccess, data)]);
    },
    error: e => {
      return put(createClientFailure(e));
    },
    transformReceiveData: AgentPortalClient.model.transformReceived
  });
};

export const fetchClient = action => {
  const { id } = action;
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.get, API.AGENT_PORTAL_CLIENT(id));
    },
    success: data => {
      return put(fetchClientSuccess(data));
    },
    error: e => {
      return put(fetchClientFailure(e));
    },
    transformReceiveData: AgentPortalClient.model.transformReceived
  });
};

export const updateClient = action => {
  const { id, payload, eventHandlers } = action;
  const { onSuccess } = eventHandlers;

  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.patch, API.AGENT_PORTAL_CLIENT(id), payload);
    },
    success: data => {
      return all([put(updateClientSuccess(data)), call(onSuccess, data)]);
    },
    error: e => {
      return put(updateClientFailure(e));
    },
    transformReceiveData: AgentPortalClient.model.transformReceived
  });
};

export const createClientFromApplicant = action => {
  const { payload, eventHandlers } = action;
  const { onSuccess, onFailure } = eventHandlers;

  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.AGENT_PORTAL_CLIENT_FROM_APPLICANT,
        payload
      );
    },
    success: data => {
      return all([
        put(createClientfromApplicantSuccess(data)),
        call(onSuccess, data)
      ]);
    },
    error: e => {
      return all([
        call(onFailure, e),
        put(createClientfromApplicantFailure(e))
      ]);
    },
    transformReceiveData: AgentPortalClient.model.transformReceived
  });
};

export function* watchClientsAsync() {
  yield takeLatest(actionTypes.CLIENT_CREATE_REQUEST, createClient);
  yield takeEvery(
    actionTypes.CLIENT_CREATE_FROM_APPLICANT_REQUEST,
    createClientFromApplicant
  );
  yield takeLatest(actionTypes.CLIENT_FETCH_REQUEST, fetchClient);
  yield takeEvery(actionTypes.CLIENT_UPDATE_REQUEST, updateClient);
}

export default [fork(watchClientsAsync)];
