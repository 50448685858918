/**
 * SEARCH TABLE STYLES
 * @author Ryan Rivera
 */
const styles = () => ({
  paper: {
    height: "100%",
    minHeight: "320px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  },
  toolbar: {
    paddingLeft: "16px",
    paddingRight: "16px",
    flex: "auto 0",
    display: "flex"
  },
  spacer: {
    flex: "auto"
  },
  tableSearch: {
    width: "220px"
  }
});

export default styles;
