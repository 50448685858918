/**
 * ENVIRONMENT CONSTANTS
 */
const env = process.env;

// Global system configurations
export const NODE_ENV = env.NODE_ENV;
export const REACT_APP_VERSION = window.REACT_APP_VERSION || "latest";

// Third party configurations
export const REACT_APP_GOOGLE_OAUTH_CLIENT_ID =
  window.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ||
  "793544040925-e5i394v6cmssbr0mod2tjksio96amitn.apps.googleusercontent.com";

export const REACT_APP_CANADA_POST_KEY =
  window.REACT_APP_CANADA_POST_KEY || "HE21-BJ58-AN66-ER29";

export const REACT_APP_BUGSNAG_KEY =
  window.REACT_APP_BUGSNAG_KEY || "27ebe9a4f0a41870c5d68d5bb1f6e1a2";

// Logging
export const LOG_LEVEL = env.REACT_APP_LOG_LEVEL || "warn";
export const LOG_TOKEN = env.REACT_APP_LOG_TOKEN;

// Personal Preference for logging
export const SHOW_STATE_DIFF = env.REACT_APP_SHOW_STATE_DIFF || "true";
export const COLLAPSE_LOGS = env.REACT_APP_COLLAPSE_LOGS;

// Persistence Config
export const PERSISTED_DATA_DEBUG = env.REACT_APP_PERSISTED_DATA_DEBUG || "false"; // prettier-ignore

export const getValuesFromHostName = hostname => {
  let values = {
    portalApiRoot: "",
    foundationApiRoot: "",
    lmsRoot: "",
    appEnv: "",
    hotjarId: null
  };

  switch (hostname) {
    case "localhost":
      values = {
        portalApiRoot: env.REACT_APP_PORTAL_API,
        foundationApiRoot: env.REACT_APP_API_ENDPOINT,
        lmsRoot: window.REACT_APP_LOAN_SOURCE || "https://uat.lms.rocks",
        appEnv: window.REACT_APP_ENV || "local",
        hotjarId: null
      };
      break;
    case "dev-powered.progressa.rocks":
      values = {
        portalApiRoot: "https://dev-portalv2.progressa.rocks",
        foundationApiRoot: "https://dev-api-gateway.progressa.rocks",
        lmsRoot: "https://devorg.lms.rocks",
        appEnv: "development",
        hotjarId: 1347612
      };
      break;
    case "qa-powered.progressa.rocks":
      values = {
        portalApiRoot: "https://qa-portalv2.progressa.rocks",
        foundationApiRoot: "https://qa-api-gateway.progressa.rocks",
        lmsRoot: "https://devorg.lms.rocks",
        appEnv: "qa",
        hotjarId: 1390575
      };
      break;
    case "uat-powered.progressa.rocks":
      values = {
        portalApiRoot: "https://uat-portalv2.progressa.rocks",
        foundationApiRoot: "https://uat-api-gateway.progressa.rocks",
        lmsRoot: "https://uat.lms.rocks",
        appEnv: "uat",
        hotjarId: 1387566
      };
      break;
    case "engage.progressa.io":
      values = {
        portalApiRoot: "https://portal.progressa.com",
        foundationApiRoot: "https://api-gateway.progressa.rocks",
        lmsRoot: "https://lms.rocks",
        appEnv: "production",
        hotjarId: 1387481
      };
      break;
    default:
      values = {
        portalApiRoot: "https://portal.progressa.com",
        foundationApiRoot: "https://api-gateway.progressa.rocks",
        lmsRoot: "https://lms.rocks",
        appEnv: "production",
        hotjarId: 1387481
      };
      break;
  }

  return values;
};

export const getPortalApiRoot = () => {
  if (window.REACT_APP_PORTAL_API) {
    return window.REACT_APP_PORTAL_API;
  }

  return getValuesFromHostName(window.location.hostname).portalApiRoot;
};

export const getApiRoot = () => {
  if (window.REACT_APP_API_ENDPOINT) {
    return window.REACT_APP_API_ENDPOINT;
  }

  return getValuesFromHostName(window.location.hostname).foundationApiRoot;
};

export const getLmsRoot = () => {
  if (window.REACT_APP_LOAN_SOURCE) {
    return window.REACT_APP_LOAN_SOURCE;
  }

  return getValuesFromHostName(window.location.hostname).lmsRoot;
};

export const getAppEnv = () => {
  if (window.REACT_APP_ENV) {
    return window.REACT_APP_ENV;
  }

  return getValuesFromHostName(window.location.hostname).appEnv;
};

const getHotjarId = () => {
  if (window.REACT_APP_HOTJAR_ID) {
    return window.REACT_APP_HOTJAR_ID;
  }

  return getValuesFromHostName(window.location.hostname).hotjarId;
};

export const REACT_APP_LOAN_SOURCE = getLmsRoot();
export const REACT_APP_PORTAL_API_ROOT = getPortalApiRoot();
export const REACT_APP_API_ROOT = getApiRoot();
export const REACT_APP_HOTJAR_ID = getHotjarId();
export const REACT_APP_ENV = getAppEnv();

export const ENABLE_QUICK_NOTE = window.REACT_APP_ENABLE_QUICK_NOTE_LOAN_APPS || "false"; // prettier-ignore
export const ENABLE_EMAIL_TAB = window.REACT_APP_ENABLE_EMAIL_TAB || "false"; // prettier-ignore
export const ENABLE_ACTIVITY_TAB = window.REACT_APP_ENABLE_ACTIVITY_TAB || "false"; // prettier-ignore
export const ENABLE_OUTSTANDING_DOCS = window.REACT_APP_ENABLE_OUTSTANDING_DOCS || "false"; // prettier-ignore
export const ENABLE_CREDIT_REPORT_BUTTONS = window.REACT_APP_ENABLE_CREDIT_REPORT_BUTTONS || "false"; // prettier-ignore
export const ENABLE_BANK_ACCOUNT_SECTION = window.REACT_APP_ENABLE_BANK_ACCOUNT_SECTION || "false"; // prettier-ignore
export const ENABLE_TRACKING_SUPPRESSION_HINT = window.REACT_APP_ENABLE_TRACKING_SUPPRESSION_HINT || "false"; // prettier-ignore
