import {
  LOAN_APPLICATIONS_SEARCH,
  LOAN_APPLICATIONS_SEARCH_SUCCESS,
  LOAN_APPLICATIONS_SEARCH_FAILURE,
  LOAN_APPLICATIONS_SEARCH_TABLE_SETTINGS_UPDATE
} from "../loan.applications.action.types";

/**
 * LOAN APPLICATIONS UI STATE REDUCER
 * @author Ryan Rivera
 * @type {{results: Array}}
 */
const initialState = {
  isSearching: false,
  hasError: false,
  errorMessage: "",
  errorMessageType: "info",
  searchCleared: false,
  hiddenColumns: ["cosignerEmail", "cosignerPhone"]
};

/**
 * Whitelist for loan.applications.ui
 * that will be persisted to the browser storage
 * @type {string[]}
 */
export const loanApplicationsUIWhitelist = ["hiddenColumns"];

/**
 * Reducer for loan.applications.ui
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAN_APPLICATIONS_SEARCH:
      return {
        ...state,
        isSearching: true
      };
    case LOAN_APPLICATIONS_SEARCH_SUCCESS:
      return {
        ...state,
        isSearching: false,
        hasError: false,
        errorMessage: ""
      };
    case LOAN_APPLICATIONS_SEARCH_FAILURE:
      return {
        ...state,
        isSearching: false,
        hasError: true,
        errorMessage: action.error.message,
        errorMessageType: action.error.type || "error"
      };
    case LOAN_APPLICATIONS_SEARCH_TABLE_SETTINGS_UPDATE:
      return {
        ...state,
        ...action.settings
      };
    default:
      return state;
  }
};
