import { put, takeLatest, fork, call } from "redux-saga/effects";
import { http } from "../../http/http.service";
import { API_VERSION_FETCH_REQUEST } from "./info.action.types";
import { fetchApiVersionSuccess } from "./info.actions";

/**
 * fetchApiVersion
 */
export const fetchApiVersion = () =>
  http({
    request: (httpSrv, API) => call(httpSrv.get, API.INFO),
    success: data => put(fetchApiVersionSuccess(data))
  });

export function* watchDashboardAsync() {
  yield takeLatest(API_VERSION_FETCH_REQUEST, fetchApiVersion);
}

export default [fork(watchDashboardAsync)];
