import React, { useEffect } from "react";
import { t } from "@lingui/macro";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { utils } from "@cauldron/core";
import PersonAdd from "@material-ui/icons/PersonAdd";
import { searchTableTestId } from "../loan.applications/loan.applications.data.test.ids";
import { getMenuList } from "../../utils/engage.app.utils";
import { menuList } from "../pages.modules";
import { ErrorBoundary } from "../../shared";
import Page from "../../shared/Page";
import { IconTextButton } from "../../ui.library";
import {
  ADD_NEW_APPLICANT,
  APP_SECONDARY_HEADER
} from "../constants/data.test.ids";
import { withAuth } from "../../services/auth";
import styles from "./styles/clients.create.styles";
import { APPLICATION_PURPOSE_TYPES } from "../../services/data/agent.portal.applications/agent.portal.applications.constants";
import ClientsCreateForm from "./clients.create.form";
import {
  agentPortalClient as clientService,
  agentPortalApplication as applicationService,
  referralSource as referralSourceService,
  loading as loadingService,
  errorHandler as errorHandlerService
} from "../../services";

const { generateTestIds, removePhoneFormat } = utils;

function ClientsCreateContainer({
  i18n,
  user,
  history,
  isFetching,
  errors,
  createClient,
  createApplication,
  fetchReferralSources,
  companyReferralSources
}) {
  useEffect(
    () => {
      fetchReferralSources();
    },
    [fetchReferralSources]
  );

  const onLogoClick = () => {
    history.push("/");
  };

  const handleOnApplicationCreation = loanAppData => {
    const { id } = loanAppData;
    history.push(`/loan-applications/${id}`);
  };

  const handleOnClientCreation = clientData => {
    const { email } = clientData;

    const hasOneReferralSource = companyReferralSources.length === 1;
    const payload = { application: {}, userId: email };

    payload.application.loanPurpose = APPLICATION_PURPOSE_TYPES[0];
    if (hasOneReferralSource) {
      payload.application.referrerId = companyReferralSources[0].id;
    }

    if (createApplication) {
      createApplication(payload, {
        onSuccess: handleOnApplicationCreation
      });
    }
  };

  const handleOnSubmit = ({ first, last, email, number, sendWelcomeEmail }) => {
    if (createClient) {
      createClient(
        {
          email,
          name: { first, last },
          phone: {
            countryCode: "1",
            number: removePhoneFormat(number),
            type: "mobile",
            primary: true
          },
          agreedToTerms: true,
          sendWelcomeEmail
        },
        {
          onSuccess: handleOnClientCreation
        }
      );
    }
  };

  const handleOnCancel = () => {
    history.push("/loan-applications");
  };

  const renderAddApplicantButton = () => {
    return (
      <IconTextButton
        dataTestId={generateTestIds(APP_SECONDARY_HEADER, ADD_NEW_APPLICANT)}
        buttonText={i18n.t`Add new applicant`}
        icon={<PersonAdd />}
        onClick={() => {}}
        disabled
      />
    );
  };

  const { logOut, profile, filterByAccess } = user;

  const modulePath = [
    { label: i18n.t`Modules`, path: "/" },
    { label: i18n.t`Loan Applications`, path: "/loan-applications" }
  ];

  return (
    <Page
      dataTestId={{
        root: searchTableTestId.root,
        main: searchTableTestId.main
      }}
      HeaderProps={{
        profile,
        paths: modulePath,
        actions: renderAddApplicantButton(),
        menuList: getMenuList(filterByAccess(menuList, user), i18n),
        onLogOut: logOut,
        onLogoClick: onLogoClick,
        emptyMenuListText: i18n.t`No modules available`
      }}
      isFetching={false}
    >
      <ErrorBoundary
        title={i18n._(t`Oops...`)}
        message={i18n.t`An unexpected UI exception has occurred in the loan applications module.`}
        links={[{ path: "/", label: i18n.t`Dashboard` }]}
      >
        <ClientsCreateForm
          errors={errors}
          isFetching={isFetching}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
        />
      </ErrorBoundary>
    </Page>
  );
}

// Selectors
const {
  getCompanyReferralSources,
  getReferralSourcesByCompany
} = referralSourceService.selectors;

const { createLoadingSelector } = loadingService.selectors;
const { createErrorMessageSelector } = errorHandlerService.selectors;

const createClientLoadingSelector = createLoadingSelector([
  clientService.actionTypes.CLIENT_CREATE_REQUEST,
  applicationService.actionTypes.APPLICATION_CREATE_REQUEST,
  referralSourceService.actionTypes.actionTypes.REFERRAL_SOURCES_FETCH_REQUEST
]);

const createErrorStack = createErrorMessageSelector([
  clientService.actionTypes.CLIENT_CREATE_REQUEST,
  applicationService.actionTypes.APPLICATION_CREATE_REQUEST,
  referralSourceService.actionTypes.actionTypes.REFERRAL_SOURCES_FETCH_REQUEST
]);

const mapStateToProps = state => ({
  companyReferralSources: getCompanyReferralSources(state),
  referralSourcesByCompany: getReferralSourcesByCompany(state),
  isFetching: createClientLoadingSelector(state),
  errors: createErrorStack(state)
});

const mapDispatchToProps = {
  fetchReferralSources: referralSourceService.actions.fetchReferralSources,
  createClient: clientService.actions.createClient,
  createApplication: applicationService.actions.createApplication
};

const enhance = compose(
  withI18n(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  withAuth
);

export default enhance(ClientsCreateContainer);
