import PropTypes from "prop-types";

/**
 * COMMENT MODEL
 */

export const defaultProps = {
  category: null,
  createdAt: null,
  createdBy: {
    email: null,
    name: null
  },
  documents: [],
  id: null,
  message: null,
  pinExpiration: null,
  pinned: false,
  subject: null,
  system: null
};

// SCHEMA
export const schema = {
  category: PropTypes.string,
  createdAt: PropTypes.string,
  createdBy: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string
  }),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      fileIndex: PropTypes.number,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      mimeType: PropTypes.string
    })
  ),
  id: PropTypes.string,
  message: PropTypes.string,
  pinExpiration: PropTypes.string,
  pinned: PropTypes.bool,
  subject: PropTypes.string,
  system: PropTypes.string
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-comments";
