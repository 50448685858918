/**
 * Client Transform [SENT]
 * Update marital
 * data -> goes to the service
 * @param {*} rawData
 * @author Ehsan
 */
export const transformSent = sendData => {
  const data = { ...sendData };
  return data;
};
