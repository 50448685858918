/**
 * EDIT_TABLE STYLES
 * @author rrive
 */
const styles = theme => ({
  footerCell: {
    display: "block",
    background: theme.palette.common["white"],
    height: 4,
    width: "110%",
    position: "relative",
    top: "-3px",
    left: "-5px"
  }
});

export default styles;
