import { i18nMark } from "@lingui/react";

export const DIALOG_ACTIONS = {
  OKAY: i18nMark("Okay"),
  DELETE: i18nMark("Delete"),
  CANCEL: i18nMark("Cancel"),
  CONFIRM: i18nMark("Confirm"),
  SUBMIT_REVIEW: i18nMark("Submit Review"),
  DOES_NOT_ACCEPT: i18nMark("Does not accept")
};

// TODO: move project-specific constants into the pages folder
export const DIALOG_MESSAGES = {
  CANCEL: {
    BODY: i18nMark(
      "Cancelling will result in changes being lost. Are you sure?"
    )
  },
  DELETE_DRAFT_NOTE: {
    TITLE: i18nMark("Delete draft note"),
    MESSAGE: i18nMark(
      "Deleting this draft note will permanently remove it from the system."
    )
  },
  COSIGNER_CANCEL_ADD: i18nMark("Cancel add cosigner"),
  REFERRAL_CANCEL_EDIT: i18nMark("Cancel edit referral"),

  BANK_ACCOUNT_CANCEL_EDIT: i18nMark("Cancel edit bank account"),
  BANK_ACCOUNT_CANCEL_ADD: i18nMark("Cancel add bank account"),
  BANK_ACCOUNT_DELETE_DIALOG_TITLE: i18nMark("Delete bank account"),
  BANK_ACCOUNT_DELETE_DIALOG_BODY: i18nMark(
    "Deleting this information will permanently remove it from the system."
  ),

  LIABILITY_CANCEL_ADD: i18nMark("Cancel add liability"),
  LIABILITY_CANCEL_EDIT: i18nMark("Cancel edit liability"),
  LIABILITY_DELETE_DIALOG_TITLE: i18nMark("Delete liability"),
  LIABILITY_DELETE_DIALOG_BODY: i18nMark(
    "Deleting this information will permanently remove it from the system."
  ),

  INCOME_CANCEL_ADD: i18nMark("Cancel add income"),
  INCOME_CANCEL_EDIT: i18nMark("Cancel edit income"),
  INCOME_DELETE_DIALOG_TITLE: i18nMark("Delete income"),
  INCOME_DELETE_DIALOG_BODY: i18nMark(
    "Deleting this information will permanently remove it from the system."
  ),
  INCOME_DELETE_AGREE: i18nMark("Delete"),
  INCOME_DELETE_DISAGREE: i18nMark("Cancel"),

  BANK_REQUEST_CANCEL: i18nMark("Cancel automated bank statement request"),
  MANUAL_BANK_REQUEST_CANCEL: i18nMark("Cancel manual bank statement request"),
  DOCS_TAB_CANCEL_DOCUMENT_UPLOAD_TITLE: i18nMark("Discard new documentation?"),
  DOCS_TAB_CANCEL_DOCUMENT_UPLOAD_BODY: i18nMark(
    "This draft and its contents will be discarded."
  ),
  DOCS_TAB_CANCEL_DOCUMENT_EDIT_TITLE: i18nMark("Discard details?"),
  DOCS_TAB_CANCEL_DOCUMENT_EDIT_BODY: i18nMark(
    "The changes will be discarded."
  ),
  DOCS_TAB_CANCEL_DOCUMENT_UPLOAD_AGREE: i18nMark("Discard"),
  SIGN_UP_CANCEL: i18nMark("Cancel sign up"),
  APPLICATION_CREATION_CANCEL: i18nMark("Cancel application creation"),
  COSIGNER: {
    DELETE: {
      TITLE: i18nMark("Delete cosigner"),
      BODY: i18nMark(
        "Deleting the cosigner will permanently remove all accompanying data."
      )
    }
  },
  CLIENT_TITLE_EDIT: i18nMark("Cancel edit title"),
  CLIENT_NAME_EDIT: i18nMark("Cancel edit name"),
  CLIENT_MARITAL_STATUS_EDIT: i18nMark("Cancel edit marital status"),
  CANCEL_PHONE_EDIT_DIALOG_TITLE: i18nMark("Cancel edit phone"),
  CANCEL_PHONE_ADD_DIALOG_TITLE: i18nMark("Cancel add phone"),
  DELETE_PHONE_DIALOG_TITLE: i18nMark("Delete phone"),
  CANCEL_AUTH_USER_EDIT_DIALOG_TITLE: i18nMark("Cancel edit auth user"),
  CANCEL_AUTH_USER_ADD_DIALOG_TITLE: i18nMark("Cancel add auth user"),
  DELETE_AUTH_USER_DIALOG_TITLE: i18nMark("Delete auth user"),
  AGENT_PORTAL_CANCEL_APPLICATION_TITLE: i18nMark("Confirm does not accept"),
  AGENT_PORTAL_CANCEL_APPLICATION_BODY: i18nMark(
    "The application status will be set to Stale - Cancelled by Customer."
  ),
  AGENT_PORTAL_CANCEL_APPLICATION_AGREE_BUTTON: i18nMark("Does not accept"),
  TRANCHE_DISCARD_IMPORT: {
    TITLE: i18nMark("Discard report"),
    BODY: i18nMark(
      "Reports can not be saved at this point. Navigating away will discard the details of this report."
    ),
    CANCEL: i18nMark("Cancel"),
    CONFIRM: i18nMark("Discard")
  },
  CANCEL_EDIT_PERSONAL_DETAILS: i18nMark("Cancel edit personal details"),
  CANCEL_CLIENT_EMAIL_EDIT: i18nMark("Cancel edit email"),
  DELETE_CLIENT_EMAIL_TITLE: i18nMark("Delete email"),
  DELETE_CLIENT_EMAIL_MESSAGE: i18nMark(
    "Deleting the email will permanently remove it from the system."
  ),
  CANCEL_PHONE_EDIT: i18nMark("Cancel edit phone number"),
  DELETE_PHONE_TITLE: i18nMark("Delete phone number"),
  DELETE_PHONE_MESSAGE: i18nMark(
    "Deleting the phone number will permanently remove it from the system."
  ),
  CANCEL_CLIENT_RESIDENCE_EDIT: i18nMark("Cancel edit residence"),
  DELETE_CLIENT_RESIDENCE_TITLE: i18nMark("Delete residence"),
  DELETE_CLIENT_RESIDENCE_MESSAGE: i18nMark(
    "Deleting the residence will permanently remove it from the system."
  )
};
