import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { services } from "@cauldron/core";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles/typography.styles";
import {
  REACT_APP_ENV,
  ENABLE_TRACKING_SUPPRESSION_HINT
} from "../../core/constants/env";

const enableTrackingSuppressionHint =
  REACT_APP_ENV === "local" && ENABLE_TRACKING_SUPPRESSION_HINT === "true";

/**
 * getSuppressors
 * @param suppressTracking
 */
export function getSuppressors(suppressTracking) {
  const { SUPPRESS_HOTJAR } = services.hotjar.constants;
  const trackingSuppressors = {};
  if (suppressTracking) {
    trackingSuppressors[SUPPRESS_HOTJAR] = "";
  }
  return trackingSuppressors;
}

/**
 * AlchemyTypography
 * @param bold
 * @param type
 * @param variant
 * @param textAlign
 * @param classes
 * @param className
 * @param children
 * @param dataTestId
 * @param dataTestBuilder
 * @param suppressTracking
 * @returns {*}
 * @constructor
 */
function AlchemyTypography({
  bold,
  type,
  variant,
  textAlign,
  classes,
  className,
  children,
  dataTestId,
  dataTestBuilder,
  suppressTracking,
  ...rest
}) {
  const generateDataTestId = (...args) => {
    if (!dataTestBuilder || !dataTestId) return null;
    return dataTestBuilder(...args);
  };

  const typeClass = classes[type];
  const variantClass = classes[variant];
  const classNames = classnames(
    typeClass,
    variantClass,
    className,
    bold && classes.bold,
    classes[textAlign],
    suppressTracking && enableTrackingSuppressionHint && classes["no-tracking"]
  );

  return (
    <Typography
      className={classNames}
      data-test={generateDataTestId(dataTestId)}
      {...(getSuppressors ? getSuppressors(suppressTracking) : {})}
      {...rest}
    >
      {children}
    </Typography>
  );
}

AlchemyTypography.defaultProps = {
  className: "",
  variant: "grey",
  textAlign: "left",
  type: "p3"
};

AlchemyTypography.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "h7",
    "h8",
    "p1",
    "p2",
    "p3",
    "p4",
    "p5"
  ]),
  dataTestId: PropTypes.string,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "lightGrey",
    "grey",
    "disabled",
    "disabledLight",
    "error",
    "warning",
    "success"
  ]),
  textAlign: PropTypes.oneOf(["left", "right", "center"])
};

export default withStyles(styles)(AlchemyTypography);
