import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles/index";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { styles } from "./styles/avatar.styles";
import AvatarMenu from "./components/avatar.menu";

/**
 * UserAvatar
 */
class UserAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      menuOpen: false,
      savedAnchor: null
    };

    this.avatarRef = React.createRef();
  }

  getInitials = fullName => {
    const nameArr =
      fullName && typeof fullName === "string"
        ? fullName.trim().split(" ")
        : [];
    return nameArr
      .filter((name, i, arr) => i === 0 || i === arr.length - 1)
      .map(partial => partial.charAt(0).toUpperCase());
  };

  handleAvatarMenu = (event, action) => {
    action
      ? this.setState({
          menuOpen: action,
          anchorEl: event.currentTarget,
          savedAnchor: event.currentTarget
        })
      : this.setState({ menuOpen: action, anchorEl: null });
  };

  handleLabelClick = (event, action) => {
    action
      ? this.setState(prevState => ({
          menuOpen: action,
          anchorEl: prevState.savedAnchor
            ? prevState.savedAnchor
            : this.avatarRef.current
        }))
      : this.setState({ menuOpen: action, anchorEl: null });
  };

  validateLink = link => {
    if (link || link !== undefined) {
      if (link.length > 0) return true;
    }
    return false;
  };

  render() {
    const { anchorEl, menuOpen } = this.state;
    const { classes, fullName, hasPhoto, dataTestId, onLogout } = this.props;
    const { photoLink } = this.props;
    const validLink = this.validateLink(photoLink);

    return (
      <Fragment>
        <Button
          data-test={dataTestId.avatarButton}
          classes={{ root: classes.headerBtn }}
          color="inherit"
          aria-label="User Menu"
          aria-owns={anchorEl ? "avatar-user-menu" : null}
          aria-haspopup="true"
          onClick={event => this.handleAvatarMenu(event, true)}
          ref={this.avatarRef}
        >
          <Avatar
            aria-label={fullName}
            className={classes.avatar}
            src={hasPhoto && validLink ? photoLink : ""}
            alt="User Avatar"
          >
            {!hasPhoto && this.getInitials(fullName)}
          </Avatar>
          <Typography
            color="inherit"
            classes={{ root: classes.headerBtnLabel }}
            onClick={event => this.handleLabelClick(event, true)}
          >
            {fullName}
          </Typography>
        </Button>
        <AvatarMenu
          dataTestId={dataTestId}
          anchorEl={anchorEl ? anchorEl : {}}
          menuOpen={menuOpen}
          menuClose={event => this.handleAvatarMenu(event, false)}
          onLogout={onLogout}
        />
      </Fragment>
    );
  }
}

UserAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  photoLink: PropTypes.string,
  dataTestId: PropTypes.shape({
    avatarButton: PropTypes.string
  })
};

UserAvatar.defaultProps = {
  dataTestId: {}
};

export default withStyles(styles)(UserAvatar);
