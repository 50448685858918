import * as actions from "./bank.details.action.types";

// ADD BANK ACCOUNT
export const cacheAddBankAccountForms = ({ id: clientId, values, index }) => {
  return {
    type: actions.LOAN_APPLICATION_BANK_ACCOUNT_CACHE_ADD_FORMS,
    data: {
      id: clientId,
      values,
      index
    }
  };
};

export const cancelAddBankAccountForm = ({ id: clientId, index }) => {
  return {
    type: actions.LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_ADD_FORMS,
    id: clientId,
    index
  };
};

export const addNewBankAccount = (clientId, values, formId) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_ADD,
  clientId,
  values,
  formId
});

export const addNewBankAccountSuccess = (clientId, data) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_ADD_SUCCESS,
  clientId,
  data
});

export const addNewBankAccountFailure = (clientId, error) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_ADD_FAILURE,
  clientId,
  error
});

// DELETE BANK ACCOUNT
export const deleteBankAccount = (clientId, accountId) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_DELETE,
  clientId,
  accountId
});

export const deleteBankAccountSuccess = (clientId, data, accountId) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_DELETE_SUCCESS,
  clientId,
  data,
  accountId
});

export const deleteBankAccountFailure = (clientId, error) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_DELETE_FAILURE,
  clientId,
  error
});

// EDIT BANK ACCOUNT
export const cacheEditBankAccountForms = (clientId, accountId, values) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_CACHE_EDIT_FORMS,
  clientId,
  accountId,
  values
});

export const cancelEditBankAccountForm = (clientId, accountId) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_EDIT_FORM,
  clientId,
  accountId
});

export const cacheEditBankAccountValues = (clientId, accountId, values) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_CACHE_FORM_VALUES,
  clientId,
  accountId,
  values
});

export const updateClientBankAccount = (clientId, accountId, values) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_UPDATE,
  clientId,
  accountId,
  values
});

export const updateBankAccountSuccess = (clientId, data, accountId) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_SUCCESS,
  clientId,
  data,
  accountId
});

export const updateBankAccountFailure = (clientId, accountId, error) => ({
  type: actions.LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_FAILURE,
  clientId,
  accountId,
  error
});
