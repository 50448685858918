import React, { Component } from "react";
import PropTypes from "prop-types";
import find from "lodash/find";
import { utils } from "@cauldron/core";

// MATERIAL-UI
import { withStyles } from "@material-ui/core";
import styles from "./styles/edit.field.styles";

// LOCAL
import TextField from "../../../FormControls/TextField";
import Select from "../Select";
import CurrencyField from "../../../FormControls/CurrencyField";

// UTILS
const { isEnterKey, isTabKey } = utils;

/**
 * Provides editable control fields for the table cell
 */
class EditField extends Component {
  inputRef = React.createRef();

  constructor(props) {
    super(props);
    const { value, error } = props;
    this.state = {
      value,
      error
    };
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      this.setState({ error });
    }
  }

  componentWillUnmount() {
    this.inputRef = null;
  }

  emitOnChange = () => {
    const { onChange, row } = this.props;
    const { value, error } = this.state;
    const { id, index } = row;
    onChange(id, value, index, error);
  };

  updateErrorState = error => {
    this.setState({ error }, this.emitOnChange);
  };

  updateErrorStateOnKeyPress = () => {
    const error = this.validate();
    if (error) {
      this.updateErrorState(error);
    } else {
      this.emitOnChange();
    }
  };

  validate = () => {
    const { options } = this.props;
    const { value } = this.state;
    return options && options.validate ? options.validate(value) : "";
  };

  handleBlur = () => {
    const error = this.validate();
    this.updateErrorState(error);
  };

  handleSelect = value => {
    this.setState({ value }, this.emitOnChange);
  };

  handleCurrencyInputChange = (field, value) => {
    this.setState({ value });
  };

  handleInputChange = event => {
    const value = event.target.value;
    this.setState({ value });
  };

  handleKeyDown = event => {
    if (isTabKey(event)) {
      this.updateErrorStateOnKeyPress();
    }
  };

  handleKeyPress = event => {
    if (isEnterKey(event)) {
      this.updateErrorStateOnKeyPress();
    }
  };

  renderInput = () => {
    const { classes, format = "STRING", options } = this.props;
    const inputControls = {
      STRING: {
        component: TextField,
        onChange: this.handleInputChange,
        textAlign: "left"
      },
      CURRENCY: {
        component: CurrencyField,
        onChange: this.handleCurrencyInputChange,
        textAlign: "right"
      }
    };
    const InputField = inputControls[format].component;
    const { value, error } = this.state;
    return (
      <div className={classes.textField}>
        <InputField
          autoFocus={options && options.defaultOpen}
          value={value}
          hasError={!!error}
          helperText={error}
          onChange={inputControls[format].onChange}
          onKeyPress={this.handleKeyPress}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          fullWidth
          inputRef={this.inputRef}
          textAlign={inputControls[format].textAlign}
        />
      </div>
    );
  };

  renderSelect = () => {
    const { classes, options } = this.props;
    const { dataOptions: selectOptions } = options;
    const { value } = this.state;
    const selectValue = find(selectOptions, { value: value }).value;
    return (
      <div className={classes.select}>
        <Select
          autoFocus={options && options.defaultOpen}
          value={selectValue}
          options={selectOptions}
          onChange={this.handleSelect}
        />
      </div>
    );
  };

  render() {
    const { options } = this.props;
    return options.dataType === "ENUM"
      ? this.renderSelect()
      : this.renderInput();
  }
}

EditField.propTypes = {
  /**
   * Determines the input control type used
   */
  format: PropTypes.string,

  /**
   * Table column options
   * column.options
   */
  options: PropTypes.object,

  /**
   * Row properties returned by react-table
   */
  row: PropTypes.object.isRequired,

  /**
   * Field value
   */
  value: PropTypes.any,

  /**
   * Handler for when the field value changes
   */
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(EditField);
