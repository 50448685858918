import React from "react";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";

import PropTypes from "prop-types";
import components from "./nested.menu.index";

// LOCAL STYLES
import styles from "../styles/nested.menu.styles";

const SubMenu = props => {
  const {
    classes,
    containerId,
    parentOpen,
    parentAnchorEl,
    close,
    type,
    menuItems,
    handleMenuItemClick,
    dataTestId,
    actions
  } = props;
  const WrappedComponent = components(type);
  return !menuItems ? null : (
    <Popper
      open={parentOpen}
      anchorEl={parentAnchorEl}
      disablePortal
      placement="right-start"
      modifiers={{
        offset: {
          offset: "0 -8px"
        },
        flip: {
          enabled: true
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: "scrollParent"
        }
      }}
    >
      <Paper classes={{ root: classes.root }}>
        <WrappedComponent
          containerId={containerId}
          close={close}
          type={type}
          menuItems={menuItems}
          handleMenuItemClick={handleMenuItemClick}
          dataTestId={dataTestId}
          actions={actions}
        />
      </Paper>
    </Popper>
  );
};

SubMenu.defaultProps = {
  type: "SELECT"
};

SubMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  containerId: PropTypes.string.isRequired,
  parentOpen: PropTypes.bool.isRequired,
  parentAnchorEl: PropTypes.any,
  close: PropTypes.func.isRequired,
  type: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired
};

export default withStyles(styles)(SubMenu);
