import React, { useState } from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";

import styles from "./styles/radio.button.styles";

function RadioButton({
  name,
  label,
  options,
  dataTestId,
  dataTestBuilder,
  classes,
  onChange,
  value
}) {
  const [selected, setSelected] = useState(value);

  const handleChange = e => {
    setSelected(e.target.value);
    onChange && onChange(e.target.value);
  };

  return (
    <div style={{ paddingBottom: 8 }}>
      <FormControl component="fieldset">
        {/* TODO: replace with material ui <Box /> */}
        {label && (
          <div style={{ marginTop: 4, marginBottom: 8 }}>
            <FormLabel
              classes={{ root: classes.legend }}
              component="legend"
              data-test={dataTestBuilder(dataTestId, "label")}
            >
              {label}
            </FormLabel>
          </div>
        )}
        <RadioGroup name={name} value={selected} onChange={handleChange}>
          {options.map(({ value, label }, i) => (
            <FormControlLabel
              classes={{ root: classes.formControlRoot }}
              key={i.toString()}
              value={value}
              control={<Radio classes={{ root: classes.optionLabel }} />}
              label={label}
              data-test={dataTestBuilder(dataTestId, "option", i)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

RadioButton.defaultProps = {
  label: ""
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired
};

const enhance = compose(withStyles(styles));

export default enhance(RadioButton);
