import React, { Component, Fragment } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import partition from "lodash/partition";
import keyBy from "lodash/keyBy";

// MATERIAL-UI
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withStyles } from "@material-ui/core/styles";
import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";
import classNames from "classnames";
import styles from "./styles/edit.table.styles";

import { ActionButtons } from "../ActionButtons";

// UI-LIBRARY
import { AddButton } from "../../../Buttons";
import AlchemyTable from "../../data.table";

// UTILS
import {
  canAddRow,
  createCacheData,
  createPayloadData,
  isColTypeEdit,
  getAddedItems,
  getEditableFields,
  hasError,
  warnUnsavedChanges
} from "./edit.table.utils";

import {
  EDIT_TABLE_COLUMN_KEY,
  ROW_TYPE_ERROR,
  ROW_TYPE_WARNING
} from "./edit.table.constants";

import HelperText from "../../../HelperText";
import { EditField } from "../EditField";
import FormatText from "../../../FormatText";

// State applicable to the entire table row
// on initial edit or add action
const editState = {
  isEditMode: true,
  isFocused: true,
  errors: {}
};

const stateKeyAdd = "add";
const stateKeyUpdate = "update";

class EditTable extends Component {
  constructor(props) {
    super(props);
    this.addButton = React.createRef();
  }
  state = {
    [stateKeyAdd]: {},
    [stateKeyUpdate]: {}
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;

    const filterNew = ({ id }) => id;
    const previousSavedDataLength = prevProps.data.filter(filterNew).length;
    const currentSavedDataLength = data.filter(filterNew).length;

    if (previousSavedDataLength < currentSavedDataLength) {
      this.addButton.current.focus();
    }

    if (prevProps.data.length !== data.length) {
      const partitionedData = partition(data, d => !d.id);
      this.setState({
        [stateKeyAdd]: keyBy(partitionedData[0], "index"),
        [stateKeyUpdate]: keyBy(partitionedData[1], "id")
      });
    }
  }

  getColumns = () => {
    const {
      classes,
      columns,
      addButtonText,
      editButtonText,
      deleteButtonText,
      saveButtonText,
      isFetching,
      disableDeleteButton,
      disableEditButton,
      dataTestBuilder,
      dataTestId
    } = this.props;

    const addOnChangeEventHandler = col => {
      if (isColTypeEdit(col)) {
        col[EDIT_TABLE_COLUMN_KEY].onChange = (id, value, index, error) => {
          const field = col.id;
          this.handleOnChange({ id, value, index, error, field });
        };
      }
      return col;
    };

    const actionColumn = {
      width: 108,
      resizable: false,
      // react-table mechanism for cell styling overrides
      style: {
        background: "#FFF",
        overflow: "visible"
      },
      sortable: false,
      Header: () => null,
      Cell: ({ row }) => {
        const { _original } = row;
        return (
          <ActionButtons
            row={_original}
            isFetching={isFetching}
            addButtonText={addButtonText}
            editButtonText={editButtonText}
            deleteButtonText={deleteButtonText}
            saveButtonText={saveButtonText}
            handleOnEdit={this.handleOnEdit}
            handleOnDelete={this.handleOnDelete}
            handleOnSubmit={this.handleOnSubmit}
            disableDeleteButton={disableDeleteButton}
            disableEditButton={disableEditButton}
            dataTestBuilder={dataTestBuilder}
            dataTestId={
              dataTestBuilder &&
              dataTestBuilder(dataTestId, "edit-table", `row-${row._index}`)
            }
          />
        );
      },
      Footer: () => <div className={classes.footerCell} />
    };
    return [...columns.map(addOnChangeEventHandler), actionColumn];
  };

  handleOnClickAway = data => {
    if (data) this.updateRowFocus(data);
  };

  updateRowFocus = data => {
    const { id, index, isEditMode, isFocused } = data;
    if (isEditMode && isFocused) {
      this.handleOnCache(id, { ...data, isFocused: false }, index);
    }
  };

  handleOnAdd = e => {
    e.preventDefault();
    const { data, initialValues } = this.props;
    // limits the add form to one at a time only
    const nextItemIndex = getAddedItems(data).length;
    if (canAddRow(data)) {
      const values = { ...initialValues, ...editState };
      if (!values.defaultProps) {
        values.defaultProps = { ...initialValues };
      }
      this.handleOnCache(null, values, nextItemIndex);
    }
  };

  handleOnCache = (id, values, index) => {
    const { onCache } = this.props;
    onCache(id, values, index);
  };

  handleOnChange = ({ id, value, index, error, field }) => {
    const bucket = id ? [stateKeyUpdate] : [stateKeyAdd];
    const key = id ? id : index;

    this.setState(
      state => {
        const stateField = state[bucket][key];
        const stateFieldErrors = stateField ? stateField.errors : {};
        return {
          [bucket]: {
            ...state[bucket],
            [key]: {
              ...state[bucket][key],
              id,
              [field]: value,
              // index,
              errors: pickBy(
                {
                  ...stateFieldErrors,
                  [field]: error
                },
                value => !isNil(value)
              )
            }
          }
        };
      },
      () => {
        const { [bucket]: updatedBucket } = this.state;
        this.handleOnCache(id, { ...updatedBucket[key] }, index);
      }
    );
  };

  handleOnDelete = row => {
    const { onDelete, onCancel } = this.props;
    // Delete only if there is an id for the item
    // having an id means the items exists in the service
    if (!isNil(row.id) && onDelete) {
      onDelete(row.id);
    }

    // If deleting an added object which hasn't been submitted to service
    // delete will delegate to cancel and simply remove the object from cache
    else if (!isNil(row.index)) {
      onCancel(row.id, row.index, row.formCacheId);
    }
  };

  handleOnEdit = row => {
    const { initialValues } = this.props;

    // Only caches fields set in the initialValues
    const data = createCacheData(initialValues, row);

    // Edit simply caches data to let the UI know that the form is in edit mode
    // If there is no cache data, the UI simply assumes that the row is not in edit mode
    if (row && !isNil(row.id) && data) {
      const values = { ...data, ...editState };
      if (!values.defaultProps) {
        values.defaultProps = { ...data };
      }
      this.handleOnCache(row.id, values);
    }
  };

  handleOnSubmit = (row, event) => {
    const { columns } = this.props;
    event.preventDefault();

    const errors = columns.reduce((acc, curr) => {
      const colId = curr.id;
      const val = row[colId];
      const disabled = row.disable && row.disable[colId];
      if (disabled) {
        return acc;
      }
      const err =
        curr.cellEdit && curr.cellEdit.validate && curr.cellEdit.validate(val);
      if (err) {
        acc[colId] = err;
        return acc;
      }
      return acc;
    }, {});

    if (hasError(errors)) {
      row.errors = errors;
      this.handleOnCache(row.id, row, row.index);
      return;
    } else {
      const { columns, onUpdate, onCreate } = this.props;

      const editableFields = getEditableFields(columns);

      // Only the fields marked as editable in the column schema are included in the payload
      const data = createPayloadData(editableFields, row);

      // If there is an id, UI assumes that the resource needs to be updated
      if (!isNil(row.id) && onUpdate && data) {
        onUpdate(row.id, { id: row.id, ...data }, row.formCacheId);
      }

      // If there is no id, UI assumes that a resource needs to be created
      else if (!isNil(row.index) && onCreate && data) {
        onCreate(data, row.index, row.formCacheId);
      }
    }
  };

  getHelperText = (message, classes, type) => {
    return (
      <HelperText
        className={classNames(...classes, "message-wrapper")}
        type={type}
        hideIcon
      >
        {message}
      </HelperText>
    );
  };

  getTrProps = (state, rowInfo) => {
    const data = rowInfo && rowInfo.original ? rowInfo.original : {};
    return { data };
  };

  getTrComponent = ({ children, className, data = {} }) => {
    let type;

    const classes = ["rt-tr", className];

    const { unsavedChangesText, columns } = this.props;

    const editableFields = getEditableFields(columns);
    const isFocused = data.isFocused;
    const hasWarning = warnUnsavedChanges(editableFields, data) && !isFocused;

    const errors = data.errors || {};
    const hasError = Object.keys(errors).length > 0;

    let helperText = null;

    // Highlight row as error if field-level or row-level error exists
    if (hasError) {
      type = ROW_TYPE_ERROR;
      classes.push(type);

      // Show row-level error helper text
      if (errors.row) {
        helperText = this.getHelperText(errors.row, classes, type);
      }
    }

    // Highlight and show row-level warning helper text
    else if (hasWarning) {
      type = ROW_TYPE_WARNING;
      classes.push(type);
      helperText = this.getHelperText(unsavedChangesText, classes, type);
    }

    return data.isEditMode ? (
      <Fragment>
        <ClickAwayListener
          onClickAway={this.handleOnClickAway.bind(this, data)}
        >
          <div className={classNames(classes)} role="row">
            {children}
          </div>
        </ClickAwayListener>
        {helperText}
      </Fragment>
    ) : (
      <div className={classNames(classes)} role="row">
        {children}
      </div>
    );
  };

  formatEditableCell = cellInfo => {
    const { value, column, original, row } = cellInfo;

    const { _original } = row;
    const { format, noValue, cellEdit, formatDisplayValue } = column;

    // column cellEdit options
    const colOptions = { ...cellEdit };

    const error =
      original.errors && original.errors[column.id]
        ? original.errors[column.id]
        : null;

    // disable individual cells. Uses disable object on row data i.e. disable: { yourColumnKey: true }
    const disableEditCell =
      original.disable && original.disable[cellInfo.column.id];

    return original.isEditMode && isColTypeEdit(column) && !disableEditCell ? (
      <EditField
        key={original.id || original.index}
        error={error}
        format={format}
        options={colOptions}
        noValue={noValue}
        row={_original}
        label={column.label}
        value={value}
        onChange={colOptions.onChange}
      />
    ) : (
      <FormatText
        format={format}
        noValue={noValue}
        value={formatDisplayValue ? formatDisplayValue(value) : value}
        options={column.options}
      />
    );
  };

  render() {
    const {
      data,
      fullAddButtonText,
      noDataText,
      hideTableBody,
      disableAddButton,
      dataTestId,
      dataTestBuilder
    } = this.props;
    const columns = this.getColumns();

    return (
      <Fragment>
        {!hideTableBody && (
          <AlchemyTable
            columns={columns}
            data={data}
            showFooter
            sortable={false}
            pageSize={data.length}
            getTrProps={this.getTrProps}
            TrComponent={this.getTrComponent}
            noDataText={noDataText}
            Cell={this.formatEditableCell}
          />
        )}

        <AddButton
          disabled={!canAddRow(data) || disableAddButton}
          buttonText={fullAddButtonText}
          onClick={this.handleOnAdd}
          buttonRef={this.addButton}
          dataTestId={
            dataTestBuilder &&
            dataTestBuilder(dataTestId, "edit-table", "add-button")
          }
        />
      </Fragment>
    );
  }
}

EditTable.defaultProps = {
  initialValues: {},
  data: [],
  isFetching: false
};

EditTable.propTypes = {
  classes: PropTypes.object.isRequired,

  /**
   * Table column definition
   */
  columns: PropTypes.array.isRequired,

  /**
   * Table row initial values upon creation
   */
  initialValues: PropTypes.object,

  /**
   * Table data
   */
  data: PropTypes.array,

  isFetching: PropTypes.bool,

  /**
   * Action handlers
   */
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCache: PropTypes.func.isRequired,

  /**
   * UI Texts
   */
  fullAddButtonText: PropTypes.string.isRequired,
  addButtonText: PropTypes.string.isRequired,
  editButtonText: PropTypes.string.isRequired,
  deleteButtonText: PropTypes.string.isRequired,
  saveButtonText: PropTypes.string.isRequired,
  noDataText: PropTypes.string.isRequired,
  unsavedChangesText: PropTypes.string.isRequired
};

const enhance = compose(withStyles(styles));
export default enhance(EditTable);
