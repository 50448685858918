import React from "react";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";

// UI.LIBRARY
import HelperText from "../../HelperText";

// LOCAL STYLE
import styles from "../styles/no.data.message.styles";

/**
 * ALCHEMY TABLE NO DATA MESSAGE
 * No message UI component for when the table yields 0 result
 * @param children
 * @param classes
 * @returns {*}
 * @constructor
 */
const NoDataMessage = ({ classes, ...rest }) => (
  <div className={classes.messageBox}>
    <HelperText {...rest} />
  </div>
);

export default withStyles(styles)(NoDataMessage);
