/**
 * TITLE STYLES
 * @author ryanr
 * @param theme {object}
 */
const styles = theme => ({
  title: Object.assign({}, theme.typography["h6Dark"], {
    display: "inline-block",
    margin: "12px 5px 0",
    color: theme.palette.common["black"]
  })
});

export default styles;
