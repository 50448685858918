import { normalize } from "../../model.utils";
import { defaultProps, schema, MODEL_NAME } from "./model";

/**
 * Funded loan (details) Transform [RECEIVED]
 * pay-off
 * get a specific loan data
 * transform data -> before it reaches the store
 * @param {*} rawData
 * @author Ehsan
 */

export const transformReceived = rawData =>
  normalize(rawData, defaultProps, schema, MODEL_NAME);
