import * as actionTypes from "./dashboard.action.types";

/**
 * GET DETAILS PAGE DATA
 */
export function fetchDashboardProfile() {
  return { type: actionTypes.DASHBOARD_REQUEST_START };
}

export function fetchDashboardProfileSuccess(payload) {
  return { type: actionTypes.DASHBOARD_REQUEST_SUCCESS, payload };
}

export function fetchDashboardProfileFailure(error) {
  return { type: actionTypes.DASHBOARD_REQUEST_FAIL, error };
}
