import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Breadcrumb from "../Breadcrumb";
import { styles } from "./styles/secondary.header.styles";

/**
 * SECONDARY HEADER
 * this component contains Breadcrumb and Actions
 */
function SecondaryHeader(props) {
  const { classes, paths, actions, dataTestId } = props;
  return (
    <Toolbar
      data-test={dataTestId.root}
      classes={{
        root: classes.root,
        gutters: classes.gutters
      }}
    >
      {paths && paths.length && (
        <Breadcrumb dataTestId={dataTestId.breadcrumb} paths={paths} />
      )}
      {actions}
    </Toolbar>
  );
}

SecondaryHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  dataTestId: PropTypes.object.isRequired,
  paths: PropTypes.array,
  actions: PropTypes.any
};

export default withStyles(styles)(SecondaryHeader);
