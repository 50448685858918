// ROLES
export const AUDITOR_USER = "AUDITOR_USER";
export const SUPER_USER = "SUPER_USER";
export const USER = "USER";

// ENTITIES
export const LOAN_APPS = "LOAN_APPS";
export const FUNDED_LOANS = "FUNDED_LOANS";
export const TRANCHE = "TRANCHE";

// ACTIONS
export const SEARCH = "SEARCH";
export const VIEW = "VIEW";
export const EDIT = "EDIT";
export const CREATE = "CREATE";
export const READ = "READ";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

// LOAN APPS CUSTOM ACTIONS
export const CHANGE_AGREEMENT_TERMS = "CHANGE_AGREEMENT_TERMS";
export const GENERATE_LOAN_AGREEMENT = "GENERATE_LOAN_AGREEMENT";
export const VIEW_APP_DEAL_LEAD = "VIEW_APP_DEAL_LEAD";
export const CHANGE_APP_DEAL_LEAD = "CHANGE_APP_DEAL_LEAD";
export const CHANGE_APP_REFERRAL = "CHANGE_APP_REFERRAL";
export const CHANGE_APP_STATUS = "CHANGE_APP_STATUS";
export const VIEW_RISK_ASSESSMENT_BRIEF_DESC = "VIEW_RISK_ASSESSMENT_BRIEF_DESC"; // prettier-ignore
export const VIEW_RISK_ASSESSMENT_FULL_DESC = "VIEW_RISK_ASSESSMENT_FULL_DESC";
export const SUBMIT_MANUAL_SCORECARD = "SUBMIT_MANUAL_SCORECARD";

// generated
export const ALL_PERMISSIONS =  "ALL_PERMISSIONS" // prettier-ignore
export const ALL_READONLY_PERMISSIONS =  "ALL_READONLY_PERMISSIONS"; // prettier-ignore
export const VIEW_CONFIGURATION =  "VIEW_CONFIGURATION"; // prettier-ignore
export const ADD_UPDATE_REMOVE_USERS_TEAMS_ROLES_AND_TEAM_MEMBERSHIPS =  "ADD_UPDATE_REMOVE_USERS_TEAMS_ROLES_AND_TEAM_MEMBERSHIPS"; // prettier-ignore
export const SEND_EMAILS =  "SEND_EMAILS"; // prettier-ignore
export const SEND_SMS_TEXT_MESSAGES =  "SEND_SMS_TEXT_MESSAGES"; // prettier-ignore
export const SEARCH_LOANS =  "SEARCH_LOANS"; // prettier-ignore
export const VIEW_LOANS =  "VIEW_LOANS"; // prettier-ignore
export const SEARCH_APPLICATIONS = "SEARCH_APPLICATIONS"; // prettier-ignore - duplicate
export const VIEW_APPLICATIONS = "VIEW_APPLICATIONS"; // prettier-ignore - duplicate
export const CREATE_NEW_APPLICATIONS =  "CREATE_NEW_APPLICATIONS"; // prettier-ignore
export const CHANGE_THE_TERMS_OF_AN_APPLICATIONS_LOAN_AGREEMENT =  "CHANGE_THE_TERMS_OF_AN_APPLICATIONS_LOAN_AGREEMENT"; // prettier-ignore
export const GENERATE_A_LOAN_AGREEMENT_FOR_AN_APPLICATION =  "GENERATE_A_LOAN_AGREEMENT_FOR_AN_APPLICATION"; // prettier-ignore
export const VIEW_LOAN_AGREEMENT =  "VIEW_LOAN_AGREEMENT"; // prettier-ignore
export const VIEW_THE_DEAL_LEAD_OWNER_OF_AN_APPLICATION =  "VIEW_THE_DEAL_LEAD_OWNER_OF_AN_APPLICATION"; // prettier-ignore
export const CHANGE_THE_DEAL_LEAD_OWNER_OF_AN_APPLICATION =  "CHANGE_THE_DEAL_LEAD_OWNER_OF_AN_APPLICATION"; // prettier-ignore
export const CHANGE_THE_REFERRAL_SOURCE_OF_AN_APPLICATION =  "CHANGE_THE_REFERRAL_SOURCE_OF_AN_APPLICATION"; // prettier-ignore
export const MODIFY_APPLICATION_REFERRAL_AGENT =  "MODIFY_APPLICATION_REFERRAL_AGENT"; // prettier-ignore
export const VIEW_APPLICATION_REFERRAL_AGENT =  "VIEW_APPLICATION_REFERRAL_AGENT"; // prettier-ignore
export const CHANGE_THE_STATUS_OF_AN_APPLICATION =  "CHANGE_THE_STATUS_OF_AN_APPLICATION"; // prettier-ignore
export const VIEW_APPLICATION_STATUS_HISTORY =  "VIEW_APPLICATION_STATUS_HISTORY"; // prettier-ignore
export const VIEW_DECISION_ACTIONS =  "VIEW_DECISION_ACTIONS"; // prettier-ignore
export const VIEW_THE_BRIEF_DESCRIPTION_OF_THE_JUSTIFICATION_FOR_A_DECISION_ACTION =  "VIEW_THE_BRIEF_DESCRIPTION_OF_THE_JUSTIFICATION_FOR_A_DECISION_ACTION"; // prettier-ignore
export const VIEW_THE_FULLY_DETAILED_DESCRIPTION_OF_THE_JUSTIFICATION_FOR_A_DECISION_ACTION =  "VIEW_THE_FULLY_DETAILED_DESCRIPTION_OF_THE_JUSTIFICATION_FOR_A_DECISION_ACTION"; // prettier-ignore
export const EVALUATE_AN_APPLICATION_CREATING_A_NEW_DECISION_ACTION_RISK_ASSESSMENT = "EVALUATE_AN_APPLICATION_CREATING_A_NEW_DECISION_ACTION_RISK_ASSESSMENT"; // prettier-ignore
export const VIEW_DEBTTOINCOME_RATIOS =  "VIEW_DEBTTOINCOME_RATIOS"; // prettier-ignore
export const ADD_AN_APPLICANT_COSIGNER_TO_AN_APPLICATION =  "ADD_AN_APPLICANT_COSIGNER_TO_AN_APPLICATION"; // prettier-ignore
export const REMOVE_AN_APPLICANT_COSIGNER_FROM_AN_APPLICATION =  "REMOVE_AN_APPLICANT_COSIGNER_FROM_AN_APPLICATION"; // prettier-ignore
export const MODIFY_A_NOTE =  "MODIFY_A_NOTE"; // prettier-ignore
export const VIEW_NOTES =  "VIEW_NOTES"; // prettier-ignore
export const VIEW_NOTE_TEMPLATES =  "VIEW_NOTE_TEMPLATES"; // prettier-ignore
export const CREATE_EDIT_DELETE_NOTE_TEMPLATES =  "CREATE_EDIT_DELETE_NOTE_TEMPLATES"; // prettier-ignore
export const VIEW_DOCUMENTS =  "VIEW_DOCUMENTS"; // prettier-ignore
export const MODIFY_DOCUMENT =  "MODIFY_DOCUMENT"; // prettier-ignore
export const VIEW_OUTSTANDING_DOCUMENTS =  "VIEW_OUTSTANDING_DOCUMENTS"; // prettier-ignore
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT"; // prettier-ignore
export const MODIFY_DOCUMENT_CONTENT =  "MODIFY_DOCUMENT_CONTENT"; // prettier-ignore
export const VIEW_DOCUMENT_CONTENT =  "VIEW_DOCUMENT_CONTENT"; // prettier-ignore
export const DELETE_A_DOCUMENT =  "DELETE_A_DOCUMENT"; // prettier-ignore
export const APPROVE_OR_REJECT_A_DOCUMENT =  "APPROVE_OR_REJECT_A_DOCUMENT"; // prettier-ignore
export const VIEW_AUDIT_HISTORY =  "VIEW_AUDIT_HISTORY"; // prettier-ignore
export const MODIFY_LOAN_DETAILS =  "MODIFY_LOAN_DETAILS"; // prettier-ignore
export const VIEW_LOAN_DETAILS =  "VIEW_LOAN_DETAILS"; // prettier-ignore
export const ADD_UPDATE_AN_APPLICANT_COSIGNER =  "ADD_UPDATE_AN_APPLICANT_COSIGNER"; // prettier-ignore
export const ADD_MODIFY_INCOME_SOURCE =  "ADD_MODIFY_INCOME_SOURCE"; // prettier-ignore
export const VIEW_INCOME_SOURCES =  "VIEW_INCOME_SOURCES"; // prettier-ignore
export const VIEW_APPLICANT_EMAIL_ADDRESSES =  "VIEW_APPLICANT_EMAIL_ADDRESSES"; // prettier-ignore
export const ADD_MODIFY_APPLICANT_EMAIL_ADDRESSES =  "ADD_MODIFY_APPLICANT_EMAIL_ADDRESSES"; // prettier-ignore
export const VIEW_EMAIL_MESSAGES_RECEIVED_SENT_DRAFTS =  "VIEW_EMAIL_MESSAGES_RECEIVED_SENT_DRAFTS"; // prettier-ignore
export const ADD_MODIFY_EMAIL_MESSAGE_DRAFTS =  "ADD_MODIFY_EMAIL_MESSAGE_DRAFTS"; // prettier-ignore
export const VIEW_LIABILITIES =  "VIEW_LIABILITIES"; // prettier-ignore
export const ADD_MODIFY_LIABILITY =  "ADD_MODIFY_LIABILITY"; // prettier-ignore
export const MODIFY_CLIENT_EXPENSES =  "MODIFY_CLIENT_EXPENSES"; // prettier-ignore
export const VIEW_CLIENT_EXPENSES =  "VIEW_CLIENT_EXPENSES"; // prettier-ignore
export const VIEW_CLIENT_SOCIAL_INSURANCE_NUMBERS =  "VIEW_CLIENT_SOCIAL_INSURANCE_NUMBERS"; // prettier-ignore
export const MODIFY_CLIENT_BANK_ACCOUNT =  "MODIFY_CLIENT_BANK_ACCOUNT"; // prettier-ignore
export const VIEW_CLIENT_BANK_ACCOUNTS =  "VIEW_CLIENT_BANK_ACCOUNTS"; // prettier-ignore
export const VIEW_CLIENT_BANK_ACCOUNT_NUMBERS =  "VIEW_CLIENT_BANK_ACCOUNT_NUMBERS"; // prettier-ignore
export const VIEW_CREDIT_REPORT =  "VIEW_CREDIT_REPORT"; // prettier-ignore
export const MODIFY_CREDIT_REPORT =  "MODIFY_CREDIT_REPORT"; // prettier-ignore
export const RETRIEVE_A_NEW_CREDIT_HISTORY_REPORT_FROM_THE_CREDIT_BUREAU =  "RETRIEVE_A_NEW_CREDIT_HISTORY_REPORT_FROM_THE_CREDIT_BUREAU"; // prettier-ignore
export const VIEW_BANK_STATEMENTS =  "VIEW_BANK_STATEMENTS"; // prettier-ignore
export const ADD_MODIFY_BANK_STATEMENT =  "ADD_MODIFY_BANK_STATEMENT"; // prettier-ignore
export const VIEW_BANK_STATEMENT_STATUS =  "VIEW_BANK_STATEMENT_STATUS"; // prettier-ignore
export const CREATE_A_NEW_REQUEST_CODE_FOR_BANK_STATEMENT_RETRIEVAL =  "CREATE_A_NEW_REQUEST_CODE_FOR_BANK_STATEMENT_RETRIEVAL"; // prettier-ignore
export const RESEND_NOTIFICATION_TO_THE_CUSTOMER_ABOUT_AN_EXISTING_REQUEST_CODE =  "RESEND_NOTIFICATION_TO_THE_CUSTOMER_ABOUT_AN_EXISTING_REQUEST_CODE"; // prettier-ignore
export const SUBMIT_MANUAL_SCORECARD_INFO =  "SUBMIT_MANUAL_SCORECARD_INFO"; // prettier-ignore
export const REQUEST_A_PAPI_CREDIT_DECISION =  "REQUEST_A_PAPI_CREDIT_DECISION"; // prettier-ignore
export const VIEW_TRANCHES =  "VIEW_TRANCHES"; // prettier-ignore
export const CREATE_AND_EDIT_TRANCHES =  "CREATE_AND_EDIT_TRANCHES"; // prettier-ignore
