import { createSelector } from "reselect";

const trackersSelector = state => state.ui;
export const getTrackedComponents = createSelector(
  trackersSelector,
  trackers => trackers
);

const trackerCollapseAllSelector = (state, category) =>
  state.ui && state.ui[category] && state.ui[category].collapseAll
    ? state.ui[category].collapseAll
    : null;

export const getTrackerCollapseAll = createSelector(
  trackerCollapseAllSelector,
  tracker => tracker
);
