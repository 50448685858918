import { utils } from "@cauldron/core";
import { actionTypes } from "./coordinator.action.types";

const { makeActionCreator } = utils;

export const evaluate = makeActionCreator(
  actionTypes.EVALUATE_REQUEST,
  "payload",
  "formId",
  "eventHandlers"
);

export const evaluateSuccess = makeActionCreator(
  actionTypes.EVALUATE_SUCCESS,
  "data"
);

export const evaluateFailure = makeActionCreator(
  actionTypes.EVALUATE_FAILURE,
  "error"
);

export const preScreen = makeActionCreator(
  actionTypes.PRE_SCREEN_REQUEST,
  "payload",
  "eventHandlers"
);

export const preScreenSuccess = makeActionCreator(
  actionTypes.PRE_SCREEN_SUCCESS,
  "data"
);

export const preScreenFailure = makeActionCreator(
  actionTypes.PRE_SCREEN_FAILURE,
  "error"
);
