"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeLocale = changeLocale;
exports.resetLocale = resetLocale;

var _i18nAction = require("./i18n.action.types");

function changeLocale(locale) {
  return {
    type: _i18nAction.LOCALE_CHANGE,
    locale: locale
  };
}

function resetLocale() {
  return {
    type: _i18nAction.LOCALE_RESET
  };
}