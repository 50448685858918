import filter from "lodash/filter";
import * as actions from "../action.types/loan.application.details.action.types";
import {
  LOAN_APPLICATION_CLIENT_LIABILITY_ADD_SUCCESS,
  LOAN_APPLICATION_CLIENT_LIABILITY_DELETE_SUCCESS,
  LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_SUCCESS
} from "../../components/application.card/components/liabilities/engine/liabilities.action.types";

import {
  LOAN_APPLICATION_BANK_ACCOUNT_ADD_SUCCESS,
  LOAN_APPLICATION_BANK_ACCOUNT_DELETE_SUCCESS,
  LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_SUCCESS
} from "../../components/application.card/components/bank.details/engine/bank.details.action.types";

import {
  LOAN_APPLICATION_CLIENT_INCOME_ADD_SUCCESS,
  LOAN_APPLICATION_CLIENT_INCOME_DELETE_SUCCESS,
  LOAN_APPLICATION_CLIENT_INCOME_UPDATE_SUCCESS
} from "../../components/application.card/components/incomes/engine/incomes.action.types";
import {
  LOAN_APPLICATION_BANK_REQUEST_FETCH_SUCCESS,
  LOAN_APPLICATION_BANK_REQUEST_SEND_SUCCESS,
  LOAN_APPLICATION_BANK_REQUEST_RESEND_SUCCESS
} from "../../components/application.card/components/bank.requests/engine/bank.requests.types";

import {
  LOAN_REQUEST_NOTE_TEMPLATES_FETCH_SUCCESS,
  LOAN_REQUEST_NOTE_TEMPLATES_FETCH_FAILURE,
  LOAN_REQUEST_SUBMIT_SUCCESS,
  LOAN_REQUEST_SUBMIT_FAILURE
} from "../../components/application.card/components/loanRequest/engine/loan.request.action.types";

import { actionTypes as statusActionTypes } from "../../../../services/data/status";

import { coordinator as coordinatorService } from "../../../../services";

const initialState = {
  application: {},
  scores: { results: [] },
  debtRatio: null,
  bankRequests: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // APPLICATION DETAILS
    case actions.LOAN_APPLICATION_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        application: action.payload
      };

    // BANK REQUEST
    case LOAN_APPLICATION_BANK_REQUEST_FETCH_SUCCESS:
      return {
        ...state,
        bankRequests: action.data.results
      };

    case LOAN_APPLICATION_BANK_REQUEST_SEND_SUCCESS:
      return {
        ...state,
        bankRequests: state.bankRequests.concat(action.data)
      };

    case LOAN_APPLICATION_BANK_REQUEST_RESEND_SUCCESS:
      return {
        ...state,
        bankRequests: state.bankRequests.map(request => {
          if (
            request.progressaRequestCode === action.data.progressaRequestCode
          ) {
            return {
              ...request,
              ...action.data
            };
          }
          return request;
        })
      };

    // SCORES
    case actions.LOAN_APPLICATION_SCORES_FETCH_SUCCESS:
      return {
        ...state,
        scores: action.data
      };

    case coordinatorService.actionTypes.EVALUATE_SUCCESS:
      return {
        ...state,
        scores: {
          ...state.scores,
          results: [action.data, ...state.scores.results]
        }
      };

    // NOTES
    case actions.LOAN_APPLICATION_NOTES_FETCH_SUCCESS:
      return {
        ...state,
        notes: action.data
      };
    case actions.LOAN_APPLICATION_NOTES_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          notes: [action.data, ...state.application.notes]
        }
      };

    // AFFORDABILITY
    case actions.LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_SUCCESS:
      return {
        ...state,
        debtRatio: action.data
      };

    case actions.LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_FAILURE:
      return {
        ...state,
        debtRatio: null
      };

    // CLIENT INCOME
    case LOAN_APPLICATION_CLIENT_INCOME_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                incomeSources: client.incomeSources.concat(action.data)
              };
            }
            return client;
          })
        }
      };

    case LOAN_APPLICATION_CLIENT_INCOME_DELETE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                incomeSources: filter(
                  client.incomeSources,
                  income => income.id !== action.incomeId
                )
              };
            }
            return client;
          })
        }
      };

    case LOAN_APPLICATION_CLIENT_INCOME_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            const updateIncomes = client.incomeSources.map(income => {
              if (income.id === action.incomeId) {
                return {
                  ...income,
                  ...action.data
                };
              }
              return income;
            });
            return {
              ...client,
              incomeSources: updateIncomes
            };
          })
        }
      };

    // CLIENT LIABILITY
    case LOAN_APPLICATION_CLIENT_LIABILITY_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                liabilities: client.liabilities.concat(action.data)
              };
            }
            return client;
          })
        }
      };

    case LOAN_APPLICATION_CLIENT_LIABILITY_DELETE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                liabilities: filter(
                  client.liabilities,
                  liability => liability.id !== action.liabilityId
                )
              };
            }
            return client;
          })
        }
      };

    case LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            const updatedLiabilities = client.liabilities.map(liabilty => {
              if (liabilty.id === action.liabilityId) {
                return { ...liabilty, ...action.data };
              }
              return liabilty;
            });
            return {
              ...client,
              liabilities: updatedLiabilities
            };
          })
        }
      };

    // CLIENT EXPENSE
    case actions.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                expenses: client.expenses.concat(action.data)
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_EXPENSE_DELETE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                expenses: filter(
                  client.expenses,
                  expense => expense.id !== action.expenseId
                )
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                expenses: client.expenses.map(expense => {
                  if (expense.id === action.expenseId) {
                    return { ...expense, ...action.data };
                  }
                  return expense;
                })
              };
            }
            return client;
          })
        }
      };

    // CLIENT BANK ACCOUNT
    case LOAN_APPLICATION_BANK_ACCOUNT_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                bankAccounts: client.bankAccounts.concat(action.data)
              };
            }
            return client;
          })
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                bankAccounts: filter(
                  client.bankAccounts,
                  account => account.id !== action.accountId
                )
              };
            }
            return client;
          })
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            const updatedBankAccounts = client.bankAccounts.map(account => {
              if (account.id === action.accountId) {
                return { ...account, ...action.data };
              }
              return account;
            });
            return {
              ...client,
              bankAccounts: updatedBankAccounts
            };
          })
        }
      };

    // LOAN APPLICATION CLIENT
    case actions.LOAN_APPLICATION_CLIENT_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: [...state.application.clients, action.data]
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.filter(
            client => client.id !== action.clientId
          )
        }
      };

    case actions.LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          referral: action.data
        }
      };

    case actions.LOAN_APPLICATION_DEAL_LEAD_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          owner: action.data
        }
      };

    case actions.LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                ...action.data.find(({ id }) => action.clientId === id)
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_EMAIL_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                emails: client.emails.map(emailDetails => {
                  if (emailDetails.id === action.emailId) {
                    return action.data;
                  }
                  if (action.data.primary) {
                    return { ...emailDetails, primary: false };
                  }
                  return emailDetails;
                })
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_EMAIL_DELETE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                emails: client.emails.filter(
                  emailDetails => emailDetails.id !== action.emailId
                )
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_EMAIL_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              const existingEmails = action.data.primary
                ? client.emails.map(emailData => ({
                    ...emailData,
                    primary: false
                  }))
                : client.emails;
              return {
                ...client,
                emails: [...existingEmails, action.data]
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_PHONE_NUMBER_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                phoneNumbers: client.phoneNumbers.map(details => {
                  if (details.id === action.phoneId) {
                    return action.data;
                  }
                  if (action.data.primary) {
                    return { ...details, primary: false };
                  }
                  return details;
                })
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_PHONE_NUMBER_DELETE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                phoneNumbers: client.phoneNumbers.filter(
                  details => details.id !== action.phoneId
                )
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_PHONE_NUMBER_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              const existingPhoneNumbers = action.data.primary
                ? client.phoneNumbers.map(numberData => ({
                    ...numberData,
                    primary: false
                  }))
                : client.phoneNumbers;
              return {
                ...client,
                phoneNumbers: [...existingPhoneNumbers, action.data]
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                addresses: client.addresses.map(addressDetails => {
                  if (addressDetails.id === action.residenceId) {
                    return action.data;
                  }
                  if (action.data.primaryResidence) {
                    return { ...addressDetails, primaryResidence: false };
                  }
                  return addressDetails;
                })
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              return {
                ...client,
                addresses: client.addresses.filter(
                  addressDetails => addressDetails.id !== action.residenceId
                )
              };
            }
            return client;
          })
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_RESIDENCE_ADD_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          clients: state.application.clients.map(client => {
            if (client.id === action.clientId) {
              const existingAddresses = action.data.primaryResidence
                ? client.addresses.map(addressData => ({
                    ...addressData,
                    primaryResidence: false
                  }))
                : client.addresses;
              return {
                ...client,
                addresses: [...existingAddresses, action.data]
              };
            }
            return client;
          })
        }
      };

    // LOAN REQUEST
    case LOAN_REQUEST_NOTE_TEMPLATES_FETCH_SUCCESS: {
      const noteTemplates = action.templates;
      return {
        ...state,
        application: {
          ...state.application,
          noteTemplates
        }
      };
    }
    case LOAN_REQUEST_NOTE_TEMPLATES_FETCH_FAILURE:
      return state;

    case LOAN_REQUEST_SUBMIT_SUCCESS: {
      const loanRequestNotes = { ...action.data };
      const notes = state.notes;

      notes.push({ ...loanRequestNotes });
      return {
        ...state,
        application: {
          ...state.application,
          notes
        }
      };
    }

    case LOAN_REQUEST_SUBMIT_FAILURE:
      return state;

    case statusActionTypes.LOAN_APPLICATION_STATUS_UPDATE_SUCCESS: {
      return {
        ...state,
        application: {
          ...state.application,
          status: action.data.status
        }
      };
    }

    default:
      return state;
  }
};
