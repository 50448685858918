// SCOPED STYLES
const styles = theme => ({
  fileUploadWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&:hover": {
      cursor: "pointer",
      "& > span": {
        backgroundColor: theme.palette.secondary.light
      },
      "& > span > span": {
        color: theme.palette.secondary.dark
      }
    }
    // TODO: active state color (when press and hold the click event)
    // "& > span": {
    //   "&:active": {
    //     backgroundColor: theme.palette.secondary.main
    //   }
    // }
  }
  //   filePickerWrapper: {
  //     // backgroundColor: "pink",
  //     padding: "117px 0",
  //     position: "absolute",
  //     opacity: 0,
  //     "&:hover": {
  //       cursor: "pointer"
  //     }
  //   }
});

export default styles;
