"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var styles = function styles(theme) {
  return {
    root: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      "& .logo": {
        margin: "20px",
        animation: "logo-spin infinite 2s linear",
        fill: theme.palette.primary.main
      }
    },
    "@keyframes logo-spin": {
      "0%": {
        transform: "rotate(0deg)"
      },
      "100%": {
        transform: "rotate(360deg)"
      }
    }
  };
};

var _default = styles;
exports.default = _default;