/**
 * NESTED_MENU STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = theme => ({
  root: {
    position: "relative",
    display: "inline-block",
    zIndex: 100,
    "& :focus": {
      outline: "none"
    },
    "& > div#menu": {
      minWidth: "162px",
      maxWidth: "252px",
      textAlign: "center"
    }
  },
  triggerLabelRoot: {
    display: "inline",
    color: theme.palette.grey[900]
  },
  triggerButtonRoot: {
    color: theme.palette.grey[900],
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.secondary["dark"]
    },
    "& .material-icons": {
      margin: "0 5px"
    }
  }
});

export default styles;
