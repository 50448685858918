import { i18nMark } from "@lingui/react";

/**
 * FUNDED LOAN SUCCESS MESSAGES
 * @author: Ehsan
 */

const DEFAULT_SUCCESS_MESSAGES = {
  ADD: i18nMark("The data was successfully added."),
  UPDATE: i18nMark("The data was updated successfully."),
  DELETE: i18nMark("The data was successfully deleted.")
};
export const FUNDED_LOAN_SUCCESS_MESSAGES = {
  DEFAULT: DEFAULT_SUCCESS_MESSAGES,
  PERSONAL_DETAILS: {
    TITLE: {
      UPDATE: i18nMark("Title updated successfully.")
    },
    MARITAL: {
      UPDATE: i18nMark("Marital status updated successfully.")
    }
  }
};

export const TRANCHE_IMPORT_MESSAGES = {
  IMPORT: i18nMark("Import successfully completed."),
  DEFAULT: i18nMark("imported successfully and") // TODO: @stanly update partial translation
};
