/**
 * PAGINATION STYLES
 * class name -pagination
 * @param theme
 * @author Ryan Rivera
 */
const styles = theme => ({
  zIndex: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "stretch",
  flexWrap: "wrap",
  padding: 0,
  border: "none",

  "& input, & select": {
    border: "1px solid transparent",
    background: theme.palette.common["white"],
    padding: "3px 7px",
    fontSize: "inherit",
    borderRadius: theme.shape.borderRadius,
    fontWeight: "normal",
    outline: "none",
    "-webkit-appearance": "none"
  },

  "& .-previous, & .-next": {
    flex: "1 1",
    textAlign: "center"
  },

  "& .-center": {
    flex: "1.5 1",
    textAlign: "center",
    marginBottom: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around"
  },

  "& .-pageInfo": {
    display: "inline-block",
    margin: "3px 10px",
    whiteSpace: "nowrap"
  },

  "& .-pageJump": {
    display: "inline-block",

    "& input": {
      width: "70px",
      textAlign: "center"
    }
  },

  "& .-pageSizeOptions": {
    margin: "3px 10px",
    position: "relative",

    "& select": {
      paddingRight: "17px",
      cursor: "pointer"
    },

    "& .-caret": {
      position: "absolute",
      right: "14px",
      top: "40%",

      "&:before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
        borderTop: "5px solid " + theme.palette.grey[900],
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent"
      },

      "&:after": {
        content: "''",
        position: "absolute",
        left: "2px",
        top: 0,
        pointerEvents: "none",
        borderTop: "3px solid " + theme.palette.common["white"],
        borderLeft: "3px solid transparent",
        borderRight: "3px solid transparent"
      }
    }
  },

  "& .-btn": {
    appearance: "none",
    display: "block",
    width: "100%",
    height: "100%",
    border: 0,
    borderRadius: theme.shape.borderRadius,
    padding: "4px",
    fontSize: "1em",
    color: theme.palette.common["white"],
    background: theme.palette.secondary["dark"],
    transition: "all 0.2s ease",
    cursor: "pointer",
    outline: "none",

    "&[disabled]": {
      background: theme.palette.lightGrey[200],
      color: theme.palette.grey[600],
      opacity: "0.5",
      cursor: "default"
    },

    "&:not([disabled]):hover": {
      background: theme.palette.secondary["light"],
      color: theme.palette.grey[900]
    }
  }
});

export default styles;
