import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";

// MATERIAL-UI
import Snackbar from "../../ui.library/Snackbar";

// Services Dependencies
import { getMessenger, messengerActions } from "../../services/messenger";

class Messenger extends Component {
  render() {
    const {
      i18n,
      messenger,
      messengerActions,
      dataTestId,
      dataTestBuilder
    } = this.props;
    const { show, ...rest } = messenger;
    const { hideMessage } = messengerActions;
    const message = i18n._(messenger.message);
    return (
      <Snackbar
        open={show}
        closeSnackbar={hideMessage}
        message={message}
        dataTestId={dataTestId}
        dataTestBuilder={dataTestBuilder}
        {...rest}
      />
    );
  }
}

Messenger.propTypes = {
  messenger: PropTypes.object.isRequired,
  messengerActions: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  messenger: getMessenger(state)
});

const mapDispatchToProps = dispatch => ({
  messengerActions: bindActionCreators(messengerActions, dispatch)
});

const enhance = compose(
  withI18n(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(Messenger);
