import React from "react";
import ReactErrorBoundary from "react-error-boundary";
import { ErrorMessage } from "../ErrorMessage";

function ErrorBoundary(props) {
  const { children, ...rest } = props;
  const ErrorBoundaryWrapper = ReactErrorBoundary;

  const FallbackComponent = ({ error }) => (
    <ErrorMessage error={error.toString()} {...rest} />
  );

  return (
    <ErrorBoundaryWrapper FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundaryWrapper>
  );
}

export default ErrorBoundary;
