import { actionTypes } from "./agent.portal.clients.action.types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLIENT_CREATE_SUCCESS:
    case actionTypes.CLIENT_CREATE_FROM_APPLICANT_SUCCESS:
      return action.data;

    case actionTypes.CLIENT_FETCH_SUCCESS:
      return action.data;

    case actionTypes.CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.data
      };

    default:
      return state;
  }
};
