import React from "react";
import PropTypes from "prop-types";
import { utils } from "@cauldron/core";
import { compose } from "recompose";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import {
  AlchemyTextField,
  AlchemyCheckbox,
  AlchemyPhoneField
} from "../../ui.library";

const { generateTestIds } = utils;

function ClientsCreateFormDetails({
  i18n,
  setFormStateData,
  handleChange,
  values,
  dataTestId,
  getInputProps
}) {
  return (
    <div>
      <AlchemyTextField
        {...getInputProps({
          name: "first",
          label: i18n._(t`First name`)
        })}
      />
      <AlchemyTextField
        {...getInputProps({
          name: "last",
          label: i18n._(t`Last name`)
        })}
      />
      <AlchemyTextField
        {...getInputProps({
          name: "email",
          label: i18n._(t`Email address`)
        })}
      />
      <AlchemyPhoneField
        {...getInputProps({
          name: "number",
          label: i18n._(t`Mobile phone`)
        })}
      />
      <AlchemyCheckbox
        checkboxText={i18n._(t`Send welcome email`)}
        onChange={e => {
          handleChange(e);
          setFormStateData({
            ...values,
            sendWelcomeEmail: e.target.checked
          });
        }}
        fieldName="sendWelcomeEmail"
        value={values.sendWelcomeEmail}
        data-test={generateTestIds(
          dataTestId,
          "send-welcome-email",
          "checkbox"
        )}
        dataTestBuilder={generateTestIds}
      />
    </div>
  );
}

ClientsCreateFormDetails.propTypes = {
  dataTestId: PropTypes.string.isRequired
};

const enhance = compose(withI18n());

export default enhance(ClientsCreateFormDetails);
