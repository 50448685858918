import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import { InputLabel } from "@material-ui/core";

// LOCAL STYLES
import styles from "./styles/input.label.styles";

/**
 * INPUT_LABEL COMPONENT
 * @author davidl | rrive
 */
function _InputLabel({ classes, children }) {
  return (
    <InputLabel classes={{ root: classes.labelColor }}>{children}</InputLabel>
  );
}

_InputLabel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired
};

const enhance = compose(withStyles(styles));

export default enhance(_InputLabel);
