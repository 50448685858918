/**
 * INPUT_LABEL STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = theme => ({
  labelColor: {
    color: theme.palette.lightGrey[900]
  }
});

export default styles;
