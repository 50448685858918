"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _recompose = require("recompose");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styles = require("@material-ui/core/styles");

var _IconButton2 = _interopRequireDefault(require("@material-ui/core/IconButton"));

var _iconButton = _interopRequireDefault(require("./styles/icon.button.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}
/**
 * ICON_BUTTON
 * wrapper for material-ui IconButton
 * SM | MD | LG
 */


var _IconButton = function _IconButton(_ref) {
  var classes = _ref.classes,
      size = _ref.size,
      dataTestId = _ref.dataTestId,
      rest = _objectWithoutProperties(_ref, ["classes", "size", "dataTestId"]);

  return _react.default.createElement(_IconButton2.default, _extends({
    "data-test": dataTestId ? dataTestId : null,
    classes: {
      root: classes["root".concat(size)],
      label: classes.label,
      disabled: classes.disabled
    }
  }, rest));
};

_IconButton.propTypes = {
  classes: _propTypes.default.object.isRequired,
  size: _propTypes.default.oneOf(["SM", "MD", "LG"]).isRequired,
  dataTestId: _propTypes.default.string
};
_IconButton.defaultProps = {
  dataTestId: "",
  size: "MD"
};
var enhance = (0, _recompose.compose)((0, _styles.withStyles)(_iconButton.default));

var _default = enhance(_IconButton);

exports.default = _default;