import {
  LOAN_APPLICATION_CLIENT_INCOME_ADD,
  LOAN_APPLICATION_CLIENT_INCOME_ADD_SUCCESS,
  LOAN_APPLICATION_CLIENT_INCOME_DELETE,
  LOAN_APPLICATION_CLIENT_INCOME_DELETE_SUCCESS,
  LOAN_APPLICATION_CLIENT_INCOME_DELETE_FAILURE,
  LOAN_APPLICATION_CLIENT_INCOME_UPDATE,
  LOAN_APPLICATION_CLIENT_INCOME_UPDATE_SUCCESS
} from "./incomes.action.types";

export const addNewIncome = (clientId, applicationId, data, formId) => {
  return {
    type: LOAN_APPLICATION_CLIENT_INCOME_ADD,
    payload: {
      applicationId,
      clientId,
      data
    },
    formId
  };
};

export const addNewIncomeSuccess = (clientId, data) => {
  return {
    type: LOAN_APPLICATION_CLIENT_INCOME_ADD_SUCCESS,
    clientId,
    data
  };
};

export const updateIncome = (
  applicationId,
  clientId,
  incomeId,
  data,
  formId
) => {
  return {
    type: LOAN_APPLICATION_CLIENT_INCOME_UPDATE,
    applicationId,
    clientId,
    incomeId,
    payload: {
      data
    },
    formId
  };
};

export const updateIncomeSuccess = (clientId, incomeId, data) => {
  return {
    type: LOAN_APPLICATION_CLIENT_INCOME_UPDATE_SUCCESS,
    clientId,
    incomeId,
    data
  };
};

export const deleteIncome = (applicationId, clientId, incomeId, formId) => {
  return {
    type: LOAN_APPLICATION_CLIENT_INCOME_DELETE,
    applicationId,
    clientId,
    incomeId,
    formId
  };
};

export const deleteIncomeSuccess = (clientId, incomeId) => {
  return {
    type: LOAN_APPLICATION_CLIENT_INCOME_DELETE_SUCCESS,
    clientId,
    incomeId
  };
};

export const deleteIncomeFailure = () => {
  return {
    type: LOAN_APPLICATION_CLIENT_INCOME_DELETE_FAILURE
  };
};
