import rtResizerStyles from "./table.resizer.styles";
import paginationStyles from "./table.pagination.styles";
import loadingStyles from "./table.loading.styles";
import rtExpanderStyles from "./table.expander.styles";
import { fadeHEX } from "../../../utils/jss.utils";

/**
 * DATATABLE STYLES
 * @author Ryan Rivera
 */
const styles = theme => ({
  dataTable: {
    background: theme.palette.common["white"],
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    color: theme.palette.grey[900],
    lineHeight: "16px",
    height: "100%",
    minHeight: 0,
    display: "flex",
    flex: "auto",
    width: "100%",

    "& .popover": {
      zIndex: 1
    },

    "& .text-align-right": {
      textAlign: "right"
    },

    "& .text-align-center": {
      justifyContent: "center"
    },

    "& .pad-sides": {
      "& .rt-thead": {
        "& .rt-th": {
          "&:last-child .header": {
            marginRight: "15px"
          },
          "&:first-child .header": {
            marginLeft: "15px"
          }
        }
      },
      "& .rt-tbody": {
        "& .rt-td:last-child": {
          paddingRight: "20px"
        },
        "& .rt-td:first-child": {
          paddingLeft: "20px"
        }
      },
      "& .rt-tfoot": {
        "& .rt-td:last-child": {
          paddingRight: "20px"
        },
        "& .rt-td:first-child": {
          paddingLeft: "20px"
        }
      }
    },

    "& .perma-scroll": {
      "& .rt-thead": {
        paddingRight: "17px" // account for scrollbar width
      },
      "& .rt-tbody": {
        overflowX: "hidden",
        overflowY: "scroll",
        height: "100%",

        "&::-webkit-scrollbar": {
          "-webkit-appearance": "none",
          width: "17px",

          "&:vertical": {
            width: "17px"
          }
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.25)",
          border: "2px solid #f1f1f1",
          "-webkit-box-shadow": "0 0 1px rgba(255,255,255,.5)",

          "&:hover": {
            backgroundColor: "rgba(0,0,0,.4)"
          }
        },

        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1"
        },

        "& .rt-expandable": {
          cursor: "pointer",
          textOverflow: "clip"
        }
      },
      "& .rt-tfoot": {
        paddingRight: "17px" // account for scrollbar width
      }
    },

    "& mark": {
      backgroundColor: theme.palette.secondary["main"]
    },

    // .ReactTable css class
    "& > div": {
      position: "relative",
      display: "flex",
      flex: "auto",
      flexDirection: "column",
      boxSizing: "border-box",
      height: "100%",
      width: "100%",

      "&.-striped .rt-tr.-odd": {
        background: theme.palette.lightGrey[50]
      },

      "&.-highlight .rt-tbody a.rt-tr:not(.-padRow)": {
        "&:hover": {
          background: theme.palette.secondary["light"]
        },
        "&:active": {
          background: theme.palette.secondary["main"]
        }
      },

      "& .message-wrapper": {
        padding: "0 4px 4px",
        fontSize: "12px",
        width: "calc(100% - 108px)"
      },

      "& .rt-tr": {
        "&.warning": {
          backgroundColor: `${fadeHEX(
            theme.palette.warning["main"],
            ".05"
          )} !important`
        },

        "&.error": {
          backgroundColor: `${fadeHEX(
            theme.palette.error["main"],
            ".05"
          )} !important`
        }
      }
    },

    "& .-cursor-pointer": {
      cursor: "pointer"
    },

    "& .rt-table": {
      flex: "auto 1",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      width: "100%",
      borderCollapse: "collapse",
      overflow: "auto",
      overflowY: "hidden",
      height: "100%"
    },

    "& .rt-thead": {
      flex: "1 0 auto",
      display: "flex",
      flexDirection: "column",
      userSelect: "none",

      "& .rt-th, & .rt-td": {
        padding: "5px",
        lineHeight: "normal",
        position: "relative",
        transition: "box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
        boxShadow: "inset 0 0 0 0 transparent",
        outline: "none"
      },

      "& .rt-resizable-header": {
        overflow: "visible",
        outline: "none"
      },

      "& .rt-resizable-header:last-child": {
        overflow: "hidden"
      },

      "& .rt-resizable-header-content": {
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },

    "& .rt-tr-group": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      borderBottom: `1px solid ${theme.palette.common.white}`
    },

    "& .rt-tr": {
      flex: "1 0 auto",
      display: "inline-flex"
    },

    "& a.rt-tr": {
      textDecoration: "none",
      color: theme.palette.grey[900]
    },

    "& .rt-th": {
      fontWeight: "bold",
      margin: "8px 0",

      "&.-sort-asc .header-sort-icon": {
        opacity: 1,

        "& .icon-desc": {
          opacity: 0,
          zIndex: 0
        },

        "& .icon-asc": {
          opacity: 1,
          zIndex: 1
        }
      },

      "&.-sort-desc .header-sort-icon": {
        opacity: 1,

        "& .icon-desc": {
          opacity: 1,
          zIndex: 1
        },

        "& .icon-asc": {
          opacity: 0,
          zIndex: 0
        }
      }
    },

    "& .rt-th, & .rt-td": {
      minWidth: "20px",
      flex: "1 0",
      textOverflow: "ellipsis",
      overflow: "hidden",
      transition: "0.3s ease",
      transitionProperty: "width, min-width, padding, opacity"
    },

    "& .rt-td": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: "5px",
      paddingRight: "5px",
      "& > span, > p, > button": {
        display: "block",
        padding: "6px 0",
        marginTop: "4px",
        marginBottom: "4px",
        lineHeight: "16px"
      },
      "& > button": {
        textAlign: "left"
      }
    },

    "& .rt-tfoot": {
      flex: "1 0 auto",
      display: "flex",
      flexDirection: "column",
      borderTop: `2px solid ${theme.palette.common["black"]}`,
      fontWeight: "bold",

      "& .rt-td": {
        color: theme.palette.common["black"],
        "&:last-child": {
          borderRight: 0
        }
      }
    },

    "& .rt-resizing": {
      "& .rt-th, & .rt-td": {
        transition: "none !important",
        cursor: "col-resize",
        userSelect: "none",

        "&:first-child": {
          transition: "none !important",
          cursor: "col-resize",
          userSelect: "none"
        }
      }
    },

    "& .rt-noData": {
      position: "absolute",
      left: 0,
      top: "96px",
      zIndex: "1",
      pointerEvents: "none",
      padding: "10px 20px"
    },

    "& .rt-th.-hidden, & .rt-td.-hidden": {
      width: "0 !important",
      minWidth: "0 !important",
      padding: "0 !important",
      border: "0 !important",
      opacity: "0 !important"
    },

    "& .rt-expander": rtExpanderStyles(theme),

    "& .rt-resizer": rtResizerStyles(theme),

    "& .-pagination": paginationStyles(theme),

    "& .-loading": loadingStyles(theme)
  }
});

export default styles;
