import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// LOCAL STYLES
import styles from "../styles/statistics.styles";

const invisible = value => (value === 0 ? "invisible" : "");

const Statistics = ({ classes, value, list, noValue }) => {
  return (
    <div className={classNames("statistics-wrapper", classes.wrapper)}>
      {list.map(({ id, icon, color = "action" }) => (
        <span
          key={id}
          className={classNames(
            "statistics",
            invisible(value[id]),
            classes.statistics,
            classes[color]
          )}
        >
          <span className={classNames("statistics-count", classes.count)}>
            {value[id] == null ? noValue : value[id]}
          </span>
          <Icon
            className="statistics-icon"
            classes={{ root: classes.iconRoot }}
            color="inherit"
          >
            {value[id] == null ? "" : icon}
          </Icon>
        </span>
      ))}
    </div>
  );
};

Statistics.propTypes = {
  value: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  noValue: PropTypes.string.isRequired
};

export default withStyles(styles)(Statistics);
