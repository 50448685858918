import React from "react";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles/status.icon.styles";

/**
 * Display a status icon
 * @param {string} icon icon name
 * @param {string} status normal | success | warning | error | disabled
 */

const StatusIcon = ({ classes, icon, status, dataTestId, dataTestBuilder }) => (
  <Icon
    className={classes[status]}
    classes={{ root: classes.iconRoot }}
    data-test={dataTestBuilder && dataTestBuilder(dataTestId, "status-icon")}
  >
    {icon}
  </Icon>
);

StatusIcon.defaultProps = {
  status: "normal"
};

StatusIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  status: PropTypes.string,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(StatusIcon);
