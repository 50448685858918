/**
 * translateMessages
 * @param i18n
 */
export const translateMessages = (i18n, definitions) => {
  const translatables = ["title", "message", "error", "links"];
  const errMsg = {};
  for (const key in definitions) {
    if (definitions.hasOwnProperty(key)) {
      const definition = definitions[key];
      if (translatables.includes(key) && key === "links") {
        errMsg[key] = definition.map(d => ({ ...d, label: i18n._(d.label) }));
      } else if (translatables.includes(key) && definition) {
        errMsg[key] = i18n._(definition);
      } else {
        errMsg[key] = definition;
      }
    }
  }
  return errMsg;
};
