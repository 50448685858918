import { services } from "@cauldron/core";
import { REACT_APP_HOTJAR_ID, REACT_APP_ENV } from "./core/constants/env";

const { hotjar } = services;

if (process.env.NODE_ENV !== "test" && REACT_APP_ENV !== "local") {
  // Initialize Hotjar Tracking
  hotjar.initialize({
    hjid: REACT_APP_HOTJAR_ID,
    enabled: process.env.REACT_APP_ENABLE_HOTJAR === "true"
  });

  // Initialize Bugsnag reporting
  // bugsnag.initialize({
  //   bsId: REACT_APP_BUGSNAG_KEY,
  //   appVersion: REACT_APP_VERSION,
  //   releaseStage: REACT_APP_ENV,
  //   notifyReleaseStages: ["development", "qa", "uat", "production"],
  //   enabled: process.env.REACT_APP_ENABLE_EXTERNAL_LOGGING === "true"
  // });
}
