const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit}px 0`,
    minHeight: theme.spacing.unit
  },
  center: {
    textAlign: "center"
  }
});

export default styles;
