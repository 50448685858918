import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import { utils } from "@cauldron/core";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import styles from "./styles";
import { FileUpload } from "..";
import {
  TRANCHE_TILE_CARD,
  TRANCHE_TILE_CARD_TEXT
} from "../../pages/tranche.management/tranche.data.test.ids";

const { generateTestIds } = utils;

function TrancheTile({
  classes,
  i18n,
  iconValue,
  onFormSubmit,
  accept,
  validFileSize
}) {
  const generateIcon = (name, classes) => {
    if (name && name.length > 1) {
      return (
        <span className={classes.iconWrapper}>
          <Icon className={classes.contentIcon}>{name}</Icon>
        </span>
      );
    } else {
      return null;
    }
  };

  const handleFormSubmit = (isValidFile, fileMeta, result) => {
    onFormSubmit(isValidFile, fileMeta, result);
  };

  return (
    <Card
      className={classes.card}
      data-test={generateTestIds(TRANCHE_TILE_CARD)}
    >
      <div className={classes.contentWrapper}>
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <FileUpload
            id="tranche_picker"
            accept={accept}
            onFormSubmit={handleFormSubmit}
            inputClass={classes.filePickerWrapper}
            multiple={false}
            validFileSize={validFileSize} //in MB
          >
            {generateIcon(iconValue, classes)}
            <Typography
              className={classes.tileFooter}
              data-test={generateTestIds(TRANCHE_TILE_CARD_TEXT)}
            >
              {i18n.t`New tranche import`}
            </Typography>
          </FileUpload>
        </CardContent>
      </div>
    </Card>
  );
}

TrancheTile.propTypes = {
  classes: PropTypes.object.isRequired,
  iconValue: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  accept: PropTypes.array.isRequired,
  validFileSize: PropTypes.number.isRequired
};

const enhance = compose(
  withI18n(),
  withStyles(styles)
);

export default enhance(TrancheTile);
