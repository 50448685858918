import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles/select.styles";

function AlchemySelect({ classes, options, value, onChange, autoFocus }) {
  return (
    <form autoComplete="off">
      <FormControl className={classes.formControl}>
        <Select
          autoFocus={autoFocus}
          classes={{
            root: classes.root,
            selectMenu: classes.selectMenu
          }}
          value={value}
          onChange={e => onChange(e.target.value)}
          input={
            <Input
              classes={{
                root: classes.inputRoot,
                focused: "focused",
                disabled: "disabled",
                error: "error",
                underline: classes.inputUnderline
              }}
            />
          }
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value} className={classes.menuItem}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
}

AlchemySelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired
};

export default withStyles(styles)(AlchemySelect);
