export const NOTE_DRAFT_CREATE = "funded.loan.details/NOTE_DRAFT_CREATE";
export const NOTE_DRAFT_DELETE = "funded.loan.details/NOTE_DRAFT_DELETE";
export const NOTE_DRAFT_UPDATE = "funded.loan.details/NOTE_DRAFT_UPDATE";
export const NOTE_NEW_NOTE_SUBMIT = "funded.loan.details/DRAFT_SUBMIT";
export const NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS =
  "funded.loan.details/DRAFT_SUBMIT_SUCCESS";
export const NOTE_TAB_NEW_NOTE_SUBMIT_FAILURE =
  "funded.loan.details/DRAFT_SUBMIT_FAILURE";
export const NOTE_PARENT_LIST = "funded.loan.details/PARENT_LIST_UPDATE";

/**
 * Get the notes (comments) info based on the specific loan number
 */
export const FUNDED_LOAN_NOTES_FETCH = "funded.loan.details/NOTES_FETCH";
export const FUNDED_LOAN_NOTES_FETCH_SUCCESS =
  "funded.loan.details/NOTES_FETCH_SUCCESS";
export const FUNDED_LOAN_NOTES_FETCH_FAILURE =
  "funded.loan.details/NOTES_FETCH_FAILURE";

export const FUNDED_LOAN_PERSIST_NOTE =
  "funded.loan.details/FUNDED_LOAN_PERSIST_NOTE";

/**
 * Update Note Priority
 * loan comment update
 */
export const NOTE_PRIORITY_UPDATE =
  "funded.loan.details/NOTE_LOAN_PRIORITY_UPDATE";
export const NOTE_TAB_PRIORITY_UPDATE_SUCCESS =
  "funded.loan.details/UPDATE_PRIORITY_SUCCESS";
export const NOTE_TAB_PRIORITY_UPDATE_FAILURE =
  "funded.loan.details/UPDATE_PRIORITY_FAILURE";
