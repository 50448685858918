export const styles = theme => ({
  headerIconBtn: {
    width: "auto",
    height: 48,
    "& span p": {
      paddingLeft: 8
    },
    "&:hover": {
      cursor: "pointer"
    }
  },
  menuIcon: {
    color: theme.palette.grey["50"]
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main
    },
    paddingLeft: "16px",
    height: "23px",
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  menuItemLink: Object.assign({}, theme.typography.p2Dark, {
    textDecoration: "none",
    color: theme.palette.grey["900"]
  }),
  headerModuleLabel: {
    fontSize: 16,
    position: "relative",
    left: -5,
    color: theme.palette.grey["50"],
    "&:hover": {
      cursor: "pointer"
    }
  }
});
