const styles = theme => ({
  root: {
    paddingBottom: theme.spacing.unit * 6,
    width: "320px"
  },
  title: {
    ...theme.typography["h2Dark"],
    fontFamily: theme.typography.fontFamily,
    textAlign: "center",
    marginBottom: theme.spacing.unit * 3
  }
});

export default styles;
