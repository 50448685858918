"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _i18nAction = require("./i18n.action.types");

var initialState = {
  locale: "en"
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _i18nAction.LOCALE_CHANGE:
      return (0, _objectSpread2.default)({}, state, {
        locale: action.locale
      });

    case _i18nAction.LOCALE_RESET:
      return initialState;

    default:
      return state;
  }
};

exports.default = _default;