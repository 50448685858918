import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles/index";
import { styles } from "./styles/alert.dialog.styles";
import {
  DIALOG,
  TITLE,
  MESSAGE,
  ACTIONS,
  BUTTON,
  CONFIRM,
  CANCEL,
  getDataTestID
} from "../constants/data.test.ids";

/**
 * AlertDialog
 * @param title
 * @param message
 * @param agreeButtonText
 * @param disagreeButtonText
 * @param onAgree
 * @param isOpen
 * @param hasOkayButton
 * @param classes
 * @param onDialogClose
 * @returns {*}
 * @constructor
 */
function AlertDialog({
  title,
  message,
  agreeButtonText,
  disagreeButtonText,
  onAgree,
  isOpen,
  hasOkayButton,
  classes,
  onDialogClose,
  dataTestId,
  dataTestBuilder
}) {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        classes={{ paperWidthSm: classes.dialogWrapper }}
        data-test={getDataTestID(dataTestBuilder, dataTestId, DIALOG)}
      >
        <DialogTitle
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="alert-dialog-title"
          data-test={getDataTestID(dataTestBuilder, dataTestId, DIALOG, TITLE)}
        >
          <Typography className={classes.dialogTitle}>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            data-test={getDataTestID(
              dataTestBuilder,
              dataTestId,
              DIALOG,
              MESSAGE
            )}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className={classes.actionsWrapper}
          data-test={getDataTestID(
            dataTestBuilder,
            dataTestId,
            DIALOG,
            ACTIONS
          )}
        >
          <Button
            onClick={onDialogClose}
            color="primary"
            className={classes.cancelButton}
            data-test={getDataTestID(
              dataTestBuilder,
              dataTestId,
              DIALOG,
              BUTTON,
              CANCEL
            )}
          >
            {disagreeButtonText}
          </Button>
          {hasOkayButton && (
            <Button
              onClick={onAgree}
              color="primary"
              autoFocus
              className={classes.confirmButton}
              action={({ focusVisible }) => {
                focusVisible();
              }}
              data-test={getDataTestID(
                dataTestBuilder,
                dataTestId,
                DIALOG,
                BUTTON,
                CONFIRM
              )}
            >
              {agreeButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  agreeButtonText: PropTypes.string,
  disagreeButtonText: PropTypes.string,
  onAgree: PropTypes.func,
  onDialogClose: PropTypes.func,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(AlertDialog);
