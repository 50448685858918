import React from "react";
import MaskedInput from "react-text-mask";
import TextField from "../TextField";

/**
 * REF: https://github.com/text-mask/text-mask/tree/master/react#readme
 * DOCS: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
 */
export const PhoneMask = props => {
  const { inputRef, onChange, onBlur, ...other } = props;
  const mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; // prettier-ignore

  return (
    <MaskedInput
      onChange={onChange}
      onBlur={onBlur}
      ref={inputRef()}
      mask={mask}
      placeholderChar={"\u2000"}
      showMask={false}
      {...other}
    />
  );
};

/**
 * Wrapper around TextField to add masking capability
 * @param props
 * @constructor
 */
function PhoneField({ InputProps, ...rest }) {
  return (
    <TextField
      {...rest}
      InputProps={{ inputComponent: PhoneMask, ...InputProps }}
    />
  );
}

export default PhoneField;
