import {
  LOAN_APPLICATIONS_SEARCH,
  LOAN_APPLICATIONS_SEARCH_SUCCESS,
  LOAN_APPLICATIONS_SEARCH_FAILURE,
  LOAN_APPLICATIONS_SEARCH_TABLE_SETTINGS_UPDATE
} from "./loan.applications.action.types";

export function searchLoanApplications(searchCriteria) {
  return {
    type: LOAN_APPLICATIONS_SEARCH,
    searchCriteria
  };
}

export function searchLoanApplicationsSuccess(data) {
  return {
    type: LOAN_APPLICATIONS_SEARCH_SUCCESS,
    data: data
  };
}

export function searchLoanApplicationsFailure(error) {
  return {
    type: LOAN_APPLICATIONS_SEARCH_FAILURE,
    error
  };
}

export function updateSearchTableSettings(settings) {
  return {
    type: LOAN_APPLICATIONS_SEARCH_TABLE_SETTINGS_UPDATE,
    settings
  };
}
