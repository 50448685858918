import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { authenticateUser, getProfile, getToken } from "../../services/auth";
import { getFetchStatus } from "./engine/login.selectors";
import LoginComponent from "./components/login.component";
import Loader from "../../ui.library/Loader";

/**
 * LOGIN PAGE
 * this components shows the login page in which user need to provide credentials to login to the dashboard.
 * @type Container
 * @author Ehsan
 * @version 0.1.2
 */
function Login(props) {
  const { isFetching, authenticateUser, token } = props;
  return !isFetching && !token ? (
    <LoginComponent
      authenticateUser={authenticateUser}
      isFetching={isFetching}
    />
  ) : (
    <Loader centerScreen thickness={3.5} size={75} />
  );
}

Login.propTypes = {
  isFetching: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isFetching: getFetchStatus(state),
  token: getToken(state),
  profile: getProfile(state)
});

const mapDispatchToProps = dispatch => ({
  authenticateUser: bindActionCreators(authenticateUser, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
