import { actionTypes } from "./applications.action.types";

export const linkClient = (clientId, applicationId, eventHandlers) => ({
  type: actionTypes.APPLICATION_CLIENT_LINK_REQUEST,
  clientId,
  applicationId,
  eventHandlers
});

export const linkClientSuccess = data => ({
  type: actionTypes.APPLICATION_CLIENT_LINK_SUCCESS,
  data
});

export const linkClientFailure = error => ({
  type: actionTypes.APPLICATION_CLIENT_LINK_FAILURE,
  error
});
