import { utils } from "@cauldron/core";

const { makeActionTypes } = utils;

const namespace = "status";

/**
 * Generates actions
 * creates one for each REQUEST | SUCCESS | FAILURE
 */

export const actionTypes = {
  ...makeActionTypes(namespace, "LOAN_APPLICATION_STATUS_UPDATE")
};
