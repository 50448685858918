/**
 * Loan App Bank Service Request Code Resent Transform [SENT]
 * @param sendData
 * @returns {{progressaRequestCode: (string), emailAddress: null, phoneNumber: null}}
 */
export const transformSent = sendData => {
  let data = {
    progressaRequestCode: sendData.progressaRequestCode,
    emailAddress: sendData.isEmailCheck ? sendData.email : null,
    phoneNumber: null
  };
  if (sendData.isSMSCheck) {
    data.phoneNumber = { number: sendData.phone };
  }
  return data;
};
