import {
  ERROR_POST,
  ERROR_HANDLE,
  MESSAGE_SHOW,
  MESSAGE_HIDE
} from "./messenger.action.types";

export const postError = error => {
  return {
    type: ERROR_POST,
    error
  };
};

export const handleError = error => {
  return {
    type: ERROR_HANDLE,
    error
  };
};

export const showMessage = messenger => {
  return {
    type: MESSAGE_SHOW,
    messenger
  };
};

/**
 * Error Message Convenience Method
 * @param message
 * @returns {{type, messageObj}}
 */
export const showError = (message, options = {}) => {
  return showMessage({
    message,
    show: true,
    type: "error",
    ...options
  });
};

/**
 * Success Message Convenience Method
 * @param message
 * @returns {{type, messageObj}}
 */
export const showSuccess = (message, options = {}) =>
  showMessage({
    message,
    show: true,
    type: "success",
    ...options
  });

/**
 * Warning Message Convenience Method
 * @param message
 * @returns {{type, messageObj}}
 */
export const showWarning = (message, options = {}) =>
  showMessage({
    message,
    show: true,
    type: "warn",
    ...options
  });

/**
 * Info Message Convenience Method
 * @param message
 * @returns {{type, messageObj}}
 */
export const showInfo = (message, options = {}) =>
  showMessage({
    message,
    show: true,
    type: "info",
    ...options
  });

export const hideMessage = () => {
  return {
    type: MESSAGE_HIDE
  };
};
