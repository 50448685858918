"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.APPLICATION_SCORES = exports.APPLICATION_STATUS = exports.APPLICATION_REFERRAL = exports.APPLICATION_DEAL_LEAD = exports.APPLICATION_STATUS_HISTORY = exports.APPLICATION_REMOVE_CLIENT = exports.APPLICATION_PROFILE = exports.APPLICATION_OUTSTANDING_DOCS = exports.APPLICATION_NOTES = exports.APPLICATION_LOAN_PAYMENT = exports.APPLICATION_LOAN_PAYMENTS = exports.APPLICATION_EXTENDED = exports.APPLICATION_EMAILS = exports.APPLICATION_PREVIEW_DOCUMENT = exports.APPLICATION_DOCUMENT = exports.APPLICATION_DOCUMENTS = exports.APPLICATION_DISBURSEMENTS = exports.APPLICATION_DEBT_SERVICE_RATIO = exports.APPLICATION_AUDIT_HISTORY = exports.APPLICATION_ADD_CLIENT = exports.APPLICATION = exports.APPLICATIONS_USERS = exports.APPLICATIONS_SEARCH = exports.APPLICATIONS_NOTE_TEMPLATES = exports.APPLICATIONS = void 0;

var _api = require("./api.contants");
/**
 * API APPLICATIONS ENDPOINTS
 */
// APPLICATIONS


var APPLICATIONS = "".concat(_api.ROOT, "/applications");
exports.APPLICATIONS = APPLICATIONS;
var APPLICATIONS_NOTE_TEMPLATES = "".concat(APPLICATIONS, "/note-templates"); // prettier-ignore

exports.APPLICATIONS_NOTE_TEMPLATES = APPLICATIONS_NOTE_TEMPLATES;
var APPLICATIONS_SEARCH = "".concat(APPLICATIONS, "/search");
exports.APPLICATIONS_SEARCH = APPLICATIONS_SEARCH;
var APPLICATIONS_USERS = "".concat(APPLICATIONS, "/users"); // APPLICATION

exports.APPLICATIONS_USERS = APPLICATIONS_USERS;

var APPLICATION = function APPLICATION(appId) {
  return "".concat(APPLICATIONS, "/").concat(appId);
};

exports.APPLICATION = APPLICATION;

var APPLICATION_ADD_CLIENT = function APPLICATION_ADD_CLIENT(appId) {
  return "".concat(APPLICATION(appId), "/add-client");
}; // prettier-ignore


exports.APPLICATION_ADD_CLIENT = APPLICATION_ADD_CLIENT;

var APPLICATION_AUDIT_HISTORY = function APPLICATION_AUDIT_HISTORY(appId) {
  return "".concat(APPLICATION(appId), "/audit-history");
}; // prettier-ignore


exports.APPLICATION_AUDIT_HISTORY = APPLICATION_AUDIT_HISTORY;

var APPLICATION_DEBT_SERVICE_RATIO = function APPLICATION_DEBT_SERVICE_RATIO(appId) {
  return "".concat(APPLICATION(appId), "/debt-service-ratio");
}; // prettier-ignore


exports.APPLICATION_DEBT_SERVICE_RATIO = APPLICATION_DEBT_SERVICE_RATIO;

var APPLICATION_DISBURSEMENTS = function APPLICATION_DISBURSEMENTS(appId) {
  return "".concat(APPLICATION(appId), "/disbursements");
}; // prettier-ignore


exports.APPLICATION_DISBURSEMENTS = APPLICATION_DISBURSEMENTS;

var APPLICATION_DOCUMENTS = function APPLICATION_DOCUMENTS(appId) {
  return "".concat(APPLICATION(appId), "/documents");
};

exports.APPLICATION_DOCUMENTS = APPLICATION_DOCUMENTS;

var APPLICATION_DOCUMENT = function APPLICATION_DOCUMENT(appId, docId) {
  return "".concat(APPLICATION_DOCUMENTS(appId), "/").concat(docId);
}; // prettier-ignore


exports.APPLICATION_DOCUMENT = APPLICATION_DOCUMENT;

var APPLICATION_PREVIEW_DOCUMENT = function APPLICATION_PREVIEW_DOCUMENT(appId, docId) {
  return "".concat(APPLICATIONS, "/").concat(appId, "/documents/").concat(docId);
}; // prettier-ignore


exports.APPLICATION_PREVIEW_DOCUMENT = APPLICATION_PREVIEW_DOCUMENT;

var APPLICATION_EMAILS = function APPLICATION_EMAILS(appId) {
  return "".concat(APPLICATION(appId), "/emails");
};

exports.APPLICATION_EMAILS = APPLICATION_EMAILS;

var APPLICATION_EXTENDED = function APPLICATION_EXTENDED(appId) {
  return "".concat(APPLICATION(appId), "/extended");
};

exports.APPLICATION_EXTENDED = APPLICATION_EXTENDED;

var APPLICATION_LOAN_PAYMENTS = function APPLICATION_LOAN_PAYMENTS(appId) {
  return "".concat(APPLICATION(appId), "/loan-payments");
}; // prettier-ignore


exports.APPLICATION_LOAN_PAYMENTS = APPLICATION_LOAN_PAYMENTS;

var APPLICATION_LOAN_PAYMENT = function APPLICATION_LOAN_PAYMENT(appId, payId) {
  return "".concat(APPLICATION_LOAN_PAYMENTS(appId), "/").concat(payId);
}; // prettier-ignore


exports.APPLICATION_LOAN_PAYMENT = APPLICATION_LOAN_PAYMENT;

var APPLICATION_NOTES = function APPLICATION_NOTES(appId) {
  return "".concat(APPLICATION(appId), "/notes");
};

exports.APPLICATION_NOTES = APPLICATION_NOTES;

var APPLICATION_OUTSTANDING_DOCS = function APPLICATION_OUTSTANDING_DOCS(appId) {
  return "".concat(APPLICATION(appId), "/outstanding-documents");
}; // prettier-ignore


exports.APPLICATION_OUTSTANDING_DOCS = APPLICATION_OUTSTANDING_DOCS;

var APPLICATION_PROFILE = function APPLICATION_PROFILE(appId) {
  return "".concat(APPLICATION(appId), "/profile");
};

exports.APPLICATION_PROFILE = APPLICATION_PROFILE;

var APPLICATION_REMOVE_CLIENT = function APPLICATION_REMOVE_CLIENT(appId) {
  return "".concat(APPLICATION(appId), "/remove-client");
}; // prettier-ignore


exports.APPLICATION_REMOVE_CLIENT = APPLICATION_REMOVE_CLIENT;

var APPLICATION_STATUS_HISTORY = function APPLICATION_STATUS_HISTORY(appId) {
  return "".concat(APPLICATION(appId), "/status-history");
}; // prettier-ignore


exports.APPLICATION_STATUS_HISTORY = APPLICATION_STATUS_HISTORY;

var APPLICATION_DEAL_LEAD = function APPLICATION_DEAL_LEAD(appId) {
  return "".concat(APPLICATION(appId), "/owner");
}; // prettier-ignore


exports.APPLICATION_DEAL_LEAD = APPLICATION_DEAL_LEAD;

var APPLICATION_REFERRAL = function APPLICATION_REFERRAL(appId) {
  return "".concat(APPLICATION(appId), "/referral");
}; // prettier-ignore


exports.APPLICATION_REFERRAL = APPLICATION_REFERRAL;

var APPLICATION_STATUS = function APPLICATION_STATUS(appId) {
  return "".concat(APPLICATION(appId), "/status");
}; // prettier-ignore


exports.APPLICATION_STATUS = APPLICATION_STATUS;

var APPLICATION_SCORES = function APPLICATION_SCORES(appId) {
  return "".concat(APPLICATION(appId), "/decision-actions");
}; // prettier-ignore


exports.APPLICATION_SCORES = APPLICATION_SCORES;