import { i18nMark } from "@lingui/react";

export const APP_SECTIONS = {
  DASHBOARD: "dashboard",
  FUNDED_NOTES: "fundedLoanNotes",
  FUNDED_DETAILS: "fundedLoanDetails",
  FUNDED_PAYMENTS: "fundedLoanPayments",
  FUNDED_DOCS: "fundedLoanDocs",
  FUNDED_DETAILS_STATIC_CODES: "fundedLoansStaticCodes",
  LOAN_APPS_DETAILS: "loanApplicationDetails",
  TRANCHE: "tranche",
  LOAN_APPS_NOTES: "loanApplicationNotes",
  LOAN_APPS_NOTE_TEMPLATES: "loanApplicationNoteTemplates",
  LOAN_APPS_REQUEST_SUBMIT: "loanApplicationRequestSubmit",
  LOAN_APPS_STATUS: "loanApplicationStatus"
};

export const ERRORS = {
  "404": {
    icon: "link_off",
    title: i18nMark("Oops..."),
    message: i18nMark(
      "Unfortunately, this page does not exist. Please check your URL or try these helpful links."
    )
  },
  "400Default": {
    icon: "error_outline",
    title: i18nMark("Invalid request"),
    message: i18nMark(
      "A request has been made that could not be understood. If you think this is in error, please contact us at helpdesk@progressa.com."
    )
  },
  "500Default": {
    icon: "error_outline",
    title: i18nMark("Server error"),
    message: i18nMark(
      "The server has encountered a temporary error. Your request could not be completed."
    )
  },
  "-1": {
    icon: "error_outline",
    title: i18nMark("Something went wrong!"),
    message: i18nMark(
      "The server has encountered a temporary error. Your request could not be completed."
    )
  }
};
