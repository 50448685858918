"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "PersonName";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  salutation: null,
  first: null,
  middle: null,
  last: null,
  suffix: null
};
/**
 * Protects data from change in data type
 */

var schema = {
  salutation: _propTypes.default.string,
  first: _propTypes.default.string,
  middle: _propTypes.default.string,
  last: _propTypes.default.string,
  suffix: _propTypes.default.string
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var transformSend = function transformSend(data) {
  return data;
};

var _default = {
  defaultProps: defaultProps,
  schema: schema,
  transformReceived: transformReceived,
  transformSend: transformSend
};
exports.default = _default;