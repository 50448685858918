/**
 * Get the docs info based on the specific loan and clients
 */
export const FUNDED_LOAN_DETAILS_DOCS_FETCH = "funded.loan.details/DOCS_FETCH";
export const FUNDED_LOAN_DETAILS_DOCS_FETCH_SUCCESS =
  "funded.loan.details/DOCS_FETCH_SUCCESS";
export const FUNDED_LOAN_DETAILS_DOCS_FETCH_COMPLETED =
  "funded.loan.details/DOCS_FETCH_COMPLETED";

/**
 * Docs UI update actions. e.g. update selected index and update docs count
 */
export const FUNDED_LOAN_DETAILS_DOCS_UPDATE_SELECTED_INDEX =
  "funded.loan.details/DOCS_UPDATE_SELECTED_INDEX";

/**
 * Get pay off amount based on specific loan number and pay off date
 */
export const FUNDED_LOAN_DETAILS_PAYOFF_FETCH =
  "funded.loan.details/PAYOFF_FETCH";
export const FUNDED_LOAN_DETAILS_PAYOFF_FETCH_SUCCESS =
  "funded.loan.details/PAYOFF_FETCH_SUCCESS";
export const FUNDED_LOAN_DETAILS_PAYOFF_FETCH_FAILURE =
  "funded.loan.details/PAYOFF_FETCH_FAILURE";

/**
 * Get payments detail based on loan id
 */
export const FUNDED_LOAN_DETAILS_PAYMENTS_FETCH =
  "funded.loan.details/PAYMENTS_FETCH";
export const FUNDED_LOAN_DETAILS_PAYMENTS_FETCH_SUCCESS =
  "funded.loan.details/PAYMENTS_FETCH_SUCCESS";
export const FUNDED_LOAN_DETAILS_PAYMENTS_FETCH_FAILURE =
  "funded.loan.details/PAYMENTS_FETCH_FAILURE";

/**
 * Add Note / Note Edit mode
 */
export const NOTE_TAB_DRAFT_UPDATE = "funded.loan.details/DRAFT_UPDATE";
export const NOTE_TAB_PERSIST_BODY = "funded.loan.details/PERSIST_BODY";
export const NOTE_TAB_PERSIST_REMOVE_DATA =
  "funded.loan.details/PERSIST_REMOVE";
export const NOTE_TAB_PERSIST_DATE = "funded.loan.details/PERSIST_DATE";
export const NOTE_TAB_PERSIST_PRIORITY = "funded.loan.details/PERSIST_PRIORITY";
export const NOTE_TAB_PERSIST_REMOVE_PRIORITY =
  "funded.loan.details/PERSIST_PRIORITY_REMOVE";
export const NOTE_TAB_PERSIST_REMOVE_SELECTED_DATE =
  "funded.loan.details/PERSIST_REMOVE_DATE";

/**
 * Update Note Priority
 * Client comment update
 */
export const NOTE_TAB_CLIENT_PRIORITY_UPDATE =
  "funded.loan.details/NOTE_CLIENT_PRIORITY_UPDATE";

/**
 * Update Note expiry
 * Client comment update
 */
export const NOTE_TAB_CLIENT_EXPIRY_UPDATE =
  "funded.loan.details/NOTE_CLIENT_EXPIRY_UPDATE";

/**
 * Update Note expiry
 * Loan comment update
 */
export const NOTE_TAB_LOAN_EXPIRY_UPDATE =
  "funded.loan.details/NOTE_LOAN_EXPIRY_UPDATE";

/**
 * Remove client Note Priority
 */
export const NOTE_TAB_CLIENT_PRIORITY_REMOVE =
  "funded.loan.details/NOTE_CLIENT_PRIORITY_REMOVE";

/**
 * Remove loan Note Priority
 */
export const NOTE_TAB_LOAN_PRIORITY_REMOVE =
  "funded.loan.details/NOTE_LOAN_PRIORITY_REMOVE";

export const NOTE_TAB_UPDATED_NOTE_REMOVE =
  "funded.loan.details/UPDATED_NOTE_REMOVE";
