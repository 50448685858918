import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles/index";
import AddCircle from "@material-ui/icons/AddCircle";
import { styles } from "./styles/add.button.styles";
import { IconTextButton } from "../../index";

/**
 * AddButton
 * @param classes
 * @param icon
 * @param onClick
 * @param buttonText
 * @param disabled
 * @param dataTestId
 * @param dataTestBuilder
 * @param rest
 * @returns {*}
 * @constructor
 */
function AddButton({
  classes,
  icon,
  onClick,
  buttonText,
  disabled,
  dataTestId,
  buttonRef,
  dataTestBuilder,
  ...rest
}) {
  const defaultIcon = (
    <AddCircle
      className={!disabled ? classes.addIcon : classes.addIconDisabled}
    />
  );

  return (
    <IconTextButton
      dataTestId={dataTestId}
      icon={icon || defaultIcon}
      onClick={!disabled ? onClick : () => {}}
      buttonText={buttonText}
      disabled={disabled}
      buttonRef={buttonRef}
      dataTestBuilder={dataTestBuilder}
      {...rest}
    />
  );
}

AddButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(AddButton);
