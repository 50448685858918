import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import classNames from "classnames";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import styles from "./styles/text.button.styles";

const getDataTestId = (dataTestBuilder, dataTestId) => {
  return dataTestId && dataTestBuilder ? dataTestBuilder(dataTestId) : null;
};

/**
 * Basic button with text
 * - Can take a children *before* text
 * takes all props from Button - see https://material-ui.com/api/button/
 */
const TextButton = props => {
  const {
    buttonText,
    onClick,
    children,
    classes,
    importance,
    dataTestId,
    dataTestBuilder,
    buttonWidth,
    className,
    ...rest
  } = props;

  return (
    <Button
      onClick={onClick}
      variant="contained"
      className={classNames([
        classes[importance.toLowerCase()],
        classes.root,
        buttonWidth ? classes[buttonWidth] : "",
        className
      ])}
      data-test={getDataTestId(dataTestBuilder, dataTestId)}
      {...rest}
    >
      {children}
      {buttonText}
    </Button>
  );
};

TextButton.defaultProps = {
  buttonText: "",
  importance: "SECONDARY",
  dataTestId: null,
  dataTestBuilder: null
};

TextButton.propTypes = {
  buttonText: PropTypes.string,
  importance: PropTypes.oneOf(["CRITICAL", "PRIMARY", "SECONDARY", "LINK"]),
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func,
  buttonWidth: PropTypes.oneOf(["md", "lg"])
};

const enhance = compose(withStyles(styles));

export default enhance(TextButton);
