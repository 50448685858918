import { put, takeLatest, fork, call, all } from "redux-saga/effects";
import { http } from "../../../../../../../services/http/http.service";
import {
  LOAN_REQUEST_NOTE_TEMPLATES_FETCH,
  LOAN_REQUEST_SUBMIT
} from "./loan.request.action.types";
import {
  fetchNoteTemplatesSuccess,
  fetchNoteTemplatesFailure,
  loanRequestSubmitSuccess,
  loanRequestSubmitFailure
} from "./loan.request.actions";
import { LOAN_REQUEST_NOTE } from "../../../../../../constants/note.tags";
import {
  addSectionError,
  clearSectionError,
  APP_SECTIONS
} from "../../../../../../../services/error.handler";

/**
 * fetch note templates
 */
export const fetchNoteTemplates = () => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.get, API.APPLICATIONS_NOTE_TEMPLATES);
    },
    success: data =>
      all([
        put(fetchNoteTemplatesSuccess(data)),
        put(clearSectionError(APP_SECTIONS.LOAN_APPS_NOTE_TEMPLATES))
      ]),
    error: error =>
      all([
        put(fetchNoteTemplatesFailure(error)),
        put(addSectionError(APP_SECTIONS.LOAN_APPS_NOTE_TEMPLATES, error))
      ])
  });
};

/**
 * post a note (loan request)
 */
export const submitLoanRequest = action => {
  const { message, applicationId } = action.data;
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.APPLICATION_NOTES(applicationId), {
        title: "",
        comment: message,
        published: true,
        tags: [LOAN_REQUEST_NOTE]
      });
    },
    success: data =>
      all([
        put(loanRequestSubmitSuccess(data)),
        put(clearSectionError(APP_SECTIONS.LOAN_APPS_REQUEST_SUBMIT))
      ]),
    error: error =>
      all([
        put(loanRequestSubmitFailure(error)),
        put(addSectionError(APP_SECTIONS.LOAN_APPS_REQUEST_SUBMIT))
      ])
  });
};

function* watchFundedLoanDetailsAsync() {
  yield takeLatest(LOAN_REQUEST_NOTE_TEMPLATES_FETCH, fetchNoteTemplates);
  yield takeLatest(LOAN_REQUEST_SUBMIT, submitLoanRequest);
}

export default [fork(watchFundedLoanDetailsAsync)];
