/**
 * Alchemy select styles
 * @author rrive
 * @param theme {object}
 */
const styles = theme => ({
  root: {
    width: "100%"
  },
  formSelectRootError: {
    fontSize: "14px",
    "& label": {
      zIndex: 1,
      fontSize: "14px",
      marginTop: 0,
      background: theme.palette.lightGrey[100],
      "&[data-shrink='false']": {
        transform: "translate(0, 17px) scale(1)"
      },
      "&[data-shrink='true']": {
        transform: "translate(0, 3px) scale(.85)"
      }
    },
    "& label + div": {
      marginTop: "4px",
      paddingTop: "12px"
    },
    "& button": {
      padding: "4px"
    },
    "& > div": {
      marginTop: 0
    },
    "& p": {
      color: theme.palette.grey[600],
      lineHeight: "16px",
      fontSize: "12px"
    }
  },
  formSelectRoot: {
    fontSize: "14px",
    "& label": {
      zIndex: 1,
      fontSize: "14px",
      lineHeight: "16px",
      marginTop: 0,
      background: theme.palette.lightGrey[100],
      "&[data-shrink='false']": {
        transform: "translate(0, 17px) scale(1)",
        color: theme.palette.lightGrey[900]
      },
      "&[data-shrink='true']": {
        transform: "translate(0, 3px) scale(.85)",
        color: theme.palette.grey[900]
      }
    },
    "& label + div": {
      marginTop: "4px",
      paddingTop: "12px"
    },
    "& button": {
      padding: "4px"
    },
    "& > div": {
      marginTop: 0
    },
    "& p": {
      color: theme.palette.grey[600],
      lineHeight: "16px",
      fontSize: "12px"
    }
  },
  inputRoot: {
    color: theme.palette.grey[900],
    background: theme.palette.lightGrey[100],
    boxShadow: `-12px 0px 0px 0px ${
      theme.palette.lightGrey[100]
    }, 12px 0px 0px 0px ${theme.palette.lightGrey[100]}`,
    borderRadius: 2,
    fontSize: "14px",
    lineHeight: "16px",
    "& input": {
      marginTop: "1px",
      marginBottom: "-1px",
      padding: "3px 0"
    },
    "& > div": {
      marginTop: "-7px"
    },
    // Deals with input field errors
    "&.error": {
      color: theme.palette.error["main"]
    },
    // Deals with input field focused
    "&.focused:not(.error)": {
      color: theme.palette.primary["main"]
    },
    // Deals with input field disabled
    "&.disabled:not(.error)": {
      color: theme.palette.grey[900],
      background: "transparent",
      boxShadow: "none"
    },
    "& button": {
      "&[aria-label='Clear selection']": {
        "& svg": {
          width: "19px",
          height: "19px"
        }
      }
    }
  },
  inputUnderline: {
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary["main"]}`
    },
    "&:before": {
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    "&:hover:not(.disabled):not(.focused):not(.error):before": {
      borderBottom: `2px solid ${theme.palette.grey[400]}`
    },
    "&.disabled:before": {
      borderBottom: `1px dashed ${theme.palette.grey[400]}`
    }
  },
  withPadding: {
    padding: "4px 0"
  }
});

export default styles;
