import { put, call, fork, takeLatest, all } from "redux-saga/effects";
import { http } from "../../../../../../../services/http/http.service";
import {
  LOAN_APPLICATION_BANK_REQUEST_FETCH,
  LOAN_APPLICATION_BANK_REQUEST_SEND,
  LOAN_APPLICATION_BANK_REQUEST_RESEND
} from "./bank.requests.types";
import {
  fetchLoanApplicationBankRequestFailure,
  fetchLoanApplicationBankRequestSuccess,
  sendLoanApplicationBankRequestSuccess,
  resendLoanApplicationBankRequestSuccess
} from "./bank.requests.actions";
import {
  addFormSectionError,
  deleteCachedForm,
  isFetchingForm
} from "../../../../../../../services/forms/forms.actions";
import * as messengerActions from "../../../../../../../services/messenger/messenger.actions";
import { SUCCESS_MESSAGES } from "../../../../../../../core/constants";
import { transformRequestCodeSent } from "../../../../../../../models/bankService/requestCodes";
import { transformRequestCodeResent } from "../../../../../../../models/bankService/resend";
import { transformBankReportSearchReceived } from "../../../../../../../models/bankReport/search";

export const fetchBankRequests = action => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.BANK_REPORTS_SEARCH, {
        applicationId: action.applicationId
      });
    },
    success: data => put(fetchLoanApplicationBankRequestSuccess(data)),
    error: error => put(fetchLoanApplicationBankRequestFailure(error)),
    transformReceiveData: transformBankReportSearchReceived
  });
};

export const sendBankRequests = action => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.BANK_SERVICE_REQUEST_CODE,
        transformRequestCodeSent(
          action.applicationId,
          action.clientId,
          action.data
        )
      );
    },
    success: data =>
      all([
        put(sendLoanApplicationBankRequestSuccess(data)),
        put(deleteCachedForm(action.data.formId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.BANK_REQUEST.SEND))
      ]),
    error: error =>
      all([
        put(isFetchingForm(action.data.formId, false)),
        put(addFormSectionError(action.data.formId, error))
      ])
  });
};

export const resendBankRequests = action => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.BANK_SERVICE_REQUEST_CODE_RESEND,
        transformRequestCodeResent(action.data)
      );
    },
    success: data =>
      all([
        put(resendLoanApplicationBankRequestSuccess(data)),
        put(deleteCachedForm(action.data.formId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.BANK_REQUEST.SEND))
      ]),
    error: error =>
      all([
        put(isFetchingForm(action.data.formId, false)),
        put(addFormSectionError(action.data.formId, error))
      ])
  });
};

export function* watchBankRequests() {
  yield takeLatest(LOAN_APPLICATION_BANK_REQUEST_FETCH, fetchBankRequests);
  yield takeLatest(LOAN_APPLICATION_BANK_REQUEST_SEND, sendBankRequests);
  yield takeLatest(LOAN_APPLICATION_BANK_REQUEST_RESEND, resendBankRequests);
}

export default [fork(watchBankRequests)];
