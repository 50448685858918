import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import styles from "./styles/layout.fieldset.styles";

function Fieldset({ classes, className, stacked, children, centered }) {
  const classArray = [
    classes.set,
    stacked && classes.stacked,
    centered && classes.centered,
    className
  ];
  return <div className={classNames(classArray)}>{children}</div>;
}

Fieldset.defaultProps = {
  centered: false,
  stacked: false
};

Fieldset.propTypes = {
  classes: PropTypes.object.isRequired,
  centered: PropTypes.bool,
  stacked: PropTypes.bool
};

export default withStyles(styles)(Fieldset);
