import { normalize } from "../../../../model.utils";
import { defaultProps, schema, MODEL_NAME } from "./model";

/**
 * Client Transform [RECEIVED]
 * Update name
 * transform data -> before it reaches the store
 * @param {*} rawData
 * @author Ehsan
 */

export const transformReceived = rawData =>
  normalize(rawData, defaultProps, schema, MODEL_NAME);
