"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "siteLinks", {
  enumerable: true,
  get: function get() {
    return _site.default;
  }
});
Object.defineProperty(exports, "blogLinks", {
  enumerable: true,
  get: function get() {
    return _site.blogLinks;
  }
});
Object.defineProperty(exports, "socialMediaLinks", {
  enumerable: true,
  get: function get() {
    return _socialMedia.default;
  }
});
Object.defineProperty(exports, "copyright", {
  enumerable: true,
  get: function get() {
    return _copyright.default;
  }
});
Object.defineProperty(exports, "creditPullConsent", {
  enumerable: true,
  get: function get() {
    return _credit.default;
  }
});
Object.defineProperty(exports, "newsletterConsent", {
  enumerable: true,
  get: function get() {
    return _newsletter.default;
  }
});
Object.defineProperty(exports, "termsAndConditionsConsent", {
  enumerable: true,
  get: function get() {
    return _terms.default;
  }
});

var _site = _interopRequireWildcard(require("./site.links"));

var _socialMedia = _interopRequireDefault(require("./social.media.links"));

var _copyright = _interopRequireDefault(require("./copyright"));

var _credit = _interopRequireDefault(require("./consents/credit.pull"));

var _newsletter = _interopRequireDefault(require("./consents/newsletter"));

var _terms = _interopRequireDefault(require("./consents/terms.conditions"));