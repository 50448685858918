import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";
import ReactTable from "react-table";
import classNames from "classnames";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles/table.styles";

// LOCAL COMPONENTS
import FormatText from "../FormatText";
import FormatObject from "../FormatObject";
import SortButton from "./components/sort.button";
import NoDataMessage from "./components/no.data.message";
import Pagination from "./components/pagination";
import TableLoader from "./components/table.loader";

/**
 * DATA TABLE
 * Data table
 * Reference: https://www.npmjs.com/package/react-table
 * @type Component
 * @author Ryan Rivera
 * @version 0.1.0
 */
class AlchemyTable extends PureComponent {
  extractColumns = columns => {
    const { showFooter, Cell: TableCell = null } = this.props;
    return columns.map(col => ({
      Cell: TableCell || this.formatCell,
      Footer: showFooter ? this.formatFooter : null,
      Header: this.formatHeader,
      accessor: col.id,
      ...col
    }));
  };

  formatFooter = ({ value, column }) => {
    const { format, options, footer } = column;
    const colOptions = Object.assign({}, this.getTableOptions(), options);

    if (footer && typeof footer === "function") {
      return footer(value, column);
    }

    // Given that the value is not an object
    // and value is null or undefined
    // render value as string
    const renderAsString = typeof value !== "object" || isNil(value);

    return (
      <FormatText
        renderAsString={renderAsString}
        format={format}
        options={colOptions}
        noValue=" "
        value={footer}
      />
    );
  };

  formatCell = cellInfo => {
    const { value, column, row } = cellInfo;

    const { _original } = row;
    const { format, noValue, options } = column;

    // column.options
    const colOptions = { ...this.getTableOptions(), ...options };

    // Given that the value is not an object
    // and value is null or undefined
    // render value as string
    const renderAsString = typeof value !== "object" || isNil(value);
    const RenderValue = renderAsString ? FormatText : FormatObject;

    return (
      <RenderValue
        format={format}
        options={colOptions}
        noValue={noValue}
        row={_original}
        label={column.label}
        value={value}
        onChange={colOptions.onChange}
      />
    );
  };

  formatHeader = ({ column }) => {
    const { label, options, format } = column;
    const { dataTestId } = this.props;
    return (
      <SortButton
        label={label}
        options={options}
        format={format}
        dataTestId={dataTestId}
      />
    );
  };

  getTableOptions = () => {
    // Add and return future global table options here...
    const { searchHighlight, searchedTerm } = this.props;
    return { searchHighlight, searchedTerm };
  };

  StyledNoDataMessage = props => {
    const { noDataType } = this.props;
    return <NoDataMessage type={noDataType} {...props} />;
  };

  render() {
    const {
      classes,
      data,
      columns,
      defaultPageSize,
      sidePadding,
      permaScroll,
      dataTestId,
      dataTestBuilder,
      ...overrides
    } = this.props;
    return (
      <ReactTable
        data={data}
        columns={this.extractColumns(columns)}
        className={classNames([
          "AlchemyTable",
          "-striped -highlight",
          sidePadding ? "pad-sides" : "",
          permaScroll ? "perma-scroll" : ""
        ])}
        sortable
        multiSort={false}
        resizable
        showPageJump={false}
        showPagination={false}
        defaultPageSize={defaultPageSize}
        minRows={1}
        NoDataComponent={this.StyledNoDataMessage}
        LoadingComponent={TableLoader}
        PaginationComponent={Pagination}
        permaScroll={false}
        {...overrides}
      >
        {(state, makeTable) => {
          return (
            <div
              data-test={dataTestBuilder && dataTestBuilder(dataTestId)}
              className={classes.dataTable}
            >
              {makeTable()}
            </div>
          );
        }}
      </ReactTable>
    );
  }
}

AlchemyTable.defaultProps = {
  data: [],
  searchedTerm: "",
  defaultPageSize: 50,
  pageSizeOptions: [25, 50, 100],
  searchHighlight: false,
  loading: false,
  sidePadding: false,
  permaScroll: false,
  noDataType: "info"
};

AlchemyTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.array,
  defaultPageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      format: PropTypes.string,
      sortable: PropTypes.bool,
      width: PropTypes.number,
      options: PropTypes.object
    }).isRequired
  ).isRequired,
  showPagination: PropTypes.bool,
  sidePadding: PropTypes.bool,
  permaScroll: PropTypes.bool,
  searchedTerm: PropTypes.string,
  searchHighlight: PropTypes.bool,
  loading: PropTypes.bool,
  noDataType: PropTypes.string,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(AlchemyTable);
