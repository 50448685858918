import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles/field.group.styles";
import { AlchemyTypography } from "../AlchemyTypography";

/**
 * FIELD GROUP
 * this component is the content field group container inside the detail expander
 * @type Component
 * @author Stanley
 * @version 1.0.0
 * @param {string} contentKey the key string for the content
 * @param {func || string || number} Content dynamic content element or string
 */
class FieldGroup extends React.Component {
  renderContent = Content => {
    const {
      classes,
      dataTestId,
      dataTestBuilder,
      suppressTracking
    } = this.props;
    if (typeof Content === "string" || typeof Content === "number") {
      return (
        <AlchemyTypography
          className={classes.itemContentTypography}
          dataTestId={dataTestBuilder && dataTestBuilder(dataTestId, "value")}
          dataTestBuilder={dataTestBuilder}
          suppressTracking={suppressTracking}
        >
          {Content}
        </AlchemyTypography>
      );
    } else if (typeof Content === "function") {
      return <Content />;
    } else {
      return (
        <AlchemyTypography
          className={classes.itemContentTypography}
          dataTestId={dataTestBuilder && dataTestBuilder(dataTestId, "value")}
          dataTestBuilder={dataTestBuilder}
          suppressTracking={suppressTracking}
        >
          -
        </AlchemyTypography>
      );
    }
  };

  render() {
    const {
      classes,
      contentKey,
      Content,
      hasLetterWrap,
      required,
      dataTestId,
      dataTestBuilder,
      className
    } = this.props;

    return (
      <div className={classNames([classes.item, className.root])}>
        <span className={classes.itemKey}>
          <AlchemyTypography
            className={classes.itemKeyTypography}
            dataTestId={dataTestBuilder && dataTestBuilder(dataTestId, "label")}
            dataTestBuilder={dataTestBuilder}
          >
            {contentKey}
            {required ? " *" : ""}
          </AlchemyTypography>
        </span>
        <span
          className={
            hasLetterWrap ? classes.itemContentLetterWrap : classes.itemContent
          }
        >
          {this.renderContent(Content)}
        </span>
      </div>
    );
  }
}

FieldGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.shape({ root: PropTypes.string }),
  contentKey: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  Content: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.number
  ]),
  hasLetterWrap: PropTypes.bool,
  required: PropTypes.bool,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func,
  suppressTracking: PropTypes.bool
};

FieldGroup.defaultProps = {
  dataTestId: "",
  className: {
    root: ""
  }
};

export default withStyles(styles)(FieldGroup);
