const styles = () => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto"
  }
});

export default styles;
