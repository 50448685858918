import { utils } from "@cauldron/core";
import isEmpty from "lodash/isEmpty";
import intersection from "lodash/intersection";
import { REACT_APP_LOAN_SOURCE } from "../../core/constants/env";
import { ESCALATED_LOAN_STATUSES } from "../../core/constants/ui";
import { DUPLICATE_PHONE_ERROR } from "../constants/error.messages";

const { formatSentenceCase } = utils;

/**
 * Determine if a loan is considered in an escalated state by comparing
 * status codes from the loan to known escalated statuses
 * @param statusCodes {array}
 * @returns {boolean}
 */
export const isFundedLoanEscalated = (statusCodes = []) => {
  const statuses = statusCodes.map(code => code.toUpperCase());
  return !isEmpty(intersection(statuses, ESCALATED_LOAN_STATUSES));
};

/**
 * mapStatusCodeToDisplayMessage
 * @param code
 * @returns {string}
 */
export const mapStatusCodeToDisplayMessage = code => {
  if (code === 1130) {
    return DUPLICATE_PHONE_ERROR;
  }
  return "Failure";
};

/**
 * MAP URL
 * Adds link to table row to link to either NLS or LMS
 * @param item
 * @returns {*}
 */
export const createUrl = item => {
  if (!item.url) {
    item.url = null;
    if (item.source === "NLS" && item.loanId) {
      // EXAMPLE:
      // https://power.progressa.rocks/funded-loans/422e9a3c-f73c-e611-8126-06cab7997043
      item.url = `/funded-loans/${item.loanId}`;
    } else if (item.source === "LMS" && item.loanId) {
      // EXAMPLE:
      // https://www.lms.rocks/loans/detail?LoanAgreementID=422e9a3c-f73c-e611-8126-06cab7997043
      item.url = `${REACT_APP_LOAN_SOURCE}/loans/detail?LoanAgreementID=${
        item.loanId
      }`;
      item.target = "_blank";
    }
  }
  return item;
};

/**
 * MAP FRIENDLY STATUS
 * Maps friendly status to UI
 * @param item
 * @returns {*}
 */
export const createFriendlyStatus = item => {
  item.isEscalated = false;
  const s = [`${formatSentenceCase(item.status.status)}`];
  if (item.status && item.status.codes && item.status.codes.length > 0) {
    s.push(
      `${item.status.codes
        .map(code => {
          return formatSentenceCase(code);
        })
        .join(", ")}`
    );
    item.isEscalated = isFundedLoanEscalated(item.status.codes);
  }
  item.friendlyStatus = { text: s.join(": ") };
  return item;
};
