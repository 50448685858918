import {
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITE_FORM_CACHE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_TITLE_CANCEL,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_CACHE_VALUE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_FAILURE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_FORM_CACHE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_MARITAL_CANCEL,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_CACHE_VALUE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_FAILURE,
  FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CACHE,
  FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CLEAN,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_FAILURE,
  FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CACHE,
  FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CLEAN,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_FAILURE,
  FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CACHE,
  FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CLEAN,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_FAILURE,
  FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CACHE,
  FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CLEAN,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_FAILURE,
  FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM,
  FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM_CLEAR
} from "../action.types/forms.action.types";

// FORM ACTIONS - TITLE
export function cachePersoanlDetailsTitleForm(form) {
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITE_FORM_CACHE,
    data: form
  };
}

export function cancelEditClientTitle(clientId, field) {
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_TITLE_CANCEL,
    clientId,
    field
  };
}

export function cacheEditClientTitle(data) {
  const { id, values } = data;
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_CACHE_VALUE,
    id,
    values
  };
}

export function updateClientTitle(data) {
  const { id, values } = data;
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE,
    clientId: id,
    values,
    field: data.field
  };
}

export function updateClientTitleSuccess(clientId, data) {
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_SUCCESS,
    clientId,
    data
  };
}

export function updateClientTitleFailure(clientId, error) {
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_FAILURE,
    clientId,
    error
  };
}

// FORM ACTIONS - MARITAL STATUS
export function cachePersoanlDetailsMaritalForm(form) {
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_FORM_CACHE,
    data: form
  };
}

export function cancelEditClientMarital(clientId, field) {
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_MARITAL_CANCEL,
    clientId,
    field
  };
}

export function cacheEditClientMarital(data) {
  const { id, values } = data;
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_CACHE_VALUE,
    id,
    values
  };
}

export function updateClientMarital(data) {
  const { id, values } = data;
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE,
    clientId: id,
    values,
    field: data.field
  };
}

export function updateClientMaritalSuccess(clientId, data) {
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_SUCCESS,
    clientId,
    data
  };
}

export function updateClientMaritalFailure(clientId, error) {
  return {
    type: FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_FAILURE,
    clientId,
    error
  };
}

// Form actions - auth user
export function cacheAuthUserAddForm(data) {
  return {
    type: FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CACHE,
    data
  };
}

export function cleanAuthUserAddForm(clientId, index) {
  return {
    type: FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CLEAN,
    clientId,
    index
  };
}

export function addAuthUser(loanId, clientId, index, authUser) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD,
    loanId,
    clientId,
    index,
    authUser
  };
}

export function addAuthUserSuccessful(clientId, index, authUser) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_SUCCESS,
    clientId,
    index,
    authUser
  };
}

export function addAuthUserFailure(clientId, index, error) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_FAILURE,
    clientId,
    index,
    error
  };
}

export function cacheAuthUserUpdateForm(data) {
  return {
    type: FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CACHE,
    data
  };
}

export function cleanAuthUserUpdateForm(authUserId) {
  return {
    type: FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CLEAN,
    authUserId
  };
}

export function updateAuthUser(loanId, clientId, authUser) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE,
    loanId,
    clientId,
    authUser
  };
}

export function updateAuthUserSuccessful(loanId, clientId, authUser) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_SUCCESS,
    loanId,
    clientId,
    authUser
  };
}

export function updateAuthUserFailure(loanId, clientId, authUserId, error) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_FAILURE,
    loanId,
    clientId,
    authUserId,
    error
  };
}

// FORM ACTIONS - PHONE
export function cachePhoneAddForm(data) {
  return {
    type: FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CACHE,
    data
  };
}

export function cleanPhoneAddForm(clientId) {
  return {
    type: FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CLEAN,
    clientId
  };
}

export function cachePhoneEditForm(data) {
  return {
    type: FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CACHE,
    data
  };
}

export function cleanPhoneEditForm(phoneId) {
  return {
    type: FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CLEAN,
    phoneId
  };
}

export function updateClientPhone(clientId, phone) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE,
    clientId,
    phone
  };
}

export function updateClientPhoneSuccessful(clientId, phoneId, phones) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_SUCCESS,
    clientId,
    phoneId,
    phones
  };
}

export function updateClientPhoneFailure(clientId, phoneId, error) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_FAILURE,
    clientId,
    phoneId,
    error
  };
}

export function addClientPhone(clientId, phone) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD,
    clientId,
    phone
  };
}

export function addClientPhoneSuccessful(clientId, phones) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_SUCCESS,
    clientId,
    phones
  };
}

export function addClientPhoneFailure(clientId, error) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_FAILURE,
    clientId,
    error
  };
}

export function cacheClientName(clientId, data) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM,
    clientId,
    data: {
      id: clientId,
      values: data
    }
  };
}

export function cleanClientName(clientId) {
  return {
    type: FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM_CLEAR,
    clientId
  };
}
