/**
 * ERROR STYLES
 * @author Ryan Rivera
 */
const styles = () => ({
  grid: {
    height: "calc(100% + 16px)",
    padding: 0
  },
  gridItem: {
    width: "100%",
    height: "100%"
  }
});

export default styles;
