import { i18nMark } from "@lingui/react";

export const STATUS_TYPES = {
  COMPLETED: i18nMark("Completed"),
  IN_PROGRESS: i18nMark("In progress"),
  VIEWED: i18nMark("Viewed")
};

export const TU_SCORE_TYPES = {
  CV_RISK_SCORE: "CV Risk",
  TU_RISK_SCORE2: "TU Risk 2",
  CV_BANKRUPTCY_SCORE: "CV Bankruptcy"
};

export const LOAN_PURPOSE_TYPES = {
  LP_NA: i18nMark("N/A"),
  LP_COLLECTION: i18nMark("Collection"),
  LP_CONSOLIDATION: i18nMark("Consolidation"),
  LP_PERSONAL: i18nMark("Personal")
};
