export const styles = theme => ({
  headerToolBar: {
    minHeight: 48,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between"
  },
  headerToolbarGutters: {
    padding: 0
  },
  headerBtn: {
    height: 24,
    width: 24,
    marginLeft: 20,
    marginRight: 4
  },
  headerBtnLabel: {
    fontSize: 16,
    lineHeight: "24px"
  },
  headerModuleLabel: {
    fontSize: 16,
    position: "relative",
    left: -5,
    color: theme.palette.grey["50"]
  },
  logo: {
    position: "relative",
    height: 32,
    width: 135,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    backgroundImage: theme.logo.backgroundImage,
    "&:hover": {
      cursor: "pointer"
    }
  },
  logoWrapper: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
});
