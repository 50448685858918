import { i18nMark } from "@lingui/react";

/**
 * FUNDED LOAN ERROR MESSAGES
 * @author: Ehsan
 */
const DEFAULT_ERROR_MESSAGE = i18nMark("Something went wrong.");
export const FUNDED_LOAN_ERROR_MESSAGES = {
  DEFAULT: DEFAULT_ERROR_MESSAGE,
  PERSONAL_DETAILS: {
    TITLE: {
      UPDATE: i18nMark("Error updating client title.")
    },
    MARITAL: {
      UPDATE: i18nMark("Error updating client marital status.")
    }
  }
};

export const ERROR_DEFINITIONS = {
  NOT_FOUND: {
    icon: "link_off",
    error: i18nMark("Error: 404"),
    title: i18nMark("Oops..."),
    message: i18nMark(
      "Unfortunately, this page does not exist. Please check your URL or try these helpful links."
    ),
    links: [{ label: i18nMark("Modules"), path: "/" }]
  },
  UNDER_MAINTENANCE: {
    icon: "settings",
    title: i18nMark("Maintenance"),
    message: i18nMark(
      "Maintenance is currently being performed. Expected downtime is: Aug 15, 2018, 12:00pm to Aug 15, 2018, 2:00pm"
    )
  },
  SERVER_ERROR: {
    icon: "error_outline",
    error: i18nMark("Error: 123"),
    title: i18nMark("Server error"),
    message: i18nMark(
      "The server has encountered a temporary error... Your request could not be completed. Please try again in 30 seconds."
    )
  }
};

export const TRANCHE_IMPORT_MESSAGES = {
  UPLOAD: i18nMark("New tranches must be created in Nortridge before import."),
  IMPORT: i18nMark("Import encountered errors."),
  DEFAULT: i18nMark("encountered an error")
};

export const DUPLICATE_PHONE_ERROR = i18nMark(
  "The phone number already exists and cannot be added again."
);
