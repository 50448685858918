/**
 * main root type
 */
export const LOAN_APP_TYPE = "loan.application";

// LOAN_APPLICATION_DETAILS_FETCH
export const LOAN_APPLICATION_DETAILS_FETCH = `${LOAN_APP_TYPE}/DETAILS_FETCH`;
export const LOAN_APPLICATION_DETAILS_FETCH_SUCCESS = `${LOAN_APP_TYPE}/DETAILS_FETCH_SUCCESS`;
export const LOAN_APPLICATION_DETAILS_FETCH_FAILURE = `${LOAN_APP_TYPE}/DETAILS_FETCH_FAILURE`;

// LOAN_APPLICATION_NOTES_FETCH
export const LOAN_APPLICATION_NOTES_FETCH = `${LOAN_APP_TYPE}/NOTES_FETCH`;
export const LOAN_APPLICATION_NOTES_FETCH_SUCCESS = `${LOAN_APP_TYPE}/NOTES_FETCH_SUCCESS`;
export const LOAN_APPLICATION_NOTES_FETCH_FAILURE = `${LOAN_APP_TYPE}/NOTES_FETCH_FAILURE`;
export const LOAN_APPLICATION_NOTES_ADD = `${LOAN_APP_TYPE}/NOTES_ADD`;
export const LOAN_APPLICATION_NOTES_ADD_SUCCESS = `${LOAN_APP_TYPE}/NOTES_ADD_SUCCESS`;
export const LOAN_APPLICATION_NOTES_ADD_FAILURE = `${LOAN_APP_TYPE}/NOTES_ADD_FAILURE`;

export const LOAN_APPLICATION_NOTES_CACHE_SELECTED_NOTE = `${LOAN_APP_TYPE}/CACHE_SELECTED_NOTE`;

// LOAN_APPLICATION_EMAILS_FETCH
export const LOAN_APPLICATION_EMAILS_FETCH = `${LOAN_APP_TYPE}/EMAILS_FETCH`;
export const LOAN_APPLICATION_EMAILS_FETCH_SUCCESS = `${LOAN_APP_TYPE}/EMAILS_FETCH_SUCCESS`;
export const LOAN_APPLICATION_EMAILS_FETCH_FAILURE = `${LOAN_APP_TYPE}/EMAILS_FETCH_FAILURE`;

// LOAN_APPLICATION_EMAILS_FETCH
export const LOAN_APPLICATION_SCORES_FETCH = `${LOAN_APP_TYPE}/SCORES_FETCH`;
export const LOAN_APPLICATION_SCORES_FETCH_SUCCESS = `${LOAN_APP_TYPE}/SCORES_FETCH_SUCCESS`;
export const LOAN_APPLICATION_SCORES_FETCH_FAILURE = `${LOAN_APP_TYPE}/SCORES_FETCH_FAILURE`;

// LOAN_APPLICATION_TABS_SWITCH
export const LOAN_APPLICATION_TABS_SWITCH = `${LOAN_APP_TYPE}/TABS_SWITCH`;

// LOAN APPLICATION AFFORDABILITY
export const LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH = `${LOAN_APP_TYPE}/DEBT_SERVICE_RATIO_FETCH`;
export const LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_SUCCESS = `${LOAN_APP_TYPE}/LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_SUCCESS`;
export const LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH_FAILURE = `${LOAN_APP_TYPE}/DEBT_SERVICE_RATIO_FETCH_FAILURE`;

// CLIENT EXPENSE - ADD
export const LOAN_APPLICATION_CLIENT_EXPENSE_ADD = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_ADD`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_ADD_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_ADD_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_ADD_FAILURE = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_ADD_FAILURE`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_ADD_CACHE = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_ADD_CACHE`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_ADD_CACHE_CLEAN = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_ADD_CACHE_CLEAN`;

// CLIENT EXPENSE - UPDATE
export const LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_UPDATE`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_UPDATE_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_FAILURE = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_UPDATE_FAILURE`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_CACHE = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_UPDATE_CACHE`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_CACHE_CLEAN = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_UPDATE_CACHE_CLEAN`;

// CLIENT EXPENSE - DELETE
export const LOAN_APPLICATION_CLIENT_EXPENSE_DELETE = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_DELETE`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_DELETE_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_DELETE_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_EXPENSE_DELETE_FAILURE = `${LOAN_APP_TYPE}/CLIENT_EXPENSE_DELETE_FAILURE`;

// CREATE CLIENT FORM
export const LOAN_APPLICATION_CLIENT_ADD_CACHE = `${LOAN_APP_TYPE}/CLIENT_ADD_CACHE`;
export const LOAN_APPLICATION_CLIENT_ADD_CACHE_CLEAN = `${LOAN_APP_TYPE}/CLIENT_ADD_CACHE_CLEAN`;
export const LOAN_APPLICATION_CLIENT_ADD = `${LOAN_APP_TYPE}/CLIENT_ADD`;
export const LOAN_APPLICATION_CLIENT_ADD_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_ADD_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_ADD_FAILURE = `${LOAN_APP_TYPE}/CLIENT_ADD_FAILURE`;
export const LOAN_APPLICATION_CLIENT_APPLICATION_LINK = `${LOAN_APP_TYPE}/CLIENT_APPLICATION_LINK`;

// DELETE CLIENT
export const LOAN_APPLICATION_CLIENT_DELETE = `${LOAN_APP_TYPE}/CLIENT_DELETE`;
export const LOAN_APPLICATION_CLIENT_DELETE_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_DELETE_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_DELETE_FAILURE = `${LOAN_APP_TYPE}/CLIENT_DELETE_FAILURE`;

export const LOAN_APPLICATION_REFERRAL_SOURCES_CACHE_FORM = `${LOAN_APP_TYPE}/REFERRAL_SOURCES_CACHE_FORM`;
export const LOAN_APPLICATION_REFERRAL_SOURCES_CACHE_VALUES = `${LOAN_APP_TYPE}/REFERRAL_SOURCES_CACHE_VALUES`;
export const LOAN_APPLICATION_REFERRAL_SOURCES_CLEAN_FORM = `${LOAN_APP_TYPE}/REFERRAL_SOURCES_CLEAN_FORM`;

// DEAL LEAD
export const LOAN_APPLICATION_DEAL_LEAD_CACHE_FORM = `${LOAN_APP_TYPE}/DEAL_LEAD_CACHE_FORM`;
export const LOAN_APPLICATION_DEAL_LEAD_CACHE_VALUES = `${LOAN_APP_TYPE}/DEAL_LEAD_CACHE_VALUES`;
export const LOAN_APPLICATION_DEAL_LEAD_CLEAN_FORM = `${LOAN_APP_TYPE}/DEAL_LEAD_CLEAN_FORM`;

export const LOAN_APPLICATION_DEAL_LEAD_UPDATE = `${LOAN_APP_TYPE}/DEAL_LEAD_UPDATE`;
export const LOAN_APPLICATION_DEAL_LEAD_UPDATE_SUCCESS = `${LOAN_APP_TYPE}/DEAL_LEAD_UPDATE_SUCCESS`;
export const LOAN_APPLICATION_DEAL_LEAD_UPDATE_FAILURE = `${LOAN_APP_TYPE}/DEAL_LEAD_UPDATE_FAILURE`;

export const LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH = `${LOAN_APP_TYPE}/DEAL_LEAD_OPTIONS_FETCH`;
export const LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH_SUCCESS = `${LOAN_APP_TYPE}/DEAL_LEAD_OPTIONS_FETCH_SUCCESS`;
export const LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH_FAILURE = `${LOAN_APP_TYPE}/DEAL_LEAD_OPTIONS_FETCH_FAILURE`;

export const LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE = `${LOAN_APP_TYPE}/REFERRAL_SOURCE_UPDATE`;
export const LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE_SUCCESS = `${LOAN_APP_TYPE}/REFERRAL_SOURCE_UPDATE_SUCCESS`;
export const LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE_FAILURE = `${LOAN_APP_TYPE}/REFERRAL_SOURCE_UPDATE_FAILURE`;

export const LOAN_APPLICATION_GET_CREDIT_REPORT_SUMMARY = `${LOAN_APP_TYPE}/GET_CREDIT_REPORT_SUMMARY`;
export const LOAN_APPLICATION_GET_CREDIT_REPORT_SUMMARY_SUCCESS = `${LOAN_APP_TYPE}/GET_CREDIT_REPORT_SUMMARY_SUCCESS`;

export const LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_REQUEST = `${LOAN_APP_TYPE}/PERSONAL_DETAILS_UPDATE_REQUEST`;
export const LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_SUCCESS = `${LOAN_APP_TYPE}/PERSONAL_DETAILS_UPDATE_SUCCESS`;
export const LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_FAILURE = `${LOAN_APP_TYPE}/PERSONAL_DETAILS_UPDATE_FAILURE`;

export const LOAN_APPLICATION_CLIENT_EMAIL_ADD = `${LOAN_APP_TYPE}/CLIENT_EMAIL_ADD`;
export const LOAN_APPLICATION_CLIENT_EMAIL_ADD_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_EMAIL_ADD_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_EMAIL_ADD_FAILURE = `${LOAN_APP_TYPE}/CLIENT_EMAIL_ADD_FAILURE`;
export const LOAN_APPLICATION_CLIENT_EMAIL_UPDATE = `${LOAN_APP_TYPE}/CLIENT_EMAIL_UPDATE`;
export const LOAN_APPLICATION_CLIENT_EMAIL_UPDATE_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_EMAIL_UPDATE_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_EMAIL_UPDATE_FAILURE = `${LOAN_APP_TYPE}/CLIENT_EMAIL_UPDATE_FAILURE`;
export const LOAN_APPLICATION_CLIENT_EMAIL_DELETE = `${LOAN_APP_TYPE}/CLIENT_EMAIL_DELETE`;
export const LOAN_APPLICATION_CLIENT_EMAIL_DELETE_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_EMAIL_DELETE_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_EMAIL_DELETE_FAILURE = `${LOAN_APP_TYPE}/CLIENT_EMAIL_DELETE_FAILURE`;

export const LOAN_APPLICATION_PHONE_NUMBER_ADD = `${LOAN_APP_TYPE}/PHONE_NUMBER_ADD`;
export const LOAN_APPLICATION_PHONE_NUMBER_ADD_SUCCESS = `${LOAN_APP_TYPE}/PHONE_NUMBER_ADD_SUCCESS`;
export const LOAN_APPLICATION_PHONE_NUMBER_ADD_FAILURE = `${LOAN_APP_TYPE}/PHONE_NUMBER_ADD_FAILURE`;
export const LOAN_APPLICATION_PHONE_NUMBER_UPDATE_REQUEST = `${LOAN_APP_TYPE}/PHONE_NUMBER_UPDATE_REQUEST`;
export const LOAN_APPLICATION_PHONE_NUMBER_UPDATE_SUCCESS = `${LOAN_APP_TYPE}/PHONE_NUMBER_UPDATE_SUCCESS`;
export const LOAN_APPLICATION_PHONE_NUMBER_UPDATE_FAILURE = `${LOAN_APP_TYPE}/PHONE_NUMBER_UPDATE_FAILURE`;
export const LOAN_APPLICATION_PHONE_NUMBER_DELETE = `${LOAN_APP_TYPE}/PHONE_NUMBER_DELETE`;
export const LOAN_APPLICATION_PHONE_NUMBER_DELETE_SUCCESS = `${LOAN_APP_TYPE}/NUMBER_DELETE_SUCCESS`;
export const LOAN_APPLICATION_PHONE_NUMBER_DELETE_FAILURE = `${LOAN_APP_TYPE}/NUMBER_DELETE_FAILURE`;

export const LOAN_APPLICATION_CLIENT_RESIDENCE_ADD = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_ADD`;
export const LOAN_APPLICATION_CLIENT_RESIDENCE_ADD_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_ADD_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_RESIDENCE_ADD_FAILURE = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_ADD_FAILURE`;
export const LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_REQUEST = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_UPDATE_REQUEST`;
export const LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_UPDATE_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_FAILURE = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_UPDATE_FAILURE`;
export const LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_DELETE`;
export const LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE_SUCCESS = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_DELETE_SUCCESS`;
export const LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE_FAILURE = `${LOAN_APP_TYPE}/CLIENT_RESIDENCE_DELETE_FAILURE`;

export const LOAN_APPLICATION_RESET_RISK_ASSESSMENTS_UPDATED_FLAG = `${LOAN_APP_TYPE}LOAN_APPLICATION_RESET_RISK_ASSESSMENTS_UPDATED_FLAG`;
