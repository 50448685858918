import PropTypes from "prop-types";

export const defaultProps = {
  accountsRetrieved: [],
  applicationId: "",
  clientId: "",
  createdAt: "",
  progressaRequestCode: "",
  sentToEmail: undefined,
  sentToPhoneNumber: undefined,
  status: ""
};

export const schema = {
  accountsRetrieved: PropTypes.array,
  applicationId: PropTypes.string,
  clientId: PropTypes.string,
  createdAt: PropTypes.string,
  progressaRequestCode: PropTypes.string,
  sentToEmail: PropTypes.string,
  status: PropTypes.string
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "bank-report-search";
