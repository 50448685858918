"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * AppClient Collection
 */

var _default = {
  model: "AppClient",
  transformReceived: function transformReceived(data) {
    return data;
  },
  transformSend: null
};
exports.default = _default;