import {
  TRANCHE_MANAGEMENT_UPLOAD_FILE,
  TRANCHE_MANAGEMENT_UPLOAD_FILE_SUCCESS,
  TRANCHE_MANAGEMENT_UPLOAD_FILE_FAILURE,
  TRANCHE_MANAGEMENT_IMPORT,
  TRANCHE_MANAGEMENT_IMPORT_SUCCESS,
  TRANCHE_MANAGEMENT_IMPORT_FAILURE,
  TRANCHE_MANAGEMENT_CANCEL,
  TRANCHE_MANAGEMENT_UPDATE_ERROR_STATUS
} from "./tranche.action.types";

const initialState = {
  isUploading: false,
  trancheFile: null,
  isImporting: false,
  imported: false,
  uploaded: false,
  selectedTranches: null,
  hasErrorOnUpload: false,
  hasErrorOnImport: false,
  errorData: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // UPLOAD TRANCHE FILE
    case TRANCHE_MANAGEMENT_UPLOAD_FILE:
      return {
        ...state,
        isUploading: true,
        uploaded: false,
        hasErrorOnUpload: false
      };
    case TRANCHE_MANAGEMENT_UPLOAD_FILE_SUCCESS: {
      const { results } = action.data;
      return {
        ...state,
        isUploading: false,
        uploaded: true,
        hasErrorOnUpload: false,
        trancheFile: results
      };
    }
    case TRANCHE_MANAGEMENT_UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploading: false,
        uploaded: false,
        hasErrorOnUpload: true,
        hasErrorOnImport: false
      };

    // IMPORT TRANCHES
    case TRANCHE_MANAGEMENT_IMPORT:
      return {
        ...state,
        isImporting: true,
        imported: false
      };
    case TRANCHE_MANAGEMENT_IMPORT_SUCCESS: {
      const { errorData } = action.data;
      return {
        ...state,
        selectedTranches: action.data.results,
        isImporting: false,
        hasErrorOnImport: false,
        imported: true,
        errorData: errorData
      };
    }
    case TRANCHE_MANAGEMENT_IMPORT_FAILURE:
      return {
        ...state,
        selectedTranches: null,
        isImporting: false,
        hasErrorOnImport: true,
        hasErrorOnUpload: false,
        imported: false,
        errorData: null
      };

    // CANCEL TRANCHE
    case TRANCHE_MANAGEMENT_CANCEL:
      return initialState;

    // UPDATE ERROR STATUS
    case TRANCHE_MANAGEMENT_UPDATE_ERROR_STATUS: {
      const { status, mode } = action;
      if (mode === "upload") {
        return {
          ...state,
          isUploading: false,
          uploaded: false,
          hasErrorOnUpload: status
        };
      }
      if (mode === "import") {
        return {
          ...state,
          isImporting: false,
          imported: false,
          hasErrorOnImport: status
        };
      }

      return state;
    }

    default:
      return state;
  }
};
