export const styles = theme => ({
  addButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer"
    },
    "&:hover svg": {
      color: theme.palette.secondary.dark
    }
  },
  iconButton: {
    width: "36px",
    height: "36px"
  },
  addIcon: theme.icon.icon20,
  addIconDisabled: theme.icon.icon20Disabled,
  buttonText: Object.assign({}, theme.typography.p2Dark, {
    verticalAlign: "middle",
    display: "flex"
  })
});
