import * as actionTypes from "./info.action.types";

export function fetchApiVersion() {
  return { type: actionTypes.API_VERSION_FETCH_REQUEST };
}

export function fetchApiVersionSuccess(data) {
  return {
    type: actionTypes.API_VERSION_FETCH_SUCCESS,
    data
  };
}

export function fetchApiVersionFailure(error) {
  return {
    type: actionTypes.API_VERSION_FETCH_FAILURE,
    error
  };
}
