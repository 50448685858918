export const UPLOAD_BANK_STATEMENT_REQUEST = "document/UPLOAD_BANK_STATEMENT_REQUEST"; // prettier-ignore
export const UPLOAD_DOCUMENT_REQUEST = "document/UPLOAD_DOCUMENT_REQUEST";
export const EDIT_DOCUMENT_REQUEST = "document/EDIT_DOCUMENT_REQUEST";
export const EDIT_DOCUMENT_SUCCESS = "document/EDIT_DOCUMENT_SUCCESS";

export const GET_DOCUMENT_REQUEST = "document/GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "document/GET_DOCUMENT_SUCCESS";

export const GET_OUTSTANDING_DOCUMENT_REQUEST = "document/GET_OUTSTANDING_DOCUMENT_REQUEST"; // prettier-ignore
export const GET_OUTSTANDING_DOCUMENT_SUCCESS = "document/GET_OUTSTANDING_DOCUMENT_SUCCESS"; // prettier-ignore

export const SELECT_DOC_ENTRY = "document/SELECT_DOC_ENTRY";
export const UPDATE_DOC_MODE = "document/UPDATE_DOC_MODE";
