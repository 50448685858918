"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "MaskedInput", {
  enumerable: true,
  get: function get() {
    return _masked.default;
  }
});

var _masked = _interopRequireDefault(require("./masked.input"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}