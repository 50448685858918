import React from "react";
import classNames from "classnames";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Clear from "@material-ui/icons/Clear";

import { withStyles } from "@material-ui/core/styles";
import styles from "../styles/select.filter.buttons.styles";

import IconButton from "../../../Buttons/IconButton";

const ToggleMenuBtn = withStyles(styles)(({ classes, ...rest }) => {
  const classesArray = [classes.root, classes.toggleMenuBtn];
  return (
    <IconButton className={classNames(classesArray)} {...rest}>
      <ArrowDropDown className={classes.toggleMenuIcon} />
    </IconButton>
  );
});

const ClearInputBtn = withStyles(styles)(({ classes, hidden, ...rest }) => {
  const classesArray = [classes.root, classes.clearBtn];
  if (hidden) {
    classesArray.push(classes.clearBtnHidden);
  }
  return (
    <IconButton className={classNames(classesArray)} {...rest}>
      <Clear className={classes.clearIcon} />
    </IconButton>
  );
});

export { ToggleMenuBtn, ClearInputBtn };
