import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Cancel from "@material-ui/icons/Cancel";
import Search from "@material-ui/icons/Search";
import { AlchemyIconButton } from "../Buttons";

import styles from "./styles/search.styles";

/**
 * SEARCH INPUT COMPONENT
 */
function SearchField({
  classes,
  initialTerm,
  autoFocus,
  placeholder,
  width,
  dataTestId: { input: inputTestId, icon: iconTestId, clear: clearTestId },
  dataTestBuilder,
  onSearch
}) {
  const [term, setTerm] = useState(initialTerm);

  const handleClear = () => {
    const value = "";
    setTerm(value);
    onSearch(value);
  };

  const handleSearch = () => {
    onSearch(term);
  };

  const handleChange = ({ which, target }) => {
    const value = target.value || "";
    const isEnterKey = which === 13;
    isEnterKey ? handleSearch() : setTerm(value);
  };

  return (
    <div style={{ width: width }}>
      <FormControl className={classes.formControl} fullWidth>
        <Input
          id="search-field"
          type="text"
          value={term}
          onKeyPress={handleChange}
          onChange={handleChange}
          className={classes.searchInput}
          classes={{ underline: classes.underline }}
          fullWidth
          autoFocus={autoFocus}
          placeholder={placeholder}
          inputProps={{ "data-test": inputTestId, autoComplete: "off" }}
          startAdornment={
            <InputAdornment
              classes={{ root: classes.rootSearchAdornment }}
              position="end"
            >
              <AlchemyIconButton
                className={classNames(["search", classes.rootBtn])}
                aria-label="Search"
                disableRipple
                onClick={handleSearch}
                size="MD"
                dataTestId={iconTestId}
                dataTestBuilder={dataTestBuilder}
              >
                <Search className={classes.btn} />
              </AlchemyIconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment
              classes={{ root: classes.rootClearAdornment }}
              position="end"
            >
              <AlchemyIconButton
                className={classNames([
                  "clear",
                  term !== "" ? "" : "hidden",
                  classes.rootBtn
                ])}
                aria-label="Clear Search"
                disableRipple
                onClick={handleClear}
                size="MD"
                dataTestId={clearTestId}
                dataTestBuilder={dataTestBuilder}
              >
                <Cancel className={classes.btn} />
              </AlchemyIconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}

SearchField.defaultProps = {
  term: "",
  autoFocus: false,
  width: 216
};

SearchField.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialTerm: PropTypes.string,
  autoFocus: PropTypes.bool,
  width: PropTypes.number,
  dataTestId: PropTypes.object
};

export default withStyles(styles)(SearchField);
