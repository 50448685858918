import React from "react";
import PropTypes from "prop-types";

// MATERIAL-UI COMPONENTS
import MUISnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "./components/snackbar.content";

/**
 * SEARCH INPUT COMPONENT
 * @author Ryan Rivera
 */
const Snackbar = props => {
  const {
    orientation,
    open,
    closeSnackbar,
    autoHideDuration,
    message,
    type,
    dataTestId = "snackbar",
    dataTestBuilder
  } = props;
  return (
    <MUISnackbar
      anchorOrigin={orientation}
      open={open}
      onClose={closeSnackbar}
      data-test={dataTestId}
      autoHideDuration={autoHideDuration}
    >
      <SnackbarContent
        closeSnackbar={closeSnackbar}
        message={message}
        type={type}
        dataTestId={dataTestId}
        dataTestBuilder={dataTestBuilder}
      />
    </MUISnackbar>
  );
};

Snackbar.defaultProps = {
  orientation: { vertical: "bottom", horizontal: "left" },
  open: false,
  autoHideDuration: 5000
};

Snackbar.propTypes = {
  orientation: PropTypes.object,
  open: PropTypes.bool,
  closeSnackbar: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number
};

export default Snackbar;
