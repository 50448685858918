import React, { useState } from "react";
import { t, Trans } from "@lingui/macro";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import validator from "validator";
import { CenterCenter } from "@cauldron/ui";
import { utils } from "@cauldron/core";
import { CancelConfirmation } from "../../shared";
import { Spread } from "../../shared/Page";
import { FORM_INPUTS_VALIDATION } from "../pages.validations";
import { DIALOG_MESSAGES } from "../../core/constants";
import { DIALOG_ACTIONS } from "../../core/constants/dialog.messages";
import ClientsCreateFormDetails from "./clients.create.form.details";
import { AppsForm } from "../../shared/AlchemyForm";
import { ScrollArea, AlchemyTypography, Well, Section } from "../../ui.library";

const { generateTestIds, objectsHaveDiff, removePhoneFormat } = utils;

function ClientsCreateForm({ i18n, onCancel, onSubmit, isFetching, errors }) {
  const DATA_TEST_ROOT_ID = "clients-create-form";

  const initialValues = {
    first: "",
    last: "",
    email: "",
    number: "",
    sendWelcomeEmail: false
  };

  const [clientFormData, setClientFormData] = useState(initialValues);

  const handleSetFormStateData = values => {
    setClientFormData({ ...values });
  };

  const validateFn = values => {
    const errors = {};

    if (!values.first) {
      errors.first = i18n._(FORM_INPUTS_VALIDATION.REQUIRED_FIRST_NAME);
    }

    if (!values.last) {
      errors.last = i18n._(FORM_INPUTS_VALIDATION.REQUIRED_LAST_NAME);
    }

    if (!values.email) {
      errors.email = i18n._(FORM_INPUTS_VALIDATION.REQUIRED_EMAIL);
    } else if (!validator.isEmail(values.email)) {
      errors.email = i18n._(FORM_INPUTS_VALIDATION.INVALID_EMAIL);
    }

    const phone = removePhoneFormat(values.number);
    if (!phone) {
      errors.number = i18n._(FORM_INPUTS_VALIDATION.REQUIRED_PHONE_NUMBER);
    } else if (!validator.isNumeric(phone)) {
      errors.number = i18n._(FORM_INPUTS_VALIDATION.INVALID_PHONE);
    } else if (phone.toString().trim().length !== 10) {
      errors.number = FORM_INPUTS_VALIDATION.INVALID_PHONE;
    }

    return errors;
  };

  const isCancelConfirmationActive = objectsHaveDiff(
    initialValues,
    clientFormData
  );

  const errorStack = errors ? errors.map(m => i18n._(m.message)) : [];

  return (
    <ScrollArea stretch>
      <Spread
        header={
          <CancelConfirmation
            onCancel={onCancel}
            isActive={isCancelConfirmationActive}
            cancelDialogTitleText={i18n._(DIALOG_MESSAGES.SIGN_UP_CANCEL)}
            message={i18n._(DIALOG_MESSAGES.CANCEL.BODY)}
            agreeButtonText={i18n._(DIALOG_ACTIONS.OKAY)}
            disagreeButtonText={i18n._(DIALOG_ACTIONS.CANCEL)}
            dataTestId={generateTestIds(DATA_TEST_ROOT_ID)}
            dataTestBuilder={generateTestIds}
          />
        }
      >
        <CenterCenter>
          <Well width={320}>
            <Section alignText="center">
              <AlchemyTypography
                type="h2"
                data-test={generateTestIds(DATA_TEST_ROOT_ID, "title")}
              >
                <Trans>Sign up</Trans>
              </AlchemyTypography>
            </Section>
            <Section>
              <AppsForm
                formDetailComponent={ClientsCreateFormDetails}
                initialValues={initialValues}
                formValues={clientFormData}
                validate={validateFn}
                onSubmit={onSubmit}
                setFormStateData={handleSetFormStateData}
                onCancel={onCancel}
                isSubmitting={isFetching}
                submitButtonText={i18n._(t`Create account`)}
                cancelDialogTitleText={i18n._(DIALOG_MESSAGES.SIGN_UP_CANCEL)}
                dataTestId={generateTestIds(DATA_TEST_ROOT_ID)}
                dataTestBuilder={generateTestIds}
                errorStack={errorStack}
              />
            </Section>
          </Well>
        </CenterCenter>
      </Spread>
    </ScrollArea>
  );
}

const enhance = compose(withI18n());

export default enhance(ClientsCreateForm);
