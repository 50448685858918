/**
 * EDIT_TABLE_ACTION_BUTTON_STYLES
 * @author rrive
 */
const styles = theme => ({
  cell: {
    textAlign: "right",
    background: theme.palette.common["white"],
    paddingTop: 0
  },
  actionButtonIcon: { fontSize: "20px" }
});

export default styles;
