import { fadeHEX } from "../../../utils/jss.utils";

const styles = theme => ({
  page: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: "96px",
    alignItems: "stretch"
  },
  pageHeader: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 100
  },
  main: {
    width: "100%",
    flex: "1 100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.lightGrey[50]
  },
  spot1: {
    backgroundColor: fadeHEX(theme.palette.spot[1], 0.1) // fade to 10%
  },
  spot5: {
    backgroundColor: fadeHEX(theme.palette.spot[5], 0.1) // fade to 10%
  }
});

export default styles;
