import { put, takeLatest, fork, call } from "redux-saga/effects";
import { http } from "../../../../services/http/http.service";
import {
  fetchLoanApplicationScoresSuccess,
  fetchLoanApplicationScoresFailure
} from "../actions/loan.application.details.actions";
import {
  applicationScoresReceived,
  applicationScoresSent
} from "../../../../models/score";

import { LOAN_APPLICATION_SCORES_FETCH } from "../action.types/loan.application.details.action.types";

/**
 * GET SCORES
 * Get loan application scores
 * @param {object} action
 */
export const fetchLoanApplicationScores = action =>
  http({
    request: (httpSrv, API) => {
      const appId = action.applicationId;
      return call(httpSrv.get, API.APPLICATION_SCORES(appId));
    },
    success: data => put(fetchLoanApplicationScoresSuccess(data)),
    error: error => put(fetchLoanApplicationScoresFailure(error)),
    transformReceiveData: applicationScoresReceived,
    transformSendData: applicationScoresSent
  });

function* watchLoanApplicationDetailsAsync() {
  yield takeLatest(LOAN_APPLICATION_SCORES_FETCH, fetchLoanApplicationScores);
}

export default [fork(watchLoanApplicationDetailsAsync)];
