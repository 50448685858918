import { API } from "@cauldron/core";
import { REACT_APP_API_ROOT } from "../../../core/constants/env";
import { sortByCreatedDate } from "../../model.utils";

/**
 * generateLink
 * TODO: pending API change will allow to simplify this
 * @param name
 * @param comment
 * @param parentId
 * @param document
 * @returns {string}
 */
function generateLink(name, comment, parentId, document) {
  const { id, clientId } = comment;
  const { fileIndex } = document;
  return name && clientId
    ? `${REACT_APP_API_ROOT + API.FUNDED_CLIENTS_DOCUMENT_LINK(id, fileIndex)}`
    : `${REACT_APP_API_ROOT +
        API.FUNDED_LOAN_DOCUMENT_LINK(parentId, id, fileIndex)}`;
}

/**
 * parseDocuments
 * @param name
 * @param parent
 * @param parentId
 * @param comment
 * @param document
 * @returns {{createdAt: *, createdByName: *, parent: *, filename: *, subject: *, link: string, mimeType: *, message: *, category: *}}
 */
export function parseDocuments(name, parent, parentId, comment, document) {
  return {
    filename: document.fileName,
    createdAt: comment.createdAt,
    createdByName: comment.createdBy.name,
    message: comment.message,
    parent: parent,
    link: generateLink(name, comment, parentId, document),
    subject: comment.subject,
    category: comment.category,
    mimeType: document.mimeType
  };
}

/**
 * toDocumentsCollection
 * combines all loan and client comments to a single collection
 * @param acc
 * @param currentComment
 * @returns {*}
 */
function toDocumentsCollection(acc, currentComment) {
  const { id, name = null, comments = [] } = currentComment;
  const parent = name ? name : "Loan";
  const parentId = id;
  const clientId = name ? parentId : null;
  const loanId = name ? null : parentId;

  // if name is null, it is a loan comment
  const isLoanComment = parent === "Loan";

  const filteredComments = comments.filter(comment => {
    return comment.documents.length;
  });

  const parsedDocuments = filteredComments
    .sort(sortByCreatedDate)
    .reduce((acc, fComment) => {
      const { documents = [] } = fComment;

      const comment = {
        ...fComment,
        clientId,
        loanId,
        isLoanComment
      };

      const docs = documents.map(
        parseDocuments.bind(this, name, parent, parentId, comment)
      );
      return acc.concat(docs);
    }, []);

  return acc.concat(parsedDocuments);
}

/**
 * Funded loan details [RECEIVED]
 * documents
 * transform data -> before it reaches the store
 * @param {*} rawData
 * @author Ehsan
 */

export const transformReceived = data => {
  return {
    results: data.reduce(toDocumentsCollection, [])
  };
};
