"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var transitionStyles = {
  transition: "background-color .2s ease-in-out, color .2s ease-in-out",
  "& .material-icons": {
    transition: "color .2s ease-in-out"
  }
};

var styles = function styles(theme) {
  return {
    rootMD: _objectSpread({
      width: "32px",
      height: "32px"
    }, transitionStyles, {
      "&$disabled": {
        color: theme.palette.lightGrey[900]
      },
      "&:hover": {
        color: theme.palette.primary["main"]
      }
    }),
    rootSM: _objectSpread({
      width: "24px",
      height: "24px"
    }, transitionStyles, {
      "&:hover": {
        color: theme.palette.primary["main"]
      }
    }),
    label: {
      position: "absolute"
    },
    disabled: {
      color: theme.palette.lightGrey[900]
    }
  };
};

var _default = styles;
exports.default = _default;