"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.choice = void 0;

var _react = require("@storybook/addon-knobs/react");

var choice = function choice(name) {
  var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "MAIN";
  return (0, _react.boolean)("".concat(name, "? "), false, id);
};

exports.choice = choice;