import { i18nMark } from "@lingui/react";

export const LOAN_TERMS = [
  { label: i18nMark("6 Months"), value: "6" },
  { label: i18nMark("12 Months"), value: "12" },
  { label: i18nMark("18 Months"), value: "18" },
  { label: i18nMark("24 Months"), value: "24" },
  { label: i18nMark("30 Months"), value: "30" },
  { label: i18nMark("36 Months"), value: "36" },
  { label: i18nMark("42 Months"), value: "42" },
  { label: i18nMark("48 Months"), value: "48" },
  { label: i18nMark("54 Months"), value: "54" },
  { label: i18nMark("60 Months"), value: "60" }
];

export const APPLICATION_PURPOSE_TYPES = ["LP_COLLECTION", "LP_CONSOLIDATION"];
