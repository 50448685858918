import { createSelector } from "reselect";
import keyBy from "lodash/keyBy";
import { utils } from "@cauldron/core";
import { selectors } from "../applications";

const { normalizeToMonthly, normalizeToAnnual, concatText } = utils;

export const getClients = createSelector(
  selectors.applicationSelector,
  application => {
    const clients = application.clients;
    if (!clients || !clients.length) return [];
    return clients.map(client => {
      const addTotalExpenses = expense => {
        const { payment = 0, payFrequency = "Unknown" } = expense;
        expense.monthlyExpense = normalizeToMonthly[payFrequency](payment);
        expense.annualExpense = normalizeToAnnual[payFrequency](payment);
        return expense;
      };

      const { firstName, middleName, lastName } = client;
      client.fullName = concatText(" ", firstName, middleName, lastName);

      // Calculate monthly expense
      client.expenses.map(addTotalExpenses);

      // Calculate monthly expense
      client.liabilities.map(addTotalExpenses);

      // Calculate total monthly expenses
      const monthlyExpenseTotal = client.liabilities
        .concat(client.expenses)
        .reduce(
          (acc = 0, curr = 0) => ({
            monthlyExpense: acc.monthlyExpense + curr.monthlyExpense
          }),
          { monthlyExpense: 0 }
        );

      // Calculate total annual expenses
      const annualExpenseTotal = client.liabilities
        .concat(client.expenses)
        .reduce(
          (acc = 0, curr = 0) => ({
            annualExpense: acc.annualExpense + curr.annualExpense
          }),
          { annualExpense: 0 }
        );

      // Calculate total annual liabilities
      const annualLiabilitiesTotal = client.liabilities.reduce(
        (acc, curr) => acc + curr.annualExpense,
        0
      );

      // Calculate Totals
      client.statistics = {
        ...monthlyExpenseTotal,
        ...annualExpenseTotal,
        annualLiabilitiesTotal
      };
      return client;
    });
  }
);

export const getClientsById = createSelector(
  getClients,
  clients => (!clients ? {} : keyBy(clients, "id"))
);
