"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IDENTIFY = exports.REGISTER = exports.TRACK = void 0;
var TRACK = "mixpanel/TRACK";
exports.TRACK = TRACK;
var REGISTER = "mixpanel/REGISTER";
exports.REGISTER = REGISTER;
var IDENTIFY = "mixpanel/IDENTIFY";
exports.IDENTIFY = IDENTIFY;