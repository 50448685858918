import { API } from "@cauldron/core";
import { sortByCreatedDate } from "../../model.utils";
import { transformReceived as transformComment } from "./received";
import { REACT_APP_API_ROOT } from "../../../core/constants/env";

/**
 * generateLink
 * TODO: pending API change will allow to simplify this
 * @param name
 * @param comment
 * @param parentId
 * @param document
 * @returns {string}
 */
function generateLink(name, comment, parentId, document) {
  const { id, clientId } = comment;
  const { fileIndex } = document;
  return name && clientId
    ? `${REACT_APP_API_ROOT + API.FUNDED_CLIENTS_DOCUMENT_LINK(id, fileIndex)}`
    : `${REACT_APP_API_ROOT +
        API.FUNDED_LOAN_DOCUMENT_LINK(parentId, id, fileIndex)}`;
}

/**
 * parseDocuments
 * @param name
 * @param parent
 * @param parentId
 * @param comment
 * @param document
 * @returns {{createdAt: *, createdByName: *, parent: *, filename: *, subject: *, link: string, mimeType: *, message: *, category: *}}
 */
export function parseDocuments(name, parent, parentId, comment, document) {
  return {
    filename: document.fileName,
    createdAt: comment.createdAt,
    createdByName: comment.createdBy.name,
    message: comment.message,
    parent: parent,
    link: generateLink(name, comment, parentId, document),
    subject: comment.subject,
    category: comment.category,
    mimeType: document.mimeType
  };
}

/**
 * parseComments
 * @param name
 * @param parent
 * @param parentId
 * @param clientId
 * @param loanId
 * @param isLoanComment
 * @param comment
 * @returns {{[p: string]: *}}
 */
function parseComments(
  name,
  parent,
  parentId,
  clientId,
  loanId,
  isLoanComment,
  comment
) {
  const { createdBy, documents, ...restOfCommentProps } = comment;

  return {
    isLoanComment,
    clientId,
    loanId,
    name,
    createdByName: createdBy.name,
    createdByEmail: createdBy.email,
    documents: documents.map(
      parseDocuments.bind(this, name, parent, parentId, comment)
    ),
    ...restOfCommentProps
  };
}

/**
 * toCommentsCollection
 * combines all loan and client comments to a single collection
 * @param acc
 * @param currentComment
 * @returns {*}
 */
function toCommentsCollection(acc, currentComment) {
  const { id, name = null, comments = [] } = currentComment;
  const parent = name ? name : "Loan";
  const parentId = id;
  const clientId = name ? parentId : null;
  const loanId = name ? null : parentId;

  // if name is null, it is a loan comment
  const isLoanComment = parent === "Loan";

  const parsedComments = comments.map(
    parseComments.bind(
      this,
      name,
      parent,
      parentId,
      clientId,
      loanId,
      isLoanComment
    )
  );
  return acc.concat(parsedComments);
}

/**
 * transformReceived
 * wraps the data with object.results
 * reduces all comments to a single collection
 * then sort asc by createdDate
 * @param data
 * @returns {{results: *}}
 */
export const transformReceived = data => {
  return {
    results: data
      .reduce(toCommentsCollection, [])
      .map(transformComment)
      .sort(sortByCreatedDate)
  };
};
