import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { styles } from "./styles/loader.styles";

/**
 * LOADER - spinning circular loader
 * @type Component
 * @author Ehsan | Stanley
 * @version 1.0.0
 * @param {number} size - diameter of the loader in px
 * @param {number} thickness - thickness of the loader in px
 * @param {boolean} centerScreen - align the loader to the center of the screen
 */
class Loader extends React.Component {
  render() {
    const { classes, centerScreen, size, thickness } = this.props;
    const centerScreenStyle = {
      position: "absolute",
      top: `calc(50% - ${size / 2}px)`,
      left: `calc(50% - ${size / 2}px)`
    };

    return (
      <div className={classes.root}>
        <CircularProgress
          className={classes.progress}
          size={size}
          thickness={thickness}
          style={centerScreen ? centerScreenStyle : null}
        />
      </div>
    );
  }
}

Loader.defaultProps = {
  size: 75,
  thickness: 3.5,
  centerScreen: true
};

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * The size of the loader. Default size being 75.
   */
  size: PropTypes.number.isRequired,
  /**
   * The thickness of the loader. Default thickness is 3.5.
   */
  thickness: PropTypes.number.isRequired,
  /**
   * Whether the loader is located in the center of the screen. Default to be true.
   */
  centerScreen: PropTypes.bool
};

export default withStyles(styles)(Loader);
