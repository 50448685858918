/**
 * Add translations to menu list
 * @param user
 * @param i18n
 * @returns {*}
 */
export const getMenuList = (filteredMenu, i18n) => {
  return filteredMenu.map(menu => {
    return { label: i18n._(menu.label), path: menu.path };
  });
};
