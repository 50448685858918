import React from "react";
import PropTypes from "prop-types";
import { utils } from "@cauldron/core";

// MATERIAL-UI
import AppBar from "@material-ui/core/AppBar";

// UI-LIBRARY
import { PrimaryHeader, SecondaryHeader } from "../../ui.library";

// DATA-TEST_ATTRS
import {
  APP_HEADER,
  APP_SECONDARY_HEADER,
  AVATAR_ICON,
  LOGO_ICON,
  AVATAR_MENU_ITEM,
  MODULES_ICON,
  MODULES_MENU_LA,
  MODULES_MENU_FL,
  BREADCRUMB_NAV
} from "../../pages/constants/data.test.ids";

const { generateTestIds } = utils;

function Header(props) {
  const {
    paths,
    profile,
    actions,
    menuList,
    emptyMenuListText,
    onLogOut,
    onLogoClick,
    onShowAppInfo
  } = props;

  const p = profile ? profile : {};
  const profilePic = p.imageUrl ? p.imageUrl : "";
  const fullName = p.name ? p.name : "";

  return (
    <div className="page-header-wrapper">
      <AppBar position="static" elevation={1}>
        <PrimaryHeader
          dataTestId={{
            root: generateTestIds(APP_HEADER),
            logo: generateTestIds(APP_HEADER, LOGO_ICON),
            avatarButton: generateTestIds(APP_HEADER, AVATAR_ICON),
            avatarMenuItem: generateTestIds(APP_HEADER, AVATAR_MENU_ITEM),
            modulesButton: generateTestIds(APP_HEADER, MODULES_ICON),
            modulesLa: generateTestIds(APP_HEADER, MODULES_MENU_LA),
            modulesFl: generateTestIds(APP_HEADER, MODULES_MENU_FL)
          }}
          fullName={fullName}
          menuList={menuList}
          hasPhoto={!!profilePic}
          photoLink={profilePic}
          onLogout={onLogOut}
          onLogoClick={onLogoClick}
          onShowAppInfo={onShowAppInfo}
          emptyMenuListText={emptyMenuListText}
        />
        {(paths || actions) && (
          <SecondaryHeader
            dataTestId={{
              root: APP_SECONDARY_HEADER,
              breadcrumb: generateTestIds(APP_SECONDARY_HEADER, BREADCRUMB_NAV)
            }}
            paths={paths}
            actions={actions}
          />
        )}
      </AppBar>
    </div>
  );
}

Header.defaultProps = {
  dataTestId: {}
};

Header.propTypes = {
  /**
   * the bread crumb action component render function
   */
  actions: PropTypes.any,

  /**
   * User contains user profile
   */
  profile: PropTypes.object,

  /**
   * menu list of modules available for the user
   */
  menuList: PropTypes.array.isRequired,
  emptyMenuListText: PropTypes.string,

  /**
   * user actions
   */
  onLogOut: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  paths: PropTypes.array
};

export default Header;
