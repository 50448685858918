"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _api = require("./api");

var _store = _interopRequireDefault(require("./store"));

var utils = _interopRequireWildcard(require("./utils"));

var services = _interopRequireWildcard(require("./services"));

var theme = _interopRequireWildcard(require("./theme"));

var domain = _interopRequireWildcard(require("./domain"));

var marketing = _interopRequireWildcard(require("./marketing"));

var legal = _interopRequireWildcard(require("./legal"));

module.exports = {
  API: _api.API,
  configureStore: _store.default,
  utils: utils,
  services: services,
  theme: theme,
  domain: domain,
  marketing: marketing,
  legal: legal
};