import React, { Component } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { MaskedInput } from "@cauldron/ui";

// LOCAL STYLES
import styles from "./styles/text.field.styles";

class AlchemyTextField extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { value, hasError, helperText, InputProps } = this.props;
    return (
      value !== nextProps.value ||
      hasError !== nextProps.hasError ||
      helperText !== nextProps.helperText ||
      InputProps !== nextProps.InputProps
    );
  }

  render() {
    const {
      classes,
      InputProps = {},
      InputLabelProps = {},
      FormHelperTextProps = {},
      required = false,
      label,
      hasError,
      helperText,
      textAlign,
      dataTestId,
      dataTestBuilder,
      mask,
      ...other
    } = this.props;

    // TODO: consider adding maxLength to top level props

    if (mask) {
      InputProps.inputComponent = MaskedInput;
      InputProps.inputProps.mask = mask;
    }

    return (
      <TextField
        autoComplete="off"
        fullWidth
        label={required ? `${label} *` : label}
        classes={{ root: classes.formControlRoot }}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            focused: "focused",
            disabled: "disabled",
            error: "error",
            underline: classes.inputUnderline
          },
          ...InputProps,
          inputProps: {
            ...InputProps.inputProps,
            className: `text-align-${textAlign}`
          }
        }}
        InputLabelProps={{
          classes: {
            formControl: classes.labelFormControl,
            shrink: classes.labelShrink
          },
          FormLabelClasses: {
            focused: "focused",
            error: "error",
            disabled: "disabled"
          },
          ...InputLabelProps
        }}
        FormHelperTextProps={{
          required: required,
          classes: {
            root: classes.helperTextRoot,
            error: classes.helperTextError,
            disabled: classes.helperTextDisabled,
            focused: classes.helperTextFocused
          },
          ...FormHelperTextProps
        }}
        helperText={!helperText && required ? "Required" : helperText}
        data-test={dataTestBuilder && dataTestBuilder(dataTestId, "text-field")}
        error={hasError}
        {...other}
      />
    );
  }
}

AlchemyTextField.defaultProps = {
  textAlign: "left"
};

AlchemyTextField.propTypes = {
  required: PropTypes.bool,
  textAlign: PropTypes.oneOf(["left", "center", "right"])
};

const enhance = compose(withStyles(styles));

export default enhance(AlchemyTextField);
