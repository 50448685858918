import React, { Component } from "react";
import PropTypes from "prop-types";

import MenuList from "@material-ui/core/MenuList";
import SubMenu from "./nested.menu.submenu";

import { MenuItem } from "../../MenuItem";

class SelectMenu extends Component {
  state = {
    currentOpen: null
  };
  prefix = "open";

  handleOpen = event => {
    event.stopPropagation();

    if (!event.target.id) {
      return;
    }

    if (!this.anchorEl) {
      this.anchorEl = event.target;
    }

    const currentMenuItem = `${this.prefix}_${event.target.id}`;
    this.setState({ currentOpen: currentMenuItem });
  };

  handleCloseAll = event => {
    const { close } = this.props;
    this.handleClose(event);
    close(event);
  };

  handleClose = () => {
    this.setState({ currentOpen: null });
  };

  handleClick = event => {
    const { containerId, type, handleMenuItemClick } = this.props;
    const itemId = event.target.id;
    handleMenuItemClick(event, { containerId, type, itemId });
    this.handleCloseAll(event);
  };

  render() {
    const { dataTestId, actions, menuItems, handleMenuItemClick } = this.props;

    return (
      <MenuList>
        {menuItems &&
          menuItems.map(item => {
            const { currentOpen } = this.state;
            const currentMenuItem = `${this.prefix}_${item.id}`;
            const selected =
              (item.menuItems && currentOpen === currentMenuItem) || false;

            return (
              <div
                key={item.id}
                ref={node => (this.nodeRef = node)}
                style={{
                  position: "relative"
                }}
              >
                <MenuItem
                  id={item.id}
                  disabled={item.disabled}
                  onClick={!selected ? this.handleClick : null}
                  onMouseOver={this.handleOpen}
                  onFocus={this.handleOpen}
                  disableRipple
                  selected={selected}
                >
                  {item.label}
                </MenuItem>
                {item.menuItems && (
                  <SubMenu
                    containerId={item.id}
                    parentAnchorEl={this.nodeRef}
                    parentOpen={selected}
                    close={this.handleCloseAll}
                    menuItems={item.menuItems}
                    type={item.type}
                    handleMenuItemClick={handleMenuItemClick}
                    dataTestId={dataTestId}
                    actions={actions}
                  />
                )}
              </div>
            );
          })}
      </MenuList>
    );
  }
}

SelectMenu.defaultProps = {
  menuItems: []
};

SelectMenu.propTypes = {
  containerId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  handleMenuItemClick: PropTypes.func.isRequired
};

export default SelectMenu;
