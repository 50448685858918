import { utils } from "@cauldron/core";
import { actionTypes } from "./referral.sources.action.types";

const { makeActionCreator } = utils;

export function fetchReferralSources(eventHandlers = {}) {
  return {
    type: actionTypes.REFERRAL_SOURCES_FETCH_REQUEST,
    eventHandlers
  };
}

export function fetchReferralSourcesSuccess(payload) {
  return {
    type: actionTypes.REFERRAL_SOURCES_FETCH_SUCCESS,
    payload
  };
}

export function fetchReferralSourcesFailure(error) {
  return {
    type: actionTypes.REFERRAL_SOURCES_FETCH_FAILURE,
    error
  };
}

export const fetchReferralSourceAgents = makeActionCreator(
  actionTypes.REFERRAL_SOURCE_AGENTS_FETCH_REQUEST,
  "id"
);
export const fetchReferralSourceAgentsSuccess = makeActionCreator(
  actionTypes.REFERRAL_SOURCE_AGENTS_FETCH_SUCCESS,
  "data"
);
export const fetchReferralSourceAgentsFailure = makeActionCreator(
  actionTypes.REFERRAL_SOURCE_AGENTS_FETCH_FAILURE,
  "error"
);

export const createAgent = makeActionCreator(
  actionTypes.CREATE_REFERRAL_AGENT_REQUEST,
  "id",
  "payload",
  "eventHandlers"
);

export const createAgentSuccess = makeActionCreator(
  actionTypes.CREATE_REFERRAL_AGENT_SUCCESS,
  "data"
);

export const createAgentFailure = makeActionCreator(
  actionTypes.CREATE_REFERRAL_AGENT_FAILURE,
  "error"
);
