import { utils } from "@cauldron/core";

const { makeActionTypes } = utils;

const namespace = "agent.portal.clients";

/**
 * Generates actions
 * creates one for each REQUEST | SUCCESS | FAILURE
 */
export const actionTypes = {
  ...makeActionTypes(namespace, "CLIENT_CREATE"),
  ...makeActionTypes(namespace, "CLIENT_UPDATE"),
  ...makeActionTypes(namespace, "CLIENT_FETCH"),
  ...makeActionTypes(namespace, "CLIENT_CREATE_FROM_APPLICANT")
};
