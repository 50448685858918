import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ModuleMenuIcon from "@material-ui/icons/Apps";
import Typography from "@material-ui/core/Typography";
import HeaderMenuComponent from "./components/header.menu";
import { styles } from "./styles/header.menu.styles";

/**
 * HEADER MENU
 * this component refers to the top header menu which shows all available modules.
 * @type Component
 * @author Ehsan
 * @version 0.1.1
 */

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      menuOpen: false,
      savedAnchor: null
    };

    this.iconButtonRef = React.createRef();
  }

  handleMenu = (event, action) => {
    action
      ? this.setState({
          menuOpen: action,
          anchorEl: event.currentTarget,
          savedAnchor: event.currentTarget
        })
      : this.setState({ menuOpen: action, anchorEl: null });
  };

  handleLabelClick = (event, action) => {
    action
      ? this.setState(prevState => ({
          menuOpen: action,
          anchorEl: prevState.savedAnchor
            ? prevState.savedAnchor
            : this.iconButtonRef.current
        }))
      : this.setState({ menuOpen: action, anchorEl: null });
  };

  handleOnShowAppInfo = event => {
    const { onShowAppInfo } = this.props;
    if (onShowAppInfo) onShowAppInfo();
    this.handleMenu(event, false);
  };

  render() {
    const { classes, menuList, emptyMenuListText, dataTestId } = this.props;
    const { anchorEl, menuOpen } = this.state;
    const hasMenuItems = menuList && menuList.length > 0;
    return (
      <Fragment>
        <Button
          data-test={dataTestId.modulesButton}
          classes={{ root: classes.headerIconBtn }}
          color="inherit"
          aria-label="Module menu"
          aria-owns={anchorEl ? "module-menu" : null}
          aria-haspopup="true"
          onClick={event => this.handleMenu(event, true)}
          ref={this.iconButtonRef}
          disabled={!hasMenuItems && !emptyMenuListText}
        >
          <ModuleMenuIcon classes={{ root: classes.menuIcon }} />
          <Typography
            color="inherit"
            classes={{ root: classes.headerModuleLabel }}
            onClick={event => this.handleLabelClick(event, true)}
          >
            Modules
          </Typography>
        </Button>
        <HeaderMenuComponent
          dataTestId={dataTestId}
          anchorEl={anchorEl}
          menuOpen={menuOpen}
          menuClose={event => this.handleMenu(event, false)}
          menuList={menuList.concat([
            { type: "DIVIDER" },
            {
              label: "About",
              onClick: this.handleOnShowAppInfo
            }
          ])}
          emptyMenuListText={emptyMenuListText}
        />
      </Fragment>
    );
  }
}

HeaderMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  menuList: PropTypes.array.isRequired,
  emptyMenuListText: PropTypes.string
};

export default withStyles(styles)(HeaderMenu);
