import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import DateFieldPicker from "../DatePicker";

// LOCAL STYLES
import styles from "./styles/date.field.styles";

// HELPERS
export const getUTC = value => {
  return value.utc() ? value.utc().format() : value;
};

export const isolateDate = callback => event => {
  // Need to isolate just date as react propagates
  // synthetic events from children
  if (moment.isMoment(event) || event === null) {
    callback.call(this, event);
  }
};

/**
 * DATE_FIELD COMPONENT
 * support for most API of TextField: https://material-ui.com/api/text-field/
 * @author rrive
 */
class DateField extends Component {
  state = {
    focused: false
  };

  handleOnBlur = () => {
    this.setState({ focused: false });
  };

  handleOnFocus = () => {
    this.setState({ focused: true });
  };

  render() {
    const {
      id,
      classes,
      className,
      dateFormat,
      disabled,
      error,
      hasError,
      inputProps,
      InputProps,
      helperText,
      FormHelperTextProps,
      InputLabelProps,
      label,
      name,
      onChange,
      placeholder,
      required,
      value,
      dataTestId,
      dataTestBuilder,
      ...other
    } = this.props;

    const { focused } = this.state;

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

    return (
      <FormControl
        aria-describedby={helperTextId}
        className={className}
        classes={{ root: classes.formControlRoot }}
        disabled={disabled}
        error={error || hasError}
        data-test={dataTestBuilder && dataTestBuilder(dataTestId)}
        fullWidth
        {...other}
      >
        {label && (
          <InputLabel
            focused={focused}
            shrink={!!value || !!focused}
            htmlFor={id}
            {...InputLabelProps}
            required={required}
            classes={{
              formControl: classes.labelFormControl,
              shrink: classes.labelShrink
            }}
            FormLabelClasses={{
              focused: "focused",
              error: "error",
              disabled: "disabled"
            }}
          >
            {label}
          </InputLabel>
        )}
        <DateFieldPicker
          dateFormat={dateFormat}
          disabled={disabled}
          fullWidth
          onChange={onChange}
          onClickOutside={this.handleClickOutside}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          name={name}
          placeholder={placeholder}
          inputProps={inputProps}
          value={value}
          error={error}
          dataTestId={dataTestId}
          {...InputProps}
        />
        <FormHelperText
          id={helperTextId}
          classes={{
            root: classes.helperTextRoot,
            error: classes.helperTextError,
            disabled: classes.helperTextDisabled,
            focused: classes.helperTextFocused
          }}
          {...FormHelperTextProps}
        >
          {!helperText && required ? "Required" : helperText}
        </FormHelperText>
      </FormControl>
    );
  }
}

DateField.defaultProps = {
  required: false,
  onBlur: () => {}
};

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(DateField);
