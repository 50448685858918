import { put, takeLatest, fork, call } from "redux-saga/effects";
import { http } from "../../../services/http/http.service";
import { DASHBOARD_REQUEST_START } from "./dashboard.action.types";
import {
  fetchDashboardProfileSuccess,
  fetchDashboardProfileFailure
} from "./dashboard.actions";

/**
 * fetchDashboardModules
 */
export const fetchDashboardModules = () =>
  http({
    request: (httpSrv, API) => call(httpSrv.get, API.PROFILE),
    success: data => [put(fetchDashboardProfileSuccess(data))],
    error: error => put(fetchDashboardProfileFailure(error))
  });

export function* watchDashboardAsync() {
  yield takeLatest(DASHBOARD_REQUEST_START, fetchDashboardModules);
}

export default [fork(watchDashboardAsync)];
