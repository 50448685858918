"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "apply", {
  enumerable: true,
  get: function get() {
    return _bugsnag.apply;
  }
});
Object.defineProperty(exports, "initialize", {
  enumerable: true,
  get: function get() {
    return _bugsnag.initialize;
  }
});
Object.defineProperty(exports, "notify", {
  enumerable: true,
  get: function get() {
    return _bugsnag.notify;
  }
});
Object.defineProperty(exports, "getErrorBoundary", {
  enumerable: true,
  get: function get() {
    return _bugsnag.getErrorBoundary;
  }
});

var _bugsnag = require("./bugsnag");