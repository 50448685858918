import PropTypes from "prop-types";

/**
 * UPLOAD MODEL
 */

// UPLOAD TRANCHE - DEFAULT PROPS
export const defaultProps = {
  id: null,
  name: null,
  type: null,
  loansCount: 0,
  value: 0,
  dateRange: {
    min: null,
    max: null
  },
  investor: {
    name: null
  }
};

// UPLOAD TRANCHE - SCHEMA
export const schema = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  loansCount: PropTypes.number,
  value: PropTypes.number,
  dateRange: PropTypes.object,
  investor: PropTypes.object
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Tranche-upload";
