export const WRAPPER = "wrapper";
export const ROOT = "root";
export const DIALOG = "dialog";
export const HEADER = "header";
export const ICON = "icon";
export const TEXT = "text";
export const ICON_TEXT = `${ICON}-${TEXT}`;
export const TITLE = "title";
export const MESSAGE = "message";
export const ACTIONS = "actions";
export const BUTTON = "button";
export const CONFIRM = "confirm";
export const CANCEL = "cancel";

export function getDataTestID(dataTestBuilder, dataTestId, ...args) {
  return dataTestBuilder && dataTestId && dataTestBuilder(dataTestId, ...args);
}
