import * as actionTypes from "./info.action.types";

const initialState = {};

/**
 * Reducer for info
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.API_VERSION_FETCH_SUCCESS:
      return { ...state, ...action.data };

    case actionTypes.API_VERSION_FETCH_FAILURE:
      return state;

    default:
      return state;
  }
};
