import { utils } from "@cauldron/core";

const { makeActionTypes } = utils;

const namespace = "application";

/**
 * Generates actions
 * creates one for each REQUEST | SUCCESS | FAILURE
 */
export const actionTypes = {
  ...makeActionTypes(namespace, "APPLICATION_CLIENT_LINK")
};
