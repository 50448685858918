const styles = theme => ({
  root: {
    lineHeight: "1.3em",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[900],

    "& h1, h2, h3, h4, h5, h6, h7, p, section, ul, ol": {
      margin: 0
    },
    "& h1, h2, h3, h4, h5, h6, h7": {
      padding: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit}px`
    },
    "& .center": {
      textAlign: "center"
    },
    "& section, ul, ol, div, p": {
      color: "inherit",
      paddingTop: `${theme.spacing.unit}px`,
      paddingBottom: `${theme.spacing.unit}px`
    },
    "& ul, ol": {
      paddingLeft: `${theme.spacing.unit * 4}px`
    },
    "& h1": theme.typography.h1Dark,
    "& h2": theme.typography.h2Dark,
    "& h3": theme.typography.h3Dark,
    "& h4": theme.typography.h4Dark,
    "& h5": theme.typography.h5Dark,
    "& h6": theme.typography.h6Dark,
    "& h7": theme.typography.h7Dark,
    "& li": {
      paddingLeft: theme.spacing.unit
    },
    "& a, a:link, a:visited, a:hover": {
      ...theme.typography.link
    }
  }
});

export default styles;
