import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

// MATERIAL-UI COMPONENTS
import Downshift from "downshift";
import TextField from "../../TextField";

import SelectFilterMenu from "./select.filter.menu";
import { ToggleMenuBtn, ClearInputBtn } from "./select.filter.buttons";
import { normalizeValue, getFilteredOptions } from "./select.filter.utils";
import styles from "../styles/select.styles";

/**
 * SelectFilter
 */
class SelectFilter extends Component {
  inputRef = React.createRef();

  state = {
    filter: false
  };

  handleOnStateChange = changes => {
    const { name, onChange } = this.props;
    if (changes.hasOwnProperty("isOpen") && changes.isOpen) {
      this.loadOptions();
    }
    if (changes.hasOwnProperty("inputValue")) {
      onChange(name, changes.inputValue);
    }
  };

  handleOnInputValueChange = (value, dsProps) => {
    const { inputValue, selectedItem } = dsProps;
    const label = selectedItem ? selectedItem.label : "";
    if (inputValue !== label) {
      this.setState({ filter: true });
    }
  };

  handleOnChange = selected => {
    const { name, onChange } = this.props;
    const value = selected ? selected.value : "";
    const callback = () => onChange(name, value);
    this.setState({ filter: false }, callback);
  };

  handleOnOuterClick = () => {
    this.setState({ filter: false });
  };

  handleToggleButtonOnClick = () => {
    this.inputRef.current.focus();
    this.loadOptions();
  };

  loadOptions = () => {
    const { onLoadOptions } = this.props;
    if (onLoadOptions) onLoadOptions();
  };

  getDataTestId = (builder, id, ...args) => {
    return builder && builder(id, ...args);
  };

  handleStateReducer = (state, changes) => {
    const { isCustomInput } = this.props;
    if (isCustomInput) {
      switch (changes.type) {
        case Downshift.stateChangeTypes.mouseUp:
        case Downshift.stateChangeTypes.blurButton:
        case Downshift.stateChangeTypes.blurInput:
          return { isOpen: false };
        default:
          if (changes.inputValue) {
            return {
              ...changes,
              selectedItem: {
                label: changes.inputValue,
                value: changes.inputValue
              }
            };
          }
          return changes;
      }
    }
    return changes;
  };

  getInputValue = () => {
    const { filter } = this.state;
    const { includeNonOptionData, options, value } = this.props;
    const normalizedValue = normalizeValue(
      options,
      value,
      includeNonOptionData
    );

    if (
      !filter &&
      typeof value !== "undefined" &&
      value !== null &&
      normalizedValue
    ) {
      return normalizedValue.label;
    }
    return value;
  };

  render() {
    const {
      classes,
      name,
      label,
      placeholder,
      helperText,
      initialValue,
      options,
      disabled,
      error,
      isFetching,
      required,
      filterOptions,
      onBlur,
      className,
      dataTestId,
      dataTestBuilder,
      customInputComponent,
      includeNonOptionData,
      FormHelperTextProps,
      InputLabelProps
    } = this.props;

    const { filter } = this.state;

    return (
      <Downshift
        id={name}
        onChange={this.handleOnChange}
        onStateChange={this.handleOnStateChange}
        onOuterClick={this.handleOnOuterClick}
        onInputValueChange={this.handleOnInputValueChange}
        initialSelectedItem={normalizeValue(
          options,
          initialValue,
          includeNonOptionData
        )}
        itemToString={item => (item ? item.label || item.value : "")}
        inputValue={this.getInputValue()}
        stateReducer={this.handleStateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getToggleButtonProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          clearSelection,
          openMenu
        }) => {
          const menuItems = filter
            ? getFilteredOptions(options, inputValue, filterOptions)
            : options;

          const isBtnHidden = !inputValue || disabled;

          const inputComponent = customInputComponent
            ? { inputComponent: customInputComponent }
            : {};
          return (
            <div
              className={classes.root}
              data-test={this.getDataTestId(
                dataTestBuilder,
                dataTestId,
                "wrapper"
              )}
            >
              <TextField
                className={className}
                name={name}
                data-test={this.getDataTestId(
                  dataTestBuilder,
                  dataTestId,
                  "select"
                )}
                label={label}
                helperText={helperText}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
                error={error}
                fullWidth
                inputRef={this.inputRef}
                InputProps={{
                  inputProps: {
                    onClick: disabled ? null : openMenu,
                    "data-test": this.getDataTestId(
                      dataTestBuilder,
                      dataTestId,
                      "input"
                    )
                  },
                  endAdornment: (
                    <Fragment>
                      <ClearInputBtn
                        size="SM"
                        data-test={this.getDataTestId(
                          dataTestBuilder,
                          dataTestId,
                          "button-clear"
                        )}
                        {...getToggleButtonProps({
                          hidden: isBtnHidden,
                          onClick: () => {
                            this.inputRef.current.focus();
                            clearSelection();
                            this.handleOnChange();
                          },
                          tabIndex: isBtnHidden ? -1 : 0
                        })}
                      />
                      <ToggleMenuBtn
                        size="SM"
                        data-test={this.getDataTestId(
                          dataTestBuilder,
                          dataTestId,
                          "button-toggle"
                        )}
                        {...getToggleButtonProps({
                          disabled,
                          onClick: this.handleToggleButtonOnClick
                        })}
                      />
                    </Fragment>
                  ),
                  ...inputComponent
                }}
                InputLabelProps={{
                  ...getLabelProps(),
                  ...InputLabelProps
                }}
                FormHelperTextProps={{
                  ...FormHelperTextProps
                }}
                {...getInputProps({ onBlur })}
              />
              {isOpen ? (
                <SelectFilterMenu
                  dataTestId={dataTestId}
                  dataTestBuilder={dataTestBuilder}
                  isFetching={isFetching}
                  isOpen={isOpen}
                  menuItems={menuItems}
                  inputValue={inputValue}
                  highlightedIndex={highlightedIndex}
                  selectedItem={selectedItem}
                  getItemProps={getItemProps}
                  menuProps={
                    isOpen ? getMenuProps({}, { suppressRefError: true }) : {}
                  }
                  popperNode={this.inputRef.current}
                />
              ) : null}
            </div>
          );
        }}
      </Downshift>
    );
  }
}

SelectFilter.defaultProps = {
  label: null,
  helperText: null,
  placeholder: null,
  required: false,
  isFetching: false,
  onChange: null,
  onLoadOptions: null,
  filterOptions: null,
  isCustomInput: false,
  includeNonOptionData: true
};

/**
 * Please see Downshift for further options
 * https://github.com/paypal/downshift (they are all passed in)
 */
SelectFilter.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * name (required)
   * identifies the field (field name)
   */
  name: PropTypes.string.isRequired,

  /**
   * label (optional)
   */
  label: PropTypes.string,

  /**
   * label (optional)
   */
  helperText: PropTypes.string,

  /**
   * placeholder (optional)
   */
  placeholder: PropTypes.string,

  /**
   * options (required)
   */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ])
  ).isRequired,

  /**
   * initialValue (optional)
   * defaults to empty string
   */
  initialValue: PropTypes.string,

  /**
   * filterOptions (optional)
   * defines how the options are filtered
   * strategy - defaults to 'CONTAINS'
   */
  filterOptions: PropTypes.shape({
    strategy: PropTypes.oneOfType([
      PropTypes.oneOf(["CONTAINS", "STARTS_WITH"]),
      PropTypes.func
    ])
  }),

  /**
   * error (optional)
   * default to false
   * colors the field 'red'
   */
  error: PropTypes.bool,

  /**
   * disabled (optional)
   * default to false
   */
  disabled: PropTypes.bool,

  /**
   * required (optional)
   * Adds asterisk to label
   */
  required: PropTypes.bool,

  /**
   * isFetching (optional)
   * default to false
   * used in conjunction with onLoadOptions
   */
  isFetching: PropTypes.bool,

  /**
   * onChange (optional)
   * called when the downshift selected item changes
   */
  onChange: PropTypes.func,

  /**
   * onBlur
   * called when the input field is blurred
   */
  onBlur: PropTypes.func,

  /**
   * onLoadOptions (optional)
   * Used if the select items are loaded asynchronously
   * provide the action to call when downshift state 'isOpen' set to true
   */
  onLoadOptions: PropTypes.func,

  /**
   * Used if we allow the user to type in custom input that's not part of the options
   */
  isCustomInput: PropTypes.bool,

  /**
   * used if we need a custom input component
   */
  customInputComponent: PropTypes.func,

  /**
   * This prop is used when the selected value may not be one of the options available
   * If includeNonOptionData is true (default is true), we will display the selected option on top of the available options.
   * If includeNonOptionData is false, we will empty the selected option.
   */
  includeNonOptionData: PropTypes.bool,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(SelectFilter);
