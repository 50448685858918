import { utils } from "@cauldron/core";

const { removePhoneFormat } = utils;

/**
 * Transform our flat incomeModel into the API MODEL
 * with date transformations.
 */
export const transformSent = data => {
  if (!data.type || data.type === "Job") {
    return {
      type: "Job",
      employment: true,
      income: data.pay,
      payFrequency: data.payFrequency,
      employmentDetails: {
        position: data.position,
        jobType: data.jobType,
        companyName: data.companyName,
        address: {
          street: data.street,
          unitNumber: data.unit,
          city: data.city,
          province: data.province,
          country: "Canada",
          postalCode: data.postalCode
        },
        contactName: data.contactName,
        phoneNumber: {
          number: data.phoneNumber
            ? removePhoneFormat(data.phoneNumber)
            : data.phoneNumber,
          ext: data.ext
        }
      },
      startMonth: data.startMonth,
      startYear: data.startYear
    };
  }

  return {
    type: data.type,
    income: data.pay,
    payFrequency: data.payFrequency,
    startMonth: data.startMonth,
    startYear: data.startYear,
    pay: data.pay,
    description: data.description,
    employmentDetails: {
      businessType: data.businessType || null,
      industrySector: data.industrySector || null
    }
  };
};
