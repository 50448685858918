/**
 * getHiddenColumns
 * @param columns
 * @returns {*}
 */
function getHiddenColumns(columns) {
  return columns
    .filter(col => !col.show && !col.alwaysVisible)
    .map(col => col.id);
}

/**
 * resolveInitialVisibility
 * @param columns
 * @returns {*}
 */
function resolveInitialVisibility(columns) {
  const resolve = col => ({ ...col, show: col.show !== false });
  return columns.map(resolve);
}

/**
 * resolveVisibility
 * @param columns
 * @param hiddenColumns
 * @returns {*}
 */
function resolveVisibility(columns, hiddenColumns) {
  const resolve = col => ({
    ...col,
    show:
      hiddenColumns && col.id
        ? hiddenColumns.indexOf(col.id) < 0
        : col.show !== false
  });
  return columns.map(resolve);
}

/**
 * showPagination
 * @param length
 * @param defaultPageSize
 * @returns {boolean}
 */
function showPagination(length, defaultPageSize) {
  if (!length) return false;
  return length > defaultPageSize;
}

export {
  getHiddenColumns,
  resolveInitialVisibility,
  resolveVisibility,
  showPagination
};
