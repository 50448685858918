"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var styles = function styles() {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    alignX: {
      alignItems: "center"
    },
    alignY: {
      justifyContent: "center"
    },
    fullHeight: {
      height: "100%"
    }
  };
};

var _default = styles;
exports.default = _default;