import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Icon from "@material-ui/core/Icon";
import { styles } from "./styles/detail.expander.styles";
import { AlchemyIconButton } from "../Buttons";
import ExpanderMenu from "./components/header.menu";
import { ROOT, HEADER, ICON } from "../constants";

const ContentWrapper = ({
  children,
  classes,
  hasTwoColumns,
  hasNestedExpander,
  wrapperStyle,
  noPadding,
  dataTestId
}) => {
  return (
    <div
      data-test={dataTestId}
      style={wrapperStyle}
      className={classNames([
        hasTwoColumns ? classes.twoColumnDiv : classes.columnDiv,
        hasNestedExpander ? "nested" : "",
        "content_wrapper",
        noPadding ? classes.noPadding : ""
      ])}
    >
      {children}
    </div>
  );
};

/**
 * DETAIL EXPANDER
 * this component shows those expanders that are being used inside tabs and other expanders (as child expander).
 * @type Component
 * @author Ehsan / Stanley
 * @version 2.0.0
 * @param {string} title
 * @param {array} contentArray dynamic content element array
 * @param {boolean} hasTwoColumns whether we display in two column format or one column format
 * @param {boolean} defaultExpanded control the default expanded state for the detail expander
 * @param {func} IconComponent the icon component that is optional and displayed next to the expander icon
 */
class DetailExpander extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeaderSeparator: true,
      expanded: props.defaultExpanded,
      menuOpen: false,
      anchorEl: null
    };
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) {
      onRef(this);
    }
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) {
      onRef(undefined);
    }
  }

  getDataTestLocator = (...args) => {
    const { dataTestId, dataTestBuilder } = this.props;
    return dataTestBuilder && dataTestBuilder(dataTestId, ...args);
  };

  getExpandIcon = () => {
    const { classes, noIcon, tracker, dataTestBuilder } = this.props;
    const { expanded } = this.state;

    if (noIcon) return;

    const currentExpandedValue = tracker ? tracker.expanded : expanded;

    const ExpandIcon = currentExpandedValue ? (
      <ExpandLessIcon className={classes.expandIcon} />
    ) : (
      <ExpandMoreIcon className={classes.expandIcon} />
    );

    return (
      <AlchemyIconButton
        size="MD"
        dataTestId={this.getDataTestLocator(ICON)}
        dataTestBuilder={dataTestBuilder}
      >
        {ExpandIcon}
      </AlchemyIconButton>
    );
  };

  generateHeaderMenu = () => {
    const { anchorEl, menuOpen } = this.state;
    const {
      classes,
      menuList,
      onMenuItemClick,
      dataTestBuilder,
      dataTestId
    } = this.props;
    const menuDataTestId =
      dataTestBuilder && dataTestBuilder(dataTestId, "section-expander-menu");

    return (
      <Fragment>
        <AlchemyIconButton
          aria-label="Expander Menu"
          aria-owns={anchorEl ? "expander-menu" : null}
          aria-haspopup="true"
          onClick={event => this.handleExpanderMenuClick(event, true)}
          size="MD"
          dataTestId={menuDataTestId}
          dataTestBuilder={dataTestBuilder}
        >
          <MoreHorizIcon className={classes.moreHorizIcon} />
        </AlchemyIconButton>
        <ExpanderMenu
          dataTestId={menuDataTestId}
          dataTestBuilder={dataTestBuilder}
          anchorEl={anchorEl ? anchorEl : {}}
          menuOpen={menuOpen}
          menuClose={event => this.handleExpanderMenuClick(event, false)}
          menuList={menuList}
          onMenuItemClick={onMenuItemClick}
        />
      </Fragment>
    );
  };

  handleExpanderMenuClick = (event, action) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    action
      ? this.setState({ menuOpen: action, anchorEl: event.currentTarget })
      : this.setState({ menuOpen: action, anchorEl: null });
  };

  onExpandClick = e => {
    const { expanded } = this.state;
    e.stopPropagation();
    const { handleExpandClick, tracker } = this.props;
    if (handleExpandClick) {
      const currentVal = tracker ? tracker.expanded : expanded;
      handleExpandClick(!currentVal);
    } else {
      this.setState(prevState => ({
        expanded: !prevState.expanded
      }));
    }
  };

  generateActions = list => {
    return list.map((action, index) => {
      return this.renderActions(action, index);
    });
  };

  renderActions = (action, index) => {
    const { classes, dataTestBuilder, dataTestId } = this.props;
    return (
      <Fragment key={index}>
        <AlchemyIconButton
          dataTestId={
            dataTestBuilder && dataTestBuilder(dataTestId, `action-${index}`)
          }
          dataTestBuilder={dataTestBuilder}
          disabled={action.isDisabled}
          onClick={action.handler}
          size="MD"
        >
          <Icon classes={{ root: classes.rootIcon }}>{action.icon}</Icon>
        </AlchemyIconButton>
      </Fragment>
    );
  };
  render() {
    const {
      id,
      classes,
      title,
      hasMenu,
      contentArray,
      hasTwoColumns,
      IconComponent,
      children,
      adornments,
      hasNestedExpander,
      collapsible,
      hasHover,
      actionsList,
      isTopLevel,
      hasMissingTitle,
      importance,
      hasNewTitle,
      tracker,
      noPadding,
      onMouseEnter,
      onMouseLeave,
      wrapperStyle,
      dataTestId,
      dataTestBuilder
    } = this.props;

    const { showHeaderSeparator, expanded } = this.state;

    const hasActions = !!actionsList.length;

    const currentExpandedValue = tracker ? tracker.expanded : expanded;

    const contentWrapperDataTestId =
      dataTestBuilder &&
      dataTestBuilder(dataTestId, "expander-content-wrapper");

    return (
      <Card
        id={id}
        className={classNames([
          classes.card,
          hasNestedExpander ? classes.cardNested : "",
          "card"
        ])}
        classes={{
          root: hasHover ? classes.cardRootHover : classes.cardRoot
        }}
        data-test={this.getDataTestLocator(ROOT)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CardHeader
          data-test={this.getDataTestLocator(HEADER)}
          title={title}
          className={classNames([
            collapsible ? classes.cardHeader : classes.cardHeaderStatic,
            "nestedCardHeader"
          ])}
          classes={{
            title: classNames(
              classes.headerTitle,
              hasMissingTitle && importance ? classes[importance] : "",
              hasNewTitle ? classes.temporary : ""
            ),
            action: classes.headerAction
          }}
          action={
            <div
              data-test={
                dataTestBuilder && dataTestBuilder(dataTestId, "actions")
              }
              className={classes.actionWrapper}
            >
              {hasHover && hasActions && this.generateActions(actionsList)}
              {adornments}
              {hasMenu && this.generateHeaderMenu()}
              {IconComponent && <IconComponent />}
              {collapsible && this.getExpandIcon()}
            </div>
          }
          onClick={collapsible ? this.onExpandClick : null}
          onMouseEnter={this.onMouseEnter}
        />
        {showHeaderSeparator && (
          <hr
            className={
              isTopLevel ? classes.headerSeparatorTop : classes.headerSeparator
            }
          />
        )}
        <Collapse in={currentExpandedValue} timeout="auto">
          <CardContent
            className={classNames([
              classes.cardContent,
              "detail-expander-card-content"
            ])}
          >
            {!children &&
              contentArray &&
              contentArray.map((Content, key) => (
                <ContentWrapper
                  classes={classes}
                  key={key}
                  hasTwoColumns={hasTwoColumns}
                  hasNestedExpander={hasNestedExpander}
                  noPadding={noPadding}
                  dataTestId={contentWrapperDataTestId}
                >
                  <Content />
                </ContentWrapper>
              ))}
            {children && (
              <ContentWrapper
                classes={classes}
                hasTwoColumns={hasTwoColumns}
                hasNestedExpander={hasNestedExpander}
                wrapperStyle={wrapperStyle}
                noPadding={noPadding}
                dataTestId={contentWrapperDataTestId}
              >
                {children}
              </ContentWrapper>
            )}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

DetailExpander.defaultProps = {
  onMenuItemClick: () => {},
  hasNestedExpander: false,
  collapsible: true,
  actionsList: [],
  hasHover: false,
  dataTestId: ""
};

DetailExpander.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.oneOfType(
    [PropTypes.string.isRequired, PropTypes.element.isRequired],
    null
  ),
  contentArray: PropTypes.array,
  hasTwoColumns: PropTypes.bool.isRequired,
  defaultExpanded: PropTypes.bool,
  IconComponent: PropTypes.func,
  adornments: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  onMenuItemClick: PropTypes.func,
  hasNestedExpander: PropTypes.bool,
  collapsible: PropTypes.bool,
  actionsList: PropTypes.array,
  hasHover: PropTypes.bool,
  hasMissingTitle: PropTypes.bool,
  importance: PropTypes.oneOf(["temporary", "warning", "critical"]),
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(DetailExpander);
