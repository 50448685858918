import PropTypes from "prop-types";

/**
 * FUNDED LOAN DETAILS MODEL
 * static codes
 */

// FUNDED LOAN DETAILS  - DEFAULT PROPS
export const defaultProps = {
  codeNames: []
};

/**
 * FUNDED LOAN DETAILS - SCHEMA
 * static codes
 */
export const schema = {
  codeNames: PropTypes.array
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-loan-details-static-codes";
