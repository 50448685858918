import React from "react";
import PropTypes from "prop-types";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";

import Crumb from "./components/crumb";
import { styles } from "./styles/breadcrumb.styles";

/**
 * Breadcrumb
 * @param classes
 * @param modulePath
 * @param dataTestId
 * @returns {*}
 * @constructor
 */
const Breadcrumb = ({ classes, paths, dataTestId }) => {
  return (
    <div className={classes.breadcrumb}>
      {paths.map(({ label, path, suppressTracking = false }, i) => {
        return (
          <Crumb
            dataTestId={`${dataTestId}-${i}`}
            key={i}
            index={i}
            classes={classes}
            label={label}
            path={path}
            modLength={paths.length}
            suppressTracking={suppressTracking}
          />
        );
      })}
    </div>
  );
};

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
  dataTestId: PropTypes.string
};

export default withStyles(styles)(Breadcrumb);
