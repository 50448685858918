/**
 * SEARCH FIELD STYLES
 * @author Ryan Rivera
 * @param theme
 */
const styles = theme => ({
  searchInput: {
    color: theme.palette.primary["main"],
    "& input::-ms-clear": {
      display: "none"
    }
  },
  formControl: {
    background: theme.palette.common["white"]
  },
  rootSearchAdornment: {
    marginLeft: 0
  },
  rootClearAdornment: {
    marginRight: 0
  },
  rootBtn: {
    // width: "24px",
    // height: "24px",
    opacity: 1,
    transition: "opacity .2s ease-in",

    "&.hidden": {
      opacity: 0,
      pointerEvents: "none"
    },

    "&:hover": {
      color: theme.palette.primary["main"],
      backgroundColor: "transparent"
    }
  },
  btn: {
    height: "20px",
    width: "20px"
  },
  underline: {
    borderBottomColor: theme.palette.grey[800],
    "&:hover:before": {
      borderBottomColor: theme.palette.grey[800] + " !important"
    },
    "&:after": {
      borderBottomColor: theme.palette.primary["main"]
    }
  },
  label: {
    position: "absolute"
  }
});

export default styles;
