"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "Score";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  family: null,
  outputs: null,
  ratings: null,
  tags: null,
  vendor: null,
  version: null
};
/**
 * Protects data from change in data type
 */

var schema = {
  family: _propTypes.default.string,
  outputs: _propTypes.default.shape({}),
  ratings: _propTypes.default.shape({}),
  tags: _propTypes.default.arrayOf(_propTypes.default.string),
  vendor: _propTypes.default.string,
  version: _propTypes.default.string
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var _default = {
  defaultProps: defaultProps,
  schema: schema,
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;