import { utils } from "@cauldron/core";

const { removePhoneFormat } = utils;

export const prepareClientModelData = data => ({
  addresses: [
    {
      city: data.city,
      postalCode: data.postalCode,
      province: data.province,
      street: data.streetAddress,
      unitNumber: data.unit
    }
  ],
  emails: [
    {
      email: data.email
    }
  ],
  firstName: data.firstName,
  gender: data.gender ? data.gender : null,
  dateOfBirth: data.dateOfBirth,
  lastName: data.lastName,
  middleName: data.middleName,
  maritalStatus: data.maritalStatus,
  phoneNumbers: [
    {
      number: removePhoneFormat(data.phoneNumber),
      ext: data.extension,
      type: data.phoneType,
      primary: true
    }
  ],
  sin: data.sin,
  title: data.title
});

export const transformPersonalDetailsReceived = data => {
  return data
    .filter(client => client) // remove nulls
    .map(
      ({
        id,
        gender,
        title,
        maritalStatus,
        dateOfBirth,
        sin,
        firstName,
        lastName,
        middleName
      }) => {
        return {
          id,
          gender,
          title,
          maritalStatus,
          dateOfBirth,
          sin,
          firstName,
          lastName,
          middleName
        };
      }
    );
};
