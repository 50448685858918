import { fork, call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./credit.report.action.types";
import { fetchCreditReportSummarySuccess } from "./credit.report.actions";
import { http } from "../../http/http.service";

export const fetchCreditReportSummary = ({ applicationId, clientId }) => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.get,
        API.CREDIT_REPORTS_SUMMARY(applicationId, clientId)
      );
    },
    success: data => [put(fetchCreditReportSummarySuccess(clientId, data))]
  });
};

export function* watchDocumentAsync() {
  yield takeLatest(
    actionTypes.CREDIT_REPORT_SUMMARY_FETCH_REQUEST,
    fetchCreditReportSummary
  );
}

export default [fork(watchDocumentAsync)];
