import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { AlchemyIconButton } from "../../ui.library/Buttons";
import AlertDialog from "../../ui.library/AlertDialog";
import styles from "./styles/cancel.confirmation.styles";

function CreateCancelBtn({
  classes,
  onCancel,
  dataTestId,
  dataTestBuilder,
  isActive,
  cancelDialogTitleText,
  message,
  agreeButtonText,
  disagreeButtonText
}) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleClose = () => {
    isActive ? openConfirmDialog() : onCancel();
  };

  const handleCancellation = () => {
    closeConfirmDialog();
    onCancel();
  };

  return (
    <Fragment>
      <AlchemyIconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={handleClose}
        size="MD"
        dataTestId={
          dataTestBuilder && dataTestBuilder(dataTestId, "close-button")
        }
        dataTestBuilder={dataTestBuilder}
      >
        <Close className={classes.closeIcon} />
      </AlchemyIconButton>
      <AlertDialog
        title={cancelDialogTitleText}
        message={message}
        agreeButtonText={agreeButtonText}
        disagreeButtonText={disagreeButtonText}
        isOpen={confirmDialogOpen}
        onAgree={handleCancellation}
        onDialogClose={closeConfirmDialog}
        hasOkayButton
        dataTestId={dataTestId}
        dataTestBuilder={dataTestBuilder}
      />
    </Fragment>
  );
}

export default withStyles(styles)(CreateCancelBtn);
