"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _centerCenter = _interopRequireDefault(require("./styles/center.center.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/**
 * CenterCenter - centers children vertically and horizontally
 * needs parent to have 100% width and height
 * @param classes
 * @param vertical - default true
 * @param horizontal - default true
 * @param children
 * @returns {*}
 * @constructor
 */


function CenterCenter(_ref) {
  var _classNames;

  var classes = _ref.classes,
      vertical = _ref.vertical,
      horizontal = _ref.horizontal,
      hasFullHeight = _ref.hasFullHeight,
      children = _ref.children;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.alignX, horizontal), _defineProperty(_classNames, classes.alignY, vertical), _defineProperty(_classNames, classes.fullHeight, hasFullHeight), _classNames))
  }, children);
}

CenterCenter.defaultProps = {
  vertical: true,
  horizontal: true,
  hasFullHeight: true
};
CenterCenter.propTypes = {
  classes: _propTypes.default.objectOf(_propTypes.default.string).isRequired,
  vertical: _propTypes.default.bool,
  horizontal: _propTypes.default.bool,
  hasFullHeight: _propTypes.default.bool,
  children: _propTypes.default.node.isRequired
};

var _default = (0, _core.withStyles)(_centerCenter.default)(CenterCenter);

exports.default = _default;