import PropTypes from "prop-types";

/**
 * CLIENT MARITAL MODEL
 */

// UPDATE CLIENT MARITAL  - DEFAULT PROPS
export const defaultProps = {
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  maritalStatus: ""
};

// UPDATE CLIENT MARITAL - SCHEMA
export const schema = {
  title: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  maritalStatus: PropTypes.string
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-client-marital-update";
