import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

// LOCAL STYLES
import styles from "./styles/helper.text.styles";

const getIcon = (type, iconType) => {
  const icons = {
    info: "info",
    error: "error",
    warning: "warning",
    success: "check_circle"
  };
  const accessor = iconType ? iconType : type;
  return icons[accessor];
};

/**
 * HELPER_TEXT COMPONENT
 * @author rrive
 */
const HelperText = ({
  className,
  classes,
  type,
  iconType,
  children,
  hideIcon,
  dataTestId
}) => (
  <Typography
    className={className}
    classes={{ root: classes.messageRoot }}
    data-test={dataTestId}
  >
    {!hideIcon && (
      <Icon
        className={classNames([type, "icon"])}
        classes={{ root: classes.iconRoot }}
      >
        {getIcon(type, iconType)}
      </Icon>
    )}
    <span className={classNames([type, "message"])}>{children}</span>
  </Typography>
);

HelperText.defaultProps = {
  type: "info",
  hideIcon: false
};

HelperText.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  iconType: PropTypes.string,
  hideIcon: PropTypes.bool
};

export default withStyles(styles)(HelperText);
