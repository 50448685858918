import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REFRESH,
  USER_LOGIN_REFRESH_SUCCESS,
  USER_LOGOUT_SUCCESS
} from "../../../services/auth";

const initialState = {
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case USER_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false
      };

    case USER_LOGIN_REFRESH:
      return {
        ...state,
        isFetching: true
      };

    case USER_LOGIN_REFRESH_SUCCESS:
      return {
        ...state,
        isFetching: false
      };

    case USER_LOGOUT_SUCCESS:
      return { isFetching: false };

    default:
      return state;
  }
};
