export default [{
  name: "termsAndConditions",
  type: "TermsAndConditions",
  text: "I have read and agree to the terms and conditions and privacy policy",
  linkedResources: [{
    name: "termsAndConditions",
    version: "0.0.1"
  }, {
    name: "privacyPolicy",
    version: "0.0.1"
  }]
}];