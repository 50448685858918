import {
  CREATE_FORM_CACHE,
  FORM_CACHE,
  FORM_VALUES_CACHE,
  FORM_FETCHING_STATUS_UPDATE,
  FORM_ERRORS_UPDATE
} from "./forms.actions.types";

/**
 * formsDetailReducer handles all the actions on the forms object.
 * It doesn't have knowledge that it’s part of another data structure, or weather its dealing with existing or new data
 */

export default function formsDetailReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_FORM_CACHE:
      return {
        ...state,
        group: action.data.group,
        id: action.formId,
        values: {
          ...state.values,
          ...action.data.values
        }
      };
    case FORM_CACHE:
      return {
        ...state,
        group: action.data.group,
        id: action.formId,
        values: {
          ...state.values,
          ...action.data.values
        }
      };
    case FORM_VALUES_CACHE:
      return {
        ...state,
        values: { ...state.values, ...action.values },
        id: action.formId
      };
    case FORM_FETCHING_STATUS_UPDATE:
      return { ...state, isFetching: action.value };
    case FORM_ERRORS_UPDATE: {
      const {
        error: { related }
      } = action;
      if (related && related.length > 0) {
        return { ...state, errors: related };
      }
      return state;
    }
    default:
      return state;
  }
}
