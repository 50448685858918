const styles = theme => ({
  formControlRoot: {
    "&:hover svg": {
      color: theme.palette.grey[900]
    }
  },
  legend: {
    color: theme.palette.grey[900],
    fontSize: 14
  },
  optionLabel: {
    marginLeft: 20,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 14,
    color: theme.palette.lightGrey[900]
  }
});

export default styles;
