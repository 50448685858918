"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _keyBy = _interopRequireDefault(require("lodash/keyBy"));

var _auto = require("./auto.address");
/**
 * CanadaPost Service
 * @param pcaClass
 * @param onConfig
 * @param onPopulate
 * @param getInputProps
 * @param currValues
 * @param canadaPostKey
 * @returns {*}
 * @constructor
 */


function useAddressComplete(_ref) {
  var pcaClass = _ref.pcaClass,
      onConfig = _ref.onConfig,
      onPopulate = _ref.onPopulate,
      getInputProps = _ref.getInputProps,
      currValues = _ref.currValues,
      canadaPostKey = _ref.canadaPostKey;
  var pcaRef = (0, _react.useRef)(null);

  if (!pcaRef.current) {
    pcaRef.current = (0, _auto.getPCA)(pcaClass);
  }

  var pcaFieldsRef = (0, _react.useRef)(null);
  var pcaFieldsHash = (0, _react.useRef)(null);

  if (!pcaFieldsRef.current) {
    pcaFieldsRef.current = onConfig(pcaRef.current);
    pcaFieldsHash.current = (0, _keyBy.default)(pcaFieldsRef.current, "field");
  }

  var _useState = (0, _react.useState)(""),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      search = _useState2[0],
      setSearch = _useState2[1];

  var inputRef = (0, _react.useRef)(null);
  var pcaController = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    function search(term) {
      setSearch(term);
    }

    function blur() {
      setSearch("");
    }

    function populate(address) {
      if (onPopulate) onPopulate(address, pcaFieldsRef.current, pcaFieldsHash.current);
    }

    var eventHandlers = {
      search: search,
      blur: blur,
      populate: populate
    };
    var config = {
      key: canadaPostKey,
      defaultCountry: "Canada"
    };
    pcaController.current = (0, _auto.createController)(pcaFieldsRef.current, pcaRef.current, eventHandlers, config);
    return function () {
      pcaController.current.destroy();
      pcaController.current = null;
    };
  }, [canadaPostKey, currValues, onPopulate]);
  (0, _react.useEffect)(function () {
    function handleScroll() {
      var controller = pcaController.current;
      var autocomplete = controller.autocomplete,
          countrylist = controller.countrylist;
      var isAutoAddressVisible = autocomplete.visible;
      var isCountryListVisible = countrylist.autocomplete.visible;

      if (isAutoAddressVisible) {
        autocomplete.reposition();
      } else if (isCountryListVisible) {
        countrylist.autocomplete.reposition();
      }
    }

    document.addEventListener("scroll", handleScroll, true);
    return function () {
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, [search]);

  var getAutoAddressInputProps = function getAutoAddressInputProps(props) {
    var autoAddressInputProps = (0, _objectSpread2.default)({}, props, {
      inputRef: inputRef
    });
    return getInputProps(autoAddressInputProps);
  };

  return {
    getAutoAddressInputProps: getAutoAddressInputProps,
    pcaFieldsHash: pcaFieldsHash.current
  };
}

var _default = useAddressComplete;
exports.default = _default;