"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Icon = _interopRequireDefault(require("@material-ui/core/Icon"));

var _core = require("@material-ui/core");

var _confirmDialogCloseBtn = _interopRequireDefault(require("./styles/confirm.dialog.close.btn.styles"));

var _Buttons = require("../Buttons");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function CloseBtn(_ref) {
  var classes = _ref.classes,
      onClick = _ref.onClick;
  return _react.default.createElement("div", {
    className: classes.root
  }, _react.default.createElement(_Buttons.IconButton, {
    icon: "clear",
    "aria-label": "Close",
    onClick: onClick
  }, _react.default.createElement(_Icon.default, null, "clear")));
}

var _default = (0, _core.withStyles)(_confirmDialogCloseBtn.default)(CloseBtn);

exports.default = _default;