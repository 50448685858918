import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./spread.styles";

/**
 * Spread
 * A page layout component where the white background
 * spans the entire page. It has a header where action buttons
 * can be inserted such as a 'Close' button
 * @param classes
 * @param children
 * @param header
 * @returns {*}
 * @constructor
 */
function Spread({ classes, children, header }) {
  return (
    <div className={classes.root}>
      <div className={classes.header}>{header}</div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

Spread.propTypes = {
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(Spread);
