import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles/layout.section.title.styles";

function SectionTitle({ classes, children, dataTestId, dataTestBuilder }) {
  return (
    <Typography
      className={classes.root}
      data-test={
        dataTestBuilder && dataTestBuilder(dataTestId, "section-title")
      }
    >
      {children}
    </Typography>
  );
}

SectionTitle.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  dataTestBuilder: PropTypes.func.isRequired
};

export default withStyles(styles)(SectionTitle);
