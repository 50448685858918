import PropTypes from "prop-types";

/**
 * CLIENT PHONE MODEL
 */

// UPDATE CLIENT PHONE  - DEFAULT PROPS
export const defaultProps = {
  id: "",
  countryCode: "",
  number: "",
  ext: "",
  type: "",
  primary: true,
  flags: ["MobilePhone"]
};

// UPDATE CLIENT PHONE - SCHEMA
export const schema = {
  id: PropTypes.string,
  countryCode: PropTypes.string,
  number: PropTypes.string,
  ext: PropTypes.string,
  type: PropTypes.string,
  primary: PropTypes.bool,
  flags: PropTypes.array
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-client-phone-update";
