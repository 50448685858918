import {
  // Title-related types
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITE_FORM_CACHE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_TITLE_CANCEL,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_CACHE_VALUE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_FAILURE,
  // marital-related types
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_FORM_CACHE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_MARITAL_CANCEL,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_CACHE_VALUE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_FAILURE,
  // phone edit
  FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CACHE,
  FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CLEAN,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_FAILURE,
  // phone add
  FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CACHE,
  FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CLEAN,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_FAILURE,
  // auth user add
  FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CACHE,
  FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CLEAN,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_FAILURE,
  // auth user update
  FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CACHE,
  FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CLEAN,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_FAILURE,
  // client name
  FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM,
  FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM_CLEAR
} from "../action.types/forms.action.types";

import {
  QUICK_NOTE_CACHE,
  QUICK_NOTE_CACHE_CLEAN
} from "../action.types/quick.notes.action.types";

import {
  cancelEditFormByField,
  cacheEditFormValues,
  cachForm,
  updateFormFetchingStatus,
  formCache,
  createInitialFormState
} from "../../../../utils/reducer.utils";

const keys = [
  "clientName",
  "personal",
  "phone",
  "email",
  "residence",
  "authUser",
  "quickNotes"
];
const initialState = createInitialFormState(keys);

/**
 * Whitelist for funded.loan.details.ui
 * that will be persisted to the browser storage
 * @type {string[]}
 */
export const fundedLoanDetailsFormWhitelist = [];

/**
 * Reducer for funded.loan.details.form
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    // CLIENT - TITLE
    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITE_FORM_CACHE:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: cachForm(state.personal.update, action.data)
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_TITLE_CANCEL:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: cancelEditFormByField(
            state.personal.update,
            action.clientId,
            action.field
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_CACHE_VALUE:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: cacheEditFormValues(
            state.personal.update,
            {
              id: action.id
            },
            action.values
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: updateFormFetchingStatus(
            state.personal.update,
            action.clientId,
            "title",
            true
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_SUCCESS:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: updateFormFetchingStatus(
            state.personal.update,
            action.clientId,
            "title",
            false
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_FAILURE:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: updateFormFetchingStatus(
            state.personal.update,
            action.clientId,
            "title",
            false
          )
        }
      };

    // CLIENT - MARITAL STATUS
    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_FORM_CACHE:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: cachForm(state.personal.update, action.data)
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_CACHE_VALUE:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: cacheEditFormValues(
            state.personal.update,
            {
              id: action.id
            },
            action.values
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_EDIT_MARITAL_CANCEL:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: cancelEditFormByField(
            state.personal.update,
            action.clientId,
            action.field
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: updateFormFetchingStatus(
            state.personal.update,
            action.clientId,
            "marital",
            true
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_SUCCESS:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: updateFormFetchingStatus(
            state.personal.update,
            action.clientId,
            "marital",
            false
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_FAILURE:
      return {
        ...state,
        personal: {
          ...state.personal,
          update: updateFormFetchingStatus(
            state.personal.update,
            action.clientId,
            "marital",
            false
          )
        }
      };

    case FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CACHE:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          add: formCache.addCollection.cacheMultiple(state.authUser.add)(
            action.data
          )
        }
      };

    case FUNDED_LOAN_DETAILS_AUTH_USER_ADD_FORM_CLEAN:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          add: formCache.addCollection.cleanMultiple(state.authUser.add)(
            action.clientId,
            action.index
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          add: updateFormFetchingStatus(
            state.authUser.add,
            action.clientId,
            undefined,
            true,
            action.index
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_SUCCESS:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          add: formCache.addCollection.cleanMultiple(state.authUser.add)(
            action.clientId,
            action.index
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_FAILURE:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          add: updateFormFetchingStatus(
            state.authUser.add,
            action.clientId,
            undefined,
            false,
            action.index
          )
        }
      };

    case FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CACHE:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          update: formCache.updateCollection.cache(state.authUser.update)(
            action.data
          )
        }
      };

    case FUNDED_LOAN_DETAILS_AUTH_USER_UPDATE_FORM_CLEAN:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          update: formCache.updateCollection.clean(state.authUser.update)(
            action.authUserId
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          update: updateFormFetchingStatus(
            state.authUser.update,
            action.authUser.authUserId,
            undefined,
            true
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_SUCCESS:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          update: formCache.updateCollection.clean(state.authUser.update)(
            action.authUser.id
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_FAILURE:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          update: updateFormFetchingStatus(
            state.authUser.update,
            action.authUserId,
            undefined,
            false
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CACHE:
      return {
        ...state,
        phone: {
          ...state.phone,
          add: formCache.addCollection.cache(state.phone.add)(action.data)
        }
      };

    case FUNDED_LOAN_DETAILS_PHONE_ADD_FORM_CLEAN:
      return {
        ...state,
        phone: {
          ...state.phone,
          add: formCache.addCollection.clean(state.phone.add)(
            action.clientId,
            0
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD:
      return {
        ...state,
        phone: {
          ...state.phone,
          add: updateFormFetchingStatus(
            state.phone.add,
            action.clientId,
            undefined,
            true
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_SUCCESS:
      return {
        ...state,
        phone: {
          ...state.phone,
          add: formCache.addCollection.clean(state.phone.add)(
            action.clientId,
            0
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_FAILURE:
      return {
        ...state,
        phone: {
          ...state.phone,
          add: updateFormFetchingStatus(
            state.phone.add,
            action.clientId,
            undefined,
            false
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CACHE:
      return {
        ...state,
        phone: {
          ...state.phone,
          update: formCache.updateCollection.cache(state.phone.update)(
            action.data
          )
        }
      };

    case FUNDED_LOAN_DETAILS_PHONE_EDIT_FORM_CLEAN:
      return {
        ...state,
        phone: {
          ...state.phone,
          update: formCache.updateCollection.clean(state.phone.update)(
            action.phoneId
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE:
      return {
        ...state,
        phone: {
          ...state.phone,
          update: updateFormFetchingStatus(
            state.phone.update,
            action.phone.phoneId,
            undefined,
            true
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_SUCCESS:
      return {
        ...state,
        phone: {
          ...state.phone,
          update: formCache.updateCollection.clean(state.phone.update)(
            action.phoneId
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_FAILURE:
      return {
        ...state,
        phone: {
          ...state.phone,
          update: updateFormFetchingStatus(
            state.phone.update,
            action.phoneId,
            undefined,
            false
          )
        }
      };

    // QUICK NOTE CACHING
    case QUICK_NOTE_CACHE:
      return {
        ...state,
        quickNotes: {
          ...state.quickNotes,
          add: formCache.addCollection.cache(state.quickNotes.add)(action.data)
        }
      };

    case QUICK_NOTE_CACHE_CLEAN:
      return {
        ...state,
        quickNotes: {
          ...state.quickNotes,
          add: formCache.addCollection.clean(state.quickNotes.add)(
            action.data.id,
            action.data.index
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM:
      return {
        ...state,
        clientName: {
          add: [],
          update: formCache.updateCollection.cache(state.clientName.update)(
            action.data
          )
        }
      };

    case FUNDED_LOAN_DETAILS_CLIENT_EDIT_NAME_FORM_CLEAR:
      return {
        ...state,
        clientName: {
          add: [],
          update: formCache.updateCollection.clean(state.clientName.update)(
            action.clientId
          )
        }
      };

    default:
      return state;
  }
};
