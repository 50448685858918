"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeActionCreator = makeActionCreator;
exports.getRangeOptions = getRangeOptions;
exports.makeActionTypes = makeActionTypes;
exports.compactObj = compactObj;
exports.initializeValues = initializeValues;
exports.generateTestIds = exports.hasKey = exports.isTabKey = exports.isEnterKey = exports.objectsHaveDiff = exports.normalizeToAnnual = exports.normalizeToMonthly = exports.sanitizeCurrencyValue = exports.promote = exports.getSelectedOptionLabel = exports.getSelectedOption = exports.getSelectOptions = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _find = _interopRequireDefault(require("lodash/find"));

var _omitBy = _interopRequireDefault(require("lodash/omitBy"));

var _isNaN = _interopRequireDefault(require("lodash/isNaN"));

var _uniq = _interopRequireDefault(require("lodash/uniq"));

var _kebabCase = _interopRequireDefault(require("lodash/kebabCase"));

var _pickBy = _interopRequireDefault(require("lodash/pickBy"));

var _isNil = _interopRequireDefault(require("lodash/isNil"));

var _formatText = require("./format.text.utils");
/**
 * APP UTILS
 * app.utils are pure utility functions
 * these functions should not have any side-effects
 * app.utils should not depend on project code or core code
 * app.utils should not perform any translations
 * app.utils can depend on other utils within the /utils folder
 */

/**
 * Get a list of select options
 * @param optionArray
 * @param i18n
 * @returns {*}
 */


var getSelectOptions = function getSelectOptions(optionArray, i18n) {
  if (!optionArray) {
    return [];
  }

  return optionArray.map(function (_ref) {
    var label = _ref.label,
        value = _ref.value;
    return {
      label: i18n._(label),
      value: value
    };
  });
};
/**
 * Get the selected option from the option array
 * @param optionArray
 * @param selectedValue
 * @returns {*}
 */


exports.getSelectOptions = getSelectOptions;

var getSelectedOption = function getSelectedOption(optionArray, selectedValue) {
  return (0, _find.default)(optionArray, {
    value: selectedValue
  });
};
/**
 * Get the selected option's (translated) label
 * @param optionArray
 * @param selectedValue
 * @param i18n
 * @returns {string}
 */


exports.getSelectedOption = getSelectedOption;

var getSelectedOptionLabel = function getSelectedOptionLabel(optionArray, selectedValue, i18n) {
  var selectedOption = getSelectedOption(optionArray, selectedValue);
  return i18n && selectedOption && selectedOption.label ? i18n._(selectedOption.label) : selectedValue;
};
/**
 * PROMOTE
 * promotes an object to index 0 of an array based on key/value match
 * @param key
 * @param value
 * @param arr
 * @returns {*}
 * @author Ryan R
 */


exports.getSelectedOptionLabel = getSelectedOptionLabel;

var promote = function promote(key, value, arr) {
  if (!arr) {
    return [];
  }

  var newArr = arr;

  for (var i = 0; i < newArr.length; i++) {
    if (newArr[i][key] === value) {
      var a = newArr.splice(i, 1); // removes the item

      newArr.unshift(a[0]); // adds it back to the beginning

      break;
    }
  }

  return newArr;
};
/**
 * SANITIZE CURRENCY VALUE
 * returns zero if value is not a number
 * parses string numbers and returns a number type for further calc
 * @param value
 * @returns {number}
 * @author Ryan R
 */


exports.promote = promote;

var sanitizeCurrencyValue = function sanitizeCurrencyValue(value) {
  if (typeof value === "number") return value;
  if (typeof value === "string" && !(0, _isNaN.default)(value)) return parseFloat(value);
  return 0;
};
/**
 * NORMALIZE TO MONTHLY
 * Normalize value to monthly
 * @author Ryan R
 */


exports.sanitizeCurrencyValue = sanitizeCurrencyValue;
var normalizeToMonthly = {
  Annually: function Annually(value) {
    return sanitizeCurrencyValue(value) / 12;
  },
  SemiAnnually: function SemiAnnually(value) {
    return sanitizeCurrencyValue(value) * 2 / 12;
  },
  Quarterly: function Quarterly(value) {
    return sanitizeCurrencyValue(value) * 4 / 12;
  },
  Monthly: function Monthly(value) {
    return sanitizeCurrencyValue(value);
  },
  SemiMonthly: function SemiMonthly(value) {
    return sanitizeCurrencyValue(value) * 24 / 12;
  },
  BiWeekly: function BiWeekly(value) {
    return sanitizeCurrencyValue(value) * 26 / 12;
  },
  Weekly: function Weekly(value) {
    return sanitizeCurrencyValue(value) * 52 / 12;
  },
  Daily: function Daily(value) {
    return sanitizeCurrencyValue(value) * 365 / 12;
  },
  Unknown: function Unknown(value) {
    return sanitizeCurrencyValue(value);
  }
};
/**
 * NORMALIZE TO ANNUAL
 * Normalize value to annual
 * @author Ryan R
 */

exports.normalizeToMonthly = normalizeToMonthly;
var normalizeToAnnual = {
  Annually: function Annually(value) {
    return sanitizeCurrencyValue(value);
  },
  SemiAnnually: function SemiAnnually(value) {
    return sanitizeCurrencyValue(value) * 2;
  },
  Quarterly: function Quarterly(value) {
    return sanitizeCurrencyValue(value) * 4;
  },
  Monthly: function Monthly(value) {
    return sanitizeCurrencyValue(value) * 12;
  },
  SemiMonthly: function SemiMonthly(value) {
    return sanitizeCurrencyValue(value) * 24;
  },
  BiWeekly: function BiWeekly(value) {
    return sanitizeCurrencyValue(value) * 26;
  },
  Weekly: function Weekly(value) {
    return sanitizeCurrencyValue(value) * 52;
  },
  Daily: function Daily(value) {
    return sanitizeCurrencyValue(value) * 365;
  },
  Unknown: function Unknown(value) {
    return sanitizeCurrencyValue(value);
  }
};
/**
 * DEEP COMPARISON BETWEEN 2 OBJECTS
 * prev Object VS curr Object
 * @author Ehsan
 */

exports.normalizeToAnnual = normalizeToAnnual;

var objectsHaveDiff = function objectsHaveDiff(prev, curr) {
  var diff = (0, _omitBy.default)(curr, function (v, k) {
    return prev[k] === v;
  });
  return !!Object.keys(diff).length;
};
/**
 * KEY UTILS
 * event.key is react's synthetic event wrapper
 * falls back to event.which
 * @param event
 * @returns {boolean}
 */


exports.objectsHaveDiff = objectsHaveDiff;

var isEnterKey = function isEnterKey(event) {
  if (event && event.key) {
    return event.key === "Enter";
  } else if (event && event.which) {
    return event.which === 13;
  } else {
    return false;
  }
};

exports.isEnterKey = isEnterKey;

var isTabKey = function isTabKey(event) {
  if (event && event.key) {
    return event.key === "Tab";
  } else if (event && event.which) {
    return event.which === 9;
  } else {
    return false;
  }
};
/**
 * Determine whether or not the object has specific key
 * @author Ehsan
 * @param (object) object
 * @param (key) key
 */


exports.isTabKey = isTabKey;

var hasKey = function hasKey(object, key) {
  return !(!object.hasOwnProperty(key) && !object[key]);
};
/**
 * this util checks the data-test feature flag before creating any data-test attribute
 * @param args
 * @returns {*}
 */


exports.hasKey = hasKey;

var generateTestIds = function generateTestIds() {
  var delimiter, normalizedArgs;

  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  if ((window.REACT_APP_ENABLE_DATA_TEST === "true" || process.env.REACT_APP_DEFAULT_THEME === "portal") && args.length > 0) {
    var filterArgs = args.filter(function (arg) {
      return typeof arg === "string" || typeof arg === "number";
    });

    if (process.env.REACT_APP_DEFAULT_THEME === "portal") {
      delimiter = "-";
      normalizedArgs = filterArgs.map(function (arg) {
        return (0, _kebabCase.default)(arg);
      });
    } else {
      delimiter = "--";
      normalizedArgs = (0, _toConsumableArray2.default)(filterArgs);
    }

    var testId = _formatText.concatText.apply(void 0, [delimiter].concat((0, _toConsumableArray2.default)(normalizedArgs)));

    return testId ? testId.toLowerCase() : testId;
  }

  return null;
};
/**
 *
 * @param type
 * @param argNames
 * @returns {function(...[*]): {type: *}}
 */


exports.generateTestIds = generateTestIds;

function makeActionCreator(type) {
  for (var _len2 = arguments.length, argNames = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    argNames[_key2 - 1] = arguments[_key2];
  }

  return function () {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    var action = {
      type: type
    };
    argNames.forEach(function (arg, index) {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}
/**
 * Get a numerical range of options as strings
 * @param start
 * @param end
 * @param reverse
 * @returns {array}
 */


function getRangeOptions(start, end, reverse) {
  if (start > end) {
    return;
  }

  var opts = [];
  var i = start;

  while (i <= end) {
    opts.push({
      label: i.toString(),
      value: i.toString()
    });
    i++;
  }

  if (reverse) {
    return opts.reverse();
  }

  return opts;
}
/**
 * makeActionTypes
 * @param nameSpace
 * @param actionType
 * @param suffix
 * @returns {*}
 */


function makeActionTypes(nameSpace, actionType) {
  var suffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : []; // nameSpace validations

  if (!nameSpace) throw Error("Missing nameSpace");
  if (nameSpace !== nameSpace.toLowerCase()) throw Error("nameSpace must be all lowercase"); // actionType validations

  if (!actionType) throw Error("Missing actionType");
  if (actionType !== actionType.toUpperCase()) throw Error("actionType must be all uppercase");
  var defaults = ["REQUEST", "SUCCESS", "FAILURE"];
  var actions = (0, _uniq.default)([].concat(defaults, (0, _toConsumableArray2.default)(suffix)));

  function reducer(acc, curr) {
    var action = "".concat(actionType, "_").concat(curr);
    acc[action] = "".concat(nameSpace, "/").concat(action);
    return acc;
  }

  return actions.reduce(reducer, {});
}
/**
 * compactObj
 * removes fields that are empty string, undefined or null
 * @param values
 * @returns {*}
 */


function compactObj(values) {
  if ((0, _isNil.default)(values) || (0, _typeof2.default)(values) !== "object") return values;
  if (Object.keys(values).length === 0) return values;
  return (0, _pickBy.default)(values, function (value) {
    return !(0, _isNil.default)(value) && value !== "";
  });
}
/**
 * initializeValues
 * initializes form values to set default "" if value is null or undefined
 * @param values
 * @param defaultInitialValue - initialise field values to the passed argument only if it equals null or empty string
 */


function initializeValues(values, defaultInitialValue) {
  if (defaultInitialValue === null || defaultInitialValue === "") {
    var initialValues = (0, _objectSpread2.default)({}, values);

    for (var key in values) {
      if (values.hasOwnProperty(key) && (0, _isNil.default)(values[key])) {
        initialValues[key] = defaultInitialValue;
      }
    }

    return initialValues;
  } else {
    return values;
  }
}