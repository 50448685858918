"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var styles = function styles(theme) {
  return {
    logo: {
      cursor: "pointer"
    },
    blue: _objectSpread({}, theme.altLogo),
    white: _objectSpread({}, theme.logo),
    small: {
      height: "35px",
      width: "146px"
    },
    medium: {
      height: "41px",
      width: "173px"
    },
    large: {
      height: "45px",
      width: "189px"
    },
    xlarge: {
      height: "48px",
      width: "204px"
    },
    xxlarge: {
      height: "72px",
      width: "300px"
    }
  };
};

exports.styles = styles;