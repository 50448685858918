const styles = theme => ({
  fieldContainerWrapper: {
    padding: "0px 4px"
  },
  fieldContainer: {
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: "2px",
      "& > div:first-child": {
        display: "block",
        "&:hover": {
          cursor: "pointer"
        }
      }
    }
  },
  fieldContainerActions: {
    position: "absolute",
    right: "0px",
    top: "0px",
    display: "none",
    backgroundColor: theme.palette.secondary.light,
    zIndex: 1
  },
  fieldContainerBody: {
    padding: "0px 12px"
  },
  icon: theme.icon.icon16,
  iconDisabled: Object.assign({}, theme.icon.icon16, {
    color: theme.palette.lightGrey[900]
  })
});
export default styles;
