export const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    minHeight: 48,
    display: "flex",
    justifyContent: "space-between"
  },
  gutters: {
    padding: "0 16px"
  }
});
