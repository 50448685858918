/**
 * BUTTON_GROUP STYLES
 * @author ryanr
 * @param theme {object}
 */
const styles = theme => ({
  root: {
    "& > button + button": {
      marginLeft: theme.spacing.unit * 2
    },
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  center: {
    justifyContent: "center"
  },
  left: {
    justifyContent: "flex-start"
  }
});

export default styles;
