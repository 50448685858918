import React from "react";
import PropTypes from "prop-types";
import {
  Action,
  ActionGroup
} from "../../../ui.library/NestedMenu/components/action.menu";

function ColumnSettingsReset({ dataTestId, resetText, onClick }) {
  return (
    <ActionGroup dataTestId={dataTestId.settings.actionGroup}>
      <Action onClick={onClick} dataTestId={dataTestId.settings.actionRestore}>
        {resetText}
      </Action>
    </ActionGroup>
  );
}

ColumnSettingsReset.propTypes = {
  dataTestId: PropTypes.object,
  resetText: PropTypes.string,
  onClick: PropTypes.func
};

export default ColumnSettingsReset;
