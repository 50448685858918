"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderMissing = renderMissing;
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectSpread3 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRedux = require("react-redux");

var _react2 = require("@lingui/react");

var _loglevel = require("../loglevel");

var log = (0, _loglevel.create)("i18n Service");

function renderMissing(language, id) {
  return id ? "_".concat(id.toUpperCase(), "_") : null;
}

function I18nLoader(_ref) {
  var initialState = _ref.initialState,
      language = _ref.language,
      children = _ref.children,
      loadCatalog = _ref.loadCatalog,
      Loading = _ref.loading,
      debugMode = _ref.debugMode;

  var _useState = (0, _react.useState)(initialState),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      catalogs = _useState2[0],
      setCatalogs = _useState2[1];

  (0, _react.useEffect)(function () {
    loadCatalog(language).then(function (catalog) {
      return setTimeout(function () {
        return setCatalogs((0, _objectSpread3.default)({}, catalogs, (0, _defineProperty2.default)({}, language, catalog)));
      }, Loading ? 200 : 0);
    });
  }, [language] // eslint-disable-line
  ); // Skip rendering when catalog isn't loaded.

  if (!catalogs[language]) return Loading; // Configure props

  var i18nProps = {
    language: language,
    catalogs: catalogs
  }; // Warn missing translation if not in production and not in uat

  if (debugMode) {
    i18nProps.missing = renderMissing;
    log.debug("i18n is running in debug mode.");
  }

  return _react.default.createElement(_react2.I18nProvider, i18nProps, children);
}

I18nLoader.defaultProps = {
  loading: null,
  initialState: {},
  debugMode: false
};
I18nLoader.propTypes = {
  children: _propTypes.default.node.isRequired,

  /**
   * Connected to the store to retrieve the locale
   */
  language: _propTypes.default.string.isRequired,

  /**
   * loadCatalog
   * required async function that returns a promise
   * this function imports the catalog from a json file or js file in production
   */
  loadCatalog: _propTypes.default.func.isRequired,

  /**
   * Optional loading component
   */
  loading: _propTypes.default.node,

  /**
   * Used for testing
   */
  initialState: _propTypes.default.shape({}),

  /**
   * debug mode
   */
  debugMode: _propTypes.default.bool
}; // Example: depends on implementation of reducer

var getLanguage = function getLanguage(state) {
  return state.i18n.locale;
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    language: getLanguage(state)
  };
};

var _default = (0, _reactRedux.connect)(mapStateToProps)(I18nLoader);

exports.default = _default;