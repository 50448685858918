import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles/layout.well.styles";

function Well({ classes, children, width }) {
  const style = {
    width
  };
  return (
    <div className={classes.root} style={style}>
      {children}
    </div>
  );
}

Well.defaultProps = {
  width: "auto"
};

Well.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withStyles(styles)(Well);
