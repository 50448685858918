/**
 * COMMON STYLES
 * @author rrive
 * @param theme {object}
 */
const formControlStyles = theme => ({
  // Form control.classes
  formControlRoot: {
    "& label + div": {
      marginTop: 0,
      paddingTop: "12px"
    },
    paddingTop: "4px",
    paddingBottom: "4px"
  },

  // InputLabelProps.classes
  labelFormControl: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.lightGrey[900],
    transform: "translate(0, 17px) scale(1)",
    zIndex: 1,
    pointerEvents: "none",
    // Deals with labels that have error
    "&.error": {
      color: theme.palette.error["main"]
    },
    // Deals with labels that are focused
    "&.focused:not(.error)": {
      color: theme.palette.primary["main"]
    },
    // Deals with labels that are disabled
    "&.disabled:not(.error)": {
      color: theme.palette.grey[900]
    }
  },
  labelShrink: {
    transform: "translate(0, 3px) scale(.85)",
    color: theme.palette.grey[900]
  },

  // InputProps.classes
  inputRoot: {
    color: theme.palette.grey[900],
    background: theme.palette.lightGrey[100],
    boxShadow: `-12px 0px 0px 0px ${
      theme.palette.lightGrey[100]
    }, 12px 0px 0px 0px ${theme.palette.lightGrey[100]}`,
    borderRadius: 2,
    fontSize: "14px",
    lineHeight: "16px",
    "& input": {
      padding: "3px 0"
    },
    // Deals with input field errors
    "&.error": {
      color: theme.palette.error["main"]
    },
    // Deals with input field focused
    "&.focused:not(.error)": {
      color: theme.palette.primary["main"]
    },
    // Deals with input field disabled
    "&.disabled:not(.error)": {
      color: theme.palette.grey[900],
      background: "transparent",
      boxShadow: "none"
    }
  },
  fullWidth: {
    "& div:first-child": {
      width: "inherit",
      "& .react-datepicker-wrapper": {
        width: "inherit",
        "& > div": {
          width: "inherit"
        }
      }
    }
  },
  inputUnderline: {
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary["main"]}`
    },
    "&:before": {
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    "&:hover:not(.disabled):not(.focused):not(.error):before": {
      borderBottom: `2px solid ${theme.palette.grey[400]}`
    },
    "&.disabled:before": {
      borderBottom: `1px dashed ${theme.palette.grey[400]}`
    }
  },

  // Helper Text
  helperTextRoot: {
    color: theme.palette.grey[600],
    lineHeight: "16px",
    fontSize: "12px"
  },
  helperTextDisabled: {
    color: `${theme.palette.grey[900]} !important`,
    "& + div": {
      background: "transparent",
      boxShadow: "none"
    }
  },
  helperTextError: {
    lineHeight: "16px",
    fontSize: "12px",
    color: `${theme.palette.error["main"]} !important`
  },
  helperTextFocused: {
    lineHeight: "16px",
    fontSize: "12px",
    color: theme.palette.primary["main"]
  }
});

export default formControlStyles;
