import { utils } from "@cauldron/core";
import * as actionTypes from "./notes.action.types";

const { makeActionCreator } = utils;

export const fetchLoanApplicationNotes = applicationId => ({
  type: actionTypes.LOAN_APPLICATION_NOTES_FETCH,
  applicationId
});

export const fetchLoanApplicationNotesSuccess = data => {
  const { results } = data;
  return {
    type: actionTypes.LOAN_APPLICATION_NOTES_FETCH_SUCCESS,
    data: results
  };
};

export const addLoanApplicationNotes = makeActionCreator(
  actionTypes.LOAN_APPLICATION_NOTES_ADD,
  "applicationId",
  "formId",
  "data"
);

export const addLoanApplicationNotesSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_NOTES_ADD_SUCCESS,
  "data"
);

export const addLoanApplicationNotesFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_NOTES_ADD_FAILURE,
  "data"
);

export const fetchLoanApplicationNotesFailure = error => ({
  type: actionTypes.LOAN_APPLICATION_NOTES_FETCH_FAILURE,
  error
});
