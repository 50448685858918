export const styles = theme => ({
  card: {
    boxShadow: "none"
  },
  cardRoot: {
    borderRadius: 0
  },
  cardRootHover: {
    backgroundColor: theme.palette.secondary.light,
    "& .content_wrapper": {
      backgroundColor: theme.palette.secondary.light,
      "& .nestedCardHeader": {
        backgroundColor: theme.palette.secondary.light
      }
    }
  },
  cardHeader: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "pointer"
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main
    },
    paddingLeft: "16px",
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: "32px"
  },
  cardHeaderStatic: {
    paddingLeft: "16px",
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: "32px"
  },
  headerRoot: {},
  headerTitle: {
    color: theme.palette.grey[900],
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px"
  },
  temporary: {
    color: theme.palette.lightGrey[900],
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px"
  },
  warning: {
    color: theme.palette.warning["main"],
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px"
  },
  critical: {
    color: theme.palette.error["main"],
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px"
  },
  headerAction: {
    display: "flex",
    alignItems: "baseline",
    marginTop: 0,
    marginBottom: 0,
    paddingRight: "8px"
  },
  headerSeparator: {
    height: 1,
    color: theme.palette.lightGrey[300],
    backgroundColor: theme.palette.lightGrey[300],
    border: "none",
    margin: 0,
    position: "relative",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "-1px"
  },
  collapseOverflowVisible: {
    overflow: "visible"
  },
  expandIcon: theme.icon.icon20,
  cardContent: {
    padding: 0,

    "&:last-child": {
      padding: 0
    },
    "&:hover": {
      "& > div p.short-note": {
        "&:after": {
          // backgroundColor: theme.palette.secondary.light
        }
      }
    },
    "& p.short-note": {
      "&:after": {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  columnDiv: {
    width: "100%",
    display: "inline-block",
    padding: "0 16px",
    "&.nested": {
      padding: "0 0 0 56px",
      "& .nested": {
        padding: "0 0 0 24px"
      }
    }
  },
  twoColumnDiv: {
    minWidth: "300px",
    width: "100%",
    display: "inline-block",
    verticalAlign: "top",
    padding: "0 16px",
    "&.nested": {
      padding: "0 0 0 72px",
      "& .nested": {
        padding: "0 0 0 24px"
      }
    }
  },
  itemKey: {
    width: "38%",
    display: "inline-block",
    verticalAlign: "top",
    paddingBottom: "8px"
  },
  itemContent: {
    width: "62%",
    display: "inline-block",
    verticalAlign: "top",
    wordBreak: "break-all"
  },
  itemKeyTypography: theme.typography.p3Disabled,
  itemContentTypography: theme.typography.p3Dark,
  itemKeyTwoColumns: {
    width: "38%",
    display: "inline-block",
    verticalAlign: "top",
    paddingBottom: "8px"
  },
  itemContentTwoColumns: {
    width: "62%",
    display: "inline-block",
    verticalAlign: "top"
  },
  contentSeparator: {
    height: "16px"
  },
  headerSeparatorTop: {
    width: "calc(100% - 38px)",
    height: 1,
    color: theme.palette.lightGrey[300],
    backgroundColor: theme.palette.lightGrey[300],
    border: "none",
    margin: 0,
    position: "relative",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "-1px"
  },
  moreHorizIcon: {
    fontSize: "20px",
    color: theme.palette.grey[900]
  },
  width: "16px",
  height: "16px",
  rootIcon: {
    "&:hover": {
      color: theme.palette.secondary["dark"]
    },
    "&:active": {
      color: theme.palette.secondary["dark"]
    },
    fontSize: 16
  },
  themedDetailExpander: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    "& > div .nestedCardHeader": {
      paddingRight: "16px"
    }
  },
  noPadding: {
    padding: 0
  },
  actionWrapper: {
    display: "flex"
  }
});
