import { actionTypes } from "./agent.portal.applications.action.types";

export function fetchApplication(id) {
  return {
    type: actionTypes.APPLICATION_FETCH_REQUEST,
    id
  };
}

export function fetchApplicationSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_FETCH_SUCCESS,
    payload
  };
}

export function fetchApplicationFailure(error) {
  return {
    type: actionTypes.APPLICATION_FETCH_FAILURE,
    error
  };
}

export function createApplication(payload, eventHandlers) {
  return {
    type: actionTypes.APPLICATION_CREATE_REQUEST,
    payload,
    eventHandlers
  };
}
export function createApplicationSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_CREATE_SUCCESS,
    payload
  };
}

export function createApplicationFailure(error) {
  return {
    type: actionTypes.APPLICATION_CREATE_FAILURE,
    error
  };
}

export function updateApplication(id, payload, eventHandlers) {
  return {
    type: actionTypes.APPLICATION_UPDATE_REQUEST,
    id,
    payload,
    eventHandlers
  };
}

export function updateApplicationSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_UPDATE_SUCCESS,
    payload
  };
}

export function updateApplicationFailure(error) {
  return {
    type: actionTypes.APPLICATION_UPDATE_FAILURE,
    error
  };
}
