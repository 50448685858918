import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_REFRESH,
  FORCED_USER_LOGOUT,
  USER_LOGOUT,
  PROFILE_REQUEST,
  PROFILE_REQUEST_SUCCESS,
  PROFILE_REQUEST_FAILURE,
  USER_LOGIN_REFRESH_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REQUEST_FAILURE,
  USER_LOGIN_REFRESH_FAILURE,
  PERMISSIONS_REQUEST_SUCCESS,
  PERMISSIONS_REQUEST_FAILURE,
  CONTEXT_PROFILE_REQUEST_FAILURE,
  CONTEXT_PROFILE_REQUEST_SUCCESS
} from "./auth.action.types";

/**
 * AUTHENTICATE_USER
 * @param googleAuthResponse
 * @returns {{type: string, payload: {googleAuthResponse: *}}}
 */
export const authenticateUser = googleAuthResponse => ({
  type: USER_LOGIN_REQUEST,
  payload: { googleAuthResponse }
});

export const authenticateUserSuccess = payload => ({
  type: USER_LOGIN_REQUEST_SUCCESS,
  payload
});

export const authenticateUserFailure = error => ({
  type: USER_LOGIN_REQUEST_FAILURE,
  payload: error.message
});

/**
 * RE_AUTHENTICATE_USER
 * @param googleAuthResponse
 * @returns {{type: string, payload: {googleAuthResponse: *}}}
 */
export const refreshAuthUser = googleAuthResponse => ({
  type: USER_LOGIN_REFRESH,
  payload: { googleAuthResponse }
});

export const refreshAuthUserSuccess = payload => ({
  type: USER_LOGIN_REFRESH_SUCCESS,
  payload
});

export const refreshAuthUserFailure = error => ({
  type: USER_LOGIN_REFRESH_FAILURE,
  payload: error.message
});

/**
 * LOG_OUT_USER
 * @returns {{type: string}}
 */
export const forceLogoutUser = () => ({ type: FORCED_USER_LOGOUT });

export const logoutUser = () => ({ type: USER_LOGOUT });

export const logoutUserSuccess = () => ({ type: USER_LOGOUT_SUCCESS });

export const logoutUserFailure = error => ({
  type: USER_LOGOUT_FAILURE,
  payload: error.message
});

/**
 * FETCH PROFILE
 * @returns {{type: string}}
 */
export const fetchProfile = () => ({ type: PROFILE_REQUEST });

/**
 * FETCH PROFILE SUCCESS
 * @param payload
 * @returns {{payload: *, type: string}}
 */
export const fetchProfileSuccess = payload => ({
  type: PROFILE_REQUEST_SUCCESS,
  payload
});

/**
 * FETCH PROFILE FAILURE
 * @param error
 * @returns {{type: string, error: *}}
 */
export const fetchProfileFailure = error => ({
  type: PROFILE_REQUEST_FAILURE,
  error
});

/**
 * FETCH CONTEXT PROFILE SUCCESS
 * @param payload
 * @returns {{payload: *, type: string}}
 */
export const fetchContextProfileSuccess = payload => ({
  type: CONTEXT_PROFILE_REQUEST_SUCCESS,
  payload
});

/**
 * FETCH CONTEXT PROFILE FAILURE
 * @param error
 * @returns {{type: string, error: *}}
 */
export const fetchContextProfileFailure = error => ({
  type: CONTEXT_PROFILE_REQUEST_FAILURE,
  error
});

/**
 * FETCH PERMISSIONS SUCCESS
 * @param payload
 * @returns {{payload: *, type: string}}
 */
export const fetchPermissionsSuccess = payload => ({
  type: PERMISSIONS_REQUEST_SUCCESS,
  payload
});

/**
 * FETCH PERMISSIONS FAILURE
 * @param error
 * @returns {{type: string, error: *}}
 */
export const fetchPermissionsFailure = error => ({
  type: PERMISSIONS_REQUEST_FAILURE,
  error
});
