"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _logo = _interopRequireDefault(require("./img/logo1-01.svg"));

var _logo2 = _interopRequireDefault(require("./img/logo2-01.svg"));

var FONT_FAMILY = "Lato, Helvetica, Arial, sans-serif";
var palette = {
  common: {
    white: "#FFF",
    black: "#000"
  },
  primary: {
    light: "#5ABCF4",
    main: "#008CC1",
    dark: "#005F90",
    contrastText: "#FFF"
  },
  secondary: {
    light: "#F2FAFC",
    // hover
    main: "#D9F0F5",
    // selected
    dark: "#72C7DB",
    // default
    contrastText: "#FFF"
  },
  error: {
    light: "#FF6838",
    main: "#FF2901",
    dark: "#C30000",
    contrastText: "#FFF"
  },
  warning: {
    light: "#ffed4d",
    main: "#FFBB00",
    dark: "#c78b00",
    contrastText: "#FFF"
  },
  success: {
    light: "#98ff4d",
    main: "#61CF01",
    dark: "#1e9d00",
    contrastText: "#FFF"
  },
  grey: {
    50: "#F1F1F1",
    100: "#E3E4E3",
    200: "#D5D5D5",
    300: "#C6C7C6",
    400: "#B8B9B8",
    500: "#A9ABA9",
    600: "#9A9C9A",
    700: "#8A8C8A",
    800: "#7A7C7A",
    900: "#5F625F",
    light: "#9A9C9A",
    dark: "#5F625F"
  },
  lightGrey: {
    50: "#FAFAFA",
    100: "#F5F6F5",
    200: "#EFF0F0",
    300: "#EAECEC",
    400: "#E5E7E7",
    500: "#E0E1E3",
    600: "#DADCDE",
    700: "#D6D8D9",
    800: "#CFD2D4",
    900: "#C4C7C9" // disable grey

  },
  spot: {
    0: "#E96161",
    1: "#F77B37",
    2: "#FFD800",
    3: "#81DA3A",
    4: "#34E2AB",
    5: "#30DFDC",
    // Tropical Blue
    6: "#9861E9",
    7: "#CA66EB",
    8: "#F737A3"
  },
  disabled: "#C4C7C9"
};
var _default = {
  palette: palette,
  overrides: {
    MuiRadio: {
      colorSecondary: {
        color: palette.grey.light,
        "&$checked": {
          color: palette.grey.dark
        }
      }
    },
    RadioButtonLegend: {
      color: palette.grey.dark
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: palette.primary.main
        }
      },
      focused: {}
    },
    MuiTypography: {
      h6: {
        fontSize: "14px",
        fontWeight: "bold",
        color: palette.grey.dark,
        lineHeight: "16px"
      }
    }
  },
  logo: {
    backgroundImage: "url(".concat(_logo.default, ")"),
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center"
  },
  altLogo: {
    backgroundImage: "url(".concat(_logo2.default, ")"),
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center"
  },
  typography: {
    useNextVariants: true,
    h1Dark: {
      fontSize: "48px",
      fontWeight: "bold",
      color: palette.grey[900],
      lineHeight: "56px"
    },
    h2Dark: {
      fontSize: "36px",
      fontWeight: "bold",
      color: palette.grey[900],
      lineHeight: "40px"
    },
    h3Dark: {
      fontSize: "26px",
      fontWeight: "bold",
      color: palette.grey[900],
      lineHeight: "32px"
    },
    h4Dark: {
      fontSize: "20px",
      fontWeight: "bold",
      color: palette.grey[900],
      lineHeight: "24px"
    },
    h5Dark: {
      fontSize: "16px",
      fontWeight: "bold",
      color: palette.grey[900],
      lineHeight: "18px"
    },
    h6Dark: {
      fontSize: "14px",
      fontWeight: "bold",
      color: palette.grey[900],
      lineHeight: "16px"
    },
    h7Dark: {
      fontSize: "12px",
      fontWeight: "bold",
      color: palette.grey[900],
      lineHeight: "16px"
    },
    p1Dark: {
      fontSize: "20px",
      color: palette.grey[900],
      lineHeight: "28px"
    },
    p2Dark: {
      fontSize: "16px",
      color: palette.grey[900],
      lineHeight: "20px"
    },
    p3Dark: {
      fontSize: "14px",
      color: palette.grey[900],
      lineHeight: "16px"
    },
    p4Dark: {
      fontSize: "12px",
      color: palette.grey[900],
      lineHeight: "16px"
    },
    p5Dark: {
      fontSize: "10px",
      color: palette.grey[900],
      lineHeight: "14px"
    },
    h1Light: {
      fontSize: "48px",
      fontWeight: "bold",
      color: palette.common["white"],
      lineHeight: "56px"
    },
    h2Light: {
      fontSize: "36px",
      fontWeight: "bold",
      color: palette.common["white"],
      lineHeight: "40px"
    },
    h3Light: {
      fontSize: "26px",
      fontWeight: "bold",
      color: palette.common["white"],
      lineHeight: "32px"
    },
    h4Light: {
      fontSize: "20px",
      fontWeight: "bold",
      color: palette.common["white"],
      lineHeight: "24px"
    },
    h5Light: {
      fontSize: "16px",
      fontWeight: "bold",
      color: palette.common["white"],
      lineHeight: "18px"
    },
    h6Light: {
      fontSize: "14px",
      fontWeight: "bold",
      color: palette.common["white"],
      lineHeight: "16px"
    },
    h7Light: {
      fontSize: "12px",
      fontWeight: "bold",
      color: palette.common["white"],
      lineHeight: "16px"
    },
    p1Light: {
      fontSize: "20px",
      color: palette.common["white"],
      lineHeight: "28px"
    },
    p2Light: {
      fontSize: "16px",
      color: palette.common["white"],
      lineHeight: "24px"
    },
    p3Light: {
      fontSize: "14px",
      color: palette.common["white"],
      lineHeight: "16px"
    },
    p4Light: {
      fontSize: "12px",
      color: palette.common["white"],
      lineHeight: "16px"
    },
    p5Light: {
      fontSize: "10px",
      color: palette.common["white"],
      lineHeight: "14px"
    },
    p1Error: {
      fontSize: "20px",
      color: palette.error["main"],
      lineHeight: "24px"
    },
    p2Error: {
      fontSize: "16px",
      color: palette.error["main"],
      lineHeight: "20px"
    },
    p3Error: {
      fontSize: "14px",
      color: palette.error["main"],
      lineHeight: "16px"
    },
    p4Error: {
      fontSize: "12px",
      color: palette.error["main"],
      lineHeight: "16px"
    },
    p5Error: {
      fontSize: "10px",
      color: palette.error["main"],
      lineHeight: "14px"
    },
    p1Disabled: {
      fontSize: "20px",
      color: palette.grey[600],
      lineHeight: "24px"
    },
    p2Disabled: {
      fontSize: "16px",
      color: palette.grey[600],
      lineHeight: "20px"
    },
    p3Disabled: {
      fontSize: "14px",
      color: palette.grey[600],
      lineHeight: "16px"
    },
    p4Disabled: {
      fontSize: "12px",
      color: palette.grey[600],
      lineHeight: "16px"
    },
    p5Disabled: {
      fontSize: "10px",
      color: palette.grey[600],
      lineHeight: "14px"
    },
    link: {
      textDecoration: "none",
      color: palette.primary.main
    },
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    body2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      fontFamily: FONT_FAMILY,
      lineHeight: "1.5em",
      color: palette.grey[900]
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      fontFamily: FONT_FAMILY,
      lineHeight: "1.5em",
      color: palette.grey[900]
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      fontFamily: FONT_FAMILY,
      lineHeight: "1.375em",
      color: palette.grey[900]
    },
    button: {
      fontSize: "0.875rem",
      textTransform: "normal",
      fontWeight: 500,
      fontFamily: FONT_FAMILY
    }
  },
  shape: {
    borderRadius: 2
  },
  icon: {
    icon20: {
      "&:hover": {
        color: palette.secondary["dark"]
      },
      "&:active": {
        color: palette.secondary["dark"]
      },
      width: "20px",
      height: "20px",
      color: palette.grey[900]
    },
    icon20Disabled: {
      width: "20px",
      height: "20px",
      color: palette.lightGrey[900]
    },
    icon20Selected: {
      width: "20px",
      height: "20px",
      color: palette.secondary["dark"]
    },
    icon16: {
      "&:hover": {
        color: palette.secondary["dark"]
      },
      "&:active": {
        color: palette.secondary["dark"]
      },
      width: "16px",
      height: "16px",
      color: palette.grey[900]
    },
    icon16Static: {
      width: "16px",
      height: "16px",
      color: palette.grey[900]
    }
  },
  button: {
    btn32: {
      paddingTop: "6px",
      paddingBottom: "6px",
      borderRadius: "2px",
      boxShadow: "none",
      backgroundColor: palette.secondary["dark"],
      color: palette.common["white"],
      fontSize: "14px",
      lineHeight: "16px",
      transition: "all .2s",
      minHeight: "32px",
      "&:hover": {
        backgroundColor: palette.secondary["light"],
        color: palette.grey[900]
      },
      "&:active": {
        boxShadow: "none"
      }
    }
  },
  hover: {
    backgroundColor: palette.secondary["light"],
    cursor: "pointer"
  },
  active: {
    backgroundColor: palette.secondary["light"]
  }
};
exports.default = _default;