import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";

// LOCAL STYLES
import styles from "./styles/button.group.styles";

/**
 * BUTTON_GROUP COMPONENT
 * @author ryanr
 */
function ButtonGroup(props) {
  const { classes, align, children } = props;
  return (
    <div className={classNames([classes.root, classes[align]])}>{children}</div>
  );
}

ButtonGroup.defaultProps = {
  align: "right"
};

ButtonGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  align: PropTypes.oneOf(["left", "right", "center"])
};

export default withStyles(styles)(ButtonGroup);
