import { i18nMark } from "@lingui/react";
import { utils } from "@cauldron/core";
import validator from "validator";
import isNil from "lodash/isNil";

const {
  removePhoneFormat,
  isSIN,
  isStreetAddress,
  isValidStreetLength,
  isValidCityLength,
  isValidAge
} = utils;

const REQ = name => `${name} is required.`;

export const FORM_INPUTS_VALIDATION = {
  CITY_TOO_LONG: i18nMark("City must be under 26 characters."),
  EXTENSION_TOO_LONG: i18nMark("Input cannot be longer than 10."),
  INVALID_EMAIL: i18nMark("Email addresses should be in the format yourname@example.com."), // prettier-ignore
  INPUT_TOO_LONG: i18nMark("Input is too long."),
  INVALID_PHONE: i18nMark("Phone numbers should be 10 digits only and not contain non-numeric characters."), // prettier-ignore
  INVALID_PHONE_EXT: i18nMark("Extension only accepts numeric values."),
  INVALID_DATE: i18nMark("Invalid date."),
  INVALID_START_MONTH: i18nMark("Invalid start month."),
  INVALID_POSTAL_CODE: i18nMark("Invalid postal code."),
  INVALID_INSTITUTION_NUMBER: i18nMark("Invalid institution number."),
  INVALID_COUNTRY_CODE: i18nMark("Invalid country code."),
  INVALID_BRANCH_NUMBER: i18nMark("Invalid branch number."),
  INVALID_ACCOUNT_NUMBER: i18nMark("Invalid account number."),
  INVALID_AMOUNT: i18nMark("Invalid amount."),
  INVALID_NON_NEGATIVE_AMOUNT: i18nMark("A value of zero or above is required."), // prettier-ignore
  INVALID_STREET_ADDRESS: i18nMark("Street address must start with a number."),
  INVALID_SIN: i18nMark("Invalid SIN."),
  INVALID_FILE_SIZE: i18nMark("The import could not be completed because the file size is zero or over 8mb."), // prettier-ignore
  INVALID_MANUAL_STATEMENT_TYPE: i18nMark("The file type is not supported. Please try again with a file that ends with .pdf, .png, .jpg, .jpeg."), // prettier-ignore
  INVALID_MINIMUM_AGE_REQUIREMENT: i18nMark("You do not meet the minimum age requirements."), // prettier-ignore
  INVALID_MINIMUM_AGE_REQUIREMENT_FOR_PROVINCE: i18nMark("You do not meet the minimum age requirements for your province."), // prettier-ignore
  NO_FILE: i18nMark("Select a document to continue."),
  MESSAGE_TOO_LONG: i18nMark("Message must be under 64000 characters."),
  REQUIRED: i18nMark("Required."),
  REQUIRED_AGENT_NAME: i18nMark("Agent name is required."),
  REQUIRED_AGENT_PHONE: i18nMark("Agent phone is required."),
  REQUIRED_APPLICANT: i18nMark("Applicant is required."), // prettier-ignore
  REQUIRED_AUTHORIZATION_STATEMENT: i18nMark("Authorization statement is required."), // prettier-ignore
  REQUIRED_BANK_NAME: i18nMark("Bank name is required."),
  REQUIRED_CITY: i18nMark("City is required."),
  REQUIRED_CITI_FAIRSTONE_DEBIT_AMOUNT: i18nMark("Citi/Fairstone debit amount is required."), // prettier-ignore
  REQUIRED_CREDIT_PULL_CONSENT: i18nMark("Credit pull consent is required."),
  REQUIRED_DATE_OF_BIRTH: i18nMark("Date of birth is required."),
  REQUIRED_DEAL_LEAD: i18nMark("Deal lead is required."),
  REQUIRED_DESCRIPTION: i18nMark("Description is required."),
  REQUIRED_DOCUMENT_TYPE: i18nMark("Document type is required."),
  REQUIRED_EASY_FINANCIAL_DEBIT_AMOUNT: i18nMark("Easy Financial debit amount required."), // prettier-ignore
  REQUIRED_EMAIL_OR_PHONE: i18nMark("Email or phone number is required."),
  REQUIRED_EMPLOYMENT_STATUS: i18nMark("Employment status is required."),
  REQUIRED_EMPLOYER_NAME: i18nMark("Employer name is required."),
  REQUIRED_EMAIL: i18nMark("Email is required."),
  REQUIRED_FIRST_NAME: i18nMark("First name is required."),
  REQUIRED_FREQUENCY: i18nMark("Frequency is required."),
  REQUIRED_GENDER: i18nMark("Gender is required."),
  REQUIRED_INTERVIEW_NOTES: i18nMark("Interview notes are required."),
  REQUIRED_LAST_NAME: i18nMark("Last name is required."),
  REQUIRED_LIABILITY_DESCRIPTION: i18nMark("Creditor is required."),
  REQUIRED_LOAN_AMOUNT: i18nMark("Loan amount is required."),
  REQUIRED_MARITAL_STATUS: i18nMark("Marital status is required."),
  REQUIRED_MONTHLY_HOUSING_COST: i18nMark("Monthly housing cost is required."),
  REQUIRED_NOTE_MESSAGE: i18nMark("Note message is required."),
  REQUIRED_NUM_DEPENDANTS: i18nMark("Number of dependents is required."),
  REQUIRED_NUM_DISTINCT_PAYDAY_LENDERS: i18nMark("Number of unique payday lenders is required."), // prettier-ignore
  REQUIRED_NUM_NSFS: i18nMark("Number of NSFs is required."),
  REQUIRED_NUM_PAYDAY_LOANS: i18nMark("Number of payday loans is required."),
  REQUIRED_PARENT: i18nMark("Parent is required."),
  REQUIRED_PARTICIPANT: i18nMark("Participant is required."),
  REQUIRED_PAY: i18nMark("Pay is required."),
  REQUIRED_PAYROLL_DEPOSIT_90_DAYS: i18nMark("Payroll deposit for last 90 days is required."), // prettier-ignore
  REQUIRED_PHONE_NUMBER: i18nMark("Phone number is required."),
  REQUIRED_PHONE_TYPE: i18nMark("Phone type is required."),
  REQUIRED_POSITION: i18nMark("Position is required."),
  REQUIRED_POSTAL_CODE: i18nMark("Postal code is required."),
  REQUIRED_PROVINCE: i18nMark("Province is required."),
  REQUIRED_REFERRAL_SOURCE: i18nMark("Referral source is required."),
  REQUIRED_REFERRAL_AGENT: i18nMark("Referral agent is required."),
  REQUIRED_RESIDENCY_TYPE: i18nMark("Residential status is required."),
  REQUIRED_SIN: i18nMark("SIN is required."),
  REQUIRED_START_DATE: i18nMark("Start date is required."),
  REQUIRED_START_MONTH: i18nMark("Start month is required."),
  REQUIRED_START_YEAR: i18nMark("Start year is required."),
  REQUIRED_STREET_ADDRESS: i18nMark("Street address is required."),
  REQUIRED_SUBJECT: i18nMark("Subject is required."),
  REQUIRED_TERM: i18nMark("Term is required."),
  REQUIRED_TERMS_CONDITIONS_CONSENT: i18nMark("Terms and conditions consent is required."), // prettier-ignore
  REQUIRED_TITLE: i18nMark("Title is required."),
  REQUIRED_TYPE_OF_JOB: i18nMark("Type of job is required."),
  STREET_TOO_LONG: i18nMark("Street address, including unit/box #, must be under 80 characters."), // prettier-ignore
  SUBJECT_TOO_LONG: i18nMark("Subject must be under 50 characters."),
  UNSUPPORTED_QUEBEC_ADDRESS: i18nMark(
    "We do not service Quebec at this time."
  ),
  REQUIRED_REASON: i18nMark("Reason is required.")
};

/**
 * Validate required
 */
export const validateRequired = value => {
  return isNil(value) || value === "" ? FORM_INPUTS_VALIDATION.REQUIRED : null;
};

/**
 * validateNonNegativeAmount
 * should be equal to or greater than $0.00
 * @param value
 */
export const validateNonNegativeAmount = value => {
  const isNegative = parseInt(value, 10) < 0;
  const isValidNumber = !isNaN(parseInt(value, 10));
  return isNil(value) || (isValidNumber && isNegative)
    ? FORM_INPUTS_VALIDATION.INVALID_NON_NEGATIVE_AMOUNT
    : null;
};

// Multi field validations
/**
 * Validate email field
 * @param values
 */
export const validateEmail = values => {
  const errors = {};
  if (!values.email) {
    errors.email = FORM_INPUTS_VALIDATION.REQUIRED;
  } else if (!validator.isEmail(values.email)) {
    errors.email = FORM_INPUTS_VALIDATION.INVALID_EMAIL;
  }
  return errors;
};

/**
 * Validate address
 * @param values
 */
export const validateAddress = values => {
  const errors = {};
  if (!values.streetAddress) {
    errors.streetAddress = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  if (!isStreetAddress(values.streetAddress)) {
    errors.streetAddress = FORM_INPUTS_VALIDATION.INVALID_STREET_ADDRESS;
  }

  if (
    isStreetAddress(values.streetAddress) &&
    !isValidStreetLength(values.unitNumber, values.streetAddress)
  ) {
    errors.streetAddress = FORM_INPUTS_VALIDATION.STREET_TOO_LONG;
  }

  if (!values.city) {
    errors.city = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  if (!isValidCityLength(values.city)) {
    errors.city = FORM_INPUTS_VALIDATION.CITY_TOO_LONG;
  }

  if (!values.province) {
    errors.province = FORM_INPUTS_VALIDATION.REQUIRED_PROVINCE;
  }

  if (!values.postalCode) {
    errors.postalCode = FORM_INPUTS_VALIDATION.REQUIRED_POSTAL_CODE;
  } else if (!validator.isPostalCode(values.postalCode, "CA")) {
    errors.postalCode = FORM_INPUTS_VALIDATION.INVALID_POSTAL_CODE;
  }
  return errors;
};

/**
 * Validate phone
 * @param values
 */
export const validatePhone = values => {
  const errors = {};
  const phone = removePhoneFormat(values.number);
  if (!phone) {
    errors.number = FORM_INPUTS_VALIDATION.REQUIRED_PHONE_NUMBER;
  } else if (!validator.isNumeric(phone)) {
    errors.number = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  } else if (phone.toString().trim().length !== 10) {
    errors.number = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  }

  if (isNaN(Number(values.ext)))
    errors.ext = FORM_INPUTS_VALIDATION.INVALID_PHONE_EXT;

  if (values.ext && values.ext.trim().length > 10)
    errors.ext = FORM_INPUTS_VALIDATION.EXTENSION_TOO_LONG;
  if (!values.type) {
    errors.type = FORM_INPUTS_VALIDATION.REQUIRED_PHONE_TYPE;
  }

  return errors;
};

// TODO: move project-specific validations out of core utils

/**
 * validateAuthUserForm
 * @param values
 */
export const validateAuthUserForm = values => {
  const errors = {};
  const phone = removePhoneFormat(values.phone);
  if (!values.firstName) {
    errors.firstName = FORM_INPUTS_VALIDATION.REQUIRED;
  } else if (!values.firstName) {
    errors.firstName = FORM_INPUTS_VALIDATION.REQUIRED;
  } else if (values.firstName.length >= 255) {
    errors.firstName = FORM_INPUTS_VALIDATION.INPUT_TOO_LONG;
  }

  if (!validator.isEmail(values.email)) {
    errors.email = FORM_INPUTS_VALIDATION.INVALID_EMAIL;
  }

  if (values.middleName) {
    if (values.middleName.length >= 255) {
      errors.middleName = FORM_INPUTS_VALIDATION.INPUT_TOO_LONG;
    }
  }

  if (!values.lastName) {
    errors.lastName = FORM_INPUTS_VALIDATION.REQUIRED;
  } else if (values.lastName.length >= 255) {
    errors.lastName = FORM_INPUTS_VALIDATION.INPUT_TOO_LONG;
  }

  if (!values.relationship) {
    errors.relationship = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  if (!values.phone) {
    errors.phone = FORM_INPUTS_VALIDATION.REQUIRED;
  } else if (!validator.isNumeric(phone.toString())) {
    errors.phone = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  } else if (phone.toString().length > 10 || phone.toString().length < 10) {
    errors.phone = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  }

  if (!values.phoneType) {
    errors.phoneType = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  if (isNaN(Number(values.extension)))
    errors.extension = FORM_INPUTS_VALIDATION.INVALID_PHONE_EXT;

  if (values.extension.trim().length > 10)
    errors.extension = FORM_INPUTS_VALIDATION.EXTENSION_TOO_LONG;

  if (!values.dob) {
    errors.dob = FORM_INPUTS_VALIDATION.REQUIRED;
  } else if (!validator.isISO8601(values.dob)) {
    errors.dob = FORM_INPUTS_VALIDATION.INVALID_DATE;
  }
  return errors;
};

/**
 * Validate client form
 * @param values
 */
export const validateClient = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = FORM_INPUTS_VALIDATION.REQUIRED_FIRST_NAME;
  }

  if (!values.lastName) {
    errors.lastName = FORM_INPUTS_VALIDATION.REQUIRED_LAST_NAME;
  }

  const phone = removePhoneFormat(values.phoneNumber);
  if (!phone) {
    errors.phoneNumber = FORM_INPUTS_VALIDATION.REQUIRED_PHONE_NUMBER;
  } else if (!validator.isNumeric(phone.toString())) {
    errors.phoneNumber = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  } else if (phone.toString().length > 10 || phone.toString().length < 10) {
    errors.phoneNumber = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  }

  if (!values.email) {
    errors.email = FORM_INPUTS_VALIDATION.REQUIRED_EMAIL;
  } else if (!validator.isEmail(values.email)) {
    errors.email = FORM_INPUTS_VALIDATION.INVALID_EMAIL;
  }

  if (!values.streetAddress) {
    errors.streetAddress = FORM_INPUTS_VALIDATION.REQUIRED_STREET_ADDRESS;
  }

  if (isNaN(Number(values.streetAddress.charAt(0)))) {
    errors.streetAddress = FORM_INPUTS_VALIDATION.INVALID_STREET_ADDRESS;
  }

  const totalValidLength =
    values.streetAddress.trim().length + values.unit.trim().length;

  if (!isNaN(Number(values.streetAddress.charAt(0))) && totalValidLength > 80) {
    errors.streetAddress = FORM_INPUTS_VALIDATION.STREET_TOO_LONG;
  }

  if (!values.city) {
    errors.city = FORM_INPUTS_VALIDATION.REQUIRED_CITY;
  }

  if (values.city.trim().length > 25) {
    errors.city = FORM_INPUTS_VALIDATION.CITY_TOO_LONG;
  }

  if (!values.province) {
    errors.province = FORM_INPUTS_VALIDATION.REQUIRED_PROVINCE;
  }

  if (!values.postalCode) {
    errors.postalCode = FORM_INPUTS_VALIDATION.REQUIRED_POSTAL_CODE;
  } else if (!validator.isPostalCode(values.postalCode, "CA")) {
    errors.postalCode = FORM_INPUTS_VALIDATION.INVALID_POSTAL_CODE;
  }

  if (!values.dateOfBirth) {
    errors.dateOfBirth = FORM_INPUTS_VALIDATION.REQUIRED_DATE_OF_BIRTH;
  } else if (!validator.isISO8601(values.dateOfBirth)) {
    errors.dateOfBirth = FORM_INPUTS_VALIDATION.INVALID_DATE;
  } else if (!isValidAge(values.dateOfBirth, values.province)) {
    if (!values.province) {
      errors.dateOfBirth =
        FORM_INPUTS_VALIDATION.INVALID_MINIMUM_AGE_REQUIREMENT;
    } else {
      errors.dateOfBirth =
        FORM_INPUTS_VALIDATION.INVALID_MINIMUM_AGE_REQUIREMENT_FOR_PROVINCE;
    }
  }

  if (!values.termsAndConditionsConsent) {
    errors.termsAndConditionsConsent =
      FORM_INPUTS_VALIDATION.REQUIRED_TERMS_CONDITIONS_CONSENT;
  }

  if (!values.creditPullConsent) {
    errors.creditPullConsent =
      FORM_INPUTS_VALIDATION.REQUIRED_CREDIT_PULL_CONSENT;
  }

  if (values.sin && !isSIN(values.sin)) {
    errors.sin = FORM_INPUTS_VALIDATION.INVALID_SIN;
  }
  return errors;
};

/**
 * BANK ACCOUNT VALIDATIONS
 * Validate Bank detail forms
 */
export const validateBankDetails = values => {
  const errors = {};

  if (!values.institutionName) {
    errors.institutionName = FORM_INPUTS_VALIDATION.REQUIRED;
  }
  if (!values.branchNumber) {
    errors.branchNumber = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  if (values.branchNumber) {
    if (!validator.matches(values.branchNumber, /^[0-9-]*$/)) {
      errors.branchNumber = FORM_INPUTS_VALIDATION.INVALID_BRANCH_NUMBER;
    }
  }

  if (!values.accountNumber) {
    errors.accountNumber = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  if (values.accountNumber) {
    if (!validator.matches(values.accountNumber, /^[0-9-]*$/)) {
      errors.accountNumber = FORM_INPUTS_VALIDATION.INVALID_ACCOUNT_NUMBER;
    }
  }

  if (!values.type) {
    errors.type = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  if (!values.institutionNumber) {
    errors.institutionNumber = FORM_INPUTS_VALIDATION.REQUIRED;
  } else if (
    !validator.isNumeric(values.institutionNumber) ||
    values.institutionNumber.length > 3
  ) {
    errors.institutionNumber =
      FORM_INPUTS_VALIDATION.INVALID_INSTITUTION_NUMBER;
  }

  /**
   * Temporary: Branch Address and Contact forms validations have been commented
   * TODO: un-comment after add bankAccount API get updated and ready to consume from BE.
   */

  //  branch address info are all optional
  // if (!validator.isPostalCode(values.branchPostalCode, "CA")) {
  //   errors.branchPostalCode = FORM_INPUTS_VALIDATION.INVALID_POSTAL_CODE;
  // }

  // if (!values.branchCountry) {
  //   errors.branchCountry = FORM_INPUTS_VALIDATION.REQUIRED;
  // } else if (!validator.isISO31661Alpha2(values.branchCountry)) {
  //   errors.branchCountry = FORM_INPUTS_VALIDATION.INVALID_COUNTRY_CODE;
  // }

  // contact info are all optional
  // const phone = removePhoneFormat(values.contactPhone);

  // if (!validator.isNumeric(phone)) {
  //   errors.contactPhone = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  // } else if (phone.toString().trim().length !== 10) {
  //   errors.contactPhone = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  // }

  // if (!validator.isEmail(values.contactEmail)) {
  //   errors.contactEmail = FORM_INPUTS_VALIDATION.INVALID_EMAIL;
  // }

  return errors;
};

/**
 * NOTES VALIDATIONS
 * Validate note
 * @param values
 */
export const validateNote = ({ subject, category, parent, message }) => {
  const errors = {};
  if (stringLength(subject)) errors.subject = REQ("Subject");
  if (stringLength(category)) errors.category = REQ("Category");
  if (stringLength(parent)) errors.parent = REQ("Parent");
  if (stringLength(message)) errors.message = REQ("Message");
  return errors;
};

/**
 * INCOME VALIDATIONS
 * Validate the specific fields that are required for the
 * income form field, pass in the messages to display
 * REQUIRED, INVALID_POSTAL_CODE are currently required messages.
 * @param values
 */
export const validateLoanAppIncome = values => {
  const errors = {};

  if (!values.jobType) {
    errors.jobType = FORM_INPUTS_VALIDATION.REQUIRED_TYPE_OF_JOB;
  }

  if (
    !values.companyName ||
    (typeof values.companyName === "string" && !values.companyName.trim())
  ) {
    errors.companyName = FORM_INPUTS_VALIDATION.REQUIRED_EMPLOYER_NAME;
  }

  if (!values.position) {
    errors.position = FORM_INPUTS_VALIDATION.REQUIRED_POSITION;
  }

  const phone = values.phoneNumber
    ? removePhoneFormat(values.phoneNumber)
    : null;
  if (phone && !validator.isNumeric(phone.toString())) {
    errors.phoneNumber = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  } else if (
    phone &&
    (phone.toString().length > 10 || phone.toString().length < 10)
  ) {
    errors.phoneNumber = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  }

  if (!values.pay) {
    errors.pay = FORM_INPUTS_VALIDATION.REQUIRED_PAY;
  }

  if (!values.payFrequency) {
    errors.payFrequency = FORM_INPUTS_VALIDATION.REQUIRED_FREQUENCY;
  }

  if (!values.startYear) {
    errors.startYear = FORM_INPUTS_VALIDATION.REQUIRED_START_YEAR;
  }

  if (!values.startMonth) {
    errors.startMonth = FORM_INPUTS_VALIDATION.REQUIRED_START_MONTH;
  }

  // selecting a date that creates a future date is invalid, year opts are limited to current year
  if (values.startYear && values.startMonth) {
    const startDate = new Date([
      Number(values.startYear),
      Number(values.startMonth)
    ]);
    const now = new Date();
    if (startDate > now) {
      errors.startMonth = FORM_INPUTS_VALIDATION.INVALID_START_MONTH;
    }
  }

  if (values.postalCode && !validator.isPostalCode(values.postalCode, "CA")) {
    errors.postalCode = FORM_INPUTS_VALIDATION.INVALID_POSTAL_CODE;
  }

  if (isNaN(Number(values.ext)))
    errors.ext = FORM_INPUTS_VALIDATION.INVALID_PHONE_EXT;

  if (values.ext.trim().length > 10)
    errors.ext = FORM_INPUTS_VALIDATION.EXTENSION_TOO_LONG;

  if (isNaN(Number(values.street.charAt(0)))) {
    errors.street = FORM_INPUTS_VALIDATION.INVALID_STREET_ADDRESS;
  }

  const totalValidLength =
    values.street.trim().length + values.unit.trim().length;

  if (!isNaN(Number(values.street.charAt(0))) && totalValidLength > 80) {
    errors.street = FORM_INPUTS_VALIDATION.STREET_TOO_LONG;
  }

  if (values.city.trim().length > 25) {
    errors.city = FORM_INPUTS_VALIDATION.CITY_TOO_LONG;
  }

  return errors;
};

export const validateLoanAppIncomeAlt = values => {
  const errors = {};
  if (
    !values.description ||
    (typeof values.description === "string" && !values.description.trim())
  ) {
    errors.description = FORM_INPUTS_VALIDATION.REQUIRED_DESCRIPTION;
  }

  if (values.pay === null || values.pay === "") {
    errors.pay = FORM_INPUTS_VALIDATION.REQUIRED_PAY;
  }

  if (values.pay && values.pay < 0) {
    errors.pay = FORM_INPUTS_VALIDATION.INVALID_NON_NEGATIVE_AMOUNT;
  }

  if (!values.payFrequency) {
    errors.payFrequency = FORM_INPUTS_VALIDATION.REQUIRED_FREQUENCY;
  }

  if (!values.startMonth) {
    errors.startMonth = FORM_INPUTS_VALIDATION.REQUIRED_START_MONTH;
  }

  if (!values.startYear) {
    errors.startYear = FORM_INPUTS_VALIDATION.REQUIRED_START_YEAR;
  }

  // selecting a date that creates a future date is invalid, year opts are limited to current year
  if (values.startYear && values.startMonth) {
    const startDate = new Date([
      Number(values.startYear),
      Number(values.startMonth)
    ]);
    const now = new Date();
    if (startDate > now) {
      errors.startMonth = FORM_INPUTS_VALIDATION.INVALID_START_MONTH;
    }
  }

  return errors;
};

// MISC Validations
const stringLength = s => !s || s.trim().length === 0;

/**
 * LIABILITY VALIDATION
 */

export const validateLiabilityDescription = value => {
  if (typeof value !== "string" || !value.trim()) {
    return FORM_INPUTS_VALIDATION.REQUIRED_LIABILITY_DESCRIPTION;
  }
  if (value.length > 200) {
    return FORM_INPUTS_VALIDATION.INPUT_TOO_LONG;
  }
  return null;
};

export const validateLiabilityCreditLimit = value => {
  const nonNegativeNumberError = validateNonNegativeAmount(value);
  if (nonNegativeNumberError) {
    return nonNegativeNumberError;
  }
  if (value > 10000000) {
    return FORM_INPUTS_VALIDATION.INPUT_TOO_LONG;
  }
  return null;
};

export const validateLiabilityBalance = value => {
  const nonNegativeNumberError = validateNonNegativeAmount(value);
  if (nonNegativeNumberError) {
    return nonNegativeNumberError;
  }
  if (value > 10000000) {
    return FORM_INPUTS_VALIDATION.INPUT_TOO_LONG;
  }
  return null;
};

export const validateLiabilityPayment = value => {
  const nonNegativeNumberError = validateNonNegativeAmount(value);
  if (nonNegativeNumberError) {
    return nonNegativeNumberError;
  }
  if (value > 10000000) {
    return FORM_INPUTS_VALIDATION.INPUT_TOO_LONG;
  }
  return null;
};

/**
 * PERSONAL DETAILS - TITLE
 */

export const validateClientTitle = values => {
  const errors = {};
  if (!values.title) {
    errors.title = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  return errors;
};

/**
 * HELPER FNC TO SUPPORT hasError/isTouched
 * used in form field validation
 * @author: Ehsan
 * @param {string} name - input name
 * @param {string} mode - text / error
 * data param ->
 * @param {object} touched
 * @param {object} errors
 */

export const validateInput = (name, mode, data) => {
  const { touched, errors } = data;

  if (mode === "text") {
    return touched[name] && errors[name];
  }

  if (mode === "error") {
    return !!(touched[name] && errors[name]);
  }

  return false;
};

export const validateClientNameFields = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  if (!values.lastName) {
    errors.lastName = FORM_INPUTS_VALIDATION.REQUIRED;
  }

  return errors;
};

export const validateEditDealLead = values => {
  const errors = {};
  if (!values.dealLead) {
    errors.dealLead = FORM_INPUTS_VALIDATION.REQUIRED_DEAL_LEAD;
  }
  return errors;
};

/**
 * Referral Source Validation
 */
export const validateReferralSource = values => {
  const errors = {};
  const { referralSource, referralAgent } = values;

  if (!referralSource) {
    errors.referralSource = FORM_INPUTS_VALIDATION.REQUIRED_REFERRAL_SOURCE;
  }

  if (!referralAgent) {
    errors.referralAgent = FORM_INPUTS_VALIDATION.REQUIRED_REFERRAL_AGENT;
  }
  return errors;
};

/**
 * Personal Details Validation
 */
export const validatePersonalDetails = values => {
  const errors = {};
  if (!values.gender) {
    errors.gender = FORM_INPUTS_VALIDATION.REQUIRED_GENDER;
  }
  if (!values.title) {
    errors.title = FORM_INPUTS_VALIDATION.REQUIRED_TITLE;
  }
  if (!values.maritalStatus) {
    errors.maritalStatus = FORM_INPUTS_VALIDATION.REQUIRED_MARITAL_STATUS;
  }
  if (!values.dateOfBirth) {
    errors.dateOfBirth = FORM_INPUTS_VALIDATION.REQUIRED_DATE_OF_BIRTH;
  } else if (!validator.isISO8601(values.dateOfBirth)) {
    errors.dateOfBirth = FORM_INPUTS_VALIDATION.INVALID_DATE;
  } else if (!isValidAge(values.dateOfBirth, values.province)) {
    if (!values.province) {
      errors.dateOfBirth =
        FORM_INPUTS_VALIDATION.INVALID_MINIMUM_AGE_REQUIREMENT;
    } else {
      errors.dateOfBirth =
        FORM_INPUTS_VALIDATION.INVALID_MINIMUM_AGE_REQUIREMENT_FOR_PROVINCE;
    }
  }

  if (values.sin && !isSIN(values.sin)) {
    errors.sin = FORM_INPUTS_VALIDATION.INVALID_SIN;
  }

  return errors;
};

/**
 * Client Email Validation
 */
export const validateClientEmail = values => {
  const errors = {};
  if (!values.email) {
    errors.email = FORM_INPUTS_VALIDATION.REQUIRED_EMAIL;
  }
  if (values.email && !validator.isEmail(values.email)) {
    errors.email = FORM_INPUTS_VALIDATION.INVALID_EMAIL;
  }

  return errors;
};

/**
 * Bank Request Validation
 * @param values
 */
export const validateBankRequest = values => {
  const errors = {};
  if (!values.isEmailCheck && !values.isSMSCheck) {
    errors.email = FORM_INPUTS_VALIDATION.REQUIRED_EMAIL_OR_PHONE;
    errors.phone = FORM_INPUTS_VALIDATION.REQUIRED_EMAIL_OR_PHONE;
  }

  if (values.isEmailCheck) {
    if (!values.email || !validator.isEmail(values.email)) {
      errors.email = FORM_INPUTS_VALIDATION.INVALID_EMAIL;
    }
  }

  if (values.isSMSCheck) {
    if (!values.phone) {
      errors.phone = FORM_INPUTS_VALIDATION.INVALID_PHONE;
    } else if (!validator.isNumeric(values.phone.toString())) {
      errors.phone = FORM_INPUTS_VALIDATION.INVALID_PHONE;
    } else if (
      values.phone.toString().length > 10 ||
      values.phone.toString().length < 10
    ) {
      errors.phone = FORM_INPUTS_VALIDATION.INVALID_PHONE;
    }
  }
  return errors;
};

/**
 * Validate application note
 * @param values
 */
export const validateApplicationNote = values => {
  const errors = {};

  if (!values.title.trim()) {
    errors.title = FORM_INPUTS_VALIDATION.REQUIRED_SUBJECT;
  }

  if (values.title.length >= 50) {
    errors.title = FORM_INPUTS_VALIDATION.SUBJECT_TOO_LONG;
  }

  if (!values.comment.trim()) {
    errors.comment = FORM_INPUTS_VALIDATION.REQUIRED_NOTE_MESSAGE;
  }

  if (values.comment.length >= 64000) {
    errors.comment = FORM_INPUTS_VALIDATION.MESSAGE_TOO_LONG;
  }

  return errors;
};

export const validateInterview = values => {
  const errors = {};

  if (!values.comment.trim()) {
    errors.comment = FORM_INPUTS_VALIDATION.REQUIRED_INTERVIEW_NOTES;
  }

  return errors;
};

/**
 * Validate Manual Bank Statement
 * @param values
 */
export const validateManualBankStatement = values => {
  const errors = {};

  const validFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf"
  ];
  if (!validFileTypes.includes(values.file.type)) {
    errors.file = FORM_INPUTS_VALIDATION.INVALID_MANUAL_STATEMENT_TYPE;
  }

  if (values.file.size > 8388608 || values.file.size === 0) {
    errors.file = FORM_INPUTS_VALIDATION.INVALID_FILE_SIZE;
  }

  return errors;
};

export const validateDocumentUpload = values => {
  const errors = {};

  if (!values.hasFile) {
    errors.hasFile = FORM_INPUTS_VALIDATION.NO_FILE;
  }

  if (values.file && (values.file.size > 8388608 || values.file.size === 0)) {
    errors.file = FORM_INPUTS_VALIDATION.INVALID_FILE_SIZE;
  }

  if (values.parentId && !values.documentType) {
    errors.documentType = FORM_INPUTS_VALIDATION.REQUIRED_DOCUMENT_TYPE;
  }

  return errors;
};

export const validateScorecard = values => {
  const errors = {};

  if (!values.participant) {
    errors.particpant = FORM_INPUTS_VALIDATION.REQUIRED_PARTICIPANT;
  }

  if (!values.bankName) {
    errors.bankName = FORM_INPUTS_VALIDATION.REQUIRED_BANK_NAME;
  }

  if (validateNonNegativeAmount(values.payrollDeposit90Days)) {
    errors.payrollDeposit90Days =
      FORM_INPUTS_VALIDATION.INVALID_NON_NEGATIVE_AMOUNT;
  }

  if (
    validateNonNegativeAmount(values.numNSFs) ||
    !Number.isInteger(Number(values.numNSFs))
  ) {
    errors.numNSFs = FORM_INPUTS_VALIDATION.INVALID_AMOUNT;
  }

  if (!values.numNSFs) {
    errors.numNSFs = FORM_INPUTS_VALIDATION.REQUIRED_NUM_NSFS;
  }

  if (validateNonNegativeAmount(values.easyFinancialDebitAmount)) {
    errors.easyFinancialDebitAmount =
      FORM_INPUTS_VALIDATION.INVALID_NON_NEGATIVE_AMOUNT;
  }

  if (validateNonNegativeAmount(values.citiFairstoneDebitAmount)) {
    errors.citiFairstoneDebitAmount =
      FORM_INPUTS_VALIDATION.INVALID_NON_NEGATIVE_AMOUNT;
  }

  if (
    validateNonNegativeAmount(values.numDistinctPaydayLenders) ||
    !Number.isInteger(Number(values.numDistinctPaydayLenders))
  ) {
    errors.numDistinctPaydayLenders = FORM_INPUTS_VALIDATION.INVALID_AMOUNT;
  }

  if (!values.numDistinctPaydayLenders) {
    errors.numDistinctPaydayLenders =
      FORM_INPUTS_VALIDATION.REQUIRED_NUM_DISTINCT_PAYDAY_LENDERS;
  }

  return errors;
};

export const validateDocumentEdit = values => {
  const errors = {};

  if (!values.parentId) {
    errors.parentId = FORM_INPUTS_VALIDATION.REQUIRED_PARENT;
  }

  if (values.parentId && !values.documentType) {
    errors.documentType = FORM_INPUTS_VALIDATION.REQUIRED_DOCUMENT_TYPE;
  }

  return errors;
};

export const validateName = values => {
  const errors = {};

  if (!values.firstName.trim()) {
    errors.firstName = FORM_INPUTS_VALIDATION.REQUIRED_FIRST_NAME;
  }

  if (!values.lastName.trim()) {
    errors.lastName = FORM_INPUTS_VALIDATION.REQUIRED_LAST_NAME;
  }
  return errors;
};

export const validateStatusReason = values => {
  const errors = {};

  if (!values.endStateReason) {
    errors.endStateReason = FORM_INPUTS_VALIDATION.REQUIRED_REASON;
  }

  if (values.note && values.note.length > 200) {
    errors.note = FORM_INPUTS_VALIDATION.INPUT_TOO_LONG;
  }

  return errors;
};
