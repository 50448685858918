import { put, takeLatest, fork, call, all } from "redux-saga/effects";
import { http } from "../../../services/http/http.service";
import {
  TRANCHE_MANAGEMENT_UPLOAD_FILE,
  TRANCHE_MANAGEMENT_IMPORT
} from "./tranche.action.types";
import {
  uploadTrancheFileSuccess,
  uploadTrancheFileFailure,
  importTranchesSuccess,
  importTranchesFailure
} from "./tranche.actions";
import {
  trancheImportReceived,
  trancheUploadReceived,
  trancheUploadSent
} from "../../../models/tranche";
import {
  addSectionError,
  clearSectionError,
  APP_SECTIONS
} from "../../../services/error.handler";

/**
 * UPLOAD TRANCHE FILE
 * @param {object} action
 */
export const trancheFileUpload = action => {
  return http({
    request: (httpSrv, API) => {
      const reqConfig = {
        url: API.TRANCHE_UPLOAD,
        data: action.file,
        method: "post",
        headers: {
          "content-type": "text/csv"
        }
      };
      return call(httpSrv.request, reqConfig);
    },
    success: data => [
      put(uploadTrancheFileSuccess(data)),
      put(clearSectionError(APP_SECTIONS.TRANCHE))
    ],
    error: error => [
      put(uploadTrancheFileFailure(error)),
      put(addSectionError(APP_SECTIONS.TRANCHE, error))
    ],
    transformReceiveData: trancheUploadReceived,
    transformSendData: trancheUploadSent
  });
};

/**
 * IMPORT TRANCHE FILE
 * @param {object} action
 */
export const trancheTableImport = action => {
  return http({
    request: (httpSrv, API) => {
      const { selectedTranches } = action;
      const requestActions = [];

      selectedTranches.forEach(t => {
        requestActions.push(call(httpSrv.post, API.TRANCHE_IMPORT(t.id), {}));
      });

      return all(requestActions);
    },
    success: data => [
      put(importTranchesSuccess(data)),
      put(clearSectionError(APP_SECTIONS.TRANCHE))
    ],
    error: error => [
      put(importTranchesFailure(error)),
      put(addSectionError(APP_SECTIONS.TRANCHE, error))
    ],
    transformReceiveData: trancheImportReceived
  });
};

function* watchFundedLoanDetailsAsync() {
  yield takeLatest(TRANCHE_MANAGEMENT_UPLOAD_FILE, trancheFileUpload);
  yield takeLatest(TRANCHE_MANAGEMENT_IMPORT, trancheTableImport);
}

export default [fork(watchFundedLoanDetailsAsync)];
