"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createErrorLog = createErrorLog;

function createErrorLog(e) {
  var errMsgLog = e;
  var res = e && e.response ? e.response : null;
  if (!res) return errMsgLog;
  var status = res.status || null;
  var method = res.config ? res.config.method : null;
  var url = res.config ? res.config.url : null;
  errMsgLog = {
    context: "".concat(status, " ").concat(method.toUpperCase(), " ").concat(url),
    method: method,
    url: url,
    response: res.data || null,
    status: status,
    statusText: res.statusText || null
  };
  return errMsgLog;
}