import formControlStyles from "../../styles/form.controls.styles";

/**
 * DATE_FIELD STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = theme => ({
  ...formControlStyles(theme),
  inputRoot: {
    ...formControlStyles(theme).inputRoot,

    "& .react-datepicker-popper": {
      zIndex: 1000
    }
  }
});

export default styles;
