import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { fetchApiVersion } from "./info.actions";
import { getApiVersion } from "./info.selectors";
import { API_VERSION_TEXT } from "./api.version.data.test.ids";

class ApiVersion extends Component {
  componentDidMount() {
    const { fetchApiVersion } = this.props;
    fetchApiVersion();
  }

  render() {
    const { info } = this.props;
    return <span data-test={API_VERSION_TEXT}>{info.version}</span>;
  }
}

const mapStateToProps = state => {
  return { info: getApiVersion(state) };
};

const enhance = compose(
  connect(
    mapStateToProps,
    { fetchApiVersion }
  )
);

ApiVersion.defaultProps = {
  info: {}
};

ApiVersion.propTypes = {
  info: PropTypes.object
};

export { ApiVersion };
export default enhance(ApiVersion);
