import React, { Suspense, lazy, useEffect } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchLoanApplicationDetails } from "./engine/actions/loan.application.details.actions";
import {
  getApplicationStatus,
  getApplicationFetchStatus
} from "./engine/selectors/loan.application.details.selectors";
import { Loader } from "../../ui.library";
import { getIsProfileRequestComplete } from "../../services/auth/auth.selectors";
import { application as applicationService } from "../../services";

const { STATUS } = applicationService.constants;

const ApplicationCreate = lazy(() =>
  import("./loan.application.creation.container")
);

const ApplicationDetails = lazy(() =>
  import("./loan.application.details.container")
);

function LoanApplicationDetailsContainer({
  match,
  applicationStatus,
  isFetching,
  fetchLoanApplicationDetails,
  isProfileRequestComplete
}) {
  useEffect(
    () => {
      const selectedAppId = match.params.id;
      if (
        fetchLoanApplicationDetails &&
        selectedAppId &&
        isProfileRequestComplete
      ) {
        fetchLoanApplicationDetails(selectedAppId);
      }
    },
    [fetchLoanApplicationDetails, match.params.id, isProfileRequestComplete]
  );

  if (isFetching) {
    return <Loader centerScreen thickness={3.5} size={75} />;
  }

  if (applicationStatus === STATUS.DRAFT) {
    return (
      <Suspense fallback={<Loader centerScreen />}>
        <ApplicationCreate />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loader centerScreen />}>
      <ApplicationDetails />
    </Suspense>
  );
}

const mapStateToProps = state => ({
  applicationStatus: getApplicationStatus(state),
  isFetching: getApplicationFetchStatus(state),
  isProfileRequestComplete: getIsProfileRequestComplete(state)
});

const mapDispatchToProps = {
  fetchLoanApplicationDetails
};

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(LoanApplicationDetailsContainer);
