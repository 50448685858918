const styles = () => ({
  textField: {
    padding: "3px 0 0"
  },
  select: {
    padding: "7px 0 0"
  }
});

export default styles;
