import React, { useEffect, Fragment } from "react";
import { t } from "@lingui/macro";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import { connect } from "react-redux";
import { utils } from "@cauldron/core";
import PersonAdd from "@material-ui/icons/PersonAdd";
import FileCopy from "@material-ui/icons/FileCopy";
import { searchTableTestId } from "../loan.applications/loan.applications.data.test.ids";
import { getMenuList } from "../../utils/engage.app.utils";
import { menuList } from "../pages.modules";
import { ErrorBoundary } from "../../shared/ErrorBoundary";
import Page from "../../shared/Page";
import { IconTextButton, Loader } from "../../ui.library";
import {
  ADD_NEW_APPLICANT,
  APP_SECONDARY_HEADER,
  DUPLICATE_APPLICATION
} from "../constants/data.test.ids";
import {
  application as applicationService,
  agentPortalClient as agentPortalClientService,
  agentPortalApplication as agentPortalApplicationService,
  client as clientService,
  referralSource as referralSourceService,
  loading as loadingService,
  errorHandler as errorHandlerService
} from "../../services";
import { fetchLoanApplicationDetails } from "../loan.application.details/engine/actions/loan.application.details.actions";
import { FIELD_NAMES } from "./loan.application.constants";
import LoanApplicationDuplicateForm from "./loan.application.duplicate.form";
import { withAuth } from "../../services/auth";

const { APPLICATION_PURPOSE_TYPES } = agentPortalApplicationService.constants;

const { generateTestIds, promote } = utils;

function LoanApplicationDuplicateContainer({
  i18n,
  match,
  user,
  history,
  isFetching,
  errors,
  clientsById,
  applicationId,
  createApplication,
  linkClient,
  fetchReferralSources,
  companyReferralSources,
  fetchLoanApplicationDetails,
  createClientfromApplicant
}) {
  // eslint-disable-next-line
  useEffect(
    () => {
      const selectedAppId = match.params.id;
      if (fetchLoanApplicationDetails && selectedAppId) {
        fetchLoanApplicationDetails(selectedAppId);
        fetchReferralSources();
      }
    },
    [
      applicationId,
      fetchLoanApplicationDetails,
      fetchReferralSources,
      match.params.id
    ]
  );

  const onLogoClick = () => {
    history.push("/");
  };

  const goToLoanAppDetails = id => {
    if (id) history.push(`/loan-applications/${id}`);
  };

  const createPortalApplication = applicant => {
    const hasOneReferralSource = companyReferralSources.length === 1;

    const payload = {
      application: {},
      userId: applicant.email
    };

    if (APPLICATION_PURPOSE_TYPES && APPLICATION_PURPOSE_TYPES.length) {
      payload.application.loanPurpose = APPLICATION_PURPOSE_TYPES[0];
    }

    if (hasOneReferralSource) {
      payload.application.referrerId = companyReferralSources[0].id;
    }

    return new Promise(function(resolve, reject) {
      createApplication(payload, {
        onSuccess: resolve,
        onFailure: reject
      });
    });
  };

  const asyncCreateClientFromApplicant = (id, email) => {
    return new Promise((resolve, reject) => {
      createClientfromApplicant(
        {
          applicantId: id,
          email: email,
          sendWelcomeEmail: false
        },
        {
          onSuccess: resolve,
          onFailure: reject
        }
      );
    });
  };

  const ensurePortalClients = (values, hasCoApplicant) => {
    const applicantId = values[FIELD_NAMES.APPLICANT];
    const coApplicantId = values[FIELD_NAMES.CO_APPLICANT];

    if (!applicantId) return;

    const applicantUpsert = asyncCreateClientFromApplicant(
      applicantId,
      values[FIELD_NAMES.APPLICANT_EMAIL]
    );

    const coApplicantUpsert = hasCoApplicant
      ? asyncCreateClientFromApplicant(
          coApplicantId,
          values[FIELD_NAMES.CO_APPLICANT_EMAIL]
        )
      : Promise.resolve();

    return Promise.all([applicantUpsert, coApplicantUpsert]);
  };

  const handleOnSubmit = function(values) {
    const coApplicantId = values[FIELD_NAMES.CO_APPLICANT];
    const hasCoApplicant = coApplicantId && coApplicantId !== "";

    // Ensure there is a portal client by calling createClientfromApplicant
    // for each of the applicants
    ensurePortalClients(values, hasCoApplicant).then(
      ([applicantRes, coApplicantRes]) => {
        // Once portal clients have been created,
        // create application using the primary client userId (email)
        if (applicantRes) {
          createPortalApplication(applicantRes).then(loanAppData => {
            // Once the application has been created with primary user associated with it
            // If there is a coApplicant, link the coApplicant to the application as well
            if (loanAppData && hasCoApplicant && coApplicantRes) {
              linkClient(coApplicantId, loanAppData.id, {
                onSuccess: () => goToLoanAppDetails(loanAppData.id)
              });
            } else if (loanAppData) {
              goToLoanAppDetails(loanAppData.id);
            }
          });
        }
      }
    );
  };

  const handleOnCancel = () => {
    const selectedAppId = match.params.id;
    history.push(`/loan-applications/${selectedAppId}`);
  };

  const renderAddApplicantButton = () => {
    return (
      <Fragment>
        <IconTextButton
          dataTestId={generateTestIds(
            APP_SECONDARY_HEADER,
            DUPLICATE_APPLICATION
          )}
          buttonText={i18n.t`Duplicate application`}
          icon={<FileCopy />}
          onClick={() => {}}
          disabled
        />
        <IconTextButton
          dataTestId={generateTestIds(APP_SECONDARY_HEADER, ADD_NEW_APPLICANT)}
          buttonText={i18n.t`Add new applicant`}
          icon={<PersonAdd />}
          onClick={() => {}}
          disabled
        />
      </Fragment>
    );
  };

  const { logOut, profile, filterByAccess } = user;

  const getModulePath = () => {
    const modulePath = [
      {
        label: i18n.t`Modules`,
        path: "/"
      },
      {
        label: i18n.t`Loan Applications`,
        path: "/loan-applications"
      }
    ];

    const path = "/loan-applications/" + applicationId;
    if (Object.keys(clientsById).length) {
      const clients = promote("primary", true, Object.values(clientsById));
      modulePath.push({
        label: clients[0].fullName,
        path: path,
        suppressTracking: true
      });
    }

    return modulePath;
  };

  if (!Object.keys(clientsById).length) return <Loader centerScreen />;

  return (
    <Page
      dataTestId={{
        root: searchTableTestId.root,
        main: searchTableTestId.main
      }}
      HeaderProps={{
        profile,
        paths: getModulePath(),
        actions: renderAddApplicantButton(),
        menuList: getMenuList(filterByAccess(menuList, user), i18n),
        onLogOut: logOut,
        onLogoClick: onLogoClick,
        emptyMenuListText: i18n.t`No modules available`
      }}
    >
      <ErrorBoundary
        title={i18n._(t`Oops...`)}
        message={i18n.t`An unexpected UI exception has occurred in the loan applications module.`}
        links={[{ path: "/", label: i18n.t`Dashboard` }]}
      >
        <LoanApplicationDuplicateForm
          clientsById={clientsById}
          onCancel={handleOnCancel}
          onSubmit={handleOnSubmit}
          errors={errors}
          isFetching={isFetching}
        />
      </ErrorBoundary>
    </Page>
  );
}

const { createLoadingSelector } = loadingService.selectors;
const { createErrorMessageSelector } = errorHandlerService.selectors;

const mapStateToProps = state => ({
  applicationId: applicationService.selectors.getApplicationId(state),
  clientsById: clientService.selectors.getClientsById(state),
  companyReferralSources: referralSourceService.selectors.getCompanyReferralSources(state), // prettier-ignore
  referralSourcesByCompany: referralSourceService.selectors.getReferralSourcesByCompany(state), // prettier-ignore
  isFetching: createLoadingSelector([
    agentPortalApplicationService.actionTypes.APPLICATION_CREATE_REQUEST,
    agentPortalClientService.actionTypes.CLIENT_CREATE_REQUEST,
    agentPortalClientService.actionTypes.CLIENT_CREATE_FROM_APPLICANT_REQUEST,
    applicationService.actionTypes.APPLICATION_CLIENT_LINK_REQUEST
  ])(state),
  errors: createErrorMessageSelector([
    agentPortalApplicationService.actionTypes.APPLICATION_CREATE_REQUEST,
    agentPortalClientService.actionTypes.CLIENT_CREATE_REQUEST,
    agentPortalClientService.actionTypes.CLIENT_CREATE_FROM_APPLICANT_REQUEST,
    applicationService.actionTypes.APPLICATION_CLIENT_LINK_REQUEST
  ])(state)
});

const mapDispatchToProps = {
  fetchReferralSources: referralSourceService.actions.fetchReferralSources,
  createApplication: agentPortalApplicationService.actions.createApplication,
  createClientfromApplicant:
    agentPortalClientService.actions.createClientfromApplicant,
  linkClient: applicationService.actions.linkClient,
  fetchLoanApplicationDetails
};

const enhance = compose(
  withI18n(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withAuth
);

export default enhance(LoanApplicationDuplicateContainer);
