"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "ClientDetails";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  address: {
    unitNumber: null,
    city: null,
    civicNumber: null,
    country: null,
    postalCode: null,
    primaryResidence: null,
    province: null,
    street: null
  },
  dateOfBirth: null,
  employment: {
    employerName: null,
    employmentStatus: null,
    income: null,
    payFrequency: null,
    paymentMethod: null,
    startMonth: null,
    startYear: null
  },
  name: {
    salutation: null,
    first: null,
    middle: null,
    last: null,
    suffix: null
  },
  phone: {
    countryCode: null,
    number: null,
    ext: null,
    type: null,
    primary: null
  },
  monthlyHousingCost: {
    amount: null
  },
  maritalStatus: null,
  numDependants: null,
  residencyType: null,
  sin: null,
  preferredContactDays: null
};
/**
 * Protects data from change in data type
 */

var schema = {
  address: _propTypes.default.shape({
    unitNumber: _propTypes.default.string,
    city: _propTypes.default.string,
    civicNumber: _propTypes.default.string,
    country: _propTypes.default.string,
    id: _propTypes.default.string,
    postalCode: _propTypes.default.string,
    primaryResidence: _propTypes.default.bool,
    province: _propTypes.default.string,
    street: _propTypes.default.string
  }),
  dateOfBirth: _propTypes.default.string,
  employment: _propTypes.default.shape({
    employerName: _propTypes.default.string,
    employmentStatus: _propTypes.default.string,
    id: _propTypes.default.string,
    income: _propTypes.default.number,
    payFrequency: _propTypes.default.string,
    paymentMethod: _propTypes.default.string,
    startMonth: _propTypes.default.number,
    startYear: _propTypes.default.number
  }),
  id: _propTypes.default.string,
  name: _propTypes.default.objectOf(_propTypes.default.string),
  phone: _propTypes.default.shape({
    id: _propTypes.default.string,
    countryCode: _propTypes.default.string,
    number: _propTypes.default.string,
    ext: _propTypes.default.string,
    type: _propTypes.default.string,
    primary: _propTypes.default.bool
  }),
  monthlyHousingCost: _propTypes.default.shape({
    id: _propTypes.default.string,
    amount: _propTypes.default.number
  }),
  numDependants: _propTypes.default.number,
  residencyType: _propTypes.default.string,
  sin: _propTypes.default.string,
  preferredContactDays: _propTypes.default.array
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var _default = {
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;