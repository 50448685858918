import { transformReceivedCollection } from "../model.utils";
import { defaultProps, schema, MODEL_NAME } from "./model";

/**
 * Application Scores Transform [RECEIVED]
 * transform data -> before it reaches the store
 * @param {*} rawData
 */

export const transformReceived = rawData => {
  if (rawData.results) {
    return transformReceivedCollection(
      rawData,
      defaultProps,
      schema,
      MODEL_NAME
    );
  } else {
    return transformReceivedCollection(
      { results: [rawData] },
      defaultProps,
      schema,
      MODEL_NAME
    );
  }
};
