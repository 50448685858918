"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("@material-ui/core");

var _banner = _interopRequireDefault(require("./styles/banner.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function Banner(_ref) {
  var classes = _ref.classes,
      heading = _ref.heading,
      bodyText = _ref.bodyText,
      footerHeading = _ref.footerHeading,
      actions = _ref.actions;
  return _react.default.createElement("div", {
    className: classes.banner
  }, _react.default.createElement("div", {
    className: classes.content
  }, _react.default.createElement("div", {
    className: classes.heading
  }, heading), _react.default.createElement("div", {
    className: classes.body
  }, bodyText), _react.default.createElement("div", {
    className: classes.space24
  }), _react.default.createElement("div", {
    className: classes.footerHeading
  }, footerHeading), _react.default.createElement("div", {
    className: classes.space24
  }), actions));
}

Banner.propTypes = {
  classes: _propTypes.default.objectOf(_propTypes.default.string).isRequired
};
Banner.defaultProps = {
  icon: null,
  text: "",
  action: null
};

var _default = (0, _core.withStyles)(_banner.default)(Banner);

exports.default = _default;