import React from "react";
import Loadable from "react-loadable";
import Loader from "../../ui.library/Loader";

export default Loadable.Map({
  loader: {
    FundedLoans: () => import("./funded.loans.index")
  },
  render(loaded, props) {
    const FundedLoans = loaded.FundedLoans.default;
    return <FundedLoans {...props} />;
  },
  loading: () => <Loader size={75} thickness={3.5} centerScreen />
});
