import { put, takeLatest, fork, call } from "redux-saga/effects";
import { actionTypes } from "./manual.score.types";
import { showSuccess } from "../../../../../services/messenger/messenger.actions";
import { fetchLoanApplicationDetails } from "../loan.application.details.sagas";
import {
  deleteCachedForm,
  addFormSectionError
} from "../../../../../services/forms/forms.actions";
import { SUCCESS_MESSAGES } from "../../../../../core/constants";
import { addBankReportsManual } from "../../../../../services/data/bank.reports/bank.reports.saga";
import { evaluate } from "../../../../../services/data/coordinator/coordinator.saga";
import * as coordinatorActions from "../../../../../services/data/coordinator/coordinator.actions";

export function* submitManualScore(action) {
  const { appId, clientId, data, formId } = action;

  const bankReportRes = yield call(addBankReportsManual, {
    appId,
    clientId,
    data
  });

  if (!bankReportRes.success) {
    yield put(addFormSectionError(formId, bankReportRes.data));
    return;
  }

  const evaluateAction = coordinatorActions.evaluate({ appId, clientId });
  const evaluateRes = yield call(evaluate, evaluateAction);

  if (!evaluateRes.success) {
    yield put(addFormSectionError(formId, bankReportRes.data));
    return;
  }

  yield put(deleteCachedForm(formId));
  yield put(showSuccess(SUCCESS_MESSAGES.SCORE_CARD.ADD));
  yield call(fetchLoanApplicationDetails, {
    applicationId: appId
  });
}

function* watchLoanApplicationDetailsAsync() {
  yield takeLatest(actionTypes.MANUAL_SCORE_REQUEST, submitManualScore);
}

export default [fork(watchLoanApplicationDetailsAsync)];
