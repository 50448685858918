import { utils } from "@cauldron/core";
import {
  LA_SEARCH_PAGE,
  LA_MAIN_PAGE,
  SEARCH_TOOLBAR,
  SEARCH_TOOLBAR_SEARCH,
  SEARCH_TOOLBAR_SEARCH_INPUT,
  SEARCH_TOOLBAR_SEARCH_ICON_BTN,
  SEARCH_TOOLBAR_SEARCH_CLEAR_BTN,
  SEARCH_TOOLBAR_SETTINGS,
  SEARCH_TOOLBAR_SETTINGS_BTN,
  SEARCH_TOOLBAR_SETTINGS_POPPER,
  SEARCH_TOOLBAR_SETTINGS_POPPER_MENU,
  SEARCH_TOOLBAR_SETTINGS_POPPER_MENU_ITEM,
  SEARCH_TOOLBAR_SETTINGS_POPPER_MENU_CHECKBOX,
  SEARCH_TABLE,
  SEARCH_TABLE_SORT_BUTTON,
  SEARCH_TABLE_HEADER,
  SEARCH_TABLE_SORT_BUTTON_DEC,
  SEARCH_TABLE_SORT_BUTTON_ASC,
  SEARCH_TOOLBAR_SETTINGS_ACTION_GROUP,
  SEARCH_TOOLBAR_SETTINGS_ACTION_RESTORE
} from "../constants/data.test.ids";

const { generateTestIds } = utils;

const rootSettingsPopperTestId = generateTestIds(
  SEARCH_TOOLBAR,
  SEARCH_TOOLBAR_SETTINGS,
  SEARCH_TOOLBAR_SETTINGS_BTN,
  SEARCH_TOOLBAR_SETTINGS_POPPER
);

const rootSettingsButton = generateTestIds(
  SEARCH_TOOLBAR,
  SEARCH_TOOLBAR_SETTINGS,
  SEARCH_TOOLBAR_SETTINGS_BTN
);

export const searchTableTestId = {
  root: generateTestIds(LA_SEARCH_PAGE),
  main: generateTestIds(LA_MAIN_PAGE),
  toolbar: generateTestIds(SEARCH_TOOLBAR),
  search: {
    root: generateTestIds(SEARCH_TOOLBAR, SEARCH_TOOLBAR_SEARCH),
    input: generateTestIds(
      SEARCH_TOOLBAR,
      SEARCH_TOOLBAR_SEARCH,
      SEARCH_TOOLBAR_SEARCH_INPUT
    ),
    icon: generateTestIds(
      SEARCH_TOOLBAR,
      SEARCH_TOOLBAR_SEARCH,
      SEARCH_TOOLBAR_SEARCH_ICON_BTN
    ),
    clear: generateTestIds(
      SEARCH_TOOLBAR,
      SEARCH_TOOLBAR_SEARCH,
      SEARCH_TOOLBAR_SEARCH_CLEAR_BTN
    )
  },
  settings: {
    root: generateTestIds(SEARCH_TOOLBAR, SEARCH_TOOLBAR_SETTINGS),
    button: rootSettingsButton,
    popper: generateTestIds(rootSettingsPopperTestId),
    menu: generateTestIds(
      rootSettingsButton,
      SEARCH_TOOLBAR_SETTINGS_POPPER_MENU
    ),
    menuItem: generateTestIds(
      rootSettingsButton,
      SEARCH_TOOLBAR_SETTINGS_POPPER_MENU,
      SEARCH_TOOLBAR_SETTINGS_POPPER_MENU_ITEM
    ),
    checkbox: generateTestIds(
      rootSettingsButton,
      SEARCH_TOOLBAR_SETTINGS_POPPER_MENU,
      SEARCH_TOOLBAR_SETTINGS_POPPER_MENU_ITEM,
      SEARCH_TOOLBAR_SETTINGS_POPPER_MENU_CHECKBOX
    ),
    actionGroup: generateTestIds(
      rootSettingsButton,
      SEARCH_TOOLBAR_SETTINGS_ACTION_GROUP
    ),
    actionRestore: generateTestIds(
      rootSettingsButton,
      SEARCH_TOOLBAR_SETTINGS_ACTION_GROUP,
      SEARCH_TOOLBAR_SETTINGS_ACTION_RESTORE
    )
  },
  table: generateTestIds(SEARCH_TABLE),
  sort: {
    root: generateTestIds(SEARCH_TABLE, SEARCH_TABLE_SORT_BUTTON),
    button_dec: generateTestIds(SEARCH_TABLE, SEARCH_TABLE_SORT_BUTTON_DEC),
    button_asc: generateTestIds(SEARCH_TABLE, SEARCH_TABLE_SORT_BUTTON_ASC)
  },
  colHeader: generateTestIds(SEARCH_TABLE, SEARCH_TABLE_HEADER)
};
