import { i18nMark } from "@lingui/react";

export const SUCCESS_MESSAGES = {
  DEFAULT_DATA: {
    ADD: i18nMark("The data was successfully added."),
    UPDATE: i18nMark("The data was updated successfully."),
    DELETE: i18nMark("The data was successfully deleted.")
  },
  AUTH_USER: {
    ADD: i18nMark("Authorized user was successfully added."),
    UPDATE: i18nMark("Authorized user updated successfully."),
    DELETE: i18nMark("The authorized user was successfully deleted.")
  },
  BANK_ACCOUNT: {
    ADD: i18nMark("Bank account was successfully added."),
    UPDATE: i18nMark("Bank account updated successfully."),
    DELETE: i18nMark("The bank account was successfully deleted.")
  },
  COSIGNER: {
    ADD: i18nMark("Cosigner was successfully added."),
    UPDATE: i18nMark("Cosigner updated successfully."),
    DELETE: i18nMark("The cosigner was successfully deleted.")
  },
  DOCUMENT: {
    ADD: i18nMark(
      "Document was successfully added. Please refresh your page if you do not see the document."
    ),
    UPDATE: i18nMark("The document was updated successfully."),
    DELETE: i18nMark("The document was successfully deleted.")
  },
  EXPENSE: {
    ADD: i18nMark("Expense was successfully added."),
    UPDATE: i18nMark("Expense updated successfully."),
    DELETE: i18nMark("The expense was successfully deleted.")
  },
  INCOME: {
    ADD: i18nMark("Income was successfully added."),
    UPDATE: i18nMark("Income updated successfully."),
    DELETE: i18nMark("The income was successfully deleted.")
  },
  LIABILITY: {
    ADD: i18nMark("Liability was successfully added."),
    UPDATE: i18nMark("Liability updated successfully."),
    DELETE: i18nMark("The liability was successfully deleted.")
  },
  BANK_REQUEST: {
    SEND: i18nMark("Bank statement request was successfully sent."),
    COPY: i18nMark("The URL link was successfully copied to the clipboard."),
    UPLOAD: i18nMark(
      "Manual bank statement was successfully added. Please refresh your page if you do not see the document."
    )
  },
  NOTE: {
    ADD: i18nMark("Note was successfully added."),
    UPDATE: i18nMark("Note updated successfully."),
    DELETE: i18nMark("The note was successfully deleted.")
  },
  FUNDED_CLIENT_NAME: {
    UPDATE: i18nMark("Name was successfully updated.")
  },
  DEAL_LEAD: {
    UPDATE: i18nMark("Deal lead was successfully updated.")
  },
  REFERRAL_SOURCE: {
    UPDATE: i18nMark("Referral source was successfully updated.")
  },
  PERSONAL_DETAILS: {
    UPDATE: i18nMark("Personal details were successfully updated.")
  },
  CLIENT_EMAIL: {
    ADD: i18nMark("Email was successfully added."),
    UPDATE: i18nMark("Email was successfully updated."),
    DELETE: i18nMark("Email was successfully deleted.")
  },
  PHONE_NUMBER: {
    ADD: i18nMark("Phone number was successfully added."),
    UPDATE: i18nMark("Phone number was successfully updated."),
    DELETE: i18nMark("Phone number was successfully deleted.")
  },
  CLIENT_RESIDENCE: {
    ADD: i18nMark("Residence was successfully added."),
    UPDATE: i18nMark("Residence was successfully updated."),
    DELETE: i18nMark("Residence was successfully deleted.")
  },
  STATUS: {
    UPDATE: i18nMark("Status was successfully updated.")
  },
  LOAN_APPLICATION_NOTES: {
    ADD: i18nMark("Note was successfully added.")
  },
  SCORE_CARD: {
    ADD: i18nMark("The manual scorecard was successful")
  }
};
