import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import styles from "./styles/icon.button.styles";
import { BUTTON } from "../../../pages/constants/data.test.ids";

/**
 * ICON_BUTTON
 * wrapper for material-ui IconButton
 * SM | MD | LG
 */
const _IconButton = ({
  classes,
  size,
  dataTestId,
  dataTestBuilder,
  ...rest
}) => (
  <IconButton
    data-test={dataTestBuilder && dataTestBuilder(dataTestId, BUTTON)}
    classes={{
      root: classes[`root${size}`],
      label: classes.label,
      disabled: classes.disabled
    }}
    {...rest}
  />
);

_IconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf(["SM", "MD", "LG"]).isRequired,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

const enhance = compose(withStyles(styles));
export default enhance(_IconButton);
