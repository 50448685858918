import * as actionTypes from "../action.types/funded.loan.details.action.types";

/**
 * GET DETAILS PAGE DATA
 */
export function fetchDetailsPageData(id) {
  return { type: actionTypes.FUNDED_LOAN_DETAILS_LOAN_FETCH, id };
}

export function fetchDetailsPageDataSuccess(payload) {
  return { type: actionTypes.FUNDED_LOAN_DETAILS_LOAN_FETCH_SUCCESS, payload };
}

export function fetchDetailsPageDataFailure(error) {
  return { type: actionTypes.FUNDED_LOAN_DETAILS_LOAN_FETCH_FAILURE, error };
}

export function switchTab(tab) {
  return { type: actionTypes.FUNDED_LOAN_DETAILS_TAB_SWITCH, tab };
}

export function fetchStaticCodes() {
  return {
    type: actionTypes.FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH
  };
}

export function fetchStaticCodesSuccess(data) {
  return {
    type: actionTypes.FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH_SUCCESS,
    data
  };
}

export function fetchStaticCodesFailure(error) {
  return {
    type: actionTypes.FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH_FAILURE,
    error
  };
}
