import * as actionTypes from "../dashboard.action.types";
import { USER_LOGOUT_SUCCESS } from "../../../../services/auth";
import { MODULES } from "../../../../core/constants/modules";

const initialState = {
  modules: [],
  isAuditorUser: false
};

/**
 * Whitelist for dashboard.data
 * that will be persisted to the browser storage
 * @type {string[]}
 */
export const dashboardDataWhitelist = ["modules", "isAuditorUser"];

/**
 * Reducer for dashboard data
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_REQUEST_SUCCESS: {
      const isSuperUser = action.payload.superUser;
      const isAuditorUser =
        action.payload.auditorUser && !action.payload.superUser;
      // If user is a super user or an auditor user, all modules will be available
      const modules =
        isSuperUser || isAuditorUser ? MODULES : action.payload.modules;

      return {
        ...state,
        modules,
        isAuditorUser
      };
    }
    case actionTypes.DASHBOARD_REQUEST_FAIL:
      return {
        ...state
      };

    case USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
