import React from "react";
import PropTypes from "prop-types";
import Shiitake from "shiitake";

/**
 * Clamp
 * @param props
 * @returns {string}
 * @constructor
 */
function Clamp(props) {
  const { lines, children } = props;
  return children ? (
    <Shiitake
      lines={lines}
      throttleRate={100}
      tagName="span"
      overflowNode={" \u2026"}
    >
      {children}
    </Shiitake>
  ) : (
    ""
  );
}

Clamp.defaultProps = {
  lines: 3
};

Clamp.propTypes = {
  lines: PropTypes.number
};

export default Clamp;
