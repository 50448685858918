import { call, put, fork, takeLatest, all } from "redux-saga/effects";
import { actionTypes } from "./applications.action.types";
import { linkClientSuccess, linkClientFailure } from "./applications.actions";
import { http } from "../../http/http.service";

function linkClient({ clientId, applicationId, eventHandlers }) {
  const { onSuccess } = eventHandlers;
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.APPLICATION_ADD_CLIENT(applicationId), {
        clientId
      });
    },
    success: data => {
      return all([call(onSuccess, data), put(linkClientSuccess(data))]);
    },
    error: error => {
      return put(linkClientFailure(error));
    }
  });
}

function* watchLoanApplicationAsync() {
  yield takeLatest(actionTypes.APPLICATION_CLIENT_LINK_REQUEST, linkClient);
}

export default [fork(watchLoanApplicationAsync)];
