const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    background: theme.palette.common["white"]
  },
  stretch: {
    height: "100%",
    overflowY: "auto"
  }
});

export default styles;
