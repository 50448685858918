import { fork, call, put, takeLatest, all } from "redux-saga/effects";
import {
  termsAndConditionsConsent,
  newsletterConsent,
  creditPullConsent
} from "@cauldron/core/src/marketing";
import { http } from "../../http/http.service";
import { actionTypes } from "./app.service.consents.action.types";
import {
  postConsentsFailure,
  postConsentsSuccess
} from "./app.service.consents.actions";

export const updateConsents = action => {
  const { appId, clientId, eventHandlers = {} } = action;
  const { onSuccess, onFailure } = eventHandlers;
  const payload = [];
  if (action.hasTermsConsent) {
    payload.push(termsAndConditionsConsent[0]);
  }

  if (action.hasCreditPullConsent) {
    payload.push(creditPullConsent[0]);
  }

  if (action.hasNewsletterConsent) {
    payload.push(newsletterConsent[0]);
  }

  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.put, API.CONSENTS(appId, clientId), payload);
    },
    success: () =>
      onSuccess
        ? all([put(postConsentsSuccess()), call(onSuccess)])
        : put(postConsentsSuccess()),
    error: e =>
      onFailure
        ? all([put(postConsentsFailure(e)), call(onFailure)])
        : put(postConsentsFailure(e))
  });
};

export function* watchAppServiceConsentsAsync() {
  yield takeLatest(actionTypes.POST_CONSENTS_REQUEST, updateConsents);
}

export default [fork(watchAppServiceConsentsAsync)];
