/**
 * SELECT_FILTER_EXPAND_BTN STYLES
 */
const styles = theme => ({
  root: {
    color: theme.palette.grey[900],
    opacity: 1,
    transition: "opacity, color 200ms ease-in-out",

    "&:not([disabled])": {
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary["light"]
      }
    }
  },
  toggleMenuBtn: {
    "&[disabled]": {
      opacity: 0.25
    }
  },
  toggleMenuIcon: {
    height: "20px"
  },
  clearBtn: {
    "&[disabled]": {
      opacity: 0
    }
  },
  clearIcon: {
    height: "16px"
  },
  clearBtnHidden: {
    pointerEvents: "none",
    opacity: 0
  }
});

export default styles;
