import {
  TRACKER_INIT,
  TRACKER_UPDATE,
  TRACKER_UPDATE_WITH_CATEGORY,
  TRACKER_CLEAR,
  TRACKER_CLEAR_WITH_CATEGORY,
  TRACKER_COLLAPSE_ALL_UPDATE
} from ".";

function initWithoutClients() {
  return {
    "0-borrower-editName": { expanded: true },
    "0-borrower-personal": { expanded: true },
    "0-borrower-contact": { expanded: true },
    "0-borrower-residence": { expanded: true },
    "0-borrower-authUser": { expanded: true },
    "1-coborrower-editName": { expanded: true },
    "1-coborrower-personal": { expanded: true },
    "1-coborrower-contact": { expanded: true },
    "1-coborrower-residence": { expanded: true },
    "1-coborrower-authUser": { expanded: true }
  };
}

function initClientTrackers(clients) {
  if (!clients) {
    return initWithoutClients();
  } else {
    return clients.reduce((clientTrackers, client) => {
      const clientType = client.primary ? "borrower" : "coborrower";
      const identifier = `${client.id}-${clientType}`;
      const personalId = `${identifier}-personal`;
      const contactId = `${identifier}-contact`;
      const residenceId = `${identifier}-residence`;
      const authUserId = `${identifier}-authUser`;
      const editNameFormId = `${identifier}-editName`;

      const hasAuthUsers = client.authUsers.length;

      clientTrackers[editNameFormId] = { expanded: true };
      clientTrackers[personalId] = { expanded: true };
      clientTrackers[contactId] = { expanded: true };
      clientTrackers[residenceId] = { expanded: true };
      clientTrackers[authUserId] = { expanded: hasAuthUsers ? true : false };
      return clientTrackers;
    }, {});
  }
}

function initTabbedTrackers(state, tab) {
  if (tab === "loan") {
    return {
      loan_details: { expanded: true },
      payment_details: { expanded: true },
      bank_details: { expanded: true },
      summary_details: { expanded: false },
      other_loans: { expanded: false }
    };
  }
}

function updateTrackers(state, { identifier, data }) {
  const updatedTrackers = {
    ...state.ui
  };
  identifier.forEach(id => {
    updatedTrackers[id] = data;
  });

  return {
    ...state,
    ...updatedTrackers
  };
}

function updateTracker(state, { identifier, data }) {
  return {
    ...state,
    [identifier]: data
  };
}

function updateTrackerWithCategory(state, { identifier, data, category }) {
  return {
    ...state,
    [category]: {
      ...state[category],
      [identifier]: data
    }
  };
}

function trackerClear() {
  return {};
}

function trackerUpdate(state, action) {
  const updaters = {
    single: updateTracker,
    multiple: updateTrackers
  };
  const { identifier } = action;
  const type = Array.isArray(identifier) ? "multiple" : "single";
  return updaters[type](state, action);
}

function trackerUpdateWithCategory(state, action) {
  const updaters = {
    single: updateTrackerWithCategory,
    multiple: updateTrackers
  };
  const { identifier } = action;
  const type = Array.isArray(identifier) ? "multiple" : "single";
  return updaters[type](state, action);
}

function trackerClearWithCategory(state, { category }) {
  return {
    ...state,
    [category]: {}
  };
}

function trackerInit(state, action) {
  const clientTrackers = initClientTrackers();
  const tabbedTrackers = action.tab
    ? initTabbedTrackers(state, action.tab)
    : {};

  return {
    ...clientTrackers,
    ...tabbedTrackers
  };
}

function trackerUpdateCollapseAll(state, { category, value }) {
  return {
    ...state,
    [category]: {
      collapseAll: value
    }
  };
}

export default function(state = {}, action) {
  switch (action.type) {
    case TRACKER_INIT:
      return trackerInit(state, action);
    case TRACKER_UPDATE:
      return trackerUpdate(state, action);
    case TRACKER_CLEAR:
      return trackerClear(state, action);
    case TRACKER_UPDATE_WITH_CATEGORY:
      return trackerUpdateWithCategory(state, action);
    case TRACKER_CLEAR_WITH_CATEGORY:
      return trackerClearWithCategory(state, action);
    case TRACKER_COLLAPSE_ALL_UPDATE:
      return trackerUpdateCollapseAll(state, action);
    default:
      return state;
  }
}
