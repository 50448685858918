import React from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles/avatar.styles";

function AvatarMenu({
  anchorEl,
  dataTestId,
  menuOpen,
  menuClose,
  classes,
  onLogout
}) {
  return (
    <Menu
      id="avatar-user-menu"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={menuClose}
      disableAutoFocusItem
    >
      <MenuItem
        data-test={dataTestId.avatarMenuItem}
        key="logout"
        id="logout"
        onClick={onLogout}
        className={classes.menuItem}
      >
        <Typography className={classes.menuItemText}>Log Out</Typography>
      </MenuItem>
    </Menu>
  );
}

AvatarMenu.propTypes = {
  dataTestId: PropTypes.shape({
    avatarMenuItem: PropTypes.string
  }),
  classes: PropTypes.object.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  menuClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object.isRequired
};

export default withStyles(styles)(AvatarMenu);
