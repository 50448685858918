import MultiSelectMenu from "./nested.menu.multiselect";
import SelectMenu from "./nested.menu.selectmenu";

const components = type => {
  const component = {
    MULTI_SELECT: MultiSelectMenu,
    SELECT: SelectMenu
  };
  return component.hasOwnProperty(type) ? component[type] : component["SELECT"];
};

export default components;
