"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "AppClient";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  bankAccounts: [],
  incomeSources: [],
  expenses: [],
  liabilities: [],
  assets: []
};
/**
 * Protects data from change in data type
 */

var schema = {
  bankAccounts: _propTypes.default.array,
  incomeSources: _propTypes.default.array,
  expenses: _propTypes.default.array,
  liabilities: _propTypes.default.array,
  assets: _propTypes.default.array
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var _default = {
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;