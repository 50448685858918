"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TEST = exports.DEVELOPMENT = exports.PRODUCTION = void 0;
var PRODUCTION = "production";
exports.PRODUCTION = PRODUCTION;
var DEVELOPMENT = "development";
exports.DEVELOPMENT = DEVELOPMENT;
var TEST = "test";
exports.TEST = TEST;