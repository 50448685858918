import { fork, call, put, takeLatest, all } from "redux-saga/effects";
import {
  LOAN_APPLICATION_NOTES_FETCH,
  LOAN_APPLICATION_NOTES_ADD
} from "./notes.action.types";
import {
  fetchLoanApplicationNotesSuccess,
  fetchLoanApplicationNotesFailure,
  addLoanApplicationNotesSuccess
} from "./notes.actions";
import { http } from "../../http/http.service";
import {
  deleteCachedForm,
  isFetchingForm,
  addFormSectionError
} from "../../forms/forms.actions";
import {
  addSectionError,
  clearSectionError,
  APP_SECTIONS
} from "../../error.handler";
import { SUCCESS_MESSAGES } from "../../../core/constants";
import * as messengerActions from "../../messenger/messenger.actions";

export const fetchLoanApplicationNotes = action =>
  http({
    request: (httpSrv, API) => {
      const appId = action.applicationId;
      return call(httpSrv.get, API.APPLICATION_NOTES(appId));
    },
    success: data =>
      all([
        put(fetchLoanApplicationNotesSuccess(data)),
        put(clearSectionError(APP_SECTIONS.LOAN_APPS_NOTES))
      ]),
    error: error =>
      all([
        put(fetchLoanApplicationNotesFailure(error)),
        put(addSectionError(APP_SECTIONS.LOAN_APPS_NOTES, error))
      ])
  });

export const addLoanApplicationNotes = action => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.APPLICATION_NOTES(action.applicationId),
        action.data
      );
    },
    success: data => {
      return [
        put(addLoanApplicationNotesSuccess(data)),
        put(deleteCachedForm(action.formId)),
        put(
          messengerActions.showSuccess(
            SUCCESS_MESSAGES.LOAN_APPLICATION_NOTES.ADD
          )
        )
      ];
    },
    error: error => [
      put(isFetchingForm(action.formId, false)),
      put(put(addFormSectionError(action.formId, error)))
    ]
  });
};

export function* watchDocumentAsync() {
  yield takeLatest(LOAN_APPLICATION_NOTES_FETCH, fetchLoanApplicationNotes);
  yield takeLatest(LOAN_APPLICATION_NOTES_ADD, addLoanApplicationNotes);
}

export default [fork(watchDocumentAsync)];
