import {
  GET_DOCUMENT_SUCCESS,
  GET_OUTSTANDING_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_SUCCESS
} from "./document.action.types";

const initialState = {
  docs: [],
  outstandingDocs: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENT_SUCCESS:
      return { ...state, docs: action.data.results };

    case GET_OUTSTANDING_DOCUMENT_SUCCESS:
      return { ...state, outstandingDocs: action.data.results };

    case EDIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        docs: state.docs.map(doc => {
          if (doc.id === action.data.id) {
            return { ...doc, ...action.data };
          }
          return doc;
        })
      };

    default:
      return state;
  }
};
