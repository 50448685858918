import { utils } from "@cauldron/core";
import { actionTypes } from "./app.service.consents.action.types";

const { makeActionCreator } = utils;

export const postConsents = makeActionCreator(
  actionTypes.POST_CONSENTS_REQUEST,
  "appId",
  "clientId",
  "hasTermsConsent",
  "hasCreditPullConsent",
  "hasNewsletterConsent",
  "eventHandlers"
);

export const postConsentsSuccess = makeActionCreator(
  actionTypes.POST_CONSENTS_SUCCESS
);

export const postConsentsFailure = makeActionCreator(
  actionTypes.POST_CONSENTS_FAILURE,
  "error"
);
