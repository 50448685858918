import {
  put,
  takeEvery,
  takeLatest,
  fork,
  call,
  all
} from "redux-saga/effects";
import { domain } from "@cauldron/core";
import { http } from "../../../../services/http/http.service";
import {
  isFetchingForm,
  deleteCachedForm,
  addFormSectionError
} from "../../../../services/forms/forms.actions";

import {
  LOAN_APPLICATION_DETAILS_FETCH,
  LOAN_APPLICATION_NOTES_FETCH,
  LOAN_APPLICATION_EMAILS_FETCH,
  LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH,
  LOAN_APPLICATION_CLIENT_EXPENSE_ADD,
  LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE,
  LOAN_APPLICATION_CLIENT_EXPENSE_DELETE,
  LOAN_APPLICATION_CLIENT_ADD,
  LOAN_APPLICATION_CLIENT_DELETE,
  LOAN_APPLICATION_CLIENT_APPLICATION_LINK,
  LOAN_APPLICATION_DEAL_LEAD_UPDATE,
  LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH,
  LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE,
  LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_REQUEST,
  LOAN_APPLICATION_GET_CREDIT_REPORT_SUMMARY,
  LOAN_APPLICATION_CLIENT_EMAIL_ADD,
  LOAN_APPLICATION_CLIENT_EMAIL_UPDATE,
  LOAN_APPLICATION_CLIENT_EMAIL_DELETE,
  LOAN_APPLICATION_PHONE_NUMBER_ADD,
  LOAN_APPLICATION_PHONE_NUMBER_UPDATE_REQUEST,
  LOAN_APPLICATION_PHONE_NUMBER_DELETE,
  LOAN_APPLICATION_CLIENT_RESIDENCE_ADD,
  LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_REQUEST,
  LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE,
  LOAN_APPLICATION_NOTES_ADD
} from "../action.types/loan.application.details.action.types";
import {
  LOAN_APPLICATION_BANK_ACCOUNT_ADD,
  LOAN_APPLICATION_BANK_ACCOUNT_DELETE,
  LOAN_APPLICATION_BANK_ACCOUNT_UPDATE
} from "../../components/application.card/components/bank.details/engine/bank.details.action.types";
import {
  LOAN_APPLICATION_CLIENT_LIABILITY_DELETE,
  LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE,
  LOAN_APPLICATION_CLIENT_LIABILITY_ADD
} from "../../components/application.card/components/liabilities/engine/liabilities.action.types";
import {
  fetchLoanApplicationDetailsSuccess,
  fetchLoanApplicationDetailsFailure,
  fetchLoanApplicationNotesFailure,
  fetchLoanApplicationNotesSuccess,
  fetchLoanApplicationEmailsSuccess,
  fetchLoanApplicationEmailsFailure,
  fetchDebtServiceRatio,
  fetchDebtServiceRatioSuccess,
  fetchDebtServiceRatioFailure,
  addClientExpenseSuccess,
  addClientExpenseFailure,
  cleanAddCacheExpense,
  cleanUpdateCacheExpense,
  updateClientExpenseSuccess,
  updateClientExpenseFailure,
  deleteClientExpenseSuccess,
  deleteClientExpenseFailure,
  linkClientToApplication,
  addClientFailure,
  deleteClientSuccess,
  deleteClientFailure,
  updateDealLeadSuccess,
  updateDealLeadFailure,
  cleanUpdateClientDealLead,
  fetchDealLeadOptionsSuccess,
  fetchDealLeadOptionsFailure,
  updateReferralSourceSuccess,
  cleanUpdateClientReferral,
  updatePersonalDetailsSuccess,
  updatePersonalDetailsFailure,
  fetchCreditReportSummarySuccess,
  addClientEmailSuccess,
  addClientEmailFailure,
  updateClientEmailSuccess,
  updateClientEmailFailure,
  deleteClientEmailSuccess,
  deleteClientEmailFailure,
  addPhoneNumberSuccess,
  addPhoneNumberFailure,
  updatePhoneNumberSuccess,
  updatePhoneNumberFailure,
  deletePhoneNumberSuccess,
  deletePhoneNumberFailure,
  cacheAddClientExpense,
  cacheUpdateClientExpense,
  addClientResidenceSuccess,
  addClientResidenceFailure,
  updateClientResidenceSuccess,
  updateClientResidenceFailure,
  deleteClientResidenceSuccess,
  deleteClientResidenceFailure,
  updateStatusSuccess,
  addLoanApplicationNotesSuccess,
  addClientSuccess
} from "../actions/loan.application.details.actions";
import {
  addNewBankAccountSuccess,
  addNewBankAccountFailure,
  deleteBankAccountSuccess,
  deleteBankAccountFailure,
  updateBankAccountSuccess,
  updateBankAccountFailure,
  cancelEditBankAccountForm
} from "../../components/application.card/components/bank.details/engine/bank.details.actions";
import {
  addClientLiabilitySuccess,
  addClientLiabilityFailure,
  deleteClientLiabilitySuccess,
  deleteClientLiabilityFailure,
  updateClientLiabilitySuccess,
  updateClientLiabilityFailure
} from "../../components/application.card/components/liabilities/engine/liabilities.actions";

import * as messengerActions from "../../../../services/messenger/messenger.actions";
import { SUCCESS_MESSAGES, ERROR_MESSAGES } from "../../../../core/constants";
import {
  prepareClientModelData,
  transformPersonalDetailsReceived
} from "../../../../models/clients/client.model";
import { userContextProfileRequest } from "../../../../services/auth";
import { transformDealLeadListReceived } from "../../../../models/dealLead/deal.lead.model";
import { transformAddressToSend } from "../../../../models/clients/address.model";
import {
  addSectionError,
  clearSectionError,
  APP_SECTIONS
} from "../../../../services/error.handler";
import { postConsents } from "../../../../services/data/app.service.consents/app.service.consents.actions";
import { actionTypes as statusActionTypes } from "../../../../services/data/status";

// DOMAIN MODEL OR COLLECTION
const { Application, DebtServiceRatio } = domain;

/**
 * GET APPLICATION DETAILS
 * Get details page data base on the passed id from the search page
 * @param {object} action
 */
export const fetchLoanApplicationDetails = action =>
  http({
    request: function*(httpSrv, API) {
      const appId = action.applicationId;
      yield userContextProfileRequest(API.APPLICATION_PROFILE(appId));
      return yield call(httpSrv.get, API.APPLICATION_EXTENDED(appId));
    },
    success: data => put(fetchLoanApplicationDetailsSuccess(data)),
    error: error =>
      all([
        put(addSectionError(APP_SECTIONS.LOAN_APPS_DETAILS, error)),
        put(fetchLoanApplicationDetailsFailure(error))
      ]),
    transformReceiveData: Application.model.transformReceived
  });

/**
 * FETCH NOTES
 * Fetch application notes/comments
 * @param {object} action
 * @returns {IterableIterator<*>}
 */
export const fetchLoanApplicationNotes = action =>
  http({
    request: (httpSrv, API) => {
      const appId = action.applicationId;
      return call(httpSrv.get, API.APPLICATION_NOTES(appId));
    },
    success: data =>
      all([
        put(fetchLoanApplicationNotesSuccess(data)),
        put(clearSectionError(APP_SECTIONS.LOAN_APPS_NOTES))
      ]),
    error: error =>
      all([
        put(fetchLoanApplicationNotesFailure(error)),
        put(addSectionError(APP_SECTIONS.LOAN_APPS_NOTES, error))
      ])
  });

/**
 * FETCH EMAILS
 * Fetch application emails
 * @param {object} action
 * @returns {IterableIterator<*>}
 */
export const fetchLoanApplicationEmails = action =>
  http({
    request: (httpSrv, API) => {
      const appId = action.applicationId;
      return call(httpSrv.get, API.APPLICATION_EMAILS(appId));
    },
    success: data => put(fetchLoanApplicationEmailsSuccess(data)),
    error: error => put(fetchLoanApplicationEmailsFailure(error))
  });

/**
 * FETCH AFFORDABILITY
 */
export const fetchLoanApplicationsAffordability = action =>
  http({
    request: (httpSrv, API) => {
      const appId = action.applicationId;
      return call(httpSrv.get, API.APPLICATION_DEBT_SERVICE_RATIO(appId));
    },
    success: data => put(fetchDebtServiceRatioSuccess(data)),
    error: error => put(fetchDebtServiceRatioFailure(error)),
    transformReceiveData: DebtServiceRatio.model.transformReceived
  });

/**
 * CREATE CLIENT LIABILITY
 */
export const createClientLiability = action =>
  http({
    request: (httpSrv, API) => {
      const clientId = action.clientId;
      return call(httpSrv.post, API.CLIENT_LIABILITIES(clientId), action.data);
    },
    success: data =>
      all([
        put(addClientLiabilitySuccess(action.clientId, data)),
        put(deleteCachedForm(action.formCacheId)),
        put(fetchDebtServiceRatio(action.applicationId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.LIABILITY.ADD))
      ]),
    error: error => [
      put(addClientLiabilityFailure(action.clientId, error)),
      put(messengerActions.showError(ERROR_MESSAGES.LIABILITY.ADD))
    ]
  });

/**
 * DELETE CLIENT LIABILITY
 */
export const removeClientLiability = action =>
  http({
    request: (httpSrv, API) => {
      const clientId = action.clientId;
      const liabilityId = action.liabilityId;
      return call(httpSrv.delete, API.CLIENT_LIABILITY(clientId, liabilityId));
    },
    success: () =>
      all([
        put(deleteClientLiabilitySuccess(action.clientId, action.liabilityId)),
        put(fetchDebtServiceRatio(action.applicationId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.LIABILITY.DELETE))
      ]),
    error: error => [
      put(deleteClientLiabilityFailure(action.clientId, error)),
      put(messengerActions.showError(ERROR_MESSAGES.LIABILITY.DELETE))
    ]
  });

/**
 * UPDATE CLIENT LIABILITY
 */

export const updateClientLiability = action =>
  http({
    request: (httpSrv, API) => {
      const clientId = action.clientId;
      const liabilityId = action.liabilityId;
      const values = action.values;
      return call(
        httpSrv.put,
        API.CLIENT_LIABILITY(clientId, liabilityId),
        values
      );
    },
    success: data =>
      all([
        put(deleteCachedForm(action.liabilityId, true)),
        // put(cancelEditLiabilityForm(action.clientId, action.liabilityId)),
        put(
          updateClientLiabilitySuccess(
            action.clientId,
            action.liabilityId,
            data
          )
        ),
        put(fetchDebtServiceRatio(action.applicationId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.LIABILITY.UPDATE))
      ]),
    error: error => [
      put(updateClientLiabilityFailure(action.clientId, error)),
      put(messengerActions.showError(ERROR_MESSAGES.LIABILITY.UPDATE))
    ]
  });

/**
 * CREATE CLIENT EXPENSE
 */
export const createClientExpense = action =>
  http({
    request: (httpSrv, API) => {
      const clientId = action.clientId;
      return call(httpSrv.post, API.CLIENT_EXPENSES(clientId), action.data);
    },
    success: data =>
      all([
        put(cleanAddCacheExpense(action.clientId, action.index)),
        put(addClientExpenseSuccess(action.clientId, data)),
        put(fetchDebtServiceRatio(action.applicationId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.EXPENSE.ADD))
      ]),
    error: error =>
      all([
        put(addClientExpenseFailure(action.clientId, error)),
        put(
          cacheAddClientExpense({
            id: action.clientId,
            values: {
              ...action.data,
              errors: { ...action.data.errors, row: error.message }
            },
            index: action.index
          })
        )
      ])
  });

/**
 * UPDATE CLIENT EXPENSE
 */
export const updateClientExpense = action =>
  http({
    request: (httpSrv, API) => {
      const { clientId, expenseId } = action;
      return call(
        httpSrv.put,
        API.CLIENT_EXPENSE(clientId, expenseId),
        action.data
      );
    },
    success: data =>
      all([
        put(
          updateClientExpenseSuccess(action.clientId, action.expenseId, data)
        ),
        put(cleanUpdateCacheExpense(action.expenseId)),
        put(fetchDebtServiceRatio(action.applicationId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.EXPENSE.UPDATE))
      ]),
    error: error =>
      all([
        put(updateClientExpenseFailure(action.clientId, error)),
        put(
          cacheUpdateClientExpense({
            id: action.expenseId,
            values: {
              ...action.data,
              errors: { ...action.data.errors, row: error.message }
            },
            index: action.index
          })
        )
      ])
  });

/**
 * DELETE CLIENT EXPENSE
 */
export const deleteClientExpense = action =>
  http({
    request: (httpSrv, API) => {
      const { clientId, expenseId } = action;
      return call(
        httpSrv.delete,
        API.CLIENT_EXPENSE(clientId, expenseId),
        action.data
      );
    },
    success: () =>
      all([
        put(deleteClientExpenseSuccess(action.clientId, action.expenseId)),
        put(fetchDebtServiceRatio(action.applicationId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.EXPENSE.DELETE))
      ]),
    error: error =>
      all([
        put(deleteClientExpenseFailure(action.clientId, error)),
        put(
          cacheUpdateClientExpense({
            id: action.expenseId,
            values: {
              ...action.data,
              errors: { ...action.data.errors, row: error.message }
            },
            index: action.index
          })
        )
      ])
  });

/**
 * ADD CLIENT BANK ACCOUNT
 */
export const addClientBankAccount = action => {
  return http({
    request: (httpSrv, API) => {
      const clientId = action.clientId;
      return call(
        httpSrv.post,
        API.CLIENT_BANK_ACCOUNTS(clientId),
        action.values
      );
    },
    success: data => [
      put(addNewBankAccountSuccess(action.clientId, data)),
      put(deleteCachedForm(action.formId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.BANK_ACCOUNT.ADD))
    ],
    error: error => [
      put(addNewBankAccountFailure(action.clientId, error)),
      put(messengerActions.showError(ERROR_MESSAGES.BANK_ACCOUNT.ADD))
    ]
  });
};

/**
 * DELETE CLIENT BANK ACCOUNT
 */
export const removeClientBankAccount = ({ accountId, clientId }) => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.delete, API.CLIENT_BANK_ACCOUNT(clientId, accountId));
    },
    success: data => [
      put(deleteBankAccountSuccess(clientId, data, accountId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.BANK_ACCOUNT.DELETE))
    ],
    error: error => [
      put(deleteBankAccountFailure(clientId, error)),
      put(messengerActions.showError(ERROR_MESSAGES.BANK_ACCOUNT.DELETE))
    ]
  });
};

/**
 * UPDATE CLIENT BANK ACCOUNT
 */
export const updateClientBankAccount = ({ accountId, clientId, values }) => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.put,
        API.CLIENT_BANK_ACCOUNT(clientId, accountId),
        values
      );
    },
    success: data => [
      put(updateBankAccountSuccess(clientId, data, accountId)),
      put(cancelEditBankAccountForm(clientId, accountId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.BANK_ACCOUNT.UPDATE))
    ],
    error: error => [
      put(updateBankAccountFailure(clientId, accountId, error)),
      put(messengerActions.showError(ERROR_MESSAGES.BANK_ACCOUNT.UPDATE))
    ]
  });
};

/**
 * Add client
 * @param applicationId
 * @param values
 */
export const addClient = ({ applicationId, data }) => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.CLIENTS, prepareClientModelData(data));
    },
    success: response =>
      put(linkClientToApplication(response.id, applicationId, data, response)),
    error: error => [
      put(addClientFailure(applicationId, null, error)),
      put(messengerActions.showError(ERROR_MESSAGES.COSIGNER.ADD))
    ]
  });
};

/**
 * Add client
 * @param applicationId
 * @param values
 */
export const deleteClient = ({ applicationId, data }) => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.APPLICATION_REMOVE_CLIENT(applicationId),
        data
      );
    },
    success: () => [
      put(deleteClientSuccess(data.clientId)),
      put(fetchDebtServiceRatio(applicationId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.COSIGNER.DELETE))
    ],
    error: () => [
      put(deleteClientFailure()),
      put(messengerActions.showError(ERROR_MESSAGES.COSIGNER.DELETE))
    ]
  });
};

/**
 * link client to application
 * @param clientId
 * @param applicationId
 * @param data
 * @returns {IterableIterator<*>}
 */
export const linkClientApplication = ({
  clientId,
  applicationId,
  payload,
  response
}) => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.APPLICATION_ADD_CLIENT(applicationId), {
        clientId
      });
    },
    success: () =>
      all([
        put(addClientSuccess(applicationId, clientId, response)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.COSIGNER.ADD)),
        put(
          postConsents(
            applicationId,
            clientId,
            payload.termsAndConditionsConsent,
            payload.creditPullConsent,
            payload.newsletterConsent
          )
        )
      ]),
    error: error => [
      put(addClientFailure(applicationId, clientId, error)),
      put(messengerActions.showError(ERROR_MESSAGES.COSIGNER.ADD))
    ]
  });
};

/**
 * Update a deal lead
 * @returns {IterableIterator<*>}
 */
export const updateDealLead = ({ applicationId, data }) => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.APPLICATION_DEAL_LEAD(applicationId), data);
    },
    success: data => [
      put(updateDealLeadSuccess(data)),
      put(cleanUpdateClientDealLead(applicationId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.DEAL_LEAD.UPDATE))
    ],
    error: () => [
      put(updateDealLeadFailure()),
      put(messengerActions.showError(ERROR_MESSAGES.DEAL_LEAD.UPDATE))
    ]
  });
};

/**
 * Fetch list of deal lead options
 * @returns {IterableIterator<*>}
 */
export const fetchDealLeadOptions = () => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.get, API.APPLICATIONS_USERS);
    },
    success: data => [put(fetchDealLeadOptionsSuccess(data))],
    error: () => [put(fetchDealLeadOptionsFailure())],
    transformReceiveData: transformDealLeadListReceived
  });
};

/*
 * Update a referral source
 * @returns {IterableIterator<*>}
 */
export const updateReferralSource = ({ applicationId, data }) => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.APPLICATION_REFERRAL(applicationId), data);
    },
    success: data => [
      put(updateReferralSourceSuccess(data)),
      put(cleanUpdateClientReferral(applicationId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.REFERRAL_SOURCE.UPDATE))
    ],
    error: () => [
      put(messengerActions.showError(ERROR_MESSAGES.REFERRAL_SOURCE.UPDATE))
    ]
  });
};

export const updatePersonalDetails = action => {
  const { eventHandlers = null } = action;

  return http({
    request: (httpSrv, API) => {
      return all([
        put(isFetchingForm(action.formId, true)),
        call(httpSrv.patch, API.CLIENT(action.clientId), action.data)
      ]);
    },
    success: data => {
      const successActions = [
        put(updatePersonalDetailsSuccess(action.clientId, data))
      ];

      if (eventHandlers && eventHandlers.onSuccess) {
        successActions.push(call(eventHandlers.onSuccess));
        return all(successActions);
      }

      return all(
        successActions.concat([
          put(isFetchingForm(action.formId, false)),
          put(deleteCachedForm(action.formId, true)),
          put(
            messengerActions.showSuccess(
              SUCCESS_MESSAGES.PERSONAL_DETAILS.UPDATE
            )
          )
        ])
      );
    },
    error: error => {
      const failureActions = [put(updatePersonalDetailsFailure())];

      if (eventHandlers && eventHandlers.onFailure) {
        failureActions.push(call(eventHandlers.onFailure));
        return all(failureActions);
      }

      return all(
        failureActions.concat([
          put(isFetchingForm(action.formId, false)),
          put(addFormSectionError(action.formId, error))
        ])
      );
    },
    transformReceiveData: transformPersonalDetailsReceived
  });
};

export const fetchCreditReportSummary = ({ applicationId, clientId }) => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.get,
        API.CREDIT_REPORTS_SUMMARY(applicationId, clientId)
      );
    },
    success: data => [put(fetchCreditReportSummarySuccess(clientId, data))]
  });
};

export const updateClientEmail = action => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.put,
        API.CLIENT_EMAILS_PUT(action.clientId, action.emailId),
        action.data
      );
    },
    success: data => {
      return [
        put(updateClientEmailSuccess(action.emailId, action.clientId, data)),
        put(deleteCachedForm(action.formId, true)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.CLIENT_EMAIL.UPDATE))
      ];
    },
    error: () => [
      put(updateClientEmailFailure()),
      put(isFetchingForm(action.formId, false)),
      put(messengerActions.showError(ERROR_MESSAGES.CLIENT_EMAIL.UPDATE))
    ]
  });
};

export const deleteClientEmail = action => {
  return http({
    request: (httpSrv, API) => {
      return [
        call(
          httpSrv.delete,
          API.CLIENT_EMAILS_DELETE(action.clientId, action.emailId)
        )
      ];
    },
    success: () => {
      return [
        put(deleteClientEmailSuccess(action.clientId, action.emailId)),
        put(deleteCachedForm(action.formId, true)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.CLIENT_EMAIL.DELETE))
      ];
    },
    error: () => [
      put(deleteClientEmailFailure()),
      put(isFetchingForm(action.formId, false)),
      put(messengerActions.showError(ERROR_MESSAGES.CLIENT_EMAIL.DELETE))
    ]
  });
};

export const addClientEmail = action => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.CLIENT_EMAILS(action.clientId),
        action.data
      );
    },
    success: data => {
      return [
        put(addClientEmailSuccess(action.clientId, data)),
        put(deleteCachedForm(action.formId, true)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.CLIENT_EMAIL.ADD))
      ];
    },
    error: () => [
      put(addClientEmailFailure()),
      put(isFetchingForm(action.formId, false)),
      put(messengerActions.showError(ERROR_MESSAGES.CLIENT_EMAIL.ADD))
    ]
  });
};

/**
 * UPDATE CLIENT PHONE NUMBER
 */

export const updatePhoneNumber = action => {
  const { eventHandlers = null } = action;

  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.put,
        API.CLIENT_PHONE_NUMBERS_PUT(action.clientId, action.phoneId),
        action.data
      );
    },
    success: data => {
      const successActions = [
        put(updatePhoneNumberSuccess(action.phoneId, action.clientId, data))
      ];

      if (eventHandlers && eventHandlers.onSuccess) {
        successActions.push(call(eventHandlers.onSuccess));
        return all(successActions);
      }

      return all(
        successActions.concat([
          put(deleteCachedForm(action.formId, true)),
          put(
            messengerActions.showSuccess(SUCCESS_MESSAGES.PHONE_NUMBER.UPDATE)
          )
        ])
      );
    },
    error: () => {
      const failureActions = [put(updatePhoneNumberFailure())];

      if (eventHandlers && eventHandlers.onFailure) {
        failureActions.push(call(eventHandlers.onFailure));
        return all(failureActions);
      }

      return all(
        failureActions.concat([
          put(isFetchingForm(action.formId, false)),
          put(messengerActions.showError(ERROR_MESSAGES.PHONE_NUMBER.UPDATE))
        ])
      );
    }
  });
};

export const deletePhoneNumber = action => {
  return http({
    request: (httpSrv, API) => {
      return [
        call(
          httpSrv.delete,
          API.CLIENT_PHONE_NUMBERS_DELETE(action.clientId, action.phoneId)
        )
      ];
    },
    success: () => {
      return [
        put(deletePhoneNumberSuccess(action.clientId, action.phoneId)),
        put(deleteCachedForm(action.formId, true)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.PHONE_NUMBER.DELETE))
      ];
    },
    error: () => [
      put(deletePhoneNumberFailure()),
      put(isFetchingForm(action.formId, false)),
      put(messengerActions.showError(ERROR_MESSAGES.PHONE_NUMBER.DELETE))
    ]
  });
};

export const addPhoneNumber = action => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.CLIENT_PHONE_NUMBERS(action.clientId),
        action.data
      );
    },
    success: data => {
      return [
        put(addPhoneNumberSuccess(action.clientId, data)),
        put(deleteCachedForm(action.formId, true)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.PHONE_NUMBER.ADD))
      ];
    },
    error: () => [
      put(addPhoneNumberFailure()),
      put(isFetchingForm(action.formId, false)),
      put(messengerActions.showError(ERROR_MESSAGES.PHONE_NUMBER.ADD))
    ]
  });
};

/**
 * UPDATE CLIENT RESIDENCE
 */

export const updateClientResidence = action => {
  const { eventHandlers = null } = action;
  return http({
    request: (httpSrv, API, sendData) => {
      return call(
        httpSrv.put,
        API.CLIENT_ADDRESS(action.clientId, action.residenceId),
        sendData
      );
    },
    success: data => {
      const successActions = [
        put(
          updateClientResidenceSuccess(
            action.residenceId,
            action.clientId,
            data
          )
        )
      ];

      if (eventHandlers && eventHandlers.onSuccess) {
        successActions.push(call(eventHandlers.onSuccess));
        return all(successActions);
      }

      return all(
        successActions.concat([
          put(deleteCachedForm(action.formId, true)),
          put(
            messengerActions.showSuccess(
              SUCCESS_MESSAGES.CLIENT_RESIDENCE.UPDATE
            )
          )
        ])
      );
    },
    error: () => {
      const failureActions = [put(updateClientResidenceFailure())];

      if (eventHandlers && eventHandlers.onFailure) {
        failureActions.push(call(eventHandlers.onFailure));
        return all(failureActions);
      }

      return all(
        failureActions.concat([
          put(isFetchingForm(action.formId, false)),
          put(
            messengerActions.showError(ERROR_MESSAGES.CLIENT_RESIDENCE.UPDATE)
          )
        ])
      );
    },
    data: action.data,
    transformSendData: transformAddressToSend
  });
};

export const deleteClientResidence = action => {
  return http({
    request: (httpSrv, API) => {
      return [
        call(
          httpSrv.delete,
          API.CLIENT_ADDRESS(action.clientId, action.residenceId)
        )
      ];
    },
    success: () => {
      return [
        put(deleteClientResidenceSuccess(action.clientId, action.residenceId)),
        put(deleteCachedForm(action.formId, true)),
        put(
          messengerActions.showSuccess(SUCCESS_MESSAGES.CLIENT_RESIDENCE.DELETE)
        )
      ];
    },
    error: () => [
      put(deleteClientResidenceFailure()),
      put(isFetchingForm(action.formId, false)),
      put(messengerActions.showError(ERROR_MESSAGES.CLIENT_RESIDENCE.DELETE))
    ]
  });
};

export const addClientResidence = action => {
  return http({
    request: (httpSrv, API, sendData) => {
      return call(
        httpSrv.post,
        API.CLIENT_ADDRESSES(action.clientId),
        sendData
      );
    },
    success: data => {
      return [
        put(addClientResidenceSuccess(action.clientId, data)),
        put(deleteCachedForm(action.formId, true)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.CLIENT_RESIDENCE.ADD))
      ];
    },
    error: () => [
      put(addClientResidenceFailure()),
      put(isFetchingForm(action.formId, false)),
      put(messengerActions.showError(ERROR_MESSAGES.CLIENT_RESIDENCE.ADD))
    ],
    data: action.data,
    transformSendData: transformAddressToSend
  });
};

export const updateStatus = action =>
  http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.put,
        API.APPLICATION_STATUS(action.applicationId),
        action.data
      );
    },
    success: data => {
      const successActions = [
        call(fetchLoanApplicationDetails, {
          applicationId: action.applicationId
        }),
        put(updateStatusSuccess(data)),
        put(deleteCachedForm(action.formId)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.STATUS.UPDATE))
      ];
      if (action.onSuccesAction) {
        successActions.push(put(action.onSuccesAction));
      }
      return all(successActions);
    },
    error: error => put(addSectionError(APP_SECTIONS.LOAN_APPS_STATUS, error))
  });

export const addLoanApplicationNotes = action => {
  return http({
    request: (httpSrv, API) => {
      return call(
        httpSrv.post,
        API.APPLICATION_NOTES(action.applicationId),
        action.data
      );
    },
    success: data => {
      return [
        put(addLoanApplicationNotesSuccess(data)),
        put(deleteCachedForm(action.formId)),
        put(
          messengerActions.showSuccess(
            SUCCESS_MESSAGES.LOAN_APPLICATION_NOTES.ADD
          )
        )
      ];
    },
    error: error => [
      put(isFetchingForm(action.formId, false)),
      put(put(addFormSectionError(action.formId, error)))
    ]
  });
};

function* watchLoanApplicationDetailsAsync() {
  yield takeLatest(LOAN_APPLICATION_DETAILS_FETCH, fetchLoanApplicationDetails);
  yield takeLatest(LOAN_APPLICATION_NOTES_FETCH, fetchLoanApplicationNotes);
  yield takeLatest(LOAN_APPLICATION_EMAILS_FETCH, fetchLoanApplicationEmails);
  yield takeLatest(
    LOAN_APPLICATION_DEBT_SERVICE_RATIO_FETCH,
    fetchLoanApplicationsAffordability
  );
  yield takeLatest(LOAN_APPLICATION_CLIENT_EXPENSE_ADD, createClientExpense);
  yield takeLatest(LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE, updateClientExpense);
  yield takeLatest(LOAN_APPLICATION_CLIENT_EXPENSE_DELETE, deleteClientExpense);
  yield takeLatest(LOAN_APPLICATION_BANK_ACCOUNT_ADD, addClientBankAccount);
  yield takeLatest(
    LOAN_APPLICATION_BANK_ACCOUNT_DELETE,
    removeClientBankAccount
  );
  yield takeLatest(
    LOAN_APPLICATION_BANK_ACCOUNT_UPDATE,
    updateClientBankAccount
  );
  yield takeLatest(LOAN_APPLICATION_CLIENT_ADD, addClient);
  yield takeLatest(LOAN_APPLICATION_CLIENT_DELETE, deleteClient);
  yield takeLatest(
    LOAN_APPLICATION_CLIENT_APPLICATION_LINK,
    linkClientApplication
  );
  yield takeLatest(
    LOAN_APPLICATION_CLIENT_LIABILITY_ADD,
    createClientLiability
  );
  yield takeLatest(
    LOAN_APPLICATION_CLIENT_LIABILITY_DELETE,
    removeClientLiability
  );
  yield takeLatest(
    LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE,
    updateClientLiability
  );
  yield takeLatest(LOAN_APPLICATION_DEAL_LEAD_UPDATE, updateDealLead);
  yield takeLatest(
    LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH,
    fetchDealLeadOptions
  );
  yield takeLatest(
    LOAN_APPLICATION_REFERRAL_SOURCE_UPDATE,
    updateReferralSource
  );
  yield takeLatest(
    LOAN_APPLICATION_PERSONAL_DETAILS_UPDATE_REQUEST,
    updatePersonalDetails
  );
  yield takeEvery(
    LOAN_APPLICATION_GET_CREDIT_REPORT_SUMMARY,
    fetchCreditReportSummary
  );
  yield takeLatest(LOAN_APPLICATION_CLIENT_EMAIL_ADD, addClientEmail);
  yield takeLatest(LOAN_APPLICATION_CLIENT_EMAIL_UPDATE, updateClientEmail);
  yield takeLatest(LOAN_APPLICATION_CLIENT_EMAIL_DELETE, deleteClientEmail);
  yield takeLatest(LOAN_APPLICATION_PHONE_NUMBER_ADD, addPhoneNumber);
  yield takeLatest(
    LOAN_APPLICATION_PHONE_NUMBER_UPDATE_REQUEST,
    updatePhoneNumber
  );
  yield takeLatest(LOAN_APPLICATION_PHONE_NUMBER_DELETE, deletePhoneNumber);
  yield takeLatest(LOAN_APPLICATION_CLIENT_RESIDENCE_ADD, addClientResidence);
  yield takeLatest(
    LOAN_APPLICATION_CLIENT_RESIDENCE_UPDATE_REQUEST,
    updateClientResidence
  );
  yield takeLatest(
    LOAN_APPLICATION_CLIENT_RESIDENCE_DELETE,
    deleteClientResidence
  );
  yield takeLatest(
    statusActionTypes.LOAN_APPLICATION_STATUS_UPDATE_REQUEST,
    updateStatus
  );
  yield takeLatest(LOAN_APPLICATION_NOTES_ADD, addLoanApplicationNotes);
}

export default [fork(watchLoanApplicationDetailsAsync)];
