import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles/baseline";

function TypographyRoot({ classes, children }) {
  return <div className={classes.root}>{children}</div>;
}

TypographyRoot.propTypes = {
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(TypographyRoot);
