import { REACT_APP_ENV } from "./core/constants/env";

/**
 * loading of messages must be co-located with the app
 * since the translation is on a per-project basis
 * @param language
 * @returns {Promise<*>|*}
 */
export async function loadCatalog(language) {
  if (process.env.NODE_ENV === "development") {
    return await import(/* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
    `@lingui/loader!../locale/${language}/messages.json`);
  } else {
    // load compiled messages.js
    return await import(`../locale/${language}/messages.js`);
  }
}

export const debugMode =
  REACT_APP_ENV !== "production" && REACT_APP_ENV !== "uat";
