import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import { withI18n } from "@lingui/react";
import { withURLQuery } from "@cauldron/ui";
import { generateTestIds } from "@cauldron/core/src/utils";

// LOCAL DEPENDENCIES
import TableRow, { getTrProps } from "./components/table.row";
import ColumnSettingsReset from "./components/table.settings.reset";
import styles from "./styles/search.table.styles";
import { AlchemyTable } from "../../ui.library/AlchemyTable";
import { SearchField, NestedMenu } from "../../ui.library";
import { getHiddenColumns, showPagination } from "./search.table.utils";
import { useUrlParams, useNoData } from "./search.table.hooks";

/**
 * SEARCH TABLE
 * Common Search Table Component.
 * @author Ryan Rivera
 */
function SearchTable({
  i18n,
  cacheURLParams,
  classes,
  data,
  sortBy,
  defaultPageSize,
  defaultNoDataText,
  pageSizeOptions,
  searchHighlight,
  isSearching,
  placeholder,
  columns,
  dataTestId,
  dataTestBuilder = generateTestIds,
  resetText,
  hasError,
  searchCleared,
  error,
  searchOnClear,
  executeSearch,
  clearSearch,
  initialUrlParams,
  urlParams,
  updateSettings,
  initialColumns
}) {
  // Hook for URL Params
  const { cachedURLParams, updateCachedURLParams } = useUrlParams({
    urlParams,
    cacheURLParams
  });

  const { search, page, pageSize } = cachedURLParams;

  // Hook for no data scenario
  const { noDataText, noDataType } = useNoData({
    data,
    isSearching,
    hasError,
    searchCleared,
    error,
    noDataMessages: {
      DEFAULT: defaultNoDataText,
      NO_RESULTS: i18n.t`Search found no results.`,
      PLACEHOLDER: i18n.t`Search by name, co-signer, email, phone & loan#`
    },
    defaultNoDataType: "info"
  });

  useEffect(() => {
    // If there is a search criteria in the url upon page enter, make a request to search
    if (search && search !== "") {
      executeSearch({ query: search });
      updateCachedURLParams({ ...cachedURLParams });
    } else if (searchOnClear) {
      clearSearch({ query: urlParams.search });
      updateCachedURLParams({ ...cachedURLParams });
    }
  }, []); // eslint-disable-line

  useEffect(
    () => {
      let maxPage = 0;
      const dataLength = data.length;

      if (dataLength && pageSize) {
        maxPage = Math.ceil(dataLength / pageSize) - 1;
      }

      // Given page size is over the max page
      // set the page to the max page
      if (maxPage > 0 && page > maxPage) {
        updateCachedURLParams({ page: maxPage });
      }

      // always scroll to top of page when data or page changes
      if (document && document.getElementsByClassName("rt-tbody")[0]) {
        document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
      }
    },
    [page, pageSize, data.length] // eslint-disable-line
  );

  function getTableSettingsConfig(columns) {
    return {
      id: "searchTableSettings",
      label: i18n.t`Settings`,
      menuItems: [
        {
          id: "columnVisibility",
          label: i18n.t`Show/hide columns`,
          type: "MULTI_SELECT",
          menuItems: columns.map(col => ({
            id: col.id,
            label: col.label,
            disabled: !!col.visibilityToggleDisabled,
            selected: typeof col.show !== "undefined" ? col.show : true
          }))
        }
      ]
    };
  }

  function applyColumnVisibility(event, el) {
    const isChecked = event.currentTarget.checked;
    const itemId = el.itemId;

    const columnsChanges = columns;

    const index = columnsChanges.findIndex(col => col.id === itemId);
    columnsChanges[index].show = isChecked;

    const hiddenColumns = getHiddenColumns(columnsChanges);
    updateSettings({ hiddenColumns });
  }

  function applyTableSettings(event, el) {
    const containerId = el.containerId;
    const tableSettings = {
      columnVisibility: applyColumnVisibility
    };

    if (containerId && tableSettings[containerId]) {
      tableSettings[containerId](event, el);
    }
  }

  function resetTableSettings() {
    const hiddenColumns = getHiddenColumns(initialColumns);
    updateSettings({ hiddenColumns });
  }

  function handleOnSearch(search) {
    !search
      ? clearSearch({ query: initialUrlParams.search })
      : executeSearch({ query: search });

    // urlParams.page ensure page 0 when searching
    updateCachedURLParams({ search, page: initialUrlParams.page });
  }

  function handleOnFilteredChange(filtered) {
    updateCachedURLParams({ filtered });
  }

  function handleOnPageChange(pageIndex) {
    updateCachedURLParams({ page: pageIndex });
  }

  function handleOnPageSizeChange(pageSize) {
    updateCachedURLParams({ pageSize, page: initialUrlParams.page });
  }

  function handleOnSortedChange() {
    updateCachedURLParams({ page: initialUrlParams.page });
  }

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar} data-test={dataTestId.toolbar}>
        <div
          className={classNames("table-search", classes.tableSearch)}
          data-test={dataTestId.search.root}
        >
          <SearchField
            initialTerm={search}
            onSearch={handleOnSearch}
            autoFocus
            placeholder={placeholder}
            dataTestId={dataTestId.search}
            dataTestBuilder={dataTestBuilder}
          />
        </div>
        <div className={classes.spacer} />
        <div data-test={dataTestId.settings.root}>
          <NestedMenu
            triggerIconName="settings"
            menu={getTableSettingsConfig(columns)}
            onChange={applyTableSettings}
            placement="bottom-end"
            dataTestId={dataTestId.settings}
            dataTestBuilder={dataTestBuilder}
            actions={
              <ColumnSettingsReset
                dataTestId={dataTestId}
                resetText={resetText}
                onClick={resetTableSettings}
              />
            }
          />
        </div>
      </Toolbar>
      <AlchemyTable
        data={data}
        columns={columns}
        loading={isSearching}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        showPagination={showPagination(data.length, defaultPageSize)}
        defaultSorted={sortBy}
        noDataText={noDataText}
        page={page}
        getTrProps={getTrProps}
        onSortedChange={handleOnSortedChange}
        onPageChange={handleOnPageChange}
        onPageSizeChange={handleOnPageSizeChange}
        onFilteredChange={handleOnFilteredChange}
        TrComponent={TableRow}
        noDataType={noDataType}
        searchHighlight={searchHighlight}
        searchedTerm={search}
        sidePadding
        permaScroll
        dataTestId="search-table"
        dataTestBuilder={dataTestBuilder}
      />
    </Paper>
  );
}

SearchTable.defaultProps = {
  data: [],
  updateSettings: null
};

SearchTable.propTypes = {
  classes: PropTypes.object.isRequired,
  updateSettings: PropTypes.func,
  data: PropTypes.array,
  initialColumns: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  sortBy: PropTypes.array,
  defaultPageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  searchHighlight: PropTypes.bool,
  isSearching: PropTypes.bool.isRequired,
  executeSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  searchCleared: PropTypes.bool.isRequired,
  searchOnClear: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string
  }),
  defaultNoDataText: PropTypes.string,
  resetText: PropTypes.string.isRequired,
  cacheURLParams: PropTypes.func.isRequired,
  initialUrlParams: PropTypes.object.isRequired,
  urlParams: PropTypes.object.isRequired,
  dataTestId: PropTypes.object,
  dataTestBuilder: PropTypes.func
};

export function transformIncomingUrlQuery(query) {
  const page =
    !isNaN(query.page) && query.page > 0 ? parseInt(query.page, 10) - 1 : 0;
  const pageSize = !isNaN(query.pageSize)
    ? parseInt(query.pageSize, 10)
    : query.pageSize;
  return { ...query, page, pageSize };
}

export function transformOutgoingUrlQuery(query) {
  const page =
    !isNaN(query.page) && query.page ? parseInt(query.page, 10) + 1 : 0;
  return { ...query, page };
}

const enhance = compose(
  withI18n(),
  withStyles(styles),

  // initialized with 'initialUrlParams' are the only ones that will
  // be cached and returned
  withURLQuery({
    initialUrlParams: { search: "", page: 0, pageSize: 50 },
    showDefaultUrlParams: false,
    transformIncomingUrlQuery,
    transformOutgoingUrlQuery
  })
);

export default enhance(SearchTable);
