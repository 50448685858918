import { put, takeLatest, fork, call, all } from "redux-saga/effects";
import { http } from "../../../../services/http/http.service";
import * as actionTypes from "../action.types/client.content.action.types";
import {
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE
} from "../action.types/forms.action.types";
import {
  updateClientPhoneSuccessful,
  updateClientPhoneFailure,
  addClientPhoneSuccessful,
  addClientPhoneFailure,
  addAuthUserSuccessful,
  addAuthUserFailure,
  updateAuthUserSuccessful,
  updateAuthUserFailure,
  updateClientTitleSuccess,
  cancelEditClientTitle,
  updateClientTitleFailure,
  updateClientMaritalSuccess,
  cancelEditClientMarital,
  updateClientMaritalFailure
} from "../actions/form.actions";
import * as actions from "../actions/client.content.actions";
import * as messengerActions from "../../../../services/messenger/messenger.actions";
import { prepareNewAuthUserData } from "../../../../models/authUser/new.auth.user.model";
import { SUCCESS_MESSAGES, ERROR_MESSAGES } from "../../../../core/constants";
import { FUNDED_LOAN_ERROR_MESSAGES } from "../../../constants/error.messages";
import { FUNDED_LOAN_SUCCESS_MESSAGES } from "../../../constants/success.messages";
import { prepareClientPhoneModelData } from "../../../../models/fundedLoanClients/client.phone.model";
import { mapStatusCodeToDisplayMessage } from "../../../funded.loans/funded.loans.utils";
import {
  authUserUpdateInfoReceived,
  authUserUpdateInfoSent,
  authUserAddReceived,
  authUserAddSent
} from "../../../../models/funded/client/auth.user";
import {
  clientPhoneUpdateReceived,
  clientPhoneUpdateSent,
  clientPhoneAddReceived,
  clientPhoneAddSent
} from "../../../../models/funded/client/phone";
import {
  clientTitleUpdateReceived
  //clientTitleUpdateSent
} from "../../../../models/funded/client/title";
import {
  clientMaritalUpdateReceived,
  clientMaritalUpdateSent
} from "../../../../models/funded/client/marital";
import {
  clientNameUpdateReceived,
  clientNameUpdateSent
} from "../../../../models/funded/client/name";

import {
  isFetchingForm,
  deleteCachedForm,
  addFormSectionError
} from "../../../../services/forms/forms.actions";

/**
 * UPDATE AUTH USER INFO
 * @param {object} action
 */
export const updateAuthUserInfo = action => {
  const authUser = action.authUser;
  const loanId = action.loanId;
  const clientId = action.clientId;

  return http({
    request: (httpSrv, API, sendData) =>
      call(
        httpSrv.put,
        API.FUNDED_LOAN_CLIENT_AUTH_USER(loanId, clientId, authUser.authUserId),
        sendData
      ),
    success: data => [
      put(updateAuthUserSuccessful(loanId, clientId, data)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.AUTH_USER.UPDATE))
    ],
    error: error => [
      put(updateAuthUserFailure(loanId, clientId, authUser.authUserId, error)),
      put(messengerActions.showError(ERROR_MESSAGES.AUTH_USER.UPDATE))
    ],
    data: action.authUser,
    transformReceiveData: authUserUpdateInfoReceived,
    transformSendData: authUserUpdateInfoSent
  });
};

/**
 * DELETE AUTH USER INFO
 * @param {object} action
 */
export const deleteAuthUserInfo = action => {
  const loanId = action.loanId;
  const clientId = action.clientId;
  const authUserId = action.authUserId;

  return http({
    request: (httpSrv, API) =>
      call(
        httpSrv.delete,
        API.FUNDED_LOAN_CLIENT_AUTH_USER(loanId, clientId, authUserId)
      ),
    success: () => [
      put(actions.deleteAuthUserDataSuccess(clientId, authUserId)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.AUTH_USER.DELETE))
    ],
    error: error => [
      put(actions.deleteAuthUserDataFailure(error)),
      put(messengerActions.showError(ERROR_MESSAGES.AUTH_USER.DELETE))
    ]
  });
};

/**
 * ADD AUTH USER
 * @param {object} action
 */
export const addNewAuthUser = action => {
  return http({
    request: (httpSrv, API) =>
      call(
        httpSrv.post,
        API.FUNDED_LOAN_CLIENT_AUTH_USERS(action.loanId, action.clientId),
        prepareNewAuthUserData(action.authUser) // TODO: once the http sent issue get solved then this arg can be removed
      ),
    success: data => [
      put(addAuthUserSuccessful(action.clientId, action.index, data)),
      put(messengerActions.showSuccess(SUCCESS_MESSAGES.AUTH_USER.ADD))
    ],
    error: error => [
      put(addAuthUserFailure(action.clientId, action.index, error)),
      put(messengerActions.showError(ERROR_MESSAGES.AUTH_USER.ADD))
    ],
    data: action.authUser,
    transformReceiveData: authUserAddReceived,
    transformSendData: authUserAddSent
  });
};

/**
 * Update client email
 * @param action
 * @returns {IterableIterator<*>}
 */
export const updateClientEmail = action => {
  const {
    clientId,
    data: { email },
    formId
  } = action;

  return http({
    request: (httpSrv, API) =>
      call(httpSrv.put, API.FUNDED_CLIENT_EMAIL(clientId), { email }),
    success: data =>
      all([
        put(actions.updateClientEmailSuccess(data, clientId)),
        put(isFetchingForm(formId, false)),
        put(deleteCachedForm(formId, true)),
        put(messengerActions.showSuccess(SUCCESS_MESSAGES.CLIENT_EMAIL.UPDATE))
      ]),
    error: error =>
      all([
        put(actions.updateClientEmailFailure(error)),
        put(isFetchingForm(formId, false)),
        put(addFormSectionError(formId, error))
      ])
  });
};

/**
 * Update client address
 * @param action
 * @returns {IterableIterator<*>}
 */
export const updateClientAddress = action => {
  const { clientId, data, formId } = action;

  return http({
    request: (httpSrv, API) =>
      call(httpSrv.put, API.FUNDED_CLIENT_ADDRESS(clientId), { ...data }),
    success: data =>
      all([
        put(actions.updateClientAddressSuccess(data, clientId)),
        put(isFetchingForm(formId, false)),
        put(deleteCachedForm(formId, true)),
        put(
          messengerActions.showSuccess(SUCCESS_MESSAGES.CLIENT_RESIDENCE.UPDATE)
        )
      ]),
    error: error =>
      all([
        put(actions.updateClientAddressFailure(error)),
        put(isFetchingForm(formId, false)),
        put(addFormSectionError(formId, error))
      ])
  });
};

export const updateClientPhone = action => {
  const clientId = action.clientId;
  const phone = action.phone;

  return http({
    request: (httpSrv, API) =>
      call(
        httpSrv.put,
        API.FUNDED_CLIENT_PHONE(clientId, phone.phoneId),
        prepareClientPhoneModelData(phone)
      ),
    success: data => [
      put(updateClientPhoneSuccessful(clientId, phone.phoneId, data)),
      put(messengerActions.showSuccess("Success"))
    ],
    error: error => [
      put(updateClientPhoneFailure(clientId, phone.phoneId, error)),
      put(messengerActions.showError("Failure"))
    ],
    transformReceiveData: clientPhoneUpdateReceived,
    transformSendData: clientPhoneUpdateSent
  });
};

export const deleteClientPhone = action => {
  const clientId = action.clientId;
  const phoneId = action.phoneId;
  return http({
    request: (httpSrv, API) =>
      call(httpSrv.delete, API.FUNDED_CLIENT_PHONE(clientId, phoneId)),
    success: data => [
      put(actions.deleteClientPhoneSuccess(data, clientId, phoneId)),
      put(messengerActions.showSuccess("Success"))
    ],
    error: error => [
      put(actions.deleteClientPhoneFailure(error)),
      put(messengerActions.showError("Failure"))
    ]
  });
};

export const addClientPhone = action => {
  const clientId = action.clientId;
  const phone = action.phone;
  return http({
    request: (httpSrv, API) =>
      call(
        httpSrv.post,
        API.FUNDED_CLIENT_PHONES(clientId),
        prepareClientPhoneModelData(phone)
      ),
    success: data => [
      put(addClientPhoneSuccessful(clientId, data)),
      put(messengerActions.showSuccess("Success"))
    ],
    error: error => {
      const mappedDisplayMessage = mapStatusCodeToDisplayMessage(error.code);
      return [
        put(addClientPhoneFailure(clientId, error)),
        put(messengerActions.showError(mappedDisplayMessage))
      ];
    },
    transformReceiveData: clientPhoneAddReceived,
    transformSendData: clientPhoneAddSent
  });
};

/**
 * UPDATE CLIENT TITLE
 */
export const updateClientTitle = ({ clientId, values, field }) => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.put, API.FUNDED_CLIENT(clientId), values);
    },
    success: data => [
      put(updateClientTitleSuccess(clientId, data)),
      put(cancelEditClientTitle(clientId, field)),
      put(
        messengerActions.showSuccess(
          FUNDED_LOAN_SUCCESS_MESSAGES.PERSONAL_DETAILS.TITLE.UPDATE
        )
      )
    ],
    error: error => [
      put(updateClientTitleFailure(clientId, error)),
      put(
        messengerActions.showError(
          FUNDED_LOAN_ERROR_MESSAGES.PERSONAL_DETAILS.TITLE.UPDATE
        )
      )
    ],
    transformReceiveData: clientTitleUpdateReceived
    //transformSendData: clientTitleUpdateSent
  });
};

/**
 * UPDATE CLIENT MARITAL STATUS
 */
export const updateClientMarital = ({ clientId, values, field }) => {
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.put, API.FUNDED_CLIENT(clientId), values);
    },
    success: data => [
      put(updateClientMaritalSuccess(clientId, data)),
      put(cancelEditClientMarital(clientId, field)),
      put(
        messengerActions.showSuccess(
          FUNDED_LOAN_SUCCESS_MESSAGES.PERSONAL_DETAILS.MARITAL.UPDATE
        )
      )
    ],
    error: error => [
      put(updateClientMaritalFailure(clientId, error)),
      put(
        messengerActions.showError(
          FUNDED_LOAN_ERROR_MESSAGES.PERSONAL_DETAILS.MARITAL.UPDATE
        )
      )
    ],
    transformReceiveData: clientMaritalUpdateReceived,
    transformSendData: clientMaritalUpdateSent
  });
};

export const updateClientName = action => {
  return http({
    request: (httpSrv, API) =>
      call(httpSrv.put, API.FUNDED_CLIENT(action.clientId), action.data),
    success: data => [
      put(actions.updateClientNameSuccess(action.clientId, data)),
      put(
        messengerActions.showSuccess(SUCCESS_MESSAGES.FUNDED_CLIENT_NAME.UPDATE)
      )
    ],
    error: error => [
      put(actions.updateClientNameFailure(error)),
      put(messengerActions.showError(ERROR_MESSAGES.FUNDED_CLIENT_NAME.UPDATE))
    ],
    transformReceiveData: clientNameUpdateReceived,
    transformSendData: clientNameUpdateSent
  });
};

function* watchFundedLoanDetailsAsync() {
  yield takeLatest(
    FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE,
    updateAuthUserInfo
  );
  yield takeLatest(
    actionTypes.FUNDED_LOAN_DETAILS_AUTH_USER_DELETE,
    deleteAuthUserInfo
  );
  yield takeLatest(FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD, addNewAuthUser);
  yield takeLatest(
    actionTypes.FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE,
    updateClientEmail
  );
  yield takeLatest(
    actionTypes.FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE,
    updateClientAddress
  );
  yield takeLatest(FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE, updateClientPhone);
  yield takeLatest(
    actionTypes.FUNDED_LOAN_DETAILS_CLIENT_PHONE_DELETE,
    deleteClientPhone
  );
  yield takeLatest(FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD, addClientPhone);
  yield takeLatest(
    FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE,
    updateClientTitle
  );
  yield takeLatest(
    FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE,
    updateClientMarital
  );
  yield takeLatest(
    actionTypes.FUNDED_LOAN_DETAILS_CLIENT_UPDATE_NAME,
    updateClientName
  );
}

export default [fork(watchFundedLoanDetailsAsync)];
