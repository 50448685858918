export const getForm = (state, formId) => {
  return state.forms.find(form => form.id === formId);
};

// get is form cached by id
export const getIsFormCached = (state, formId) => {
  return state.forms.find(form => form.id === formId) ? true : false;
};

// TODO: remove this. For form grouping add a group key and use the getFormsByGroup selector
// see client app residence section for example
export const getByRegExIsFormCached = (state, pattern) => {
  const regEx = new RegExp(pattern);
  return state.forms.some(({ id }) => regEx.test(id));
};

export const getFormsByGroup = (state, group) => {
  return state.forms.filter(form => form.group === group);
};
