import PropTypes from "prop-types";

/**
 * CLIENT NAME MODEL
 */

// UPDATE CLIENT NAME  - DEFAULT PROPS
export const defaultProps = {
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  maritalStatus: "",
  address: {
    city: "",
    country: "",
    postalCode: "",
    province: "",
    streetAddress: "",
    unitNumber: ""
  },
  authUsers: [],
  bankAccount: {
    id: "",
    accountNumber: "",
    branchNumber: "",
    institutionName: "",
    institutionNumber: "",
    type: ""
  },
  dateOfBirth: "",
  deceased: false,
  emailAddress: "",
  gender: "",
  phones: [],
  primary: true,
  sin: ""
};

// UPDATE CLIENT NAME - SCHEMA
export const schema = {
  title: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  maritalStatus: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    province: PropTypes.string,
    streetAddress: PropTypes.string,
    unitNumber: PropTypes.string
  }),
  authUsers: PropTypes.array,
  bankAccount: PropTypes.shape({
    id: PropTypes.string,
    accountNumber: PropTypes.string,
    branchNumber: PropTypes.string,
    institutionName: PropTypes.string,
    institutionNumber: PropTypes.string,
    type: PropTypes.string
  }),
  dateOfBirth: PropTypes.string,
  deceased: PropTypes.bool,
  emailAddress: PropTypes.string,
  gender: PropTypes.string,
  phones: PropTypes.array,
  primary: PropTypes.bool,
  sin: PropTypes.string
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-client-name-update";
