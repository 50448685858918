"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var _PersonName = require("../PersonName");

var _PhoneNumber = require("../PhoneNumber");
/**
 * Model name
 * Alias to AgentPortalRegistrationRequest Model
 */


var MODEL_NAME = "AgentPortalClient";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  email: null,
  name: _PersonName.model.defaultProps,
  phone: _PhoneNumber.model.defaultProps
};
/**
 * Protects data from change in data type
 */

var schema = {
  email: _propTypes.default.string,
  name: _propTypes.default.shape(_PersonName.model.schema),
  phone: _propTypes.default.shape(_PhoneNumber.model.schema)
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
}; // Any "" values will be transformed to null


var transformSend = function transformSend(data) {
  return data;
};

var _default = {
  transformReceived: transformReceived,
  transformSend: transformSend
};
exports.default = _default;