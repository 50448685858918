/**
 * SELECT_FILTER_MENU STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = () => ({
  root: {
    maxHeight: "300px",
    overflowY: "auto"
  }
});

export default styles;
