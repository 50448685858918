"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactTextMask = _interopRequireDefault(require("react-text-mask"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}
/**
 * MaskedInput
 * @param props
 * @returns {*}
 * @constructor
 */


function MaskedInput(_ref) {
  var inputRef = _ref.inputRef,
      onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      mask = _ref.mask,
      other = _objectWithoutProperties(_ref, ["inputRef", "onChange", "onBlur", "mask"]);

  return _react.default.createElement(_reactTextMask.default, _extends({
    onChange: onChange,
    onBlur: onBlur,
    ref: inputRef(),
    mask: mask,
    placeholderChar: "\u2000",
    showMask: false,
    guide: false
  }, other));
}

MaskedInput.propTypes = {
  inputRef: _propTypes.default.func.isRequired,
  onChange: _propTypes.default.func.isRequired,
  onBlur: _propTypes.default.func.isRequired,
  mask: _propTypes.default.array.isRequired
};
var _default = MaskedInput;
exports.default = _default;