"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "PhoneNumber";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  id: null,
  countryCode: null,
  number: null,
  ext: null,
  type: null,
  primary: null
};
/**
 * Protects data from change in data type
 */

var schema = {
  id: _propTypes.default.string,
  countryCode: _propTypes.default.string,
  number: _propTypes.default.string,
  ext: _propTypes.default.string,
  type: _propTypes.default.string,
  primary: _propTypes.default.bool
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var transformSend = function transformSend(data) {
  return data;
};

var _default = {
  defaultProps: defaultProps,
  schema: schema,
  transformReceived: transformReceived,
  transformSend: transformSend
};
exports.default = _default;