import { put } from "redux-saga/effects";
import { addFormSectionError } from "../forms/forms.actions";
import { showError } from "../messenger/messenger.actions";

/**
 * @param error {object} saga reponse error object
 * @param primaryActions {array} primary action list
 * @param formId {string}
 * @param message {string} snackbar message
 */
export const generateFormErrorActions = (
  primaryActions,
  formId,
  error,
  message
) => {
  const errorActions = primaryActions;

  if (formId) {
    errorActions.push(put(addFormSectionError(formId, error)));
  } else {
    errorActions.push(put(showError(message)));
  }

  return errorActions;
};
