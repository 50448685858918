import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import find from "lodash/find";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import { CenterCenter } from "@cauldron/ui";
import { utils } from "@cauldron/core";
import { Spread } from "../../shared/Page";
import { CancelConfirmation } from "../../shared";
import { DIALOG_MESSAGES } from "../../core/constants";
import { DIALOG_ACTIONS } from "../../core/constants/dialog.messages";
import ClientsCreateFormDetails from "./loan.application.duplicate.form.details";
import { AppsForm } from "../../shared/AlchemyForm";
import validations from "./loan.application.duplicate.form.validations";
import { FIELD_NAMES } from "./loan.application.constants";
import {
  Section,
  Well,
  ScrollArea,
  AlchemyTypography,
  ButtonGroup,
  TextButton
} from "../../ui.library";

const { generateTestIds, objectsHaveDiff } = utils;

function LoanApplicationDuplicateForm({
  i18n,
  isFetching,
  errors,
  clientsById,
  onCancel,
  onSubmit
}) {
  const DATA_TEST_ROOT_ID = "create-from-existing-form";

  function getActions({ formProps }) {
    const { reset, cancel } = formProps;
    return (
      <ButtonGroup>
        <TextButton
          importance="CRITICAL"
          buttonWidth="md"
          name="reset-details"
          disabled={isFetching}
          onClick={reset}
          dataTestId={generateTestIds(
            DATA_TEST_ROOT_ID,
            "reset-details-button"
          )}
          dataTestBuilder={generateTestIds}
        >
          <Trans>Reset details</Trans>
        </TextButton>
        <TextButton
          buttonWidth="md"
          name="cancel"
          disabled={isFetching}
          onClick={cancel}
          dataTestId={generateTestIds(DATA_TEST_ROOT_ID, "cancel-button")}
          dataTestBuilder={generateTestIds}
        >
          <Trans>Cancel</Trans>
        </TextButton>
        <TextButton
          type="submit"
          importance="PRIMARY"
          buttonWidth="md"
          name="submit"
          disabled={isFetching}
          dataTestId={generateTestIds(
            DATA_TEST_ROOT_ID,
            "start-application-button"
          )}
          dataTestBuilder={generateTestIds}
        >
          <Trans>Start application</Trans>
        </TextButton>
      </ButtonGroup>
    );
  }

  const primaryApplicant =
    find(Object.values(clientsById), c => c.primary) || {};
  const primaryCoApplicant =
    find(Object.values(clientsById), c => !c.primary) || {};

  const initialValues = {
    [FIELD_NAMES.APPLICANT]: primaryApplicant.id || "",
    [FIELD_NAMES.APPLICANT_EMAIL]:
      primaryApplicant.emails && primaryApplicant.emails.length
        ? primaryApplicant.emails[0].email
        : "",
    [FIELD_NAMES.APPLICANT_NUMBER]:
      primaryApplicant.phoneNumbers && primaryApplicant.phoneNumbers.length
        ? primaryApplicant.phoneNumbers[0].number
        : "",
    [FIELD_NAMES.CO_APPLICANT]: primaryCoApplicant.id || "",
    [FIELD_NAMES.CO_APPLICANT_EMAIL]:
      primaryCoApplicant.emails && primaryCoApplicant.emails.length
        ? primaryCoApplicant.emails[0].email
        : "",
    [FIELD_NAMES.CO_APPLICANT_NUMBER]:
      primaryCoApplicant.phoneNumbers && primaryCoApplicant.phoneNumbers.length
        ? primaryCoApplicant.phoneNumbers[0].number
        : ""
  };

  const [clientFormData, setClientFormData] = useState(initialValues);

  const handleSetFormStateData = values => {
    setClientFormData({ ...values });
  };

  const isCancelConfirmationActive = objectsHaveDiff(
    initialValues,
    clientFormData
  );

  const errorStack = errors ? errors.map(m => i18n._(m.message)) : [];

  return (
    <ScrollArea stretch>
      <Spread
        header={
          <CancelConfirmation
            onCancel={onCancel}
            isActive={isCancelConfirmationActive}
            cancelDialogTitleText={i18n._(
              DIALOG_MESSAGES.APPLICATION_CREATION_CANCEL
            )}
            message={i18n._(DIALOG_MESSAGES.CANCEL.BODY)}
            agreeButtonText={i18n._(DIALOG_ACTIONS.OKAY)}
            disagreeButtonText={i18n._(DIALOG_ACTIONS.CANCEL)}
            dataTestId={generateTestIds(DATA_TEST_ROOT_ID)}
            dataTestBuilder={generateTestIds}
          />
        }
      >
        <CenterCenter vertical={false}>
          <Well width={800}>
            <Section>
              <AlchemyTypography
                type="h4"
                data-test={generateTestIds(DATA_TEST_ROOT_ID, "title")}
              >
                <Trans>Add new application from existing application</Trans>
              </AlchemyTypography>
            </Section>
            <Section>
              <AppsForm
                getActions={getActions}
                formDetailComponent={ClientsCreateFormDetails}
                initialValues={initialValues}
                formValues={clientFormData}
                validate={validations.bind(this, i18n)}
                onSubmit={onSubmit}
                setFormStateData={handleSetFormStateData}
                onCancel={onCancel}
                cancelDialogTitleText={i18n._(
                  DIALOG_MESSAGES.APPLICATION_CREATION_CANCEL
                )}
                dataTestId={generateTestIds(DATA_TEST_ROOT_ID)}
                dataTestBuilder={generateTestIds}
                errorStack={errorStack}
                customProps={{ clientsById }}
              />
            </Section>
          </Well>
        </CenterCenter>
      </Spread>
    </ScrollArea>
  );
}

const enhance = compose(withI18n());

export default enhance(LoanApplicationDuplicateForm);
