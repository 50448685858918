import {
  LOAN_APPLICATION_BANK_REQUEST_FETCH,
  LOAN_APPLICATION_BANK_REQUEST_FETCH_FAILURE,
  LOAN_APPLICATION_BANK_REQUEST_FETCH_SUCCESS,
  LOAN_APPLICATION_BANK_REQUEST_SEND,
  LOAN_APPLICATION_BANK_REQUEST_SEND_SUCCESS,
  LOAN_APPLICATION_BANK_REQUEST_RESEND,
  LOAN_APPLICATION_BANK_REQUEST_RESEND_SUCCESS
} from "./bank.requests.types";

// APPLICATION BANK REQUEST
export const fetchLoanApplicationBankRequest = applicationId => ({
  type: LOAN_APPLICATION_BANK_REQUEST_FETCH,
  applicationId
});

export const fetchLoanApplicationBankRequestSuccess = data => ({
  type: LOAN_APPLICATION_BANK_REQUEST_FETCH_SUCCESS,
  data
});

export const fetchLoanApplicationBankRequestFailure = error => ({
  type: LOAN_APPLICATION_BANK_REQUEST_FETCH_FAILURE,
  error
});

export const sendLoanApplicationBankRequest = (
  applicationId,
  clientId,
  data
) => ({
  type: LOAN_APPLICATION_BANK_REQUEST_SEND,
  applicationId,
  clientId,
  data
});

export const sendLoanApplicationBankRequestSuccess = data => ({
  type: LOAN_APPLICATION_BANK_REQUEST_SEND_SUCCESS,
  data
});

export const resendLoanApplicationBankRequest = data => ({
  type: LOAN_APPLICATION_BANK_REQUEST_RESEND,
  data
});

export const resendLoanApplicationBankRequestSuccess = data => ({
  type: LOAN_APPLICATION_BANK_REQUEST_RESEND_SUCCESS,
  data
});
