import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import styles from "./styles/layout.scroll.area.styles";

function ScrollArea({ classes, children, stretch }) {
  const classArray = [classes.root, stretch && classes.stretch];
  return <div className={classNames(classArray)}>{children}</div>;
}

ScrollArea.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ScrollArea);
