import React from "react";
import PropTypes from "prop-types";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";

// LOCAL STYLES
import Typography from "@material-ui/core/Typography";
import styles from "../styles/table.title.styles";

/**
 * TITLE COMPONENT
 * @author ryanr
 */
const TableTitle = ({ classes, children }) => (
  <Typography className={classes.title}>{children}</Typography>
);

TableTitle.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TableTitle);
