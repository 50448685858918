import { put, takeLatest, fork, call, all } from "redux-saga/effects";
import { http } from "../../../../services/http/http.service";
import {
  FUNDED_LOAN_DETAILS_LOAN_FETCH,
  FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH
} from "../action.types/funded.loan.details.action.types";
import {
  fetchDetailsPageDataSuccess,
  fetchDetailsPageDataFailure,
  fetchStaticCodesSuccess,
  fetchStaticCodesFailure
} from "../actions/funded.loan.details.actions";
import { updateParentList } from "../actions/notes.actions";
import * as messengerActions from "../../../../services/messenger/messenger.actions";
import {
  addSectionError,
  clearSectionError,
  APP_SECTIONS
} from "../../../../services/error.handler";
import {
  fundedLoanDetailsReceived,
  fundedLoanDetailsSent
} from "../../../../models/funded/loanDetails";
import { fundedLoanDetailsStaticCodesReceived } from "../../../../models/funded/staticCodes";

/**
 * GET DETAILS INFO DATA STREAM
 * Get details page data base on the passed id from the search page
 * @param {object} action
 */
export const fetchFundedLoanDetails = action => {
  return http({
    request: function*(httpSrv, API) {
      yield call(fetchStaticCodesList);
      return yield call(httpSrv.get, API.FUNDED_LOAN(action.id), action.id);
    },
    success: data => [
      put(fetchDetailsPageDataSuccess(data)),
      put(updateParentList(data)),
      put(clearSectionError(APP_SECTIONS.FUNDED_DETAILS))
    ],
    error: error =>
      all([
        put(addSectionError(APP_SECTIONS.FUNDED_DETAILS, error)),
        put(fetchDetailsPageDataFailure(error))
      ]),
    transformReceiveData: fundedLoanDetailsReceived,
    transformSendData: fundedLoanDetailsSent
  });
};

/**
 * FETCH STATIC CODES
 * @param {object} action
 */
export const fetchStaticCodesList = () => {
  return http({
    request: (httpSrv, API) => call(httpSrv.get, API.FUNDED_LOANS_STATIC_CODES),
    success: data => [
      put(fetchStaticCodesSuccess(data)),
      put(clearSectionError(APP_SECTIONS.FUNDED_DETAILS_STATIC_CODES))
    ],
    error: error =>
      all([
        put(fetchStaticCodesFailure(error)),
        put(addSectionError(APP_SECTIONS.FUNDED_DETAILS_STATIC_CODES, error)),
        put(messengerActions.showError("Failure in fetching codes!"))
      ]),
    transformReceiveData: fundedLoanDetailsStaticCodesReceived
  });
};

function* watchFundedLoanDetailsAsync() {
  yield takeLatest(FUNDED_LOAN_DETAILS_LOAN_FETCH, fetchFundedLoanDetails);
  yield takeLatest(
    FUNDED_LOAN_DETAILS_STATIC_CODES_FETCH,
    fetchStaticCodesList
  );
}

export default [fork(watchFundedLoanDetailsAsync)];
