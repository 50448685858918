import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

function SummaryCode({
  classes,
  title,
  code,
  message,
  hasBadStanding,
  dataTestId
}) {
  return (
    <div className={classes.fieldWrapper}>
      <Typography className={classes.fieldTitle}>{title}</Typography>
      <div className={classes.fieldSubTitleWrapper}>
        <Typography
          className={
            !hasBadStanding ? classes.fieldLabel : classes.fieldLabelNegative
          }
          data-test={dataTestId.CODE}
        >
          {code}
        </Typography>
        <Typography
          className={classes.fieldMessage}
          data-test={dataTestId.VALUE}
        >
          {message}
        </Typography>
      </div>
    </div>
  );
}

export default withStyles(styles)(SummaryCode);
