import _ from "lodash";

import { createSelector } from "reselect";

const stateSlice = "errors";

const sectionErrorSelector = (state, section) =>
  state.errors ? state.errors[section] : {};

export const getSectionError = createSelector(
  sectionErrorSelector,
  error => error
);

export const createErrorMessageSelector = actions => state => {
  return (
    _(actions)
      .map(action => {
        const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action);
        const [, requestName] = matches;
        return state[stateSlice][requestName];
      })
      .compact()
      .first() || null
  );
};
