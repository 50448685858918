import React, { useState } from "react";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button/index";
import { withStyles } from "@material-ui/core/styles/index";
import styles from "./styles/toggle.styles";

function ToggleButton({
  classes,
  onClick,
  text,
  disabled,
  disableAll,
  active,
  dataTestId
}) {
  return (
    <Button
      disabled={disableAll}
      data-test={dataTestId}
      className={classNames([
        classes.toggleStyle,
        classes.toggleButton,
        disableAll && "disabled",
        disabled && "disabled",
        active && "active"
      ])}
      type="button"
      onClick={onClick}
      disableRipple
    >
      {text}
    </Button>
  );
}

function IconToggleButton({
  classes,
  className,
  onClick,
  text,
  icon,
  active,
  dataTestId
}) {
  return (
    <Button
      data-test={dataTestId}
      className={classNames([
        classes.toggleStyle,
        className,
        active && "active"
      ])}
      classes={{
        label: active
          ? classes.activeIconToggleButton
          : classes.iconToggleButton
      }}
      type="button"
      onClick={onClick}
      disableRipple
    >
      <div className={classes.iconWrapper}>{icon}</div>
      <Typography
        className={
          active ? classes.typographyActiveWrapper : classes.typographyWrapper
        }
      >
        {text}
      </Typography>
    </Button>
  );
}

function getInitialValue(value) {
  if (Array.isArray(value)) {
    return [...value];
  }
  return !isNil(value) ? [value] : [];
}

function ToggleGroup({
  buttons,
  classes,
  className,
  onClickToggle,
  value,
  multi,
  style,
  condensed,
  disableAll,
  dataTestId,
  dataTestBuilder
}) {
  const [selected, setSelected] = useState(getInitialValue(value));

  function handleToggleClick(toggledButton) {
    let newValue = [...selected];

    if (multi) {
      if (newValue.indexOf(toggledButton) > -1) {
        newValue.splice(newValue.indexOf(toggledButton), 1);
      } else {
        newValue.push(toggledButton);
      }
      setSelected(newValue);
      onClickToggle(newValue);
    } else {
      newValue = [toggledButton];
      setSelected(newValue);
      const normalizedValue = newValue[newValue.length - 1];
      onClickToggle(
        typeof normalizedValue === "undefined" ? "" : normalizedValue
      );
    }
  }

  return (
    <div
      style={style}
      data-test={dataTestBuilder && dataTestBuilder(dataTestId, "btn-group")}
      className={classNames([
        classes.toggleButtonGroup,
        className,
        condensed && "condensed"
      ])}
    >
      {buttons.map((button, idx) => {
        return button.icon ? (
          <IconToggleButton
            className={classes.iconToggleStyle}
            dataTestId={
              dataTestBuilder && dataTestBuilder(dataTestId, "btn", idx)
            }
            key={button.key || button.text}
            classes={classes}
            text={button.text}
            icon={button.icon}
            iconClass={button.iconClass}
            onClick={() => handleToggleClick(idx)}
            active={selected.indexOf(idx) > -1}
          />
        ) : (
          <ToggleButton
            dataTestId={
              dataTestBuilder && dataTestBuilder(dataTestId, "btn", idx)
            }
            key={button.key || button.text}
            classes={classes}
            text={button.text}
            onClick={() => handleToggleClick(idx)}
            active={selected.indexOf(idx) > -1}
            disableAll={disableAll}
            disabled={selected.length > 0 && selected.indexOf(idx) === -1}
          />
        );
      })}
    </div>
  );
}

ToggleButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dataTestId: PropTypes.string
};

ToggleGroup.defaultProps = {
  value: null,
  condensed: false
};

ToggleGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  onClickToggle: PropTypes.func,
  condensed: PropTypes.bool,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};
export default withStyles(styles)(ToggleGroup);
