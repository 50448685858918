"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _mixpanelAction = require("./mixpanel.action.types");
/**
 * Mixpanel Middleware
 * https://developer.mixpanel.com/docs/javascript
 * @returns {function(*): function(*=): *}
 *
 * @example
 * if (process.env.REACT_APP_ENABLE_MIXPANEL === "true") {
 *   middleware.push(mixpanel.middleware);
 * }
 */


function middleware() {
  var mixpanel = window.mixpanel || null;
  return function (next) {
    return function (action) {
      if (mixpanel) {
        switch (action.type) {
          case _mixpanelAction.TRACK:
            mixpanel.track(action.event, (0, _objectSpread2.default)({}, action.payload));
            break;

          case _mixpanelAction.REGISTER:
            mixpanel.register((0, _objectSpread2.default)({}, action.payload));
            break;

          case _mixpanelAction.IDENTIFY:
            mixpanel.identify(action.id);
            break;

          default:
            break;
        }
      } // Call the next dispatch method in the middleware chain.
      // This will likely be the action itself, unless
      // a middleware further in chain changed it.


      return next(action);
    };
  };
}

var _default = middleware;
exports.default = _default;