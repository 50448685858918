import PropTypes from "prop-types";

/**
 * FUNDED LOAN DETAILS MODEL
 * pay-off
 */

// FUNDED LOAN DETAILS  - DEFAULT PROPS
export const defaultProps = {
  payoffAmount: 0
};

/**
 * FUNDED LOAN DETAILS - SCHEMA
 * pay-off
 */
export const schema = {
  payoffAmount: PropTypes.number
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Funded-loan-details-payy-off-amount";
