const styles = theme => ({
  card: {
    height: 344,
    width: 325,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  cardContentRoot: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "default"
    }
  },
  contentIcon: {
    fontSize: "9em",
    cursor: "pointer",
    color: theme.palette.lightGrey[900]
  },
  iconWrapper: {
    borderRadius: "100%",
    backgroundColor: theme.palette.lightGrey[50],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 240,
    width: 240
  },
  tileFooter: {
    ...theme.typography.h5Dark,
    position: "absolute",
    bottom: "20px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  filePickerWrapper: {
    padding: "165px 43px",
    position: "absolute",
    opacity: 0,
    "&:hover": {
      cursor: "pointer"
    }
  }
});

export default styles;
