/**
 * Funded Comments Transform [SENT]
 * @param {*} rawData
 */
export const transformSent = sendData => {
  const { category, createdByEmail, createdByName, ...rest } = sendData;

  return {
    category: category === "-" ? null : category,
    createdBy: { name: createdByName, email: createdByEmail },
    ...rest
  };
};
