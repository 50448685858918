import {
  TRANCHE_MANAGEMENT_UPLOAD_FILE,
  TRANCHE_MANAGEMENT_UPLOAD_FILE_SUCCESS,
  TRANCHE_MANAGEMENT_UPLOAD_FILE_FAILURE,
  TRANCHE_MANAGEMENT_IMPORT,
  TRANCHE_MANAGEMENT_IMPORT_SUCCESS,
  TRANCHE_MANAGEMENT_IMPORT_FAILURE,
  TRANCHE_MANAGEMENT_CANCEL,
  TRANCHE_MANAGEMENT_UPDATE_ERROR_STATUS
} from "./tranche.action.types";

// UPLOAD TRANCHE FILE (CSV)
export function uploadTrancheFile(file) {
  return {
    type: TRANCHE_MANAGEMENT_UPLOAD_FILE,
    file
  };
}

/**
 * @param data
 * @returns {{type: string, data: *}}
 */
export function uploadTrancheFileSuccess(data) {
  return {
    type: TRANCHE_MANAGEMENT_UPLOAD_FILE_SUCCESS,
    data
  };
}

/**
 * @param error
 * @returns {{type: string, error: *}}
 */
export function uploadTrancheFileFailure(error) {
  return {
    type: TRANCHE_MANAGEMENT_UPLOAD_FILE_FAILURE,
    error
  };
}

// IMPORT TRANCHE TABLES (FROM CSV)
export function importTranches(selectedTranches) {
  return {
    type: TRANCHE_MANAGEMENT_IMPORT,
    selectedTranches
  };
}

/**
 * @param data
 * @returns {{type: string, data: *}}
 */
export function importTranchesSuccess(data) {
  return {
    type: TRANCHE_MANAGEMENT_IMPORT_SUCCESS,
    data
  };
}

/**
 * @param error
 * @returns {{type: string, error: *}}
 */
export function importTranchesFailure(error) {
  return {
    type: TRANCHE_MANAGEMENT_IMPORT_FAILURE,
    error
  };
}

export function cancelTranche() {
  return {
    type: TRANCHE_MANAGEMENT_CANCEL
  };
}

export function updateErrorStatus(status, mode) {
  return {
    type: TRANCHE_MANAGEMENT_UPDATE_ERROR_STATUS,
    status,
    mode
  };
}
