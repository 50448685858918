"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _model = _interopRequireDefault(require("./model"));

var transformReceived = function transformReceived(data) {
  return {
    results: data.results.map(function (d) {
      return _model.default.transformReceived(d);
    })
  };
};
/**
 * Documentation Collection
 */


var _default = {
  model: "Documentation",
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;