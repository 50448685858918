export const USER_LOGIN_REQUEST = "auth/USER_LOGIN_REQUEST";
export const USER_LOGIN_REQUEST_SUCCESS = "auth/USER_LOGIN_REQUEST_SUCCESS";
export const USER_LOGIN_REQUEST_FAILURE = "auth/USER_LOGIN_REQUEST_FAILURE";

export const USER_LOGIN_REFRESH = "auth/USER_LOGIN_REFRESH";
export const USER_LOGIN_REFRESH_SUCCESS = "auth/USER_LOGIN_REFRESH_SUCCESS";
export const USER_LOGIN_REFRESH_FAILURE = "auth/USER_LOGIN_REFRESH_FAILURE";

export const FORCED_USER_LOGOUT = "auth/FORCED_USER_LOGOUT";
export const USER_LOGOUT = "auth/USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "auth/USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "auth/USER_LOGOUT_FAILURE";

// PROFILE
export const PROFILE_REQUEST = "auth/PROFILE_REQUEST";
export const PROFILE_REQUEST_SUCCESS = "auth/PROFILE_REQUEST_SUCCESS";
export const PROFILE_REQUEST_FAILURE = "auth/PROFILE_REQUEST_FAILURE";
export const CONTEXT_PROFILE_REQUEST_SUCCESS =
  "auth/CONTEXT_PROFILE_REQUEST_SUCCESS";
export const CONTEXT_PROFILE_REQUEST_FAILURE =
  "auth/CONTEXT_PROFILE_REQUEST_FAILURE";

// PERMISSIONS
export const PERMISSIONS_REQUEST_SUCCESS = "auth/PERMISSIONS_REQUEST_SUCCESS";
export const PERMISSIONS_REQUEST_FAILURE = "auth/PERMISSIONS_REQUEST_FAILURE";
