import React from "react";
import { compose } from "recompose";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import {
  AlchemyPhoneField,
  AlchemySelect,
  AlchemyTextField,
  DetailExpander,
  Fieldset
} from "../../ui.library";
import { FIELD_NAMES } from "./loan.application.constants";

function applicantSelectOptions(c) {
  return {
    label: c.fullName && c.fullName !== "" ? c.fullName : c.id,
    value: c.id
  };
}

/**
 * LoanApplicationDuplicateFormDetails
 * @param i18n
 * @param customProps
 * @param getInputProps
 * @param getSelectProps
 * @param setFieldValue
 * @param values
 * @returns {*}
 * @constructor
 */
function LoanApplicationDuplicateFormDetails({
  i18n,
  customProps,
  getInputProps,
  getSelectProps,
  setFieldValue,
  values
}) {
  function blankCoApplicant() {
    setFieldValue(FIELD_NAMES.CO_APPLICANT, "");
    setFieldValue(FIELD_NAMES.CO_APPLICANT_EMAIL, "");
    setFieldValue(FIELD_NAMES.CO_APPLICANT_NUMBER, "");
  }

  function setCoApplicantFields(field, value, coApplicant) {
    const { emails, phoneNumbers } = coApplicant;
    setFieldValue(field, value);
    setFieldValue(
      FIELD_NAMES.CO_APPLICANT_EMAIL,
      emails && emails.length ? emails[0].email : ""
    );
    setFieldValue(
      FIELD_NAMES.CO_APPLICANT_NUMBER,
      phoneNumbers && phoneNumbers.length ? phoneNumbers[0].number : ""
    );
  }

  const { clientsById } = customProps;
  const clients = Object.values(clientsById);

  function getApplicantOptions(isPrimary) {
    let options = [];

    if (isPrimary && clients.length) {
      options = options.concat(clients.map(applicantSelectOptions));
    } else {
      options.push({ label: i18n._(t`None`), value: "" });
      if (clients.length) {
        options = options.concat(
          clients
            .filter(c => {
              return !values[FIELD_NAMES.CO_APPLICANT]
                ? c.id !== values[FIELD_NAMES.APPLICANT]
                : true;
            })
            .map(applicantSelectOptions)
        );
      }
    }

    return options;
  }

  function handleApplicantChange(field, value) {
    if (value === values[FIELD_NAMES.APPLICANT]) return;

    setFieldValue(field, value);

    const applicant = clientsById[value];

    if (clients.length > 1) blankCoApplicant();

    if (!applicant) {
      setFieldValue(FIELD_NAMES.APPLICANT_EMAIL, "");
      setFieldValue(FIELD_NAMES.APPLICANT_NUMBER, "");
    } else {
      const { emails, phoneNumbers } = applicant;
      setFieldValue(
        FIELD_NAMES.APPLICANT_EMAIL,
        emails && emails.length ? emails[0].email : ""
      );
      setFieldValue(
        FIELD_NAMES.APPLICANT_NUMBER,
        phoneNumbers && phoneNumbers.length ? phoneNumbers[0].number : ""
      );
    }
  }

  function handleCoApplicantChange(field, value) {
    if (value === values[FIELD_NAMES.CO_APPLICANT]) return;

    const coApplicant = clientsById[value];

    if (
      !coApplicant ||
      (value === values[FIELD_NAMES.APPLICANT] &&
        !values[FIELD_NAMES.CO_APPLICANT])
    ) {
      setFieldValue(field, "");
      setFieldValue(FIELD_NAMES.CO_APPLICANT_EMAIL, "");
      setFieldValue(FIELD_NAMES.CO_APPLICANT_NUMBER, "");
    }

    // Only switch if there is currently a cosigner
    // and the newly selected cosigner is equal to the applicant
    else if (
      values[FIELD_NAMES.CO_APPLICANT] &&
      value === values[FIELD_NAMES.APPLICANT]
    ) {
      const applicantSwitchData = {
        fullName: values[FIELD_NAMES.CO_APPLICANT],
        email: values[FIELD_NAMES.CO_APPLICANT_EMAIL],
        number: values[FIELD_NAMES.CO_APPLICANT_NUMBER]
      };

      setCoApplicantFields(field, value, coApplicant);

      // Switch with Applicant
      setFieldValue(FIELD_NAMES.APPLICANT, applicantSwitchData.fullName);
      setFieldValue(FIELD_NAMES.APPLICANT_EMAIL, applicantSwitchData.email);
      setFieldValue(FIELD_NAMES.APPLICANT_NUMBER, applicantSwitchData.number);
    } else if (value !== values[FIELD_NAMES.APPLICANT]) {
      setCoApplicantFields(field, value, coApplicant);
    }
  }

  return (
    <div>
      {clients &&
        clients.length &&
        clients
          .sort((x, y) => (x.primary === y.primary ? 0 : x.primary ? -1 : 1))
          .slice(0, 2)
          .map(client => {
            const isPrimary = client.primary;
            const hasEmail =
              client && client.emails && client.emails.length > 0;

            return (
              <DetailExpander
                key={client.id}
                title={
                  isPrimary
                    ? i18n._(t`Applicant details`)
                    : i18n._(t`Cosigner details`)
                }
                defaultExpanded
                hasTwoColumns
              >
                <Fieldset>
                  <AlchemySelect
                    {...getSelectProps({
                      name: isPrimary
                        ? FIELD_NAMES.APPLICANT
                        : FIELD_NAMES.CO_APPLICANT,
                      label: isPrimary
                        ? i18n._(t`Applicant`)
                        : i18n._(t`Cosigner`),
                      options: getApplicantOptions(isPrimary),
                      required: isPrimary,
                      onChange: isPrimary
                        ? handleApplicantChange
                        : handleCoApplicantChange
                    })}
                  />
                  <AlchemyTextField
                    {...getInputProps({
                      name: isPrimary
                        ? FIELD_NAMES.APPLICANT_EMAIL
                        : FIELD_NAMES.CO_APPLICANT_EMAIL,
                      label: i18n._(t`Email address`),
                      required: isPrimary
                        ? true
                        : !!values[FIELD_NAMES.CO_APPLICANT],
                      disabled:
                        hasEmail ||
                        (!hasEmail && isPrimary
                          ? !values[FIELD_NAMES.APPLICANT]
                          : !values[FIELD_NAMES.CO_APPLICANT])
                    })}
                  />
                  <AlchemyPhoneField
                    {...getInputProps({
                      name: isPrimary
                        ? FIELD_NAMES.APPLICANT_NUMBER
                        : FIELD_NAMES.CO_APPLICANT_NUMBER,
                      label: i18n._(t`Mobile phone`),
                      required: false,
                      disabled: true
                    })}
                  />
                </Fieldset>
              </DetailExpander>
            );
          })}
    </div>
  );
}

const enhance = compose(withI18n());

export default enhance(LoanApplicationDuplicateFormDetails);
