import { i18nMark } from "@lingui/react";

export const EXPAND_ALL = "expand_all";
export const COLLAPSE_ALL = "collapse_all";
export const DELETE = "DELETE";
export const EDIT_DETAILS = "edit_details";
export const DELETE_COSIGNER = "DELETE_COSIGNER";

export const EXPANDERS_MENU_LIST = [
  {
    name: EXPAND_ALL,
    label: i18nMark("Expand all"),
    value: true,
    importance: "DEFAULT"
  },
  {
    name: COLLAPSE_ALL,
    label: i18nMark("Collapse all"),
    value: false,
    importance: "DEFAULT"
  }
];

export const CARDS_MENU_LIST = [...EXPANDERS_MENU_LIST];

export const LOAN_APPS_DOCS_MENU_LIST = [
  {
    name: EDIT_DETAILS,
    label: i18nMark("Edit details"),
    importance: "DEFAULT"
  }
];

export const LOAN_APPS_CARDS_MENU_LIST = [
  ...EXPANDERS_MENU_LIST,
  {
    name: DELETE_COSIGNER,
    label: i18nMark("Delete cosigner"),
    importance: "CRITICAL"
  }
];

export const TOOLTIPS = {
  tabsHeader: {
    fullView: "Full View Mode",
    splitView: "Split View Mode",
    fullScreen: "Full Screen Mode",
    fullScreenClose: "Close Full Screen Mode",
    tileView: "Tile View Mode"
  },
  tabsContent: {
    timer: "Time",
    warning: "Warning",
    priority: "Priority"
  },
  notesExpander: {
    timer: "Timer",
    priority: "Priority"
  }
};

export const FORM_ACTION_TYPE = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete"
};

export const FORM_CATEGORY = {
  CLIENTS: "clients",
  NOTES: "notes"
};

export const FORM_GROUP_TYPE = {
  AUTH_USER: "authUser",
  PHONE: "phone",
  EMAIL: "email",
  ADDRESS: "address",
  NOTE: "note",
  QUICKNOTE: "quicknote",
  NAME: "name"
};

/**
 * FIELD GRID UI LIBRARY CONSTANT STYLES
 * @type {{NO_INDENT: string, LEFT_INDENT: string}}
 */
export const FIELD_GRID_STYLES = {
  NO_INDENT: "noIndent",
  LEFT_INDENT: "leftIndent"
};

const ACTIONS = {
  SUBMIT: i18nMark("Submit"),
  CONFIRM: i18nMark("Confirm")
};

export const LOAN_APPLICATION_FORM_SUBMIT_BUTTON = {
  INCOME: i18nMark("Submit income"),
  LIABILITY: i18nMark("Submit liability"),
  BANKING: i18nMark("Submit bank")
};

export const FUNDED_LOAN_FORM_SUBMIT_BUTTON = {
  PERSONAL_DETAILS: {
    TITLE: ACTIONS.SUBMIT,
    MARITAL_STATUS: ACTIONS.SUBMIT
  }
};

export const MISSING_TITLE = {
  BANK_DETAILS: i18nMark("Missing bank name"),
  LIABILITIES: i18nMark("Missing liability type"),
  INCOME: i18nMark("Missing employer name"),
  INCOME_ALT: i18nMark("Missing description")
};

export const IMPORTANCE = {
  TEMPORARY: "temporary",
  WARNING: "warning",
  CRITICAL: "critical"
};

export const ESCALATED_LOAN_STATUSES = ["IN ARREARS", "ESCALATION"];

export const DOCS_TAB_MODE = {
  MISSING_DOCS: "missingDocs",
  DOCS: "docs",
  DOCS_FORM: "docsForm",
  MISSING_DOCS_FORM: "missingDocsForm",
  MISSING_DOCS_TEMPLATE: "missingDocsTemplate"
};
