import React from "react";
import classNames from "classnames";
import { utils } from "@cauldron/core";
import {
  SEARCH_TABLE,
  SEARCH_TABLE_RESULT_ROW
} from "../../../pages/constants/data.test.ids";

const { generateTestIds } = utils;

export const getTrProps = (state, rowInfo) => {
  const data = rowInfo && rowInfo.original ? rowInfo.original : null;
  if (!data) return {};

  /**
   * genrating dynamic TestIds for each row
   * [Example] search-table--result-row--p2--i24 (page2, index24)
   */
  const rowTestId = generateTestIds(
    SEARCH_TABLE,
    SEARCH_TABLE_RESULT_ROW,
    `p${rowInfo.page + 1}`,
    `i${rowInfo.viewIndex}`
  );

  return {
    url: data && data.url ? data.url : null,
    target: data && data.target ? data.target : "_self",
    rowTestId
  };
};
export default ({ children, className, url, target, rowTestId, ...rest }) => {
  return url && url !== "" ? (
    <a
      href={url}
      target={target}
      className={classNames("rt-tr", className)}
      role="row"
      data-test={rowTestId}
      {...rest}
    >
      {children}
    </a>
  ) : (
    <div className={classNames("rt-tr", className)} role="row" {...rest}>
      {children}
    </div>
  );
};
