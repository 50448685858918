"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");
/**
 * InfoResponse
 * @type {string}
 */


var MODEL_NAME = "Info";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  commit: null,
  version: null
};
/**
 * Protects data from change in data type
 */

var schema = {
  commit: _propTypes.default.string,
  version: _propTypes.default.string
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var _default = {
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;