import { actionTypes } from "./agent.portal.applications.action.types";
import { actionTypes as referralSourceActionTypes } from "../referral.sources/referral.sources.action.types";

const initialState = {
  application: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPLICATION_CREATE_SUCCESS:
      return { ...action.payload };

    case actionTypes.APPLICATION_FETCH_SUCCESS:
      return { ...action.payload };

    case actionTypes.APPLICATION_UPDATE_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          ...action.payload
        }
      };

    case referralSourceActionTypes.CREATE_REFERRAL_AGENT_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          referrer: {
            ...state.application.referrer,
            agent: {
              ...action.data
            }
          }
        }
      };

    default:
      return state;
  }
};
