import { createSelector } from "reselect";

const fetchStatusSelector = state => state.pages.login.isFetching;
export const getFetchStatus = createSelector(
  fetchStatusSelector,
  status => status
);

const sessionSelector = state => (state && state.session ? state.session : {});

export const getSession = createSelector(
  sessionSelector,
  session => session
);
export const getProfile = createSelector(
  getSession,
  ({ profile }) => profile
);
export const getToken = createSelector(
  getSession,
  session => {
    return !session ? undefined : session.token;
  }
);
export const getExpiry = createSelector(
  getSession,
  session => {
    return !session ? undefined : session.expiresAt;
  }
);
export const getPermissionDefinitions = createSelector(
  getSession,
  ({ permissionDefinitions }) => permissionDefinitions
);
export const getIsProfileRequestComplete = createSelector(
  getSession,
  ({ isProfileRequestComplete }) => isProfileRequestComplete
);
