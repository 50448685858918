import React from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import { styles } from "../styles/header.menu.styles";

function generateMenuItemStyle(item, classes) {
  const MENU_ITEM_STYLE_MAP = {
    DEFAULT: {
      itemStyle: classes.menuItem,
      textStyle: classes.itemText
    },
    CRITICAL: {
      itemStyle: classes.criticalMenuItem,
      textStyle: item.disabled ? classes.menuItem : classes.criticalItemText
    }
  };
  if (!item) {
    return {
      ...MENU_ITEM_STYLE_MAP["DEFAULT"]
    };
  }
  return {
    ...MENU_ITEM_STYLE_MAP[item.importance]
  };
}

function handleMenuItemClick(item, onMenuItemClick, menuClose) {
  onMenuItemClick(item);
  menuClose();
}

/**
 * ExpanderMenu
 * @param classes
 * @param anchorEl
 * @param menuList
 * @param dataTestId
 * @param menuOpen
 * @param menuClose
 * @param onMenuItemClick
 * @returns {*}
 * @constructor
 */
function ExpanderMenu({
  classes,
  anchorEl,
  menuList,
  dataTestId,
  dataTestBuilder,
  menuOpen,
  menuClose,
  onMenuItemClick
}) {
  return (
    <Menu
      id="parent-header-menu"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={menuClose}
      data-test={dataTestBuilder && dataTestBuilder(dataTestId, "menu")}
      disableAutoFocusItem
      onClick={e => e.stopPropagation()} // stop event propagation for disabled options
    >
      {menuList &&
        menuList.map((item, index) => {
          const { itemStyle, textStyle } = generateMenuItemStyle(item, classes);
          return (
            <MenuItem
              key={index}
              data-test={
                dataTestBuilder &&
                dataTestBuilder(dataTestId, "menu-item", index)
              }
              onClick={e => {
                e.stopPropagation();
                handleMenuItemClick(item, onMenuItemClick, menuClose);
              }}
              className={itemStyle}
              disabled={item.disabled}
            >
              <Typography className={textStyle}>
                <span>{item.label}</span>
              </Typography>
            </MenuItem>
          );
        })}
    </Menu>
  );
}

ExpanderMenu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  menuClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  menuList: PropTypes.array.isRequired,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

ExpanderMenu.defaultProps = {
  dataTestId: []
};

export default withStyles(styles)(ExpanderMenu);
