"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "DebtServiceRatio";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  futureDebtService: null,
  futureGrossDebtService: null,
  grossAnnualIncome: null,
  grossDebtService: null,
  loanExpenses: null,
  otherLiabilityPayments: null,
  progressaLoanExpense: null,
  totalDebtService: null
};
/**
 * Protects data from change in data type
 */

var schema = {
  futureDebtService: _propTypes.default.number,
  futureGrossDebtService: _propTypes.default.number,
  grossAnnualIncome: _propTypes.default.number,
  grossDebtService: _propTypes.default.number,
  loanExpenses: _propTypes.default.number,
  otherLiabilityPayments: _propTypes.default.number,
  progressaLoanExpense: _propTypes.default.number,
  totalDebtService: _propTypes.default.number
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var _default = {
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;