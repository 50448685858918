/**
 * NESTED_MENU_MULTISELECT STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = theme => ({
  listRoot: {
    paddingBottom: 0,

    "& + *": {
      marginTop: "0 !important"
    }
  },
  listItemRoot: {
    ...theme.typography.p3Dark,
    paddingTop: "7px",
    paddingBottom: "7px",
    paddingLeft: "16px",
    color: theme.palette.grey[900],
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: theme.palette.secondary["light"]
    },

    "&:active": {
      backgroundColor: theme.palette.secondary["main"]
    },

    "&:focus": {
      outline: "none"
    }
  }
});

export default styles;
