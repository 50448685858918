// NOTE TEMPLATES
export const LOAN_REQUEST_NOTE_TEMPLATES_FETCH =
  "loan.request/NOTE_TEMPLATES_FETCH";
export const LOAN_REQUEST_NOTE_TEMPLATES_FETCH_SUCCESS =
  "loan.request/NOTE_TEMPLATES_FETCH_SUCCESS";
export const LOAN_REQUEST_NOTE_TEMPLATES_FETCH_FAILURE =
  "loan.request/NOTE_TEMPLATES_FETCH_FAILURE";

// LOAN REQUEST (SUBMIT)
export const LOAN_REQUEST_SUBMIT = "loan.request/LOAN_REQUEST_SUBMIT";
export const LOAN_REQUEST_SUBMIT_SUCCESS =
  "loan.request/LOAN_REQUEST_SUBMIT_SUCCESS";
export const LOAN_REQUEST_SUBMIT_FAILURE =
  "loan.request/LOAN_REQUEST_SUBMIT_FAILURE";
