import { fadeHEX } from "../../../utils/jss.utils";

export const styles = theme => ({
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main
    },
    height: "23px",
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  itemText: { color: theme.typography.p2Dark.color },
  criticalItemText: {
    color: "#FF2901"
  },
  criticalMenuItem: {
    paddingLeft: "16px",
    height: "23px",
    paddingTop: "6px",
    paddingBottom: "6px",
    "&:hover": {
      backgroundColor: fadeHEX(theme.palette.error.main, 0.45)
    },
    "&:active": {
      backgroundColor: fadeHEX(theme.palette.error.main, 0.7)
    },
    "&:hover p": {
      color: theme.typography.p2Dark.color
    },
    "&:active p": {
      color: theme.palette.secondary.contrastText
    }
  }
});
