export const styles = theme => ({
  cardRoot: {
    borderRadius: theme.shape.borderRadius,
    minHeight: "49px"
  },
  cardHeader: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "pointer"
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main
    },
    paddingLeft: "16px",
    paddingTop: "16px",
    paddingBottom: "8px"
  },
  cardContent: {
    padding: "0px",
    "&:last-child": {
      paddingBottom: "16px"
    }
  },
  headerSeparator: {
    color: theme.palette.lightGrey[300],
    backgroundColor: theme.palette.lightGrey[300],
    border: "none",
    margin: 0,
    position: "relative",
    marginLeft: "16px",
    marginRight: "16px",
    borderRadius: theme.shape.borderRadius
  },
  headerSeparator1: {
    height: 1,
    marginTop: "-1px"
  },
  headerSeparator4: {
    height: 4,
    marginTop: "-4px"
  },
  headerTitle: {
    color: theme.palette.grey[900],
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px"
  },
  subTitle: {
    color: theme.palette.grey[900],
    fontSize: "14px",
    lineHeight: "16px"
  },
  headerAction: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    marginTop: 0,
    paddingRight: "8px"
  },
  rootIcon: Object.assign({}, theme.icon.icon20, { fontSize: 20 }),
  hasPriorityIcon: {
    color: theme.palette.primary.light
  },
  expandIcon: theme.icon.icon20,
  moreHorizIcon: theme.icon.icon20,
  captionDark: theme.typography.p4Dark,
  captionDisable: theme.typography.p4Disabled,
  detailNoteDraftContentWrapper: {
    "&:hover": {
      cursor: "pointer"
    },
    height: "108px"
  },
  selectedNote: {
    "& > div": {
      backgroundColor: theme.palette.secondary["main"]
    }
  },
  unselectedNote: {
    "& > div": {
      backgroundColor: "transparent",
      "& > div:hover": {
        backgroundColor: theme.palette.secondary.light
      }
    }
  }
});
