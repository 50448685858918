import { persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  default as sessionReducer,
  whitelist as sessionWhitelist
} from "./auth/auth.reducer";
import { PERSISTED_DATA_DEBUG } from "../core/constants/env";

const basePersistConfig = {
  // default to local storage and can be overridden
  // in each persisted reducer
  storage,

  // defined version for migration
  version: 0,

  // prefix for persistend data
  keyPrefix: "pbp_",

  // adds error and key properties in the action
  debug: PERSISTED_DATA_DEBUG === "true"
};

export const persistedSessionReducer = persistReducer(
  {
    ...basePersistConfig,
    migrate: createMigrate(
      {
        // The key should match the version set in the basePersistConfig
        // ex. return the updated persisted state structure if version is 0
        // 0: state => state
      },
      { debug: PERSISTED_DATA_DEBUG === "true" }
    ),
    key: "sessionV2",
    whitelist: sessionWhitelist
  },
  sessionReducer
);
