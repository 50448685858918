/**
 * MENU_ITEM STYLES
 * @param theme {object}
 */
const styles = theme => ({
  menuItemRoot: {
    ...theme.typography.p3Dark,
    minHeight: "23px",
    height: "auto",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "16px",
    color: theme.palette.grey[900],
    backgroundColor: "transparent",
    whiteSpace: "inherit",

    "&:hover": {
      backgroundColor: theme.palette.secondary["light"]
    },

    "&.focused": {
      backgroundColor: theme.palette.secondary["light"]
    },

    "&:active": {
      backgroundColor: theme.palette.secondary["main"]
    },

    "&:focus": {
      outline: "none"
    },

    "& mark": {
      backgroundColor: "transparent",
      fontWeight: "bold"
    }
  },
  menuItemRootSelected: {
    backgroundColor: theme.palette.secondary["main"] + " !important",
    opacity: "1 !important"
  }
});

export default styles;
