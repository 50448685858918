import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import moment from "moment";

import { utils } from "@cauldron/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AlchemyForm from "./alchemy.form";
import styles from "./styles/apps.form.styles";
import { DIALOG_MESSAGES } from "../../core/constants/index";
import AlertDialog from "../../ui.library/AlertDialog";
import { DIALOG_ACTIONS } from "../../core/constants/dialog.messages";
import { validateInput } from "../../pages/pages.validations";
import HelperText from "../../ui.library/HelperText";
import { isolateDate } from "../../ui.library";

const { objectsHaveDiff } = utils;
const CancelDialog = AlertDialog;
const DeleteDialog = AlertDialog;

/**
 * Trim string values unless field is specified in preventTrim array
 * @param values
 * @param preventTrim
 */
export const trimValues = (values, preventTrim) => {
  const trimmedValues = {};
  for (const v in values) {
    if (values.hasOwnProperty(v)) {
      trimmedValues[v] =
        preventTrim.indexOf(v) < 0 && typeof values[v] === "string"
          ? values[v].trim()
          : values[v];
    }
  }
  return trimmedValues;
};

function AlchemyButton({ name, dataTestId, disabled, onClick, children }) {
  return (
    <Button
      name={name}
      variant="contained"
      color="primary"
      // className={classNames([classes.deleteButton, classes.halfButton])}
      onClick={onClick}
      disabled={disabled}
      data-test={dataTestId}
    >
      <Typography>{children}</Typography>
    </Button>
  );
}

/**
 * Income Form is a wrapper on top of AlchemyForm.
 */
class _Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChanged: objectsHaveDiff(props.initialValues, props.formValues),
      openCancelDialog: false,
      openDeleteDialog: false
    };
  }

  handleCancelButtonClick = () => {
    const { hasChanged } = this.state;
    if (hasChanged) {
      this.setState({ openCancelDialog: true });
    } else {
      this.handleConfirmCancel();
    }
  };

  handleConfirmCancel = () => {
    const { index, onCancel } = this.props;
    onCancel(index);
  };

  handleDeleteButtonClick = () => {
    this.setState({ openDeleteDialog: true });
  };

  handleConfirmDelete = () => {
    const { onDelete } = this.props;
    this.setState({ openDeleteDialog: false });
    onDelete();
  };

  handleDiscardDialog = () => {
    this.setState({ openCancelDialog: false, openDeleteDialog: false });
  };

  handleSetFormStateData = (values, options) => {
    const { setFormStateData, index = null, initialValues } = this.props;
    this.setState({ hasChanged: objectsHaveDiff(initialValues, values) });

    if (setFormStateData && values) {
      const arg = options ? options : index;
      setFormStateData(values, arg);
    }
  };

  fieldHasError = (errors, touched, field) => {
    return validateInput(field, "error", { errors, touched });
  };

  getHelperText = (errors, touched, field) => {
    return validateInput(field, "text", { errors, touched });
  };

  getDataTest = (...args) => {
    const { dataTestBuilder, dataTestId } = this.props;
    return dataTestBuilder && dataTestBuilder(dataTestId, ...args);
  };

  renderErrorStack = () => {
    const { classes, errorStack, i18n } = this.props;
    return errorStack.map((e, i) => (
      <div className={classes.errorLine} key={i}>
        <HelperText type="error">{i18n._(e)}</HelperText>
      </div>
    ));
  };

  getInputProps = (formProps, inputProps = {}) => {
    const { values, handleChange, handleBlur, errors, touched } = formProps;
    const name = inputProps.name;
    return {
      name,
      value: values[name],
      hasError: this.fieldHasError(errors, touched, name),
      helperText: this.getHelperText(errors, touched, name),
      onChange: handleChange,
      onBlur: e => {
        this.handleSetFormStateData(values);
        handleBlur(e);
      },
      placeholder: "",
      required: true,
      FormHelperTextProps: {
        "data-test": this.getDataTest(name, "helper-text")
      },
      InputLabelProps: {
        "data-test": this.getDataTest(name, "label")
      },
      InputProps: {
        inputProps: {
          "data-test": this.getDataTest(name, "input")
        }
      },
      "data-test": this.getDataTest(name, "wrapper"),
      ...inputProps
    };
  };

  getInputDateProps = (formProps, inputProps = {}) => {
    const {
      values,
      setFieldValue,
      setFieldTouched,
      errors,
      touched
    } = formProps;
    const name = inputProps.name;

    return {
      name,
      value: values[name],
      hasError: this.fieldHasError(errors, touched, name),
      helperText: this.getHelperText(errors, touched, name),
      dateFormat: "MMMM Do, YYYY",
      inputProps: {
        minDate: moment("1900-01-01"),
        maxDate: moment(Date.now())
      },
      onChange: isolateDate(date => {
        setFieldValue(name, date ? date.format("Y-MM-DD") : null);
      }),
      onBlur: () => {
        setFieldTouched(name, true);
      },
      placeholder: "",
      required: true,
      FormHelperTextProps: {
        "data-test": this.getDataTest(name, "helper-text")
      },
      InputLabelProps: {
        "data-test": this.getDataTest(name, "label")
      },
      "data-test": this.getDataTest(name, "wrapper"),
      dataTestId: this.getDataTest(name, "input"),
      ...inputProps
    };
  };

  getInputCurrencyProps = (formProps, inputProps = {}) => {
    const { setFieldValue } = formProps;
    const inputPropsProxy = this.getInputProps(formProps, inputProps);
    return {
      ...inputPropsProxy,
      onChange: setFieldValue
    };
  };

  getSelectProps = (formProps, inputProps = {}) => {
    const { dataTestBuilder } = this.props;
    const { values, handleBlur, errors, touched, setFieldValue } = formProps;
    const name = inputProps.name;
    return {
      name,
      initialValue: values[name],
      error: this.fieldHasError(errors, touched, name),
      helperText: this.getHelperText(errors, touched, name),
      onChange: setFieldValue,
      onBlur: e => {
        this.handleSetFormStateData(values);
        handleBlur(e);
      },
      placeholder: "",
      required: true,
      dataTestId: this.getDataTest(name),
      dataTestBuilder: dataTestBuilder,
      FormHelperTextProps: {
        "data-test": this.getDataTest(name, "helper-text")
      },
      InputLabelProps: {
        "data-test": this.getDataTest(name, "label")
      },
      value: values[name],
      ...inputProps
    };
  };

  getDefaultActions = () => {
    const {
      classes,
      i18n,
      onDelete,
      submitButtonText,
      deleteButtonText,
      isSubmitting,
      onCancel,
      onSubmit
    } = this.props;

    return (
      <Fragment>
        {onDelete && (
          <Button
            variant="contained"
            color="primary"
            className={classNames([classes.deleteButton, classes.halfButton])}
            onClick={this.handleDeleteButtonClick}
            disabled={isSubmitting}
            data-test={this.getDataTest("delete-button")}
          >
            <Typography className={classes.deleteText}>
              {deleteButtonText ? i18n.t`${deleteButtonText}` : i18n.t`Delete`}
            </Typography>
          </Button>
        )}
        {onCancel && (
          <Button
            variant="contained"
            color="primary"
            className={classNames([classes.cancelButton, classes.halfButton])}
            onClick={this.handleCancelButtonClick}
            disabled={isSubmitting}
            data-test={this.getDataTest("cancel-button")}
          >
            <Typography className={classes.cancelText}>
              {i18n.t`Cancel`}
            </Typography>
          </Button>
        )}
        {onSubmit && (
          <Button
            variant="contained"
            color="primary"
            className={classNames([
              isSubmitting
                ? classes.disabledConfirmButton
                : classes.confirmButton,
              classes.halfButton
            ])}
            type="submit"
            disabled={isSubmitting}
            data-test={this.getDataTest("confirm-button")}
          >
            <Typography
              className={
                isSubmitting ? classes.disabledConfirmText : classes.confirmText
              }
            >
              {submitButtonText ? i18n._(submitButtonText) : i18n.t`Submit`}
            </Typography>
          </Button>
        )}
      </Fragment>
    );
  };

  getFormComponent = formProps => {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      resetForm,
      setFieldValue,
      setFieldTouched,
      setValues
    } = formProps;

    const {
      classes,
      customProps,
      hasBackground,
      withPadding,
      actionsWrapperHasMargin,
      getActions,
      formDetailComponent: FormDetailComponent,
      dataTestId,
      dataTestBuilder,
      errorStack,
      className,
      initialValues
    } = this.props;

    const formHasError = !!(errorStack && errorStack.length > 0);
    const rootFormClass = !hasBackground
      ? classes.formWrapper
      : classes.formWrapperWithBg;

    return (
      <form
        onSubmit={handleSubmit}
        className={classNames([rootFormClass, className.root])}
        autoComplete="off"
        data-test={this.getDataTest("form")}
      >
        <div
          className={classNames([
            !hasBackground
              ? classes.formContainer
              : classes.formContainerWithBg,
            withPadding ? classes.withPadding : ""
          ])}
        >
          <FormDetailComponent
            values={values}
            hasError={this.fieldHasError.bind(this, errors, touched)}
            getHelperText={this.getHelperText.bind(this, errors, touched)}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            customProps={customProps}
            setFormStateData={this.handleSetFormStateData}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setValues={setValues}
            dataTestId={dataTestId}
            dataTestBuilder={dataTestBuilder}
            getInputProps={this.getInputProps.bind(this, formProps)}
            getInputDateProps={this.getInputDateProps.bind(this, formProps)}
            getSelectProps={this.getSelectProps.bind(this, formProps)}
            getInputCurrencyProps={this.getInputCurrencyProps.bind(
              this,
              formProps
            )}
          />
          {formHasError && (
            <div className={classes.formErrorStrack}>
              {this.renderErrorStack()}
            </div>
          )}
          <div
            className={classNames([
              actionsWrapperHasMargin
                ? classes.actionsWrapper
                : classes.actionsWrapperWithNoMargin
            ])}
          >
            {!getActions && this.getDefaultActions()}
            {getActions &&
              getActions({
                AlchemyButton,
                formProps: {
                  ...formProps,
                  reset: () => resetForm(initialValues),
                  cancel: this.handleCancelButtonClick
                }
              })}
          </div>
        </div>
      </form>
    );
  };

  handleOnSubmit = values => {
    const { onSubmit, index, preventTrim } = this.props;
    const trimmedValues = trimValues(values, preventTrim);
    if (onSubmit) onSubmit(trimmedValues, index);
  };

  render() {
    const {
      initialValues,
      validate,
      formValues,
      i18n,
      enableReinitialize,
      deleteDialogTitleText,
      deleteDialogMessageText,
      onDelete,
      dataTestId,
      dataTestBuilder,
      defaultInitialValue
    } = this.props;

    // DIALOG TEXTS
    const {
      cancelDialogTitleText,
      cancelDialogMessageText = i18n._(DIALOG_MESSAGES.CANCEL.BODY),
      agreeButtonText = i18n._(DIALOG_ACTIONS.OKAY),
      disagreeButtonText = i18n._(DIALOG_ACTIONS.CANCEL)
    } = this.props;

    const { openCancelDialog, openDeleteDialog } = this.state;

    return (
      <Fragment>
        <AlchemyForm
          initialValues={{ ...initialValues, ...formValues }}
          validate={validate}
          onSubmit={this.handleOnSubmit}
          formComponent={this.getFormComponent}
          enableReinitialize={enableReinitialize}
          defaultInitialValue={defaultInitialValue}
        />
        <CancelDialog
          title={cancelDialogTitleText}
          message={cancelDialogMessageText}
          agreeButtonText={agreeButtonText}
          disagreeButtonText={disagreeButtonText}
          onAgree={this.handleConfirmCancel}
          isOpen={openCancelDialog}
          hasOkayButton
          onDialogClose={this.handleDiscardDialog}
          dataTestId={dataTestId}
          dataTestBuilder={dataTestBuilder}
        />
        {onDelete && (
          <DeleteDialog
            title={deleteDialogTitleText}
            message={deleteDialogMessageText}
            agreeButtonText={i18n._(DIALOG_ACTIONS.DELETE)}
            disagreeButtonText={i18n._(DIALOG_ACTIONS.CANCEL)}
            onAgree={this.handleConfirmDelete}
            isOpen={openDeleteDialog}
            hasOkayButton
            onDialogClose={this.handleDiscardDialog}
          />
        )}
      </Fragment>
    );
  }
}

_Form.defaultProps = {
  customProps: {},
  getActions: null,
  initialValues: {},
  formValues: {},
  validate: () => {},
  isSubmitting: false,
  preventTrim: [],
  enableReinitialize: false,
  actionsWrapperHasMargin: true,
  useBuildInDeleteDialog: false,
  className: {
    root: ""
  },
  cancelDialogTitleText: ""
};

_Form.propTypes = {
  /**
   * Form fields
   */
  formDetailComponent: PropTypes.func.isRequired,
  /**
   * Values to initialize fields with
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * Form cached values
   */
  formValues: PropTypes.object.isRequired,
  /**
   * Validation function to pass to Formik
   */
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /**
   * Is submitting flag for the form
   */
  isSubmitting: PropTypes.bool,
  /**
   * Submit button text
   */
  submitButtonText: PropTypes.string,
  /**
   * delete button text
   */
  deleteButtonText: PropTypes.string,
  /**
   * Cancel Dialog text overrides
   */
  cancelDialogTitleText: PropTypes.string,
  cancelDialogMessageText: PropTypes.string,
  agreeButtonText: PropTypes.string,
  disagreeButtonText: PropTypes.string,

  /**
   * Delete Dialog text overrides
   */
  deleteDialogTitleText: PropTypes.string,
  deleteDialogMessageText: PropTypes.string,

  /**
   * control the background styling for specific sections of the app
   * eg. Client card vs Edit bank in Loan apps
   */
  hasBackground: PropTypes.bool,
  /**
   * Adds or removes margin to the actions wrapper (default is true)
   */
  actionsWrapperHasMargin: PropTypes.bool,

  /**
   * Array of fields where if defined in the array,
   * the value of that field does not get trimmed.
   */
  preventTrim: PropTypes.array,
  /**
   * Ensures formik reinitialize values when a value is not updated by Formik
   */
  enableReinitialize: PropTypes.bool,
  /**
   * Data-test locator properties
   */
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func,
  className: PropTypes.shape({
    root: PropTypes.string
  }),

  /**
   * custom actions
   */
  getActions: PropTypes.func
};

const enhance = compose(
  withStyles(styles),
  withI18n()
);

export default enhance(_Form);
