import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { configureStore, services } from "@cauldron/core";
import {
  agentPortalApplication,
  agentPortalClient,
  coordinator,
  creditReportReducer,
  documentReducer,
  errorHandler,
  loading,
  messengerReducer,
  notes,
  referralSource,
  servicesSaga,
  sessionReducer,
  trackerReducer,
  info
} from "./services";
import { pagesReducers, pagesSaga } from "./pages";
import formsReducer from "./services/forms/forms.reducer";

const rootReducer = combineReducers({
  messenger: messengerReducer,
  pages: pagesReducers,
  session: sessionReducer,
  i18n: services.i18n.reducer,
  ui: trackerReducer,
  forms: formsReducer,
  errors: errorHandler.reducer,
  data: combineReducers({
    info: info.reducer,
    creditReports: creditReportReducer,
    documents: documentReducer,
    notes: notes.reducer,
    agentPortalApplication: agentPortalApplication.reducer,
    agentPortalClient: agentPortalClient.reducer,
    referralSources: referralSource.reducer,
    coordinator: coordinator.reducer
  }),
  loading: loading.reducer
});

const rootSaga = function* sagas() {
  yield all([...pagesSaga, ...servicesSaga]);
};

const { store, persistor, history } = configureStore({
  initialState: {},
  rootReducer,
  rootSaga
});

export { store, persistor, history };
