export const styles = theme => ({
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main
    },
    paddingLeft: "16px",
    height: "23px",
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  itemText: { color: theme.typography.p2Dark.color }
});
