import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { styles } from "./styles/field.grid.styles";
import { FIELD_GRID_STYLES } from "../../core/constants/ui";

/**
 * Field grid component is a responsive grid that contains one or many field groups
 * @type Component
 * @author Stanley
 * @version 1.0.0
 * @param {string} indentStyle the indent style for FieldGrid. Default is noIndent
 */

class FieldGrid extends React.Component {
  render() {
    const { classes, children, indentStyle } = this.props;

    return (
      <div
        className={classnames(classes.fieldGridWrapper, classes[indentStyle])}
      >
        {children}
      </div>
    );
  }
}

FieldGrid.defaultProps = {
  indentStyle: FIELD_GRID_STYLES.NO_INDENT
};

FieldGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * Indent style: either no indent or left indent
   */
  indentStyle: PropTypes.oneOf([
    FIELD_GRID_STYLES.NO_INDENT,
    FIELD_GRID_STYLES.LEFT_INDENT
  ]).isRequired
};

export default withStyles(styles)(FieldGrid);
