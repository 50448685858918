"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.API = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var API_MAIN = _interopRequireWildcard(require("./api.main"));

var API_ANON = _interopRequireWildcard(require("./api.anon"));

var API_AGENT_PORTAL = _interopRequireWildcard(require("./api.agent.portal"));

var API_APPLICATIONS = _interopRequireWildcard(require("./api.applications"));

var API_CLIENTS = _interopRequireWildcard(require("./api.clients"));

var API_PORTAL = _interopRequireWildcard(require("./api.portal"));

var API_FUNDED = _interopRequireWildcard(require("./api.funded"));

var API = (0, _objectSpread2.default)({}, API_MAIN, API_ANON, API_AGENT_PORTAL, API_APPLICATIONS, API_CLIENTS, API_FUNDED, API_PORTAL);
exports.API = API;
var _default = API;
exports.default = _default;