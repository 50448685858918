import errorBg from "../images/error-bg.svg";

/**
 * ERROR MESSAGE STYLES
 * @author Ryan Rivera
 * @param theme
 */
const styles = theme => ({
  root: {
    height: "100%",
    backgroundImage: `url(${errorBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 125%",
    backgroundPosition: "center center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "700px"
  },
  messageWrapper: {
    width: "680px",
    display: "flex",
    flexDirection: "row"
  },
  icon: {
    width: "206px",
    fontSize: "206px",
    color: theme.palette.grey[900]
  },
  text: {
    margin: "20px"
  },
  title: { ...theme.typography.h1Dark, marginBottom: 16 },
  message: { ...theme.typography.h4Dark, marginBottom: 16, width: 428 },
  error: { ...theme.typography.h6Dark, marginBottom: 16, width: 428 },
  link: {
    ...theme.typography.p2Dark,
    marginBottom: 16
  },
  parentLink: {
    textDecorationColor: theme.palette.grey[900]
  },
  tryAgain: {
    ...theme.typography.h4Dark,
    marginBottom: 16,
    width: 428,
    marginTop: 28
  }
});

export default styles;
