import validator from "validator";
import { utils } from "@cauldron/core";
import { FIELD_NAMES } from "./loan.application.constants";
import { FORM_INPUTS_VALIDATION } from "../pages.validations";

const { removePhoneFormat } = utils;

const validations = (i18n, values) => {
  const errors = {};

  if (!values[FIELD_NAMES.APPLICANT]) {
    errors[FIELD_NAMES.APPLICANT] = i18n._(
      FORM_INPUTS_VALIDATION.REQUIRED_APPLICANT
    );
  }

  const applicantEmail = values[FIELD_NAMES.APPLICANT_EMAIL];
  if (!applicantEmail) {
    errors[FIELD_NAMES.APPLICANT_EMAIL] = i18n._(
      FORM_INPUTS_VALIDATION.REQUIRED_EMAIL
    );
  } else if (!validator.isEmail(applicantEmail)) {
    errors[FIELD_NAMES.APPLICANT_EMAIL] = i18n._(
      FORM_INPUTS_VALIDATION.INVALID_EMAIL
    );
  }

  const applicantPhone = removePhoneFormat(
    values[FIELD_NAMES.APPLICANT_NUMBER]
  );
  if (applicantPhone && !validator.isNumeric(applicantPhone)) {
    errors[FIELD_NAMES.APPLICANT_NUMBER] = i18n._(
      FORM_INPUTS_VALIDATION.INVALID_PHONE
    );
  } else if (applicantPhone && applicantPhone.toString().trim().length !== 10) {
    errors[FIELD_NAMES.APPLICANT_NUMBER] = FORM_INPUTS_VALIDATION.INVALID_PHONE;
  }

  if (values[FIELD_NAMES.CO_APPLICANT]) {
    const coApplicantEmail = values[FIELD_NAMES.CO_APPLICANT_EMAIL];
    if (!coApplicantEmail) {
      errors[FIELD_NAMES.CO_APPLICANT_EMAIL] = i18n._(
        FORM_INPUTS_VALIDATION.REQUIRED_EMAIL
      );
    } else if (!validator.isEmail(coApplicantEmail)) {
      errors[FIELD_NAMES.CO_APPLICANT_EMAIL] = i18n._(
        FORM_INPUTS_VALIDATION.INVALID_EMAIL
      );
    }

    const coApplicantPhone = removePhoneFormat(
      values[FIELD_NAMES.CO_APPLICANT_NUMBER]
    );
    if (coApplicantPhone && !validator.isNumeric(coApplicantPhone)) {
      errors[FIELD_NAMES.CO_APPLICANT_NUMBER] = i18n._(
        FORM_INPUTS_VALIDATION.INVALID_PHONE
      );
    } else if (
      coApplicantPhone &&
      coApplicantPhone.toString().trim().length !== 10
    ) {
      errors[FIELD_NAMES.CO_APPLICANT_NUMBER] =
        FORM_INPUTS_VALIDATION.INVALID_PHONE;
    }
  }

  return errors;
};

export default validations;
