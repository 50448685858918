"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.track = track;
exports.register = register;
exports.identify = identify;

var _mixpanelAction = require("./mixpanel.action.types");
/**
 * mixpanel.track
 * @param event
 * @param payload
 * @returns {{payload: *, type: string, event: *}}
 */


function track(event, payload) {
  return {
    type: _mixpanelAction.TRACK,
    event: event,
    payload: payload
  };
}
/**
 * mixpanel.register
 * @param payload
 */


function register(payload) {
  return {
    type: _mixpanelAction.REGISTER,
    payload: payload
  };
}
/**
 * mixpanel.identify
 * You should call it as soon as the user logs in to your application.
 * This will track all of their actual application usage to the correct user ID.
 * @param id
 */


function identify(id) {
  return {
    type: _mixpanelAction.IDENTIFY,
    id: id
  };
}