"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTheme = exports.createTransition = void 0;

var _packs = _interopRequireDefault(require("./packs"));
/**
 * createTransition
 * @param theme
 * @param property
 * @returns {{transitionProperty: *, transitionDuration: number, transitionTimingFunction: string}}
 */


var createTransition = function createTransition(theme, property) {
  return {
    transitionProperty: property,
    transitionDuration: theme.transitions.duration.shorter,
    transitionTimingFunction: theme.transitions.easing.easeInOut
  };
};
/**
 * getTheme
 * @param key
 * @returns {*}
 */


exports.createTransition = createTransition;

var getTheme = function getTheme(key) {
  return key && _packs.default[key] ? _packs.default[key] : null;
};

exports.getTheme = getTheme;