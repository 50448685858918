import React from "react";
import PropTypes from "prop-types";
import { utils } from "@cauldron/core";
import { Formik } from "formik";

const { initializeValues } = utils;

/**
 * AlchemyForm is a basic wrapper over Formik. It contains no styling or logic.
 * @param FormComponent
 * @param initialValues
 * @param validate
 * @param onSubmit
 * @param enableReinitialize
 * @param defaultInitialValue
 * @returns {*}
 * @constructor
 */
function AlchemyForm({
  formComponent: FormComponent,
  initialValues,
  validate,
  onSubmit,
  enableReinitialize,
  defaultInitialValue
}) {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initializeValues(initialValues, defaultInitialValue)}
      validate={validate}
      onSubmit={onSubmit}
      render={formProps => <FormComponent {...formProps} />}
    />
  );
}

AlchemyForm.defaultProps = {
  enableReinitialize: false,
  initialValues: {},
  validate: () => {}
};

AlchemyForm.propTypes = {
  // Ensures formik reinitialize values when a value is not updated by Formik
  enableReinitialize: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formComponent: PropTypes.func.isRequired
};

export default AlchemyForm;
