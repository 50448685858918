import {
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REFRESH_SUCCESS,
  USER_LOGIN_REQUEST_FAILURE,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  PROFILE_REQUEST_SUCCESS,
  PROFILE_REQUEST_FAILURE,
  CONTEXT_PROFILE_REQUEST_SUCCESS,
  CONTEXT_PROFILE_REQUEST_FAILURE,
  PERMISSIONS_REQUEST_SUCCESS,
  PERMISSIONS_REQUEST_FAILURE
} from "./auth.action.types";

/**
 * SESSION REDUCER
 */
const initialState = {
  token: null,
  profile: null,
  permissions: null,
  permissionDefinitions: null,
  roles: [],
  expiresAt: null
};

export const whitelist = [
  "roles",
  "permissions",
  "profile",
  "token",
  "expiresAt"
];

export default (state = initialState, action) => {
  let googleAuthResponse = {
      tokenObj: {},
      profileObj: {}
    },
    id_token = null,
    expires_at = null,
    tokenObj = {},
    profileObj = { ...state.profile };

  switch (action.type) {
    case USER_LOGIN_REQUEST_SUCCESS:
      googleAuthResponse = action.payload.googleAuthResponse;
      tokenObj = googleAuthResponse.tokenObj;
      id_token = tokenObj.id_token;
      expires_at = tokenObj.expires_at;

      // Google profile object is only available during a successful login
      // during re-auth we grab the profile from the state
      profileObj = googleAuthResponse.profileObj;

      return {
        ...state,
        profile: { ...profileObj },
        token: id_token,
        expiresAt: expires_at
      };

    case USER_LOGIN_REFRESH_SUCCESS:
      tokenObj = action.payload.googleAuthResponse;
      id_token = tokenObj.id_token;
      expires_at = tokenObj.expires_at;

      return {
        ...state,
        profile: { ...profileObj },
        token: id_token,
        expiresAt: expires_at
      };

    case PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isProfileRequestComplete: true
      };

    case CONTEXT_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        permissions:
          state.permissions && Array.isArray(state.permissions)
            ? state.permissions.concat(action.payload.permissions)
            : null,
        allowedStatuses: action.payload.allowedStatuses
      };

    case PERMISSIONS_REQUEST_SUCCESS:
      return {
        ...state,
        permissionDefinitions: action.payload.results
      };

    case USER_LOGIN_REQUEST_FAILURE:
    case USER_LOGOUT_SUCCESS:
      return initialState;

    case USER_LOGOUT_FAILURE:
    case PROFILE_REQUEST_FAILURE:
    case CONTEXT_PROFILE_REQUEST_FAILURE:
    case PERMISSIONS_REQUEST_FAILURE:
      return state;

    default:
      return state;
  }
};
