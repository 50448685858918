"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAN_AMOUNT = void 0;
var LOAN_AMOUNT = {
  MIN: 500,
  MAX: 15000
};
exports.LOAN_AMOUNT = LOAN_AMOUNT;