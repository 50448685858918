"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Loader", {
  enumerable: true,
  get: function get() {
    return _loader.default;
  }
});

var _loader = _interopRequireDefault(require("./loader"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}