import { domain } from "@cauldron/core";
import { fork, call, put, takeLatest, all } from "redux-saga/effects";
import { actionTypes } from "./coordinator.action.types";
import {
  evaluateSuccess,
  evaluateFailure,
  preScreenSuccess,
  preScreenFailure
} from "./coordinator.actions";

import { http } from "../../http/http.service";
import { isFetchingForm, addFormSectionError } from "../../forms/forms.actions";

const { DecisionAction } = domain;

export function* evaluate(action) {
  const { formId, payload, eventHandlers = {} } = action;
  const onSuccess = eventHandlers.onSuccess;
  const res = yield* http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.COORDINATOR_EVALUATE, payload);
    },
    transformReceiveData: DecisionAction.model.transformReceived
  });

  if (res.success) {
    const effects = [put(evaluateSuccess(res.data))];
    if (onSuccess) effects.push(call(onSuccess, res.data));
    yield all(effects);
  } else {
    yield all([
      put(evaluateFailure(res.data)),
      put(isFetchingForm(formId, false)),
      put(addFormSectionError(formId, res.data))
    ]);
  }

  return res;
}

export const preScreen = action => {
  const { payload, eventHandlers = {} } = action;
  const onSuccess = eventHandlers.onSuccess;

  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.COORDINATOR_PRE_SCREEN, payload);
    },
    success: data => {
      const effects = [put(preScreenSuccess(data))];
      if (onSuccess) effects.push(call(onSuccess, data));
      return all(effects);
    },
    error: error => {
      return put(preScreenFailure(error));
    },
    transformReceiveData: DecisionAction.model.transformReceived
  });
};

export function* watchCoordinatorEvaluateAsync() {
  yield takeLatest(actionTypes.EVALUATE_REQUEST, evaluate);
  yield takeLatest(actionTypes.PRE_SCREEN_REQUEST, preScreen);
}

export default [fork(watchCoordinatorEvaluateAsync)];
