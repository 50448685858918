export const MODULE_APPLICATIONS = "APPLICATIONS";
export const MODULE_LOANS = "LOANS";
export const MODULE_TRANCHES = "TRANCHES";
export const MODULE_SYSCONFIG = "SYSCONFIG";

export const MODULES = [
  MODULE_APPLICATIONS,
  MODULE_LOANS,
  MODULE_TRANCHES,
  MODULE_SYSCONFIG
];
