import find from "lodash/find";
import findIndex from "lodash/findIndex";
import * as actions from "../action.types/funded.loan.details.action.types";
import {
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_SUCCESS,
  FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_SUCCESS
} from "../action.types/forms.action.types";
import * as clientActionTypes from "../action.types/client.content.action.types";
import { updateClientsData } from "../../../../utils/reducer.utils";
import { FUNDED_LOAN_DETAILS_DOCS_FETCH_SUCCESS } from "../action.types/tabs.action.types";

const initialState = {};
let newAuthUsers = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FUNDED_LOAN_DETAILS_LOAN_FETCH_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        ...data
      };
    }

    case actions.FUNDED_LOAN_DETAILS_LOAN_FETCH_FAILURE:
      return state;

    case FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_UPDATE_SUCCESS:
      state.clients.forEach(client => {
        if (client.id === action.clientId) {
          newAuthUsers = client.authUsers.map(authUser => {
            if (authUser.id === action.authUser.id) {
              return action.authUser;
            } else {
              return authUser;
            }
          });
        }
      });

      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === action.clientId) {
            return {
              ...client,
              authUsers: newAuthUsers
            };
          } else {
            return client;
          }
        })
      };

    case clientActionTypes.FUNDED_LOAN_DETAILS_AUTH_USER_DELETE_SUCCESS: {
      const deleteAuthUserClient = find(state.clients, {
        id: action.clientId
      });
      const deleteAuthUserIndex = findIndex(deleteAuthUserClient.authUsers, {
        id: action.authUserId
      });
      const authUsers = [...deleteAuthUserClient.authUsers];
      authUsers.splice(deleteAuthUserIndex, 1);
      return {
        ...state,
        clients: state.clients.map(c => {
          if (c.id === action.clientId) {
            return { ...c, authUsers: [...authUsers] };
          } else {
            return c;
          }
        })
      };
    }

    case FUNDED_LOAN_DETAILS_CLIENT_AUTH_USER_ADD_SUCCESS:
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === action.clientId) {
            return {
              ...client,
              authUsers: [...client.authUsers, action.authUser]
            };
          }
          return client;
        })
      };

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_PHONE_DELETE_SUCCESS: {
      const deletePhoneClient = find(state.clients, { id: action.clientId });
      const deletePhoneIndex = findIndex(deletePhoneClient.phones, {
        id: action.phoneId
      });
      const phoneArray = [...deletePhoneClient.phones];
      phoneArray.splice(deletePhoneIndex, 1);
      return {
        ...state,
        clients: state.clients.map(c => {
          if (c.id === action.clientId) {
            return { ...c, phones: [...phoneArray] };
          } else {
            return c;
          }
        })
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_EMAIL_UPDATE_SUCCESS: {
      const client = Object.assign(
        {},
        find(state.clients, { id: action.clientId })
      );
      const { email } = action.data;
      client.emailAddress = email;
      return {
        ...state,
        clients: state.clients.map(c => {
          if (c.id === action.clientId) {
            return { ...c, ...client };
          } else {
            return c;
          }
        })
      };
    }

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_ADDRESS_UPDATE_SUCCESS:
      /**
       * note: I LEAVE THE COMMENTS BELOW (OLD APPROACH) AS REFERRENCE FOR FUTURE REFACTORING
       * TODO: Remove the comments after the code get refactored
       */

      // let newClient = Object.assign(
      //   {},
      //   find(state.clients, { id: action.clientId })
      // );
      // newClient.address = action.data;
      // return {
      //   ...state,
      //   clients: state.clients.map(c => {
      //     if (c.id === action.clientId) {
      //       return { ...c, ...newClient };
      //     } else {
      //       return c;
      //     }
      //   })
      // };

      return {
        ...state,
        clients: updateClientsData(state.clients, action.clientId, "address", {
          ...action.data
        })
      };

    // UPDATE CLIENT TITLE
    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_TITLE_UPDATE_SUCCESS:
      return {
        ...state,
        clients: updateClientsData(
          state.clients,
          action.clientId,
          "title",
          action.data.title
        )
      };

    // UPDATE CLIENT MARITAL
    case FUNDED_LOAN_DETAILS_PERSONAL_DETAILS_MARITAL_UPDATE_SUCCESS:
      return {
        ...state,
        clients: updateClientsData(
          state.clients,
          action.clientId,
          "maritalStatus",
          action.data.maritalStatus
        )
      };

    case clientActionTypes.FUNDED_LOAN_DETAILS_CLIENT_UPDATE_NAME_SUCCESS: {
      const {
        firstName: updatedFirstName,
        middleName: updatedMiddleName,
        lastName: updatedLastName
      } = action.data;
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === action.clientId) {
            return {
              ...client,
              firstName: updatedFirstName,
              middleName: updatedMiddleName,
              lastName: updatedLastName
            };
          } else {
            return client;
          }
        })
      };
    }

    case FUNDED_LOAN_DETAILS_CLIENT_PHONE_UPDATE_SUCCESS:
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === action.clientId) {
            return {
              ...client,
              phones: action.phones.results
            };
          } else {
            return client;
          }
        })
      };

    case FUNDED_LOAN_DETAILS_CLIENT_PHONE_ADD_SUCCESS:
      return {
        ...state,
        clients: state.clients.map(client => {
          if (client.id === action.clientId) {
            return {
              ...client,
              phones: action.phones.results
            };
          } else {
            return client;
          }
        })
      };

    case FUNDED_LOAN_DETAILS_DOCS_FETCH_SUCCESS: {
      const documentResults =
        action.data && action.data.results ? action.data.results : [];
      const documentCounts = documentResults.length;
      return {
        ...state,
        loanStats: {
          ...state.loanStats,
          documentCounts
        }
      };
    }

    default:
      return state;
  }
};
