const styles = theme => ({
  root: {
    display: "flex",
    background: theme.palette.common["white"],
    height: "100%",
    flexDirection: "column",
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 2,
    width: "inherit"
  },
  header: {
    display: "flex",
    justifyContent: "flex-end"
  },
  content: {
    flex: "1 1 100%"
  }
});

export default styles;
