import React from "react";
import Loadable from "react-loadable";
import Loader from "../../ui.library/Loader";

export default Loadable.Map({
  loader: {
    LoanApplications: () => import("./loan.applications.index")
  },
  render(loaded, props) {
    const LoanApplications = loaded.LoanApplications.default;
    return <LoanApplications {...props} />;
  },
  loading: () => <Loader size={75} thickness={3.5} centerScreen />
});
