// CLIENT LIABILITY - ADD
export const LOAN_APPLICATION_CLIENT_LIABILITY_ADD =
  "loan.application/CLIENT_LIABILITY_ADD";
export const LOAN_APPLICATION_CLIENT_LIABILITY_ADD_SUCCESS =
  "loan.application/CLIENT_LIABILITY_ADD_SUCCESS";
export const LOAN_APPLICATION_CLIENT_LIABILITY_ADD_FAILURE =
  "loan.application/CLIENT_LIABILITY_ADD_FAILURE";
export const LOAN_APPLICATION_CLIENT_LIABILITY_ADD_CACHE =
  "loan.application/CLIENT_LIABILITY_ADD_CACHE";
export const LOAN_APPLICATION_CLIENT_LIABILITY_ADD_CACHE_CLEAN =
  "loan.application/CLIENT_LIABILITY_ADD_CACHE_CLEAN";

// CLIENT LIABILITY - DELETE
export const LOAN_APPLICATION_CLIENT_LIABILITY_DELETE =
  "loan.application/CLIENT_LIABILITY_DELETE";
export const LOAN_APPLICATION_CLIENT_LIABILITY_DELETE_SUCCESS =
  "loan.application/CLIENT_LIABILITY_DELETE_SUCCESS";
export const LOAN_APPLICATION_CLIENT_LIABILITY_DELETE_FAILURE =
  "loan.application/CLIENT_LIABILITY_DELETE_FAILURE";

// CLIENT LIABILITY - UPDATE
export const LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE =
  "loan.application/CLIENT_LIABILITY_UPDATE";
export const LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_SUCCESS =
  "loan.application/CLIENT_LIABILITY_UPDATE_SUCCESS";
export const LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_FAILURE =
  "loan.application/CLIENT_LIABILITY_UPDATE_FAILURE";
export const LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_CACHE =
  "loan.application/CLIENT_LIABILITY_UPDATE_CACHE";
export const LOAN_APPLICATION_CLIENT_LIABILITY_CANCEL_EDIT_FORM =
  "loan.application/CLIENT_LIABILITY_CANCEL_EDIT_FORM";
export const LOAN_APPLICATION_LIABILITY_CACHE_FORM_VALUES =
  "loan.application/LIABILITY_CACHE_FORM_VALUES";
