import {
  LOAN_REQUEST_NOTE_TEMPLATES_FETCH,
  LOAN_REQUEST_NOTE_TEMPLATES_FETCH_SUCCESS,
  LOAN_REQUEST_NOTE_TEMPLATES_FETCH_FAILURE,
  LOAN_REQUEST_SUBMIT,
  LOAN_REQUEST_SUBMIT_SUCCESS,
  LOAN_REQUEST_SUBMIT_FAILURE
} from "./loan.request.action.types";

// NOTE TEMPLATES
export function fetchNoteTemplates() {
  return {
    type: LOAN_REQUEST_NOTE_TEMPLATES_FETCH
  };
}
export function fetchNoteTemplatesSuccess(data) {
  const { results } = data;
  return {
    type: LOAN_REQUEST_NOTE_TEMPLATES_FETCH_SUCCESS,
    templates: results
  };
}
export function fetchNoteTemplatesFailure(error) {
  return {
    type: LOAN_REQUEST_NOTE_TEMPLATES_FETCH_FAILURE,
    error
  };
}

// LOAN REQUEST (SEND)
export function loanRequestSubmit(data) {
  return {
    type: LOAN_REQUEST_SUBMIT,
    data
  };
}
export function loanRequestSubmitSuccess(data) {
  return {
    type: LOAN_REQUEST_SUBMIT_SUCCESS,
    data
  };
}
export function loanRequestSubmitFailure(error) {
  return {
    type: LOAN_REQUEST_SUBMIT_FAILURE,
    error
  };
}
