import { createSelector } from "reselect";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import { utils } from "@cauldron/core";
import { getFormsByGroup } from "../../../../services/forms/forms.selectors";
import {
  referralSource as referralSourceService,
  creditReport as creditReportService
} from "../../../../services";

const { promote, normalizeToMonthly, normalizeToAnnual, concatText } = utils;

const tabsActionsListSelector = state =>
  state.pages.loanApplicationDetails.ui.tabsActions;
export const getTabsActions = createSelector(
  tabsActionsListSelector,
  list => list
);

// FETCH STATUS
const fetchStatusSelector = state =>
  state.pages.loanApplicationDetails.ui.isFetching;

export const getFetchStatus = createSelector(
  fetchStatusSelector,
  isFetching => isFetching
);

export const getApplicationFetchStatus = createSelector(
  getFetchStatus,
  fetchStatus => fetchStatus.application
);

export const getNotesFetchStatus = createSelector(
  getFetchStatus,
  fetchStatus => fetchStatus.notes
);

export const getDocsFetchStatus = createSelector(
  getFetchStatus,
  fetchStatus => fetchStatus.documents
);

export const getOutstandingDocsFetchStatus = createSelector(
  getFetchStatus,
  fetchStatus => fetchStatus.outstandingDocs
);

export const getBankRequestFetchStatus = createSelector(
  getFetchStatus,
  fetchStatus => fetchStatus.bankRequests
);

export const getLiabilityFetchStatus = createSelector(
  getFetchStatus,
  fetchStatus => fetchStatus.liability
);

export const getExpenseFetchStatus = createSelector(
  getFetchStatus,
  fetchStatus => fetchStatus.expense
);

// APPLICATION
const applicationSelector = state =>
  state.pages.loanApplicationDetails.data.application;
export const getApplication = createSelector(
  applicationSelector,
  application => application
);
export const getApplicationStatus = createSelector(
  applicationSelector,
  application => {
    return application.status;
  }
);
export const getApplicationId = createSelector(
  applicationSelector,
  application => application.id
);

export const getLoanPurpose = createSelector(
  applicationSelector,
  application => application.extras.loanPurpose
);

// APPLICATION.OWNER
const ownerSelector = state =>
  state.pages.loanApplicationDetails.data.application.owner;
export const getOwner = createSelector(
  ownerSelector,
  owner => owner
);

// APPLICATION.REFERRAL
const referralSelector = state =>
  state.pages.loanApplicationDetails.data.application.referral;
export const getReferral = createSelector(
  referralSelector,
  referral => referral
);

// Use getReferralSourcesByCompany selector from referralSource service
// to get a list of referral sources
const { getReferralSourcesByCompany } = referralSourceService.selectors;

const referralEditFormSelector = state =>
  state.pages.loanApplicationDetails.form.referral.update;
export const getReferralUpdateFormCollection = createSelector(
  referralEditFormSelector,
  getReferralSourcesByCompany,
  (form, referralSources) => {
    return form.map(referralFormState => {
      const key = "options";
      const referralSourceOptions = referralFormState.hasOwnProperty(key)
        ? { [key]: referralSources }
        : {};
      return { ...referralFormState, ...referralSourceOptions };
    });
  }
);

// APPLICATION.LOAN
const loanSelector = state =>
  state.pages.loanApplicationDetails.data.application.loan;
export const getLoan = createSelector(
  loanSelector,
  loan => loan
);

export const getLoanId = createSelector(
  loanSelector,
  loan => loan.id
);

// APPLICATION.CLIENTS
const addTotalExpenses = expense => {
  const { payment = 0, payFrequency = "Unknown" } = expense;
  expense.monthlyExpense = normalizeToMonthly[payFrequency](payment);
  expense.annualExpense = normalizeToAnnual[payFrequency](payment);
  return expense;
};

export const clientsSelector = state => {
  const clients = state.pages.loanApplicationDetails.data.application.clients;
  return promote("primary", true, clients);
};
const { selectCreditReports } = creditReportService.selectors;

export const getClients = createSelector(
  [clientsSelector, selectCreditReports],
  (clients, creditReports) =>
    clients.map(client => {
      const { id, firstName, middleName, lastName } = client;

      const clientCreditReport = creditReports[id] || {};
      client.creditReports = clientCreditReport;
      client.fullName = concatText(" ", firstName, middleName, lastName);

      // Calculate monthly expense
      client.expenses.map(addTotalExpenses);

      // Calculate monthly expense
      client.liabilities.map(addTotalExpenses);

      // Calculate total monthly expenses
      const monthlyExpenseTotal = client.liabilities
        .concat(client.expenses)
        .reduce(
          (acc = 0, curr = 0) => ({
            monthlyExpense: acc.monthlyExpense + curr.monthlyExpense
          }),
          { monthlyExpense: 0 }
        );

      // Calculate total annual expenses
      const annualExpenseTotal = client.liabilities
        .concat(client.expenses)
        .reduce(
          (acc = 0, curr = 0) => ({
            annualExpense: acc.annualExpense + curr.annualExpense
          }),
          { annualExpense: 0 }
        );

      // Calculate total annual liabilities
      const annualLiabilitiesTotal = client.liabilities.reduce(
        (acc, curr) => acc + curr.annualExpense,
        0
      );

      // Calculate Totals
      client.statistics = {
        ...monthlyExpenseTotal,
        ...annualExpenseTotal,
        annualLiabilitiesTotal
      };
      return client;
    })
);

const clientIdSelector = (state, props) => props.clientId;
export const getPrimaryAddress = createSelector(
  [clientsSelector, clientIdSelector],
  (clients, clientId) => {
    const client = find(clients, ["id", clientId]);
    if (client && client.addresses) {
      const address = find(client.addresses, ["primaryResidence", true]);
      return address;
    }
    return null;
  }
);

// APPLICATION.NOTES
const notesSelector = state =>
  state.pages.loanApplicationDetails.data.application.notes;
export const getNotes = createSelector(
  notesSelector,
  data => data
);

export const getSelectedNoteId = state =>
  state.pages.loanApplicationDetails.ui.selectedNote;

// DOCUMENTS
export const getAppDocs = state => state.data.documents.docs;

// EMAILS
const emailsSelector = state => state.pages.loanApplicationDetails.data.emails;
export const getEmails = createSelector(
  emailsSelector,
  data => data
);

// DECISION ACTIONS
const decisionActionsSelector = state => {
  return state.pages.loanApplicationDetails.data.scores.results;
};

export const getDecisionActions = createSelector(
  decisionActionsSelector,
  results => {
    const clonedResults = cloneDeep(results);
    // last created was applicant used to decide score
    return clonedResults.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  }
);

export const getIsRiskAssessmentsUpdated = state => {
  return !!state.pages.loanApplicationDetails.ui.riskAssessmentsUpdated;
};

// TABS INFO
// Provides tab counts
const tabsInfoSelector = state =>
  state.pages.loanApplicationDetails.ui.tabsInfo;
export const getTabsInfo = createSelector(
  getNotes,
  getAppDocs,
  getEmails,
  tabsInfoSelector,
  (notes = [], docs = [], emails = [], tabsInfo) =>
    tabsInfo.map(tab => {
      switch (tab.value) {
        case "NotesTab":
          tab.count = notes.length;
          break;
        case "DocsTab":
          tab.count = docs.filter(doc => doc.status === "Unreviewed").length;
          break;
        case "EmailsTab":
          tab.count = emails.length;
          break;
        default:
          break;
      }
      return tab;
    })
);

const debtServiceRatioSelector = state =>
  state.pages.loanApplicationDetails.data.debtRatio;
export const getDebtServiceRatio = createSelector(
  debtServiceRatioSelector,
  data => data
);

const serviceDebtFetchingStatusSelector = state =>
  state.pages.loanApplicationDetails.ui.isFetching.serviceDebts;
export const getDebtServiceRatioFetchStatus = createSelector(
  serviceDebtFetchingStatusSelector,
  status => status
);

// CLIENT EXPENSE FORM COLLECTION
const addFormTotalExpenses = expense => {
  const { values = null } = expense;
  if (values) {
    expense.values = addTotalExpenses(values);
  }
  return expense;
};

// CLIENT LIABILITY FORM COLLECTION
export const getLiabilityFormCollection = (state, group) =>
  getFormsByGroup(state, group).map(addFormTotalExpenses);

const expenseFormCollectionSelector = state =>
  state.pages.loanApplicationDetails.form.expense;
export const getExpenseAddFormCollection = createSelector(
  expenseFormCollectionSelector,
  expenses => expenses.add.map(addFormTotalExpenses)
);
export const getExpenseUpdateFormCollection = createSelector(
  expenseFormCollectionSelector,
  expenses => keyBy(expenses.update.map(addFormTotalExpenses), "id")
);

// EDIT BANK ACCOUNT SELECTORS
const updateBankFormsSelector = state =>
  state.pages.loanApplicationDetails.form.bankDetails.update;
export const getUpdateForms = createSelector(
  updateBankFormsSelector,
  forms => forms
);

// ADD BANK FORM SELECTORS
const addBankFormsSelector = state =>
  state.pages.loanApplicationDetails.form.bankDetails.add;
export const getAddForms = createSelector(
  addBankFormsSelector,
  forms => forms
);

// CLIENT FORM COLLECTION
const clientFormCollectionSelector = state =>
  state.pages.loanApplicationDetails.form.client.add;
export const getClientAddFormCollection = createSelector(
  clientFormCollectionSelector,
  form => form
);
export const getClientAddFormLength = createSelector(
  clientFormCollectionSelector,
  form => form.length
);

const clientAddFormFetchingStatusSelector = state =>
  state.pages.loanApplicationDetails.ui.isFetching.clientAddForm;
export const getClientAddFormFetchStatus = createSelector(
  clientAddFormFetchingStatusSelector,
  status => status
);

const dealLeadEditFormSelector = state => {
  return state.pages.loanApplicationDetails.form.dealLead.update[0];
};
export const getDealLeadEditForm = createSelector(
  dealLeadEditFormSelector,
  form => form
);

const dealLeadOptionsSelector = state => {
  return state.pages.loanApplicationDetails.data.dealLeadOptions;
};

export const getDealLeadOptions = createSelector(
  dealLeadOptionsSelector,
  options => options
);

const bankRequestsSelector = state => {
  return state.pages.loanApplicationDetails.data.bankRequests;
};

export const getBankRequests = createSelector(
  bankRequestsSelector,
  bankRequests => {
    const sortedRequests = sortBy(bankRequests, ["createdAt"]);
    return sortedRequests;
  }
);

// TODO: Refactor to move allowedStatuses to application details state slice
export const allowedStatusesSelector = state => state.session.allowedStatuses;

export const getAllowedStatuses = createSelector(
  allowedStatusesSelector,
  statuses => {
    if (!statuses) return [];
    return statuses.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
);

// TODO: use getTemplatesByTag once refactored and moved to service
export const noteTemplatesSelector = state =>
  state.pages.loanApplicationDetails.data.application.noteTemplates;

export const getNoteTemplates = createSelector(
  noteTemplatesSelector,
  templates => {
    if (!templates) return [];
    return templates.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
);

const templatesSelector = state =>
  state.pages.loanApplicationDetails.data.application.noteTemplates;
const tagSelector = (state, tag) => tag;

export const getTemplatesByTag = createSelector(
  [templatesSelector, tagSelector],
  (templates = [], tag) =>
    templates
      .filter(({ tags }) => tags.includes(tag))
      .sort((a, b) => (a.name > b.name ? 1 : -1))
);

export const docsTabUISelector = state =>
  state.pages.loanApplicationDetails.ui.docsTab;
export const getDocsTabUIMode = createSelector(
  docsTabUISelector,
  docsTabUI => docsTabUI.mode
);

export const getDocsTabUISelectedClientId = createSelector(
  docsTabUISelector,
  docsTabUI => docsTabUI.selectedClientId
);

export const getDocsTabUISelectedLoanId = createSelector(
  docsTabUISelector,
  docsTabUI => docsTabUI.selectedLoanId
);

export const getDocsTabUISelectedDocId = createSelector(
  docsTabUISelector,
  docsTabUI => docsTabUI.selectedDocId
);
