import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import number from "numeral";

import TextField from "../TextField";

const NumberFormatCustom = props => {
  const { inputRef, onChange, maxLength, ...other } = props;
  return (
    <NumberFormat
      {...other}
      maxLength={maxLength || 20}
      getInputRef={inputRef}
      onValueChange={(values, e) => {
        onChange(e.target.name, values.value);
      }}
      decimalScale={2}
      thousandSeparator
      prefix="$"
    />
  );
};

export const normalizeValue = value => {
  // if value is falsy including empty string, return null
  return value ? number(value).value() : null;
};

/**
 * CURRENCY_FIELD COMPONENT
 * @author rrive
 * Wrapper for Currency type field
 */
const CurrencyField = props => {
  const { name, onChange, onBlur, onKeyPress, InputProps, ...other } = props;

  // sanitize value
  const handleOnChange = (field, value) => {
    // Normalize value due to API not handling empty string
    // API treats value as double dataType
    const normalizedValue = normalizeValue(value);
    onChange(field, normalizedValue);
  };

  return (
    <TextField
      name={name}
      onChange={handleOnChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      InputProps={{
        ...InputProps,
        inputComponent: NumberFormatCustom
      }}
      {...other}
    />
  );
};

CurrencyField.defaultProps = {
  onChange: null,
  onBlur: null,
  onKeyPress: null,
  InputProps: {}
};

CurrencyField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func
};

export default CurrencyField;
