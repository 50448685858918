"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _SvgIcon = _interopRequireDefault(require("@material-ui/core/SvgIcon"));

var _core = require("@material-ui/core");

var _loader = _interopRequireDefault(require("./styles/loader.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Loader
 * @param classes
 * @param size
 * @param speed
 * @param color
 * @param dataTestId
 * @param dataTestBuilder
 * @returns {*}
 * @constructor
 */


function Loader(_ref) {
  var classes = _ref.classes,
      size = _ref.size,
      speed = _ref.speed,
      color = _ref.color,
      dataTestId = _ref.dataTestId,
      dataTestBuilder = _ref.dataTestBuilder;
  var styleProps = {
    width: "".concat(size, "px"),
    height: "".concat(size, "px"),
    animationDuration: speed,
    fill: color
  };
  return _react.default.createElement("div", {
    "data-test": dataTestId && dataTestBuilder(dataTestId, "loader"),
    className: classes.root
  }, _react.default.createElement(_SvgIcon.default, {
    viewBox: "0 0 121 120",
    className: "logo",
    style: styleProps
  }, _react.default.createElement("circle", {
    id: "32",
    opacity: "0.35",
    cx: "42.5",
    cy: "4.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "31",
    opacity: "0.4",
    cx: "52.5",
    cy: "1.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "30",
    opacity: "0.45",
    cx: "63.5",
    cy: "0.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "29",
    opacity: "0.5",
    cx: "74.5",
    cy: "2.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "28",
    opacity: "0.55",
    cx: "84.5",
    cy: "5.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "27",
    opacity: "0.6",
    cx: "93.5",
    cy: "10.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "26",
    opacity: "0.65",
    cx: "101.5",
    cy: "16.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "25",
    opacity: "0.7",
    cx: "109.5",
    cy: "24.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "24",
    opacity: "0.75",
    cx: "114.5",
    cy: "33.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "23",
    opacity: "0.8",
    cx: "118.5",
    cy: "43.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "22",
    opacity: "0.85",
    cx: "120.5",
    cy: "53.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "21",
    opacity: "0.9",
    cx: "120.5",
    cy: "64.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "20",
    opacity: "0.95",
    cx: "119.5",
    cy: "74.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "19",
    cx: "115.5",
    cy: "84.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "18",
    cx: "110.5",
    cy: "93.5",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "17",
    cx: "104",
    cy: "101",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "16",
    cx: "95",
    cy: "109",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "15",
    cx: "86",
    cy: "114",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "14",
    cx: "76",
    cy: "117",
    r: "1"
  }), _react.default.createElement("circle", {
    id: "13",
    cx: "65.5",
    cy: "118.5",
    r: "1.5"
  }), _react.default.createElement("circle", {
    id: "12",
    cx: "55.5",
    cy: "118.5",
    r: "1.5"
  }), _react.default.createElement("circle", {
    id: "11",
    cx: "44.5",
    cy: "116.5",
    r: "1.5"
  }), _react.default.createElement("circle", {
    id: "10",
    cx: "35",
    cy: "113",
    r: "2"
  }), _react.default.createElement("circle", {
    id: "09",
    cx: "26",
    cy: "107",
    r: "2"
  }), _react.default.createElement("circle", {
    id: "08",
    cx: "19",
    cy: "100",
    r: "2"
  }), _react.default.createElement("circle", {
    id: "07",
    cx: "12.5",
    cy: "91.5",
    r: "2.5"
  }), _react.default.createElement("circle", {
    id: "06",
    cx: "7.5",
    cy: "81.5",
    r: "2.5"
  }), _react.default.createElement("circle", {
    id: "05",
    cx: "4",
    cy: "71",
    r: "3"
  }), _react.default.createElement("circle", {
    id: "03",
    cx: "3.5",
    cy: "50.5",
    r: "3.5"
  }), _react.default.createElement("circle", {
    id: "02",
    cx: "6",
    cy: "40",
    r: "4"
  }), _react.default.createElement("circle", {
    id: "01",
    cx: "11",
    cy: "31",
    r: "2"
  }), _react.default.createElement("path", {
    d: "M3,64 C4.65685425,64 6,62.6568542 6,61 C6,59.3431458 4.65685425,58 3,58 C1.34314575,58 0,59.3431458 0,61 C0,62.6568542 1.34314575,64 3,64 Z",
    id: "04"
  }), _react.default.createElement("path", {
    d: "M17.4958376,11.3338589 L1,16.6656366 L15.5769425,24.4198145 L19.8726035,27.054487 L16.382379,25.7246642 L23.3336907,39 L28.6658048,22.5052027 L34,6 L17.4958376,11.3338589 Z M10,17.3128903 L27,12 L17.6818349,21 L10,17.3128903 Z",
    id: "Icon"
  })));
}

Loader.defaultProps = {
  size: 100,
  speed: "1800ms",
  color: null
};
Loader.propTypes = {
  classes: _propTypes.default.objectOf(_propTypes.default.string).isRequired,
  size: _propTypes.default.number,
  speed: _propTypes.default.string,
  color: _propTypes.default.string
};

var _default = (0, _core.withStyles)(_loader.default)(Loader);

exports.default = _default;