import * as actionTypes from "./liabilities.action.types";

// ADD
export const addClientLiability = (
  clientId,
  applicationId,
  data,
  formCacheId
) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_ADD,
  clientId,
  applicationId,
  data,
  formCacheId
});

export const addClientLiabilitySuccess = (clientId, data) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_ADD_SUCCESS,
  clientId,
  data
});

export const addClientLiabilityFailure = (clientId, error) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_ADD_FAILURE,
  clientId,
  error
});

// DELETE

export const deleteClientLiability = (
  clientId,
  applicationId,
  liabilityId
) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_DELETE,
  clientId,
  applicationId,
  liabilityId
});

export const deleteClientLiabilitySuccess = (clientId, liabilityId) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_DELETE_SUCCESS,
  clientId,
  liabilityId
});

export const deleteClientLiabilityFailure = (clientId, error) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_DELETE_FAILURE,
  error
});

// UPDATE

export const updateClientLiability = (
  clientId,
  applicationId,
  liabilityId,
  values
) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE,
  clientId,
  applicationId,
  liabilityId,
  values
});

export const updateClientLiabilitySuccess = (clientId, liabilityId, data) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_SUCCESS,
  clientId,
  liabilityId,
  data
});

export const updateClientLiabilityFailure = (clientId, error) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_FAILURE,
  clientId,
  error
});

// CACHE

export const cacheAddClientLiability = data => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_ADD_CACHE,
  data
});

export const cleanAddCacheLiability = (clientId, index) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_ADD_CACHE_CLEAN,
  id: clientId,
  index
});

export const cancelEditLiabilityForm = (clientId, liabilityId) => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_CANCEL_EDIT_FORM,
  clientId,
  liabilityId
});

export const cacheUpdateClientLiability = data => ({
  type: actionTypes.LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_CACHE,
  data
});

export const cacheEditLiabilityValues = (clientId, liabilityId, values) => ({
  type: actionTypes.LOAN_APPLICATION_LIABILITY_CACHE_FORM_VALUES,
  clientId,
  liabilityId,
  values
});
