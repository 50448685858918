import React from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import { styles } from "../styles/header.menu.styles";

function handleMenuItemClick(item, onMenuItemClick, menuClose) {
  onMenuItemClick(item);
  menuClose();
}

/**
 * ExpanderMenu
 * @param classes
 * @param menuList
 * @param anchorEl
 * @param menuOpen
 * @param menuClose
 * @param onMenuItemClick
 * @returns {*}
 * @constructor
 */
function ExpanderMenu({
  classes,
  menuList,
  anchorEl,
  menuOpen,
  menuClose,
  onMenuItemClick,
  dataTestId,
  dataTestBuilder
}) {
  return (
    <Menu
      id="detail-user-menu"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={menuClose}
      disableAutoFocusItem
    >
      {menuList &&
        menuList.map((item, index) => {
          return (
            <MenuItem
              key={index}
              data-test={
                dataTestBuilder &&
                dataTestBuilder(dataTestId, "menu-item", index)
              }
              onClick={e => {
                e.stopPropagation();
                handleMenuItemClick(item, onMenuItemClick, menuClose);
              }}
              className={classes.menuItem}
              disabled={item.disabled}
            >
              <Typography className={classes.itemText}>{item.label}</Typography>
            </MenuItem>
          );
        })}
    </Menu>
  );
}

ExpanderMenu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  menuClose: PropTypes.func.isRequired,
  menuList: PropTypes.array.isRequired,
  anchorEl: PropTypes.object.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func
};

export default withStyles(styles)(ExpanderMenu);
