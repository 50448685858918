"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoryCanvas = StoryCanvas;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function StoryCanvas(_ref) {
  var children = _ref.children;
  return _react.default.createElement("div", {
    style: {
      background: "#FFF",
      width: "80%",
      margin: "16px",
      padding: "16px"
    }
  }, children);
}

StoryCanvas.propTypes = {
  children: _propTypes.default.any
};