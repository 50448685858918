import React from "react";
import PropTypes from "prop-types";
import Statistics from "./components/statistics";
import Status from "./components/status";

/**
 * FORMAT OBJECT
 * @author Ryan Rivera
 * @param props
 * @constructor
 */
const FormatObject = ({ value, format, options, noValue, row }) => {
  let formattedObject;
  format = format ? format.toUpperCase() : "OBJECT";
  switch (format) {
    case "STATISTICS":
      formattedObject = (
        <Statistics value={value} noValue={noValue} list={options.list} />
      );
      break;

    case "STATUS":
      formattedObject = (
        <Status isEscalated={row.isEscalated} value={value} options={options} />
      );
      break;

    case "OBJECT":
      formattedObject = <span>{JSON.stringify(value)}</span>;
      break;

    default:
      formattedObject = <span>{noValue}</span>;
  }

  return formattedObject;
};

FormatObject.defaultProps = {
  options: {},
  noValue: "-",
  format: "OBJECT"
};

FormatObject.propTypes = {
  format: PropTypes.string,
  value: PropTypes.object.isRequired,
  options: PropTypes.object,
  noValue: PropTypes.string
};

export default FormatObject;
