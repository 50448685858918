import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../DetailExpander/styles/detail.expander.styles";
import DetailExpander from "../DetailExpander";

import DeleteDialog from "../AlertDialog";

class ThemedDetailExpander extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasHover: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.hasHover) return null;
    return {
      ...state,
      hasHover: true
    };
  }

  renderDeleteDialog = () => {
    const { i18n, deleteDialogProps } = this.props;
    const {
      title,
      message,
      agreeButtonText,
      disagreeButtonText,
      onAgree,
      isOpen,
      hasOkayButton,
      onDialogClose,
      hasMissingTitle,
      importance
    } = deleteDialogProps;

    return (
      <DeleteDialog
        title={title}
        message={message}
        agreeButtonText={i18n.t`${agreeButtonText}`}
        disagreeButtonText={i18n.t`${disagreeButtonText}`}
        onAgree={onAgree}
        isOpen={isOpen}
        hasOkayButton={hasOkayButton}
        onDialogClose={onDialogClose}
        hasMissingTitle={hasMissingTitle}
        importance={importance}
      />
    );
  };

  handleMouseEnter = e => {
    e.stopPropagation();
    this.setState({
      hasHover: true
    });
  };

  handleMouseLeave = e => {
    const { isDeletingIncome } = this.state;
    e.stopPropagation();
    if (!isDeletingIncome) {
      this.setState({ hasHover: false });
    }
  };

  render() {
    const { classes, deleteDialogProps, children } = this.props;
    const { hasHover } = this.state;
    // TODO investigate applying expander hover background without relying on mouse events
    return (
      <div className={classes.themedDetailExpander}>
        <DetailExpander
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          hasTwoColumns={false}
          {...this.props}
          hasHover={hasHover}
        >
          {children}
        </DetailExpander>
        {deleteDialogProps && this.renderDeleteDialog()}
      </div>
    );
  }
}

ThemedDetailExpander.defaultProps = {
  hasHover: false
};

ThemedDetailExpander.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  hasHover: PropTypes.bool
};

export default withStyles(styles)(ThemedDetailExpander);
