"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var MODEL_NAME = "PortalApplication";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  id: null,
  status: null,
  initialParams: [],
  loanAmount: null,
  loanPurpose: null,
  interestRate: null,
  term: null,
  referrerId: null,
  referrer: {},
  agencyName: null,
  inActiveBankruptcy: null,
  bankTokens: [],
  termsAndConditionsConsent: null,
  creditPullConsent: null,
  newsletterConsent: null,
  decisionAction: {}
};
/**
 * Protects data from change in data type
 */

var schema = {
  id: _propTypes.default.string,
  status: _propTypes.default.string,
  initialParams: _propTypes.default.array,
  loanAmount: _propTypes.default.number,
  loanPurpose: _propTypes.default.string,
  interestRate: _propTypes.default.number,
  term: _propTypes.default.number,
  referrerId: _propTypes.default.string,
  referrer: _propTypes.default.object,
  agencyName: _propTypes.default.string,
  inActiveBankruptcy: _propTypes.default.bool,
  bankTokens: _propTypes.default.array,
  termsAndConditionsConsent: _propTypes.default.object,
  creditPullConsent: _propTypes.default.object,
  newsletterConsent: _propTypes.default.object,
  decisionAction: _propTypes.default.object
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
}; // Any "" values will be transformed to null


var transformSend = function transformSend(data) {
  var transformedData = {};
  Object.keys(data).forEach(function (field) {
    if (field !== "referrer" && field !== "currentScreen" && field !== "decisionExpirationDate") {
      transformedData[field] = (0, _model.nullify)(data[field]);
    }
  });
  return transformedData;
};

var _default = {
  defaultProps: defaultProps,
  schema: schema,
  transformReceived: transformReceived,
  transformSend: transformSend
};
exports.default = _default;