"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");

var _Score = require("../Score");

var MODEL_NAME = "DecisionAction";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  clientId: null,
  createdAt: null,
  decision: {},
  decisionSteps: [],
  forPrimary: null,
  newStatus: null,
  riskAssessmentCode: null,
  score: _Score.model.defaultProps
};
/**
 * Protects data from change in data type
 */

var schema = {
  clientId: _propTypes.default.string,
  createdAt: _propTypes.default.string,
  decision: _propTypes.default.shape({}),
  decisionSteps: _propTypes.default.array,
  forPrimary: _propTypes.default.bool,
  newStatus: _propTypes.default.string,
  riskAssessmentCode: _propTypes.default.string,
  score: _propTypes.default.shape(_Score.model.schema)
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
};

var _default = {
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;