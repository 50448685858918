import React from "react";
import Loadable from "react-loadable";

export default Loadable.Map({
  loader: {
    FundedLoanDetails: () => import("./funded.loan.details.index")
  },
  render(loaded, props) {
    const FundedLoanDetails = loaded.FundedLoanDetails.default;
    return <FundedLoanDetails {...props} />;
  },
  loading: () => <div />
});
