import find from "lodash/find";

import {
  NOTE_DRAFT_CREATE,
  NOTE_DRAFT_UPDATE,
  NOTE_DRAFT_DELETE,
  FUNDED_LOAN_NOTES_FETCH_SUCCESS,
  FUNDED_LOAN_NOTES_FETCH_FAILURE,
  NOTE_TAB_PRIORITY_UPDATE_SUCCESS,
  NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS
} from "../action.types/notes.action.types";

import { QUICK_NOTE_SUBMIT_SUCCESS } from "../action.types/quick.notes.action.types";

const handlers = {
  [NOTE_DRAFT_CREATE]: (state, action) => {
    const length = state.draftNotes.length;
    const draftNote = { ...action.data, index: length };
    return {
      ...state,
      draftNotes: [...state.draftNotes, draftNote]
    };
  },
  [NOTE_DRAFT_UPDATE]: (state, action) => {
    const updatedDraftNote = action.data;
    return {
      ...state,
      draftNotes: state.draftNotes.map((draftNote, i) => {
        if (updatedDraftNote.index === i) {
          return { ...draftNote, ...updatedDraftNote };
        }
        return draftNote;
      })
    };
  },
  [NOTE_DRAFT_DELETE]: state => {
    return {
      ...state,
      draftNotes: []
    };
  },
  [FUNDED_LOAN_NOTES_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      notes: action.data.results
    };
  },
  [FUNDED_LOAN_NOTES_FETCH_FAILURE]: state => {
    return state;
  },
  [NOTE_TAB_NEW_NOTE_SUBMIT_SUCCESS]: (state, action) => {
    const updatedNote = action.data;
    const noteExists = !!find(state.notes, { id: updatedNote.id });

    // Update if note exists
    if (noteExists) {
      return {
        ...state,
        notes: state.notes.map(note => {
          if (note.id === updatedNote.id) {
            return { ...note, ...updatedNote };
          }
          return note;
        }),
        draftNotes: []
      };
    }

    // add if note does not exist
    else {
      return {
        ...state,
        notes: [updatedNote, ...state.notes],
        draftNotes: []
      };
    }
  },
  [QUICK_NOTE_SUBMIT_SUCCESS]: (state, action) => {
    const updatedNote = action.data;
    return {
      ...state,
      notes: [updatedNote, ...state.notes]
    };
  },
  [NOTE_TAB_PRIORITY_UPDATE_SUCCESS]: (state, action) => {
    const updatedNote = action.data;
    return {
      ...state,
      notes: state.notes.map(note => {
        if (note.id === updatedNote.id) {
          return { ...note, ...updatedNote };
        }
        return note;
      })
    };
  }
};

const initialState = {
  notes: [],
  draftNotes: []
};

export default (state = initialState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action)
    : state;
};
