import { put, takeLatest, fork, call, all } from "redux-saga/effects";
import { http } from "../../../../services/http/http.service";
import {
  FUNDED_LOAN_DETAILS_PAYOFF_FETCH,
  FUNDED_LOAN_DETAILS_PAYMENTS_FETCH,
  FUNDED_LOAN_DETAILS_DOCS_FETCH
} from "../action.types/tabs.action.types";
import {
  fetchFundedLoanDetailsPayoffSuccess,
  fetchFundedLoanDetailsPayoffFailure,
  fetchFundedLoanDetailsPaymentsSuccess,
  fetchFundedLoanDetailsPaymentsFailure,
  fetchFundedLoanDetailsDocsSuccess,
  fetchFundedLoanDetailsDocsCompleted
} from "../actions/tabs.actions";
import {
  addSectionError,
  clearSectionError,
  APP_SECTIONS
} from "../../../../services/error.handler";
import { fundedLoanDetailsDocumentsReceived } from "../../../../models/funded/documents";
import {
  fundedLoanDetailsPayoffReceived,
  fundedLoanDetailsPayoffSent
} from "../../../../models/funded/payOff";
import {
  fundedLoanDetailsPaymentsReceived,
  fundedLoanDetailsPaymentsSent
} from "../../../../models/funded/payments";

/**
 * GET NOTES DATA STREAM
 * Get respective notes/comments based on the loan number
 * @param {object} action
 * @returns {IterableIterator<*>}
 */

/**
 * Fetching docs
 * @param action
 * @returns {IterableIterator<*>}
 */
export const fetchFundedLoanDetailsDocs = action => {
  return http({
    request: (httpSrv, API) => {
      const actionArray = [
        call(httpSrv.get, API.FUNDED_LOAN_NOTES(action.loanId)),
        call(httpSrv.get, API.FUNDED_CLIENT_NOTES(action.borrowerId))
      ];

      action.coBorrowerIdArray.forEach(id => {
        actionArray.push(call(httpSrv.get, API.FUNDED_CLIENT_NOTES(id)));
      });
      return all(actionArray);
    },
    success: data => [
      put(fetchFundedLoanDetailsDocsSuccess(data)),
      put(clearSectionError(APP_SECTIONS.FUNDED_DOCS))
    ],
    error: error => put(addSectionError(APP_SECTIONS.FUNDED_DOCS, error)),
    always: () => put(fetchFundedLoanDetailsDocsCompleted()),
    transformReceiveData: fundedLoanDetailsDocumentsReceived
  });
};

/**
 * Get pay off amount data
 * @param action
 * @returns {IterableIterator<*>}
 */
export const fetchFundedLoanDetailsPayoff = action =>
  http({
    request: (httpSrv, API) =>
      call(
        httpSrv.get,
        API.FUNDED_LOAN_PAYOFF_AMOUNT(action.loanId, action.date)
      ),
    success: data => put(fetchFundedLoanDetailsPayoffSuccess(data)),
    error: error => put(fetchFundedLoanDetailsPayoffFailure(error)),
    transformReceiveData: fundedLoanDetailsPayoffReceived,
    transformSendData: fundedLoanDetailsPayoffSent
  });

/**
 * Get payments data
 * @param action
 * @returns {IterableIterator<*>}
 */
export const fetchFundedLoanDetailsPayments = action =>
  http({
    request: (httpSrv, API) =>
      call(httpSrv.get, API.FUNDED_LOAN_PAYMENTS(action.loanId)),
    success: data => [
      put(fetchFundedLoanDetailsPaymentsSuccess(data)),
      put(clearSectionError(APP_SECTIONS.FUNDED_PAYMENTS))
    ],
    error: error =>
      all([
        put(fetchFundedLoanDetailsPaymentsFailure(error)),
        put(addSectionError(APP_SECTIONS.FUNDED_PAYMENTS, error))
      ]),
    // transformReceiveData: transformPaymentsReceived
    transformReceiveData: fundedLoanDetailsPaymentsReceived,
    transformSendData: fundedLoanDetailsPaymentsSent
  });

/**
 * Watch latest fetch ajax calls
 * @returns {IterableIterator<*|ForkEffect>}
 */
function* watchFundedLoanDetailsTabsAsync() {
  yield takeLatest(
    FUNDED_LOAN_DETAILS_PAYOFF_FETCH,
    fetchFundedLoanDetailsPayoff
  );
  yield takeLatest(
    FUNDED_LOAN_DETAILS_PAYMENTS_FETCH,
    fetchFundedLoanDetailsPayments
  );

  yield takeLatest(FUNDED_LOAN_DETAILS_DOCS_FETCH, fetchFundedLoanDetailsDocs);
}

export default [fork(watchFundedLoanDetailsTabsAsync)];
