import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

// React-datepicker & moment
import DatePicker from "react-datepicker";

// MATERIAL-UI COMPONENTS
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import Today from "@material-ui/icons/Today";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

// LOCAL STYLES
import styles from "./styles/alchemy.date.picker.styles";
import IconButton from "../../Buttons/IconButton";

class AlchemyDatePicker extends Component {
  root = document.getElementById("root");

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { dataTestId } = this.props;
    if (dataTestId) {
      this.inputRef.current.input.setAttribute("data-test", dataTestId);
    }
  }

  calendarContainer = props => ReactDOM.createPortal(props.children, this.root);

  handleOpenCalendar = () => {
    if (this.inputRef.current) {
      this.inputRef.current.deferFocusInput();
    }
  };

  handleOnChange = date => {
    const { onChange } = this.props;
    this.setState({ value: date }, () => {
      const { value } = this.state;
      if (onChange) onChange(value);
    });
  };

  normalizeDate = value => {
    if (!value) return null;
    return moment.isMoment(value) ? value : moment(value);
  };

  // On click outside of the DatePicker component
  // call the DatePicker component handleBlur
  // this fixes the issue of not being able to blur the field
  // when the calendar is open and the focus has been set
  // on the month and year fields
  blurInputField = () => {
    if (this.inputRef.current) {
      this.inputRef.current.handleBlur();
    }
  };

  handleOnClickOutside = () => {
    this.blurInputField();
  };

  handleOnSelect = () => {
    this.blurInputField();
  };

  getInputComponent = props => {
    return (
      <DatePicker
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={15}
        showMonthDropdown
        useShortMonthInDropdown
        ref={this.inputRef}
        dropdownMode="select"
        popperClassName="date-picker-popper"
        popperContainer={
          process.env.NODE_ENV !== "test" ? this.calendarContainer : undefined
        }
        popperPlacement="top-end"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "5px, 10px"
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
            boundariesElement: "viewport"
          }
        }}
        {...props}
      />
    );
  };

  render() {
    const {
      classes,
      dateFormat,
      disabled,
      fullWidth,
      onBlur,
      onFocus,
      name,
      placeholder,
      error,
      inputProps,
      value,
      readOnly
    } = this.props;

    const utcOffset = moment().isDST() ? -4 : -5;

    return (
      <Input
        name={name}
        inputComponent={this.getInputComponent}
        inputProps={{
          autoComplete: "off",
          selected: this.normalizeDate(value),
          onClickOutside: this.handleOnClickOutside,
          onSelect: this.handleOnSelect,
          utcOffset,
          dateFormat,
          ...inputProps
        }}
        readOnly={readOnly}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={this.handleOnChange}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        classes={{
          root: classes.inputRoot,
          focused: "focused",
          disabled: "disabled",
          error: "error",
          underline: classes.inputUnderline,
          fullWidth: classes.fullWidth
        }}
        endAdornment={
          <IconButton
            className={classes.calendarBtn}
            size="SM"
            disabled={disabled}
            onClick={this.handleOpenCalendar}
          >
            <Today className={classes.calendarIcon} />
          </IconButton>
        }
      />
    );
  }
}

AlchemyDatePicker.defaultProps = {
  dateFormat: "YYYY-MM-DD",
  disabled: false,
  fullWidth: false,
  placeholder: null,
  value: null,
  error: false,
  name: ""
};

AlchemyDatePicker.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.bool
};

export default withStyles(styles)(AlchemyDatePicker);
