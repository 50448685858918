import {
  CREATE_FORM_CACHE,
  FORM_CACHE,
  FORM_VALUES_CACHE,
  FORM_DELETE,
  FORM_FETCHING_STATUS_UPDATE,
  FORM_ERRORS_UPDATE
} from "./forms.actions.types";
import formsDetailReducer from "./forms.reducer.utils";

export default function formsReducer(state = [], action) {
  let exists;
  switch (action.type) {
    case CREATE_FORM_CACHE:
      exists = state.some(form => form.id === action.formId);
      if (!exists) {
        return [...state, formsDetailReducer(undefined, action)];
      }
      return state;
    case FORM_CACHE:
    case FORM_VALUES_CACHE:
    case FORM_FETCHING_STATUS_UPDATE:
    case FORM_ERRORS_UPDATE:
      exists = state.some(form => form.id === action.formId);
      if (exists) {
        return state.map(form => {
          if (form.id !== action.formId) {
            return form;
          }
          return formsDetailReducer(form, action);
        });
      } else {
        return [...state, formsDetailReducer(undefined, action)];
      }

    case FORM_DELETE:
      return state.filter(form => form.id !== action.formId);
    default:
      return state;
  }
}
