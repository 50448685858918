/**
 * EXPANDER STYLES
 * class name .rt-expander
 * @param theme
 * @author Ryan Rivera
 */
const styles = () => ({
  display: "inline-block",
  position: "relative",
  color: "transparent",
  margin: "0 10px",

  "&:after": {
    // content: "", // breaks in IE11... commented out for now
    position: "absolute",
    width: 0,
    height: 0,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(-90deg)",
    borderLeft: "5.04px solid transparent",
    borderRight: "5.04px solid transparent",
    borderTop: "7px solid rgba(0, 0, 0, 0.8)",
    transition: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
    cursor: "pointer"
  },

  "&.-open:after": {
    transform: "translate(-50%, -50%) rotate(0)"
  }
});

export default styles;
