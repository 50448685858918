import { utils } from "@cauldron/core";
import * as actionTypes from "./bank.reports.action.types";

const { makeActionCreator } = utils;

export const addBankReportsManual = makeActionCreator(
  actionTypes.LOAN_APPLICATION_BANK_REPORTS_MANUAL_ADD,
  "data",
  "appId",
  "clientId"
);

export const addBankReportsManualSuccess = makeActionCreator(
  actionTypes.LOAN_APPLICATION_BANK_REPORTS_MANUAL_ADD_SUCCESS,
  "data"
);

export const addBankReportsManualFailure = makeActionCreator(
  actionTypes.LOAN_APPLICATION_BANK_REPORTS_MANUAL_ADD_FAILURE,
  "data"
);
