import formControlStyles from "../../styles/form.controls.styles";

/**
 * TEXT_FIELD STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = theme => ({
  ...formControlStyles(theme),
  "text-align-left input": {
    textAlign: "left"
  },
  "text-align-right input": {
    textAlign: "right"
  },
  "text-align-center input": {
    textAlign: "center"
  }
});

export default styles;
