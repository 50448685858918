"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("./store.dev"));

var _store2 = _interopRequireDefault(require("./store.prod"));

var _constants = require("./constants");

var configureStore = process.env.NODE_ENV === _constants.PRODUCTION ? _store2.default : _store.default;
var _default = configureStore;
exports.default = _default;