import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import classNames from "classnames";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/Link";
import styles from "./styles/field.container.styles";

// UI-LIBRARY
import { AlchemyIconButton } from "../../ui.library/Buttons";
import DeleteDialog from "../../ui.library/AlertDialog";

// LOCATORS
import {
  EDIT,
  DELETE,
  COPY,
  WRAPPER
} from "../../pages/constants/data.test.ids";

/**
 * Field Container contains a list of Field Groups. It also includes the hover state and edit/delete button.
 * It is mainly being used in the client card for phones, emails, address, and auth users.
 */
class FieldContainer extends Component {
  state = {
    showDeleteDialog: false
  };

  handleDelete = () => {
    this.setState({ showDeleteDialog: true });
  };

  handleCloseDelete = () => {
    this.setState({ showDeleteDialog: false });
  };

  render() {
    const {
      classes,
      i18n,
      children,
      onEdit,
      onDelete,
      onCopy,
      disableHover,
      disableEdit,
      deleteDialogTitle,
      dataTestId,
      dataTestBuilder,
      className
    } = this.props;

    const { showDeleteDialog } = this.state;
    return (
      <div
        data-test={dataTestBuilder && dataTestBuilder(dataTestId, WRAPPER)}
        className={classNames([
          classes.fieldContainerWrapper,
          className.fieldContainerWrapper
        ])}
      >
        <div className={!disableHover ? classes.fieldContainer : ""}>
          <div className={classes.fieldContainerActions}>
            {onEdit && (
              <AlchemyIconButton
                dataTestId={
                  dataTestBuilder && dataTestBuilder(dataTestId, EDIT)
                }
                dataTestBuilder={dataTestBuilder}
                aria-label="Edit"
                onClick={onEdit}
                size="SM"
                disabled={disableEdit}
              >
                <EditIcon
                  className={disableEdit ? classes.iconDisabled : classes.icon}
                />
              </AlchemyIconButton>
            )}
            {onDelete && (
              <AlchemyIconButton
                dataTestId={
                  dataTestBuilder && dataTestBuilder(dataTestId, DELETE)
                }
                dataTestBuilder={dataTestBuilder}
                aria-label="Delete"
                onClick={this.handleDelete}
                size="SM"
              >
                <DeleteIcon className={classes.icon} />
              </AlchemyIconButton>
            )}
            {onCopy && (
              <AlchemyIconButton
                dataTestId={
                  dataTestBuilder && dataTestBuilder(dataTestId, COPY)
                }
                dataTestBuilder={dataTestBuilder}
                aria-label="Copy"
                onClick={onCopy}
                size="SM"
              >
                <CopyIcon className={classes.icon} />
              </AlchemyIconButton>
            )}
          </div>
          <div
            className={classNames([
              classes.fieldContainerBody,
              className.fieldContainerBody
            ])}
          >
            {children}
          </div>
        </div>
        {showDeleteDialog && onDelete && (
          <DeleteDialog
            title={deleteDialogTitle ? deleteDialogTitle : ""}
            message={i18n.t`Deleting this information will permanently remove it from the system.`}
            agreeButtonText={i18n.t`Delete`}
            disagreeButtonText={i18n.t`Cancel`}
            onAgree={onDelete}
            isOpen={showDeleteDialog}
            hasOkayButton
            onDialogClose={this.handleCloseDelete}
          />
        )}
      </div>
    );
  }
}

FieldContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onCopy: PropTypes.func,
  disableHover: PropTypes.bool,
  disableEdit: PropTypes.bool,
  deleteDialogTitle: PropTypes.string,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func,
  className: PropTypes.object
};

FieldContainer.defaultProps = {
  dataTestId: "",
  className: {
    fieldContainerBody: "",
    fieldContainerWrapper: ""
  }
};

const enhance = compose(
  withI18n(),
  withStyles(styles)
);

export default enhance(FieldContainer);
