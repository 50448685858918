/**
 * FUNDED LOANS DATA STATE REDUCER
 * @author Ryan Rivera
 * @type {{results: Array}}
 */
import {
  FUNDED_LOANS_SEARCH_SUCCESS,
  FUNDED_LOANS_SEARCH_FAILURE,
  FUNDED_LOANS_CLEAR
} from "../funded.loans.action.types";

const initialState = { results: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case FUNDED_LOANS_SEARCH_SUCCESS:
      return { ...action.data };
    case FUNDED_LOANS_SEARCH_FAILURE:
      return initialState;
    case FUNDED_LOANS_CLEAR:
      return initialState;
    default:
      return state;
  }
};
