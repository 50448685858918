"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _styles = require("@material-ui/core/styles");

var _logo = require("./styles/logo.styles");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function Logo(_ref) {
  var classes = _ref.classes,
      onClick = _ref.onClick,
      history = _ref.history,
      className = _ref.className,
      size = _ref.size,
      backgroundColor = _ref.backgroundColor;

  function handleOnClick(e) {
    if (onClick) {
      onClick(e);
      return;
    }

    if (history) {
      history.push("/");
    }
  }

  return _react.default.createElement("div", {
    className: className.root
  }, _react.default.createElement("div", {
    "data-test": "progressa-logo",
    role: "presentation",
    onClick: handleOnClick,
    className: (0, _classnames.default)(classes.logo, className.logo, classes[size], classes[backgroundColor])
  }));
}

Logo.defaultProps = {
  className: {
    root: "",
    logo: ""
  },
  size: "medium",
  backgroundColor: "blue",
  onClick: null
};
Logo.propTypes = {
  classes: _propTypes.default.objectOf(_propTypes.default.string).isRequired,
  history: _propTypes.default.objectOf(_propTypes.default.any),
  className: _propTypes.default.shape({
    root: _propTypes.default.string,
    logo: _propTypes.default.string
  }),
  size: _propTypes.default.oneOf(["small", "medium", "large", "xlarge", "xxlarge"]),
  backgroundColor: _propTypes.default.oneOf(["blue", "white"]),
  onClick: _propTypes.default.func
};

var _default = (0, _styles.withStyles)(_logo.styles)(Logo);

exports.default = _default;