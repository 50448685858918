/**
 * ALCHEMY TABLE SORT BUTTON STYLES
 * @param theme
 * @author Ryan Rivera
 */
const styles = theme => ({
  header: {
    display: "flex",
    borderBottom: "2px solid " + theme.palette.lightGrey[300],
    padding: "7px 0",
    justifyContent: "space-between",
    "&.text-align-right .header-sort-icon": {
      order: -1
    },
    "& .header-label": {
      lineHeight: "16px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    "& .header-sort-icon": {
      opacity: 0,
      width: "16px",
      height: "16px",
      position: "relative",

      "& .icon-desc, & .icon-asc": {
        opacity: 0,
        position: "absolute",
        top: "-2px",
        right: 0,
        width: "20px",
        height: "20px"
      }
    }
  },
  sortIcon: {
    fontSize: "20px",
    textIndent: "2px",
    color: theme.palette.grey[900],
    transition: "0.2s ease",
    transitionProperty: "color",

    "&:hover": {
      color: theme.palette.primary["light"]
    }
  }
});

export default styles;
