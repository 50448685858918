import React, { Fragment } from "react";
import GoogleLogin from "react-google-login";
import { utils } from "@cauldron/core";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles/index";
import { Trans } from "@lingui/macro";
import log from "loglevel";
import { styles } from "../styles/login.styles";
import Loader from "../../../ui.library/Loader";
import { REACT_APP_GOOGLE_OAUTH_CLIENT_ID } from "../../../core/constants/env";
import {
  LOGIN_PAGE,
  LOGO,
  CONTENT,
  SUBMIT
} from "../../constants/data.test.ids";

const { generateTestIds } = utils;
const logger = log.getLogger("login.component");

export class LoginComponent extends React.Component {
  /**
   * This constructor function is a default convention,
   * for now we don't have any local states need to bet set
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSuccess = response => {
    const { authenticateUser } = this.props;
    logger.log("Successfully receive response from Google.");
    authenticateUser(response);
  };

  onFailure = () => {
    logger.log("Failure on receiving response from Google.");
    return true;
  };

  render() {
    const { classes, isFetching, token, profile } = this.props;
    if (!isFetching && !token && !profile) {
      return (
        <Fragment>
          <div
            data-test={generateTestIds(LOGIN_PAGE, LOGO)}
            className={classes.logoDiv}
          >
            <div className={classes.logo} />
          </div>
          <div className={classes.loginForm}>
            <Typography
              data-test={generateTestIds(LOGIN_PAGE, CONTENT)}
              id="login-text"
              className={classes.loginText}
            >
              <Trans id="Employee sign in" />
            </Typography>
            <div data-test={generateTestIds(LOGIN_PAGE, SUBMIT)}>
              <GoogleLogin
                className={classes.loginButton}
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}
                clientId={REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
              >
                <Typography classes={{ root: classes.rootSignIn }}>
                  <Trans id="Sign in" />
                </Typography>
              </GoogleLogin>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <Loader size={75} thickness={3.5} centerScreen />;
    }
  }
}

export default withStyles(styles)(LoginComponent);
