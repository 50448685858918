import isNil from "lodash/isNil";
import {
  ERROR_MESSAGES,
  HTTP_STATUS_ERROR_CODES,
  HTTP_SUB_ERROR_CODES
} from "./messenger.constants";

const ERROR_MESSAGE_BY_CODE = {
  "-1": ERROR_MESSAGES.DEFAULT,
  ...HTTP_STATUS_ERROR_CODES,
  ...HTTP_SUB_ERROR_CODES
};

export const getMessageType = code => {
  const codeWarnings = [9001];
  return codeWarnings.indexOf(code) > -1 ? "warning" : "error";
};

export const getDefaultMessage = error => {
  let str = ERROR_MESSAGES.DEFAULT;

  if (error && error.response && error.response.statusText) {
    str = error.response.statusText;
  } else if (error.message) {
    str = error.message;
  }

  return str;
};

/**
 * EXTRACT ERROR MESSAGE
 * extract error message and returns a consistent error object
 * containing translated message and related error messages if any
 * @author Ryan Rivera
 * @param error
 * @returns {*}
 */
export const extractErrorMsg = error => {
  const errorMsg = {
    // main error message
    message: getDefaultMessage(error),
    // http response
    status: "-1",
    // plus multiple error messages to handle
    // on a case by case basis inside the reducer
    // as the UI is designed to show only 1 error message
    related: [],
    // new
    messages: []
  };

  if (error && error.response) {
    let dataErrors = [];
    if (error.response.data) {
      dataErrors = error.response.data.errors;
    }
    if (!isNil(errorMsg.status)) {
      errorMsg.status = error.response.status;
    }

    // Handle multiple error messages
    if (dataErrors && dataErrors.length > 1) {
      errorMsg.message = ERROR_MESSAGES.DEFAULT_MULTIPLE;
      errorMsg.related = errorMsg.related.concat(dataErrors);
      errorMsg.messages = dataErrors.map(({ code, message }) => {
        return {
          code: !isNil(code) ? code : errorMsg.status,
          message:
            ERROR_MESSAGE_BY_CODE[code] ||
            ERROR_MESSAGE_BY_CODE[errorMsg.status] ||
            message
        };
      });
    }

    // Handle single error message
    else if (dataErrors && dataErrors.length === 1) {
      // Determine status code
      let statusCode = null;

      if (error.response && !isNil(error.response.status)) {
        statusCode = error.response.status;
        errorMsg.status = error.response.status;
      } else if (
        error.response &&
        error.response.request &&
        !isNil(error.response.status)
      ) {
        statusCode = error.response.request.status;
      }

      const errorCode = dataErrors[0].code;
      if (!isNil(errorCode)) {
        errorMsg.code = errorCode;
      }

      // Use error msg derived from sub error code if exists
      if (!isNil(errorCode) && ERROR_MESSAGE_BY_CODE[errorCode]) {
        errorMsg.message = ERROR_MESSAGE_BY_CODE[errorCode];
        errorMsg.type = getMessageType(errorCode);
        errorMsg.messages = [
          { code: errorCode, message: ERROR_MESSAGE_BY_CODE[errorCode] }
        ];
      }

      // Use error msg derived from status code if error code does not exist
      else if (!isNil(statusCode) && ERROR_MESSAGE_BY_CODE[statusCode]) {
        errorMsg.message = ERROR_MESSAGE_BY_CODE[statusCode];
        errorMsg.messages = [
          { code: statusCode, message: ERROR_MESSAGE_BY_CODE[statusCode] }
        ];
      }

      // Else just grab the error message directly from service response
      else {
        errorMsg.message = dataErrors[0].message;
        errorMsg.messages = [
          { code: errorCode, message: dataErrors[0].message }
        ];
      }
    }

    // If there are no errors returned by the service, just use the default message
    else {
      errorMsg.messages = [{ code: 0, message: errorMsg.message }];
    }
  }
  // if there are no error messages, extract status text
  // the statusText now becomes the default error message
  else {
    errorMsg.messages = [{ code: 0, message: errorMsg.message }];
  }

  return errorMsg;
};
