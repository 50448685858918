const styles = theme => ({
  fieldWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "12px 0 20px 0" // 36px(design specs) - 16px(default expander content margin)
  },
  fieldSubTitleWrapper: {
    display: "flex",
    paddingTop: "8px"
  },
  fieldTitle: theme.typography.h7Dark,
  fieldLabel: {
    ...theme.typography.p3Dark,
    paddingRight: "12px"
  },
  fieldLabelNegative: Object.assign({}, theme.typography.p3Dark, {
    color: theme.palette.error.main,
    paddingRight: "12px"
  }),
  fieldMessage: theme.typography.p3Dark
});

export default styles;
