import { services, API } from "@cauldron/core";
import { call, put, all, select } from "redux-saga/effects";
import {
  REACT_APP_API_ROOT,
  REACT_APP_GOOGLE_OAUTH_CLIENT_ID
} from "../../core/constants/env";
import { getToken, getProfile, getExpiry } from "../auth/auth.selectors";
import {
  forceLogoutUser,
  logoutUser,
  refreshAuthUser
} from "../auth/auth.actions";
import { extractErrorMsg, messengerActions } from "../messenger";
import { UNDEFINED_REQUEST } from "./http.error.messages";

const { authentication, http: httpService, oauth, loglevel } = services;

const log = loglevel.create("HTTP.Service");

const { reloadAuthResponse } = oauth.google;

/**
 * handleHttpErrors
 * @param sagaErrorHandler
 * @param e
 * @param context
 * @returns {IterableIterator<*>}
 */
function* handleHttpErrors(e, context, sagaErrorHandler) {
  const errorMessage = extractErrorMsg(e);

  // Check if an error handler function is provided in saga
  // If provided, execute the error handler
  if (sagaErrorHandler && typeof sagaErrorHandler === "function") {
    log.debug("yield to error handler function provided in Saga");
    yield sagaErrorHandler(errorMessage, e, context);
  }

  // If error handler is not provided,
  // Handle all other http errors, by showing an error toast
  log.debug("yield to error messenger action", errorMessage.message);
  yield put(messengerActions.showError(errorMessage.message));
}

/**
 * SAGA LOGIC
 *
 * Saga logic is a function generator convenience method.
 * It abstracts the try/catch of saga function generator in order to
 * handle the common use case for a saga.
 *
 * @param request
 * @param success
 * @param error
 * @param always
 * @param transformSendData function
 * @param transformReceiveData function
 * @param data object
 * @param context
 * @returns {IterableIterator<*>}
 */
function* http({
  request,
  success,
  error,
  always,
  transformSendData,
  transformReceiveData,
  data,
  ...context
}) {
  try {
    if (!request) {
      throw Error(UNDEFINED_REQUEST);
    }

    let token = yield select(getToken);

    // :: ******************* ::

    // Check for valid profile
    // Logout if invalid (i.e. empty profile)
    const profile = yield select(getProfile);
    if (!profile) {
      log.debug("No profile found, forcing user logout");
      yield put(forceLogoutUser());
      return { success: false, data: null };
    }

    // Check for token expiry
    const expiry = yield select(getExpiry);
    const isTokenExpired = authentication.checkSessionExpiry(expiry, 5); // 5min tolerance

    // If token is close to expiration past tolerance,
    // re-authenticate with auth mechanism
    if (isTokenExpired) {
      log.debug("Token expired, reloadAuthResponse and refreshAuthUser");
      const googleResponse = yield call(
        reloadAuthResponse,
        {
          client_id: REACT_APP_GOOGLE_OAUTH_CLIENT_ID
        },
        {
          error: function*() {
            return yield put(logoutUser());
          }
        }
      );

      token = googleResponse.id_token;

      yield put(refreshAuthUser(googleResponse));
    } else {
      log.debug("Token is still good, processing service call...");
    }

    // :: ******************* ::

    // Handle sending data
    // TODO: transformSendData is not working as expected currently. Need to investigate further
    const sendData = transformSendData ? transformSendData(data) : data;

    const config = {
      token,
      methods: ["request", "get", "put", "patch", "post", "delete"],
      baseURL: REACT_APP_API_ROOT
    };
    const httpSvc = httpService.create(config, log);

    // Handle request
    const response = Array.isArray(request)
      ? yield all(request(httpSvc, API, sendData, context))
      : yield request(httpSvc, API, sendData, context);

    // Handle received data
    const responseData = Array.isArray(response)
      ? response.map(r => (r && r.data ? r.data : null))
      : response.data;

    const transformedResponseData = transformReceiveData
      ? transformReceiveData(responseData)
      : responseData;

    if (!transformReceiveData && process.env.NODE_ENV === "development") {
      log.warn("transformReceiveData is not implemented for: " + request);
    }

    if (success && typeof success === "function") {
      const successResponse = success(
        transformedResponseData,
        response,
        context
      );
      // successResponse can be a saga effect or an array of saga effects
      //TODO: Ryan (remove all effect from http service - success)
      Array.isArray(successResponse)
        ? yield all(successResponse)
        : yield successResponse;
    }

    return {
      success: true,
      data: transformedResponseData
    };
  } catch (e) {
    const profile = yield select(getProfile);

    if (process.env.NODE_ENV !== "test") {
      if (window.bugsnagClient && profile) {
        window.bugsnagClient.user = {
          email: profile.email
        };
      }
      const errMsgLog =
        httpService && httpService.createErrorLog
          ? httpService.createErrorLog(e)
          : e;
      log.error(errMsgLog);
    }

    // Handle 401
    const status = e.response && e.response.status;
    const errorMessage = extractErrorMsg(e);

    if (status === 401) {
      yield handleHttpErrors(e, context, error);

      log.debug("yield to logoutUser");

      yield all([
        // Show error message specific to session timeout/un-authorized
        put(messengerActions.showError(errorMessage.message)),
        // Force log-out user
        put(forceLogoutUser())
      ]);
      return { success: false, data: extractErrorMsg(e) };
    }

    // Handle all other errors
    yield handleHttpErrors(e, context, error);
    return { success: false, data: extractErrorMsg(e) };
  } finally {
    // Always gets executed
    if (always && typeof always === "function") yield always(context);
  }
}

export { http };
