import { put, fork, takeLatest, call, select, all } from "redux-saga/effects";
import { domain } from "@cauldron/core";
import { push } from "react-router-redux";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_REFRESH,
  USER_LOGOUT,
  FORCED_USER_LOGOUT,
  PROFILE_REQUEST
} from "./auth.action.types";
import {
  fetchProfileSuccess,
  fetchProfileFailure,
  fetchContextProfileSuccess,
  fetchContextProfileFailure,
  refreshAuthUserSuccess,
  refreshAuthUserFailure,
  logoutUserSuccess,
  logoutUserFailure,
  authenticateUserSuccess,
  authenticateUserFailure,
  fetchPermissionsSuccess,
  fetchPermissionsFailure
} from "./auth.actions";
import { http } from "../http/http.service";
import { getPermissionDefinitions } from "./auth.selectors";
import {
  addSectionError,
  clearSectionError,
  clearAllSectionErrors,
  APP_SECTIONS
} from "../error.handler";

const { Profile, Permission } = domain;

/**
 * USER SAGAS
 * this file contains all related user sagas and its associated async calls
 * @author Ehsan
 * @version 0.1.1
 */

// defines user login data stream
export function* userLoginRequest(action) {
  try {
    yield all([
      put(authenticateUserSuccess(action.payload)),
      put(clearAllSectionErrors())
    ]);
  } catch (e) {
    yield put(authenticateUserFailure(e));
  }
}

export function* userLoginRefresh(action) {
  try {
    yield all([
      put(refreshAuthUserSuccess(action.payload)),
      put(clearAllSectionErrors())
    ]);
  } catch (e) {
    yield put(refreshAuthUserFailure(e));
  }
}

// user-based action to log out
export function* userLogoutRequest() {
  try {
    yield put(push("/"));
    yield put(logoutUserSuccess());
  } catch (e) {
    yield put(logoutUserFailure(e));
  }
}

// forced log out
export function* forceUserLogoutRequest() {
  try {
    yield all([put(logoutUserSuccess()), put(clearAllSectionErrors())]);
  } catch (e) {
    yield put(logoutUserFailure(e));
  }
}

export const permissionDefinitionsRequest = () =>
  http({
    request: (httpSrv, API) => call(httpSrv.get, API.PERMISSIONS),
    success: data => put(fetchPermissionsSuccess(data)),
    error: error => put(fetchPermissionsFailure(error)),
    transformReceiveData: Permission.collection.transformReceived
  });

/**
 * USER CONTEXT PROFILE REQUEST
 * @param api
 * @returns {IterableIterator<*>}
 */
export const userContextProfileRequest = api =>
  http({
    request: httpSrv => call(httpSrv.get, api),
    success: data => put(fetchContextProfileSuccess(data)),
    error: error => put(fetchContextProfileFailure(error)),
    transformReceiveData: Profile.model.transformReceived
  });

export const userProfileRequest = () =>
  http({
    request: function*(httpSrv, API) {
      const hasDefinitions = yield select(getPermissionDefinitions);
      if (!hasDefinitions) {
        yield permissionDefinitionsRequest();
      }
      return yield call(httpSrv.get, API.PROFILE);
    },
    success: data => [
      put(fetchProfileSuccess(data)),
      put(clearSectionError(APP_SECTIONS.DASHBOARD))
    ],
    error: error =>
      all([
        put(fetchProfileFailure(error)),
        put(addSectionError(APP_SECTIONS.DASHBOARD, error))
      ]),
    transformReceiveData: Profile.model.transformReceived
  });

// a watcher to listen to our request ‘start’ action
export function* watchUserLoginRequest() {
  yield takeLatest(PROFILE_REQUEST, userProfileRequest);
  yield takeLatest(USER_LOGIN_REQUEST, userLoginRequest);
  yield takeLatest(USER_LOGIN_REFRESH, userLoginRefresh);
  yield takeLatest(USER_LOGOUT, userLogoutRequest);
  yield takeLatest(FORCED_USER_LOGOUT, forceUserLogoutRequest);
}

// use 'fork' to start multiple sagas in the background
// they are always attached to their parent
export default [fork(watchUserLoginRequest)];
