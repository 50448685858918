export const PRINT_BUTTON = "print-button";
export const INVESTOR_LABEL = "investor-label";
export const INVESTOR_VALUE = "investor-value";
export const LOANS_LABEL = "loans-label";
export const LOANS_VALUE = "loans-value";
export const TOTAL_VALUE_LABEL = "total-value-label";
export const TOTAL_VALUE_VALUE = "total-value-value";
export const DATE_RANGE_LABEL = "date-range-label";
export const DATE_RANGE_VALUE = "date-range-value";

export const TRANCHE = "tranche";
export const TRANCHE_TYPE_LABEL = `${TRANCHE}-type-label`;
export const TRANCHE_TYPE_VALUE = `${TRANCHE}-type-value`;
export const TRANCHE_WARNING = `${TRANCHE}-warning`;
export const TRANCHE_ERROR_REPORT = `${TRANCHE}-error-report`;
export const TRANCHE_ERROR_TABLE = `${TRANCHE}-error-table`;
export const TRANCHE_ERROR_TABLE_HEADER = `${TRANCHE}-error-table-header`;
export const TRANCHE_TILE_CARD = `${TRANCHE}-tile-card`;
export const TRANCHE_TILE_CARD_TEXT = `${TRANCHE}-tile-card-text`;
export const TRANCHE_TILE_CARD_INPUT = `${TRANCHE}-tile-card-input`;

export const TRANCHE_MANAGEMENT = `${TRANCHE}-management`;
export const TRANCHE_MANAGEMENT_ROOT = `${TRANCHE_MANAGEMENT}-root`;
export const TRANCHE_MANAGEMENT_MAIN = `${TRANCHE_MANAGEMENT}-main`;
export const TRANCHE_MANAGEMENT_CLOSE_ICON = `${TRANCHE_MANAGEMENT}-close-icon`;
export const TRANCHE_MANAGEMENT_TILE = `${TRANCHE_MANAGEMENT}-title`;
export const TRANCHE_MANAGEMENT_TIMESTAMP = `${TRANCHE_MANAGEMENT}-timestamp`;
export const TRANCHE_MANAGEMENT_IMPORTED_BY = `${TRANCHE_MANAGEMENT}-imported-by`;
export const TRANCHE_MANAGEMENT_IMPORTED_BY_NAME = `${TRANCHE_MANAGEMENT}-imported-by-name`;
export const TRANCHE_MANAGEMENT_CANCEL = `${TRANCHE_MANAGEMENT}-cancel`;
export const TRANCHE_MANAGEMENT_IMPORT = `${TRANCHE_MANAGEMENT}-import`;
