/**
 * STATUS_ICON_STYLES
 * @author Ryan Rivera
 * @param theme
 */
const styles = theme => ({
  iconRoot: {
    fontSize: "16px",
    color: theme.palette.grey[900],
    margin: "auto 5px"
  },
  normal: {
    color: theme.palette.grey[900]
  },
  success: {
    color: theme.palette.success["main"]
  },
  warning: {
    color: theme.palette.warning["main"]
  },
  error: {
    color: theme.palette.error["main"]
  },
  disabled: {
    color: theme.palette.grey[600]
  }
});

export default styles;
