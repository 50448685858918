import PropTypes from "prop-types";

/**
 * SCORE MODEL
 */

export const defaultProps = {
  clientId: "",
  createdAt: "",
  decision: {
    decidingModelName: "",
    outcome: "APPROVE",
    outputs: {
      additionalProp1: "",
      additionalProp2: "",
      additionalProp3: ""
    },
    tags: []
  },
  newStatus: "",
  score: {
    family: "",
    outputs: {
      additionalProp1: "",
      additionalProp2: "",
      additionalProp3: ""
    },
    ratings: {},
    tags: [],
    vendor: "",
    version: ""
  }
};

// SCHEMA
export const schema = {
  clientId: PropTypes.string,
  createdAt: PropTypes.string,
  decision: PropTypes.object,
  newStatus: PropTypes.string,
  score: PropTypes.object
};

/**
 * MODEL NAME
 * @type {string}
 */
export const MODEL_NAME = "Application-scores";
