import React from "react";
import { Route } from "react-router-dom";
import Auth from "./auth.service";

export const AuthPrivateRoute = ({ component: Component, ...rest }) => {
  const AuthComponent = props => (
    <Auth Component={Component} {...props} {...rest} />
  );
  return <Route {...rest} render={AuthComponent} />;
};
