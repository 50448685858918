/**
 * CHECKBOX STYLES
 * @author stanleyli
 * @param theme {object}
 */
const styles = theme => ({
  formControl: {
    padding: 0
  },
  formControlLabel: {
    marginLeft: "-6px",
    "&:hover svg": {
      color: theme.palette.grey[900]
    },
    "&:active svg": {
      color: theme.palette.grey[900]
    }
  },
  formControlLabelDisabled: {
    marginLeft: "-6px"
  },
  checked: {
    "& svg": {
      color: theme.palette.grey[900]
    }
  },
  checkbox: {
    alignSelf: "flex-start",
    color: theme.palette.lightGrey[900],
    width: 28,
    height: 28,
    "& svg": {
      width: 20,
      height: 20
    }
  },
  disabledChecked: {
    color: theme.palette.lightGrey[900] + " !important"
  },
  checkboxSmall: {
    marginRight: "8px",
    alignSelf: "flex-start",
    color: theme.palette.lightGrey[900],
    width: 24,
    height: 24,
    "& svg": {
      width: 16,
      height: 16
    }
  },
  formLabel: theme.typography.p4Dark,
  helperText: {
    ...theme.typography.p4Disabled
  }
});

export default styles;
