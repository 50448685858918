const styles = theme => ({
  set: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
    "& > *": {
      margin: "0 2.5em 4px 0",
      minHeight: "66px",
      maxWidth: "252px"
    }
  },
  centered: {
    justifyContent: "center",
    "& > *": {
      margin: "0 0 4px 0",
      maxWidth: "252px"
    }
  },
  stacked: {
    flexDirection: "column",
    "& > *": {
      margin: "0 0 4px 0"
    }
  }
});

export default styles;
