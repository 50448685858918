"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insertStyleNode = insertStyleNode;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactJss = require("react-jss");

var _jss = require("jss");

var _styles = require("@material-ui/core/styles");

var _CssBaseline = _interopRequireDefault(require("@material-ui/core/CssBaseline")); // INCLUDE CSS
// import "./theme.main.css";

/**
 * customClassName prefix
 * @param rule
 * @param styleSheet
 * @returns {string}
 */


function customClassName(rule, styleSheet) {
  return "".concat(styleSheet.options.classNamePrefix, "-").concat(rule.key);
}
/**
 * insertStyleNode
 * @param document
 */


function insertStyleNode(document) {
  if (document) {
    var styleNode = document.createComment("jss-insertion-point");
    document.head.insertBefore(styleNode, document.head.lastChild);
  }
}
/**
 * withTheme HOC
 * https://material-ui-next.com/
 * @param theme
 * @param config
 * @returns {function(*): function(*): *}
 */


var withTheme = function withTheme(theme) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return function (WrappedComponent) {
    if (!theme) throw new Error("Missing theme"); // Create insertion point in the document head

    insertStyleNode(document); // DYNAMIC CSS STYLES GENERATION

    var generateClassName = config.customClassName || process.env.NODE_ENV === "test" ? customClassName : (0, _styles.createGenerateClassName)(); // We define a custom insertion point that JSS will look for injecting the styles in the DOM.

    var jss = (0, _jss.create)((0, _styles.jssPreset)());
    jss.options.insertionPoint = "jss-insertion-point";
    var muiTheme = (0, _styles.createMuiTheme)(theme);

    function ThemedApp(props) {
      return _react.default.createElement(_reactJss.JssProvider, {
        jss: jss,
        generateClassName: generateClassName
      }, _react.default.createElement(_styles.MuiThemeProvider, {
        theme: muiTheme
      }, _react.default.createElement(_CssBaseline.default, null), _react.default.createElement(WrappedComponent, props)));
    }

    return ThemedApp;
  };
};

var _default = withTheme;
exports.default = _default;