import { i18nMark } from "@lingui/react";

/**
 * PortalClientEmployment.maritalStatus
 * @type {*[]}
 */
export const MARITAL_STATUS_TYPES = [
  { label: i18nMark("Single"), value: "Single" },
  { label: i18nMark("Common law"), value: "CommonLaw" },
  { label: i18nMark("Married"), value: "Married" },
  { label: i18nMark("Separated"), value: "Separated" },
  { label: i18nMark("Divorced"), value: "Divorced" },
  { label: i18nMark("Widowed"), value: "Widowed" }
];

/**
 * PortalClientEmployment.residencyType
 * @type {*[]}
 */
export const RESIDENCY_TYPES = [
  { label: i18nMark("Rent"), value: "Rent" },
  { label: i18nMark("Own"), value: "Own" }
];

/**
 * PortalClientEmployment.preferredContactDays
 * @type {*[]}
 */
export const PREFERRED_DAY_TYPES = [
  {
    label: i18nMark("Mon"),
    value: "MONDAY",
    dataTestId: "monday"
  },
  {
    label: i18nMark("Tues"),
    value: "TUESDAY",
    dataTestId: "tuesday"
  },
  {
    label: i18nMark("Wed"),
    value: "WEDNESDAY",
    dataTestId: "wednesday"
  },
  {
    label: i18nMark("Thurs"),
    value: "THURSDAY",
    dataTestId: "thursday"
  },
  {
    label: i18nMark("Fri"),
    value: "FRIDAY",
    dataTestId: "friday"
  },
  {
    label: i18nMark("Sat"),
    value: "SATURDAY",
    dataTestId: "saturday"
  }
];

/**
 * PortalClientEmployment.startMonth
 * Uses base 1: Jan is 1, Feb is 2, Dec is 12, etc...
 * @type {*[]}
 */
export const MONTH_TYPES = [
  { label: i18nMark("January"), value: "1" },
  { label: i18nMark("February"), value: "2" },
  { label: i18nMark("March"), value: "3" },
  { label: i18nMark("April"), value: "4" },
  { label: i18nMark("May"), value: "5" },
  { label: i18nMark("June"), value: "6" },
  { label: i18nMark("July"), value: "7" },
  { label: i18nMark("August"), value: "8" },
  { label: i18nMark("September"), value: "9" },
  { label: i18nMark("October"), value: "10" },
  { label: i18nMark("November"), value: "11" },
  { label: i18nMark("December"), value: "12" }
];

/**
 * PortalClientEmployment.province
 * Province or territory using 2 character code, see ISO 3166-2.
 * @type {*[]}
 */
export const PROVINCE_TYPES = [
  {
    label: i18nMark("Alberta"),
    value: "AB",
    enabled: true
  },
  {
    label: i18nMark("British Columbia"),
    value: "BC",
    enabled: true
  },
  {
    label: i18nMark("Manitoba"),
    value: "MB",
    enabled: true
  },
  {
    label: i18nMark("New Brunswick"),
    value: "NB",
    enabled: true
  },
  {
    label: i18nMark("Newfoundland"),
    value: "NL",
    enabled: true
  },
  {
    label: i18nMark("Northwest Territories"),
    value: "NT",
    enabled: true
  },
  {
    label: i18nMark("Nova Scotia"),
    value: "NS",
    enabled: true
  },
  {
    label: i18nMark("Nunavut"),
    value: "NU",
    enabled: true
  },
  {
    label: i18nMark("Ontario"),
    value: "ON",
    enabled: true
  },
  {
    label: i18nMark("Prince Edward Island"),
    value: "PE",
    enabled: true
  },
  {
    label: i18nMark("Québec"),
    value: "QC"
  },
  {
    label: i18nMark("Saskatchewan"),
    value: "SK",
    enabled: true
  },
  {
    label: i18nMark("Yukon Territories"),
    value: "YT",
    enabled: true
  }
];

/**
 * PortalClientEmployment.payFrequency
 * @type {*[]}
 */
export const PAY_FREQUENCY_TYPES = [
  { label: i18nMark("Hourly"), value: "Hourly" },
  { label: i18nMark("Daily"), value: "Daily", enabled: true },
  { label: i18nMark("Weekly"), value: "Weekly", enabled: true },
  { label: i18nMark("Biweekly"), value: "BiWeekly", enabled: true },
  { label: i18nMark("Semimonthly"), value: "SemiMonthly", enabled: true },
  { label: i18nMark("Semimonthly 14 days"), value: "SemiMonthly14Days" },
  { label: i18nMark("Days 28"), value: "Days28" },
  { label: i18nMark("Monthly"), value: "Monthly", enabled: true },
  { label: i18nMark("Bimonthly"), value: "BiMonthly" },
  { label: i18nMark("Quarterly"), value: "Quarterly", enabled: true },
  { label: i18nMark("Triannually"), value: "TriAnnually" },
  { label: i18nMark("Semiannually"), value: "SemiAnnually", enabled: true },
  { label: i18nMark("Annually"), value: "Annually", enabled: true },
  { label: i18nMark("Single"), value: "Single" },
  { label: i18nMark("Lumpsum"), value: "LumpSum" },
  { label: i18nMark("Optional"), value: "Optional" },
  { label: i18nMark("Unknown"), value: "Unknown" }
];

/**
 * PortalClientEmployment.employmentStatus
 * @type {*[]}
 */
export const JOB_TYPES = [
  { label: i18nMark("Full time"), value: "FullTime" },
  { label: i18nMark("Part time"), value: "PartTime" },
  { label: i18nMark("Self employed"), value: "SelfEmployed" },
  { label: i18nMark("Student"), value: "Student" },
  { label: i18nMark("Homemaker"), value: "Homemaker" },
  { label: i18nMark("Pension"), value: "Pension" },
  { label: i18nMark("Disability benefit "), value: "DisabilityBenefit" },
  { label: i18nMark("Temporary"), value: "Temporary" },
  { label: i18nMark("Unemployed"), value: "Unemployed" }
];

/**
 * PortalClientEmployment.paymentMethod
 * @type {*[]}
 */
export const PAYMENT_METHOD_TYPES = [
  { label: i18nMark("Direct deposit"), value: "DirectDeposit" },
  { label: i18nMark("Cheque"), value: "Cheque" },
  { label: i18nMark("Cash"), value: "Cash" }
];
