import { fork, call, put, takeLatest, all } from "redux-saga/effects";
import { domain } from "@cauldron/core";
import { http } from "../../http/http.service";
import { actionTypes } from "./agent.portal.applications.action.types";
import {
  fetchApplicationSuccess,
  fetchApplicationFailure,
  createApplicationSuccess,
  createApplicationFailure,
  updateApplicationSuccess,
  updateApplicationFailure
} from "./agent.portal.applications.actions";

const { AgentPortalExtendedApplication, AgentPortalApplication } = domain;

export const createApplication = action => {
  const { payload, eventHandlers } = action;
  const { onSuccess } = eventHandlers;

  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.post, API.AGENT_PORTAL_APPLICATIONS, payload);
    },
    success: data =>
      all([put(createApplicationSuccess(data)), call(onSuccess, data)]),
    error: e => put(createApplicationFailure(e)),
    // AgentPortalApplication only contains application object
    transformReceiveData: AgentPortalApplication.model.transformReceived
  });
};

export const fetchApplication = action => {
  const { id } = action;
  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.get, API.AGENT_PORTAL_APPLICATION(id));
    },
    success: data => put(fetchApplicationSuccess(data)),
    error: e => put(fetchApplicationFailure(e)),
    // AgentPortalExtendedApplication only contains application and applicant object
    transformReceiveData: AgentPortalExtendedApplication.model.transformReceived
  });
};

export const updateApplication = action => {
  const { id, payload, eventHandlers } = action;
  const { onSuccess } = eventHandlers;

  return http({
    request: (httpSrv, API) => {
      return call(httpSrv.patch, API.AGENT_PORTAL_APPLICATION(id), payload);
    },
    success: data =>
      all([put(updateApplicationSuccess(data)), call(onSuccess, data)]),
    error: e => put(updateApplicationFailure(e)),
    // AgentPortalApplication only contains application object
    transformReceiveData: AgentPortalApplication.model.transformReceived
  });
};

export function* watchClientsAsync() {
  yield takeLatest(actionTypes.APPLICATION_CREATE_REQUEST, createApplication);
  yield takeLatest(actionTypes.APPLICATION_FETCH_REQUEST, fetchApplication);
  yield takeLatest(actionTypes.APPLICATION_UPDATE_REQUEST, updateApplication);
}

export default [fork(watchClientsAsync)];
