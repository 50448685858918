"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils");
/**
 * Model name
 */


var MODEL_NAME = "ReferralSource";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  abbreviation: null,
  companyId: null,
  id: null,
  name: null,
  sourceType: null
};
/**
 * Protects data from change in data type
 */

var schema = {
  abbreviation: _propTypes.default.string,
  companyId: _propTypes.default.string,
  id: _propTypes.default.string,
  name: _propTypes.default.string,
  sourceType: _propTypes.default.string
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  return (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME);
}; // Any "" values will be transformed to null


var transformSend = function transformSend(data) {
  return data;
};

var _default = {
  transformReceived: transformReceived,
  transformSend: transformSend
};
exports.default = _default;