import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles/upload.button.styles";
import { TextButton } from "../index";

function UploadButton({
  classes,
  inputId,
  acceptTypes,
  onUpload,
  buttonText,
  dataTestId,
  dataTestBuilder,
  children
}) {
  return (
    <Fragment>
      <input
        id={inputId}
        accept={acceptTypes}
        className={classes.uploadButtonInput}
        type="file"
        onChange={onUpload}
      />
      <label htmlFor={inputId}>
        {!children && (
          <TextButton
            component="span"
            importance="PRIMARY"
            buttonText={buttonText}
            dataTestId={
              dataTestBuilder && dataTestBuilder(dataTestId, "upload-button")
            }
          />
        )}
        {children && <Fragment>{children}</Fragment>}
      </label>
    </Fragment>
  );
}

UploadButton.propTypes = {
  classes: PropTypes.object.isRequired,
  inputId: PropTypes.string.isRequired,
  acceptTypes: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  dataTestBuilder: PropTypes.func,
  children: PropTypes.node
};

export default withStyles(styles)(UploadButton);
