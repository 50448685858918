import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// MATERIAL-UI COMPONENTS
import { withStyles } from "@material-ui/core/styles";
import MUISnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { AlchemyIconButton } from "../../Buttons";

import styles from "../styles/snackbar.styles";

const SnackbarContent = props => {
  const {
    classes,
    message,
    type,
    closeSnackbar,
    dataTestId = "client-snackbar",
    dataTestBuilder,
    ...rest
  } = props;
  const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
  };
  const Icon = variantIcon[type];
  const contentId = dataTestBuilder && dataTestBuilder(dataTestId, "content");
  const contentIconId = dataTestBuilder && dataTestBuilder(contentId, "icon");
  return (
    <MUISnackbarContent
      key={message}
      aria-describedby={dataTestId}
      className={classNames(classes[type], classes.snackbarContent)}
      message={
        <span id={contentId} data-test={contentId} className={classes.message}>
          <Icon
            className={classNames(classes.icon, classes.iconVariant)}
            data-test={contentIconId}
          />
          {message}
        </span>
      }
      action={[
        <AlchemyIconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={closeSnackbar}
          size="MD"
          dataTestId={dataTestBuilder && dataTestBuilder(dataTestId, "close")}
          dataTestBuilder={dataTestBuilder}
        >
          <CloseIcon className={classes.icon} />
        </AlchemyIconButton>
      ]}
      {...rest}
    />
  );
};

SnackbarContent.defaultProps = {
  message: "",
  type: "info"
};

SnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  message: PropTypes.string,
  type: PropTypes.string
};

export default withStyles(styles)(SnackbarContent);
