import { put, call, fork, takeLatest, all } from "redux-saga/effects";
import log from "loglevel";
import { http } from "../../../../services/http/http.service";
import * as messengerActions from "../../../../services/messenger/messenger.actions";
import {
  FUNDED_LOAN_NOTES_FETCH,
  NOTE_NEW_NOTE_SUBMIT,
  NOTE_PRIORITY_UPDATE
} from "../action.types/notes.action.types";
import { QUICK_NOTE_SUBMIT } from "../action.types/quick.notes.action.types";

import {
  addNewNoteSuccess,
  addNewNoteFailure,
  fetchFundedLoanDetailsSuccess,
  fetchFundedLoanDetailsFailure,
  updateNotePrioritySuccess,
  updateNotePriorityFailure
} from "../actions/notes.actions";

import {
  submitQuickNoteSuccess,
  submitQuickNoteFailure
} from "../actions/quick.notes.actions";

import {
  fundedCommentReceived,
  fundedCommentsReceived,
  fundedCommentSent
} from "../../../../models/funded/comment";
import {
  addSectionError,
  clearSectionError,
  APP_SECTIONS
} from "../../../../services/error.handler";

const logger = log.getLogger("note.saga");

/**
 * Add new note
 * @param draft note data
 * @returns {IterableIterator<*>}
 */
export const submitDraft = action => {
  const { data, parentId, isLoan } = action;

  return http({
    request: (httpSrv, API, sendData) => {
      if (!isLoan) {
        logger.debug("post to client");
        return call(httpSrv.post, API.FUNDED_CLIENT_NOTES(parentId), sendData);
      } else {
        logger.debug("post to loan");
        return call(httpSrv.post, API.FUNDED_LOAN_NOTES(parentId), sendData);
      }
    },
    success: data => [
      put(
        addNewNoteSuccess({
          ...data,
          ...(isLoan ? { loanId: parentId } : { clientId: parentId }),
          isLoanComment: isLoan
        })
      ),
      put(messengerActions.showSuccess("Success"))
    ],
    error: error => [
      put(addNewNoteFailure(error)),
      put(messengerActions.showError("Failure"))
    ],
    data: data,
    transformSendData: fundedCommentSent,
    transformReceiveData: fundedCommentReceived
  });
};

export const submitQuickNote = action => {
  logger.debug("submitQuickNote", action);
  const { data, parentId, isLoan, name } = action;

  return http({
    request: (httpSrv, API, sendData) => {
      if (!isLoan) {
        logger.debug("post to client");
        return call(httpSrv.post, API.FUNDED_CLIENT_NOTES(parentId), sendData);
      } else {
        logger.debug("post to loan");
        return call(httpSrv.post, API.FUNDED_LOAN_NOTES(parentId), sendData);
      }
    },
    success: data => [
      put(
        submitQuickNoteSuccess({
          ...data,
          ...(isLoan ? { loanId: parentId } : { clientId: parentId }),
          name,
          isLoanComment: isLoan
        })
      ),
      put(messengerActions.showSuccess("Successfully added a quick note"))
    ],
    error: error => [
      put(submitQuickNoteFailure(error)),
      put(messengerActions.showError("Failed to add a quick note"))
    ],
    data: data,
    transformSendData: fundedCommentSent,
    transformReceiveData: fundedCommentReceived
  });
};

export const fetchFundedLoanDetailsNotes = action => {
  return http({
    request: (httpSrv, API) => {
      const actionArray = [
        // GET LOAN COMMENTS
        call(httpSrv.get, API.FUNDED_LOAN_NOTES(action.loanId)),
        // GET PRIMARY COMMENTS
        call(httpSrv.get, API.FUNDED_CLIENT_NOTES(action.borrowerId))
      ];

      // GET ALL CO-SIGNER COMMENTS
      action.coBorrowerIdArray.forEach(id => {
        actionArray.push(call(httpSrv.get, API.FUNDED_CLIENT_NOTES(id)));
      });
      return all(actionArray);
    },
    success: data => [
      put(fetchFundedLoanDetailsSuccess(data)),
      put(clearSectionError(APP_SECTIONS.FUNDED_NOTES))
    ],
    error: error =>
      all([
        put(fetchFundedLoanDetailsFailure(error)),
        put(addSectionError(APP_SECTIONS.FUNDED_NOTES, error))
      ]),
    transformReceiveData: fundedCommentsReceived
  });
};

export const updateCommentPriority = action => {
  const note = action.data;
  const { id: commentId } = note;
  const isLoan = !!note.loanId;
  const parentId = note.loanId ? note.loanId : note.clientId;

  return http({
    request: (httpSrv, API, sendData) => {
      const apiCall = note.isLoanComment
        ? API.FUNDED_LOAN_NOTE
        : API.FUNDED_CLIENT_NOTE;
      return call(httpSrv.put, apiCall(parentId, commentId), sendData);
    },
    success: data => {
      return [
        put(
          updateNotePrioritySuccess({
            ...data,
            ...(isLoan ? { loanId: parentId } : { clientId: parentId }),
            isLoanComment: note.isLoanComment
          })
        ),
        put(messengerActions.showSuccess("Success"))
      ];
    },
    error: error => [
      put(updateNotePriorityFailure(error)),
      put(messengerActions.showError("Failure"))
    ],
    data: note,
    transformSendData: fundedCommentSent,
    transformReceiveData: fundedCommentReceived
  });
};

/**
 * Watch latest fetch ajax calls
 * @returns {IterableIterator<*|ForkEffect>}
 */
function* watchFundedLoanDetailsTabsAsync() {
  yield takeLatest(QUICK_NOTE_SUBMIT, submitQuickNote);
  yield takeLatest(NOTE_NEW_NOTE_SUBMIT, submitDraft);
  yield takeLatest(FUNDED_LOAN_NOTES_FETCH, fetchFundedLoanDetailsNotes);
  yield takeLatest(NOTE_PRIORITY_UPDATE, updateCommentPriority);
}

export default [fork(watchFundedLoanDetailsTabsAsync)];
