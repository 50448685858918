import React from "react";
import PropTypes from "prop-types";
import { utils } from "@cauldron/core";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

// LOCAL STYLE
import styles from "../styles/sort.button.styles";

const { generateTestIds } = utils;

const getAlignment = options => {
  const alignmentLookup = {
    LEFT: "text-align-left",
    RIGHT: "text-align-right",
    CENTER: "text-align-center"
  };

  return options && options.align
    ? alignmentLookup[options.align.toUpperCase()]
    : "";
};

/**
 * ALCHEMY TABLE SORT BUTTON
 * @param label
 * @param classes
 * @param options
 * @returns {*}
 * @constructor
 */

const getOptions = (format, options) => {
  const modifiedOptions = {};
  switch (format) {
    case "PHONE":
      if (!options) modifiedOptions.align = "RIGHT";
      if (options) options.align = "RIGHT";
      break;
    case "CURRENCY":
      if (!options) modifiedOptions.align = "RIGHT";
      if (options) options.align = "RIGHT";
      break;
    default:
      break;
  }
  if (options) return options;
  return modifiedOptions;
};

function SortButton({ classes, label, options, format, dataTestId }) {
  const modifiedOptions = getOptions(format, options);
  const headerLabel = label.replace(/\s/g, "-").toLowerCase();
  return (
    <span
      className={classNames(
        "header",
        classes.header,
        getAlignment(modifiedOptions)
      )}
    >
      <span
        className="header-label"
        data-test={generateTestIds(dataTestId, "column-header", headerLabel)}
      >
        {label}
      </span>
      <span
        className="header-sort-icon"
        data-test={generateTestIds(dataTestId, "sort-button")}
      >
        <span className="icon-desc">
          <Icon
            className={classes.sortIcon}
            data-test={generateTestIds(dataTestId, "sort-button-dec")}
          >
            arrow_downward
          </Icon>
        </span>
        <span className="icon-asc">
          <Icon
            className={classes.sortIcon}
            data-test={generateTestIds(dataTestId, "sort-button-asc")}
          >
            arrow_upward
          </Icon>
        </span>
      </span>
    </span>
  );
}

SortButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  options: PropTypes.object,
  dataTestId: PropTypes.string
};

export default withStyles(styles)(SortButton);
