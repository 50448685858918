import React from "react";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import { compose } from "recompose";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { theme, services, utils } from "@cauldron/core";

// LOCAL DEPENDENCIES
import AppRouter from "./pages/pages.router";
import { ErrorBoundary, Messenger } from "./shared";
import "./theme.main.css";
import "./setup3rdParty";
import { loadCatalog, debugMode } from "./setupI18n";
import { store, persistor, history } from "./setupStore";

const { generateTestIds } = utils;
const { i18n } = services;
const { I18nLoader } = i18n;
const { withTheme, getTheme } = theme;
const themeName = getTheme(process.env.REACT_APP_DEFAULT_THEME);
const dataTestBuilder = generateTestIds;

const EnhancedApp = withI18n()(({ i18n, children }) => {
  return (
    <ErrorBoundary
      title={i18n._(t`Oops...`)}
      message={i18n._(t`An unexpected UI exception has occurred.`)}
    >
      {children}
    </ErrorBoundary>
  );
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <I18nLoader loadCatalog={loadCatalog} debugMode={debugMode}>
          <EnhancedApp>
            <div className="app-wrapper" data-test="app-wrapper">
              <AppRouter history={history} />
              <Messenger
                dataTestId="app-messenger"
                dataTestBuilder={dataTestBuilder}
              />
            </div>
          </EnhancedApp>
        </I18nLoader>
      </PersistGate>
    </Provider>
  );
}

const enhance = compose(withTheme(themeName));

export default enhance(App);
