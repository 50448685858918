export const LOAN_APPLICATION_BANK_REQUEST_FETCH =
  "loan.application/BANK_REQUEST_FETCH";
export const LOAN_APPLICATION_BANK_REQUEST_FETCH_SUCCESS =
  "loan.application/BANK_REQUEST_FETCH_SUCCESS";
export const LOAN_APPLICATION_BANK_REQUEST_FETCH_FAILURE =
  "loan.application/BANK_REQUEST_FETCH_FAILURE";

export const LOAN_APPLICATION_BANK_REQUEST_SEND =
  "loan.application/BANK_REQUEST_SEND";
export const LOAN_APPLICATION_BANK_REQUEST_SEND_SUCCESS =
  "loan.application/BANK_REQUEST_SEND_SUCCESS";

export const LOAN_APPLICATION_BANK_REQUEST_RESEND =
  "loan.application/BANK_REQUEST_RESEND";
export const LOAN_APPLICATION_BANK_REQUEST_RESEND_SUCCESS =
  "loan.application/BANK_REQUEST_RESEND_SUCCESS";
