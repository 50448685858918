"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.composeWithReduxDevTools = composeWithReduxDevTools;
exports.withReduxLogger = withReduxLogger;
exports.Reactotron = Reactotron;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _redux = require("redux");

var _reduxLogger = require("redux-logger");

var _constants = require("./constants");
/**
 * composeWithReduxDevTools
 * setup dev tools here
 * @returns {*}
 */


function composeWithReduxDevTools() {
  if ((typeof window === "undefined" ? "undefined" : (0, _typeof2.default)(window)) === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  return _redux.compose;
}
/**
 * withReduxLogger
 * @param middleware
 */


function withReduxLogger(middleware) {
  if (process.env.NODE_ENV !== _constants.TEST && process.env.REACT_APP_ENABLE_LOGGER === "true") {
    var reduxLoggerOptions = {
      duration: false,
      //: Boolean, // print the duration of each action?
      timestamp: false,
      //: Boolean, // print the timestamp with each action?
      level: "log",
      //: 'log', | 'console' | 'warn' | 'error' | 'info', // console's level
      logErrors: true,
      // should the logger catch, log, and re-throw errors?
      diff: process.env.REACT_APP_SHOW_STATE_DIFF === "true",
      // (alpha) show diff between states?
      collapsed: process.env.REACT_APP_COLLAPSE_LOGS === "true"
    };
    var reduxLogger = (0, _reduxLogger.createLogger)(reduxLoggerOptions);
    middleware.splice(1, 0, reduxLogger);
  }
}
/**
 * Reactotron
 * @constructor
 */


function Reactotron() {// Reactotron enhancer goes here
  // returns Reactotron
}