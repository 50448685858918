"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAuthHeader = createAuthHeader;
exports.createHttpHeaders = createHttpHeaders;
exports.createHttpRequest = createHttpRequest;
exports.createHttp = createHttp;
exports.create = create;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _axios = _interopRequireDefault(require("axios"));
/**
 * createAuthHeader
 * @param token
 * @returns {*}
 */


function createAuthHeader(token) {
  var isValidToken = typeof token === "string" && !!token;
  return isValidToken ? "Bearer " + token : "";
}
/**
 * createHttpHeaders
 * @param token
 * @param config
 * @returns {*}
 */


function createHttpHeaders(token, config) {
  var headers = config && config ? (0, _objectSpread2.default)({}, config) : {};
  var authHeader = createAuthHeader(token);
  if (authHeader) headers.Authorization = authHeader;
  return headers;
}
/**
 * createHttpRequest - provide custom http request
 * @param config
 * @param token
 * @param baseURL
 * @param timeout
 * @return {{}&{headers: *, baseURL: *, method: string, data, timeout: *}}
 */


function createHttpRequest() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var token = arguments.length > 1 ? arguments[1] : undefined;
  var baseURL = arguments.length > 2 ? arguments[2] : undefined;
  var timeout = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var _config$method = config.method,
      method = _config$method === void 0 ? "get" : _config$method,
      _config$data = config.data,
      data = _config$data === void 0 ? {} : _config$data;
  var headersConfig = config && config.headers ? config.headers : null;
  var headers = createHttpHeaders(token, headersConfig);
  return (0, _objectSpread2.default)({
    baseURL: baseURL,
    timeout: timeout,
    method: method,
    data: data
  }, config, {
    headers: headers
  });
}
/**
 * createHttp - provide basic http methods
 * @param method
 * @param data
 * @param token
 * @param baseURL
 * @param timeout
 * @return {{headers: *, baseURL: *, method: string, data, timeout: *}}
 */


function createHttp() {
  var method = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "get";
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var token = arguments.length > 2 ? arguments[2] : undefined;
  var baseURL = arguments.length > 3 ? arguments[3] : undefined;
  var timeout = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var headers = createHttpHeaders(token);
  return {
    baseURL: baseURL,
    timeout: timeout,
    method: method,
    data: data,
    headers: headers
  };
}
/**
 * httpSrv
 * Wrapper for axios and the place to add add global headers such as:
 *   authorization,
 *   baseURL for the API,
 *   timeout, etc...
 * reference:
 *  https://progressa.atlassian.net/wiki/spaces/IN/pages/587202575/HTTP+Service
 *  https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
 *  https://www.npmjs.com/package/axios - original documentation
 */


function create(config) {
  var token = config.token,
      _config$methods = config.methods,
      methods = _config$methods === void 0 ? [] : _config$methods,
      _config$baseURL = config.baseURL,
      baseURL = _config$baseURL === void 0 ? "" : _config$baseURL; // TODO: provide a better solution for long standing API calls rather than 0 timeout

  var timeout = 0;
  var functors = {};

  if (!Array.isArray(methods)) {
    throw Error("Arg method must be an array.");
  } // Create methods for http service
  // request, get, put, post, delete


  return methods.reduce(function (fns, method) {
    var validMethod = method && typeof method === "string" ? method.toLowerCase() : null;

    if (typeof method !== "string") {
      throw Error("Method must be a string.");
    }

    if (validMethod) {
      // custom requests - request
      if (validMethod === "request") {
        fns[validMethod] = function (config) {
          var customRequest = createHttpRequest(config, token, baseURL, timeout);
          return _axios.default.create(customRequest)();
        };
      } // basic requests - get, put, patch, post, delete
      else {
          fns[validMethod] = function (API, data, context) {
            var basicRequest = createHttp(validMethod.toUpperCase(), data, token, baseURL, timeout);
            return _axios.default.create(basicRequest)(API, context);
          };
        }
    }

    return fns;
  }, functors);
}