import { fadeHEX } from "../../../utils/jss.utils";

const styles = theme => ({
  formWrapper: {
    backgroundColor: theme.palette.lightGrey[100],
    marginBottom: "12px"
  },
  formWrapperWithBg: {
    marginBottom: "4px",
    padding: "0 4px"
  },
  formContainer: {
    backgroundColor: "white"
  },
  formContainerWithBg: {
    backgroundColor: theme.palette.lightGrey[100],
    paddingBottom: 20,
    borderRadius: "2px",
    "& > div:first-child": {
      marginTop: "4px"
    }
  },
  actionsWrapper: {
    marginTop: "18px",
    display: "flex",
    flexDirection: "row",
    marginRight: "16px",
    justifyContent: "flex-end"
  },
  actionsWrapperWithNoMargin: {
    marginTop: "18px",
    display: "flex",
    flexDirection: "row",
    marginRight: 0,
    justifyContent: "flex-end"
  },
  halfButton: {
    minWidth: "81px",
    height: "16px",
    lineHeight: "16px",
    textAlign: "center",
    boxShadow: "none",
    margin: "0 0 0 8px"
  },
  confirmButton: {
    height: "32px",
    backgroundColor: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      "& > span p": {
        color: theme.palette.grey[900]
      }
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
      "& > span p": {
        color: theme.palette.grey[900]
      }
    }
  },
  disabledConfirmButton: {
    height: "32px",
    backgroundColor: `${theme.palette.lightGrey[200]}!important`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      "& > span p": {
        color: theme.palette.grey[900]
      }
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
      "& > span p": {
        color: theme.palette.grey[900]
      }
    }
  },
  disabledConfirmText: {
    color: theme.palette.grey[600],
    lineHeight: 0
  },
  confirmText: {
    color: theme.palette.primary.contrastText,
    lineHeight: 0
  },
  cancelButton: {
    height: "32px",
    backgroundColor: theme.palette.lightGrey[900],
    margin: "0 8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none"
    }
  },
  cancelText: theme.typography.p3Dark,
  deleteButton: {
    height: "32px",
    backgroundColor: fadeHEX(theme.palette.error.main, 0.3),
    margin: "0 8px 0 0",
    "&:hover": {
      backgroundColor: fadeHEX(theme.palette.error.main, 0.45)
    },
    "&:active": {
      backgroundColor: fadeHEX(theme.palette.error.main, 0.7),
      boxShadow: "none"
    }
  },
  deleteText: theme.typography.p3Dark,
  emptyButton: {
    width: "87px",
    height: "32px"
  },
  withPadding: {
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  formErrorStrack: {
    display: "flex",
    alignItems: "center",
    marginTop: "24px",
    width: "auto",
    flexWrap: "wrap",
    flexDirection: "column",
    "&> div": {
      width: "100%",
      marginBotton: "4px"
    }
  },
  errorLine: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      wordBreak: "break-word",
      padding: 0,
      display: "flex",
      alignItems: "flex-start",
      " & > span:first-child": {
        marginTop: "2px"
      }
    }
  }
});

export default styles;
