/**
 * ALCHEMY TABLE NO DATA MESSAGE STYLES
 * @param theme
 * @author Ryan Rivera
 */
const styles = () => ({
  messageBox: {
    height: "100%",
    position: "absolute",
    padding: "58px 16px",
    width: "100%"
  }
});

export default styles;
