/**
 * STATISTICS STYLES
 * @param theme
 * @author Ryan Rivera
 */
const styles = theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0"
  },
  statistics: {
    display: "flex",
    padding: "0 4px",

    "&:first-child": {
      paddingLeft: 0
    },

    "&.invisible": {
      visibility: "hidden"
    }
  },
  count: {
    display: "inline-block",
    minWidth: "16px",
    fontSize: "10px",
    lineHeight: "16px",
    color: theme.palette.grey[900]
  },
  iconRoot: {
    ...theme.icon.icon20,
    display: "inline-block",
    fontSize: "20px",
    color: "inherit",

    // temporary -- modify once each icon can
    // independently perform an action such as linking
    "&:hover": {
      color: "inherit"
    }
  },
  action: {
    color: theme.palette.grey[900]
  },
  success: {
    color: theme.palette.success["main"]
  },
  warning: {
    color: theme.palette.warning["main"]
  },
  error: {
    color: theme.palette.error["main"]
  }
});

export default styles;
