import * as actions from "../action.types/loan.application.details.action.types";
import {
  LOAN_APPLICATION_CLIENT_LIABILITY_ADD_CACHE,
  LOAN_APPLICATION_CLIENT_LIABILITY_ADD_CACHE_CLEAN,
  LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_CACHE,
  LOAN_APPLICATION_CLIENT_LIABILITY_CANCEL_EDIT_FORM,
  LOAN_APPLICATION_LIABILITY_CACHE_FORM_VALUES
} from "../../components/application.card/components/liabilities/engine/liabilities.action.types";
import {
  LOAN_APPLICATION_BANK_ACCOUNT_CACHE_ADD_FORMS,
  LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_ADD_FORMS,
  LOAN_APPLICATION_BANK_ACCOUNT_CACHE_EDIT_FORMS,
  LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_EDIT_FORM,
  LOAN_APPLICATION_BANK_ACCOUNT_CACHE_FORM_VALUES,
  LOAN_APPLICATION_BANK_ACCOUNT_UPDATE,
  LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_SUCCESS,
  LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_FAILURE,
  LOAN_APPLICATION_BANK_ACCOUNT_ADD_SUCCESS
} from "../../components/application.card/components/bank.details/engine/bank.details.action.types";

import {
  formCache,
  cancelEditForm,
  cacheEditFormValues,
  createInitialFormState
} from "../../../../utils/reducer.utils";

const keys = [
  "liability",
  "expense",
  "bankDetails",
  "client",
  "dealLead",
  "referral"
];

const initialState = createInitialFormState(keys);

export default (state = initialState, action) => {
  switch (action.type) {
    // LIABILITY REDUCERS
    case LOAN_APPLICATION_CLIENT_LIABILITY_ADD_CACHE:
      return {
        ...state,
        liability: {
          ...state.liability,
          add: formCache.addCollection.cache(state.liability.add)(action.data)
        }
      };

    case LOAN_APPLICATION_CLIENT_LIABILITY_ADD_CACHE_CLEAN:
      return {
        ...state,
        liability: {
          ...state.liability,
          add: formCache.addCollection.clean(state.liability.add)(
            action.id,
            action.index
          )
        }
      };

    case LOAN_APPLICATION_CLIENT_LIABILITY_UPDATE_CACHE:
      return {
        ...state,
        liability: {
          ...state.liability,
          update: formCache.updateCollection.cache(state.liability.update)(
            action.data
          )
        }
      };

    case LOAN_APPLICATION_CLIENT_LIABILITY_CANCEL_EDIT_FORM:
      return {
        ...state,
        liability: {
          ...state.liability,
          update: cancelEditForm(
            state.liability.update,
            "id",
            action.liabilityId
          )
        }
      };

    case LOAN_APPLICATION_LIABILITY_CACHE_FORM_VALUES:
      return {
        ...state,
        liability: {
          ...state.liability,
          update: cacheEditFormValues(
            state.liability.update,
            {
              id: action.liabilityId
            },
            action.values
          )
        }
      };

    // EXPENSE REDUCERS
    case actions.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_CACHE:
      return {
        ...state,
        expense: {
          ...state.expense,
          add: formCache.addCollection.cache(state.expense.add)(action.data)
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_EXPENSE_ADD_CACHE_CLEAN:
      return {
        ...state,
        expense: {
          ...state.expense,
          add: formCache.addCollection.clean(state.expense.add)(
            action.id,
            action.index
          )
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_CACHE:
      return {
        ...state,
        expense: {
          ...state.expense,
          update: formCache.updateCollection.cache(state.expense.update)(
            action.data
          )
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_EXPENSE_UPDATE_CACHE_CLEAN:
      return {
        ...state,
        expense: {
          ...state.expense,
          update: formCache.updateCollection.clean(state.expense.update)(
            action.id
          )
        }
      };

    // BANK DETAILS

    case LOAN_APPLICATION_BANK_ACCOUNT_CACHE_ADD_FORMS:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          add: formCache.addCollection.cache(state.bankDetails.add)(action.data)
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_ADD_FORMS:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          add: formCache.addCollection.clean(state.bankDetails.add)(
            action.id,
            action.index
          )
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_CACHE_EDIT_FORMS:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          update: [
            ...state.bankDetails.update,
            {
              clientId: action.clientId,
              accountId: action.accountId,
              values: action.values,
              isFetching: false // used for spinner or disabling form buttons
            }
          ]
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_CANCEL_EDIT_FORM:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          update: cancelEditForm(
            state.bankDetails.update,
            "accountId",
            action.accountId
          )
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_CACHE_FORM_VALUES:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          update: cacheEditFormValues(
            state.bankDetails.update,
            {
              accountId: action.accountId
            },
            action.values
          )
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_UPDATE:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          update: state.bankDetails.update.map(form => {
            if (
              form.accountId === action.accountId &&
              form.clientId === action.clientId
            ) {
              return {
                ...form,
                isFetching: true
              };
            }
            return form;
          })
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          update: state.bankDetails.update.map(form => {
            if (
              form.accountId === action.accountId &&
              form.clientId === action.clientId
            ) {
              return {
                ...form,
                isFetching: false
              };
            }
            return form;
          })
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_UPDATE_FAILURE:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          update: state.bankDetails.update.map(form => {
            if (
              form.accountId === action.accountId &&
              form.clientId === action.clientId
            ) {
              return {
                ...form,
                isFetching: false
              };
            }
            return form;
          })
        }
      };

    case LOAN_APPLICATION_BANK_ACCOUNT_ADD_SUCCESS:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          add: []
        }
      };

    // LOAN APPLICATION CLIENT
    case actions.LOAN_APPLICATION_CLIENT_ADD_CACHE:
      return {
        ...state,
        client: {
          ...state.client,
          add: formCache.addCollection.cache(state.client.add)(action.data)
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_ADD_CACHE_CLEAN:
      return {
        ...state,
        client: {
          ...state.client,
          add: []
        }
      };

    case actions.LOAN_APPLICATION_CLIENT_ADD_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          add: []
        }
      };

    case actions.LOAN_APPLICATION_DEAL_LEAD_CACHE_FORM:
      return {
        ...state,
        dealLead: {
          ...state.dealLead,
          update: formCache.updateCollection.cache(state.dealLead.update)(
            action.data
          )
        }
      };

    case actions.LOAN_APPLICATION_REFERRAL_SOURCES_CACHE_FORM:
      return {
        ...state,
        referral: {
          ...state.referral,
          update: formCache.updateCollection.cache(state.referral.update)(
            action.data
          )
        }
      };

    case actions.LOAN_APPLICATION_DEAL_LEAD_CACHE_VALUES:
      return {
        ...state,
        dealLead: {
          ...state.dealLead,
          update: cacheEditFormValues(
            state.dealLead.update,
            {
              id: action.id
            },
            action.values
          )
        }
      };

    case actions.LOAN_APPLICATION_REFERRAL_SOURCES_CACHE_VALUES:
      return {
        ...state,
        referral: {
          ...state.referral,
          update: cacheEditFormValues(
            state.referral.update,
            {
              id: action.id
            },
            { ...action.values, options: action.options }
          )
        }
      };

    case actions.LOAN_APPLICATION_DEAL_LEAD_OPTIONS_FETCH_SUCCESS:
      return {
        ...state,
        dealLead: {
          ...state.dealLead,
          update: [{ ...state.dealLead.update[0], options: action.data }]
        }
      };

    case actions.LOAN_APPLICATION_DEAL_LEAD_UPDATE:
      return {
        ...state,
        dealLead: {
          ...state.dealLead,
          update: [{ ...state.dealLead.update[0], isFetching: true }]
        }
      };

    case actions.LOAN_APPLICATION_DEAL_LEAD_UPDATE_FAILURE:
      return {
        ...state,
        dealLead: {
          ...state.dealLead,
          update: [{ ...state.dealLead.update[0], isFetching: false }]
        }
      };

    case actions.LOAN_APPLICATION_DEAL_LEAD_CLEAN_FORM:
      return {
        ...state,
        dealLead: {
          ...state.dealLead,
          update: cancelEditForm(state.dealLead.update, "id", action.id)
        }
      };

    case actions.LOAN_APPLICATION_REFERRAL_SOURCES_CLEAN_FORM:
      return {
        ...state,
        referral: {
          ...state.referral,
          update: cancelEditForm(state.referral.update, "id", action.id)
        }
      };

    default:
      return state;
  }
};
