export const styles = theme => ({
  iconRoot: {
    fontSize: "16px",
    color: theme.palette.grey[900]
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main
    },
    height: "23px",
    paddingLeft: "16px",
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  menuItemStatic: {
    height: "23px",
    paddingLeft: "16px",
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  itemText: theme.typography.p2Dark
});
