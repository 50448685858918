"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "I18nLoader", {
  enumerable: true,
  get: function get() {
    return _i18n.default;
  }
});
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _i18n3.default;
  }
});
exports.actions = void 0;

var _i18n = _interopRequireDefault(require("./i18n.loader"));

var actions = _interopRequireWildcard(require("./i18n.actions"));

exports.actions = actions;

var _i18n3 = _interopRequireDefault(require("./i18n.reducer"));