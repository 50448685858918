import { utils } from "@cauldron/core";

const { makeActionTypes } = utils;

const namespace = "referral.sources";

/**
 * Generates actions
 * creates one for each REQUEST | SUCCESS | FAILURE
 */

export const actionTypes = {
  ...makeActionTypes(namespace, "REFERRAL_SOURCES_FETCH"),
  ...makeActionTypes(namespace, "REFERRAL_SOURCE_AGENTS_FETCH"),
  ...makeActionTypes(namespace, "CREATE_REFERRAL_AGENT")
};
