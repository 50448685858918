"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apply = apply;
exports.constructMsg = constructMsg;
exports.initialize = initialize;
exports.notify = notify;
exports.getErrorBoundary = getErrorBoundary;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _js = _interopRequireDefault(require("@bugsnag/js"));

var _pluginReact = _interopRequireDefault(require("@bugsnag/plugin-react"));
/**
 * Bugsnag initializer
 * @param bsId
 * @param enabled
 * @param rest
 * @param mock - used for testing
 */


function initialize(_ref, mock) {
  var bsId = _ref.bsId,
      enabled = _ref.enabled,
      rest = (0, _objectWithoutProperties2.default)(_ref, ["bsId", "enabled"]);

  if (enabled && !window.bugsnagClient) {
    window.bugsnagClient = mock ? mock() : (0, _js.default)((0, _objectSpread2.default)({
      apiKey: bsId
    }, rest));
    window.bugsnagClient.use(_pluginReact.default, _react.default);
  }
}
/**
 * Bugsnag notify
 * @param error
 * @param options
 */


function notify(error, options) {
  if (window.bugsnagClient) {
    window.bugsnagClient.notify(error, options);
  }
}
/**
 * Bugsnag getErrorBoundary
 * @return {null|any}
 */


function getErrorBoundary() {
  if (window.bugsnagClient) {
    return window.bugsnagClient.getPlugin("react");
  }

  return null;
}
/**
 * constructMsg
 * if a context needs to be set, need to pass an object with a 'context' key
 * @param args
 * @return {{context: null, message: string}}
 */


function constructMsg() {
  var messages = [];
  var context = null;

  for (var i = 0; i < arguments.length; i++) {
    var msgParam = i < 0 || arguments.length <= i ? undefined : arguments[i];

    if (msgParam && typeof msgParam === "string") {
      messages.push(msgParam);
    } else if (typeof msgParam === "number") {
      messages.push(msgParam.toString());
    } else if (msgParam.context) {
      context = msgParam.context;
      messages.push(JSON.stringify(msgParam));
    } else {
      messages.push(JSON.stringify(msgParam));
    }
  }

  return {
    message: messages.join(" "),
    context: context
  };
}
/**
 * Bugsnag loglevel integration
 * @param log
 */


function apply(log) {
  var originalFactory = log.methodFactory;
  var level = log.getLevel();

  log.methodFactory = function (methodName, logLevel, loggerName) {
    var rawMethod = originalFactory(methodName, logLevel, loggerName);
    return function () {
      var _constructMsg = constructMsg.apply(void 0, arguments),
          message = _constructMsg.message,
          context = _constructMsg.context;

      notify(message, {
        context: context ? context : "".concat(window.location.pathname, " | ").concat(loggerName)
      });
      if (message && message.length) rawMethod(message);
    };
  }; // setLevel method called in order to apply plugin


  log.setLevel(level);
}