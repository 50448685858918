"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _portal = _interopRequireDefault(require("./portal"));

var _engage = _interopRequireDefault(require("./engage"));
/**
 * Alternate themes can go here
 */


var _default = {
  engage: _engage.default,
  portal: _portal.default
};
exports.default = _default;