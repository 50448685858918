"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUrlParams = getUrlParams;
exports.omitDefaultParams = omitDefaultParams;
exports.createUrlQuery = exports.getUrlQuery = exports.checkEmptyObj = void 0;

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

var _omitBy = _interopRequireDefault(require("lodash/omitBy"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _isNil = _interopRequireDefault(require("lodash/isNil"));

var _isPlainObject = _interopRequireDefault(require("lodash/isPlainObject"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/**
 * checkEmptyObj
 * Check if object exists, if keys has values and values are not empty (length 0)
 */


var checkEmptyObj = function checkEmptyObj(obj) {
  var nonValue = function nonValue(v) {
    return !(0, _isNil.default)(v) && v !== "" && v.toString();
  };

  return (0, _isEmpty.default)(obj) || !Object.keys(obj).length || !Object.values(obj).filter(nonValue).length;
};
/**
 * GET URL QUERY
 * Retrieves a query based off the url query
 * @param query
 */


exports.checkEmptyObj = checkEmptyObj;

var getUrlQuery = function getUrlQuery(query) {
  query = decodeURIComponent(query);
  query = query.substring(query.indexOf("?") + 1, query.length);
  var qObj = {};
  query.split("&").forEach(function (q) {
    var a = q.split("=");
    if (a[0] && a[0] !== "") qObj[a[0]] = a[1];
  });
  return qObj;
};
/**
 * CREATE URL QUERY
 * Creates a query based off the url query
 * useful for preserving state through url
 * Will return only keyvalue pairs
 * @param query
 * @returns {string}
 */


exports.getUrlQuery = getUrlQuery;

var createUrlQuery = function createUrlQuery(query) {
  var urlQuery = "";

  if (checkEmptyObj(query)) {
    return urlQuery;
  }

  var cArray = [];

  for (var key in query) {
    var value = typeof query[key] === "number" ? query[key].toString() : query[key];
    var sanitizedValue = encodeURIComponent(value);
    if (!(0, _isNil.default)(sanitizedValue) && sanitizedValue !== "") cArray.push([key, sanitizedValue].join("="));
  }

  return cArray.join("&");
};
/**
 * getUrlParams
 * @param initialUrlParams
 * @param location
 */


exports.createUrlQuery = createUrlQuery;

function getUrlParams(initialUrlParams, location) {
  if (!initialUrlParams || !(0, _isPlainObject.default)(initialUrlParams)) return {};
  var urlQuery = location && location.search ? getUrlQuery(location.search) : {}; // merge latest urlQuery params with initialUrlParams

  var allowedParams = Object.keys(initialUrlParams);
  return _objectSpread({}, initialUrlParams, (0, _pick.default)(urlQuery, allowedParams));
}
/**
 * omitDefaultParams
 * @param query
 * @param initialUrlParams
 * @returns {*}
 */


function omitDefaultParams(query, initialUrlParams) {
  return !query || !(0, _isPlainObject.default)(query) ? {} : (0, _omitBy.default)(query, function (value, key) {
    return initialUrlParams[key] === value;
  });
}