import partition from "lodash/partition";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { createSelector } from "reselect";
import { COMPANY, NO_REFERRAL_AGENT } from "./referral.sources.constants";

const referralSources = state => state.data.referralSources;
const selectApplicationReferrer = state =>
  get(state.data.agentPortalApplication.application, "referrer", {});

export const getReferralSources = createSelector(
  referralSources,
  (data = []) => data.results
);

const getReferralSourcesPartition = createSelector(
  [getReferralSources, selectApplicationReferrer],
  (sources = [], applicationReferrer) => {
    const sourceAndAgents = partition(sources, { sourceType: COMPANY });
    const [companies, agents] = sourceAndAgents;
    const extendedCompanies = isEmpty(applicationReferrer)
      ? companies
      : companies.concat(get(applicationReferrer, "company", {}));
    const extendedAgents = isEmpty(applicationReferrer)
      ? agents
      : agents.concat(get(applicationReferrer, "agent", {}));
    const uniqueAgents = uniqBy(orderBy(extendedAgents, "emails"), "id");
    const uniqueCompanies = uniqBy(extendedCompanies, "id");

    return [uniqueCompanies, uniqueAgents];
  }
);

export const getCompanyReferralSources = createSelector(
  getReferralSourcesPartition,
  ([referralSources]) => {
    return referralSources;
  }
);

export const getReferralSourcesByCompany = createSelector(
  getReferralSourcesPartition,
  ([referralSources, referralAgents]) => {
    return referralSources.reduce((newSources, source) => {
      const agentsForSource = referralAgents.filter(
        agent => agent.companyId === source.id
      );

      newSources[source.id] = {
        ...source,
        agents: [
          { name: NO_REFERRAL_AGENT.label, id: NO_REFERRAL_AGENT.value },
          ...agentsForSource
        ]
      };
      return newSources;
    }, {});
  }
);
