import { normalize } from "../../model.utils";
import { defaultProps, schema, MODEL_NAME } from "./model";

/**
 * Funded loan (details) Transform [RECEIVED]
 * get a specific loan data
 * transform data -> before it reaches the store
 * @param {*} rawData
 * @author Ehsan
 */

export const transformReceived = rawData => {
  return {
    results: rawData.results.map(request => {
      return normalize(request, defaultProps, schema, MODEL_NAME);
    })
  };
};
