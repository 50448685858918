import formControlStyles from "../../styles/form.controls.styles";

/**
 * ALCHEMY_DATE_PICKER STYLES
 * @author rrive
 * @param theme {object}
 */
const styles = theme => ({
  ...formControlStyles(theme),
  "text-align-left input": {
    textAlign: "left"
  },
  "text-align-right input": {
    textAlign: "right"
  },
  "text-align-center input": {
    textAlign: "center"
  },
  calendarBtn: {
    padding: "10px",

    "&[disabled]": {
      opacity: 0.25
    }
  },
  calendarIcon: {
    ...theme.icon["icon16"],
    padding: "1px",
    margin: "2px 5px",

    "&:not([disabled])": {
      cursor: "pointer"
    }
  }
});

export default styles;
