"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _model = require("../../utils/model.utils"); // ENV CONFIGS


var ENABLE_AUTHORIZATION = process.env.REACT_APP_ENABLE_AUTHORIZATION || "false";
var ENABLE_SUPER_USER = process.env.REACT_APP_ENABLE_SUPER_USER || "false";
var ENABLE_AUDITOR_USER = process.env.REACT_APP_ENABLE_AUDITOR_USER || "false"; // ROLES

var AUDITOR_USER = "AUDITOR_USER";
var SUPER_USER = "SUPER_USER";
var USER = "USER";
var MODEL_NAME = "Profile";
/**
 * Protects data from missing fields
 */

var defaultProps = {
  email: "",
  name: "",
  permissions: [],
  auditorUser: false,
  superUser: false
};
/**
 * Protects data from change in data type
 */

var schema = {
  name: _propTypes.default.string,
  email: _propTypes.default.string,
  permissions: _propTypes.default.array,
  auditorUser: _propTypes.default.bool,
  superUser: _propTypes.default.bool
};

var transformReceived = function transformReceived(data) {
  // Normalize data as first step when receiving data from service
  // Make sure to define MODEL_NAME
  var normalizedData = (0, _model.normalize)(data, defaultProps, schema, MODEL_NAME); // Transform data further

  var permissions = normalizedData.permissions,
      auditorUser = normalizedData.auditorUser,
      superUser = normalizedData.superUser;
  var isSuperUser = superUser;
  var isAuditorUser = auditorUser; // Overrides for development and testing

  if (ENABLE_AUTHORIZATION === "false") {
    isSuperUser = true;
  }

  if (ENABLE_SUPER_USER === "true") {
    isSuperUser = true;
  }

  if (ENABLE_AUDITOR_USER === "true") {
    isAuditorUser = true;
  }

  var roles = [];
  if (permissions.length > 0) roles.push(USER);
  if (isAuditorUser && !isSuperUser) roles.push(AUDITOR_USER);
  if (isSuperUser) roles.push(SUPER_USER); // TODO: Refactor to move allowedStatuses to application details state slice

  return (0, _objectSpread2.default)({}, data, {
    permissions: permissions,
    roles: roles,
    allowedStatuses: data.allowedStatuses
  });
};

var _default = {
  transformReceived: transformReceived,
  transformSend: null
};
exports.default = _default;