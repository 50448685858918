"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CLIENT_PHONE_NUMBERS_DELETE = exports.CLIENT_PHONE_NUMBERS_PUT = exports.CLIENT_PHONE_NUMBERS = exports.CLIENT_LIABILITY = exports.CLIENT_LIABILITIES = exports.CLIENT_INCOME = exports.CLIENT_INCOMES = exports.CLIENT_GET_BY_VALUE = exports.CLIENT_EXPENSE = exports.CLIENT_EXPENSES = exports.CLIENT_EMAILS_DELETE = exports.CLIENT_EMAILS_PUT = exports.CLIENT_EMAILS = exports.CLIENT_BANK_ACCOUNT = exports.CLIENT_BANK_ACCOUNTS = exports.CLIENT_ADDRESS = exports.CLIENT_ADDRESSES = exports.CLIENT = exports.CLIENTS = void 0;

var _api = require("./api.contants");
/**
 * API CLIENTS ENDPOINTS
 */


var CLIENTS = "".concat(_api.ROOT, "/clients");
exports.CLIENTS = CLIENTS;

var CLIENT = function CLIENT(clientId) {
  return "".concat(CLIENTS, "/").concat(clientId);
};

exports.CLIENT = CLIENT;

var CLIENT_ADDRESSES = function CLIENT_ADDRESSES(clientId) {
  return "".concat(CLIENT(clientId), "/addresses");
};

exports.CLIENT_ADDRESSES = CLIENT_ADDRESSES;

var CLIENT_ADDRESS = function CLIENT_ADDRESS(clientId, addressId) {
  return "".concat(CLIENT(clientId), "/addresses/").concat(addressId);
}; // prettier-ignore


exports.CLIENT_ADDRESS = CLIENT_ADDRESS;

var CLIENT_BANK_ACCOUNTS = function CLIENT_BANK_ACCOUNTS(clientId) {
  return "".concat(CLIENT(clientId), "/bank-accounts");
}; // prettier-ignore


exports.CLIENT_BANK_ACCOUNTS = CLIENT_BANK_ACCOUNTS;

var CLIENT_BANK_ACCOUNT = function CLIENT_BANK_ACCOUNT(clientId, accountId) {
  return "".concat(CLIENT_BANK_ACCOUNTS(clientId), "/").concat(accountId);
}; // prettier-ignore


exports.CLIENT_BANK_ACCOUNT = CLIENT_BANK_ACCOUNT;

var CLIENT_EMAILS = function CLIENT_EMAILS(clientId) {
  return "".concat(CLIENT(clientId), "/emails");
};

exports.CLIENT_EMAILS = CLIENT_EMAILS;

var CLIENT_EMAILS_PUT = function CLIENT_EMAILS_PUT(clientId, emailId) {
  return "".concat(CLIENT(clientId), "/emails/").concat(emailId);
}; // prettier-ignore


exports.CLIENT_EMAILS_PUT = CLIENT_EMAILS_PUT;

var CLIENT_EMAILS_DELETE = function CLIENT_EMAILS_DELETE(clientId, emailId) {
  return "".concat(CLIENT(clientId), "/emails/").concat(emailId);
}; // prettier-ignore


exports.CLIENT_EMAILS_DELETE = CLIENT_EMAILS_DELETE;

var CLIENT_EXPENSES = function CLIENT_EXPENSES(clientId) {
  return "".concat(CLIENT(clientId), "/expenses");
};

exports.CLIENT_EXPENSES = CLIENT_EXPENSES;

var CLIENT_EXPENSE = function CLIENT_EXPENSE(clientId, expenseId) {
  return "".concat(CLIENT_EXPENSES(clientId), "/").concat(expenseId);
}; // prettier-ignore


exports.CLIENT_EXPENSE = CLIENT_EXPENSE;

var CLIENT_GET_BY_VALUE = function CLIENT_GET_BY_VALUE(clientId) {
  return "".concat(CLIENT(clientId), "/get-by-value");
}; // prettier-ignore


exports.CLIENT_GET_BY_VALUE = CLIENT_GET_BY_VALUE;

var CLIENT_INCOMES = function CLIENT_INCOMES(clientId) {
  return "".concat(CLIENT(clientId), "/incomes");
};

exports.CLIENT_INCOMES = CLIENT_INCOMES;

var CLIENT_INCOME = function CLIENT_INCOME(clientId, incomeId) {
  return "".concat(CLIENT_INCOMES(clientId), "/").concat(incomeId);
}; // prettier-ignore


exports.CLIENT_INCOME = CLIENT_INCOME;

var CLIENT_LIABILITIES = function CLIENT_LIABILITIES(clientId) {
  return "".concat(CLIENT(clientId), "/liabilities");
};

exports.CLIENT_LIABILITIES = CLIENT_LIABILITIES;

var CLIENT_LIABILITY = function CLIENT_LIABILITY(clientId, liabilityId) {
  return "".concat(CLIENT_LIABILITIES(clientId), "/").concat(liabilityId);
}; // prettier-ignore


exports.CLIENT_LIABILITY = CLIENT_LIABILITY;

var CLIENT_PHONE_NUMBERS = function CLIENT_PHONE_NUMBERS(clientId) {
  return "".concat(CLIENT(clientId), "/phone-numbers");
}; // prettier-ignore


exports.CLIENT_PHONE_NUMBERS = CLIENT_PHONE_NUMBERS;

var CLIENT_PHONE_NUMBERS_PUT = function CLIENT_PHONE_NUMBERS_PUT(clientId, phoneId) {
  return "".concat(CLIENT(clientId), "/phone-numbers/").concat(phoneId);
}; // prettier-ignore


exports.CLIENT_PHONE_NUMBERS_PUT = CLIENT_PHONE_NUMBERS_PUT;

var CLIENT_PHONE_NUMBERS_DELETE = function CLIENT_PHONE_NUMBERS_DELETE(clientId, phoneId) {
  return "".concat(CLIENT(clientId), "/phone-numbers/").concat(phoneId);
}; // prettier-ignore


exports.CLIENT_PHONE_NUMBERS_DELETE = CLIENT_PHONE_NUMBERS_DELETE;