export const transformAddressToSend = address => {
  return {
    primaryResidence: address.primaryResidence,
    unitNumber: address.unit,
    street: address.streetAddress,
    city: address.city,
    postalCode: address.postalCode,
    province: address.province
  };
};
