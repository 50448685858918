"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getPCA", {
  enumerable: true,
  get: function get() {
    return _auto.getPCA;
  }
});
Object.defineProperty(exports, "createController", {
  enumerable: true,
  get: function get() {
    return _auto.createController;
  }
});
Object.defineProperty(exports, "useAddressComplete", {
  enumerable: true,
  get: function get() {
    return _autoAddress.default;
  }
});

var _auto = require("./auto.address");

var _autoAddress = _interopRequireDefault(require("./auto.address.hook"));