import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { utils } from "@cauldron/core";
import { CenterCenter, Logo } from "@cauldron/ui";

// MATERIAL-UI
import { withStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Close from "@material-ui/icons/Close";
import Header from "../Header";
import { Spread } from "./Spread";
import {
  AlchemyIconButton,
  AlchemyTypography,
  Section
} from "../../ui.library";
import { REACT_APP_ENV, REACT_APP_VERSION } from "../../core/constants/env";
import { info } from "../../services";

// LOCAL STYLES
import styles from "./styles/page.styles";

const { generateTestIds } = utils;

const { ServiceVersion } = info;

/**
 * Main wrapper for all pages
 * @param classes
 * @param children
 * @param style
 * @param Header
 * @param dataTestId
 * @returns {*}
 * @constructor
 */
function Page({ classes, children, style, HeaderProps, dataTestId }) {
  const [appInfoVisibility, setAppInfoVisibility] = useState(false);

  function hideAppInfo() {
    setAppInfoVisibility(false);
  }

  function showAppInfo() {
    setAppInfoVisibility(true);
  }

  return (
    <div className={classes.page} style={style} data-test={dataTestId.root}>
      {HeaderProps && (
        <div className={classes.pageHeader}>
          <Header {...HeaderProps} onShowAppInfo={showAppInfo} />
        </div>
      )}

      <Fade
        in={!appInfoVisibility}
        timeout={500}
        style={{ display: appInfoVisibility ? "none" : "block" }}
      >
        <div
          className={classNames(classes.main)}
          data-test={generateTestIds(dataTestId.root, dataTestId.main)}
        >
          {children}
        </div>
      </Fade>

      {appInfoVisibility && (
        <Fade in timeout={500}>
          <div className={classNames(classes.main)} data-test="about-page">
            <Spread
              header={
                <AlchemyIconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={hideAppInfo}
                  size="MD"
                  dataTestId={generateTestIds("about-page", "close-button")}
                  dataTestBuilder={generateTestIds}
                >
                  <Close className={classes.closeIcon} />
                </AlchemyIconButton>
              }
            >
              <CenterCenter>
                <Section>
                  <Fade in timeout={1000}>
                    <div>
                      <Logo size="xxlarge" />
                    </div>
                  </Fade>
                </Section>
                <div>
                  <Section>
                    <Fade in timeout={2000}>
                      <div>
                        <AlchemyTypography type="h4">
                          Powered by Progressa
                        </AlchemyTypography>
                      </div>
                    </Fade>
                  </Section>
                  <Section>
                    <AlchemyTypography>
                      Environment: {REACT_APP_ENV}
                    </AlchemyTypography>
                    <AlchemyTypography>
                      UI: {REACT_APP_VERSION}
                    </AlchemyTypography>
                    <AlchemyTypography>
                      Service: <ServiceVersion />
                    </AlchemyTypography>
                  </Section>
                </div>
              </CenterCenter>
            </Spread>
          </div>
        </Fade>
      )}
    </div>
  );
}

Page.defaultProps = {
  dataTestId: {}
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
  dataTestId: PropTypes.object
};

export { Spread };

export default withStyles(styles)(Page);
